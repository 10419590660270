import { FC, useContext, useMemo, useState } from 'react';
import ReportingObjectsMenuBody from '../../../../../../../../../components/ContextualMenu/components/ReportingObjectsMenuBody/ReportingObjectsMenuBody';
import ContextualMenu from '../../../../../../../../../components/ContextualMenu/ContextualMenu';
import { EventsManagementContext } from '../../../../context';
import { ITableCellData } from '../../../../interfaces';
import { Wrapper } from './styles';
import { getObjectName } from '../../../../../../../../../hooks/useObjectTranslation';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';

interface IProps {
    /** Данные из ячейки */
    data: ITableCellData;
}

/**
 * Компонент для отображения ячейки с отчетными объектами
 */
const ReportingObjects: FC<IProps> = ({ data }) => {
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const context = useContext(EventsManagementContext);
    const { t } = useTranslation(['translation', 'translationCategories']);

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const reportingObjectsNames = useMemo(() => {
        if (context?.reportingObjectsById && data.value) {
            return data.value.reduce((acc: string, id: number, index: number) => {
                if (index === data.value.length - 1) {
                    acc += getObjectName({ reportingObject: context.reportingObjectsById[id], t });
                } else {
                    acc += `${getObjectName({ reportingObject: context.reportingObjectsById[id], t })}, `;
                }
                return acc;
            }, '');
        }
        return '';
    }, [context?.reportingObjectsById, data, t]);

    const onReportingObjectsSelect = (ids: number[]) => {
        context?.updateCellValueTrans({ frontId: data.frontId, key: data.key, value: ids });
        closeContextualMenu();
    };

    return (
        <ContextualMenu
            contextualMenuBody={
                <ReportingObjectsMenuBody
                    onReportingObjectsSelect={onReportingObjectsSelect}
                    selectedReportingObjectsIdsTrans={data.value}
                />
            }
            isContextualMenuOpen={isContextualMenuOpen}
            closeContextualMenu={closeContextualMenu}
        >
            <Wrapper onDoubleClick={toggleContextualMenu}>{reportingObjectsNames}</Wrapper>
        </ContextualMenu>
    );
};

export default ReportingObjects;
