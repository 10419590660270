import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

const initialState: IReducerState = {
    keyWord: 'DetailedBills',
    bgReportType: 'fsf/raw-bills-data',
    plainReportOffset: 0,
};

export const Reports_Advanced_DetailedBills_Widget_Reducer = createSlice({
    name: 'Reports_Advanced_DetailedBills_Widget_Reducer',
    initialState,

    reducers: {
        storePlainReportOffset: (state, action: PayloadAction<number>) => {
            state.plainReportOffset = action.payload;
        },
    },
});

export const { storePlainReportOffset } = Reports_Advanced_DetailedBills_Widget_Reducer.actions;

export const Reports_Advanced_DetailedBills_Widget_Reducer_Values = (state: RootState) =>
    state.Reports_Advanced_DetailedBills_Widget_Reducer;

export default Reports_Advanced_DetailedBills_Widget_Reducer.reducer;
