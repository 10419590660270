export const idToTitle = (text?: string, camelized?: boolean) => {
    const replacedText = (text || '').replace(/([A-Z])/g, ' $1');
    if (camelized) {
        return replacedText.charAt(0).toUpperCase() + replacedText.slice(1);
    }
    const result = replacedText
        .split(' ')
        .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
        .join(' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export function capitalizeFirst(string: string = ''): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
