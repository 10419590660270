import React from 'react';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { CellWrapper, Percent, Value } from '../styles';

const Cell: React.FC<{ value?: number; type?: string; name?: string }> = ({ value, type, name }) => {
    return (
        <CellWrapper>
            {type === 'percent' && <Percent>{valueFormatter({ value, units: '%' })}</Percent>}
            {type !== 'percent' && !name && <Value isMain={true}>{valueFormatter({ value })}</Value>}
            {name && (
                <Value isName={true} isMain={false}>
                    {name}
                </Value>
            )}
        </CellWrapper>
    );
};

export default Cell;
