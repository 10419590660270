import { FC, useState } from 'react';
import { ITableCustomComponentProps } from '../../../../interfaces';
import useGetDecisionTypes from '../../../../../../../api/useGetDecisionTypes';
import ContextualMenu from '../../../../../../../../../components/ContextualMenu/ContextualMenu';
import { MenuItem, MenuList } from '@fluentui/react-components';
import { menuStyles } from './styles';
import { IDecisionType } from '../../../../../../../interfaces';
import { useDispatch } from 'react-redux';
import { batchDecisionsTableUpdate } from '../../../../reducer';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';

const DecisionTypesSelect: FC<ITableCustomComponentProps> = (props) => {
    const { data } = useGetDecisionTypes();
    const [isContextualMenuOpen, setIsContextualMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const closeContextualMenu = () => {
        setIsContextualMenuOpen(false);
    };

    const openContextualMenu = () => {
        setIsContextualMenuOpen(true);
    };

    const onDecisionTypeChange = (data: IDecisionType) => () => {
        const newData = {
            value: String(data.id),
            cell: props.cell,
            row: props.row,
            col: props.col,
        };
        dispatch(batchDecisionsTableUpdate([newData]));
    };

    const decisionType = data?.find((item) => {
        return item.id === Number(props.cell.value);
    });

    return (
        <span onClick={openContextualMenu}>
            <ContextualMenu
                contextualMenuBody={
                    <MenuList style={menuStyles}>
                        {data?.map((item) => (
                            <MenuItem onClick={onDecisionTypeChange(item)} key={item.color}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                }
                isContextualMenuOpen={isContextualMenuOpen}
                closeContextualMenu={closeContextualMenu}
            >
                <div>
                    {decisionType?.name ||
                        (props.cell.value ? `${t('Unknown decision type')}: ${props.cell.value}` : '')}
                </div>
            </ContextualMenu>
        </span>
    );
};

export default DecisionTypesSelect;
