import { TAB_LIST_ITEMS } from '../../../../constants/constants';
import TabList from '../../../../../../../components/TabList/TabList';
import { TabListItemIds } from '../../../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values, storeSelectedTab } from '../../reducer';

/**
 * Компонент для отображения пивота для виджета
 */
const WidgetTabList = () => {
    const { selectedTab } = useSelector(Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values);
    const dispatch = useDispatch();

    const tabListSelectHandler = (id: TabListItemIds) => {
        dispatch(storeSelectedTab(id));
    };

    return (
        <TabList
            selectHandler={tabListSelectHandler}
            widgetName={'SalesOfTenants'}
            selectedValue={selectedTab}
            options={TAB_LIST_ITEMS}
        />
    );
};

export default WidgetTabList;
