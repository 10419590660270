import { FC, useContext } from 'react';
import { EventsManagementContext } from '../../../../../../context';
import { Item, Wrapper } from './styles';
import useTranslation from '../../../../../../../../../../../hooks/useTranslation/useTranslation';

interface IProps {
    /**
     * Функция для изменения типа события
     */
    onTypeChange: (value: number) => void;
}

/**
 * Компонент для отображения тела контекстного для отображения доступных типов событий
 */
const ContextualMenuBody: FC<IProps> = ({ onTypeChange }) => {
    const context = useContext(EventsManagementContext);
    const { t } = useTranslation(['translationEvents']);

    const onClick = (value: number) => () => {
        onTypeChange(value);
    };

    return (
        <Wrapper>
            {context?.eventTypes &&
                context.eventTypes.map((eventType) => (
                    <Item key={eventType.id} onClick={onClick(eventType.id)}>
                        {t(eventType.name)}
                    </Item>
                ))}
        </Wrapper>
    );
};

export default ContextualMenuBody;
