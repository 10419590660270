import { AxisLabelsFormatterContextObject, Series, TooltipFormatterContextObject } from 'highcharts';
import { IMetric, IReportingObject, TLang, TMetricResponse } from '../../../../General.interfaces';
import datetimeFormatHelper from '../../../../tools/Strings/datetimeFormatHelper';
import { ISelectedOption } from '../../../Selects/Select/interfaces';
import { IDataAdapterResponse, IViewSettings, TChartType } from '../interfaces';
import { TFunction } from 'i18next';
import { isUndefined, omit, orderBy, uniqBy } from 'lodash';
import { IGetObjNameByIdArgs } from '../../../../hooks/useObjectTranslation';
import { theme } from 'src/theme';
import getSeriesTickInterval from '../tools/series/getSeriesTickInterval';
import { IEvent, IEventType } from '../../../../Chapters/Events/interfaces';
import { renderToString } from 'react-dom/server';
import DynamicsTooltip from '../components/DynamicsTooltip/DynamicsTooltip';
import generateFinalSeries from '../tools/series/generateFinalSeries';
import generateFinalPlotBands from '../tools/plotBands/generateFinalPlotBands';
import { DYNAMICS_DEFAULT_PLOT_OPTIONS_CONFIG } from '../components/Dynamics/constants/constants';

export interface IArgs {
    /** Ответ от сервера */
    response: TMetricResponse[];
    /** Тип графика */
    chartType: TChartType;
    /** Детализация графика */
    detail: ISelectedOption;
    /** Все метрики */
    allMetrics: IMetric[];
    /** Язык приложения */
    lang: TLang;
    /** Функция перевода */
    t: TFunction;
    /** Функция для корректного перевода объектов */
    getObjNameById: (args: IGetObjNameByIdArgs) => string;
    /** Настройки отображения */
    viewSettings?: IViewSettings;
    /** События */
    events?: IEvent[];
    /** Типы событий */
    eventTypes?: IEventType[];
    /** Ответ от сервера по метрикам погоды */
    weatherResponse?: TMetricResponse[];
    /** Все переданные отчетные объекты */
    reportingObjects: IReportingObject[];
    /** Скрытые серии */
    hiddenSeries: string[];
    /** Флаг для сетевой версии */
    isNetwork?: boolean;
    /** Функция для изменения скрытых серий */
    onSeriesHide: (id: string) => void;
}

const dataAdapter = (args: IArgs): IDataAdapterResponse => {
    const finalSeries = generateFinalSeries(omit(args, ['events', 'eventTypes']));

    const finalPlotBands = generateFinalPlotBands({
        ...omit(args, ['getObjNameById', 'weatherResponse', 'allMetrics', 'chartType']),
        series: finalSeries,
    });

    const xAxisLabelsFormatter = function (this: AxisLabelsFormatterContextObject) {
        return datetimeFormatHelper({
            selectedChartDetailing: { ...args.detail, id: String(args.detail.id) },
            dateInMillis: this.value as number,
            isCategory: true,
            lang: args.lang,
            t: args.t,
        });
    };

    return {
        chartOptions: {
            options: {
                time: {
                    useUTC: false,
                },
                chart: {
                    height: undefined,
                },
                series: finalSeries,
                tooltip: {
                    outside: false,
                    formatter: function (this: TooltipFormatterContextObject) {
                        return renderToString(<DynamicsTooltip data={this} />);
                    },
                    ...args.viewSettings?.additionalChartOptions?.options?.tooltip,
                },
                xAxis: {
                    labels: {
                        formatter: xAxisLabelsFormatter,
                        style: {
                            fontSize: '12px',
                        },
                    },
                    crosshair: {
                        dashStyle: 'Dash',
                        color: '#babbbc',
                        width: 1,
                    },
                    lineWidth: 0,
                    margin: 0,
                    title: { text: null },
                    type: 'datetime',
                    tickInterval: getSeriesTickInterval({ series: finalSeries }),
                    plotBands: finalPlotBands,
                    ...args.viewSettings?.additionalChartOptions?.options?.xAxis,
                },
                yAxis: finalSeries.every((item) => !isUndefined(item.yAxis))
                    ? orderBy(
                          uniqBy(finalSeries, (item) => item.yAxis),
                          (item) => item.yAxis,
                          'asc',
                      ).map((item, index) => {
                          return {
                              labels: { style: { color: (item.color as string) || theme.seriesColors[index % theme.seriesColors.length] } },
                              opposite: index % 2 === 0 ? false : true,
                              title: { text: null },
                              softMin: 0,
                              ...item.yAxisData,
                          };
                      })
                    : { title: { text: null }, softMin: 0 },
                plotOptions: {
                    ...DYNAMICS_DEFAULT_PLOT_OPTIONS_CONFIG,
                    series: {
                        ...DYNAMICS_DEFAULT_PLOT_OPTIONS_CONFIG.series,
                        events: {
                            legendItemClick: function (this: Series) {
                                if (args.chartType === 'objects' && args.viewSettings?.showWeather && this.options.id) {
                                    args.onSeriesHide(this.options.id);
                                }
                            },
                        },
                    },
                },
                ...omit(args.viewSettings?.additionalChartOptions?.options || {}, ['xAxis', 'tooltip']),
            },
            ...omit(args.viewSettings?.additionalChartOptions || {}, ['options']),
        },
    };
};

export default dataAdapter;
