import getFilterInstance from '../../../../hooks/dataObjectsSelectFilters/Filters/tools/getFilterInstance';
import { cloneDeep } from 'lodash';
import {
    IDataObjectsFloorFilterState,
    IDataObjectsPasswayTypeFilterState,
    IDataObjectsSelectFilter,
    TDataObjectsFilterTypes,
} from '../../../../hooks/dataObjectsSelectFilters/interfaces';
import {
    IMsDataObject,
    IPassway2DataObjRow,
    IPeriod,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2PlaceRelation,
} from '../../../../General.interfaces';
import { DataObjectsFloorsFilter } from '../../../../hooks/dataObjectsSelectFilters/Filters/DataObjectsFloorsFilter/DataObjectsFloorsFilter';
import { DataObjectsPasswayType } from '../../../../hooks/dataObjectsSelectFilters/Filters/DataObjectsPasswayType/DataObjectsPasswayType';
import { TFunction } from 'i18next';
import { DataObjectsTenantFilter } from '../../../../hooks/dataObjectsSelectFilters/Filters/DataObjectsTenantFilter/DataObjectsTenantFilter';
import { IDataObjectsTenantFilterState } from '../../../../hooks/dataObjectsSelectFilters/Filters/DataObjectsTenantFilter/interfaces';

interface IArgs {
    /** Объект отчетных объектов, где ключ это его тип */
    reportingObjectsByType: { [reportingObjectType: string]: IReportingObject[] };
    /** Все активные фильтры */
    activeFilters: IDataObjectsSelectFilter[];
    /** Все объекты из структур */
    dataObjects: IMsDataObject[] | undefined;
    /** Основной период */
    mainPeriod: IPeriod | undefined;
    /** Интервалы основного периода */
    mainDateRanges: IPeriod[] | undefined;
    /** Связи арендаторов и этажей */
    tenant2FloorByTenantId: { [tenantId: string]: ITenant2FloorRelation[] };
    /** Связи арендаторов и помещений */
    tenant2PlaceByTenantId: { [tenantId: string]: ITenant2PlaceRelation[] };
    /** Связи проходов со всеми объектами */
    passway2DataObj: IPassway2DataObjRow[] | undefined;
    /** Объект отчетных объектов где ключ это (type:marker) */
    reportingObjectsByTypeAndMarker: { [typeAndMarker: string]: IReportingObject };
    /** Фильтры, которые были переданы в селект (По ним и нужно фильтровать) */
    dataObjectFilters: TDataObjectsFilterTypes[];
    /** Функция перевода*/
    t: TFunction;
}

/**
 * Функция для фильтрации отчетных объектов
 */
const filterObjects = (args: IArgs): { [reportingObjectType: string]: IReportingObject[] } => {
    const {
        reportingObjectsByTypeAndMarker,
        reportingObjectsByType,
        tenant2FloorByTenantId,
        tenant2PlaceByTenantId,
        dataObjectFilters,
        passway2DataObj,
        mainDateRanges,
        activeFilters,
        dataObjects,
        mainPeriod,
        t,
    } = args;
    let result = cloneDeep(reportingObjectsByType);

    activeFilters.forEach((filter) => {
        const filterInstance = getFilterInstance(filter.filter_type);

        if (filterInstance && dataObjectFilters.includes(filterInstance.name)) {
            if (filterInstance instanceof DataObjectsFloorsFilter) {
                result = filterInstance.filterDataObjects({
                    filterState: filter.filter_state as IDataObjectsFloorFilterState,
                    tenant2FloorByTenantId,
                    reportingObjectsByType,
                    mainDateRanges,
                    dataObjects,
                    mainPeriod,
                });
            }

            if (filterInstance instanceof DataObjectsPasswayType) {
                result = filterInstance.filterDataObjects({
                    filterState: filter.filter_state as IDataObjectsPasswayTypeFilterState,
                    reportingObjectsByTypeAndMarker,
                    reportingObjectsByType: result,
                    passway2DataObj,
                    mainDateRanges,
                    mainPeriod,
                    t,
                });
            }

            if (filterInstance instanceof DataObjectsTenantFilter) {
                result = filterInstance.filterDataObjects({
                    filterState: filter.filter_state as IDataObjectsTenantFilterState,
                    reportingObjectsByType: result,
                    tenant2PlaceByTenantId,
                    mainDateRanges,
                    mainPeriod,
                });
            }
        }
    });

    return result;
};

export default filterObjects;
