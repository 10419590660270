import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, storeExtendedDataObjectsSelectFiltersByType } from '../../General.reducer';
import { DATA_OBJECTS_FILTERS } from './Filters/constants/constants';
import { DataObjectsFloorsFilter } from './Filters/DataObjectsFloorsFilter/DataObjectsFloorsFilter';
import { DataObjectsPasswayType } from './Filters/DataObjectsPasswayType/DataObjectsPasswayType';
import getFilterInstance from './Filters/tools/getFilterInstance';
import { DataObjectsTenantFilter } from './Filters/DataObjectsTenantFilter/DataObjectsTenantFilter';

/**
 * Кастомный хук для работы с сырыми данными фильтров селекта объектов
 */
export const usePrepareData = () => {
    const {
        rawDataObjectsSelectFilters,
        cfg: { reportingObjectsByType },
        rawDataObjectsSelectFiltersFetching,
    } = useSelector(generalReducerValues);

    const dispatch = useDispatch();

    /** Получение расширенной структуры фильтров для селекта объектов */
    useEffect(() => {
        if (Object.keys(reportingObjectsByType).length && !rawDataObjectsSelectFiltersFetching) {
            const rawFiltersByType = rawDataObjectsSelectFilters.reduce((acc, value) => {
                acc[value.filter_type] = value;
                return acc;
            }, {});
            const extendedFiltersByType = DATA_OBJECTS_FILTERS.reduce((acc, value) => {
                const filterInstance = getFilterInstance(value.id);

                if (filterInstance instanceof DataObjectsFloorsFilter) {
                    acc[value.id] = filterInstance.getFilterData({
                        floors: reportingObjectsByType['floor'],
                        rawData: rawFiltersByType[value.id],
                    });
                }

                if (filterInstance instanceof DataObjectsPasswayType) {
                    acc[value.id] = filterInstance.getFilterData({
                        rawData: rawFiltersByType[value.id],
                    });
                }

                if (filterInstance instanceof DataObjectsTenantFilter) {
                    acc[value.id] = filterInstance.getFilterData({
                        rawData: rawFiltersByType[value.id],
                    });
                }
                return acc;
            }, {});
            dispatch(storeExtendedDataObjectsSelectFiltersByType(extendedFiltersByType));
        }
    }, [rawDataObjectsSelectFilters, reportingObjectsByType, rawDataObjectsSelectFiltersFetching, dispatch]);
};
