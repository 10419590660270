import { DateTime } from 'luxon';
import {
    IAdapterArgs,
    IAdapterResults,
    TTimeFreq,
    ISummaryCell,
    TOperationType,
    IMetricCell,
    TTF,
    ICellBillData,
} from '../interfaces';
import { generateDates } from '../tools/generateDates';
import { COLUMNS } from '../constants/constants';
import _ from 'lodash';
import { DS } from '../../../../constants/constants';

export const adapter = (args: IAdapterArgs): IAdapterResults => {
    const monthsStartDates = generateDates({ from: args.dates['dateFrom'], to: args.dates['dateTo'], timeFreq: 'MS' });
    // Фильтруем ответ с сервера по актуальной для локации валюте
    const filteredResults = args.response.data.results.filter((item) => item.currency === args.currencyCode);

    const getTimes = (): TTimeFreq => {
        const timeFreqs = Array.from(new Set(filteredResults.map((item) => item.time_freq)));
        if (timeFreqs.length === 0) {
            return null;
        } else if (timeFreqs.length === 1) {
            return timeFreqs[0];
        } else {
            return 'mixed';
        }
    };

    const summaryTableData: ISummaryCell[][] = args.ids
        // Формируем тело таблицы
        .map((objId) => {
            return monthsStartDates.map((startDate) => {
                const summaryCellData = filteredResults.filter((item) => {
                    return (
                        DateTime.fromISO(item.date).month === DateTime.fromISO(startDate).month &&
                        DateTime.fromISO(item.date).year === DateTime.fromISO(startDate).year &&
                        item.operation_type === 'income' &&
                        item.data_object === objId
                    );
                });
                return {
                    startDate,
                    summaryCellData,
                    isHeader: false,
                    readOnly: true,
                } as ISummaryCell;
            });
        })
        // Добавляем первый столбец с именами объектов
        .map((row, i) => {
            const objName = args.reportingObjectsById[args.ids[i]].object_name;
            return [{ value: objName, isFirstColumn: true, readOnly: true }, ...row];
        });

    // Сортируем по имени объекта
    const sorted = _.sortBy(summaryTableData, (item) => item[0].value);

    // Формируем хедер таблицы
    const headerRow: ISummaryCell[] = [
        { value: '', isHeader: true },
        ...monthsStartDates.map((startDate) => {
            return {
                value:
                    args.t(DateTime.fromISO(startDate).toFormat('LLLL')) +
                    ' ' +
                    DateTime.fromISO(startDate).toFormat('y'),
                isHeader: true,
            } as ISummaryCell;
        }),
    ];
    sorted.unshift(headerRow);

    const generateEmptyStructure = (timeFreq: TTF): IMetricCell[][] => {
        const sortedObjects = _.sortBy(args.ids, (id) => {
            const objName = args.reportingObjectsById[id].object_name;
            return objName;
        });

        const dates = generateDates({ from: args.dates['dateFrom'], to: args.dates['dateTo'], timeFreq });

        return sortedObjects.reduce((acc: IMetricCell[][], objId) => {
            const objName = args.reportingObjectsById[objId].object_name;
            const objRows: IMetricCell[][] = dates.map((date) => {
                return COLUMNS.map((item) => {
                    const data: IMetricCell = {
                        ...item,
                        isValid: true,
                        objName,
                        value: null,
                        initialValue: null,
                        data_object: objId,
                        date,
                    };
                    if (item.colId.includes(DS)) {
                        const operation_type = item.colId.split(DS)[0] as TOperationType;
                        const metric = item.colId.split(DS)[1];
                        const billData: ICellBillData = {
                            data_object: objId,
                            date,
                            operation_type,
                            time_freq: timeFreq,
                            currency: args.currencyCode,
                            bill_type: 'bill',
                        };
                        data.billData = billData;
                        data.metric = metric;
                    }
                    return data;
                });
            });
            acc.push(...objRows);
            return acc;
        }, []);
    };

    const getMetircsTableData = (timeFreq: TTF): IMetricCell[][] => {
        const rows: IMetricCell[][] = generateEmptyStructure(timeFreq).map((row) => {
            return row.map((cell) => {
                let value: string | null = cell?.[cell.colId] || null;
                if (filteredResults && cell?.['billData']?.['operation_type'] && cell?.['metric']) {
                    value = filteredResults.find((item) => {
                        return (
                            item.data_object === cell?.['billData']?.['data_object'] &&
                            item.operation_type === cell?.['billData']?.['operation_type'] &&
                            DateTime.fromISO(item.date).toISODate() ===
                                DateTime.fromISO(cell?.['billData']?.['date']).toISODate()
                        );
                    })?.[cell?.['metric']];
                }
                return {
                    ...cell,
                    value,
                    initialValue: value,
                };
            });
        });

        // Формируем хедер таблицы
        const headerRow: IMetricCell[] = COLUMNS.map((col) => {
            return {
                ...col,
                value: col.colName,
                isValid: true,
                initialValue: null,
                isHeader: true,
                data_object: 0,
                date: '',
                objName: '',
            };
        });

        rows.unshift(headerRow);
        return rows;
    };

    let metricsTableData: IMetricCell[][] = [];
    if (getTimes() !== null) {
        const timeFreq = getTimes() as TTF;
        metricsTableData = getMetircsTableData(timeFreq);
    } else {
        const timeFreq = args.userSelectedTimeFreq as TTF;
        metricsTableData = getMetircsTableData(timeFreq);
    }

    return {
        summaryTableData: sorted,
        metricsTableData,
        rawData: filteredResults,
        timeFreq: getTimes(),
    };
};
