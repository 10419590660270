import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { IMetricCell, TTF } from '../interfaces';

export const getDateRange = (data: IMetricCell[][]): { dateFrom: string; dateTo: string } => {
    const datesArr = data
        .filter((row) => row.some((cell) => cell.billData))
        .map((row) => {
            return row[0].billData?.date ?? '';
        });

    const sorted = sortBy(datesArr, (item) => DateTime.fromISO(item).toMillis());
    const dateFrom = sorted[0];
    const dateTo = sorted[sorted.length - 1];

    return { dateFrom, dateTo };
};
