import { userSettingsReducerValues, changeOptions } from '../../UserSettings/reducer';
import { generalReducerValues } from '../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { IProps } from './interfaces';
import { Label, Wrapper } from './styles';
import TextInput from '../../TextInput/TextInput';
import { useAppDispatch } from '../../../hooks/useSettings';

/**
 * Компонент универсального селетка
 */
const UniversalTextInput: React.FC<IProps> = ({
    iconType = 'chevron-down',
    type = 'text',
    min,
    labelText = ' ',
    name,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { selectedLocationId, currentModuleID } = useSelector(generalReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);

    // useEffect(() => {
    //     if (!currentOptions?.[name]?.length && options?.[0] && selectedLocationId) {
    //         dispatch(
    //             storeUniversalOptions({
    //                 selectedOptions: [options?.[0]],
    //                 locationId: selectedLocationId,
    //                 module: currentModuleID,
    //                 name,
    //             }),
    //         );
    //     }
    // }, [currentModuleID, currentOptions, dispatch, name, options, selectedLocationId]);

    const handleChange = (args: { id: number | undefined; name: string | undefined; value: string }) => {
        selectedLocationId &&
            dispatch(
                changeOptions({
                    newOptions: {
                        [name]: args.value,
                    },
                }),
            );
    };

    const value = currentOptions[name] || '';

    return (
        <Wrapper>
            <Label>{labelText}</Label>
            <TextInput outsideValue={value} handleChange={handleChange} type={type} minNumber={min} />
        </Wrapper>
    );
};

export default UniversalTextInput;
