import React from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../components/Selects/MetricsSelect/MetricsSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../components/Preset/Preset';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SummaryTable from './widgets/SummaryTable/SummaryTable';
import Info from './widgets/Info/Info';
import DynamicsPMO from './widgets/DynamicsPMO/DynamicsPMO';
import Reach from './widgets/Reach/Reach';
import Reference from './widgets/Reference/Reference';
import { useTranslation } from 'react-i18next';
import { ISettings } from '../../../components/UserSettings/interfaces';
import IntersectionsWithTenants from './widgets/IntersectionsWithTenants/IntersectionsWithTenants';
import EventsCarousel from '../../Events/Events/widgets/EventsCarousel/EventsCarousel';
import { Leasing_TenantOverview_Module_Reducer_Values } from './reducer';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';

const TenantOverview: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const validOptions = [t('Tenants')];

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ComparePeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ObjectsSelect
                            mode="single"
                            validOptions={validOptions}
                            dataObjectFilters={['data_objects_tenant']}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <MetricsSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'mainPeriod',
                                'comparePeriods',
                                'selectedMetrics',
                                'selectedReportingObjectsIds' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                <Info />
                <SummaryTable />
                <EventsCarousel moduleName={moduleName} />
                <DynamicsPMO />
                <Reference />
                <Reach />
                {user?.permissions.includes('core/data-source | shp | api/read') && <IntersectionsWithTenants />}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default TenantOverview;
