import { useRef, useEffect, RefObject } from 'react';

export function useClickOutside(refs: RefObject<HTMLElement>[], onClickOutside: Function) {
    const handlerRef = useRef(onClickOutside);
    handlerRef.current = onClickOutside;

    useEffect(() => {
        const handleStart = (event: MouseEvent) => {
            const target = event.target;
            if (target instanceof Node) {
                const clickedOutside = refs.every((ref) => ref.current && !ref.current.contains(target));
                if (clickedOutside && handlerRef.current) {
                    handlerRef.current(event);
                }
            }
        };

        document.addEventListener('touchstart', handleStart);
        document.addEventListener('mousedown', handleStart);

        return () => {
            document.removeEventListener('touchstart', handleStart);
            document.removeEventListener('mousedown', handleStart);
        };
    }, []);
}
