import { FC, useMemo } from 'react';
import Stack from '../../../../../Stack/Stack';
import { IDefaultSelectProps, TWidgetType } from '../../interfaces';
import MetricsSelect from '../../../../../Selects/MetricsSelect/MetricsSelect';
import { IChangeOptionsArgs } from '../../../../../UserSettings/interfaces';
import { DS } from '../../../../../../constants/constants';
import DetailSelect from '../DetailSelect/DetailSelect';
import UniversalSelect from '../../../../../Selects/UniversalSelect/UniversalSelect';
import { useSelector } from 'react-redux';
import { userSettingsReducerValues } from '../../../../../UserSettings/reducer';

const OnBoardSelects: FC<IDefaultSelectProps> = (props) => {
    const { settings } = useSelector(userSettingsReducerValues);

    const widgetType = props.widgetConfig?.type?.split(DS)?.[1] as TWidgetType;

    const widgetTypesWithSingleMetricSelect = ['objectsLocations', 'periodsLocations', 'objectsReportingObjects'];
    const widgetTypesWithDetailsSelect = ['objectsLocations', 'periodsLocations', 'objectsReportingObjects'];
    const widgetTypesWithLocationsSelect = ['periodsLocations'];

    const locationSelectOptions = useMemo(() => {
        const locations = settings?.['global']?.pls;
        if (locations?.length) {
            return locations.map((location) => ({ id: location.id, text: location.text }));
        }
        return [];
    }, [settings]);

    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        props.updateWidget('options', args.newOptions);
    };

    const onMetricsChange = (args: IChangeOptionsArgs) => {
        updateWidgetOptions(args);
    };

    return (
        <>
            <Stack gap={10} flexWrap="wrap">
                {widgetTypesWithLocationsSelect.includes(widgetType) && (
                    <UniversalSelect
                        localCurrentOptions={{ ...props.widgetConfig?.options, currentModuleID: props.moduleId } || {}}
                        changeOptions={updateWidgetOptions}
                        options={locationSelectOptions}
                        name="selectedLocation"
                        labelText="Location"
                        showFilter={true}
                        mode="single"
                    />
                )}

                {widgetTypesWithSingleMetricSelect.includes(widgetType) && (
                    <MetricsSelect
                        disabled={props.widgetConfig?.visual.useMetricFrom?.[0].id === 'fromPanel'}
                        localCurrentOptions={props.widgetConfig?.options}
                        changeOptions={onMetricsChange}
                        name={'metrics'}
                        maxSelected={1}
                    />
                )}

                {widgetTypesWithDetailsSelect.includes(widgetType) && <DetailSelect {...props} />}
            </Stack>
        </>
    );
};

export default OnBoardSelects;
