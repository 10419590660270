import React, { useEffect, useState } from 'react';
import { IWeek } from '../../configurations';
import { DateTime, Interval } from 'luxon';
import { DayCell, Month, MonthHeader, MonthName, MonthWrapper, Week, WeekDay, WeekNumber } from './styles';
import { bgColor, monthNames, weekDayNames } from '../../const';
import { arrangeDaysToMonthGrid, getRanges } from '../../core';
import { IProps } from './interfaces';
import { useTranslation } from 'react-i18next';

const MonthsGrid: React.FC<IProps> = ({
    allDaysOfCalendar,
    firstDateDidChanged,
    secondDateDidChanged,
    oneDayOnly,
    showFutureDates,
    firstSelectedDate,
    secondSelectedDate,
}) => {
    const [dateUnderCursor, setDateUnderCursor] = useState<DateTime | null>(null);
    const [weekUnderCursor, setWeekUnderCursor] = useState<number | null>(null);
    const now = DateTime.now();
    const { t } = useTranslation();

    useEffect(() => {
        if (secondSelectedDate) {
            setDateUnderCursor(secondSelectedDate);
        }
    }, [secondSelectedDate]);

    const weekDays = (
        <Week>
            {weekDayNames.map((item) => {
                return <WeekDay key={`${item['en']}`}>{t(item['en'])?.toUpperCase()}</WeekDay>;
            })}
        </Week>
    );

    const onDayCellClick = (data: { day: DateTime } | null) => () => {
        if (data?.day) {
            if (!oneDayOnly) {
                if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                    firstDateDidChanged(data.day);
                    secondDateDidChanged(null);
                    setDateUnderCursor(null);
                } else if (firstSelectedDate && !secondSelectedDate) {
                    secondDateDidChanged(data.day);
                }
            } else {
                if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                    firstDateDidChanged(data.day);
                    secondDateDidChanged(data.day);
                    setDateUnderCursor(null);
                } else if (firstSelectedDate && !secondSelectedDate) {
                    secondDateDidChanged(data.day);
                }
            }
        }
    };

    const onWeekNumberClick = (week: IWeek) => () => {
        const someDay = week.week?.filter((item) => item.data)[0].data?.day;
        if (someDay) {
            let firstWeekDay = someDay?.startOf('week');
            let lastWeekDay = someDay?.endOf('week');
            const range = Interval.fromDateTimes(firstWeekDay, lastWeekDay);
            const filteredWeek = Array.from(getRanges(range, 'day'))?.filter((day) =>
                showFutureDates ? true : day.valueOf() < now.valueOf(),
            );
            lastWeekDay = filteredWeek.pop() as DateTime;
            setDateUnderCursor(lastWeekDay);
            firstDateDidChanged(firstWeekDay);
            secondDateDidChanged(lastWeekDay);
        }
    };

    const onDayCellMouseEnter = (data: { day: DateTime } | null) => () => {
        if (firstSelectedDate && secondSelectedDate) return;
        if (data?.day) {
            setDateUnderCursor(data.day);
        }
    };

    const onWeekNumberMouseEnterLeave = (weekNumber: number | null) => () => {
        setWeekUnderCursor(() => weekNumber);
    };

    const grid = allDaysOfCalendar.map((month, i) => {
        const weeks = arrangeDaysToMonthGrid(month).map((week, j) => {
            const days = week.week.map((day, k) => {
                const disabled = day.data ? (showFutureDates ? false : day.data.day > now) : true;
                const isLastUnderCusor =
                    (day.data !== null &&
                        (day?.data?.day.valueOf() === dateUnderCursor?.valueOf() ||
                            (secondSelectedDate &&
                                day?.data?.day.valueOf() === secondSelectedDate?.startOf('day').valueOf()))) ||
                    false;

                const selected =
                    day.data !== null &&
                    (firstSelectedDate?.valueOf() === day.data.day.valueOf() ||
                        secondSelectedDate?.valueOf() === day.data.day.valueOf());
                let bg = 'transparent';
                if (day.data && firstSelectedDate && dateUnderCursor) {
                    if (
                        day.data.day.valueOf() === firstSelectedDate?.valueOf() &&
                        firstSelectedDate! < dateUnderCursor!
                    ) {
                        bg = `linear-gradient(90deg,transparent 50%,${bgColor} 0)`;
                    } else if (
                        day.data.day.valueOf() === firstSelectedDate?.valueOf() &&
                        firstSelectedDate! > dateUnderCursor!
                    ) {
                        bg = `linear-gradient(-90deg,transparent 50%,${bgColor} 0)`;
                    } else if (
                        (day.data.day.valueOf() > firstSelectedDate?.valueOf() &&
                            day.data.day.valueOf() < dateUnderCursor?.valueOf()) ||
                        (day.data.day.valueOf() < firstSelectedDate?.valueOf() &&
                            day.data.day.valueOf() > dateUnderCursor?.valueOf())
                    ) {
                        bg = `${bgColor}`;
                    } else if (day.data.day.valueOf() > firstSelectedDate?.valueOf() && isLastUnderCusor) {
                        bg = `linear-gradient(-90deg,transparent 50%,${bgColor} 0)`;
                    } else if (day.data.day.valueOf() < firstSelectedDate?.valueOf() && isLastUnderCusor) {
                        bg = `linear-gradient(90deg,transparent 50%,${bgColor} 0)`;
                    }
                }
                return (
                    <DayCell
                        key={`day${i}++${j}++${k}`}
                        disabled={disabled}
                        bg={bg}
                        selected={selected}
                        isLastUnderCusor={isLastUnderCusor}
                        onClick={onDayCellClick(day.data)}
                        data-test-id={`day${i}++${j}++${k}`}
                        onMouseEnter={onDayCellMouseEnter(day.data)}
                    >
                        <span>{day.data?.day.day}</span>
                    </DayCell>
                );
            });

            const weekDisabled = showFutureDates ? false : week.week.every((d) => d.data?.day! > now || !d.data);
            const weekInFocus = week.weekNumber === weekUnderCursor && weekUnderCursor !== null && !weekDisabled;

            if (!oneDayOnly) {
                days.unshift(
                    <WeekNumber
                        key={`weeknumber${i}++${j}++${week.weekNumber}`}
                        onMouseEnter={onWeekNumberMouseEnterLeave(week.weekNumber)}
                        onMouseLeave={onWeekNumberMouseEnterLeave(null)}
                        weekDisabled={weekDisabled}
                        onClick={onWeekNumberClick(week as IWeek)}
                    >
                        {week.weekNumber && <span>{week.weekNumber}</span>}
                    </WeekNumber>,
                );
            } else {
                days.unshift(
                    <WeekNumber
                        key={`weeknumber${i}++${j}++${week.weekNumber}`}
                        weekDisabled={weekDisabled}
                    ></WeekNumber>,
                );
            }
            return (
                <Week key={`week${i}++${j}`} weekInFocus={weekInFocus} weekDisabled={weekDisabled}>
                    {days}
                </Week>
            );
        });
        const monthName = `${t(monthNames[month[0].day.month]['en'])} ${month[0].day.year}`;

        return (
            <MonthWrapper index={i} key={`${monthName}00${i}`}>
                <MonthHeader>
                    <MonthName>{monthName}</MonthName>
                </MonthHeader>
                <Month key={`month${i}`}>
                    {weekDays}
                    {weeks}
                </Month>
            </MonthWrapper>
        );
    });

    return <>{grid}</>;
};

export default MonthsGrid;
