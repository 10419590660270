import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileInboundWidth } from 'src/theme';

import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import Rating from '../../../../../components/CommonWidgets/Rating/Rating';
import usePeriods from '../../../../../hooks/common/usePeriods';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import { generalReducerValues } from '../../../../../General.reducer';
import { ZONES_WORD } from '../../../../../constants/constants';
import { getObjSectionName } from '../../../../../tools/Strings/getObjSectionName';
import { IDataAdapterResponseItem } from '../../../../../components/CommonWidgets/Rating/interfaces';
import { queryClient } from '../../../../..';
import { RATING_QUERY_QEY } from '../../../../../components/CommonWidgets/Rating/constants/constants';

import { Performance_Dashboard_Traffic_Widget_Reducer_Values, storeRatingType } from './reducer';
import getAvailableIds from './tools/getAvailableIds';
import usePrepareData from './hooks/usePrepareData';
import { tabListItems } from './constants/constants';
import { Title } from './styles/styles';
import Footer from './components/Footer/Footer';
import Selects from './components/Selects/Selects';
import './styles/styles.scss';

/**
 * Компонент для отображения виджета "Эффективность входов"
 */
const Traffic: React.FC = () => {
    const { ratingType, selectedZoneId, selectedFloor } = useSelector(
        Performance_Dashboard_Traffic_Widget_Reducer_Values,
    );
    const {
        cfg: { reportingObjectsByType, reportingObjectsById },
        mainAreaSize: { width },
        currentModuleID,
        structures,
    } = useSelector(generalReducerValues);

    const [availablePasswaysIds, setAvailablePasswaysIds] = useState<number[]>([]);
    const [itemsToShow, setItemsToShow] = useState<number | 'all'>(10);
    const { comparePeriods, mainPeriod } = usePeriods(currentModuleID);

    const isFetching = useIsFetching({ queryKey: [RATING_QUERY_QEY, currentModuleID, 'Traffic by entrances'] });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    usePrepareData();

    useEffect(() => {
        if (mainPeriod && structures?.relations_passway2dataobj?.length) {
            const ids = getAvailableIds({
                passway2DataObjRelations: structures.relations_passway2dataobj,
                mainPeriod: mainPeriod.period,
                reportingObjectsById,
                selectedZoneId,
                selectedFloor,
                ratingType,
            });

            setAvailablePasswaysIds(ids);
        }
    }, [
        structures?.relations_passway2dataobj,
        reportingObjectsById,
        selectedZoneId,
        selectedFloor,
        mainPeriod,
        ratingType,
    ]);

    const isMobile = isMobileInboundWidth(width);

    const tabList = useMemo(() => {
        const existingTypes = Object.keys(reportingObjectsByType).map((key) => key);
        const zoneItems = existingTypes
            .filter((item) => item.includes(ZONES_WORD))
            .map((item) => ({
                id: item,
                text: getObjSectionName({ t, reportingObject: reportingObjectsByType[item][0] }),
            }));
        const tabListSelectHandler = (id: string) => {
            dispatch(storeRatingType(id));
        };
        return (
            <TabList
                widgetName="TrafficByEntrance"
                selectHandler={tabListSelectHandler}
                selectedValue={ratingType}
                options={tabListItems
                    .concat(zoneItems)
                    ?.filter((item) => existingTypes.includes(item.id) || item.id === 'all')}
            />
        );
    }, [dispatch, ratingType, reportingObjectsByType, t]);

    const dataFilter = (item: IDataAdapterResponseItem): boolean => {
        return availablePasswaysIds.includes(item.id);
    };

    const reloadWIdget = () => {
        queryClient.invalidateQueries({ queryKey: [RATING_QUERY_QEY, currentModuleID, 'Traffic by entrances'] });
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title>{t('Traffic by entrances')}</Title>
                    <WidgetAdditionalControls
                        reloadHandlerNoDispatch={reloadWIdget}
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName="Traffic by entrances"
                    />
                </WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            <Selects />
            {comparePeriods?.length && mainPeriod && (
                <Rating
                    reportingObjects={reportingObjectsByType?.['passway'] || []}
                    comparePeriods={comparePeriods.map((item) => item.period)}
                    metric={'fpc_sum_pass_count_in_wh'}
                    widgetId={'Traffic by entrances'}
                    mainPeriod={mainPeriod.period}
                    moduleId={currentModuleID}
                    viewSettings={{
                        itemsToShow: itemsToShow === 'all' ? reportingObjectsByType['passway'].length : itemsToShow,
                        mainValuePercentOfTotalStyles: { fontWeight: 700 },
                        compareValueStyles: { fontWeight: 700 },
                        showLoadingWrapperBorder: true,
                        showCompareValueArrow: true,
                        showPercentOfTotal: true,
                        showMainValueUnits: true,
                        showAllValues: true,
                        dataFilter,
                    }}
                />
            )}

            {!isFetching && <Footer testId={'Traffic by entrances'} setItemsToShow={setItemsToShow} />}
        </WidgetWrapper>
    );
};

export default Traffic;
