import { generalReducerValues } from 'src/General.reducer';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { CookiesPageWrapper, Header } from './styles';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const CookiesPage: React.FC = () => {
    const { t } = useTranslation('policies');
    const { urlsByServices } = useSelector(generalReducerValues);
    const cabinetUrl: string = urlsByServices?.['app/client'].CABINET_URL || '';
    const CabinetLink = <NavLink to={'/'}>{cabinetUrl}</NavLink>;
    return (
        <CookiesPageWrapper>
            <Header>{t('PRIVACY POLICY')}</Header>
            <p>
                {`${t('By using the website')} `}
                {CabinetLink}
                {` ${t('the User unconditionally agrees')}`}
            </p>
            <h4> {`${t('1. GENERAL PROVISIONS')}`}</h4>
            <p>{`${t('COOKIES_POLICY_1_1')}`}</p>
            <p>{`${t('COOKIES_POLICY_1_2')}`}</p>
            <p>{`${t('COOKIES_POLICY_1_3')}`}</p>
            <p>{`${t('COOKIES_POLICY_1_4')}`}</p>
            <p>
                {`${t('This Privacy Policy applies only to the Site')} `}
                {CabinetLink}
                {`. ${t('The Site')} `}
                {CabinetLink}
                {` ${t(
                    'does not control or take responsibility for third-party sites that the User may access through links on the Site',
                )} `}
                {CabinetLink}
            </p>
            <h4> {`${t('2. PURPOSES OF PROCESSING USER DATA')}`}</h4>
            <p>
                {`${t('COOKIES_POLICY_2_1')}`}
                <ul>
                    <li>{`${t('Ensuring the functionality and improving the quality of the Site;')}`}</li>
                    <li>{`${t('Maintaining statistics about Users;')}`}</li>
                    <li>{`${t('Storing personal preferences and User settings;')}`}</li>
                    <li>{`${t('Tracking the status of User access sessions;')}`}</li>
                    <li>{`${t('Improving the products and services offered on the Site.')}`}</li>
                </ul>
            </p>
            <p>{`${t('COOKIES_POLICY_2_2')}`}</p>
            <p>{`${t('COOKIES_POLICY_2_3')}`}</p>
            <h4> {`${t('3. USER DATA PROCESSING AND TRANSMISSION TO THIRD PARTIES')}`}</h4>
            <p>{`${t('COOKIES_POLICY_3_1')}`}</p>
            <p>
                {`${t('COOKIES_POLICY_3_2')}`}
                <ul>
                    <li>{`${t('COOKIES_POLICY_3_2_1')}`}</li>
                    <li>{`${t('COOKIES_POLICY_3_2_2')}`}</li>
                    <li>{`${t('COOKIES_POLICY_3_2_3')}`}</li>
                </ul>
            </p>
            <p>{`${t('COOKIES_POLICY_3_3')}`}</p>
            <p>{`${t('COOKIES_POLICY_3_4')}`}</p>
            <p>{`${t('COOKIES_POLICY_3_5')}`}</p>
            <h4> {`${t('4. ADDITIONAL TERMS')}`}</h4>
            <p>{`${t('COOKIES_POLICY_4_1')}`}</p>
            <p>{`${t('COOKIES_POLICY_4_2')}`}</p>
        </CookiesPageWrapper>
    );
};

export default CookiesPage;
