import { Heart24Regular } from '@fluentui/react-icons';

import { useTranslation } from 'react-i18next';
import Tooltip from '../../../Tooltip/Tooltip';
import IconButton from '../../../IconButton/IconButton';
import { Popover, PopoverProps, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import Feedback from '../../../Feedback/Feedback';
import React from 'react';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';

interface IProps {
    // reloadHandler: () => AnyAction;
    widgetName: string;
    triggerContent?: JSX.Element;
    showTooltip?: boolean;
}

const FeedbackButton: React.FC<IProps> = ({ widgetName, triggerContent, showTooltip = true }) => {
    const [open, setOpen] = React.useState(false);

    const { t } = useTranslation();
    const sendAnalytics = useSendSimpleAnalytics();
    const onFeedbackButtonClick = () => {
        setOpen(() => true);
    };
    const onClick = (rating: string) => {
        sendAnalytics(`${widgetName} >> rate_widget >> ${rating}`);
        setOpen(() => false);
    };

    const handleOpenChange: PopoverProps['onOpenChange'] = (e, data) => setOpen(data.open || false);

    return (
        <Popover withArrow open={open} closeOnScroll={true} onOpenChange={handleOpenChange}>
            <Tooltip showTooltip={showTooltip} content={t('Feedback')}>
                <PopoverTrigger disableButtonEnhancement>
                    {triggerContent ? (
                        <div data-test-id={`FEEDBACK-${widgetName}`} onClick={onFeedbackButtonClick}>
                            {triggerContent}
                        </div>
                    ) : (
                        <IconButton
                            onClick={onFeedbackButtonClick}
                            icon={<Heart24Regular />}
                            testId={`FEEDBACK-${widgetName}`}
                        />
                    )}
                </PopoverTrigger>
            </Tooltip>

            <PopoverSurface style={{ borderRadius: 10 }}>
                <Feedback onClick={onClick} />
            </PopoverSurface>
        </Popover>
    );
};

export default FeedbackButton;
