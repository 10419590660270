import { IExtendedTenant, ITableSortData, ITenantsReducerState, TRawMetricsData } from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store';

const initialState: ITenantsReducerState = {
    moduleName: 'Leasing:Tenants',
    extendedTenantsById: null,
    extendedTenants: null,
    rawMetricsData: null,
    tableSortData: null,
    idsOfTenants: null,
    dataRefetchObject: {},
    tableItemsToShow: 15,
};

const Leasing_Tenants_Tenants_Widget_Reducer = createSlice({
    name: 'Leasing_Tenants_Tenants_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение id всех арендаторов
         */
        storeIdsOfTenants: (state, action: PayloadAction<null | number[]>) => {
            state.idsOfTenants = action.payload;
        },

        /**
         * Сохранение сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Сохранение расширенныйх данных арендаторов
         */
        storeExtendedTenants: (state, action: PayloadAction<null | IExtendedTenant[]>) => {
            if (action.payload) {
                const extendedTenantsById = action.payload.reduce((acc, value) => {
                    acc[value.tenantData.id] = value;
                    return acc;
                }, {});
                state.extendedTenantsById = extendedTenantsById;
            } else {
                state.extendedTenantsById = null;
            }
            state.extendedTenants = action.payload;
        },

        /**
         * Сохранение данных для сортировки таблицы
         */
        storeTableSortData: (state, action: PayloadAction<null | ITableSortData>) => {
            state.tableSortData = action.payload;
        },

        /**
         * Изменение кол-во отображаемых элементов списка
         */
        storeTableItemsToShow: (state, action: PayloadAction<number | 'all'>) => {
            state.tableItemsToShow = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storeIdsOfTenants,
    storeRawMetricsData,
    storeTableItemsToShow,
    storeExtendedTenants,
    storeTableSortData,
    reloadWidget,
} = Leasing_Tenants_Tenants_Widget_Reducer.actions;

export const Leasing_Tenants_Tenants_Widget_Reducer_Values = (state: RootState) =>
    state.Leasing_Tenants_Tenants_Widget_Reducer;

export default Leasing_Tenants_Tenants_Widget_Reducer.reducer;
