import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from '../../../reducer';
import axios from 'axios';
import { IBgReport } from '../../../../../../../../General.interfaces';
import bgReportsTableDataAdapter from '../dataAdapters/bgReportsTableDataAdapter';
import { GET_ALL_BG_REPORTS_RQ_KEY } from '../../../../../../../../constants/constants';
import { DateTime } from 'luxon';
import { getHeaders } from '../../../../../../../../tools/API/getHeaders';

/**
 * Кастомный хук для получения данных с сервера
 */
const useFetchData = () => {
    const { keyWord, bgReportType } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);
    const {
        token,
        urlsByServices,
        selectedLocationId,
        lang,
        backgroundReportTypesById,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const bgReportUrl = useMemo(() => {
        if (urlsByServices) {
            return urlsByServices['app/app-backend'].BACKGROUND_REPORTS_URL;
        }
        return null;
    }, [urlsByServices]);

    const created_to = DateTime.now().plus({ days: 1 }).toISODate();
    const created_from = DateTime.now().minus({ months: 1 }).toISODate();

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        if (token && bgReportUrl && selectedLocationId) {
            return [
                [GET_ALL_BG_REPORTS_RQ_KEY, token, bgReportType, keyWord, selectedLocationId, created_from, created_to],
                () => {
                    return axios
                        .get<IBgReport[]>(
                            // `${bgReportUrl}?report_type=${bgReportType}&pl_id=${selectedLocationId}&date_from=${mainDateRange.period.dateFrom}&date_to=${mainDateRange.period.dateTo}`,
                            `${bgReportUrl}?report_type=${bgReportType}&pl_id=${selectedLocationId}&created_from=${created_from}&created_to=${created_to}`,
                            {
                                headers: getHeaders(token),
                            },
                        )
                        .then((response) => response.data);
                },
                (response: IBgReport[]) =>
                    bgReportsTableDataAdapter({
                        reports: response,
                        lang,
                        backgroundReportTypesById,
                        reportingObjectsById,
                    }),
            ];
        }
        return [[]];
    }, [
        token,
        bgReportUrl,
        selectedLocationId,
        bgReportType,
        keyWord,
        created_from,
        created_to,
        lang,
        backgroundReportTypesById,
        reportingObjectsById,
    ]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
        refetchInterval: 120000,
        retry: 2,
    });
};

export default useFetchData;
