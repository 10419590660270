import OrderSwitcher from '../../../../../../components/OrderSwitcher/OrderSwitcher';
import React, { ReactNode } from 'react';
import { Container, Th, Title } from '../styles';
import { generalReducerValues } from '../../../../../../General.reducer';
import { TOrdering } from '../../../../../../components/OrderSwitcher/interfaces';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../reducer';

const HeaderCell: React.FC<{
    hasSorting?: boolean;
    children: ReactNode;
    ordering: TOrdering;
    primaryType: string;
    colSpan: number;
    type?: string;
    handleOrderToggle: (x: { ordering: TOrdering; id: string }) => void;
}> = ({ children, colSpan, ordering, type, primaryType, hasSorting = false, ...props }) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { preparedData, allTenantsData, showReferenceCompareValues } = useSelector(
        Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    );

    const units = allMetrics?.find((item) => item.id === type)?.units || '';
    const { t } = useTranslation();

    return (
        <Th colSpan={colSpan}>
            <Container>
                <Title>
                    {children}
                    {units && !showReferenceCompareValues && `, ${t(units)}`}
                </Title>
                {hasSorting && (
                    <OrderSwitcher
                        handleOrderToggle={props.handleOrderToggle}
                        primaryId={primaryType}
                        ordering={ordering}
                        mode="triple"
                        id={type}
                        position="right"
                    />
                )}
            </Container>
        </Th>
    );
};

export default HeaderCell;
