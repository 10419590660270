import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE } from '../../../constants/constants';
import { generalReducerValues } from '../../../../../../General.reducer';
import { IExtendedReportingObject, IIntersectionData } from '../interfaces';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../constants/constants';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import {
    Leasing_CrossVisits_Widget_Reducer_Values,
    storeExtendedReportingObjectsById,
    storeSelectedReportingObjects,
    storeIntersectionsDataById,
    storeDaysInPeriod,
    toggleLoading,
    toggleError,
    resetReducer,
} from '../reducer';
import { Leasing_CrossVisits_Module_Reducer_Values } from '../../../reducer';

/**
 * Кастомный хук подготовки данных
 */
const usePrepareData = () => {
    const {
        cfg: { reportingObjectsByType, reportingObjectsById },
        selectedLocationId,
    } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Leasing_CrossVisits_Module_Reducer_Values);
    const { rawPecentAverageIntersections, extendedReportingObjectsById, rawPaiwiseIntersections } = useSelector(
        Leasing_CrossVisits_Widget_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();

    /** Обнуление редьюсера при смене локации */
    useEffect(() => {
        dispatch(resetReducer());
    }, [selectedLocationId]);

    /** Получение количества дней в периоде */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges) {
            const period = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions.mainPeriod!.id,
            )?.period;
            if (period) {
                const dateFrom = DateTime.fromISO(period.dateFrom);
                const dateTo = DateTime.fromISO(period.dateTo);
                const diff = dateTo.diff(dateFrom, ['days']).toObject();
                dispatch(storeDaysInPeriod(diff.days as number));
            }
        }
    }, [localCurrentOptions?.mainPeriod]);

    /** Получение расширенной информации о выбранных очттеных объектах */
    useEffect(() => {
        if (
            localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE]?.length &&
            localCurrentOptions?.selectedReportingObjectsIds &&
            Object.keys(reportingObjectsByType).length &&
            Object.keys(reportingObjectsById).length
        ) {
            dispatch(storeSelectedReportingObjects([]));
            const reportingObjectType = localCurrentOptions[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE][0].id;
            if (reportingObjectsByType[reportingObjectType]) {
                const extendedReportingObjects: { [id: string]: IExtendedReportingObject } =
                    localCurrentOptions.selectedReportingObjectsIds.reduce((acc, id, index) => {
                        const mainReportingObject = reportingObjectsById[id];
                        if (mainReportingObject) {
                            const contraReportingObjects = reportingObjectsByType[reportingObjectType]
                                ?.filter((element) => element.id !== id)
                                .map((element) => ({ reportingObject: element }));

                            acc[id] = {
                                reportingObject: mainReportingObject,
                                contraReportingObjects,
                                color: colors[index].color,
                            };
                        }

                        return acc;
                    }, {});

                dispatch(storeExtendedReportingObjectsById(extendedReportingObjects));
            } else {
                dispatch(storeExtendedReportingObjectsById(null));
            }
        }
    }, [
        localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE],
        localCurrentOptions?.selectedReportingObjectsIds,
        reportingObjectsByType,
        reportingObjectsById,
    ]);

    /** Получение данных пересечений для каждого отчетного объекта */
    useEffect(() => {
        if (rawPecentAverageIntersections && rawPaiwiseIntersections) {
            if (
                rawPecentAverageIntersections['status'] === ResponseStatus.Error ||
                rawPecentAverageIntersections['status'] === ResponseStatus.NoData ||
                rawPaiwiseIntersections['status'] === ResponseStatus.Error ||
                rawPaiwiseIntersections['status'] === ResponseStatus.NoData ||
                rawPaiwiseIntersections['error']
            ) {
                dispatch(toggleError(true));
                dispatch(toggleLoading(false));
            }

            if (
                Array.isArray(rawPecentAverageIntersections) &&
                Array.isArray(rawPaiwiseIntersections) &&
                extendedReportingObjectsById
            ) {
                dispatch(toggleLoading(false));
                const result: { [mainId: string]: { [contraId: string]: IIntersectionData } } =
                    rawPecentAverageIntersections.reduce((acc, value) => {
                        if (Object.hasOwn(value, 'error')) {
                            return acc;
                        }
                        const mainId = value?.[0].main_obj_id;
                        const piwiseValuesByMainId = rawPaiwiseIntersections?.find(
                            (element) => element?.[0].main_obj_id === mainId,
                        );
                        const result = value.reduce((acc, value) => {
                            let intersection = null;
                            if (piwiseValuesByMainId) {
                                const paiwiseValue = piwiseValuesByMainId?.find(
                                    (element) => element.contra_obj_id === value.contra_obj_id,
                                );
                                if (paiwiseValue) {
                                    intersection = paiwiseValue.intersection_count_unique;
                                }
                            }
                            acc[value.contra_obj_id] = {
                                percentIntersection: value.main_percent,
                                intersection,
                            };
                            return acc;
                        }, {});
                        acc[mainId] = result;
                        return acc;
                    }, {});

                dispatch(storeIntersectionsDataById(result));
            }
        }
    }, [rawPecentAverageIntersections, rawPaiwiseIntersections, extendedReportingObjectsById]);
};

export default usePrepareData;
