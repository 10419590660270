import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 90% 10%;
`;

export const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    z-index: 20;
    position: relative;
`;
