import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TMode } from '../../interfaces';
import './styles/styles.css';
import Button from '../../../../../../../../../components/Button/Button';
import { EventsMapWidgetContext } from '../../../../context';
import { Wrapper } from './styles/styles';

interface IProps {
    /**
     * изменение режима работы календаря
     */
    changeModeHandler: (mode: TMode) => void;
    /**
     * Режим работы календаря
     */
    mode: TMode;
}

/**
 * компонент для отображения кнопок с возможностью выбора типа календаря
 */
const Header: FC<IProps> = ({ mode, changeModeHandler }) => {
    const context = useContext(EventsMapWidgetContext);
    const { t } = useTranslation();

    const onModeClick = (mode: TMode) => () => {
        changeModeHandler(mode);
    };

    return (
        <Wrapper>
            <span>{t('Show by')}:</span>
            <div>
                <Button
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    appearance={mode === 'years' ? 'primary' : 'outline'}
                    onClick={onModeClick('years')}
                    text={t('Years')}
                    size="small"
                />
                <Button
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    appearance={mode === 'months' ? 'primary' : 'outline'}
                    onClick={onModeClick('months')}
                    text={t('Months')}
                    size="small"
                />
            </div>
        </Wrapper>
    );
};

export default Header;
