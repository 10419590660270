const hex2hsl = (hex: string): string => {
    // Remove the '#' character if present
    hex = hex?.replace('#', '');

    // Convert the hex values to RGB
    const r = parseInt(hex?.substring(0, 2), 16) / 255;
    const g = parseInt(hex?.substring(2, 4), 16) / 255;
    const b = parseInt(hex?.substring(4, 6), 16) / 255;

    // Find the minimum and maximum values of R, G, and B
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    // Calculate the hue
    let h = 0;
    if (max === min) {
        h = 0; // achromatic
    } else {
        const d = max - min;
        switch (max) {
            case r:
                h = ((g - b) / d + (g < b ? 6 : 0)) / 6;
                break;
            case g:
                h = ((b - r) / d + 2) / 6;
                break;
            case b:
                h = ((r - g) / d + 4) / 6;
                break;
        }
    }

    // Calculate the lightness
    const l = (max + min) / 2;

    // Calculate the saturation
    let s = 0;
    if (max !== min) {
        s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);
    }

    // Convert HSL values to string
    const hsl = `hsl(${Math.round(h * 360)}, ${Math.round(s * 100)}%, ${Math.round(l * 100)}%)`;

    return hsl;
};

export default hex2hsl;
