import styled from 'styled-components/macro';
import Icon from '../../../Icon/Icon';

export const MainWrapper = styled.div`
    background-clip: padding-box;
    background-color: #fff;
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    box-shadow: 0 8px 24px 0 rgba(22, 102, 167, 0.2);
    padding: var(--space-md);
`;
export const Header = styled.div``;
export const Main = styled.div``;
export const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-md);
`;

export const DayCell = styled.div<{ disabled: boolean; selected: boolean; isLastUnderCusor: boolean; bg: string }>`
    cursor: pointer;
    color: ${(p) =>
        p.selected || p.isLastUnderCusor ? 'white' : p.disabled ? 'var(--color-secondary-dark)' : 'black'};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    background: ${(p) => p.bg};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 45px;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: ${(p) => (p.selected || p.isLastUnderCusor ? 'var(--color-primary)' : 'transparent')};
    }
`;

export const WeekDay = styled.div`
    cursor: pointer;
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-bottom: 6px;
    width: 45px;
    font-weight: 700;
`;

export const WeekNumber = styled.div<{ weekDisabled: boolean }>`
    cursor: pointer;
    pointer-events: ${(p) => (p.weekDisabled ? 'none' : 'auto')};
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
    /* margin-bottom: 6px; */
    span {
        align-items: center;
        background: var(--color-secondary-light);
        color: ${(p) => (p.weekDisabled ? 'var(--color-contrast-low)' : 'var(--color-contrast-medium)')};
        border-radius: ${(p) => (p.theme.isRtlLanguage ? '0 16px 16px 0' : '16px 0 0 16px')};
        display: flex;
        height: 32px;
        justify-content: center;
        width: 100%;
    }
`;

export const Month = styled.div`
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    padding: 0 20px;
    padding-top: 13px;
`;

export const MonthWrapper = styled.div<{ index: number }>`
    display: grid;
    grid-template-rows: auto, auto;
    border-right: ${(p) => (p.index === 0 || p.index === 1 ? '1px solid #dce0e0' : 'none')};
    position: relative;
`;

export const ShifterLine = styled.div<{ shift: number }>`
    position: absolute;
    width: calc(100% - 20px);
    left: 10px;
    top: 11px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
`;
export const IconWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    border: 1px solid var(--color-secondary-dark) !important;
    :hover {
        background-color: var(--color-primary-lighter);
    }
`;

export const MonthHeader = styled.div`
    display: flex;
    border-bottom: 1px solid #dce0e0;
`;

export const MonthName = styled.div`
    color: var(--color-primary);
    font-size: 16px;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Week = styled.div<{ weekInFocus?: boolean; weekDisabled?: boolean }>`
    display: grid;
    grid-template-columns: 32px repeat(7, 1fr);
    border: ${(p) => (p.weekInFocus ? '1px solid rgba(74,113,232,0.3)' : '1px solid transparent')};
    border-radius: 16px;
    height: 34px;
    margin-bottom: 6px;
`;

export const CalendarWrapper = styled.div`
    font-size: 12px;
    align-items: flex-start;
    border: 1px solid #dce0e0;
    border-radius: 5px;
    display: flex;
    margin: 6px 0 0;
    position: relative;
`;
