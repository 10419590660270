import React from 'react';
import { IMetric } from '../../../../../../General.interfaces';
import { IDataAdapterResponseItem } from '../../../interfaces';
import { Td, Value } from '../../../styles';
import Stack from '../../../../../Stack/Stack';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import Icon from '../../../../../Icon/Icon';

export const CellDeviation = (props: {
    style: React.CSSProperties | undefined;
    showAbsoluteValues: boolean | undefined;
    color: { color: string; direction: string };
    metricFromStore: IMetric | undefined;
    item: IDataAdapterResponseItem;
    index: number;
    showCompareValueArrow: boolean | undefined;
}) => {
    return (
        <Td>
            <Stack styles={{ height: '100%' }} alignItems="center" justifyContent="flex-end" gap={5}>
                <Value style={props.style} color={props.showAbsoluteValues ? undefined : props.color.color}>
                    {valueFormatter({
                        precision: props.showAbsoluteValues ? props.metricFromStore?.round_decimal_places : 1,
                        value: props.item.deviation ? props.item.deviation[props.index] : 0,
                        units: props.showAbsoluteValues ? props.metricFromStore?.units : '',
                        showUnits: !props.showAbsoluteValues,
                    })}
                </Value>
                {props.showCompareValueArrow && props.color.direction !== 'usual' && (
                    <Icon
                        type={props.color.direction === 'down' ? 'short-arrow-down' : 'short-arrow-up'}
                        size="xxs"
                        color={props.color.direction === 'down' ? 'accent' : 'success'}
                    />
                )}
            </Stack>
        </Td>
    );
};
