import { TLayerData } from '../../../../../General.interfaces';
import LayerByType from './components/LayerByType/LayerByType';
import { FC, useContext, useId, useMemo } from 'react';
import useInitialData from './hooks/useInitialData';
import FloorsContext from '../../Floors/context';
import Labels from './components/Labels/Labels';
import { Group, Layer } from 'react-konva';
import { IProps } from './interfaces';

/**
 * Компонент, отвечающий за отображения слоя с геометрией (слоями мапсервиса)
 * @param stageIndex Индекс холста
 * @param stage экземпляр класса холста
 * @param plan сам план

 */
const GeometryGroup: FC<IProps> = ({ stageIndex, stage, plan }) => {
    const { selectedGroupOfZones, selectedLayerType, colorsByMarker, showPerimeters, layers } =
        useContext(FloorsContext);
    const { transformedLayer, transformedPerimeterLayer } = useInitialData({
        selectedGroupOfZones,
        selectedLayerType,
        layers,
        plan,
    });

    const id = useId();

    const geometry = useMemo(() => {
        return (
            <>
                {showPerimeters &&
                    selectedLayerType !== 'perimeter_layer' &&
                    transformedPerimeterLayer?.data.map((element) => {
                        return (
                            <LayerByType
                                key={transformedPerimeterLayer.floor + Math.random()}
                                floor={transformedPerimeterLayer.floor}
                                selectedLayerType={'perimeter_layer'}
                                object={element as TLayerData}
                                stageIndex={stageIndex}
                                stage={stage}
                                plan={plan}
                            />
                        );
                    })}
                {transformedLayer?.data.map((element) => {
                    return (
                        <LayerByType
                            key={transformedLayer.floor + Math.random()}
                            selectedLayerType={selectedLayerType}
                            object={element as TLayerData}
                            floor={transformedLayer.floor}
                            stageIndex={stageIndex}
                            stage={stage}
                            plan={plan}
                        />
                    );
                })}
            </>
        );
    }, [transformedLayer, colorsByMarker, transformedPerimeterLayer]);

    return (
        <Group id={`geometry-layer-${id}`}>
            {geometry}
            <Labels transformedLayer={transformedLayer} />
        </Group>
    );
};

export default GeometryGroup;
