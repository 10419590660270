import { FC } from 'react';
import { IProps } from './interfaces';
import Stack from '../../../../../Stack/Stack';
import { IChangeOptionsArgs } from '../../../../../UserSettings/interfaces';
import UniversalSelect from '../../../../../Selects/UniversalSelect/UniversalSelect';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import {
    OBJ_BY_METRICS_TABLE_VIEW_OPTIONS,
    OBJ_BY_METRICS_USE_METRICS_FROM_OPTIONS,
    TABLE_VIEW_OPTIONS,
} from '../../constants/constants';
import { ObjByMeticsUseMetricsFromIds } from '../../enums';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { changeRequiredParams } from '../../../../../../Dashboards.reducer';

const Selects: FC<IProps> = ({ widgetConfig, moduleId, dashboardKey, updateWidget }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updateWidgetVisual = (visualParamKey: string) => (args: IChangeOptionsArgs) => {
        updateWidget('visual', { [visualParamKey]: args.newOptions?.[visualParamKey] });

        const newChoice = args.newOptions[visualParamKey];
        if (visualParamKey === 'useMetricsFrom' && widgetConfig?.id) {
            if (Array.isArray(newChoice) && newChoice.length) {
                const [data] = newChoice;
                const paramsMap = { useMetricsFrom: 'selectedMetrics' };
                const mode = data.id === ObjByMeticsUseMetricsFromIds.FromPanel ? 'add' : 'remove';

                dispatch(
                    changeRequiredParams({
                        param: paramsMap[visualParamKey],
                        id: widgetConfig?.id,
                        dashboardKey,
                        moduleId,
                        mode,
                    }),
                );
            }
        }
    };
    return (
        <>
            <Stack styles={{ padding: '0px 12px' }} direction="column" gap={1}>
                <UniversalSelect
                    mode="single"
                    labelText={t('Use metrics from')}
                    name="useMetricsFrom"
                    options={OBJ_BY_METRICS_USE_METRICS_FROM_OPTIONS}
                    localCurrentOptions={{ ...widgetConfig?.visual, currentModuleID } || {}}
                    changeOptions={updateWidgetVisual('useMetricsFrom')}
                />
            </Stack>
        </>
    );
};

export default Selects;
