import { theme } from 'src/theme';
import styled from 'styled-components/macro';

export const VersionWrapper = styled.div`
    margin-left: auto;
    span {
        color: red;
    }
`;
export const SearchWrapper = styled.div<{ isSidebarOpen: boolean }>`
    height: 70px;
    max-height: 70px;
    width: 100%;
    display: flex;
    /* justify-content: stretch; */
    align-items: flex-end;

    padding-left: var(--space-md);
    padding-right: ${(p) => (p.isSidebarOpen ? 'var(--space-md)' : 0)};
    padding-bottom: 15px;
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxs);
    flex-shrink: 0;
    flex-grow: 1;
`;

export const SidebarForm = styled.div<{ sidebarOpen: boolean }>`
    /* padding-top: 80px; */
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: var(--space-sidebar);
    height: 100%;
    background-color: var(--color-secondary-light);
    z-index: ${(p) => (p.sidebarOpen ? 100 : 1)};

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        background-color: var(--color-white);

        transform: ${(p) => (p.sidebarOpen ? 'translateX(0)' : ' translateX(-100%)')};
        position: absolute;
        left: 0;
        top: 0;
        transition: transform 0.3s ease-in-out;
    }
`;
