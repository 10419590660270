import { AxiosError } from 'axios';
import { ResponseStatus } from '../constants';
import { isNull } from 'lodash';

type TErrorResponse = { status: ResponseStatus; message: string; alias?: string };

export const isErrorResponseGuard = (error: unknown): error is TErrorResponse => {
    return !isNull(error) && error instanceof Object && 'status' in error && 'message' in error;
};

export const isAxiosError = (error: unknown): error is AxiosError => {
    return !isNull(error) && error instanceof Object && (error as AxiosError).isAxiosError;
};
