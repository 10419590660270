import { storeExtendedTenants, storeIdsOfTenants, Leasing_Tenants_Tenants_Widget_Reducer_Values } from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../../General.reducer';
import generateExtendedTenants from '../tools/generateExtendedTenants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

/** Кастомный хук для подготовки данных*/
const usePrepareData = () => {
    const { rawMetricsData, moduleName, idsOfTenants } = useSelector(Leasing_Tenants_Tenants_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    /** Запись всех id арендаторов */
    useEffect(() => {
        if (localCurrentOptions?.selectedReportingObjectsIds?.length) {
            dispatch(storeIdsOfTenants(localCurrentOptions.selectedReportingObjectsIds));
        } else {
            dispatch(storeExtendedTenants(null));
            dispatch(storeIdsOfTenants(null));
        }
    }, [JSON.stringify(localCurrentOptions?.selectedReportingObjectsIds)]);

    /** Получение расширенной информации о арендаторе */
    useEffect(() => {
        if (
            Object.keys(reportingObjectsByType).length &&
            localCurrentOptions?.selectedMetrics &&
            reportingObjectsByType?.['tenant'] &&
            rawMetricsData &&
            idsOfTenants
        ) {
            const extendedTenants = generateExtendedTenants({
                selectedMetrics: localCurrentOptions.selectedMetrics,
                tenants: reportingObjectsByType['tenant'].filter((tenant) => idsOfTenants.includes(tenant.id)),
                rawMetricsData,
            });
            dispatch(storeExtendedTenants(extendedTenants));
        }
    }, [JSON.stringify(localCurrentOptions?.selectedMetrics), rawMetricsData, idsOfTenants]);
};

export default usePrepareData;
