import { DateTime } from 'luxon';
import { TFunction } from 'i18next';

/**
 * Функция для приведения числа к удобному виду (1000 => 1 000, 10000 => 10 000)
 */
export interface IValueFormatter {
    value: any;
    units?: string;
    t?: TFunction;
    showUnits?: boolean;
    precision?: number;
    showSpaceBetweenNumberAndUnit?: boolean;
    shortenNumbers?: boolean;
}

export function valueFormatter(params: IValueFormatter): string;
export function valueFormatter(params: IValueFormatter & { numericOutput: boolean }): string | number;

export function valueFormatter({
    value,
    units,
    t,
    showUnits = true,
    precision = 2,
    numericOutput = false,
    showSpaceBetweenNumberAndUnit = true,
    shortenNumbers = false,
}: IValueFormatter & { numericOutput: boolean }) {
    if (value === undefined || value === null || !isFinite(Number(value))) return '–';

    const SPACE_BETWEEN_NUMBER_AND_UNIT = showSpaceBetweenNumberAndUnit ? ' ' : '';

    let result: string = '';
    let u = t && units ? t(units) : units && !t ? units : '';
    if (!showUnits) u = '';
    value = Number(value).toFixed(precision);

    if (units === 'min') {
        return time_convert(Number(value));
    }

    if (shortenNumbers) {
        result = letterNotationFormatter({
            spaceBetweenNumberAndUnit: SPACE_BETWEEN_NUMBER_AND_UNIT,
            precision,
            units: u,
            value,
            t,
        });
    } else {
        result = partsFormatter({ value, units: u, spaceBetweenNumberAndUnit: SPACE_BETWEEN_NUMBER_AND_UNIT });
    }

    if (!numericOutput) {
        return result;
    } else {
        const replaceSymbol = document.dir === 'rtl' ? ',' : ' ';
        if (isFinite(Number(String(result).replaceAll(replaceSymbol, '')))) {
            return Number(String(result).replaceAll(replaceSymbol, ''));
        } else {
            return result;
        }
    }
}

function time_convert(num: number) {
    var sign = num < 0 ? '-' : '';
    const hours = Math.floor(Math.abs(num) / 60);
    var min = Math.floor(Math.abs(Math.abs(num) - hours * 60));
    var sec = Math.floor((Math.abs(num) * 60) % 60);
    return (
        sign + (hours < 10 ? '0' : '') + hours + ':' + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
    );
}

interface IPartsFormatterArgs {
    value: string;
    spaceBetweenNumberAndUnit: string;
    units: string;
}

function partsFormatter(args: IPartsFormatterArgs): string {
    const { value, spaceBetweenNumberAndUnit, units } = args;
    let parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, document.dir === 'rtl' ? ',' : ' ');
    if (parts[1] === '00') {
        return units ? parts[0] + spaceBetweenNumberAndUnit + units : parts[0];
    } else {
        return units ? parts.join('.') + spaceBetweenNumberAndUnit + units : parts.join('.');
    }
}

interface ILetterNotationFormatterArgs {
    value: string;
    spaceBetweenNumberAndUnit: string;
    units: string;
    precision: number;
    t?: TFunction;
}
function letterNotationFormatter(args: ILetterNotationFormatterArgs): string {
    const { value, spaceBetweenNumberAndUnit, units, precision, t } = args;

    const num = parseFloat(value);
    const absNum = Math.abs(num);

    if (absNum >= 1000000) {
        const suffix = 'k';
        const result =
            String(Math.round(num / 1000)).replace(/\B(?=(\d{3})+(?!\d))/g, document.dir === 'rtl' ? ',' : ' ') +
            (t ? t(suffix) : suffix);
        return units ? result + spaceBetweenNumberAndUnit + units : result;
    } else {
        return partsFormatter(args);
    }
}
// function letterNotationFormatter(args: ILetterNotationFormatterArgs): string {
//     const LETTERS = ['', 'k', 'M', 'B', 'T'];

//     const { value, spaceBetweenNumberAndUnit, units, precision, t } = args;

//     const num = parseFloat(value);
//     const absNum = Math.abs(num);
//     const tier = Math.floor(Math.log10(absNum) / 3);

//     if (tier > 0 && tier < LETTERS.length) {
//         const suffix = LETTERS[tier];
//         const result = (num / Math.pow(10, tier * 3)).toFixed(precision) + (t ? t(suffix) : suffix);
//         return units ? result + spaceBetweenNumberAndUnit + units : result;
//     } else {
//         return partsFormatter(args);
//     }
// }
