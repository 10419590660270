import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: var(--space-xxs);
    border: 1px solid var(--default-border-color);
    border-radius: 10px;
    max-width: 350px;
    flex-grow: 1;
    /* cursor: pointer; */
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);

    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    /* :hover {
        border: 1px solid var(--color-primary);
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    } */
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 20px;
`;

export const Title = styled.div`
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

export const EventsCount = styled.div`
    color: var(--color-secondary-darker);
    span {
        font-weight: 500;
    }
`;
