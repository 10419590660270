import { FC, useMemo } from 'react';
import { IProps } from './interfaces';
import Column from '../../Charts/Column/Column';
import useFetchData from './api/useFetchData';
import { withLoading } from '../../../tools/API/withLoading';
import getResponseStatus from '../../../tools/API/helpers/getResponseStatus';
import { ChartWrapper } from './styles';

const StackedBarChartByObjectsGroup: FC<IProps> = (props) => {
    const { data, isFetching, error } = useFetchData(props);

    const WithLoading = useMemo(() => {
        const Component = withLoading(
            ChartWrapper,
            {
                data: getResponseStatus({
                    isFetching,
                    error,
                    data,
                }),
                height: '100%',
            },
            false,
        );

        return Component;
    }, [error, JSON.stringify(data), isFetching]);

    return <WithLoading>{data && <Column {...data.chartProps} />}</WithLoading>;
};

export default StackedBarChartByObjectsGroup;
