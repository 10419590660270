import { cloneDeep, isUndefined } from 'lodash';
import { IObject, ISortingConfig, TNestedOptions } from '../interfaces';
import { isNested } from './getOptionsType';

interface IArgs {
    /** Конфигурация сортировки */
    sortingConfig: ISortingConfig;
    /** Опции селекта */
    options: IObject[] | TNestedOptions;
}

/**
 * Функция для сортировки опций в селекте
 */
const sortOptions = (args: IArgs): IObject[] | TNestedOptions => {
    const { sortingConfig, options } = args;
    /** Кейс, когда селект не имеет вложенности */
    if (sortingConfig['NoSection'] && !isNested(options)) {
        const [sortMultiplier, sortingField] = getSortMultiplierAndField(sortingConfig, options, 'NoSection');
        const sortedOptions = cloneDeep(options).sort((a, b) => sortFunction(a, b, sortingField, sortMultiplier));
        return sortedOptions;
    }

    /** Кейс, когда селект имеет вложенность */
    if (isNested(options)) {
        const optionsBySection: { [section: string]: { section: string; objects: IObject[] } } = cloneDeep(
            options,
        ).reduce((acc, value) => {
            acc[value.section] = value;
            return acc;
        }, {});

        Object.keys(sortingConfig).forEach((section) => {
            if (optionsBySection[section]) {
                const [sortMultiplier, sortingField] = getSortMultiplierAndField(
                    sortingConfig,
                    optionsBySection[section].objects,
                    section,
                );

                optionsBySection[section].objects.sort((a, b) => sortFunction(a, b, sortingField, sortMultiplier));
            }
        });

        return Object.values(optionsBySection);
    }

    return options;
};

export default sortOptions;

/**
 * Функция для получения множителя порядка сортировки и поля по которому
 * будет происходить сортировка
 */
const getSortMultiplierAndField = (
    sortConfig: ISortingConfig,
    objects: IObject[],
    section: string,
): [number, 'sortingField' | 'text'] => {
    const sortMultiplier = sortConfig[section].directSort ? 1 : -1;
    /**
     * Если сортированные элементы имеют поле "sortingField", то сортировка
     * происходит по этому полю
     */
    const sortingField = isUndefined(objects[0]?.sortingField) ? 'text' : 'sortingField';
    return [sortMultiplier, sortingField];
};

/**
 * Функция сортировки по переданному полю(ключу)
 */
const sortFunction = (a: IObject, b: IObject, sortingField: string, sortMultiplier: number) => {
    if (!a[sortingField] && b[sortingField]) return 1;
    if (a[sortingField] && !b[sortingField]) return -1;
    if (!a[sortingField] && !b[sortingField]) return 1;

    return JSON.stringify(a[sortingField]) > JSON.stringify(b[sortingField]) ? -1 * sortMultiplier : sortMultiplier;
};
