import { MenuItem } from '@fluentui/react-components';
import styled from 'styled-components/macro';

export const OptionsWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 100%;
    /* padding-right: var(--space-md);
    padding-left: var(--space-md); */
    /* max-width: 1176px; */
`;

export const ContextualMenuBodyWrapper = styled.div`
    padding: 5px;
`;

export const StyledMenuItem = styled(MenuItem)`
    :hover {
        color: var(--color-primary-darker) !important;
    }
`;

export const ModuleWrapper = styled.div`
    box-sizing: border-box;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--space-md);
    padding-top: 0;
    flex-grow: 1;
    height: 100%;
    background-color: var(--color-secondary-light);
`;

export const WidgetsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    /* padding-top: 10px; */
`;
