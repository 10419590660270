import {
    BaseSlots,
    IThemeRules,
    FabricSlots,
    ThemeGenerator,
    themeRulesStandardCreator,
} from '@fluentui/react/lib/ThemeGenerator';
import { createTheme } from '@fluentui/react/lib/Styling';
import { getColorFromString } from '@fluentui/react/lib/Color';
import { Theme as FluentTheme } from '@fluentui/react-components';
import { isDark } from '@fluentui/react/lib/Color';

import { commonTheme } from './commonTheme';
import { screenBreakpointsFromComputedStyle } from './definitions';

export const isTabletInboundWidth = (width: number) => {
    return width <= Number(screenBreakpointsFromComputedStyle.breakpoints.tablet.split('px')[0]);
};

export const isTabletOutboundWidth = (width: number) => {
    return width > Number(screenBreakpointsFromComputedStyle.breakpoints.tablet.split('px')[0]);
};

export const isMobileInboundWidth = (width: number) => {
    return width <= Number(screenBreakpointsFromComputedStyle.breakpoints.mobile.split('px')[0]);
};

export const isMobileOutboundWidth = (width: number) => {
    return width > Number(screenBreakpointsFromComputedStyle.breakpoints.mobile.split('px')[0]);
};

export const prepareFluentThemeFabric = (baseTheme?: FluentTheme) => {
    const themeRules = themeRulesStandardCreator();
    const colors = {
        primary: getColorFromString(commonTheme.colors.primary)!,
        secondary: getColorFromString(commonTheme.colors.info)!,

        green: getColorFromString(commonTheme.colors.success)!,
        text: getColorFromString(commonTheme.colors.text)!,
        background: getColorFromString(commonTheme.colors['secondary-light'])!,
    };

    ThemeGenerator.insureSlots(themeRules, isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!));
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.primaryColor]], colors.primary, undefined, false, false);

    ThemeGenerator.setSlot(
        themeRules[FabricSlots[FabricSlots.themeSecondary]],
        colors.secondary,
        undefined,
        false,
        false,
    );
    ThemeGenerator.setSlot(
        themeRules[FabricSlots[FabricSlots.neutralSecondaryAlt]],
        colors.green,
        undefined,
        false,
        false,
    );

    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.foregroundColor]], colors.text, undefined, false, false);
    ThemeGenerator.setSlot(
        themeRules[BaseSlots[BaseSlots.backgroundColor]],
        colors.background,
        undefined,
        false,
        false,
    );

    const themeAsJson: {
        [key: string]: string;
    } = ThemeGenerator.getThemeAsJson(themeRules);

    const finalTheme = createTheme({
        ...{ palette: themeAsJson },
        isInverted: isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!),
    });

    const themeFabric = {
        ...colors,
        theme: { ...baseTheme, ...finalTheme },
        themeRules,
    };

    return themeFabric;
};

export const prepareStyledThemeFabric = () => {
    return commonTheme;
};
