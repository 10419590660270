import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import { IReportingObject } from '../../../../../General.interfaces';
import Button from '../../../../Button/Button';
import Checkbox from '../../../../Checkbox/Checkbox';
import Collapse from '../../../../Collapse/Collapse';
import Search from '../../../../Search/Search';
import { getObjectName } from '../../../../../hooks/useObjectTranslation';
import { objectTypesMap } from '../../../../../tools/getReportingObjects';

import { ButtonWrapper, CheckBoxWrapper, ListWrapper, SearchWrapper, StyledIcon, Wrapper } from './styles';
import { IProps } from './interfaces';

/**
 * Компонент для отображения тела дропдауна
 * @param selectedReportingObjects Все выбранные отчетные объекты данного типа
 * @param reportingObjects все отчетные объекты данного типа
 * @param handleSelect Обработчик выбора
 * @param filterType тип отчетного объекта
 */
const DropdownBody: FC<IProps> = ({ reportingObjects, filterType, selectedReportingObjects, handleSelect }) => {
    const [selectedOptions, setSelectedOptions] = useState<IReportingObject[]>([]);
    const [filteredObjects, setFilteredObjects] = useState<IReportingObject[]>([]);
    const [filter, setFilter] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setFilteredObjects(cloneDeep(reportingObjects));
    }, [reportingObjects]);

    useEffect(() => {
        setSelectedOptions(cloneDeep(selectedReportingObjects));
    }, [selectedReportingObjects]);

    const onCheck = (reportingObject: IReportingObject) => (args: { checked: boolean }) => {
        if (args.checked) {
            setSelectedOptions((prevState) => [...prevState, { ...reportingObject }]);
        } else {
            const index = selectedOptions.findIndex((item) => item.id === reportingObject.id);
            if (index > -1) {
                const newState = cloneDeep(selectedOptions);
                newState.splice(index, 1);
                setSelectedOptions(newState);
            }
        }
    };

    const onSaveButtonClick = () => {
        handleSelect(selectedOptions.map((element) => element?.id));
    };

    const handleAdditionalControlClick = () => {
        setSelectedOptions(selectedReportingObjects.filter((item) => item.object_type !== filterType));
    };

    const filterOnChange = ({ value }: { value: string }) => {
        setFilter(value);
    };

    const list = useMemo(() => {
        return filteredObjects
            ?.filter((reportingObject) => reportingObject.name.toLowerCase().includes(filter.toLowerCase()))
            .map((reportingObject) => {
                if (!reportingObject) return null;
                const selected = !!selectedOptions?.find((item) => item?.id === reportingObject.id);
                return (
                    <CheckBoxWrapper key={reportingObject.id}>
                        <Checkbox
                            handleToggle={onCheck(reportingObject)}
                            label={getObjectName({ reportingObject, t })}
                            selected={selected}
                        />
                    </CheckBoxWrapper>
                );
            });
    }, [selectedOptions, filteredObjects, filter]);

    return (
        <Wrapper>
            <SearchWrapper>
                <Search fullWidth placeholder={t('Filter')} handleChange={filterOnChange} />
            </SearchWrapper>

            <Collapse
                label={t(objectTypesMap[filterType] || filterType)}
                handleAdditionalControlClick={handleAdditionalControlClick}
                additionalControl={<StyledIcon />}
                addClasses={'SelectCollapse'}
                iconJustify="space-between"
                hover="background-none"
                icon="chevron-right"
                iconPosition="right"
                isCollapsed={false}
                key={filterType}
                id={filterType}
            >
                <ListWrapper>{list}</ListWrapper>
            </Collapse>

            <ButtonWrapper>
                <Button
                    appearance="primary"
                    size="small"
                    style={{ marginTop: 'var(--space-xxs)' }}
                    onClick={onSaveButtonClick}
                >
                    {t('Save')}
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default DropdownBody;
