import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { addUserDashboardWidget } from '../../../Dashboards.reducer';
import { IProps } from './interfaces';
import { Column, ManagerWrapper, Wrapper } from './styles';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import { DS } from '../../../constants/constants';
import ColumnItem from './components/ColumnItem/ColumnItem';
import generateId from '../../../tools/generateId';
import Stack from '../../Stack/Stack';
import Button from '../../Button/Button';
import { IWidget } from '../interfaces';

const WidgetsManager: React.FC<IProps> = ({ availableWidgets, selectedKey, userDashboards, closeModal }) => {
    const { currentModuleID } = useSelector(generalReducerValues);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectedType, setSelectedType] = useState<string>(availableWidgets[0]?.typeTitle ?? '');

    const availableTypes: string[] = Array.from(new Set(availableWidgets.map((item) => item.typeTitle ?? '')));

    const currentUserDashboard = userDashboards.find(
        (dashboard) => dashboard.module_id === currentModuleID && dashboard.dashboard_key === selectedKey,
    );

    const onTypeClick = (type: string) => setSelectedType(() => type);

    const onAddClick = (widget: IWidget) => {
        const newWidgetId = `${widget.type}${DS}${generateId()}`;
        selectedKey &&
            dispatch(
                addUserDashboardWidget({
                    moduleId: currentModuleID,
                    dashboardKey: selectedKey,
                    widget: { ...widget, id: newWidgetId },
                }),
            );
    };

    return (
        <Wrapper>
            <ManagerWrapper>
                <Column key={'initial_column'}>
                    {availableTypes.map((name) => {
                        const icon = availableWidgets.find((w) => w?.typeTitle === name)?.icon;
                        return (
                            <ColumnItem
                                name={name}
                                icon={icon}
                                key={`${name}`}
                                selected={name === selectedType}
                                onClick={onTypeClick}
                            />
                        );
                    })}
                </Column>
                <Column>
                    {availableWidgets
                        .filter((item) => item.typeTitle === selectedType)
                        .map((w) => {
                            const currentTypeWidgetsCount =
                                currentUserDashboard?.widgets.filter((widget) => widget.type === w.type)?.length || 0;

                            const name = w?.title ?? '';
                            return (
                                <ColumnItem
                                    widgetsCount={currentTypeWidgetsCount}
                                    name={name}
                                    key={`${name}`}
                                    selected={false}
                                    onAddClick={onAddClick}
                                    data={w}
                                    showPlus
                                />
                            );
                        })}
                </Column>
            </ManagerWrapper>
            <Stack alignItems="center" justifyContent="flex-end" gap={10}>
                <Button onClick={closeModal}>{t('OK')}</Button>
            </Stack>
        </Wrapper>
    );
};

export default WidgetsManager;
