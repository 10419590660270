import { useMemo } from 'react';
import { IObject, TNestedOptions } from '../../../components/Selects/Select/interfaces';
import { UseQueryResult } from '@tanstack/react-query';
import { IGroupDataObjectsDAResult } from '../../../tools/API/hooks/groupDataObjects/interfaces';
import getGroupObjectId from './getGroupObjectId';

/**
 * Хук для генерация опций для вложенного селекта на основе сгруппированных объектов
 */
const useGDONestedSelectOptions = (data: UseQueryResult<IGroupDataObjectsDAResult, Error>[]): TNestedOptions => {
    return useMemo(() => {
        const result: TNestedOptions = [];

        data.forEach((item) => {
            if (item.data) {
                const objects: IObject[] = item.data.data.map((group) => {
                    return {
                        id: getGroupObjectId(item.data.objectType.section, group.group_name),
                        text: `${group.group_name} (${group.items.length})`,
                    };
                });
                result.push({ section: item.data.objectType.section, objects });
            }
        });

        return result;
    }, [data]);
};

export default useGDONestedSelectOptions;
