import { FC } from 'react';
import { IProps } from './interfaces';
import { Extension, FileName, Link, Title, Wrapper } from './styles';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import Stack from '../../../../components/Stack/Stack';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import IconButton from '../../../../components/IconButton/IconButton';
import { ArrowDownload24Filled } from '@fluentui/react-icons';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { storeNewAlert } from '../../../../components/Alert/reducer';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';
import { generalReducerValues } from '../../../../General.reducer';

/**
 * Модальное окно для загрузки файлов для загрузки файлов
 */
const FilesModal: FC<IProps> = ({ files }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const sendAnalytics = useSendSimpleAnalytics();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    /**
     * Загрузка файлa, путем создания "a" и события "click" на них
     */
    const onFileDownload = (file: string) => () => {
        const link = document.createElement('a');
        link.href = file;
        document.body.appendChild(link);
        link.click();
        link.remove();
        sendAnalytics(`report_DetailedByBills_downloaded`, 'reachGoal', currentModuleID);
    };

    const getFileNameAndExtension = (file: string): [string, string] => {
        const fileNameWHost = decodeURI(file).split('?')?.[0] || '';
        const splittedFileNameWHost = fileNameWHost.split('/');

        const fileNameWExtension = splittedFileNameWHost[splittedFileNameWHost.length - 1] as string | undefined;
        const splittedFileNameWExtension = fileNameWExtension?.split('.') || [];
        const name = splittedFileNameWExtension.slice(0, splittedFileNameWExtension.length - 1).join('.');
        const extension = splittedFileNameWExtension[splittedFileNameWExtension.length - 1];
        return [name, extension];
    };

    /**
     * Загрузка всех файлов одновременно
     */
    const onAllFilesDownload = () => {
        const promises: Promise<AxiosResponse<MediaSource>>[] = [];
        files.forEach((file) => promises.push(axios.get(file, { responseType: 'blob' })));

        function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }

        axios
            .all(promises)
            .then(async (responses) => {
                sendAnalytics(`report_DetailedByBills_download_all`, 'reachGoal', currentModuleID);
                for (const [index, response] of responses.entries()) {
                    const [name, extension] = getFileNameAndExtension(files[index] || '');

                    const href = window.URL.createObjectURL(response.data);
                    const anchorElement = document.createElement('a');
                    anchorElement.setAttribute('href', href);
                    anchorElement.setAttribute('download', `${name}.${extension}`);
                    document.body.appendChild(anchorElement);
                    anchorElement.click();
                    document.body.removeChild(anchorElement);
                    window.URL.revokeObjectURL(href);
                    await delay(300);
                }
            })
            .catch(() => {
                dispatch(
                    storeNewAlert({
                        type: 'error',
                        text: t(
                            'An error occurred while downloading files. Try downloading the necessary files one by one or reload the page!',
                        ),
                    }),
                );
            });
    };

    return (
        <Wrapper>
            <Stack justifyContent="space-between" alignItems="center">
                <Title>{t('Files')}</Title>
                <Link onClick={onAllFilesDownload}>{t('Download all')}</Link>
            </Stack>
            <Stack direction={'column'} gap={10} styles={{ marginTop: '10px' }}>
                {files.map((file) => {
                    const [name, extension] = getFileNameAndExtension(file);

                    return (
                        <Stack key={file} justifyContent="space-between">
                            <Stack styles={{ overflow: 'hidden' }} alignItems="center" gap={5}>
                                <Extension>{extension}</Extension>
                                <FileName>{name}</FileName>
                            </Stack>
                            <Tooltip content={t('Download')}>
                                <IconButton icon={<ArrowDownload24Filled />} size={32} onClick={onFileDownload(file)} />
                            </Tooltip>
                        </Stack>
                    );
                })}
            </Stack>
        </Wrapper>
    );
};

export default FilesModal;
