import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IEvent } from '../EventsMap/widgets/EventsMapWidget/interfaces';
import { IState } from './interfaces';

const initialState: IState = {
    moduleName: 'Events:Events',
    selectedEventId: null,
    currentEvent: null,
};

const Events_Events_Module_Reducer = createSlice({
    name: 'Events_Events_Module_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение ID выбранного события
         */
        storeSelectedEventId: (state, action: PayloadAction<number | null>) => {
            state.selectedEventId = action.payload;
        },

        /**
         * Сохранение текущего события
         */
        storeCurrentEvent: (state, action: PayloadAction<IEvent | null>) => {
            state.currentEvent = action.payload;
        },
    },
});

export const { storeSelectedEventId, storeCurrentEvent } = Events_Events_Module_Reducer.actions;

export const Events_Events_Module_Reducer_Values = (state: RootState) => state.Events_Events_Module_Reducer;

export default Events_Events_Module_Reducer.reducer;
