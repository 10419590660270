import { FC, FormEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDataObjectsPasswayTypeFilterState, IPasswayFilterType } from '../../../../interfaces';
import { IGetComponentProps } from '../../interfaces';
import { CheckboxWrapper, CollapseWrapper, SortByTenantNameWrapper } from './styles';
import Checkbox from '../../../../../../components/Checkbox/Checkbox';
import Collapse from '../../../../../../components/Collapse/Collapse';
import { Radio, RadioGroup, RadioGroupOnChangeData } from '@fluentui/react-components';

/**
 * Компонент для отображения фильтра типа прохода
 */
const Filter: FC<IGetComponentProps> = ({ filterData, onFiltersChange }) => {
    const [sortByTenantName, setSortByTenantName] = useState<boolean>(filterData.filter_state['sort_by_tenant_name']);
    const [shouldUpdateSortState, setShouldUpdateSortState] = useState(false);

    const [selectedKey, setSelectedKey] = useState<string | undefined>('');
    const [shouldUpdateFiltersState, setShouldUpdateFiltersState] = useState(false);

    const [isFilterActive, setIsFilterActive] = useState(filterData.active);
    const [shouldUpdateFilterActive, setShouldUpdateFilterActive] = useState(false);
    const { t } = useTranslation();

    useLayoutEffect(() => {
        const selectedKey = (filterData.filter_state['passway_filter_types'] as IPasswayFilterType[]).find(
            (element) => element.active,
        )?.key;
        setSelectedKey(selectedKey);
    }, [filterData?.filter_state?.['passway_filter_types'], t]);

    useEffect(() => {
        const filterTypes = (filterData.filter_state as IDataObjectsPasswayTypeFilterState).passway_filter_types.map(
            (element) => {
                return {
                    ...element,
                    active: selectedKey === element.key,
                };
            },
        );

        const payload = {
            filterType: filterData.filter_type,
            key: 'filter_state',
            value: {
                ...filterData.filter_state,
                passway_filter_types: filterTypes,
                sort_by_tenant_name: sortByTenantName,
            },
        };

        if (shouldUpdateFiltersState) {
            setShouldUpdateFiltersState(false);
            onFiltersChange(payload);
        }

        if (shouldUpdateSortState) {
            setShouldUpdateSortState(false);
            onFiltersChange(payload);
        }
    }, [selectedKey, sortByTenantName]);

    useEffect(() => {
        if (shouldUpdateFilterActive) {
            onFiltersChange({ filterType: filterData.filter_type, key: 'active', value: isFilterActive });
            setShouldUpdateFilterActive(false);
        }
    }, [isFilterActive]);

    const toggleFilterActive = (data: { checked: boolean }) => {
        setShouldUpdateFilterActive(true);
        setIsFilterActive(data.checked);
    };

    const toggleSortByTenantName = (data: { checked: boolean }) => {
        setShouldUpdateSortState(true);
        setSortByTenantName(data.checked);
    };

    const onChoiceChange = useCallback((_: FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
        setShouldUpdateFiltersState(true);
        setSelectedKey(data.value);
    }, []);

    return (
        <CollapseWrapper>
            <Collapse
                isCollapsed={!isFilterActive}
                additionalControl={
                    <CheckboxWrapper>
                        <Checkbox id={'Passway filter'} selected={isFilterActive} handleToggle={toggleFilterActive} />
                    </CheckboxWrapper>
                }
                additionalControlPosition="before"
                label={t('Passway filter')}
                iconJustify="space-between"
                hover="background-none"
                icon="chevron-right"
                iconPosition="right"
            >
                <RadioGroup disabled={!isFilterActive} value={selectedKey} onChange={onChoiceChange}>
                    {(filterData.filter_state as IDataObjectsPasswayTypeFilterState)?.passway_filter_types.map(
                        (element) => (
                            <Radio key={element.key} value={element.key} label={t(element.text)} />
                        ),
                    )}
                </RadioGroup>
                <SortByTenantNameWrapper>
                    <Checkbox
                        handleToggle={toggleSortByTenantName}
                        label={t('Sort by tenant name')}
                        disabled={!isFilterActive}
                        selected={sortByTenantName}
                    />
                </SortByTenantNameWrapper>
            </Collapse>
        </CollapseWrapper>
    );
};

export default Filter;
