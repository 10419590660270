import { useId, Input, Label, InputOnChangeData } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { IProps } from './interfaces';
import { useState, useEffect, FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, Wrapper, useStyles, DescriptionWrapper, NoPermissionsWrapper } from './styles';
import Button from 'src/components/Button/Button';
import { generalReducerValues } from 'src/General.reducer';
import { useAppDispatch } from 'src/hooks/useSettings';
import { useWidgetCurrentOptions } from 'src/hooks/useWidgetCurrentOptions';
import { postUserDashboards } from 'src/Dashboards.reducer';

const SharedDashboardModalBody: FC<IProps> = ({ sharedDashboard, closeModal }) => {
    const underlineId = useId('input-underline');
    const styles = useStyles();

    const [isValidPanel, setIsValidPanel] = useState(true);
    const [name, setName] = useState(sharedDashboard?.dashboard_name ?? '');
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { currentModuleID, modulesConfig, user } = useSelector(generalReducerValues);
    const currentOptions = useWidgetCurrentOptions(currentModuleID);

    useEffect(() => {
        /**
         * Проверяем, есть ли все пермишшены для модуля из дашборда в пермишшенах юзера
         */
        const presetModulePermissions = modulesConfig.find(
            (item) => item.module_id === sharedDashboard?.module_id,
        )?.permissions;
        const userPermissions = user?.permissions;

        if (presetModulePermissions?.some((permission) => !userPermissions?.includes(permission))) {
            setIsValidPanel(() => false);
        }
    }, [modulesConfig, sharedDashboard?.module_id, user?.permissions]);

    /**
     * Удаление или сохранение дашборда
     */
    const onButtonClick = () => {
        if (sharedDashboard) {
            dispatch(postUserDashboards({ dashboards: [{ ...sharedDashboard, dashboard_name: name }] }));
        }
        closeModal();
    };

    const onCancelClick = () => {
        closeModal();
    };

    const handleNameInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setName(() => data.value);
    if (!currentOptions || !sharedDashboard) return null;

    return isValidPanel ? (
        <Wrapper>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId} size="large">
                    {t('Save the dashboard panel?')}
                </Label>
            </div>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId}>
                    {t('Name')}
                </Label>
                <Input
                    onChange={handleNameInput}
                    value={name}
                    placeholder={t('Name')}
                    appearance="underline"
                    id={underlineId}
                />
            </div>

            <ButtonWrapper>
                <Button text={t('Cancel')} onClick={onCancelClick} />
                <Button text={t('Save')} appearance="primary" onClick={onButtonClick} />
            </ButtonWrapper>
        </Wrapper>
    ) : (
        <NoPermissionsWrapper>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId}>
                    {t('You have no permissions to use this dashboard panel.')}
                </Label>
            </div>

            <ButtonWrapper>
                <Button text={t('Cancel')} appearance="primary" onClick={onCancelClick} />
            </ButtonWrapper>
        </NoPermissionsWrapper>
    );
};

export default SharedDashboardModalBody;
