import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPreset, IPresetReducerState } from './interfaces';
import { AppThunk, RootState } from '../../store';
import { ISelectedOption } from '../Selects/Select/interfaces';
import { deleteRequest, getRequest, postRequest } from '../../tools/API/appBackendAPI';
import { ISettings } from '../UserSettings/interfaces';
import { addNewGlobalSpinnerItem, deleteGlobalSpinnerItemById } from '../../General.reducer';
import generateId from '../../tools/generateId';
import { cloneDeep } from 'lodash';

const initialState: IPresetReducerState = {
    selectedPreset: null,
    selectedUserPresetIds: {},
    availablePresets: {},
    sharedPresetHash: null,
    userPresets: [],
};

export const PresetReducer = createSlice({
    name: 'PresetReducer',
    initialState,
    reducers: {
        /**
         * Запись выбранного пресета
         */
        storeSelectedPreset: (state, action: PayloadAction<ISelectedOption | null>) => {
            state.selectedPreset = action.payload;
        },
        /**
         * Запись выбранного пресета
         */
        storeSelectedUserPresetIds: (state, action: PayloadAction<{ moduleId: string; presetId: number | null }>) => {
            state.selectedUserPresetIds[action.payload.moduleId] = action.payload.presetId;
        },

        /**
         * Запись доступных пресетов
         */
        storeAvailablePresets: (state, action) => {
            state.availablePresets = action.payload;
        },

        /**
         * Запись доступных пресетов
         */
        storeUserPresets: (state, action: PayloadAction<IPreset[]>) => {
            state.userPresets = action.payload;
        },

        storeSharedPresetHash: (state, action: PayloadAction<string | null>) => {
            state.sharedPresetHash = action.payload;
        },

        resetSelectedUserPresetIds: (state) => {
            state.selectedUserPresetIds = {};
        },

        /**
         * Обнуление редьюсера
         */
        resetPresetReducer: () => initialState,
    },
});

export const {
    storeSelectedPreset,
    storeAvailablePresets,
    resetSelectedUserPresetIds,
    resetPresetReducer,
    storeSharedPresetHash,
    storeSelectedUserPresetIds,
    storeUserPresets,
} = PresetReducer.actions;

export const PresetReducerValues = (state: RootState) => state.PresetReducer;

export default PresetReducer.reducer;

export const postUserPreset =
    ({
        name,
        settings,
        description = '',
        moduleId,
    }: {
        name: string;
        settings: ISettings;
        description?: string;
        moduleId?: string;
    }): AppThunk =>
    (dispatch, getState) => {
        const { token, user, urlsByServices, initialDataReceived, currentModuleID } = getState().GeneralReducer;
        const url = urlsByServices?.['app/app-backend'].USER_PRESETS_URL;

        if (!user?.id || !token || !initialDataReceived || !url) return;
        const data: Partial<IPreset> = {
            user_id: user.id,
            description: description || '',
            name,
            module_id: moduleId ? moduleId : currentModuleID,
            settings,
        };
        // const spinnerId = generateId();
        // dispatch(addNewGlobalSpinnerItem({ id: spinnerId, text: 'Loading presets...' }));
        postRequest(url, token, data).then((res) => {
            getRequest(`${url}?user_id=${user?.id}`, token).then((response) => {
                dispatch(storeUserPresets(response.data));
                // dispatch(deleteGlobalSpinnerItemById(spinnerId));
                dispatch(storeSelectedUserPresetIds({ moduleId: currentModuleID, presetId: res.data.id }));
            });
        });
    };

export const deleteUserPreset =
    (id: number): AppThunk =>
    (dispatch, getState) => {
        const { token, user, urlsByServices, initialDataReceived, currentModuleID } = getState().GeneralReducer;
        const url = urlsByServices?.['app/app-backend'].USER_PRESETS_URL;
        if (!user?.id || !token || !initialDataReceived || !url) return;
        deleteRequest(url, token, id).then((res) => {
            getRequest(`${url}?user_id=${user?.id}`, token).then((response) => {
                dispatch(storeUserPresets(response.data));
                dispatch(storeSelectedUserPresetIds({ moduleId: currentModuleID, presetId: null }));
            });
        });
    };
