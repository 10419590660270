import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { IDateRange, IPeriod, IPeriodObj } from '../General.interfaces';
import { detailOptions } from './detailOptions';
import { TTimeFreq } from '../tools/API/interfaces';

export interface IChartDetailing {
    id: TTimeFreq | string;
    text: string;
}

/**
 * Функция для получения опций детализации для графиков
 * @param t функция для перевода
 * @returns масиив с опциями для селекта детализации графиков
 */
const getChartDataDetailing = (
    t: TFunction,
    mainDateRanges: IDateRange[],
    mainPeriod?: IPeriod,
    period?: IPeriodObj,
) => {
    const chartDetailing = detailOptions
        ?.filter((item) => item.id !== null)
        .map((item) => ({ ...item, text: t(item.text) }));
    let availableIds = ['H', 'D'];
    const currentPeriod = mainDateRanges?.find((element) => element.id === mainPeriod?.id)?.period || period;

    if (currentPeriod) {
        const dateDiffrence = DateTime.fromISO(currentPeriod.dateTo)
            .diff(DateTime.fromISO(currentPeriod.dateFrom), ['days'])
            .toObject().days;

        if (dateDiffrence !== undefined) {
            if (dateDiffrence < 6) {
                availableIds = ['15MIN', 'H', 'D'];
            } else if (dateDiffrence >= 6 && dateDiffrence <= 7) {
                availableIds = ['H', 'D', 'W-MON'];
            } else if (dateDiffrence > 7 && dateDiffrence < 27) {
                availableIds = ['D', 'W-MON'];
            } else if (dateDiffrence >= 27) {
                availableIds = ['D', 'W-MON', 'MS'];
            } else {
                availableIds = ['D', 'W-MON', 'MS', 'YS'];
            }
        }
    }

    const result = chartDetailing?.filter((element) => availableIds.includes(element.id as string));

    return result;
};

export default getChartDataDetailing;
