import styled from 'styled-components';

export const Wrapper = styled.button<{ open: boolean }>`
    display: none;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 4px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:focus {
        outline: none;
    }

    div {
        width: 20px;
        height: 3px;
        background: ${({ theme, open }) => (open ? '#212529' : '#212529')};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 0;

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(-8px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        display: flex;
    }
`;
