import { IPeriod, IDateRange } from '../General.interfaces';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, storeSelectedLocationId } from '../General.reducer';
import { storeCurrentOptions } from '../components/UserSettings/reducer';
import { useTranslation } from 'react-i18next';
import { userSettingsReducerValues } from '../components/UserSettings/reducer';
import { generateMainDateRanges } from '../tools/generateMainDateRanges';
import { generateCompareDateRanges } from '../tools/generateCompareDateRanges';
import { DS } from '../constants/constants';

export const getRest = (name: string) => {
    const arr = name.split(DS);
    arr.shift();
    return arr.join(DS);
};

export const appendRest = (name: string, rest: string) => {
    return rest ? `${name}${DS}${rest}` : name;
};

/**
 * Кастомный хук для получения текущиз настроек модуля
 */
const useCurrentOptions = (): void => {
    const { t } = useTranslation();
    const { settings } = useSelector(userSettingsReducerValues);
    const { selectedLocationId, currentModuleID, lang, timeZone, modulesConfig } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    useEffect(() => {
        const config = modulesConfig?.find((item) => item.module_id === currentModuleID);
        if (config && currentModuleID && selectedLocationId && timeZone) {
            const newCurrentOptions: { [x: string]: any } = {};

            settings?.global &&
                Object.entries(settings['global']).forEach((entry) => {
                    const [key, value] = entry;
                    newCurrentOptions[key] = value;
                });

            Object.keys(config.store_method).forEach((key) => {
                const options: string[] = config.store_method[key];
                options.forEach((option) => {
                    switch (key) {
                        case 'byML':
                            newCurrentOptions[option] =
                                settings?.[`${key}:${currentModuleID}:${selectedLocationId}`]?.[option];
                            break;
                        case 'byM':
                            newCurrentOptions[option] = settings?.[`${key}:${currentModuleID}`]?.[option];
                            break;
                        case 'byL':
                            newCurrentOptions[option] = settings?.[`${key}:${selectedLocationId}`]?.[option];
                            break;
                        // case 'global':
                        //     newCurrentOptions[option] = settings?.[key]?.[option];
                        //     break;

                        default:
                            break;
                    }
                });
            });

            // if (newCurrentOptions?.['mainPeriod']) {
            //     const mainDateRanges: IDateRange[] = generateMainDateRanges(
            //         newCurrentOptions.mainPeriod as IPeriod,
            //         t,
            //         timeZone,
            //     );
            //     newCurrentOptions['mainDateRanges'] = mainDateRanges;
            // }

            // На случай если нужно в одном модуле обрабатывать разные мэйн и компарисон периоды 
            // введен механизм с выделением и присвоением остатка от названия селекта getRest и  appendRest

            Object.entries(newCurrentOptions).forEach((entry) => {
                const [key, value] = entry;
                if (key.includes('mainPeriod')) {
                    const mainDateRanges: IDateRange[] = generateMainDateRanges(value as IPeriod, t, timeZone);
                    let name = 'mainDateRanges';
                    const rest = getRest(key);
                    if (rest) {
                        name = appendRest(name, rest);
                    }
                    newCurrentOptions[name] = mainDateRanges;
                }
            });

            Object.entries(newCurrentOptions).forEach((entry) => {
                const [key, value] = entry;
                if (key.includes('mainPeriod')) {
                    const rest = getRest(key);

                    if (
                        newCurrentOptions?.[appendRest('mainDateRanges', rest)] &&
                        newCurrentOptions?.[appendRest('comparePeriods', rest)]
                    ) {
                        const compareDateRanges: IDateRange[] = generateCompareDateRanges(
                            newCurrentOptions?.[appendRest('mainDateRanges', rest)],
                            newCurrentOptions?.[appendRest('comparePeriods', rest)] as IPeriod[],
                            value as IPeriod,
                            t,
                            lang,
                        );
                        newCurrentOptions[appendRest('compareDateRanges', rest)] = compareDateRanges;
                    }
                }
            });

            // if (
            //     newCurrentOptions?.['mainDateRanges'] &&
            //     newCurrentOptions?.['comparePeriods'] &&
            //     newCurrentOptions?.['mainPeriod']
            // ) {
            //     const compareDateRanges: IDateRange[] = generateCompareDateRanges(
            //         newCurrentOptions?.['mainDateRanges'],
            //         newCurrentOptions.comparePeriods as IPeriod[],
            //         newCurrentOptions.mainPeriod as IPeriod,
            //         t,
            //         lang,
            //     );
            //     newCurrentOptions['compareDateRanges'] = compareDateRanges;
            // }

            dispatch(
                storeCurrentOptions({
                    ...newCurrentOptions,
                    selectedLocationId,
                    currentModuleID,
                }),
            );
        }
    }, [currentModuleID, dispatch, lang, selectedLocationId, settings, t, timeZone]);

    useEffect(() => {
        if (Number(settings?.global?.['pl']?.[0]?.id) !== selectedLocationId) {
            dispatch(storeSelectedLocationId(Number(settings?.global?.['pl']?.[0]?.id)));
        }
    }, [dispatch, selectedLocationId, settings?.global]);
};

export default useCurrentOptions;
