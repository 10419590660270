/**
 * Проверка на объекты не подключенные к шопстеру
 */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeNewAlert } from '../../../../../../components/Alert/reducer';
import { DS } from '../../../../../../constants/constants';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { Reports_Intersections_Widget_Reducer_Values } from '../reducer';

export const useCheckShopster = () => {
    const {
        currentModuleID,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch()
    const { keyWord } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        const mainObjId = localCurrentOptions?.[
            keyWord ? `mainObjectsIds${DS}${keyWord}` : 'mainObjectsIds'
        ]?.[0] as number;

        const compareObjectsIds = localCurrentOptions?.[
            keyWord ? `compareObjectsIds${DS}${keyWord}` : 'compareObjectsIds'
        ] as number[];

        if (mainObjId && compareObjectsIds) {
            const externalIds = [mainObjId, ...compareObjectsIds].map((id) => {
                return reportingObjectsById[id].external_ids.reduce((acc, obj) => {
                    return [...acc, obj.data_source];
                }, []);
            });

            if (externalIds.some((arr) => !arr.includes('shopster'))) {
                dispatch(
                    storeNewAlert({
                        type: 'warning',
                        text: 'Reporting objects without shopster selected!',
                        duration: 10,
                    }),
                );
                setChecked(() => false);
            } else {
                setChecked(() => true);
            }
        }
    }, [keyWord, localCurrentOptions, reportingObjectsById]);

    return checked;
};
