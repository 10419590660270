import { TFunction } from 'i18next';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import { isNull } from 'lodash';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';
import { theme } from 'src/theme';
import { AVERAGE_SERIES_ID_PART } from '../../constants/constants';
import { DS } from '../../../../../constants/constants';

interface IArgs {
    series: Array<TDynamicsSeries>;
    seriesIdsForAverage: string[];
    t: TFunction;
}

const generateAverageSeries = (args: IArgs) => {
    const { series, seriesIdsForAverage, t } = args;

    return series.reduce((acc: TDynamicsSeries[], value, seriesIndex) => {
        if (value.id && seriesIdsForAverage.includes(value.id)) {
            const filteredData = (value.data || []) as IExtendedChartPoint[];
            if (filteredData.length && filteredData.length > 1) {
                const data = filteredData.reduce(
                    (acc: { sum: number; count: number; min: number; max: number }, value) => {
                        if ((value.x as number) > acc.max) acc.max = value.x as number;
                        if ((value.x as number) < acc.min) acc.min = value.x as number;
                        acc.sum += value.y as number;
                        acc.count += 1;
                        return acc;
                    },
                    {
                        min: filteredData[0].x,
                        max: filteredData[0].x,
                        count: 0,
                        sum: 0,
                    },
                );

                const { sum, count, min, max } = data as { sum: number; count: number; min: number; max: number };

                const result: TDynamicsSeries = {
                    name: `${t('Average for')} ${value.name || ''}`,
                    id: `${AVERAGE_SERIES_ID_PART}${DS}${value.name || ''}${DS}${seriesIndex}`,
                    color: theme.seriesColors[seriesIndex % theme.seriesColors.length],
                    dashStyle: 'Dash',
                    isMain: false,
                    type: 'line',
                    yAxis: value.yAxis,
                    data: [
                        {
                            y: Math.round(sum / count),
                            showTooltipBody: false,
                            name: '',
                            x: min,
                        },
                        {
                            y: Math.round(sum / count),
                            showTooltipBody: false,
                            name: '',
                            x: max,
                            dataLabels: {
                                enabled: true,
                                format: '{y}',
                            },
                        },
                    ] as IExtendedChartPoint[],
                };
                acc.push(result);
            }
        }
        return acc;
    }, []);
};

export default generateAverageSeries;
