import { MetricInfoWrapper, MetricSettingsWrapper, Title, Text } from './styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import { generalReducerValues } from '../../../../General.reducer';
import { IMetric } from '../../../../General.interfaces';
import { comebackWindowInfo, engagementThresholdInfo } from './constants/constants';
import Stack from '../../../Stack/Stack';
import { useAppDispatch } from '../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../UserSettings/reducer';

/**
 * Компонент для отображения вкладки с настройками метрик Шопстера
 */
const Shopster = () => {
    const { t } = useTranslation();
    const { allMetrics, currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const textMap = {
        comeback_window: {
            labelText: t('Comeback window'),
            text: t('Select comeback window'),
            info: comebackWindowInfo,
        },
        engagement_threshold: {
            labelText: t('Engagement threshold'),
            text: t('Select engagement threshold'),
            info: engagementThresholdInfo,
        },
    };

    const allMetricsParams = allMetrics
        .filter((item) => Object.keys(item.optional_metric_params).length)
        .reduce((acc: IMetric[], item: IMetric) => {
            return [...acc, item.optional_metric_params];
        }, [])
        .reduce((acc, item) => {
            return { ...acc, ...item };
        }, {});

    const selects = useMemo(() => {
        return Object.entries(allMetricsParams)
            .filter((entry) => entry[0] !== 'expected_currency')
            .map((entry) => {
                const [key, value] = entry;
                const options = value?.enum?.map((element: string) => {
                    const text = element[0] !== '1' ? `${element}s` : element;
                    const text1 = `${text.split(' ')[0]} ${t(text.replace(/[^a-z]/g, ''))}`;
                    return {
                        id: element,
                        text: text1,
                    };
                });

                return (
                    <MetricSettingsWrapper key={key}>
                        <UniversalSelect
                            isGlobal
                            mode="single"
                            options={options}
                            name={key}
                            labelText={textMap[key].labelText}
                            text={textMap[key].text}
                            dropdownWidth="sm"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        {textMap[key].info.map((element: { title: string; text: string }) => {
                            return (
                                <MetricInfoWrapper key={element.title}>
                                    <Title>{t(element.title)}</Title>
                                    <Text>{t(element.text)}</Text>
                                </MetricInfoWrapper>
                            );
                        })}
                    </MetricSettingsWrapper>
                );
            });
    }, [allMetricsParams, t]);

    return <Stack gap={20}>{selects}</Stack>;
};

export default Shopster;
