import { memo, useContext, useEffect, useState } from 'react';
import filterReportingObjects from './tools/filterReportingObjects';
import sortTenants from './tools/sortReportingObjects';
import { cloneDeep } from 'lodash';
import { TableOfObjectsContext } from '../../context';
import { StyledTable } from './styles';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';

/**
 * Комопонент для отображения таблицы
 */
const Table = memo(() => {
    const context = useContext(TableOfObjectsContext);
    const { isRtlLanguage } = useSelector(generalReducerValues);
    const [filteredReportingObjects, setFilteredReportingObjects] = useState(
        cloneDeep(context?.extendedReportingObjects),
    );
    const [sortedReportingObjects, setSortedReportingObjects] = useState(cloneDeep(context?.extendedReportingObjects));

    useEffect(() => {
        if (context) {
            setFilteredReportingObjects(context.extendedReportingObjects);
        }
    }, [context?.extendedReportingObjects]);

    useEffect(() => {
        setSortedReportingObjects(filteredReportingObjects);
    }, [filteredReportingObjects]);

    /** Получение отфильтрованных отчетных объектов */
    useEffect(() => {
        if (
            context &&
            Object.keys(context.tenant2FloorByTenantId).length &&
            Object.keys(context.tenant2ZoneByTenantId).length &&
            context.selectedObjectsType === 'tenant' &&
            context.extendedReportingObjects &&
            context.filters?.length &&
            context.mainDateRanges &&
            context.mainPeriod
        ) {
            const filteredReportingObjects = filterReportingObjects({
                extendedReportingObjects: context.extendedReportingObjects,
                dataObj2ProjectCategory: context.dataObj2ProjectCategory,
                tenant2FloorByTenantId: context.tenant2FloorByTenantId,
                tenant2ZoneByTenantId: context.tenant2ZoneByTenantId,
                reportingObjectsById: context.reportingObjectsById,
                mainDateRanges: context.mainDateRanges,
                mainPeriod: context.mainPeriod,
                filters: context.filters,
            });
            setFilteredReportingObjects(filteredReportingObjects);
        } else {
            setFilteredReportingObjects(cloneDeep(context?.extendedReportingObjects));
        }
    }, [
        context?.extendedReportingObjects,
        context?.tenant2FloorByTenantId,
        context?.tenant2ZoneByTenantId,
        context?.mainDateRanges,
        context?.mainPeriod,
        context?.filters,
    ]);

    /** Сортировка арендаторов */
    useEffect(() => {
        if (context?.tableSortData) {
            setSortedReportingObjects((reportingObjects) => {
                if (reportingObjects) {
                    return sortTenants({
                        dataForSort: context.tableSortData!,
                        reportingObjects,
                    });
                }
                return null;
            });
        }
    }, [context?.tableSortData, filteredReportingObjects]);


    return (
        <StyledTable isRtlLanguage={isRtlLanguage}>
            <Header />
            <Body sortedReportingObjects={sortedReportingObjects} />
        </StyledTable>
    );
});

export default Table;
