import { RefObject, useEffect } from 'react';

export const useStretchingText = (
    textRef: RefObject<HTMLDivElement | null>,
    containerRef: RefObject<HTMLDivElement | null>,
    textValue: string | number | undefined | null,
    isFetching: boolean,
): void => {
    useEffect(() => {
        if (containerRef?.current && textRef?.current && !isFetching) {
            const maxWidth = containerRef.current.offsetWidth - 40;
            const maxHeight = containerRef.current.offsetHeight - 20;
            let minFontSize = 20;
            let maxFontSize = 40;

            while (minFontSize <= maxFontSize) {
                const fontSize = Math.floor((minFontSize + maxFontSize) / 2);
                textRef.current.style.fontSize = `${fontSize}px`;
                textRef.current.style.lineHeight = `${fontSize}px`;

                if (textRef.current.offsetHeight <= maxHeight && textRef.current.offsetWidth <= maxWidth) {
                    minFontSize = fontSize + 1;
                } else {
                    maxFontSize = fontSize - 1;
                }
            }
        }
    }, [
        containerRef?.current?.offsetWidth,
        containerRef?.current?.offsetHeight,
        textRef?.current?.offsetHeight,
        textRef?.current?.offsetWidth,
        containerRef,
        isFetching,
        textValue,
        textRef,
    ]);
};
