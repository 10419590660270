import { IChartDetailing } from '../../../../../constants/chartDetailing';
import { IReportingObject, TLoadingData } from '../../../../../General.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { cloneDeep } from 'lodash';

import { IReducerState } from './interfaces';

const initialState: IReducerState = {
    moduleName: 'Work cases:Tenant discount calculator',
    chartMode: '2023',
};

const Cases_Calculator_Calculator_Widget_Reducer = createSlice({
    name: 'Cases_Calculator_Calculator_Widget_Reducer',
    initialState,
    reducers: {
        storeChartMode: (state, action: PayloadAction<string>) => {
            state.chartMode = action.payload;
        },
    },
});

export const { storeChartMode } = Cases_Calculator_Calculator_Widget_Reducer.actions;

export const Cases_Calculator_Calculator_Widget_Reducer_Values = (state: RootState) => state.Cases_Calculator_Calculator_Widget_Reducer;

export default Cases_Calculator_Calculator_Widget_Reducer.reducer;
