import filterReportingObjects from '../../../../../../components/Filters/tools/filterReportingObjects';
import {
    IDataObj2ProjectCategory,
    IPeriod,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2ZoneRelation,
} from '../../../../../../General.interfaces';
import { ITreeMapData } from '../interfaces';

interface IArgs {
    /** Ответ для метрики площади квадратиков (alias: "-:areaMetric") */
    data: ITreeMapData[];
    /** Объект отчетных объектов, гдед ключ - это id отчетного объекта */
    reportingObjectsById: { [id: string]: IReportingObject };
    /** Массив id фильтров (отчетных объектов) */
    filters?: number[];
    /** Выбранный тип отчетныйх объектов */
    selectedObjectsType: string;
    /** связи арендаторов и этажей */
    tenant2FloorByTenantId: { [tenantId: string]: ITenant2FloorRelation[] };
    /** связи арендаторов и зон */
    tenant2ZoneByTenantId: { [tenantId: string]: ITenant2ZoneRelation[] };
    /** Связь объектов и категорий */
    dataObj2ProjectCategory: IDataObj2ProjectCategory[];
    /** Периоды дат для основоного периода */
    mainDateRanges?: IPeriod[];
    /** основной период */
    mainPeriod?: IPeriod;
}

/**
 * Функция для фильтрации ответа по выбранным фильтрам арендатора
 * @returns отфильтрованные данные ответа с сервера
 */
const filterResponseResult = (args: IArgs): ITreeMapData[] => {
    const {
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        selectedObjectsType,
        data,
        mainDateRanges,
        mainPeriod,
        filters,
    } = args;

    if (selectedObjectsType === 'tenant' && filters?.length && mainDateRanges && mainPeriod) {
        const reportingObjects: IReportingObject[] = [];

        data.forEach((element) => {
            const id = element.objectId;
            if (id && reportingObjectsById[id]) reportingObjects.push(reportingObjectsById[id]);
        });

        const filteredReportingObjectsIds = filterReportingObjects({
            initialArray: reportingObjects,
            dataObj2ProjectCategory,
            tenant2FloorByTenantId,
            tenant2ZoneByTenantId,
            reportingObjectsById,
            mainDateRanges,
            mainPeriod,
            filters,
        }).map((element) => element.id);

        return data.filter((element) => element.objectId && filteredReportingObjectsIds.includes(element.objectId));
    }
    return data;
};

export default filterResponseResult;
