import { FC, useEffect, useMemo } from 'react';
import { generalReducerValues, updateExtendedDataObjectsSelectFilters } from '../../../General.reducer';
import { getReportingObjects, IOptionsOfReportingObjects } from '../../../tools/getReportingObjects';
import { getSettings } from '../../../tools/getSettings';
import { IProps } from './interfaces';
import { StyledDeleteIcon, Wrapper } from './styles';
import { useSelector } from 'react-redux';
import {
    IDataObjectsSelectFilter,
    IUpdateDataObjectsSelectFiltersPayload,
} from '../../../hooks/dataObjectsSelectFilters/interfaces';
import filterObjects from './tools/filterObjects';
import { cloneDeep, isNull } from 'lodash';
import { CATEGORY_WORD, DS, ZONES_WORD } from '../../../constants/constants';
import _ from 'lodash';
import Select from '../Select/Select';
import { TSelectedOptions } from '../Select/interfaces';
import { getObjSectionName } from '../../../tools/Strings/getObjSectionName';
import { useAppDispatch } from '../../../hooks/useSettings';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import AdditionalFilters from './components/AdditionalFilters/AdditionalFilters';
import { getRest } from '../../../hooks/useCurrentOptions';
import { IReportingObject } from '../../../General.interfaces';

const isTenantsModules = (moduleName: string) => {
    return (
        moduleName === 'Reports:By tenants' ||
        moduleName === 'Leasing:Tenant overview' ||
        moduleName.split(DS)[1] === 'Path to purchase' ||
        moduleName === 'Leasing:Cross-visits'
    );
};

const isLeasingTenantsModule = (module: string) => {
    return module === 'Leasing:Tenants';
};

/**
 * Компонент для выбора отчетных объектов
 */
const ObjectsSelect: FC<IProps> = ({
    mode = 'multi',
    maxSelected,
    minSelected = 1,
    label = 'Objects',
    validOptions,
    canSelectEntireSection = false,
    dataObjectFilters = [],
    name = 'selectedReportingObjectsIds',
    localCurrentOptions,
    changeOptions,
    text = 'Selected',
    iconType = 'chevron-down',
    showSelectedOptionsCount = true,
    ...props
}) => {
    const rest = getRest(name);

    let mainPeriod = localCurrentOptions?.mainPeriod;
    let mainDateRanges = localCurrentOptions?.mainDateRanges;
    if (rest) {
        mainPeriod = localCurrentOptions?.[`mainPeriod${DS}${rest}`];
        mainDateRanges = localCurrentOptions?.[`mainDateRanges${DS}${rest}`];
    }
    const {
        src: { reportingObjects, projectCategories },
        extendedDataObjectsSelectFiltersByType,
        cfg: {
            reportingObjectsByType,
            tenant2FloorByTenantId,
            reportingObjectsByTypeAndMarker,
            tenant2PlaceByTenantId,
        },
        selectedLocationId,
        currentModuleID,
        modulesConfig,
        structures,
    } = useSelector(generalReducerValues);

    const { t } = useTranslation(['translation', 'translationCategories']);
    const dispatch = useAppDispatch();

    /** Функция сортировки айдишников объектов иак, чтобы локация всегда была первой */
    const sortIds = <T extends unknown>(reportingObjectsByType: { [x: string]: IReportingObject[] }, ids: T[]): T[] => {
        const locationId: number = reportingObjectsByType?.['location']?.[0]?.id;
        const result = cloneDeep(ids).sort((a: T, b: T) => {
            if (Number(a) === locationId) {
                return -1;
            } else {
                return 0;
            }
        });
        return result;
    };

    /** Запись id отчетных объектов, если нет выбранных */
    useEffect(() => {
        if (!reportingObjects.length || !selectedLocationId || !currentModuleID) return;

        if (localCurrentOptions && !localCurrentOptions?.[name] && selectedLocationId) {
            let ids: number[] = [];
            if (reportingObjectsByType['location'] && mode === 'multi') {
                if (isTenantsModules(currentModuleID)) {
                    ids = [reportingObjectsByType?.['tenant']?.[0]?.id];
                } else if (isLeasingTenantsModule(currentModuleID)) {
                    ids = [];
                } else {
                    ids = [reportingObjectsByType['location'][0].id];
                }
            } else if (mode === 'single') {
                ids = [options[0]?.['id']];
            } else {
                ids = [];
            }

            changeOptions({
                newOptions: {
                    [name]: sortIds<number>(reportingObjectsByType, ids),
                },
            });
        }
    }, [
        currentModuleID,
        reportingObjects,
        reportingObjectsByType,
        localCurrentOptions,
        selectedLocationId,
        localCurrentOptions?.[name],
    ]);

    /** Все активные фильтры */
    const activeFilters: IDataObjectsSelectFilter[] = useMemo(() => {
        return Object.values(extendedDataObjectsSelectFiltersByType).filter((element) => element.active);
    }, [extendedDataObjectsSelectFiltersByType]);

    /** Настройки для селекта (опции селекта) */
    const options = useMemo(() => {
        const result: IOptionsOfReportingObjects[] = [];

        let filteredReportingObjectsByType = props.validateObjects
            ? props.validateObjects(reportingObjectsByType)
            : cloneDeep(reportingObjectsByType);

        if (structures && mainDateRanges && mainPeriod && activeFilters.length) {
            filteredReportingObjectsByType = filterObjects({
                passway2DataObj: structures.relations_passway2dataobj,
                dataObjects: structures.elements_ms_data_objects,
                reportingObjectsByTypeAndMarker,
                reportingObjectsByType: filteredReportingObjectsByType,
                tenant2FloorByTenantId,
                tenant2PlaceByTenantId,
                dataObjectFilters,
                mainDateRanges,
                activeFilters,
                mainPeriod,
                t,
            });
        }

        if (!validOptions) {
            result.push(...getReportingObjects(filteredReportingObjectsByType, t));
        } else {
            result.push(
                ...getReportingObjects(filteredReportingObjectsByType, t)?.filter((item) => {
                    return (
                        validOptions?.map((item) => t(item)?.toLowerCase())?.includes(item.section?.toLowerCase()) ||
                        (validOptions?.map((item) => item?.toLowerCase())?.includes('zones') &&
                            item.section?.includes(t(ZONES_WORD))) ||
                        (validOptions?.map((item) => item?.toLowerCase())?.includes('categories') &&
                            item.section?.includes(t(CATEGORY_WORD)))
                    );
                }),
            );
        }

        if (mode === 'single' && validOptions?.length === 1) {
            return _.sortBy(result[0]?.objects || [], (item) => item.order);
        } else {
            return _.sortBy(result, (item) => item.order);
        }
    }, [
        reportingObjectsByTypeAndMarker,
        reportingObjectsByType,
        tenant2FloorByTenantId,
        tenant2PlaceByTenantId,
        projectCategories,
        dataObjectFilters,
        activeFilters,
        validOptions,
        structures,
        mainPeriod,
        t,
    ]);

    /** Выбранные отчетные объекты */
    const outsideSelected: TSelectedOptions = useMemo(() => {
        const selectedIds = localCurrentOptions?.[name] || [];
        return reportingObjects
            ?.filter((item) => selectedIds.includes(item.id))
            .map((item) => {
                return {
                    ...item,
                    section: getObjSectionName({ reportingObject: item, t }),
                    text: item.name,
                };
            });
    }, [localCurrentOptions?.[name], reportingObjects, t]);

    const storeObjectIds = (selectedReportingObjectsIds: number[], locationId: number | null, module: string) => {
        if (locationId) {
            changeOptions({
                newOptions: {
                    [name]: sortIds<number>(reportingObjectsByType, selectedReportingObjectsIds),
                },
            });
        }
    };

    const handleSelect = (args: { id: number | null | string; block_type?: string }[]) => {
        const selectedIds: (number | null | string)[] = [];
        args.forEach((item: { id: number | null | string }) => {
            if (item) {
                selectedIds.push(item.id);
            }
        });
        props.handleSelect && props.handleSelect(selectedIds);
        const selectedReportingObjectsIds: number[] = args
            ?.filter((item) => Boolean(item))
            .map((item) => item.id as number);
        storeObjectIds(selectedReportingObjectsIds, selectedLocationId, currentModuleID);
    };

    const onFiltersChange = (data: IUpdateDataObjectsSelectFiltersPayload) => {
        dispatch(updateExtendedDataObjectsSelectFilters(data));
    };

    const max = maxSelected ? ` (${t('max')}: ${maxSelected})` : '';

    return (
        <Wrapper>
            <Select
                testId={`${currentModuleID}_selectedReportingObjectsIds`}
                additionalControl={<StyledDeleteIcon />}
                additionalFilterControl={
                    Boolean(dataObjectFilters.length) && (
                        <AdditionalFilters
                            extendedDataObjectsSelectFiltersByType={extendedDataObjectsSelectFiltersByType}
                            dataObjectFilters={dataObjectFilters}
                            onFiltersChange={onFiltersChange}
                            testId={`${currentModuleID}_selectedReportingObjectsIds:filter:button`}
                        />
                    )
                }
                canSelectEntireSection={canSelectEntireSection}
                showSelectedOptionsCount={showSelectedOptionsCount}
                outsideSelected={outsideSelected}
                labelText={`${t(label)}${max}`}
                disabled={props.disabled}
                showSorting={true}
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                minSelected={minSelected}
                iconType={iconType || undefined}
                dropdownAlign="left"
                text={t(text)}
                dropdownWidth="md"
                options={options}
                showFilter={true}
                mode={mode}
                bgColor="info-light"
            />
        </Wrapper>
    );
};

export default ObjectsSelect;
