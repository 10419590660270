import { isNull } from 'lodash';
import {
    IBgReport,
    IBgReportType,
    IReportingObject,
    TBgReportStatus,
    TLang,
} from '../../../../../../../../../../General.interfaces';

export interface ITableItem {
    id: number;
    reportType: string;
    tenantName: string;
    tenantId: number;
    period: { dateFrom: string; dateTo: string };
    status: TBgReportStatus;
    jsonOutput: unknown;
    filesOutput: string[];
    createdAt: string;
}

interface IArgs {
    reports: IBgReport[];
    lang: TLang;
    backgroundReportTypesById: {
        [reportTypeId: string]: IBgReportType;
    };
    reportingObjectsById: {
        [x: string]: IReportingObject;
    };
}

const bgReportsTableDataAdapter = (args: IArgs): ITableItem[] => {
    const { backgroundReportTypesById, lang, reports, reportingObjectsById } = args;
    return reports.map((report) => {
        let tenantName = '';
        let tenantId = 0;
        if (
            typeof report.input_parameters === 'object' &&
            !isNull(report.input_parameters) &&
            'obj_ids' in report.input_parameters
        ) {
            tenantName = reportingObjectsById[String(report?.input_parameters?.['obj_ids']?.[0])]?.name || '';
            tenantId = reportingObjectsById[String(report?.input_parameters?.['obj_ids']?.[0])]?.id || 0;
        }
        return {
            id: report.id,
            reportType: backgroundReportTypesById[report.report_type]?.name || '',
            period: { dateFrom: report.date_from, dateTo: report.date_to },
            status: report.status,
            tenantName,
            tenantId,
            jsonOutput: report.output,
            filesOutput: report.files,
            createdAt: report.created_at,
        };
    });
};

export default bgReportsTableDataAdapter;
