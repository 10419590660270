import { AxiosResponse, AxiosError } from 'axios';
import { ResponseStatus } from './constants';

/**
 * Функция для проверки ответа на ошибку
 * @param response Ответ от сервера
 * @returns Объект с флагом ошибки и результатом (результат нужен для диспатча в случае ошибки)
 */
const checkResponseForError = (
    response: AxiosResponse | AxiosError,
): {
    isError: boolean;
    isCanceled: boolean;
    initialData: any;
    result: { status: ResponseStatus; message: string; alias?: string };
} => {
    let isCanceled = false;
    let initialData = {};

    if (response?.config?.data) {
        try {
            initialData = JSON.parse(response?.config?.data);
        } catch (error) {
            console.log('>>> error with parse initialData');
        }
    }

    if (typeof response?.['isAxiosError'] === 'boolean' && response?.['isAxiosError']) {
        let { message, code } = response as AxiosError;

        try {
            message = JSON.stringify(response['response']?.data);
        } catch (error) {
            console.log('>>> error with parse error response message');
        }

        if (code === 'ERR_CANCELED') isCanceled = true;
        return {
            isError: true,
            isCanceled,
            initialData,
            result: {
                status: ResponseStatus.Error,
                message,
                alias: initialData?.['input_parameters']?.alias,
            },
        };
    }

    return {
        isError: false,
        isCanceled,
        initialData,
        result: {
            status: ResponseStatus.Success,
            message: ResponseStatus.Success,
            alias: initialData?.['input_parameters']?.alias,
        },
    };
};

export default checkResponseForError;
