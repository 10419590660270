import { FC, useMemo, useRef } from 'react';
import { IProps } from './interfaces';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import Stack from '../../../../../components/Stack/Stack';
import Chart from './components/Chart/Chart';
import useFetchData from './api/useFetchData';
import { withLoading } from '../../../../../tools/API/withLoading';
import { Wrapper } from './styles/styles';
import getResponseStatus from '../../../../../tools/API/helpers/getResponseStatus';
import Tables from './components/Tables/Tables';
import './styles/styles.scss';

const DynamicsByMonths: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);

    const { t } = useTranslation(['metrics', 'translation']);
    const ref = useRef(null);

    const { data, error, isFetching, refetch } = useFetchData(props);

    const metricFromStore = allMetrics.find((item) => item.id === props.metric);

    const WithLoading = useMemo(() => {
        const Component = withLoading(Wrapper, {
            data: getResponseStatus({
                isFetching,
                error,
                data,
            }),
            height: 400,
        });

        return Component;
    }, [error, JSON.stringify(data), isFetching]);

    const reloadWidget = () => {
        refetch();
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {`${t('Dynamics by months')}: ${t(metricFromStore?.text || '')}, ${t(
                        metricFromStore?.units || '',
                    )}`}
                    <WidgetAdditionalControls
                        reloadHandlerNoDispatch={reloadWidget}
                        widgetName="Dynamics by months"
                        pdfDownloadData={{
                            targetRef: ref,
                        }}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <WithLoading>
                <Stack direction="column" gap={15}>
                    <Chart {...props} />
                    <Tables {...props} />
                </Stack>
            </WithLoading>
        </WidgetWrapper>
    );
};

export default DynamicsByMonths;
