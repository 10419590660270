import { DEFAULT_GENERAL_CABINET_PREFERENCES, DEFAULT_SIDEBAR_PREFERENCES } from '../constants/constants';
import { cabinetPreferencesValues, storeCabinetPreferences, storeSidebar } from '../reducer';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { generalReducerValues } from '../../../General.reducer';
import { getRequest, postRequest } from '../../../tools/API/appBackendAPI';
import { AxiosError, AxiosResponse } from 'axios';
import { mapBack2Front } from '../../../tools/mappingFrontAndBack';
import { cabinetPreferencesMapping } from '../../../constants/keysMapping';
import { IGeneralPreferences, ISidebarSettings } from '../interfaces';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useLocation } from 'react-router-dom';

/**
 * Кастомный хук для работы с настройками кабинета для пользователя
 */
const useCabinetPreferencesData = () => {
    const dispatch = useAppDispatch();
    const { user, token, urlsByServices } = useSelector(generalReducerValues);

    const cabinetPreferencesUrl = useMemo(() => {
        if (urlsByServices?.['app/app-backend']) return urlsByServices['app/app-backend'].CABINET_PREFERENCES_URL;
        return null;
    }, [urlsByServices]);

    useEffect(() => {
        if (user?.id && cabinetPreferencesUrl) {
            getRequest(cabinetPreferencesUrl, token).then((response: AxiosError | AxiosResponse) => {
                if (!response?.['data']?.length) {
                    if (response?.['data']) {
                        const data = { ...DEFAULT_GENERAL_CABINET_PREFERENCES, user_id: user.id };
                        postRequest(cabinetPreferencesUrl, token, data).then((response) => {
                            dispatch(
                                storeCabinetPreferences({
                                    preferences: mapBack2Front(
                                        cabinetPreferencesMapping,
                                        response?.['data'],
                                    ) as IGeneralPreferences,
                                }),
                            );
                        });
                    }
                } else {
                    const dataFromBack = response?.['data'][0];

                    const sidebar: ISidebarSettings = dataFromBack.sidebar || DEFAULT_SIDEBAR_PREFERENCES;
                    dispatch(storeSidebar(sidebar));

                    dispatch(
                        storeCabinetPreferences({
                            preferences: mapBack2Front(cabinetPreferencesMapping, dataFromBack) as IGeneralPreferences,
                        }),
                    );
                }
            });
        }
    }, [dispatch, token, user?.id, cabinetPreferencesUrl]);
};

export default useCabinetPreferencesData;
