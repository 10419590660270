import { DrinkCoffee24Filled } from '@fluentui/react-icons';
import styled from 'styled-components/macro';

export const Title = styled.div`
    font-size: 80px;
    line-height: 120%;
    color: var(--color-secondary-dark);
    font-weight: 500;
    display: flex;
    align-items: center;
`;

export const Cont = styled.div`
    width: 100%;
    height: 100%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Dash = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--color-secondary-dark);
    margin: var(--space-sm) 0;
`;

export const ErrorText = styled.span`
    width: 100%;
    color: var(--color-secondary-dark);
`;

export const iconStyles = {
    root: {
        fontSize: '140px',
        color: 'var(--color-primary)',
        height: '250px',
        display: 'flex',
        alignItems: 'center',
    },
};

export const StyledIcon = styled(DrinkCoffee24Filled)`
    height: 250px;
    width: 250px;
    display: flex;
    align-items: center;
    color: var(--color-primary);
`;

export const MainError = styled.div`
    margin-top: 50px;
    color: #757575;
    font-size: 50px;
    line-height: 60px;
    font-weight: 300;
    span {
        display: inline-block;
        margin-right: 20px;
        font-weight: 500;
    }
`;
