import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../../components/UserSettings/reducer';
import { Events_EventsMap_Widget_Reducer_Values } from '../../EventsMap/widgets/EventsMapWidget/reducer';
import { Events_Events_Module_Reducer_Values, storeCurrentEvent, storeSelectedEventId } from '../reducer';
import { useAppDispatch } from '../../../../hooks/useSettings';

const usePrepareData = () => {
    const { selectedEventId, currentEvent, moduleName } = useSelector(Events_Events_Module_Reducer_Values);
    const { extendedEventsDataById } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const { selectedLocationId } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useAppDispatch();

    /** Запись данных в глобальные селекты */
    useEffect(() => {
        if (currentEvent && localCurrentOptions?.selectedLocationId && localCurrentOptions?.currentModuleID) {
            dispatch(
                changeOptions({
                    newOptions: {
                        mainPeriod: {
                            id: 'selectCustom',
                            period: {
                                dateFrom: DateTime.fromISO(currentEvent.date_from).toFormat('yyyy-MM-dd'),
                                dateTo: DateTime.fromISO(currentEvent.date_to).toFormat('yyyy-MM-dd'),
                            },
                        },
                        selectedReportingObjectsIds: currentEvent.data_objects_ids,
                    },
                }),
            );
        }
    }, [currentEvent, localCurrentOptions?.selectedLocationId, localCurrentOptions?.currentModuleID]);

    /** Получение текущего события */
    useEffect(() => {
        dispatch(storeCurrentEvent(null));
        if (selectedEventId && selectedLocationId && extendedEventsDataById[selectedEventId]) {
            dispatch(storeCurrentEvent(extendedEventsDataById[selectedEventId]));
        } else {
            dispatch(storeSelectedEventId(null));
        }
    }, [dispatch, extendedEventsDataById, selectedEventId, selectedLocationId]);
};

export default usePrepareData;
