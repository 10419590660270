import React, { useMemo } from 'react';
import { PlateWrapper } from './styles';
import { IProps } from './interfaces';
import { Dashboards_Reducer_Values } from '../../../../Dashboards.reducer';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../../../hooks/useDebounce';

/** Обертка для отображения виджета (карточка) */
const WidgetPlate = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const { editPanelMode } = useSelector(Dashboards_Reducer_Values);
    /** Получение размеров виджета из стилей (можно сделать при помощи ссылки) */
    const widgetDimensions = useMemo(() => {
        if (
            props.style?.height &&
            props.style.width &&
            typeof props.style?.height === 'string' &&
            typeof props.style?.width === 'string'
        ) {
            const height = Number(props.style.height.split('px')[0]);
            const width = Number(props.style.width.split('px')[0]);
            return { width, height };
        }
        return null;
    }, [props.style]);

    const debouncedWidgetDimensions = useDebounce(widgetDimensions, 200);

    return (
        <PlateWrapper
            ref={ref}
            editPanelMode={editPanelMode}
            className={props.className}
            style={{ ...props.style }}
            onMouseDown={props.onMouseDown}
            onTouchEnd={props.onTouchEnd}
            onMouseUp={props.onMouseUp}
        >
            <>
                {React.cloneElement(props.children?.[0], { widgetDimensions: debouncedWidgetDimensions })}
                {React.cloneElement(props.children?.[1], { ...props.children?.[1]?.props, disabled: !editPanelMode })}
            </>
        </PlateWrapper>
    );
});

export default WidgetPlate;
