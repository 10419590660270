import { MenuItem } from '@fluentui/react-components';
import styled from 'styled-components/macro';
import { TTitleSize, TWrapperPaddingSize } from './interfaces';

export const Wrapper = styled.div<{ paddingSize: TWrapperPaddingSize }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: ${(p) => {
        switch (p.paddingSize) {
            case 'normal':
                return 'var(--space-xs)';

            default:
                return 'var(--space-xxxs)';
        }
    }};
`;

export const AnimationWrapper = styled.div`
    cursor: pointer;
    width: 100px;
    height: 100px;
    :hover {
        scale: 120%;
    }
`;

export const HeaderWrapper = styled.div`
    padding: 5px 12px 0px 12px;
`;

export const WidgetTitle = styled.div<{ size: TTitleSize }>`
    color: var(--color-primary-darker);
    max-width: 100%;
    font-weight: 500;
    font-size: ${(p) => {
        switch (p.size) {
            case 'normal':
                return '0.9rem';

            default:
                return '0.9rem';
        }
    }};
    line-height: ${(p) => {
        switch (p.size) {
            case 'normal':
                return 'normal';

            default:
                return '0.9rem';
        }
    }};
    flex-shrink: 0;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const NotificationsWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: var(--color-primary-darker); */
    font-size: 14px;
    /* position: absolute; */
    overflow: hidden;
    /* left: 0; */
    /* bottom: 0; */
    width: 100%;
    /* height: 100%; */
    /* z-index: 10; */
    background-color: white;

    label {
        /* color: var(--color-primary-darker); */
        font-size: 14px;
    }
`;

export const ContentWrapper = styled.div<{ hideTitle: boolean }>`
    margin-top: ${(p) => (p.hideTitle ? 0 : 'var(--space-xxxs)')};
    flex-grow: 1;
    overflow: hidden;
`;

export const WidgetAdditionalControlsWrapper = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 25;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: flex-end;
    width: 19px;
    height: 19px;
    border: 1px solid var(--color-secondary-darker);
    border-radius: 10px;
    background-color: var(--color-white);
    transition: width 0.5s;
    padding-right: 1px;

    svg {
        width: 15px;
        height: 15px;
    }
`;

export const BurgerButtonWrapper = styled.div`
    position: absolute;
    top: 0;
    left: -20px;
    background-color: white;
    z-index: 20;
`;

export const ContextualMenuBodyWrapper = styled.div`
    padding: 5px;
`;

export const StyledMenuItem = styled(MenuItem)`
    :hover {
        color: var(--color-primary) !important;
    }
`;

export const Label = styled.span<{ isRequired: boolean }>`
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    max-width: max-content;
    &:after {
        content: '*';
        display: ${(p) => (p.isRequired ? 'inline-block' : 'none')};
        position: absolute;
        right: -8px;
        color: red;
        top: -3px;
        opacity: 1;
    }
`;
