import styled from 'styled-components/macro';

export const NoEventsText = styled.div`
    color: rgba(0, 0, 0, 0.3);
    font-size: 16px;
`;

export const Wrapper = styled.div`
    height: 100%;
    overflow-y: auto;
`;

export const ContentWrapper = styled.div`
    padding: 5px;
`;
