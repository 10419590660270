import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '../../../../../../../../../components/Button/Button';
import { storeEventIdToDelete } from '../../../../reducer';

import { ButtonsWrapper, ContentWrapper, Wrapper } from './styles';

interface IProps {
    /** Закрытие модального окна */
    closeModal: () => void;
    /** Закрытие окна редактирования события */
    closeEventModalBody: () => void;
    /** Id события */
    eventId?: number;
}

/**
 * Компонент для подтверждения удаления события
 */
const DeleteConfirmModalBody: FC<IProps> = ({ eventId, closeModal, closeEventModalBody }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onDelete = () => {
        if (eventId) {
            dispatch(storeEventIdToDelete(eventId));
            closeEventModalBody();
            closeModal();
        }
    };

    return (
        <Wrapper>
            <ContentWrapper>
                <div>{t('You are going to delete the event. Continue?')}</div>
            </ContentWrapper>
            <ButtonsWrapper>
                <Button appearance="outline" onClick={closeModal} style={{ marginLeft: 'var(--space-xxs)' }}>
                    {t('Cancel')}
                </Button>
                <Button
                    appearance="primary"
                    disabled={!eventId}
                    onClick={onDelete}
                    style={{ marginLeft: 'var(--space-xxs)' }}
                >
                    {t('Ok')}
                </Button>
            </ButtonsWrapper>
        </Wrapper>
    );
};

export default DeleteConfirmModalBody;
