import React from 'react';
import { useTranslation } from 'react-i18next';
import { storeRemoveRating } from '../../reducer';
import { useDispatch } from 'react-redux';
import { Controls } from './styles';
import Button from '../../../../../../../components/Button/Button';
import Stack from '../../../../../../../components/Stack/Stack';

const ModalDeleteRating: React.FC<{ closeModal: () => void; ratingId?: number }> = ({ closeModal, ratingId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const buttonRemoveClick = () => {
        closeModal();
        if (ratingId) {
            dispatch(storeRemoveRating(ratingId));
        }
    };

    return (
        <Stack direction="column" justifyContent="space-between">
            <p> {t('You are going to delete the event. Continue?')}</p>
            <Controls>
                <Button onClick={closeModal} appearance="outline">
                    {t('Cancel')}
                </Button>
                <Button onClick={buttonRemoveClick} baseColor="accent">
                    {t('Remove')}
                </Button>
            </Controls>
        </Stack>
    );
};
export default ModalDeleteRating;
