import { makeStyles } from '@fluentui/react-components';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div<{ marginBottom?: string }>`
    max-height: ${() => theme.spaces.headerHeight};
    width: 100%;
    /* border-bottom: 1px solid var(--color-border-gray); */
    display: grid;
    grid-template-columns: calc(var(--space-sidebar) + var(--space-md)) auto;
    /* justify-content: center; */
    /* margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : 'var(--space-sm)')}; */
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    z-index: 90;

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        display: flex;
    }
`;

export const HeaderCentralArea = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: ${(p) => (p.theme.isRtlLanguage ? 'flex-start' : 'flex-end')};
    column-gap: var(--space-xs);
    justify-content: space-between;
    /* max-width: 1230px; */
    padding: 0 var(--space-md) 4px 0;
    padding-left: ${(p) => (p.theme.isRtlLanguage ? p.theme.spaces.md : 0)};
    padding-right: ${(p) => (!p.theme.isRtlLanguage ? p.theme.spaces.md : 0)};
    padding-bottom: 0;
    align-items: center;

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        padding: 10px var(--space-xs);
    }
    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
        padding: 10px var(--space-xxs);
    }
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        justify-content: space-between;
    }
`;

export const LangWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--space-xxxxs);
    width: 36px;
    height: 36px;
    border: 1px solid var(--color-border-gray);
`;

export const ControlsWrapper = styled.div`
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-xxs);
`;

export const LogoutWrapper = styled.div`
    /* margin-left: var(--space-xs); */
    cursor: pointer;
`;

export const TitleWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--space-xxs);
`;

export const LocationText = styled.div`
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
`;

export const DivBoxWrapperLogoutSC = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: 100%;
    gap: 10px;
`;

export const useInfoIconStyles = makeStyles({
    icon: {
        cursor: 'pointer',
        color: 'var(--color-secondary-darker)',
        ':hover': {
            color: 'var(--color-primary-dark)',
        },
    },
});
