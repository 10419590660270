import { IRawMetricDataByTimeRange, IYOYPeriodModalreducerState } from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../../../../store';
import { TLoadingData } from '../../../../../../../../../General.interfaces';

export const LOADING_DATA = { status: 'Loading', message: 'Loading...' };

const initialState: IYOYPeriodModalreducerState = {
    rawMetricsDataByTimeRange: null,
    rawMetricsData: LOADING_DATA,
};

const Performance_YoY_YoY_PeriodModalBody_Component_Reducer = createSlice({
    name: 'Performance_YoY_YoY_PeriodModalBody_Component_Reducer',
    initialState,
    reducers: {
        /**
         * Запись сырых данных
         */
        storeRawMetricsData: (state, action: PayloadAction<TLoadingData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Запись сырых данных в виде объекта, где ключом является
         * интервал времени, за которые были получены эти данные
         */
        storeRawMetricsDataByTimeRange: (state, action: PayloadAction<IRawMetricDataByTimeRange | null>) => {
            state.rawMetricsDataByTimeRange = action.payload;
        },

        /**
         * Обнуление редьюсера
         */
        resetYOYPeriodModalReducer: () => initialState,
    },
});

export const { storeRawMetricsDataByTimeRange, resetYOYPeriodModalReducer, storeRawMetricsData } =
    Performance_YoY_YoY_PeriodModalBody_Component_Reducer.actions;

export const Performance_YoY_YoY_PeriodModalBody_Component_Reducer_Values = (state: RootState) => state.Performance_YoY_YoY_PeriodModalBody_Component_Reducer;

export default Performance_YoY_YoY_PeriodModalBody_Component_Reducer.reducer;
