import React, { FC, useEffect, useMemo, useState } from 'react';
import { IDataAdapterResponseItem, IProps } from './interfaces';
import useFetchData from './api/useFetchData';
import { withLoading } from '../../../tools/API/withLoading';
import getResponseStatus from '../../../tools/API/helpers/getResponseStatus';
import { DivWrapperSpinnerSC, Table, TableWrapper, Th } from './styles';
import Header from './components/Header/Header';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import Body from './components/Body/Body';
import usePrepareData from './hooks/usePrepareData';
import LoadingBox from '../../LoadingBox/LoadingBox';

/**
 * Виджет "Rating"
 */
const Rating: FC<IProps> = (props) => {
    const { data, isFetching, error } = useFetchData(props);
    const sortedComparePeriods = orderBy(
        props.comparePeriods,
        (period) => DateTime.fromISO(period.dateFrom).toMillis(),
        'desc',
    );

    const WithLoading = useMemo(() => {
        const Component = withLoading(
            TableWrapper,
            {
                data: getResponseStatus({
                    isFetching,
                    error,
                    data: data,
                    isDataExists: Boolean(data?.length),
                }),
                height: 400,
            },
            Boolean(props.viewSettings?.showLoadingWrapperBorder),
        );

        return Component;
    }, [isFetching, error, JSON.stringify(data), props.viewSettings?.showLoadingWrapperBorder]);

    return (
        <WithLoading>
            {props.isLoadAftertEdit && (
                <DivWrapperSpinnerSC>
                    <LoadingBox height={100}></LoadingBox>
                </DivWrapperSpinnerSC>
            )}
            <Table>
                <Header
                    viewSettings={props.viewSettings}
                    dataItemDeltas={data ? data[0]?.deltas : []}
                    allMetrics={props.allMetrics}
                    lang={props.lang}
                />
                <Body
                    comparePeriods={sortedComparePeriods}
                    viewSettings={props.viewSettings}
                    metric={props.metric}
                    data={data}
                    allMetrics={props.allMetrics}
                />
            </Table>
        </WithLoading>
    );
};

export default Rating;
