import styled from 'styled-components/macro';

const colorLegendGrey = '#b8b8b8';
const colorLegendBlack = 'black';

export const Wrapper = styled.div<{ visible: boolean }>`
    border: 1px solid #ebebeb;
    border-radius: 30px;
    padding: 5px 10px;
    color: ${({ visible }) => (visible ? colorLegendBlack : colorLegendGrey)};
    cursor: ${({ visible }) => (visible ? 'pointer' : 'default')};
`;

export const Text = styled.span`
    font-weight: 500;
    font-size: 12px;
`;

export const MainTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;
