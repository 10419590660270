import React from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import Traffic from './widgets/Traffic/Traffic';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../components/Preset/Preset';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import { useTranslation } from 'react-i18next';
import { ISettings } from '../../../components/UserSettings/interfaces';
import { getSubCategoriesByParentName } from '../../../tools/getSubCategoriesByParentName';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';
import { ICategory, IProjectCategory, IReportingObject } from '../../../General.interfaces';
import { objectsOrder } from '../../../constants/objectsOrder';

const categories: any[] = [
    { id: 12007, project_id: 94, is_active: true, object_name: 'Товарная категория', parent_id: null },
    { id: 12008, project_id: 94, is_active: true, object_name: 'Аксессуары/бижутерия', parent_id: 12007 },
    { id: 12009, project_id: 94, is_active: true, object_name: 'Банки/Банкоматы/терминалы', parent_id: 12007 },
    { id: 12010, project_id: 94, is_active: true, object_name: 'Белье', parent_id: 12007 },
    { id: 12011, project_id: 94, is_active: true, object_name: 'Зоотовары', parent_id: 12007 },
    { id: 12012, project_id: 94, is_active: true, object_name: 'Книги/Мультимедиа', parent_id: 12007 },
    { id: 12013, project_id: 94, is_active: true, object_name: 'Кожгалантерея/сумки', parent_id: 12007 },
    { id: 12014, project_id: 94, is_active: true, object_name: 'Мобильная связь/телефоны', parent_id: 12007 },
    { id: 12015, project_id: 94, is_active: true, object_name: 'Обувь', parent_id: 12007 },
    { id: 12016, project_id: 94, is_active: true, object_name: 'Одежда', parent_id: 12007 },
    { id: 12017, project_id: 94, is_active: true, object_name: 'Оптика/Аптека/Медицинские товары', parent_id: 12007 },
    { id: 12018, project_id: 94, is_active: true, object_name: 'Парфюмерия/косметика', parent_id: 12007 },
    { id: 12019, project_id: 94, is_active: true, object_name: 'Питание', parent_id: 12007 },
    { id: 12020, project_id: 94, is_active: true, object_name: 'Подарки/сувениры', parent_id: 12007 },
    { id: 12021, project_id: 94, is_active: true, object_name: 'Продукты', parent_id: 12007 },
    { id: 12022, project_id: 94, is_active: true, object_name: 'Прочие', parent_id: 12007 },
    { id: 12023, project_id: 94, is_active: true, object_name: 'Развлечения', parent_id: 12007 },
    { id: 12024, project_id: 94, is_active: true, object_name: 'Спортивные товары/одежда', parent_id: 12007 },
    { id: 12025, project_id: 94, is_active: true, object_name: 'Товары для детей', parent_id: 12007 },
    { id: 12026, project_id: 94, is_active: true, object_name: 'Товары для дома', parent_id: 12007 },
    { id: 12027, project_id: 94, is_active: true, object_name: 'Услуги', parent_id: 12007 },
    { id: 12028, project_id: 94, is_active: true, object_name: 'Электроника/Бытовая техника', parent_id: 12007 },
    { id: 12029, project_id: 94, is_active: true, object_name: 'Ювелирные изделия/Часы', parent_id: 12007 },
    { id: 12030, project_id: 94, is_active: true, object_name: 'Регион', parent_id: null },
    { id: 12031, project_id: 94, is_active: true, object_name: 'Россия', parent_id: 12030 },
    { id: 12032, project_id: 94, is_active: true, object_name: 'Москва', parent_id: 12030 },
    { id: 12033, project_id: 94, is_active: true, object_name: 'Санкт-Петербург', parent_id: 12030 },
    { id: 12034, project_id: 94, is_active: true, object_name: 'Сибирский ФО', parent_id: 12030 },
    { id: 12035, project_id: 94, is_active: true, object_name: 'Уральский ФО', parent_id: 12030 },
    { id: 12036, project_id: 94, is_active: true, object_name: 'Размер ТЦ', parent_id: null },
    { id: 12037, project_id: 94, is_active: true, object_name: 'Все ТЦ', parent_id: 12036 },
    { id: 12039, project_id: 94, is_active: true, object_name: 'Крупные и очень крупные ТЦ', parent_id: 12036 },
    { id: 12038, project_id: 94, is_active: true, object_name: 'Малые и средние ТЦ', parent_id: 12036 },
    { id: 12043, project_id: 94, is_active: true, object_name: 'Арендаторы', parent_id: null },
    { id: 12044, project_id: 94, is_active: true, object_name: 'Одежкин', parent_id: 12043 },
    { id: 12045, project_id: 94, is_active: true, object_name: 'ОдежкинКрут', parent_id: 12043 },
    { id: 12046, project_id: 94, is_active: true, object_name: 'Будуносить', parent_id: 12043 },
    { id: 12047, project_id: 94, is_active: true, object_name: 'КакБудуносить', parent_id: 12043 },
    { id: 12048, project_id: 94, is_active: true, object_name: 'Электровеник', parent_id: 12043 },
    { id: 12049, project_id: 94, is_active: true, object_name: 'КакЭлектровеник', parent_id: 12043 },
    { id: 12050, project_id: 94, is_active: true, object_name: 'СмотрюиВарю', parent_id: 12043 },
    { id: 12051, project_id: 94, is_active: true, object_name: 'КакСмотрюиВарю', parent_id: 12043 },
    { id: 12052, project_id: 94, is_active: true, object_name: 'Вкусно', parent_id: 12043 },
    { id: 12053, project_id: 94, is_active: true, object_name: 'КакВкусно', parent_id: 12043 },
    { id: 12054, project_id: 94, is_active: true, object_name: 'ВПитерепить', parent_id: 12043 },
    { id: 12055, project_id: 94, is_active: true, object_name: 'КакВПитерепить', parent_id: 12043 },
    { id: 12697, project_id: 94, is_active: true, object_name: 'Северо-Западный ФО', parent_id: 12030 },
    { id: 12698, project_id: 94, is_active: true, object_name: 'Центральный ФО', parent_id: 12030 },
    { id: 12699, project_id: 94, is_active: true, object_name: 'Южный ФО', parent_id: 12030 },
    { id: 12700, project_id: 94, is_active: true, object_name: 'Приволжский ФО', parent_id: 12030 },
];

const Dashboard: React.FC = ({}) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const geoOptions = getSubCategoriesByParentName('Регион', categories)?.map((item) => ({
        id: String(item.id),
        text: item.object_name,
    }));

    const mallTypeOptions = getSubCategoriesByParentName('Размер ТЦ', categories)?.map((item) => ({
        id: String(item.id),
        text: item.object_name,
    }));
    const detailOptions = [
        { id: 'W-MON', text: t('Weekly') },
        { id: 'MS', text: t('Monthly') },
    ];

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            yearsOnly
                            validOptions={[
                                'previousMonth',
                                'currentYear',
                                'previousWeek',
                                'currentMonth',
                                'selectCustom',
                            ]}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ComparePeriodSelect
                            yearsOnly
                            validOptions={['sameMonthdays', 'yoy', 'selectCustom']}
                            maxSelected={2}
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            mode="single"
                            options={geoOptions}
                            name={'geoOptions'}
                            labelText={'Region'}
                            text={t('Select a region')}
                            dropdownWidth="sm"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            mode="single"
                            options={mallTypeOptions}
                            name={'mallTypeOptions'}
                            labelText={'Mall type'}
                            text={t('Select mall type')}
                            dropdownWidth="sm"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            mode="single"
                            options={detailOptions}
                            name={'myDetail'}
                            labelText={'Detail'}
                            text={t('Select detail')}
                            dropdownWidth="sm"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                ['mainPeriod', 'mallTypeOptions', 'category', 'tenant'] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                <Traffic />
                {/* <Sales /> */}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Dashboard;
