import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-xs);
`;

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--space-xxxs);
`;

export const Divider = styled.div`
    display: flex;
    justify-content: center;
`;
