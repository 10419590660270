import { useContext } from 'react';
import { TOrdering } from '../../../../../../../../../components/OrderSwitcher/interfaces';
import OrderSwitcher from '../../../../../../../../../components/OrderSwitcher/OrderSwitcher';
import { SalesOfTenantsContext } from '../../../../context';
import { PercentageValue, Th, Thead } from './styles';
import Stack from '../../../../../../../../../components/Stack/Stack';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';
import { Tr } from '../../styles';
import { Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values, storeTableOrderData } from '../../../../reducer';
import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import { TCellData } from '../../../../interfaces';
import { TableSettingsIds } from '../../../../enums';

/**
 * Компонент для отображения шапки таблицы
 */
const Header = () => {
    const { tableOrderData, selectedTableSetting } = useSelector(
        Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    );
    const context = useContext(SalesOfTenantsContext);

    const { t } = useTranslation(['metrics', 'translation']);
    const dispatch = useDispatch();

    const handleOrderToggle = (columnId: string, period: 'main' | 'compare') => (args: { ordering: TOrdering }) => {
        const { ordering } = args;
        dispatch(
            storeTableOrderData({
                ordering,
                columnId,
                period,
            }),
        );
    };

    const additionalRows = [
        {
            text: 'Total',
            dataKey: 'totalByColumnId',
        },
        {
            text: 'Average',
            dataKey: 'averageByColumnId',
        },
    ];

    return (
        <Thead>
            <Tr>
                {context?.data?.columns.map((column) => {
                    return (
                        <Th key={column.id} fontSize="16px" fontWeight="600" bgColor="#f5f5f5">
                            <Stack justifyContent={'center'} gap={10} alignItems={'center'}>
                                {column.leftHandOrderSwitcher && (
                                    <OrderSwitcher
                                        ordering={
                                            tableOrderData?.columnId === column.id && tableOrderData?.period === 'main'
                                                ? tableOrderData.ordering
                                                : undefined
                                        }
                                        handleOrderToggle={handleOrderToggle(column.id, 'main')}
                                        position="left"
                                    />
                                )}

                                <Stack direction={'column'} alignItems="center" justifyContent="center">
                                    <div>{t(column.text)}</div>
                                    {column.units && <div>{t(column.units)}</div>}
                                </Stack>

                                {column.rightHandOrderSwitcher && (
                                    <OrderSwitcher
                                        ordering={
                                            tableOrderData?.columnId === column.id &&
                                            tableOrderData?.period === 'compare'
                                                ? tableOrderData.ordering
                                                : undefined
                                        }
                                        handleOrderToggle={handleOrderToggle(column.id, 'compare')}
                                        position="right"
                                    />
                                )}
                            </Stack>
                        </Th>
                    );
                })}
            </Tr>
            {additionalRows
                .slice(selectedTableSetting.id === TableSettingsIds.ShowAbsoluteValues ? 0 : 1)
                .map((row) => {
                    return (
                        <Tr key={row.dataKey}>
                            {context?.data?.columns.map((column, index) => {
                                const metricData = context?.data?.[row.dataKey]?.[column.id] as undefined | TCellData;
                                return (
                                    <Th
                                        key={column.id + row.dataKey}
                                        bgColor="#fffded"
                                        textAlign="left"
                                        fontWeight="500"
                                        fontSize="14px"
                                    >
                                        {index === 0 ? (
                                            <div>{t(row.text)}</div>
                                        ) : (
                                            <Stack justifyContent="space-between" alignItems="center" gap={5}>
                                                <div>
                                                    {valueFormatter({
                                                        value:
                                                            selectedTableSetting.id ===
                                                            TableSettingsIds.ShowAbsoluteValues
                                                                ? metricData?.mainValue.value
                                                                : metricData?.mainValue.percentageOfTheRowTotal,
                                                        units:
                                                            selectedTableSetting.id ===
                                                            TableSettingsIds.ShowAbsoluteValues
                                                                ? undefined
                                                                : '%',
                                                    })}
                                                </div>
                                                <PercentageValue value={metricData?.compareValue.percentageOfTheMain}>
                                                    {valueFormatter({
                                                        value: metricData?.compareValue.percentageOfTheMain,
                                                        units: '%',
                                                    })}
                                                </PercentageValue>
                                            </Stack>
                                        )}
                                    </Th>
                                );
                            })}
                        </Tr>
                    );
                })}
        </Thead>
    );
};

export default Header;
