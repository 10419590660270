import React from 'react';
import { IReportingObject } from '../../../General.interfaces';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import { userSettingsReducerValues } from '../../UserSettings/reducer';
import Bubble from './components/Bubble/Bubble';

const FiltersMonitor: React.FC<IProps> = () => {
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const { currentOptions } = useSelector(userSettingsReducerValues);

    const selectedFilterIds = currentOptions?.['filters'] || [];

    const filtersByType = {};

    selectedFilterIds.forEach((id: number) => {
        const obj = reportingObjectsById?.[id];
        if (obj) {
            filtersByType[obj.object_type] = filtersByType?.[obj.object_type]
                ? [...filtersByType?.[obj.object_type], obj]
                : [obj];
        }
    });

    const bubbles = Object.keys(filtersByType).map((key) => {
        const objType = key;

        const objects = filtersByType[key] as IReportingObject[];
        return <Bubble key={`${objType}bubble**`} testId={`${objType}bubble**`} objects={objects}></Bubble>;
    });

    return <Wrapper>{bubbles}</Wrapper>;
};

export default FiltersMonitor;
