import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IDashboard,
    IProjectDashboard,
    IReducerState,
    IUpdateWidgetPayload,
    IWidget,
    TLayouts,
} from './components/DashboardsCommon/interfaces';
import { AppThunk, RootState } from './store';
import { cloneDeep } from 'lodash';
import { getRequest, patchRequest, postRequest } from './tools/API/appBackendAPI';
import axios from 'axios';
import { DS } from './constants/constants';
import { DateTime } from 'luxon';
import { getHeaders } from './tools/API/getHeaders';
import generateId from './tools/generateId';
import { addNewGlobalSpinnerItem, deleteGlobalSpinnerItemById } from './General.reducer';

const initialState: IReducerState = {
    defaultDashboards: [],
    projectDashboards: [],
    userDashboards: [],
    editPanelMode: false,
    selectedKey: {},
    sharedDashboardHash: null,
};

const Dashboards_Reducer = createSlice({
    name: 'Dashboards_Reducer',
    initialState,
    reducers: {
        storeSelectedKey: (state, action: PayloadAction<{ [x: string]: null | string }>) => {
            const { selectedKey } = cloneDeep(state);
            state.selectedKey = { ...selectedKey, ...action.payload };
        },
        storeSharedDashboardHash: (state, action: PayloadAction<null | string>) => {
            state.sharedDashboardHash = action.payload;
        },
        toggleEditPanelMode: (state) => {
            state.editPanelMode = !state.editPanelMode;
        },
        storeDefaultDashboards: (state, action: PayloadAction<IDashboard[]>) => {
            state.defaultDashboards = action.payload;
        },
        storeProjectDashboards: (state, action: PayloadAction<IProjectDashboard[]>) => {
            state.projectDashboards = action.payload;
        },
        storeUserDashboards: (state, action: PayloadAction<IDashboard[]>) => {
            state.userDashboards = action.payload;
        },

        /**
         * Обновление user dashboards по текущему модули и выбранному dashboard key (таб)
         */
        updateLayout: (state, action: PayloadAction<{ layouts: TLayouts; moduleId: string; dashboardKey: string }>) => {
            const { layouts, moduleId, dashboardKey } = action.payload;

            const userDashboards = cloneDeep(state.userDashboards).map((item) => {
                if (item.module_id === moduleId && item.dashboard_key === dashboardKey) {
                    return { ...item, layouts };
                } else {
                    return item;
                }
            });

            state.userDashboards = userDashboards;
        },

        /**
         * Изменение статичности у виджета
         */
        toggleWidgetStatic: (
            state,
            action: PayloadAction<{ moduleId: string; dashboardKey: string; isStatic: boolean; widgetId: string }>,
        ) => {
            const { moduleId, dashboardKey, isStatic, widgetId } = action.payload;

            const userDashboards = cloneDeep(state.userDashboards).map((item) => {
                if (item.module_id === moduleId && item.dashboard_key === dashboardKey) {
                    const layouts = Object.entries(item.layouts).reduce((acc, [key, value]) => {
                        acc[key] = value.map((layout) => {
                            if (layout.i === widgetId) {
                                return { ...layout, static: isStatic };
                            }
                            return layout;
                        });
                        return acc;
                    }, {}) as TLayouts;
                    return { ...item, layouts };
                } else {
                    return item;
                }
            });

            state.userDashboards = userDashboards;
        },

        /**
         * Сброс userDashboards к дефолтным настройкам
         */
        resetToDefaultDashboards: (state, action: PayloadAction<{ dashboardKey: string; moduleId: string }>) => {
            const { dashboardKey, moduleId } = action.payload;
            const { userDashboards, defaultDashboards } = cloneDeep(state);

            const currentModuleDefaultDashboard = defaultDashboards.find((dashboard) => {
                return dashboard.module_id === moduleId && dashboard.dashboard_key === dashboardKey;
                // if (dashboardKey.includes('project')) {
                //     return (
                //         dashboard.module_id === moduleId &&
                //         dashboard.dashboard_key.replace(`default${DS}`, '') === dashboardKey
                //     );
                // } else {
                // return dashboard.module_id === moduleId && dashboard.dashboard_key === dashboardKey;
                // }
            });

            const newUserDashboards = userDashboards.map((userDashboard) => {
                if (
                    userDashboard.module_id === moduleId &&
                    userDashboard.dashboard_key === dashboardKey &&
                    currentModuleDefaultDashboard
                ) {
                    return {
                        ...userDashboard,
                        layouts: cloneDeep(currentModuleDefaultDashboard.layouts),
                        widgets: cloneDeep(currentModuleDefaultDashboard.widgets),
                    };
                }

                return userDashboard;
            });
            state.userDashboards = newUserDashboards;
        },

        resetToProjectDashboard: (state, action: PayloadAction<{ dashboardKey: string; moduleId: string }>) => {
            const { dashboardKey, moduleId } = action.payload;
            const { userDashboards, projectDashboards } = cloneDeep(state);

            const currentModuleProjectDashboard = projectDashboards.find(
                (dashboard) => dashboard.module_id === moduleId && dashboard.dashboard_key === dashboardKey,
            );

            const newUserDashboards = userDashboards.map((userDashboard) => {
                if (
                    userDashboard.module_id === moduleId &&
                    userDashboard.dashboard_key === dashboardKey &&
                    currentModuleProjectDashboard
                ) {
                    return {
                        ...userDashboard,
                        layouts: cloneDeep(currentModuleProjectDashboard.layouts),
                        widgets: cloneDeep(currentModuleProjectDashboard.widgets),
                    };
                }

                return userDashboard;
            });
            state.userDashboards = newUserDashboards;
        },

        changeRequiredParams: (
            state,
            action: PayloadAction<{
                dashboardKey: string;
                moduleId: string;
                param: string;
                id: string;
                mode: 'add' | 'remove';
            }>,
        ) => {
            const { dashboardKey, moduleId, id, mode, param } = action.payload;
            const { userDashboards } = cloneDeep(state);

            const newUserDashboards = userDashboards.map((ds: IDashboard) => {
                if (ds.module_id === moduleId && ds.dashboard_key === dashboardKey) {
                    const widgets = ds.widgets.map((wg) => {
                        if (wg.id === id) {
                            if (mode === 'add') {
                                return {
                                    ...wg,
                                    requiredParams: Array.from(new Set([...(wg?.requiredParams ?? []), param])),
                                };
                            } else if (mode === 'remove') {
                                return {
                                    ...wg,
                                    requiredParams: [...(wg?.requiredParams ?? [])].filter((item) => item !== param),
                                };
                            } else {
                                return wg;
                            }
                        } else {
                            return wg;
                        }
                    });
                    return {
                        ...ds,
                        widgets,
                    };
                }

                return ds;
            });

            state.userDashboards = newUserDashboards;
        },

        /**
         * Обновление одного виджета
         */
        updateWidget: (state, action: PayloadAction<IUpdateWidgetPayload>) => {
            const { moduleId, dashboardKey, data } = action.payload;
            const userDashboards = cloneDeep(state.userDashboards).map((item) => {
                if (item.module_id === moduleId && item.dashboard_key === dashboardKey) {
                    const widgets = item.widgets.map((widget) => {
                        if (widget.id === data.widgetId) {
                            return { ...widget, [data.key]: data.value };
                        }
                        return widget;
                    });
                    return { ...item, widgets };
                } else {
                    return item;
                }
            });

            state.userDashboards = userDashboards;
        },
        /**
         * Удаление user dashboard widget
         */
        removeUserDashboardWidget: (
            state,
            action: PayloadAction<{ moduleId: string; dashboardKey: string; widgetId: string }>,
        ) => {
            const { moduleId, dashboardKey, widgetId } = action.payload;
            const userDashboards = cloneDeep(state.userDashboards);
            if (widgetId) {
                const currentUserDashboardIndex = userDashboards.findIndex(
                    (dashboard) => dashboard.module_id === moduleId && dashboard.dashboard_key === dashboardKey,
                );
                if (currentUserDashboardIndex > -1) {
                    const currentUserDashboard = userDashboards[currentUserDashboardIndex];
                    currentUserDashboard.layouts = Object.entries(currentUserDashboard.layouts).reduce(
                        (acc, [key, layout]) => {
                            acc[key] = layout.filter((item) => item.i !== widgetId);
                            return acc;
                        },
                        {},
                    ) as TLayouts;
                    currentUserDashboard.widgets = currentUserDashboard.widgets.filter(
                        (widget) => widget.id !== widgetId,
                    );
                }
                state.userDashboards = userDashboards;
            }
        },
        /**
         * Добавление одного виджета в user dashboard widgets
         */
        addUserDashboardWidget: (
            state,
            action: PayloadAction<{ moduleId: string; dashboardKey: string; widget: IWidget }>,
        ) => {
            const { moduleId, dashboardKey, widget } = action.payload;
            const userDashboards = cloneDeep(state.userDashboards).map((item) => {
                if (item.module_id === moduleId && item.dashboard_key === dashboardKey) {
                    let newLayoutSizes = widget.defaultLayout;

                    const newWidget = {
                        id: widget.id,
                        type: widget.type,
                        title: widget.title,
                        requiredParams: widget.requiredParams,
                        options: widget.options,
                        visual: widget.visual,
                        permissions: widget.permissions,
                        units: widget.units,
                        sources: widget.sources,
                    };

                    const newLayouts = Object.entries(item.layouts).reduce((acc, [key, layout]) => {
                        acc[key] = [...layout, { ...newLayoutSizes, i: newWidget.id }];
                        return acc;
                    }, {} as TLayouts);

                    return { ...item, widgets: [...item.widgets, newWidget], layouts: newLayouts };
                } else {
                    return item;
                }
            });

            state.userDashboards = userDashboards;
        },
    },
});

export const {
    storeDefaultDashboards,
    storeProjectDashboards,
    storeUserDashboards,
    storeSharedDashboardHash,
    resetToDefaultDashboards,
    resetToProjectDashboard,
    updateLayout,
    changeRequiredParams,
    removeUserDashboardWidget,
    storeSelectedKey,
    toggleEditPanelMode,
    updateWidget,
    addUserDashboardWidget,
    toggleWidgetStatic,
} = Dashboards_Reducer.actions;

export const Dashboards_Reducer_Values = (state: RootState) => state.Dashboards_Reducer;

export default Dashboards_Reducer.reducer;

export const patchProjectDashboard =
    ({ dashboard }: { dashboard: IDashboard & { user_id?: number } }): AppThunk =>
    async (dispatch, getState) => {
        const { token, user, urlsByServices, initialDataReceived, currentModuleID } = getState().GeneralReducer;
        const { projectDashboards } = getState().Dashboards_Reducer;
        const url = urlsByServices?.['app/app-backend']?.PROJECT_DASHBOARDS_URL;
        const correspondingProjectId = projectDashboards.find(
            (item) => item.dashboard_key === dashboard.dashboard_key && item.module_id === dashboard.module_id,
        )?.id;

        if (!token || !url || !user) return;

        if (dashboard.dashboard_key.includes('project') && !correspondingProjectId) {
            const dashboard_key = dashboard.dashboard_key.replace(`default${DS}`, '');
            const version = 1;
            const project_id = user.project_id ?? 94;
            const data = { ...dashboard, dashboard_key, version, project_id };
            const { id, updated_at, created_at, user_id, ...rest } = data;
            await postRequest(url, token, rest).then((res) => {
                window.location.reload();
            });
        } else if (dashboard.dashboard_key.includes('project') && correspondingProjectId) {
            const pachUrl = `${url}${correspondingProjectId}/`;
            const project_id = user.project_id ?? 94;
            const version = DateTime.now().toMillis() % 10000000;
            const data = { ...dashboard, version, project_id, id: correspondingProjectId };
            const { updated_at, user_id, ...rest } = data;
            await patchRequest({ url: pachUrl, token, data: rest }).then((res) => {
                window.location.reload();
            });
        }

        // const request = dashboards.map((ds) => {
        //     const data = { ...ds, user_id: user.id };
        //     const { id, ...rest } = data;
        //     return postRequest(url, token, rest);
        // });

        // await axios.all(requests).then((res) => {
        //     getRequest(url, token).then((response) => {
        //         dispatch(storeUserDashboards(response.data));
        //     });
        // });
    };

export const postUserDashboards =
    ({ dashboards }: { dashboards: Array<IDashboard> }): AppThunk =>
    async (dispatch, getState) => {
        const { token, user, urlsByServices, initialDataReceived, currentModuleID } = getState().GeneralReducer;
        const url = urlsByServices?.['app/app-backend']?.USER_DASHBOARDS_URL;

        if (!token || !url || !user?.id) return;

        const requests = dashboards.map((ds) => {
            const data = { ...ds, user_id: user.id };
            const { id, ...rest } = data;
            return postRequest(url, token, rest);
        });

        await axios.all(requests).then((res) => {
            getRequest(url, token).then((response) => {
                dispatch(storeUserDashboards(response.data));
            });
        });
    };

/**
 * Thunk. Обновление user dashboards
 */
export const patchUserDashboards =
    ({
        dashboards,
        makeGet,
    }: {
        makeGet?: boolean;
        dashboards: Array<Omit<IDashboard, 'updated_at' | 'created_at'>>;
    }): AppThunk =>
    async (dispatch, getState) => {
        const { token, user, urlsByServices, currentModuleID } = getState().GeneralReducer;
        const url = urlsByServices?.['app/app-backend']?.USER_DASHBOARDS_URL;

        if (!token || !url || !user?.id) return;

        const requests = dashboards.filter((dashboard) => dashboard.module_id === currentModuleID);

        axios
            .all(
                requests.map((dashboard) => {
                    const headers = getHeaders(token);
                    return axios.patch(`${url}${dashboard.id}/`, { ...dashboard }, { headers });
                }),
            )
            .then(() => {
                makeGet &&
                    getRequest(url, token).then((response) => {
                        if (!response.error) {
                            dispatch(storeUserDashboards(response.data));
                        }
                    });
            });
    };

export const deleteDashboard =
    ({ id }: { id: number }): AppThunk =>
    async (dispatch, getState) => {
        const { token, user, urlsByServices } = getState().GeneralReducer;
        const url = urlsByServices?.['app/app-backend']?.USER_DASHBOARDS_URL;

        if (!token || !url || !user?.id) return;
        const headers = getHeaders(token);

        axios.delete(`${url}${id}/`, { headers }).then(() => {
            getRequest(url, token).then((response) => {
                if (!response.error) {
                    dispatch(storeUserDashboards(response.data));
                }
            });
        });
    };
