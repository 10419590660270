import { Delete16Regular } from '@fluentui/react-icons';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    /* margin-right: var(--space-sm); */
`;

export const StyledDeleteIcon = styled(Delete16Regular)`
    color: var(--color-secondary-darker);
    cursor: pointer;
    :hover {
        color: var(--color-primary);
    }
`;
