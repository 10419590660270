import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow: auto;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--default-border-color);
    position: relative;

    th,
    td {
        border: 1px solid var(--default-border-color);
        padding: 10px;
    }
`;

export const Tr = styled.tr`
    border: 1px solid var(--default-border-color);
`;

export const Th = styled.th<{ width?: string; whiteSpace?: string; fontSize?: string; fontWeight?: string }>`
    min-width: ${(p) => p.width || 'auto'};
    max-width: ${(p) => p.width || 'auto'};
    white-space: ${(p) => p.whiteSpace || 'normal'};
    font-size: ${(p) => p.fontSize || 'inherit'};
    font-weight: ${(p) => p.fontWeight || '500'};
    height: 55px;
`;

export const Td = styled.td<{ padding?: string; width?: string }>`
    ${(p) => (p.padding ? `padding: ${p.padding} !important;` : null)}
    ${(p) => (p.width ? `width: ${p.width} !important;` : null)}
    height: 1px
`;
