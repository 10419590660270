// import { isNull, isNumber, isUndefined } from 'lodash';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';

type TValueType = string | number | null | undefined;

interface IArgs {
    /**
     * Первое значение
     */
    a: TValueType;
    /**
     * Второе значение
     */
    b: TValueType;
    /**
     * Направление сортировки
     * @param -1 Сортировка по возрастанию
     * @param 1 Сортировка по убыванию
     * @default 1
     */
    sortOrder?: 1 | -1;
}

/**
 * Функция для сортировки значений. Значения null | undefined всегда оказываются в конце списка
 */
const sortValues = (args: IArgs): number => {
    const { a, b, sortOrder = 1 } = args;

    if (!isValueDefine(a) && isValueDefine(b)) return 1;
    if (isValueDefine(a) && !isValueDefine(b)) return -1;
    if (!isValueDefine(a) && !isValueDefine(b)) return 1;

    if (isValueDefine(a) && isValueDefine(b)) {
        return a > b ? -1 * sortOrder : sortOrder;
    }
    return 1;
};

export default sortValues;

const isValueDefine = (value: TValueType): value is string | number => {
    return !isNull(value) && !isUndefined(value) && (isNumber(value) ? isFinite(value) : true);
};
