import { TFunction } from 'i18next';
import { IReportingObject } from '../General.interfaces';
import { getObjectName } from '../hooks/useObjectTranslation';
import { getObjSectionName } from './Strings/getObjSectionName';

export interface IExtendedReportingObject extends IReportingObject {
    text: string;
    type: string;
}

export interface IOptionsOfReportingObjects {
    order: number;
    section: string;
    objects: IExtendedReportingObject[];
    key: string;
}

export const getReportingObjects = (
    reportingObjectsByType: { [x: string]: IReportingObject[] },
    t: TFunction,
): IOptionsOfReportingObjects[] => {
    return Object.keys(reportingObjectsByType).map((key) => {
        const reportingObject = reportingObjectsByType[key][0];
        const objects = reportingObjectsByType[key].map((obj) => {
            const text =
                obj.object_type === 'floor' ? `${t('Floor')} ${obj.name}` : getObjectName({ reportingObject: obj, t });
            return { ...obj, text, type: 'object' };
        });
        let section = getObjSectionName({ t, reportingObject });
        // let section = key;

        // if (objectTypesMap[key]) {
        // } else if (key.includes(ZONES_WORD)) {
        //     section = `${ZONES_WORD}${key.replace(ZONES_WORD, '')}`;
        // }

        // console.log(section);

        return { section, objects, key, order: reportingObjectsByType[key][0]?.order };
    });
};

export const objectTypesMap = {
    passway: 'Passways',
    place: 'Places',
    zone: 'Zones',
    floor: 'Floors',
    location: 'Location',
    tenant: 'Tenants',
};
