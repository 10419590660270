import { isNumber, isUndefined, max, sum } from 'lodash';
import { IPeriodObj, TMetricResponse } from '../../../../General.interfaces';
import { DS } from '../../../../constants/constants';
import { IDataAdapterResponseItem, IViewSettings } from '../interfaces';
import getDifferenceBetweenNumbers from '../../../../tools/getDifferenceBetweenNumbers';

interface IArgs {
    response: TMetricResponse[];
    comparePeriods: IPeriodObj[];
    viewSettings?: IViewSettings;
}

const dataAdapter = (args: IArgs): IDataAdapterResponseItem[] => {
    var { response } = args;
    var result: IDataAdapterResponseItem[] = [];
    var tempResult: { [reportingObjectId: string]: IDataAdapterResponseItem } = {};
    response.forEach((metricResponse) => {

        metricResponse.forEach((metricResponseItem) => {
            var { context, items } = metricResponseItem;
            var splittedAlias = (context.alias || '').split(DS);
            if (splittedAlias.length) {
                var periodType = splittedAlias[0] as 'mainPeriod' | 'comparePeriod';
                var item = tempResult[context.data_objects[0].id] || {
                    name: context.data_objects[0].name,
                    id: context.data_objects[0].id,
                };

                var period = { dateFrom: context.time_range[0], dateTo: context.time_range[1] };
                switch (periodType) {
                    case 'mainPeriod':
                        item.mainValue = {
                            value: items[0].value,
                            percentOfTotal: 0,
                            percentOfMax: 0,
                            period,
                        };
                        break;
                    case 'comparePeriod':
                        item.compareValues = [
                            ...(item.compareValues || []),
                            {
                                value: items[0].value,
                                percentDiffWithMain: 0,
                                period,
                            },
                        ];
                        break;

                    default:
                        break;
                }
                if (context.alias === 'area') {
                    item.area = {
                        value: items[0].value,
                        period,
                    }
                }

                item.deltas = []
                item.absolute = 0
                item.delta = 0
                item.deviation = [0]
                tempResult[context.data_objects[0].id] = item;
            }
        });
    });

    var mainValues = Object.values(tempResult).map((item) => item.mainValue?.value);

    var mainTotal = sum(mainValues);
    var mainMax = max(mainValues);

    Object.values(tempResult).forEach((item) => {
        const { name, mainValue, compareValues, id } = item;

        let arrayDeltas:{
            value: number;
            name: string;
            period: IPeriodObj;
        }[] = []
        if (
            !isUndefined(name) &&
            !isUndefined(mainValue) &&
            !isUndefined(id) &&
            !isUndefined(compareValues) &&
            compareValues?.length
        ) {
            let _valueDiv: number[] = [];
            let _compareValues = compareValues.map((compareValue, i) => {
                arrayDeltas.push({
                    value: (mainValue.value ? mainValue.value : 0) - (compareValue.value ? compareValue.value : 0),
                    name: `${i}`,
                    period: compareValue.period,
                })
                return {
                    ...compareValue,
                    percentDiffWithMain:
                        isNumber(mainValue?.value) && isNumber(compareValue.value) && compareValue.value > 0
                            ? Number(
                                getDifferenceBetweenNumbers(mainValue!.value as number, compareValue.value)
                                    .percentDifference,
                            )
                            : null,
                };
            });

            [...args.comparePeriods].forEach((period2, index) => {
                const currentPeriodData = _compareValues.find(
                    (itemComparePeriod) =>
                        itemComparePeriod.period.dateFrom === period2.dateFrom &&
                        itemComparePeriod.period.dateTo === period2.dateTo,
                );
                _valueDiv.push(
                    args.viewSettings?.showAbsoluteValues
                        ? currentPeriodData?.value ? currentPeriodData?.value : 0
                        : currentPeriodData?.percentDiffWithMain ? currentPeriodData?.percentDiffWithMain : 0,
                );
            })
            const resultItem: IDataAdapterResponseItem = {
                name,
                id,
                mainValue: {
                    ...mainValue,
                    percentOfTotal: mainValue.value ? (mainValue.value * 100) / mainTotal : null,
                    percentOfMax: mainValue.value && isNumber(mainMax) ? (mainValue.value * 100) / mainMax : null,
                },
                compareValues: _compareValues,
                area: {
                    ...item.area,
                },
                deltas:arrayDeltas,
                absolute: mainValue.value ? mainValue.value : 0,
                delta: arrayDeltas[0].value,
                deviation: _valueDiv,
            };
            result.push(resultItem);
        }
    });

    return result;
};

export default dataAdapter;
