import styled from 'styled-components/macro';

import { theme } from 'src/theme';

export const LogoWrapper = styled.div`
    padding-top: 4px;
    height: ${() => theme.spaces.headerHeight};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    /* border-bottom: 1px solid var(--color-border-gray); */

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        display: none;
        /* max-width: calc(100vw + 250px); */
        /* left: ${(p) => (p.theme.isRtlLanguage ? '0px' : '249px')}; */
        /* padding: var(--space-md); */
        /* padding-bottom: var(--space-xs); */
    }
`;
