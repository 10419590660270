import { TServerResponse, handleServerResponse } from '../../../../../../tools/API/handleServerResponse';
import {
    increaseUpdateChartsByPeriodCounter,
    storeExtendedReportingObjects,
    storeRawMetricsData,
    toggleUpdateChartsByPeriod,
    Performance_VisitorsInside_Widget_Reducer_Values,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../General.reducer';
import generateQueryData from '../tools/generateQueryData';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { useEffect } from 'react';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';

/**
 * Кастомный хук для получения метрик с сервера
 */
export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();

    const {
        updateChartsByReportingObjectsCounter,
        extendedReportingObjectsById,
        updateChartsByPeriodCounter,
        shoudUpdateChartsByPeriod,
        dataRefetchObject,
        moduleName,
    } = useSelector(Performance_VisitorsInside_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        src: { reportingObjects },
        token,
        selectedLocationId,
    } = useSelector(generalReducerValues);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    /**
     * Получение и сохранение всех отчетных объектов (так же обновляется при изменение одного из периодов)
     */
    useEffect(() => {
        if (localCurrentOptions?.selectedReportingObjectsIds) {
            const objects = reportingObjects?.filter((element) =>
                localCurrentOptions?.selectedReportingObjectsIds?.includes(element.id),
            );

            dispatch(
                storeExtendedReportingObjects({
                    reportingObjects: objects,
                    shoudUpdateAllItems: shoudUpdateChartsByPeriod,
                }),
            );
        }
    }, [JSON.stringify(localCurrentOptions?.selectedReportingObjectsIds), reportingObjects]);

    /**
     * Увеличение счетчика, отвечающего за изменение одного из периодов
     */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.comparePeriods) {
            dispatch(increaseUpdateChartsByPeriodCounter(null));
            dispatch(toggleUpdateChartsByPeriod(true));
        }
    }, [
        JSON.stringify(localCurrentOptions?.mainPeriod),
        JSON.stringify(localCurrentOptions?.comparePeriods),
        selectedLocationId,
        dataRefetchObject,
    ]);

    /**
     * Получение метрик и обновление отчетных объектов
     */
    useEffect(() => {
        if (!localCurrentOptions || !Object.keys(extendedReportingObjectsById).length || !token) return;
        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions.selectedReportingObjectsIds &&
            localCurrentOptions.comparePeriods &&
            localCurrentOptions.mainPeriod
        ) {
            const queryData = generateQueryData({
                selectedReportingObjectsIds: localCurrentOptions.selectedReportingObjectsIds,
                compareDateRanges: localCurrentOptions.compareDateRanges,
                mainDateRanges: localCurrentOptions.mainDateRanges,
                comparePeriods: localCurrentOptions.comparePeriods,
                mainPeriod: localCurrentOptions.mainPeriod,
                extendedReportingObjectsById,
                shoudUpdateChartsByPeriod,
                token,
            }).map((element) => ({ ...element, signal }));

            if (queryData.length) {
                dispatch(storeRawMetricsData({ status: 'Loading', message: t('Loading...') }));
                fetchData(queryData)
                    .then((res: TServerResponse) => {
                        handleServerResponse({
                            responseAnalyzer: responseAnalyzer,
                            success: storeRawMetricsData,
                            error: storeRawMetricsData,
                            dispatch,
                            res,
                        });
                    })
                    .catch((error: any) => {
                        console.log('VisitorsInside windget useGetMetrics error>>>>> ', error);
                    });
            }
        }

        return () => {
            controller.abort();
        };
    }, [
        updateChartsByPeriodCounter,
        updateChartsByReportingObjectsCounter,
        token,
        selectedLocationId,
        localCurrentOptions?.selectedReportingObjectsIds,
        localCurrentOptions?.comparePeriods,
        localCurrentOptions?.mainPeriod,
    ]);
};
