import { FC } from 'react';
import { IProps } from './interfaces';
import { TabList as FluentTabList, Tab, OverflowItem, Overflow, mergeClasses } from '@fluentui/react-components';
import { useSendSimpleAnalytics } from '../../hooks/useAnalytics';
import { generalReducerValues } from '../../General.reducer';
import { useSelector } from 'react-redux';
import OverflowMenu from './components/OverflowMenu/OverflowMenu';
import { useStyles, useTabStyles, TablistWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { useCustomFocusIndicatorStyles } from '../../App.styles';
import Tooltip from '../Tooltip/Tooltip';

/**
 * Компонент для отображения TabList с использованием Я.Метрики
 */
const TabList: FC<IProps> = ({ widgetName, options, selectHandler, addComponent = null, ...props }) => {
    const tabStyles = useTabStyles();
    const { currentModuleID, sendYaAnalytics } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const sendAnalytics = useSendSimpleAnalytics();

    const focusIndicatorStyles = useCustomFocusIndicatorStyles();

    const _selectHandler = (id: string) => {
        selectHandler(id);
        widgetName && sendAnalytics(`switch_to_tab_${id}`, 'reachGoal', `${currentModuleID}:${widgetName}`);
    };

    return (
        <TablistWrapper style={props.style}>
            <Overflow minimumVisible={1}>
                {/* <div className={styles.root}> */}

                <FluentTabList
                    onTabSelect={(event, data) => _selectHandler(data.value as string)}
                    style={{ justifyContent: 'flex-end' }}
                    {...props}
                >
                    {addComponent}
                    {options.map((item) => {
                        return (
                            <OverflowItem key={item.id} id={item.id} priority={item.id === props.selectedValue ? 2 : 1}>
                                <Tab
                                    className={mergeClasses(focusIndicatorStyles.focusIndicator, tabStyles.root)}
                                    data-test-id={`${item.id}:${widgetName}`}
                                    disabled={item.disabled}
                                    value={item.id}
                                    icon={item.icon}
                                    // style={{ paddingBottom: `var(--space-xxxs)` }}
                                >
                                    <Tooltip content={item.tooltip || ''} showTooltip={Boolean(item.tooltip)}>
                                        {t(item.text)}
                                    </Tooltip>
                                </Tab>
                            </OverflowItem>
                        );
                    })}
                    <OverflowMenu
                        testId={`${currentModuleID}:${widgetName}:OverflowMenu`}
                        selectHandler={_selectHandler}
                        options={options}
                    />
                </FluentTabList>

                {/* </div> */}
            </Overflow>
        </TablistWrapper>
    );
};

export default TabList;
