import styled from 'styled-components/macro';

export const SearchWrapper = styled.div<{ fullWidth: boolean | undefined }>`
    position: relative;
    width: 100%;
    display: ${(p) => (p.fullWidth ? 'block' : 'inline-block')};
    vertical-align: center;
`;

export const ButtonWrapper = styled.div<{ isRtl?: boolean | undefined }>`
    position: absolute;
    top: 0px;
    ${(p) => (p.isRtl ? 'left: 0px;' : 'right: 0px;')}

    z-index: 10;
    display: flex;
    height: 100%;
`;
