import React from 'react';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { useTranslation } from 'react-i18next';
import { OptionsWrapper } from './styles/styles';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import BartelWidget from './widgets/Bartel/Bartel';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import TabList from '../../../components/TabList/TabList';
import { toggleTab, Reports_From_Partners_Module_Reducer_Values } from './reducer';
import BarthelOptions from './widgets/Bartel/BarthelOptions';
import ModuleOptionsPanel from 'src/components/ModuleOptionsPanel/ModuleOptionsPanel';

const FromPartners: React.FC = ({}) => {
    const { t } = useTranslation();
    const { user } = useSelector(generalReducerValues);
    const { tab } = useSelector(Reports_From_Partners_Module_Reducer_Values);
    const dispatch = useAppDispatch();

    const tabListItems = [
        {
            id: 'Bathel',
            text: t("Barthel's report"),
            permissions: ['app/client | modules/reports/barthel | feature/view'],
        },
    ];

    const allowedTabItems = tabListItems.filter((val) => {
        return val.permissions.every((permission) => user?.permissions.includes(permission));
    });

    const tabListSelectHandler = (id: string) => {
        dispatch(toggleTab(id));
    };

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>{tab === 'Bathel' && <BarthelOptions />}</OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer style={{ maxWidth: 'none' }}>
                <div style={{ marginTop: -15 }}>
                    <TabList
                        widgetName=""
                        selectHandler={tabListSelectHandler}
                        selectedValue={tab}
                        options={allowedTabItems}
                    />
                </div>
                {tab === 'Bathel' && <BartelWidget />}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default FromPartners;
