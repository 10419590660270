import { ISortingConfig, TSortingConfigSection } from '../interfaces';

interface IArgs {
    /**
     * Предыдущее состояние конфигурации сортировки
     */
    prevState: ISortingConfig;
    /**
     * Выбранная секция сортировки
     */
    section: TSortingConfigSection;
}

/**
 * Функция для получения конфигурации сортировки в зависимости от переданной секции
 * @returns конфигурацию сортировки
 */
const getSortingConfig = (args: IArgs): ISortingConfig => {
    const { prevState, section } = args;

    if (section === 'noSection') {
        let directSort = false;
        if (prevState[section]) directSort = !prevState[section].directSort;
        return {
            [section]: {
                directSort,
                section,
            },
        };
    } else {
        if (prevState[section]) {
            const prevValue = prevState[section];
            return { ...prevState, [section]: { ...prevValue, directSort: !prevValue.directSort } };
        } else {
            return {
                ...prevState,
                [section]: {
                    directSort: false,
                    section,
                },
            };
        }
    }
};

export default getSortingConfig;
