export const DEFAULT_SEPARATOR_MODIFICATION_PREFIX: string = '--';
export const DEFAULT_SEPARATOR_NAME_SPACE_PREFIX: string = '-';
export const DEFAULT_SEPARATOR_ELEMENT_PREFIX: string = '__';

export const DEFAULT_CLASS_SIDEBAR: string = `crumbs_sidebar${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}sidebar-form`;
export const DEFAULT_CLASS_HEADER: string = `crumbs_sidebar${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}header-form`;
export const DEFAULT_CLASS_SWITCHER: string = `crumbs_sidebar${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}compact-switcher`;
export const DEFAULT_CLASS_SWITCHER_FORM: string = `crumbs_sidebar${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}compact-switcher-form`;

export const DEFAULT_CLASS_MENU: string = `crumbs_sidebar${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}menu-form`;
export const DEFAULT_CLASS_SIDEBAR_LIST: string = `sidebar-list`;
export const DEFAULT_CLASS_SIDEBAR_DIVIDER: string = `sidebar-divider`;
export const DEFAULT_CLASS_SIDEBAR_LINK: string = `sidebar-link`;
export const DEFAULT_CLASS_FOOTER: string = `crumbs_sidebar${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}footer-form`;
export const DEFAULT_CLASS_LINK: string = `crumbs_sidebar${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}link-form`;

export const FAVORITES_NAME: string = `Favorites`;
