import styled from 'styled-components/macro';

export const CookiesPageWrapper = styled.div`
    padding: var(--space-sm);
    white-space: pre-line; 
    word-wrap: normal;
    width: 100%;
`;

export const Header = styled.h2`
`;
