import { FC, useMemo } from 'react';
import UniversalSelect from '../../../../../../../Selects/UniversalSelect/UniversalSelect';
import Stack from '../../../../../../../Stack/Stack';
import { IProps as ISelectsProps } from '../../interfaces';
import { ZONES_WORD } from '../../../../../../../../constants/constants';
import { getObjSectionName } from '../../../../../../../../tools/Strings/getObjSectionName';
import { RATING_PASSWAYS_TRAFFIC_TYPE_OPTIONS } from '../../../../../../../../Chapters/Dashboards/ObjectPalnels/widgetsConfig';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import { IChangeOptionsArgs } from '../../../../../../../UserSettings/interfaces';
import { TSelectedOptions } from '../../../../../../../Selects/Select/interfaces';
import { RatingPasswaysTrafficIds } from '../../../../../../WidgetsManager/enums';

const PasswaysTrafficSelects: FC<ISelectsProps> = ({ widgetConfig, updateWidget }) => {
    const {
        cfg: { reportingObjectsByType },
        currentModuleID,
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();

    const isAnyZonesInPL = Object.keys(reportingObjectsByType || {}).some((type) => type.includes(ZONES_WORD));
    const passwayTrafficType = widgetConfig?.visual?.passwaysTrafficType?.[0]?.id;

    /** Опции для селекта типа отчетных объект для режимы passwaysTraffic */
    const objectTypesForPasswaysTraffic = useMemo(() => {
        const zoneItems = Object.keys(reportingObjectsByType)
            .filter((item) => item.includes(ZONES_WORD))
            .map((item) => ({
                id: item,
                text: getObjSectionName({ t, reportingObject: reportingObjectsByType[item][0] }),
            }));
        return [...RATING_PASSWAYS_TRAFFIC_TYPE_OPTIONS, ...zoneItems];
    }, [reportingObjectsByType, t]);

    /** Опции для селектов этажей и зон */
    const [floorOptions, zoneOptions] = useMemo(() => {
        let floorOptions: TSelectedOptions = [];
        let zoneOptions: TSelectedOptions = [];
        if (reportingObjectsByType?.['floor']?.length) {
            floorOptions =
                reportingObjectsByType?.['floor']?.map((floor) => {
                    return { id: floor.id, text: floor.marker };
                }) || [];
        }
        if (isAnyZonesInPL && passwayTrafficType && String(passwayTrafficType).includes(ZONES_WORD)) {
            zoneOptions =
                reportingObjectsByType?.[passwayTrafficType]?.map((zone) => {
                    return { id: zone.id, text: zone.marker };
                }) || [];
        }
        return [floorOptions, zoneOptions];
    }, [isAnyZonesInPL, passwayTrafficType, reportingObjectsByType]);

    const onSelectChange = (key: string) => (args: IChangeOptionsArgs) => {
        updateWidget(key, args.newOptions);
    };

    return (
        <Stack styles={{ padding: '0px 12px' }} direction="column" gap={5}>
            <UniversalSelect
                options={objectTypesForPasswaysTraffic}
                localCurrentOptions={{ ...widgetConfig?.visual, currentModuleID }}
                changeOptions={onSelectChange('visual')}
                name={`passwaysTrafficType`}
                labelText={'Traffic by'}
                maxWidth={195}
                fullWidth={true}
                mode="single"
            />
            <UniversalSelect
                options={floorOptions}
                disabled={passwayTrafficType !== RatingPasswaysTrafficIds.Floor}
                localCurrentOptions={{ ...widgetConfig?.visual, currentModuleID }}
                changeOptions={onSelectChange('visual')}
                name={`passwaysTrafficFloor`}
                labelText={'Select floor'}
                maxWidth={195}
                fullWidth={true}
                mode="single"
            />
            {isAnyZonesInPL && (
                <UniversalSelect
                    options={zoneOptions}
                    disabled={!String(passwayTrafficType).includes(ZONES_WORD)}
                    localCurrentOptions={{ ...widgetConfig?.visual, currentModuleID }}
                    changeOptions={onSelectChange('visual')}
                    name={`passwaysTrafficZone`}
                    labelText={'Select zone'}
                    text={'Select group of zone'}
                    maxWidth={195}
                    fullWidth={true}
                    mode="single"
                />
            )}
        </Stack>
    );
};

export default PasswaysTrafficSelects;
