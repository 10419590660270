import { isFinite, isNaN } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import generateId from '../../../../../../../../../tools/generateId';
import { EventsMapWidgetContext } from '../../../../context';
import { weekDays } from '../../constants/constants';
import getRanges from '../../tools/getRanges';
import GridHeader from '../GridHeader/GridHeader';
import Event from './components/Event/Event';
import { Cell, CellText, Month, Row, Wrapper } from './styles';
import { arrangeDaysToMonthGrid } from './tools/arrangeDaysToMonthGrid';

interface IProps {
    /** Текущая дата */
    date: DateTime;
    /** Отображение сетки месяцы в режиме годы */
    yearView?: boolean;
}

/**
 * Компонент для отображения календаря за месяц
 */
const MonthsGrid: FC<IProps> = ({ date, yearView = false }) => {
    const context = useContext(EventsMapWidgetContext);
    const { t } = useTranslation();

    const allDaysOfCalendar = useMemo(() => {
        return getRanges({ date, mode: 'month', durationType: 'days' });
    }, [date]);

    const maxIndex = useMemo(() => {
        const result = Math.max(
            ...Object.values(context?.extendedEventsDataById || {})
                ?.filter(
                    (element) =>
                        Interval.fromDateTimes(date.startOf('month'), date.endOf('month')).contains(
                            DateTime.fromISO(element.date_from),
                        ) ||
                        Interval.fromDateTimes(date.startOf('month'), date.endOf('month')).contains(
                            DateTime.fromISO(element.date_to),
                        ),
                )
                .map((element) => element.index),
        );
        return isFinite(result) && !isNaN(result) ? result : 0;
    }, [context?.extendedEventsDataById, date]);

    const weeks = useMemo(() => {
        return arrangeDaysToMonthGrid(allDaysOfCalendar).map((week) => {
            return (
                <Row yearView={yearView} isHeader={false} key={week.weekNumber + generateId()}>
                    {!yearView && (
                        <Cell isHeader={false} maxIndex={maxIndex}>
                            {week.weekNumber}
                        </Cell>
                    )}

                    {week.week.map((day) => {
                        const currentDateEvents = Object.values(context?.extendedEventsDataById || {})?.filter(
                            (element) => {
                                if (day.data) {
                                    return (
                                        DateTime.fromISO(element.date_from.split('T')[0]).toMillis() <=
                                            day.data.toMillis() &&
                                        DateTime.fromISO(element.date_to.split('T')[0]).toMillis() >=
                                            day.data.toMillis()
                                    );
                                }
                                return false;
                            },
                        );

                        return (
                            <Cell yearView={yearView} key={generateId()} isHeader={false} maxIndex={maxIndex}>
                                {currentDateEvents.map((element) => {
                                    return <Event key={element.id} yearView={yearView} event={element} day={day} />;
                                })}
                                <CellText
                                    isWeekend={day.data?.weekday === 6 || day.data?.weekday === 7}
                                    yearView={yearView}
                                    isHeader={false}
                                >
                                    {day.data?.day}
                                </CellText>
                            </Cell>
                        );
                    })}
                </Row>
            );
        });
    }, [allDaysOfCalendar, context?.extendedEventsDataById, maxIndex]);

    return (
        <Wrapper yearView={yearView}>
            {!yearView && <GridHeader date={date} mode={'months'} />}

            <Month yearView={yearView}>
                <Row yearView={yearView} isHeader={true}>
                    {weekDays.slice(yearView ? 1 : 0).map((element) => (
                        <Cell yearView={yearView} key={element.name} isHeader={true}>
                            <CellText
                                yearView={yearView}
                                isWeekend={element.weekNumber === 6 || element.weekNumber === 7}
                                isHeader={true}
                            >
                                {yearView ? t(element.shortName) : t(element.name)}
                            </CellText>
                        </Cell>
                    ))}
                </Row>
                {weeks}
            </Month>
        </Wrapper>
    );
};

export default MonthsGrid;
