import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getReportingObjects } from '../../../../../../../tools/getReportingObjects';
import WidgetTitle from '../../../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { TableOfObjectsContext } from '../../context';
import { generalReducerValues } from '../../../../../../../General.reducer';
import WidgetAdditionalControls from '../../../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import { ITabListOptionsItem } from '../../../../../../../components/TabList/interfaces';
import TabList from '../../../../../../../components/TabList/TabList';

/**
 * Шапка виджета
 */
const Header = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const [tabListItems, setTabListItems] = useState<ITabListOptionsItem[]>([]);
    const context = useContext(TableOfObjectsContext);
    const { t } = useTranslation();

    useEffect(() => {
        const lockedFields = ['location', 'passway'];

        if (context) {
            const tabListItems = getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    text: item.section,
                    id: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.id));

            if (!context.selectedObjectsType && tabListItems.length) {
                context.storeSelectedObjectsTypeTrans(tabListItems[0].id);
            }

            setTabListItems(tabListItems);
        }
    }, [reportingObjectsByType, t]);

    const tabListSelectHandler = (id: string) => {
        context && context.storeSelectedObjectsTypeTrans(id);
    };

    return (
        <WidgetTitleWrapper>
            <WidgetTitle>
                {t('Table of objects')}
                {context && (
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: context.rootNodeRef }}
                        reloadHandler={context.reloadWidget}
                        widgetName={'Table of objects'}
                    />
                )}
            </WidgetTitle>
            <TabList
                selectedValue={context?.selectedObjectsType}
                selectHandler={tabListSelectHandler}
                options={tabListItems}
            />
        </WidgetTitleWrapper>
    );
};

export default Header;
