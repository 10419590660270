import { AxiosError, AxiosResponse } from 'axios';
import { generalReducerValues } from '../General.reducer';
import { getRequest, patchRequest, postRequest } from '../tools/API/appBackendAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { userSettingsReducerValues } from '../components/UserSettings/reducer';
import { PresetReducerValues } from '../components/Preset/reducer';

/**
 * Кастомный хук для работы с параметрами аккаунта (получение, обновление)
 */
export const useChangeAccParams = () => {
    const { settings, accParamsID } = useSelector(userSettingsReducerValues);
    const { token, user, urlsByServices, initialDataReceived } = useSelector(generalReducerValues);
    const { sharedPresetHash } = useSelector(PresetReducerValues);

    const baseUrl = useMemo(() => {
        if (urlsByServices) return urlsByServices?.['app/app-backend'].ACCOUNT_PARAMETERS_URL;
        return null;
    }, [urlsByServices]);

    /** Изменение настроек пользователя на сервере при изменении каких-либо настроек на фронте */
    useEffect(() => {
        if (!user?.id || !token || !initialDataReceived || !baseUrl || sharedPresetHash) return;
        const timer = setTimeout(() => {
            patchRequest({
                url: `${baseUrl}${accParamsID}/`,
                token,
                data: { id: accParamsID, params: settings },
            }).then();
        }, 5000);
        return () => clearTimeout(timer);
    }, [settings, baseUrl, initialDataReceived, token, user?.id]);
};
