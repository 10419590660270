import { FC } from 'react';
import NumberToShow from '../../../../../../../components/NumberToShow/NumberToShow';
import { IProps } from './interfaces';
import { Wrapper } from './styles';

/**
 * Компонент для отображения подвала виджета
 */
const Footer: FC<IProps> = ({ isMobile, storeTableItemsToShowTrans }) => {
    const handleSelect = (value: number | 'all') => {
        if (isMobile) {
            storeTableItemsToShowTrans(value);
        }
    };

    return (
        <Wrapper>
            <NumberToShow handleSelect={handleSelect} defaultSelected={10} />
        </Wrapper>
    );
};

export default Footer;
