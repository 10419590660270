import styled from 'styled-components';

export const PanelWrapper = styled.div`
    border-radius: var(--space-xxxxs);
    border: 1px solid var(--color-secondary);
    padding: var(--space-xs);
    margin-bottom: var(--space-md);
    //margin-right: var(--space-sm);
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        width: 100%;
        margin-right: 0;
    }
`;

export const Title = styled.div`
    position: absolute;
    left: var(--space-xxs);
    top: -20px;
    padding: var(--space-xxxs);
    background-color: white;
    z-index: 10;
    font-size: var(--text-md);
    color: var(--color-secondary-darker);
`;
