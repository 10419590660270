import { IAggregatedObject, IGenerateSeriesDataArgs, IResponseItem } from './interfaces';

import { DateTime } from 'luxon';
import { DS, MINUTE_IN_MILLIS } from '../../../../../../constants/constants';
import { TSeries } from '../../../../../../components/Charts/Dynamics/interfaces';
import { cloneDeep, isNull } from 'lodash';
import generateId from '../../../../../../tools/generateId';
import { stringDate } from '../../../../../../tools/Strings/stringDate';

/**
 * функция для генерации серий и дополнительных параметров необходимых для получения настроек
 * диаграммы
 * @param reportingObjectsRawMetricsDataById Объект сырыых метрик для каждого из отчетных объектов
 * @param extendedReportingObjectsById Объект расширенных отчетных объектов
 * @param shoudUpdateChartsByPeriod Флаг отвечающий за обновление всех данных
 * @param mainPeriod основной период
 * @returns объект, где для ключом является id отчетного объекта, а значение - это объект с данными,
 * необходимыми для генерации настроект графика
 */
const generateSeriesData = (args: IGenerateSeriesDataArgs) => {
    const { reportingObjectsRawMetricsDataById, mainDateRanges, mainPeriod, detailing, t, lang, timezone } = args;

    const subTitle = 'Visitors inside';
    const dateNow = DateTime.now();
    const title = 'Periods';
    const units = 'ppl';
    const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod?.id);
    const mainPeriodDateFrom = DateTime.fromISO(mainPeriodDateRnage?.period?.dateFrom || '').toMillis();
    const mainPeriodDateTo = DateTime.fromISO(mainPeriodDateRnage?.period?.dateTo || '')
        .set({ hour: 23, minute: 59 })
        .toMillis();
    const result = Object.keys(reportingObjectsRawMetricsDataById).reduce((acc, reportingObjectId) => {
        const series: TSeries[] = [];
        let visitorsAtTheMoment = 0;
        const rawMetricsData = cloneDeep(reportingObjectsRawMetricsDataById[reportingObjectId]);
        if (Array.isArray(rawMetricsData)) {
            const [rawData]: IAggregatedObject[] = rawMetricsData;
            if (rawData.visitorsInside) {
                Object.keys(rawData.visitorsInside).forEach((period, periodIndex) => {
                    const periodDate = DateTime.fromISO(period).toMillis();

                    let visitorsInsideAtTheMomentPoint = false;
                    const data = rawData.visitorsInside![period].reduce(
                        (
                            acc: { y: number | null; x: number; name: string }[],
                            metricData: IResponseItem,
                            metricIndex: number,
                            initialArray: IResponseItem[],
                        ) => {
                            const dateNowWithTimeZone = dateNow.setZone(timezone || rawData.objectInfo['timezone']);
                            const currentDatetime = DateTime.fromISO(metricData.time, { zone: 'UTC' });

                            let yValue = metricData.value;

                            /**
                             * Если сейчас итерация идет по основному периоду и выбраy сегодняшний день, то для точек со значением null до
                             * текущего часа устанавливается значение 0, а для остаьных null или то значение, которое пришло в значении метрики
                             */
                            if (
                                DateTime.fromISO(metricData.time).toMillis() <= mainPeriodDateTo &&
                                DateTime.fromISO(metricData.time).toMillis() >= mainPeriodDateFrom &&
                                DateTime.fromISO(metricData.time).day === DateTime.fromMillis(mainPeriodDateFrom).day &&
                                DateTime.fromISO(metricData.time).month ===
                                    DateTime.fromMillis(mainPeriodDateFrom).month &&
                                mainPeriod.id === 'today'
                            ) {
                                if (isNull(metricData.value) && dateNowWithTimeZone.hour > currentDatetime.hour) {
                                    // yValue = 0;
                                } else if (!isNull(metricData.value) && metricData.value <= 0) {
                                    yValue = 0;
                                } else if (metricData.value) {
                                    yValue = metricData.value;
                                }
                            } else {
                                if (!isNull(metricData.value) && metricData.value < 0) {
                                    yValue = 0;
                                } else {
                                    yValue = metricData.value;
                                }
                            }

                            const currentValue = {
                                name: stringDate(
                                    DateTime.fromISO(metricData.time).toISO(),
                                    lang,
                                    '',
                                    'dd MMM yyyy, HH:mm',
                                ),
                                x: currentDatetime.toMillis(),
                                y: yValue,
                            };

                            if (
                                dateNowWithTimeZone.day === currentDatetime.day &&
                                dateNowWithTimeZone.month === currentDatetime.month &&
                                dateNowWithTimeZone.hour === currentDatetime.hour &&
                                dateNowWithTimeZone.minute - currentDatetime.minute <= 15 &&
                                !visitorsInsideAtTheMomentPoint &&
                                mainPeriod.id === 'today'
                            ) {
                                /**
                                 * Последне не null значение
                                 */
                                const currentDateValue = cloneDeep(initialArray)
                                    .reverse()
                                    ?.find((element) => element.value !== null)?.value;

                                visitorsInsideAtTheMomentPoint = true;
                                let y = 0;

                                /**
                                 * Если итерация идет по периоду сравнения, то его значение берется на конец часа
                                 */
                                if (
                                    DateTime.fromISO(metricData.time).toMillis() <= mainPeriodDateTo &&
                                    DateTime.fromISO(metricData.time).toMillis() >= mainPeriodDateFrom
                                ) {
                                    y = metricData.value || currentDateValue || 0;
                                } else {
                                    y = metricData.value || 0;
                                }

                                visitorsAtTheMoment = y < 0 ? 0 : y;

                                const value = {
                                    name: stringDate(
                                        DateTime.fromISO(metricData.time)
                                            .set({
                                                hour: dateNowWithTimeZone.hour,
                                                minute: dateNowWithTimeZone.minute,
                                            })
                                            .toISO(),
                                        lang,
                                        '',
                                        'dd MMM yyyy, HH:mm',
                                    ),
                                    x:
                                        dateNowWithTimeZone.toMillis() +
                                        DateTime.now().setZone(timezone || rawData.objectInfo['timezone']).offset *
                                            MINUTE_IN_MILLIS,
                                    y: visitorsAtTheMoment,
                                    units,
                                };

                                return acc.concat([value]);
                            }

                            switch (detailing) {
                                case '15min':
                                    acc.push(currentValue);
                                    break;

                                case 'H':
                                default:
                                    currentDatetime.minute === 0 && acc.push(currentValue);
                                    break;
                            }

                            return acc;
                        },
                        [],
                    );
                    const seriesData: TSeries = {
                        type: periodDate === mainPeriodDateFrom ? 'areaspline' : 'spline',
                        isMain: periodDate === mainPeriodDateFrom,
                        name: stringDate(period.split(DS)[0], lang),
                        id: period.split(DS)[0] + generateId(),
                        data,
                    };
                    series.push(seriesData);
                });
            }

            acc[reportingObjectId] = {
                timezone: timezone || rawData.objectInfo['timezone'],
                showCurrentDateIndicator: true,
                tooltipSubTitle: `${t(subTitle)}, ${t(units)}`,
                tooltipTitle: t(title),
                visitorsAtTheMoment,
                series,
            };
        } else {
            acc[reportingObjectId] = null;
        }

        return acc;
    }, {});

    return result;
};

export default generateSeriesData;
