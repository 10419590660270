import { ArrowRight16Regular } from '@fluentui/react-icons';
import styled from 'styled-components/macro';

export const GrandWrapper = styled.div<{ open: boolean }>`
    position: relative;
    flex: ${(p) => (p.open ? '0 0 15%' : '0 0 56px')};
    height: 100%;
    border-right: ${(p) => (p.theme.isRtlLanguage ? 'none' : '1px solid var(--color-contrast-low)')};
    border-left: ${(p) => (p.theme.isRtlLanguage ? '1px solid var(--color-contrast-low)' : 'none')};

    transition: all 0.3s;
    padding: 15px;
`;

export const Wrapper = styled.div`
    overflow-x: hidden;
    margin-top: 25px;
`;

export const StyledIcon = styled(ArrowRight16Regular)<{ open: boolean }>`
    cursor: pointer;
    transform: ${(p) =>
        p.open
            ? p.theme.isRtlLanguage
                ? 'rotateY(0deg)'
                : 'rotateY(180deg)'
            : p.theme.isRtlLanguage
            ? 'rotateY(180deg)'
            : 'rotateY(0deg)'};
`;

export const IconWrapper = styled.div`
    right: ${(p) => (p.theme.isRtlLanguage ? 'unset' : '0px')};
    left: ${(p) => (p.theme.isRtlLanguage ? '0px' : 'unset')};
    border: 1px solid var(--color-contrast-low);
    transform: ${(p) => (p.theme.isRtlLanguage ? 'translateX(-50%)' : 'translateX(50%)')};
    top: 9px;
    background-color: white;

    position: absolute;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
`;
