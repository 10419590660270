import { FC } from 'react';
import { AnyAction } from 'redux';

import Stack from '../Stack/Stack';

import PDFDownload from './components/PDFDownload/PDFDownload';
import Reload from './components/Reload/Reload';
import { XLSXDownload } from './components/XLSXDownload/XLSXDownload';
import { CSVDownload } from './components/CSVDownload/CSVDownload';
import { Wrapper } from './styles';
import FeedbackButton from './components/FeedbackButton/FeedbackButton';
import Tooltip from '../Tooltip/Tooltip';
import { InfoFilled } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import IconButton from '../IconButton/IconButton';

interface IProps {
    widgetName: string;
    /** Данные для загрузки PDF */
    pdfDownloadData?: {
        targetRef: React.RefObject<HTMLDivElement | null>;
    };
    /** Данные для загрузки XLSX */
    xlsxDownloadData?: { tableName?: string; onDownload?: () => void };
    csvDownloadData?: { tableName?: string; onDownload?: () => void };
    /** Функция для перезагрузки виджета */
    reloadHandler?: () => AnyAction;
    /** Функция для перезагрузки виджета, но без диспатча */
    reloadHandlerNoDispatch?: () => void;
    hasFeedback?: boolean;
    hideOnMobile?: boolean;
    info?: string;
}

/**
 * Компонент для отображения дополнительных контролов для виджета
 */
const WidgetAdditionalControls: FC<IProps> = ({
    pdfDownloadData,
    reloadHandler,
    reloadHandlerNoDispatch,
    xlsxDownloadData,
    csvDownloadData,
    info,
    widgetName,
    hasFeedback = true,
    hideOnMobile = true,
}) => {
    const { t } = useTranslation(['translationHelp']);
    return (
        <Wrapper hideOnMobile={hideOnMobile}>
            <Stack padding={'0 0 0 5px'} gap={0}>
                {pdfDownloadData && <PDFDownload {...pdfDownloadData} fileName={widgetName} />}
                {(reloadHandler || reloadHandlerNoDispatch) && (
                    <Reload
                        reloadHandler={reloadHandler}
                        reloadHandlerNoDispatch={reloadHandlerNoDispatch}
                        widgetName={widgetName}
                    />
                )}
                {xlsxDownloadData?.onDownload && <XLSXDownload {...xlsxDownloadData} />}
                {csvDownloadData?.onDownload && <CSVDownload {...csvDownloadData} />}
                {hasFeedback && <FeedbackButton widgetName={widgetName} />}
                {info && (
                    <Tooltip content={t(info)}>
                        <IconButton
                            styles={{ paddingTop: 1 }}
                            onClick={() => {}}
                            icon={<InfoFilled />}
                            testId={`FEEDBACK-${widgetName}`}
                        />
                    </Tooltip>
                )}
            </Stack>
        </Wrapper>
    );
};

export default WidgetAdditionalControls;
