import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../../General.reducer';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from '../../../../../reducer';
import axios, { AxiosError } from 'axios';
import { IBgReport, IDateRange } from '../../../../../../../../../../General.interfaces';
import bgReportsTableDataAdapter, { ITableItem } from '../dataAdapters/bgReportsTableDataAdapter';
import { DS, GET_ALL_BG_REPORTS_RQ_KEY } from '../../../../../../../../../../constants/constants';
import { useWidgetCurrentOptions } from '../../../../../../../../../../hooks/useWidgetCurrentOptions';
import { useTranslation } from 'react-i18next';
import { getHeaders } from '../../../../../../../../../../tools/API/getHeaders';

/**
 * Кастомный хук для получения данных с сервера
 */
const useFetchData = () => {
    const { keyWord, bgReportType } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);
    const {
        token,
        urlsByServices,
        selectedLocationId,
        lang,
        backgroundReportTypesById,
        currentModuleID,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const bgReportUrl = useMemo(() => {
        if (urlsByServices) {
            return urlsByServices['app/app-backend'].BACKGROUND_REPORTS_URL;
        }
        return null;
    }, [urlsByServices]);

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        const dateRanges = localCurrentOptions?.[`mainDateRanges${DS}${keyWord}Archive`];

        const dateRange = dateRanges?.find(
            (item: { id: string }) => item.id === localCurrentOptions?.[`mainPeriod${DS}${keyWord}Archive`]?.id,
        );

        if (token && bgReportUrl && selectedLocationId && dateRange?.period) {
            return [
                [
                    GET_ALL_BG_REPORTS_RQ_KEY,
                    token,
                    bgReportType,
                    keyWord,
                    selectedLocationId,
                    dateRange?.period.dateFrom,
                    dateRange?.period.dateTo,
                ],
                () => {
                    return axios
                        .get<IBgReport[]>(
                            `${bgReportUrl}?report_type=${bgReportType}&pl_id=${selectedLocationId}&date_from=${dateRange?.period?.dateFrom}&date_to=${dateRange?.period?.dateTo}`,
                            {
                                headers: getHeaders(token),
                            },
                        )
                        .then((response) => response.data);
                },
                (response: IBgReport[]) =>
                    bgReportsTableDataAdapter({
                        reports: response,
                        lang,
                        backgroundReportTypesById,
                        reportingObjectsById,
                    }),
            ];
        }
        return [[]];
    }, [
        localCurrentOptions,
        keyWord,
        token,
        bgReportUrl,
        selectedLocationId,
        bgReportType,
        lang,
        backgroundReportTypesById,
        reportingObjectsById,
    ]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
        refetchInterval: 120000,
        retry: 2,
    });
};

export default useFetchData;
