import { useSelector } from 'react-redux';
import { Configuration_SalesMetricsUpload_Module_Reducer_Values, storeDataToEdit } from '../../reducer';
import Table from '../Table/Table';
import Stack from '../../../../../components/Stack/Stack';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { TSelectedOptions } from '../../../../../components/Selects/Select/interfaces';
import { generalReducerValues } from '../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import Button from '../../../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { ICorrectionBill } from '../../interfaces';
import Modals from '../../../../../components/Modals/Modals';
import ClearModal from './components/ClearModal/ClearModal';
import { useGetCorrectionBills } from '../../api/useGetCorrectionBills';
import { CSVLink } from 'react-csv';
import { useCreateCSVTemplate } from '../../tools/useCreateCSVTemplate';

import { AlertContainer } from './styles';

import useSaveTable from '../../tools/useSaveTable';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';

interface IProps {}

const DataEdit: React.FC<IProps> = () => {
    const sendAnalytics = useSendSimpleAnalytics();
    const { data } = useGetCorrectionBills();
    const { dataToEdit, timeFreq } = useSelector(Configuration_SalesMetricsUpload_Module_Reducer_Values);
    const { currentModuleID } = useSelector(generalReducerValues);
    const [clearModalStatus, setClearModalStatus] = useState<{ show: boolean; data: ICorrectionBill[] }>({
        show: false,
        data: [],
    });

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { csvHeaders, csvData, fileName } = useCreateCSVTemplate();

    const { save } = useSaveTable('dataToEdit');

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const options: TSelectedOptions = [
        { id: 'MS', text: 'Monthly' },
        { id: 'D', text: 'Daily' },
    ];

    const saveActive = useMemo(() => {
        return dataToEdit.some((row) => {
            return row.some((cell) => {
                return cell.changeType !== undefined;
            });
        });
    }, [dataToEdit]);

    const onSaveClick = () => {
        save({ dataToSave: dataToEdit });
        sendAnalytics(`Metrics_table_Save_click`);
    };

    const onResetClick = () => {
        data?.metricsTableData && dispatch(storeDataToEdit(data?.metricsTableData));
    };

    const closeClearModal = () => {
        setClearModalStatus({ show: false, data: [] });
    };

    const openClearModal = () => {
        setClearModalStatus({ show: true, data: data?.rawData || [] });
    };

    return timeFreq === 'mixed' ? (
        <AlertContainer>
            {t('Objects with different detalization are selected. Please change selections of period and/or objects.')}
        </AlertContainer>
    ) : (
        <>
            <Modals title={t('Clear data')} modalStatus={clearModalStatus} closeModal={closeClearModal}>
                <ClearModal data={clearModalStatus.data || []} closeModal={closeClearModal} />
            </Modals>

            <Stack styles={{ marginBottom: 20 }} gap={10}>
                {timeFreq === null && (
                    <UniversalSelect
                        defaultSelected={[{ id: 'MS', text: 'Monthly' }]}
                        mode="single"
                        dropdownWidth="sm"
                        name="detailing"
                        options={options}
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                    />
                )}
                <Button baseColor="accent" text={t('Clear data')} onClick={openClearModal} />
                <CSVLink
                    separator={';'}
                    enclosingCharacter=""
                    className="btn btn-secondary"
                    data={csvData}
                    headers={csvHeaders}
                    filename={`${fileName}.csv`}
                >
                    {t('Download template')}
                </CSVLink>
                <Button baseColor="warning" text={t('Reset changes')} disabled={!saveActive} onClick={onResetClick} />
                <Button text={t('Save')} appearance="primary" disabled={!saveActive} onClick={onSaveClick} />
            </Stack>
            {dataToEdit.length > 3000 ? (
                <AlertContainer>
                    {t('The data range selected is too large. Please reduce interval or tenants number.')}
                </AlertContainer>
            ) : (
                <Table data={dataToEdit} tableKey={'dataToEdit'} />
            )}
        </>
    );
};

export default DataEdit;
