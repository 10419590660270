import { ChartWrapper, MainWrapper } from './styles';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dynamics from '../../../../../components/Charts/Dynamics/Dynamics';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Cases_Benchmarks_Traffic_Widgwet_Reducer_Values, reloadWidget } from './reducer';
import { generalReducerValues } from '../../../../../General.reducer';
import useChartOptions from './hooks/useChartOptions';
import { useTranslation } from 'react-i18next';
import { withLoading } from '../../../../../tools/API/withLoading';
import { TSeries } from '../../../../../components/Charts/Dynamics/interfaces';
import { AxisLabelsFormatterContextObject } from 'highcharts';
import datetimeFormatHelper from './tools/datetimeFormatHelper';
import usePrepareData from './hooks/usePrepareData';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

const DynamicsPMO: React.FC = ({}) => {
    const ref = useRef(null);

    const { lang } = useSelector(generalReducerValues);
    const { rowMetricsData, chartOptionsGeneratorSettings, selectedChartDetailing } = useSelector(
        Cases_Benchmarks_Traffic_Widgwet_Reducer_Values,
    );

    const options = useChartOptions();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePrepareData();

    const WithLoadingTable = withLoading(ChartWrapper, { data: rowMetricsData, height: 400 });

    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return datetimeFormatHelper({
            selectedChartDetailing: selectedChartDetailing,
            dateInMillis: this.value as number,
            isCategory: true,
            lang,
            t,
        });
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {'Mall Index'}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        reloadHandler={reloadWidget}
                        widgetName={'Mall Index'}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <WithLoadingTable>
                <Dynamics
                    series={chartOptionsGeneratorSettings?.series as TSeries[]}
                    tooltipTitle={'Mall Index'}
                    xAxisLabelsFormating={xAxisLabelsFormating}
                />
            </WithLoadingTable>
        </WidgetWrapper>
    );
};

export default DynamicsPMO;
