import { KonvaEventObject } from 'konva/lib/Node';
import React, { useId } from 'react';
import { Shape } from 'react-konva';

import { POLYGON_NEUTRAL_COLOR } from '../../../../constants';

import { Props } from './interfaces';

/**
 * Компонент, отвечающий за отображение слоя зон
 * @param selectedMarker Выбранный маркер отчетного объекта
 * @param selectObject Функция для выбора маркера отчетного объекта
 * @param onMouseLeave Функция, которая вызывается при сдвиге мыши с текущего слоя
 * @param onMouseOver Функция, которая вызывается при наведении мыши на текущий слой
 * @param stageScale Масштаб холста
 * @param heatColor Цыет , который зависит от значения метрики
 * @param object Сам объект
 * @param plan Информация о плане
 */
const Zone: React.FC<Props> = ({
    onMouseLeave: onMouseLeaveTrans,
    onMouseOver: onMouseOverTrans,
    changeStageCursor,
    availableMarkers,
    selectedMarker,
    selectObject,
    stageIndex,
    heatColor,
    object,
}) => {
    const id = useId();

    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        if (selectObject) {
            if (Array.isArray(availableMarkers)) {
                availableMarkers.includes(object.zone_marker) && selectObject(object.zone_marker);
            } else {
                selectObject(object.zone_marker);
            }
        }
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        if (Array.isArray(availableMarkers)) {
            !availableMarkers.includes(object.zone_marker) && changeStageCursor(stageIndex, 'not-allowed')();
        }

        e.cancelBubble = true;
        onMouseOverTrans && onMouseOverTrans(object.zone_marker);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        if (Array.isArray(availableMarkers)) {
            !availableMarkers.includes(object.zone_marker) && changeStageCursor(stageIndex, 'pointer')();
        }
        e.cancelBubble = true;
        onMouseLeaveTrans && onMouseLeaveTrans(null);
    };

    return (
        <>
            <Shape
                sceneFunc={(context, shape) => {
                    context.beginPath();
                    context.moveTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                    object.coords.coordinates.forEach((coord: number[], i: number) => {
                        if (i > 0) {
                            context.lineTo(coord[0], coord[1]);
                        }
                    });
                    context.lineTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                    context.fillStrokeShape(shape);
                }}
                fill={
                    selectedMarker !== object.zone_marker
                        ? heatColor || POLYGON_NEUTRAL_COLOR
                        : 'rgba(85, 125, 249, .7)'
                }
                // fillPatternImage={selectedMarker === object.zone_marker ? hatchingLines : undefined}
                // fillPatternScaleY={
                //     selectedMarker === object.zone_marker ? (plan.scale * plan.width) / 25000 : undefined
                // }
                // fillPatternScaleX={
                //     selectedMarker === object.zone_marker ? (plan.scale * plan.width) / 25000 : undefined
                // }
                stroke={'white'}
                // strokeWidth={scaleTransformFoo(25, plan.scale * stageScale)}
                onMouseLeave={onMouseLeave}
                onMouseOver={onMouseOver}
                onClick={onObjectClick}
                onTap={onObjectClick}
                key={`zone-${id}`}
                id={`zone-${id}`}
                opacity={1}
            />
        </>
    );
};

export default Zone;
