import { Identity } from 'src/types';

import { StyledHeaderCell } from './styles';

export const defaultValueRenderer = (cell: any) => {
    return cell.value;
};

export const defaultDataRenderer = (cell: any) => {
    return cell.value;
};

export const defaultHeaderCellRenderer = <I extends string = string, R extends Identity<I> = Identity<I>>(row: R) => (
    <StyledHeaderCell key={row.id}>{row.id}</StyledHeaderCell>
);
