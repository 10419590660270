import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';

const ModuleWrapper: React.FC<{
    children: ReactNode;
    styles?: React.CSSProperties;
    ref?: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ children, styles, ref }) => {
    const { t } = useTranslation();
    return <Wrapper style={{ ...styles }}>{children}</Wrapper>;
};

export default ModuleWrapper;
