import { ResponseStatus } from '../../../../../../tools/API/constants';
import { IComparePeriod, IExtendedMetric, IExtendedReportingObject } from '../interfaces';
import { DateTime } from 'luxon';
import {
    IMetricResponseItem,
    IReportingObject,
    TMetricResponse,
    IPeriod,
} from '../../../../../../General.interfaces';

interface IArgs {
    rawMetricsData: TMetricResponse[] | { status: ResponseStatus; message: string };
    reportingObjects: IReportingObject[];
    mainDateRanges: IPeriod[];
    selectedMetrics: string[];
    mainPeriod: IPeriod;
    selectedComparePeriod: IComparePeriod;
}

/**
 * Функция для получения расширенной информации о арендаторах в зависимости от сырых данных метрик
 * @param selectedMetrics выбранные метрики
 * @param rawMetricsData сырые данные
 * @param mainDateRanges Периоды для основоного периода
 * @param comparePeriods Периоды сравнения
 * @param mainPeriod Основной период
 * @param reportingObjects все арендаторы
 * @param selectedComparePeriod выбранный перод сравнения
 */
const generateExtendedReportingObjects = (args: IArgs): IExtendedReportingObject[] => {
    const { rawMetricsData, mainDateRanges, mainPeriod, reportingObjects, selectedMetrics, selectedComparePeriod } =
        args;
    const result: IExtendedReportingObject[] = [];

    if (Array.isArray(rawMetricsData)) {
        const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod.id)?.period;

        if (mainPeriodDateRnage) {
            const mainPeriodDateFrom = DateTime.fromISO(mainPeriodDateRnage.dateFrom).toMillis();
            const mainPeriodDateTo = DateTime.fromISO(mainPeriodDateRnage.dateTo).toMillis();

            const comparePeriodDateFrom = DateTime.fromISO(selectedComparePeriod?.period.dateFrom).toMillis();
            const comparePeriodDateTo = DateTime.fromISO(selectedComparePeriod?.period.dateTo).toMillis();

            const mainPeriodMetrics: IMetricResponseItem[] = [];
            const comparePeriodMetrics: IMetricResponseItem[] = [];

            rawMetricsData.forEach((rawData) => {
                rawData.forEach((element) => {
                    const metricDateFrom = DateTime.fromISO(element.context.time_range[0]).toMillis();
                    const metricDateTo = DateTime.fromISO(element.context.time_range[1]).toMillis();
                    if (mainPeriodDateFrom === metricDateFrom && mainPeriodDateTo === metricDateTo) {
                        mainPeriodMetrics.push(element);
                    }

                    if (comparePeriodDateFrom === metricDateFrom && comparePeriodDateTo === metricDateTo) {
                        comparePeriodMetrics.push(element);
                    }
                });
            });

            reportingObjects.forEach((reportingObject) => {
                const currentReportingObjectMainPeriodMetrics: { [metricId: string]: IMetricResponseItem } = {};
                const currentReportingObjectComparePeriodMetrics: { [metricId: string]: IMetricResponseItem } = {};

                const currentMainReportingObjectMetrics = mainPeriodMetrics?.filter(
                    (element) => element.context.data_objects[0].id === reportingObject.id,
                );
                if (currentMainReportingObjectMetrics.length) {
                    currentMainReportingObjectMetrics.forEach((element) => {
                        currentReportingObjectMainPeriodMetrics[element.context.metric] = element;
                    });
                }

                const currentCompareReportingObjectMetrics = comparePeriodMetrics?.filter(
                    (element) => element.context.data_objects[0].id === reportingObject.id,
                );

                if (currentCompareReportingObjectMetrics.length) {
                    currentCompareReportingObjectMetrics.forEach((element) => {
                        currentReportingObjectComparePeriodMetrics[element.context.metric] = element;
                    });
                }

                const metrics: {
                    [metricId: string]: IExtendedMetric;
                } = selectedMetrics.reduce((acc, value) => {
                    acc[value] = {
                        comparePeriod: currentReportingObjectComparePeriodMetrics[value],
                        mainPeriod: currentReportingObjectMainPeriodMetrics[value],
                    };
                    return acc;
                }, {});

                result.push({
                    metricsLoading: false,
                    metricsError: false,
                    reportingObjectData: reportingObject,
                    metrics,
                });
            });
        }
    } else if (rawMetricsData.status === ResponseStatus.Loading) {
        reportingObjects.forEach((reportingObject) => {
            result.push({
                metricsLoading: true,
                metricsError: false,
                reportingObjectData: reportingObject,
                metrics: null,
            });
        });
    } else if (rawMetricsData.status === ResponseStatus.Error) {
        reportingObjects.forEach((reportingObject) => {
            result.push({
                metricsLoading: false,
                metricsError: true,
                reportingObjectData: reportingObject,
                metrics: null,
            });
        });
    }

    return result;
};

export default generateExtendedReportingObjects;
