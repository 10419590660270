import { IMetric, IMetricResponseItem, IReportingObject, IResponseItem } from '../../../../../General.interfaces';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import { WEATHER_METRICS_BY_NAME } from '../../constants/constants';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';
import { isNumber, keyBy, maxBy } from 'lodash';
import { DateTime } from 'luxon';
import { theme } from 'src/theme';
import { IWeatherData } from '../../interfaces';
import getWeatherMarkerSymbol from '../getWeatherMarkerSymbol';
import { TTimeFreq } from '../../../../../tools/API/interfaces';
import { IArgs as IDataAdapterArgs } from '../../api/dataAdapter';

interface IArgs extends Omit<IDataAdapterArgs, 'events' | 'eventType'> {
    /** Кол-во серий на графике (до добавления погоды) */
    numberOfSeriesBefore: number;
}

const generateWeatherSeries = (args: IArgs): Array<TDynamicsSeries> => {
    const weatherSeries: Array<TDynamicsSeries> = [];

    const getMetricFromStore = (metric: string): IMetric | undefined => {
        return args.allMetrics.find((item) => item.id === metric);
    };

    const reportingObjectsById = keyBy(args.reportingObjects, 'id');

    const weatherMetricsObject: {
        [metricId: string]: {
            [objectId: string]: IMetricResponseItem & { itemsByTime: { [time: string]: IResponseItem } };
        };
    } =
        args.weatherResponse?.reduce((acc, value) => {
            value.forEach((metricResponseItem) => {
                const metric = metricResponseItem.context.metric;

                acc[metric] = {
                    ...acc[metric],
                    [metricResponseItem.context.data_objects[0].id]: {
                        ...metricResponseItem,
                        itemsByTime: keyBy(metricResponseItem.items, 'time'),
                    },
                };
            });

            return acc;
        }, {}) || {};

    const notHiddenReportingObject = args.reportingObjects.find((reportingObject) =>
        args.hiddenSeries.every((hiddenSeries) => !hiddenSeries.includes(reportingObject.id.toString())),
    );

    args.weatherResponse?.forEach((metricResponse) => {
        metricResponse.forEach((metricResponseItem, metricResponseItemIndex) => {
            const metric = metricResponseItem.context.metric;
            const metricFromStore = args.allMetrics.find((item) => item.id === metric);
            const objectId = metricResponseItem.context.data_objects[0]?.id as number | undefined;
            if (
                metric === WEATHER_METRICS_BY_NAME.temperature &&
                (args.chartType === 'objects'
                    ? objectId === notHiddenReportingObject?.id
                    : metricResponseItemIndex === 0)
            ) {
                const reportingObject = reportingObjectsById[objectId || ''] as IReportingObject | undefined;
                const getWeatherValue = (metric: string, time: string): number | null => {
                    return weatherMetricsObject[metric]?.[objectId || '']?.itemsByTime[time]?.value;
                };

                const data: IExtendedChartPoint[] = metricResponseItem.items.map((element) => {
                    const weatherData: IWeatherData = {
                        reportingObject,
                        weather: {
                            temperature: {
                                metric: getMetricFromStore(WEATHER_METRICS_BY_NAME.temperature),
                                metricId: WEATHER_METRICS_BY_NAME.temperature,
                                value: getWeatherValue(WEATHER_METRICS_BY_NAME.temperature, element.time),
                                dateTime: element.time,
                                color: 'var(--color-accent)',
                            },
                            precipitation: {
                                metric: getMetricFromStore(WEATHER_METRICS_BY_NAME.precipitation),
                                metricId: WEATHER_METRICS_BY_NAME.precipitation,
                                value: getWeatherValue(WEATHER_METRICS_BY_NAME.precipitation, element.time),
                                color: theme.seriesColors[2],
                                dateTime: element.time,
                            },
                            pressure: {
                                metric: getMetricFromStore(WEATHER_METRICS_BY_NAME.pressure),
                                metricId: WEATHER_METRICS_BY_NAME.pressure,
                                value: getWeatherValue(WEATHER_METRICS_BY_NAME.pressure, element.time),
                                dateTime: element.time,
                            },
                            cloudCover: {
                                metric: getMetricFromStore(WEATHER_METRICS_BY_NAME.cloudCover),
                                metricId: WEATHER_METRICS_BY_NAME.cloudCover,
                                value: getWeatherValue(WEATHER_METRICS_BY_NAME.cloudCover, element.time),
                                dateTime: element.time,
                            },
                        },
                    };

                    const weatherSymbol = getWeatherMarkerSymbol({
                        cloudCover: getWeatherValue(WEATHER_METRICS_BY_NAME.cloudCover, element.time),
                        precipitation: getWeatherValue(WEATHER_METRICS_BY_NAME.precipitation, element.time),
                        temperature: getWeatherValue(WEATHER_METRICS_BY_NAME.temperature, element.time),
                        pressure: getWeatherValue(WEATHER_METRICS_BY_NAME.pressure, element.time),
                        dateTime: element.time,
                        detail: args.detail?.id as TTimeFreq | undefined,
                    });
                    return {
                        y: 1,
                        x: DateTime.fromISO(element.time).toMillis(),
                        precision: metricFromStore?.round_decimal_places,
                        showUnits: true,
                        showTooltipBody: false,
                        units: args.t(metricFromStore?.units || ''),
                        title: args.t(metricFromStore?.text || ''),
                        name: args.t(metricFromStore?.text || ''),
                        weatherData,
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '8px',
                                color: '#666',
                            },

                            overflow: 'allow',
                            crop: false,
                            padding: 14,

                            format: isNumber(element.value) ? element.value.toFixed(0) : '',
                        },
                        marker:
                            weatherSymbol !== 'circle'
                                ? {
                                      symbol: weatherSymbol,
                                      enabled: true,
                                      width: 40,
                                      height: 40,
                                  }
                                : {
                                      enabled: false,
                                  },
                    };
                });

                const newSeries: TDynamicsSeries = {
                    data,
                    type: 'spline',
                    showInLegend: false,
                    yAxis: args.numberOfSeriesBefore + weatherSeries.length,
                    yAxisData: { visible: false, min: 1, max: 1, top: 10 },
                    color: 'var(--color-accent)',
                    lineWidth: 0,
                    name: args.t('Weather'),
                    zIndex: 10,

                    states: {
                        hover: {
                            lineWidth: 0.01,
                            enabled: false,
                        },
                    },
                };

                weatherSeries.push(newSeries);
            }
            if (
                metric === WEATHER_METRICS_BY_NAME.precipitation &&
                (args.chartType === 'objects'
                    ? objectId === notHiddenReportingObject?.id
                    : metricResponseItemIndex === 0)
            ) {
                const data: IExtendedChartPoint[] = metricResponseItem.items.map((element) => {
                    return {
                        y: isNumber(element.value)
                            ? element.value < 1
                                ? Number(element.value.toFixed(2))
                                : Math.round(element.value)
                            : element.value,
                        x: DateTime.fromISO(element.time).toMillis(),
                        precision: metricFromStore?.round_decimal_places,
                        showTooltipBody: false,
                        title: args.t('Weather'),
                    };
                });

                const max = maxBy(data, (item) => item.y)?.y;

                const newSeries: TDynamicsSeries = {
                    data,
                    type: 'column',
                    yAxis: args.numberOfSeriesBefore + weatherSeries.length,
                    showInLegend: false,
                    color: theme.seriesColors[2],
                    yAxisData: { softMax: max ? max * 5 : 2, visible: false },
                    dataLabels: {
                        enabled: true,
                        filter: {
                            operator: '>',
                            property: 'y',
                            value: 0,
                        },

                        style: {
                            fontSize: '8px',
                            color: '#666',
                        },
                    },
                };

                weatherSeries.push(newSeries);
            }
        });
    });

    return weatherSeries;
};

export default generateWeatherSeries;
