import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { stringDate } from '../../../../../tools/Strings/stringDate';
import { Container } from '../../../../../components/Wrappers/WrapperContainer/styles';
import { IEvent } from '../../../EventsMap/widgets/EventsMapWidget/interfaces';
import {
    Comment,
    ContentWrapper,
    EventName,
    EventNameWrapper,
    EventPeriod,
    EventTypeImage,
    MainContentWrapper,
    OptionsWrapper,
    ReportingObjects,
    TextInfoWrapper,
    Wrapper,
} from './styles';
import { generalReducerValues } from '../../../../../General.reducer';
import { useTranslation } from 'react-i18next';
import { objectTypesMap } from './constants/constants';
import { IReportingObject } from '../../../../../General.interfaces';
import { SelectsWrapper } from '../../../../../components/Wrappers/SelectsWrapper/styles';
import ComparePeriodSelect from '../../../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import { PresetsWrapper } from '../../../../../components/Wrappers/PresetsWrapper/styles';
import Preset from '../../../../../components/Preset/Preset';
import MetricsSelect from '../../../../../components/Selects/MetricsSelect/MetricsSelect';
import { EVENT_TYPES_IMAGES_MAP } from '../../../../../constants/eventTypesImagesMap';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import Button from '../../../../../components/Button/Button';

interface IProps {
    /** Текущее событие */
    event: IEvent;
    /** Функция для открытия / закрытия окна редактирования события */
    toggleEditEventModalStatus: () => void;
}

/**
 * Компонент для отображения шапки модуля События
 */
const Header: FC<IProps> = ({ event, toggleEditEventModalStatus }) => {
    const {
        lang,
        currentModuleID,
        user,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const { t } = useTranslation();

    const reportingObjectsByType = useMemo(() => {
        return event.data_objects_ids.reduce((acc, id) => {
            const reportingObject = reportingObjectsById[id];
            if (reportingObject) {
                if (!acc[reportingObject.object_type]) {
                    acc[reportingObject.object_type] = [reportingObject];
                } else {
                    acc[reportingObject.object_type].push(reportingObject);
                }
            }
            return acc;
        }, {});
    }, [reportingObjectsById, event]);

    return (
        <Wrapper hex={event.event_type_color}>
            <Container style={{ backgroundColor: 'transparent' }}>
                <ContentWrapper>
                    <EventTypeImage
                        src={EVENT_TYPES_IMAGES_MAP[event.event_type_name] || EVENT_TYPES_IMAGES_MAP.other}
                        alt={'Event type image'}
                    />
                    <MainContentWrapper>
                        <TextInfoWrapper>
                            <EventNameWrapper>
                                <EventName>{event.name}</EventName>
                                {/* <EventTypeColor color={event.event_type_color} /> */}
                            </EventNameWrapper>
                            <EventPeriod>
                                {stringDate(
                                    { dateFrom: event.date_from, dateTo: event.date_to },
                                    lang,
                                    '',
                                    'd MMMM yyyy HH:mm',
                                )}
                            </EventPeriod>
                            {Object.keys(reportingObjectsByType).map((key) => {
                                const text = reportingObjectsByType[key].reduce(
                                    (acc: string, value: IReportingObject, index: number) => {
                                        if (index + 1 === reportingObjectsByType[key].length) {
                                            return (acc += value.name);
                                        } else {
                                            return (acc += `${value.name}, `);
                                        }
                                    },
                                    '',
                                );
                                return (
                                    <ReportingObjects key={key}>
                                        <span>{t(objectTypesMap[key] || key)}:</span>
                                        {text}
                                    </ReportingObjects>
                                );
                            })}
                            {event.comment && (
                                <Comment>
                                    <span>{t('Comment')}:</span>
                                    {event.comment}
                                </Comment>
                            )}
                        </TextInfoWrapper>

                        <OptionsWrapper>
                            <SelectsWrapper>
                                <ComparePeriodSelect
                                    localCurrentOptions={localCurrentOptions}
                                    changeOptions={(args) => dispatch(changeOptions(args))}
                                />
                                <MetricsSelect
                                    localCurrentOptions={localCurrentOptions}
                                    changeOptions={(args) => dispatch(changeOptions(args))}
                                />
                                {user?.permissions.includes(
                                    'app/client | modules/configuration/events | feature/edit',
                                ) && (
                                    <Button
                                        appearance="primary"
                                        onClick={toggleEditEventModalStatus}
                                        text={t('Change event')}
                                    />
                                )}
                            </SelectsWrapper>
                            <PresetsWrapper>
                                <Preset currentOptionsKeys={['selectedMetrics', 'comparePeriods']} />
                            </PresetsWrapper>
                        </OptionsWrapper>
                    </MainContentWrapper>
                </ContentWrapper>
            </Container>
        </Wrapper>
    );
};

export default Header;
