// Если понадобится динамически переопределить CSS переменные - то делаем так
// document.documentElement.style.setProperty('--color-primary', 'red')
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { registerAllModules } from 'handsontable/registry';

import './index.css';

import 'react-datasheet/lib/react-datasheet.css';
import 'handsontable/dist/handsontable.full.min.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/mousewheel';

import './style.scss';

// DONT't split these lines
initializeIcons();
registerAllModules();
const bodyStyles = getComputedStyle(document.body);

export const screenBreakpointsFromComputedStyle = {
    breakpoints: {
        mobile: bodyStyles.getPropertyValue('--screen-mobile'),
        md: bodyStyles.getPropertyValue('--screen-md'),
        tablet: bodyStyles.getPropertyValue('--screen-tablet'),
        xl: bodyStyles.getPropertyValue('--screen-xl'),
        xxl: bodyStyles.getPropertyValue('--screen-xxl'),
    },
};

export const getTheme = () => ({
    ...screenBreakpointsFromComputedStyle,
});
