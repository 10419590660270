import { forwardRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IProps } from './interfaces';
import { Wrapper } from './styles/styles';
import useChartProps from './hooks/useChartProps';

const Column = forwardRef<HighchartsReact.RefObject, IProps>((props, ref) => {
    const chartProps = useChartProps(props);

    return (
        <Wrapper customHeight={props.customChartDimensions?.height}>
            <HighchartsReact highcharts={Highcharts} ref={ref} {...chartProps} />
        </Wrapper>
    );
});

export default Column;
