import styled from 'styled-components/macro';

export const ModalWrapper = styled.div<{ showPreview: boolean }>`
    /* height: 100vh; */
    /* width: ${(p) => (p.showPreview ? '100wv' : 'auto')}; */
    width: 90vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const Footer = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;
    padding-top: var(--space-xs);
`;

export const SelectsWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: flex-end;
    gap: var(--space-xxxs);
`;
