import { useSelector } from 'react-redux';
import { useTranslation as useI18Translation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ZONES_WORD } from '../constants/constants';
import { IReportingObject } from '../General.interfaces';
import { generalReducerValues } from '../General.reducer';

/**
 * Кастомный хук для перевода.
 */

export interface IGetObjectNameArgs {
    reportingObject: { object_type?: string; type?: string; block_type?: string; name: string };
    t: TFunction;
    showType?: boolean;
    addFloor?: boolean;
}
export interface IGetObjNameByIdArgs {
    id: number;
    showType?: boolean;
    addFloor?: boolean;
}

export const getObjectName = ({ reportingObject, t, showType = false, addFloor }: IGetObjectNameArgs) => {
    let section = '';

    const standartNames = ['Price Segment', 'Category', 'Subcategory'];
    const objectType = reportingObject?.object_type ?? reportingObject?.type ?? '';

    if (!showType && addFloor) {
        if (objectType.includes('floor')) {
            section = `${t('Floor')}: `;
        }
    }

    if (showType) {
        if (objectType.includes(ZONES_WORD)) {
            section = `${t('Zone')}: `;
        } else if (reportingObject?.block_type === 'project_category') {
            if (standartNames.includes(objectType)) {
                section = `${t('Category')}: ${t(objectType, { ns: 'translationCategories' })}: `;
            } else {
                section = `${t('Category')}: ${objectType}: `;
            }
        } else {
            const word = `${t(objectType)}: `;
            section = word.charAt(0).toUpperCase() + word.slice(1);
        }
    }

    if (reportingObject?.block_type === 'project_category') {
        if (standartNames.includes(objectType)) {
            return `${section}${t(reportingObject?.name, { ns: 'translationCategories' })}`;
        } else {
            return `${section}${reportingObject?.name}`;
        }
    } else {
        return `${section}${reportingObject?.name}`;
    }
};

const useObjectTranslation = (nameSpaces: string | string[] = ['translation', 'translationCategories']) => {
    const { t } = useI18Translation(nameSpaces);

    const {
        src: { standardCategories },
    } = useSelector(generalReducerValues);

    return {
        getObjName: (obj: IReportingObject, showType: boolean = false, addFloor?: boolean) =>
            getObjectName({ reportingObject: obj, t, showType, addFloor }),
    };
};

export const useObjectNameById = (nameSpaces: string | string[] = ['translation', 'translationCategories']) => {
    const { t } = useI18Translation(nameSpaces);

    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    return {
        getObjNameById: ({ id, showType, addFloor }: IGetObjNameByIdArgs) => {
            const reportingObject = reportingObjectsById[id];
            return getObjectName({ reportingObject, t, showType, addFloor });
        },
    };
};

export default useObjectTranslation;
