import React, { useMemo, useState } from 'react';
import { TreeView } from '@mui/lab';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BothIconsWrapper, IconWrapper, Icons, ItemWrapper, TreeWrapper } from './styles';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { Configuration_Categories_Widget_Reducer_Values } from '../../reducer';

import StyledTreeItem from './components/StyledTreeItem';
import { CloseSquare, MinusSquare, PlusSquare } from './components/Icons';
import ModalAddSubCategory from './components/ModalAddSubCategory';
import ModalDeleteSubCategory from './components/ModalDeleteSubCategory';
import Modals from '../../../../../../../components/Modals/Modals';
import LoadingBox from '../../../../../../../components/LoadingBox/LoadingBox';
import { Dismiss20Regular, Add20Regular, EditRegular } from '@fluentui/react-icons';
import Button from '../../../../../../../components/Button/Button';
import Tooltip from '../../../../../../../components/Tooltip/Tooltip';
import ModalEditCategory from './components/ModalEditCategory';
import { useSendSimpleAnalytics } from '../../../../../../../hooks/useAnalytics';

const Tree: React.FC = () => {
    const {
        currentModuleID,
        src: { projectCategories, standardCategories },
    } = useSelector(generalReducerValues);

    const { showLoader } = useSelector(Configuration_Categories_Widget_Reducer_Values);
    const sendAnalytics = useSendSimpleAnalytics();
    const { t } = useTranslation();
    const catT = useTranslation('translationCategories').t;

    const [addSubCategoryModalStatus, setAddSubCategoryModalStatus] = useState<{
        show: boolean;
        parentId?: number | null;
        mode?: string;
    }>({
        show: false,
    });
    const [deleteSubCategoryModalStatus, setDeleteSubCategoryModalStatus] = useState<{
        show: boolean;
        id?: number;
        mode?: string;
    }>({
        show: false,
    });
    const [editCategoryModalStatus, setEditCategoryModalStatus] = useState<{
        show: boolean;
        id?: number;
    }>({
        show: false,
    });

    const handleModalClose = () => {
        sendAnalytics(`categories_configuration`, 'reachGoal', `${currentModuleID}:ProjectCategories`);
        setAddSubCategoryModalStatus({ show: false });
        setDeleteSubCategoryModalStatus({ show: false });
        setEditCategoryModalStatus({ show: false });
    };

    const tree = useMemo(() => {
        const onAddSubCategoryClick = (parentId: number, mode: string) => () => {
            setAddSubCategoryModalStatus({ show: true, parentId, mode });
        };

        const onDeleteSubCategoryClick = (id: number, mode: string) => () => {
            setDeleteSubCategoryModalStatus({ show: true, id, mode });
        };

        const onEditCategoryClick = (id: number) => () => {
            setEditCategoryModalStatus({ show: true, id });
        };

        const data = projectCategories
            ?.filter((item) => !item.parent_id)
            .map((item, j) => {
                const parentId = item.id;

                const isStandardCategory = standardCategories.some((cat) => cat.name === item.object_name);

                const subTree = projectCategories
                    ?.filter((item) => item.parent_id === parentId)
                    .map((item, i) => {
                        return (
                            <ItemWrapper key={`${item.id}--${i}--${j}`}>
                                <StyledTreeItem
                                    nodeId={String(item.id)}
                                    label={isStandardCategory ? catT(item.object_name) : item.object_name}
                                    key={`${item.id}--${i}--${j}`}
                                />
                                {!isStandardCategory && (
                                    <Icons>
                                        <IconWrapper onClick={onEditCategoryClick(item.id)}>
                                            <EditRegular />
                                        </IconWrapper>
                                        <IconWrapper onClick={onDeleteSubCategoryClick(item.id, 'subcategory')}>
                                            <Dismiss20Regular primaryFill="var(--color-accent-light)" />
                                        </IconWrapper>
                                    </Icons>
                                )}
                            </ItemWrapper>
                        );
                    });

                return (
                    <ItemWrapper key={`${item.id}----${j}`}>
                        <StyledTreeItem nodeId={String(item.id)} label={catT(item.object_name)}>
                            {subTree}
                        </StyledTreeItem>
                        <BothIconsWrapper>
                            {!isStandardCategory && (
                                <IconWrapper onClick={onAddSubCategoryClick(parentId, 'subcategory')}>
                                    <Tooltip content={t('New subcategory')}>
                                        <Add20Regular primaryFill="var(--color-primary)" />
                                    </Tooltip>
                                </IconWrapper>
                            )}
                            <Icons>
                                {!isStandardCategory && (
                                    <IconWrapper onClick={onEditCategoryClick(item.id)}>
                                        <EditRegular />
                                    </IconWrapper>
                                )}
                                <IconWrapper onClick={onDeleteSubCategoryClick(item.id, 'category')}>
                                    <Tooltip content={t('Delete category')}>
                                        <Dismiss20Regular primaryFill="var(--color-accent-light)" />
                                    </Tooltip>
                                </IconWrapper>
                            </Icons>
                        </BothIconsWrapper>
                    </ItemWrapper>
                );
            });

        return (
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                sx={{
                    height: 'auto',
                    maxHeight: '100%',
                    maxWidth: '400px',
                    flexGrow: 1,
                    overflowY: 'auto',
                    paddingTop: '20px',
                    paddingBottom: '30px',
                }}
            >
                {data}
            </TreeView>
        );
    }, [projectCategories, t, standardCategories]);

    return !showLoader ? (
        <TreeWrapper>
            <Button
                appearance="primary"
                text={t('Add new category')}
                onClick={() => setAddSubCategoryModalStatus({ show: true, parentId: null, mode: 'category' })}
            ></Button>

            {tree}

            <Modals
                title={addSubCategoryModalStatus.mode === 'subcategory' ? t('New subcategory') : t('New category')}
                closeModal={handleModalClose}
                modalStatus={addSubCategoryModalStatus}
                topColor={'var(--color-primary)'}
            >
                <ModalAddSubCategory modalStatus={addSubCategoryModalStatus} handleOKClick={handleModalClose} />
            </Modals>

            <Modals title={t('Edit category')} closeModal={handleModalClose} modalStatus={editCategoryModalStatus}>
                <ModalEditCategory modalStatus={editCategoryModalStatus} handleOKClick={handleModalClose} />
            </Modals>

            <Modals
                title={
                    deleteSubCategoryModalStatus.mode === 'subcategory'
                        ? t('Delete subcategory?')
                        : t('Delete category?')
                }
                closeModal={handleModalClose}
                modalStatus={deleteSubCategoryModalStatus}
                topColor={'var(--color-accent)'}
            >
                <ModalDeleteSubCategory modalStatus={deleteSubCategoryModalStatus} handleOKClick={handleModalClose} />
            </Modals>
        </TreeWrapper>
    ) : (
        <LoadingBox height={400} text={t('Loading...')} />
    );
};

export default Tree;
