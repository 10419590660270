import { differenceWith, fromPairs, isEqual, toPairs } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TCurrentOptions } from '../components/UserSettings/interfaces';
import { generalReducerValues } from '../General.reducer';
import { usePrevious } from './usePrevious';
import { useWidgetCurrentOptions } from './useWidgetCurrentOptions';
import { Dashboards_Reducer_Values } from 'src/Dashboards.reducer';
import { StringUnitLength } from 'luxon';
import { IDashboard } from 'src/components/DashboardsCommon/interfaces';

const yandexID = 91340986;
const googleID = 'G-P6WQCRVTB0';

export const useSendSimpleAnalytics = () => {
    const {
        currentModuleID,
        sendYaAnalytics,
        sendGooAnalytics,
        selectedLocationId,
        cfg: { locationsById },
        user,
    } = useSelector(generalReducerValues);

    if (sendYaAnalytics) {
        return (value: string, type: string = 'reachGoal', modul?: string) => {
            const moduleID = modul ? modul : currentModuleID;
            return yaAnalyticsFoo(type, value, moduleID, true);
        };
    } else if (sendGooAnalytics) {
        const pl_name = locationsById?.[selectedLocationId || '']?.name;
        return (value: string, type: string = 'reachGoal', modul?: string) => {
            const moduleID = modul ? modul : currentModuleID;
            return gooAnalyticsFoo(type, value, moduleID, {
                pl_id: selectedLocationId,
                user_id: user?.id,
                pl_name,
            });
        };
    } else {
        return () => {};
    }
};

const gooAnalyticsFoo = (
    type: string,
    value: string,
    currentModuleID: string | null = '',
    additionalOptions?: { [x: string]: unknown },
) => {
    const targetID = `${currentModuleID} >> ${value}`;
    console.log(`goo >> ${targetID}`);
    window['gtag']('event', 'click', { event_category: targetID, ...additionalOptions });
};

const yaAnalyticsFoo = (type: string, value: string, currentModuleID: string | null = '', sendYaAnalytics: boolean) => {
    const targetID = `${currentModuleID} >> ${value}`;
    console.log(`ya >> ${targetID}`);
    sendYaAnalytics && window['ym'](yandexID, type, targetID);
};

const objectDiff = (a: any, b: any) => fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));

const tabooList = ['compareDateRanges'];

/**
 * Кастомный хук для отправки яндекс аналитики
 */
export const useAnalytics = () => {
    const {
        currentModuleID,
        selectedLocationId,
        user,
        initialDataReceived,
        sendYaAnalytics,
        sendGooAnalytics,
        cfg: { locationsById },
        allMetrics,
    } = useSelector(generalReducerValues);
    const { userDashboards, selectedKey } = useSelector(Dashboards_Reducer_Values);
    const sendAnalytics = useSendSimpleAnalytics();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID || '');
    const previousValue: TCurrentOptions = usePrevious(localCurrentOptions) as unknown as TCurrentOptions;

    const currentDashboard = userDashboards.filter(
        (item) => item.module_id === currentModuleID && item.dashboard_key === selectedKey[currentModuleID],
    )[0];

    const previousDashboard: IDashboard = usePrevious(currentDashboard) as unknown as IDashboard;

    const getOptions = (dashboard: IDashboard) => {
        return dashboard?.widgets
            .map((item) => item.options)
            .reduce((acc, item) => {
                const items = Object.entries(item);
                return [...acc, ...items];
            }, []);
    };

    /** Отправка метрик при смене модуля */
    useEffect(() => {
        if (!initialDataReceived) return;
        sendAnalytics('jump_to_module');
        const timeout = setTimeout(() => {
            sendAnalytics('1_min_presence_inside_module');
        }, 60000);
        return () => {
            clearTimeout(timeout);
        };
    }, [currentModuleID, initialDataReceived]);

    /** Отправка метрик при изменении глобальных селектов */
    useEffect(() => {
        if (!initialDataReceived) return;

        if (previousValue?.currentModuleID === localCurrentOptions?.currentModuleID) {
            const diff = objectDiff(localCurrentOptions, previousValue);
            // console.log(diff);

            Object.keys(diff).forEach((key) => {
                if (!tabooList.includes(key) && previousValue?.[key] && localCurrentOptions?.[key]) {
                    if (key === 'selectedMetrics') {
                        const arr = diff[key] as string[];
                        const metricGroups = Array.from(
                            new Set(
                                arr?.map((item) => {
                                    return allMetrics?.find((metric) => metric.id === item)?.section;
                                }),
                            ),
                        );
                        metricGroups.forEach((group) => {
                            sendAnalytics(`${'groupOfMetrics'}_${group}_selected`);
                        });
                    } else {
                        sendAnalytics(`${key}_change`);
                    }
                }
            });
        }
    }, [localCurrentOptions, initialDataReceived, previousValue, currentModuleID]);

    /** Отправка метрик при изменении селектов в микровиджетах */
    useEffect(() => {
        if (!initialDataReceived) return;

        if (
            previousValue?.currentModuleID === localCurrentOptions?.currentModuleID &&
            previousDashboard?.module_id === currentModuleID &&
            previousDashboard?.dashboard_key === selectedKey[currentModuleID]
        ) {
            const diff = objectDiff(getOptions(currentDashboard), getOptions(previousDashboard));

            Object.values(diff).forEach((value: [string, any]) => {
                if (!tabooList.includes(value[0])) {
                    if (value[0] === 'metrics') {
                        const arr = value[1] as string[];
                        const metricGroups = Array.from(
                            new Set(
                                arr?.map((item) => {
                                    return allMetrics?.find((metric) => metric.id === item)?.section;
                                }),
                            ),
                        );
                        metricGroups.forEach((group) => {
                            sendAnalytics(`${'groupOfMetrics'}_${group}_selected`);
                        });
                    } else {
                        sendAnalytics(`${value[0]}_change`);
                    }
                }
            });
        }
    }, [
        allMetrics,
        currentDashboard,
        currentModuleID,
        initialDataReceived,
        localCurrentOptions?.currentModuleID,
        previousDashboard,
        previousValue?.currentModuleID,
        selectedKey,
        sendAnalytics,
    ]);

    /** Подключение яндекс метрик с начальными параметрами */
    useEffect(() => {
        if (selectedLocationId && initialDataReceived) {
            const pl_name = locationsById?.[selectedLocationId]?.name;
            console.log('========================================');
            console.log('user_id:', user?.id);
            console.log('username:', user?.username);
            console.log('pl_id:', selectedLocationId);
            console.log('pl_name:', pl_name);
            console.log('========================================');

            if (sendYaAnalytics) {
                window['ym'](yandexID, 'params', {
                    pl_name,
                    pl_id: selectedLocationId,
                    user_id: user?.id,
                    username: user?.username,
                });
                window['ym'](yandexID, 'userParams', {
                    user_id: user?.id,
                    username: user?.username,
                });
            }

            if (sendGooAnalytics) {
                window['gtag']('config', googleID, {
                    user_id: user?.id,
                });
                window['gtag']('event', 'pl_change', {
                    pl_id: selectedLocationId,
                    pl_name,
                });
            }
        }
    }, [selectedLocationId, initialDataReceived, sendYaAnalytics, sendGooAnalytics]);
};
