import { ChangeEvent, useRef } from 'react';
import Button from '../../../../../../../components/Button/Button';
import { ArrowUploadFilled } from '@fluentui/react-icons';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import Papa from 'papaparse';
import { useHandleDataFromFile } from '../../../../tools/useHandleDataFromFile';

const UploadFromFileButton = () => {
    const ref = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const { handleDataFromFile } = useHandleDataFromFile();

    const chooseFile = () => {
        ref?.current?.click();
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            Papa.parse(e.target.files[0], {
                complete: function (results) {
                    if (results.data) {
                        handleDataFromFile(results.data as string[][]);
                    }
                },
            });
        }
    };

    return (
        <>
            <input ref={ref} type="file" accept={'.csv'} onChange={onFileChange} style={{ display: 'none' }} />
            <Button
                text={t('Upload from file')}
                onClick={chooseFile}
                appearance="primary"
                icon={<ArrowUploadFilled fontSize={24} />}
            />
        </>
    );
};

export default UploadFromFileButton;
