/**
 * Добавляет элемент в массив, если его нет, и удаляет из массива, если он есть.
 * @param arr
 * @param value
 * @returns
 */
export const toggleArray = <T>(arr: T[], value: T): T[] => {
    if (arr.includes(value)) {
        return arr.filter((item) => item !== value);
    } else {
        return [...arr, value];
    }
};
