import React, { useState } from 'react';
import { ChevronDown20Regular, Info24Regular } from '@fluentui/react-icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isTabletInboundWidth } from 'src/theme';

import ContextualMenu from '../ContextualMenu/ContextualMenu';
import LocationsSelect from '../Selects/LocationsSelect/LocationsSelect';
import ModuleTitle from '../Wrappers/ModuleTitle/ModuleTitle';
import { changeLang, generalReducerValues } from '../../General.reducer';
import { changeOptions, userSettingsReducerValues } from '../UserSettings/reducer';
import { TLang } from '../../General.interfaces';
import { TMode } from '../Selects/Select/interfaces';
import Stack from '../Stack/Stack';
import Modals from '../Modals/Modals';
import CabinetPreferences from '../CabinetPreferences/CabinetPreferences';
import { useWidgetCurrentOptions } from '../../hooks/useWidgetCurrentOptions';
import { useAppDispatch } from '../../hooks/useSettings';
import Tooltip from '../Tooltip/Tooltip';

import CabinetPreferencesMenu from './components/CabinetPreferencesMenu/CabinetPreferencesMenu';
import LangSelectMenu from './components/LangSelectMenu/LangContextualMenu';
import ControlsAreaWrapper from './components/ControlsAreaWrapper/ControlsAreaWrapper';
import Burger from './components/Burger/Burger';
import {
    ControlsWrapper,
    DivBoxWrapperLogoutSC,
    HeaderCentralArea,
    HeaderWrapper,
    LogoutWrapper,
    TitleWrapper,
    useInfoIconStyles,
} from './styles';
import { IProps } from './interfaces';
import LogoutContextualMenuBody from './components/LogoutContextualMenuBody/LogoutContextualMenuBody';
import HeaderLogo from './components/HeaderLogo/HeaderLogo';
/**
 * Компонент для отображения шапки приложения
 */
const Header: React.FC<IProps> = ({ marginBottom, sidebarOpen, toggleSidebarOpen }) => {
    const {
        locations = [],
        lang,
        user,
        currentModuleID,
        modulesConfig,
        mainAreaSize: { width },
        appCoreData,
        domain,
        urlsByServices,
    } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const dispatch = useAppDispatch();

    const [modalStatus, setModalStatus] = useState({ show: false });
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const { settings } = useSelector(userSettingsReducerValues);

    const { t } = useTranslation();
    const t1 = useTranslation('translationSidebar').t;
    const tTooltips = useTranslation('helpTooltips').t;

    const infoIconStyles = useInfoIconStyles();

    let mode: TMode = 'single';
    let name: 'pl' | 'pls' = 'pl';
    let showClearAll = false;

    const moduleNow = modulesConfig?.find((item) => item.module_id === currentModuleID);

    if (moduleNow?.store_method['global'].includes('pls')) {
        name = 'pls';
        mode = 'multi';
        showClearAll = true;
    }

    const options = locations.map((location) => {
        return { id: String(location.id), text: location.name };
    });

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const onChangeLang = (lang: TLang) => {
        dispatch(changeLang(lang));
    };

    const onLogout = () => {
        localStorage.removeItem('xtoken');
        urlsByServices?.['core/admin-service'].LOGOUT_URL &&
            window.location.replace(
                `${urlsByServices?.['core/admin-service'].LOGOUT_URL}?next=/sso-login?domain=https://${domain}&next=/sso/ajax-token?next=` +
                    window.location.href,
            );
    };

    const outsideSelected = options?.filter((item) => {
        return settings?.['global']?.[name]?.map((item: any) => String(item.id)).includes(String(item.id));
    });

    const moduleName = t1(moduleNow?.module_name?.split(':')[1] || '');

    const locationText =
        outsideSelected && name === 'pl'
            ? `${outsideSelected[0]?.text}`
            : // ? `${outsideSelected[0]?.text} (${timeZone} ${shift}UTC)`
            outsideSelected && name === 'pls'
            ? `${outsideSelected.map((item) => item.text).join(', ')}`
            : t('Select location');

    const isTablet = isTabletInboundWidth(width);

    const toggleModal = (show: boolean) => () => {
        setModalStatus({ show });
    };

    const locationSelect = (
        <LocationsSelect
            text={t('Select location')}
            outsideText={locationText}
            showClearAll={showClearAll}
            iconType="caret-down"
            dropdownAlign="right"
            dropdownWidth="lg"
            options={options}
            labelText=""
            mode={mode}
            name={name}
            showFilter
            localCurrentOptions={localCurrentOptions}
            changeOptions={(args) => dispatch(changeOptions(args))}
        />
    );

    return locations.length ? (
        <HeaderWrapper marginBottom={marginBottom}>
            <HeaderLogo />
            <Modals
                topColor="fff"
                modalStatus={modalStatus}
                title={t('Cabinet preferences')}
                closeModal={toggleModal(false)}
            >
                <CabinetPreferences closeModal={toggleModal(false)} />
            </Modals>
            <HeaderCentralArea>
                <Burger toggleSidebarOpen={toggleSidebarOpen} sidebarOpen={sidebarOpen} />
                {isTablet && (
                    <>
                        {locationSelect}
                        <TitleWrapper>
                            <Stack alignItems="center" gap={5}>
                                <ModuleTitle>{moduleName}</ModuleTitle>
                                {moduleNow?.info && (
                                    <Tooltip
                                        content={tTooltips(moduleNow.info)}
                                        contentWrapperStyles={{ maxWidth: '350px' }}
                                    >
                                        <Info24Regular className={infoIconStyles.icon} />
                                    </Tooltip>
                                )}
                            </Stack>
                        </TitleWrapper>
                    </>
                )}
                {!isTablet && locationSelect}
                <ControlsAreaWrapper>
                    <ControlsWrapper>
                        <CabinetPreferencesMenu toggleModal={toggleModal} />
                        <LangSelectMenu appCoreData={appCoreData} lang={lang} handleClickItem={onChangeLang} />

                        {user && (
                            <ContextualMenu
                                contextualMenuBody={<LogoutContextualMenuBody onLogout={onLogout} />}
                                isContextualMenuOpen={isContextualMenuOpen}
                                closeContextualMenu={closeContextualMenu}
                                positioning="end"
                            >
                                <LogoutWrapper onClick={toggleContextualMenu} data-test-id={`LogoutWrapper`}>
                                    <DivBoxWrapperLogoutSC>
                                        <div>{user.username}</div>
                                        <ChevronDown20Regular />
                                    </DivBoxWrapperLogoutSC>
                                </LogoutWrapper>
                            </ContextualMenu>
                        )}
                    </ControlsWrapper>
                </ControlsAreaWrapper>
            </HeaderCentralArea>
        </HeaderWrapper>
    ) : null;
};

export default Header;
