import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';
import { TabListItemIds } from '../../enums';

const initialState: IReducerState = {
    selectedTab: TabListItemIds.PaymentType,
};

export const Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer = createSlice({
    name: 'Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer',
    initialState,

    reducers: {
        /**
         * Сохранение выбранного таба
         */
        storeSelectedTab: (state, action: PayloadAction<TabListItemIds>) => {
            state.selectedTab = action.payload;
        },
    },
});

export const { storeSelectedTab } = Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer.actions;

export const Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values = (state: RootState) =>
    state.Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer;

export default Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer.reducer;
