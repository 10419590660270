import { cloneDeep, isNumber, isUndefined } from 'lodash';
import { ReactNode } from 'react';
import { IPassway2DataObjRow, IReportingObject } from '../../../../General.interfaces';
import { filterValidDateRanges } from '../../../../tools/filterValidDateRanges';
import {
    IDataObjectsPasswayTypeFilterState,
    IDataObjectsSelectFilter,
    IPasswayFilterType,
    TDataObjectsFilterTypes,
} from '../../interfaces';
import { DataObjectsFilter } from '../DataObjectsFilter';
import Filter from './components/Filter/Filter';
import { passwayTypeFilterState } from './constants/constants';
import { IFilterDataObjectsArgs, IGetComponentProps, IGetFilterDataArgs } from './interfaces';

/**
 * Класс, реализующий логику работы фильтра типа прохода
 */
export class DataObjectsPasswayType implements DataObjectsFilter {
    name: TDataObjectsFilterTypes = 'data_objects_passway_type';
    active = false;

    getFilterData(args: IGetFilterDataArgs): IDataObjectsSelectFilter {
        const { rawData } = args;

        const sortByTenantName: boolean = isUndefined(rawData?.filter_state?.['sort_by_tenant_name'])
            ? passwayTypeFilterState.sort_by_tenant_name
            : rawData?.filter_state?.['sort_by_tenant_name'];

        const rawPasswayFilterTypesByKey: undefined | { [key: string]: IPasswayFilterType } = (
            rawData?.filter_state as IDataObjectsPasswayTypeFilterState
        )?.passway_filter_types?.reduce((acc: { [key: string]: IPasswayFilterType }, value: IPasswayFilterType) => {
            acc[value.key] = value;
            return acc;
        }, {});

        const filterTypes = passwayTypeFilterState.passway_filter_types.map((element) => {
            const state: undefined | IPasswayFilterType = rawPasswayFilterTypesByKey?.[element.key];
            return { ...element, active: state ? state.active : element.active };
        });

        return {
            active: rawData?.active || this.active,
            filter_state: {
                sort_by_tenant_name: sortByTenantName,
                passway_filter_types: filterTypes,
            },
            filter_type: this.name,
        };
    }

    getComponent(props: IGetComponentProps): ReactNode {
        return <Filter {...props} />;
    }

    filterDataObjects(args: IFilterDataObjectsArgs): { [reportingObjectType: string]: IReportingObject[] } {
        const {
            reportingObjectsByType,
            mainDateRanges,
            mainPeriod,
            passway2DataObj,
            filterState,
            reportingObjectsByTypeAndMarker,
            t,
        } = args;

        const mainPeriodDateRange = mainDateRanges?.find((element) => element.id === mainPeriod?.id)?.period;
        const result = cloneDeep(reportingObjectsByType);

        if (mainPeriodDateRange && passway2DataObj) {
            const activeFilterType = filterState.passway_filter_types.find((element) => element.active);
            const filteredPassway2DataObj = filterValidDateRanges(passway2DataObj, mainPeriodDateRange);

            const passways2DataObjByPasswayId = filteredPassway2DataObj.reduce((acc, value) => {
                if (value.dataobj_type && value.dataobj_type === 'tenant') {
                    if (acc[value.passway_dataobj_id]) acc[value.passway_dataobj_id].push(value);
                    else acc[value.passway_dataobj_id] = [value];
                }
                return acc;
            }, {});

            if (activeFilterType) {
                switch (activeFilterType.key) {
                    case 'interzonalPassways': {
                        if (result['passway']) {
                            const passwayRelations = filteredPassway2DataObj.reduce((acc, value) => {
                                if (isNumber(value.passway_dataobj_id)) {
                                    if (!acc[value.passway_dataobj_id]) {
                                        acc[value.passway_dataobj_id] = [value.dataobj_type];
                                    } else {
                                        acc[value.passway_dataobj_id].push(value.dataobj_type);
                                    }
                                }

                                return acc;
                            }, {});

                            result['passway'] = result['passway']?.filter(
                                (element) =>
                                    !passwayRelations[element.id]?.includes('place') &&
                                    !passwayRelations[element.id]?.includes('location'),
                            );
                        }
                        break;
                    }
                    case 'multipasswayTenantsOnly': {
                        const passway2TenantByTenantMarker = filteredPassway2DataObj.reduce((acc, value) => {
                            if (value.dataobj_type === 'tenant') {
                                if (acc[value.dataobj_marker]) acc[value.dataobj_marker].push(value);
                                else acc[value.dataobj_marker] = [value];
                            }
                            return acc;
                        }, {});
                        const availablePasswaysIds: number[] = [];

                        Object.values(passway2TenantByTenantMarker).forEach((element: IPassway2DataObjRow[]) => {
                            if (element.length > 1) {
                                element.forEach((element) => {
                                    availablePasswaysIds.push(element.passway_dataobj_id);
                                });
                            }
                        });

                        if (result['passway']) {
                            const passways: (IReportingObject & { sortingField?: string })[] = [];

                            result['passway'].forEach((element) => {
                                if (availablePasswaysIds.includes(element.id)) {
                                    const additionalMessage = this.getPasswayAdditionalText(
                                        passways2DataObjByPasswayId[element.id],
                                        reportingObjectsByTypeAndMarker,
                                    );
                                    passways.push({
                                        ...element,
                                        name: `${element.name}${additionalMessage}`,
                                        sortingField: filterState.sort_by_tenant_name
                                            ? additionalMessage.trim()
                                            : undefined,
                                    });
                                }
                            });

                            result['passway'] = passways;
                        }

                        break;
                    }

                    case 'perimeterPassways': {
                        if (result['passway']) {
                            const availablePasswayIds: number[] = [];
                            filteredPassway2DataObj.forEach((element) => {
                                if (element.dataobj_type === 'location') {
                                    availablePasswayIds.push(element.passway_dataobj_id);
                                }
                            });
                            const passways: IReportingObject[] = [];
                            result['passway'].forEach((element) => {
                                if (availablePasswayIds.includes(element.id)) {
                                    passways.push({ ...element, name: `${element.name} (${t('Perimeter')})` });
                                }
                            });
                            result.passways = passways;
                        }
                        break;
                    }

                    default:
                        if (result['passway']) {
                            result['passway'] = result['passway'].map((element) => {
                                const additionalMessage = this.getPasswayAdditionalText(
                                    passways2DataObjByPasswayId[element.id],
                                    reportingObjectsByTypeAndMarker,
                                );
                                return {
                                    ...element,
                                    name: `${element.name}${additionalMessage}`,
                                    sortingField: filterState.sort_by_tenant_name
                                        ? additionalMessage.trim()
                                        : undefined,
                                };
                            });
                        }
                        break;
                }
            }
        }
        return result;
    }

    private getPasswayAdditionalText(
        tenants: IPassway2DataObjRow[],
        reportingObjectsByTypeAndMarker: { [marker: string]: IReportingObject },
    ): string {
        let additionalMessage = '';
        if (tenants?.length) {
            additionalMessage = tenants.reduce((acc, value, index) => {
                const tenantData: IReportingObject =
                    reportingObjectsByTypeAndMarker[`${value.dataobj_type}:${value.dataobj_marker}`];
                if (tenantData) {
                    if (index === 0) acc += ` (${tenantData.name}`;
                    else acc += `, ${tenantData.name}`;
                }
                return acc;
            }, '');
        }
        return `${additionalMessage ? additionalMessage + ')' : ''}`;
    }
}
