import styled from 'styled-components/macro';

export const THead = styled.thead<{ headerHeight?: number }>`
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    position: sticky;
    top: ${(p) => (p.headerHeight ? p.headerHeight + 'px' : '0px')};
    background-color: white;
`;

export const Th = styled.th`
    padding: 20px 8px 8px 8px;
    vertical-align: top;
    :first-child {
        padding-left: 20px;
    }

    :last-child {
        padding-right: 20px;
    }
`;

export const GreyText = styled.div`
    font-size: 12px;
    color: #abafb3;
`;

export const IntersectionThDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    white-space: nowrap;
`;
