import { IDataAdapterResponseItem } from '../../../interfaces';
import { Rating, RatingWrapper, Td } from '../../../styles';
import Stack from '../../../../../Stack/Stack';
import React from 'react';

export const CellRating = (props: { item: IDataAdapterResponseItem }) => {
    return (
        <Td>
            <Stack direction="column" gap={3} justifyContent="flex-end">
                <RatingWrapper text={props.item.name}>
                    <Rating width={props.item.mainValue.percentOfMax} />
                </RatingWrapper>
            </Stack>
        </Td>
    );
};