import styled from 'styled-components/macro';
import { makeStyles } from '@griffel/react';
import { createCustomFocusIndicatorStyle, shorthands } from '@fluentui/react-components';

export const useCustomFocusIndicatorStyles = makeStyles({
    focusIndicator: createCustomFocusIndicatorStyle({
        boxShadow: 'none',
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightColor: 'transparent',
        borderLeftColor: 'transparent',
        outlineColor: 'transparent',
        '::after': {
            boxShadow: 'none',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            borderRightColor: 'transparent',
            borderLeftColor: 'transparent',
            outlineColor: 'transparent',
        },
    }),
});

export const useAppStyles = makeStyles({
    messageBarGroup: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('15px'),
    },
});

export const MainArea = styled.section`
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const WorkArea = styled.section<{ sidebarOpen: boolean }>`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    /* flex-direction: column; */
    box-sizing: border-box;
    /* position: relative; */
    * {
        box-sizing: border-box;
    }
    /* overflow: ${(p) => (p.sidebarOpen ? 'hidden' : 'auto')}; */
`;

export const SidebarLayout = styled.div<{ sidebarOpen: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${(p) => (p.sidebarOpen ? '1' : '0')};
    width: 100%;
    height: 100%;
    z-index: 100;
    display: ${(p) => (p.sidebarOpen ? 'block' : 'none')};

    transition: transform 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
`;

export const Main = styled.section<{ paddingTop: number }>`
    width: 100vw;

    @media (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        max-width: calc(100vw - var(--space-sidebar));
    }

    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-top: ${(p) => p.paddingTop + 'px'};
    background-color: var(--color-secondary-light);
`;

export const AlertsLayer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
`;

export const ModuleContentWrapper = styled.div`
    flex-grow: 1;
`;

export const GlobalSpinnerWrapper = styled.div<{ isSidebarShown: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: ${(p) => (p.isSidebarShown ? `calc(100vw - 15.5rem)` : '100vw')};
    left: ${(p) => (p.isSidebarShown ? '15.5rem' : '0')};
    position: fixed;
    /* background-color: white; */
    z-index: 500;

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        width: 100vw;
        left: 0;
    }
`;
