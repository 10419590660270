import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isTabletInboundWidth } from 'src/theme';
import { generalReducerValues } from '../../../../General.reducer';
import { IProps } from './interfaces';
import { TabletModeWrapper, Wrapper } from './styles';
import IconButton from '../../../IconButton/IconButton';
import { Settings24Regular } from '@fluentui/react-icons';
import ContextualMenu from '../../../ContextualMenu/ContextualMenu';

const ControlsAreaWrapper: FC<IProps> = ({ children }) => {
    const {
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);

    const isTablet = isTabletInboundWidth(width);

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    return (
        <>
            {isTablet ? (
                <ContextualMenu
                    contextualMenuBody={<TabletModeWrapper>{children}</TabletModeWrapper>}
                    isContextualMenuOpen={isContextualMenuOpen}
                    closeContextualMenu={closeContextualMenu}
                    gapSpace={10}
                >
                    <div>
                        <IconButton icon={<Settings24Regular />} onClick={toggleContextualMenu} size={24} />
                    </div>
                </ContextualMenu>
            ) : (
                <Wrapper>{children}</Wrapper>
            )}
        </>
    );
};

export default ControlsAreaWrapper;
