import { Helmet } from 'react-helmet';

/**
 * Компонент для инициализации счетчика GoogleAnalytics
 */
const GooMetricsCounter = () => {
    return (
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-P6WQCRVTB0"></script>
            <script type="text/javascript">
                {`window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', 'G-P6WQCRVTB0');`}
            </script>
        </Helmet>
    );
};

export default GooMetricsCounter;
