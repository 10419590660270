import axios from 'axios';
import { getHeaders } from './getHeaders';
import { IRequestStructureArgs } from './interfaces';

const requestStructure = async (args: IRequestStructureArgs) => {
    const { signal, token, queries, jq, url } = args;
    try {
        const response = await axios({
            method: 'POST',
            signal,
            url,
            headers: getHeaders(token),
            data: JSON.stringify({
                auth: {
                    xtoken: token,
                },
                queries: queries.map((q) => ({
                    item: `pl_structure/pl${q.pl_id}/${q.structure_section}/${q.structure_type}.json`,
                })),
                jq,
            }),
        });

        return response;
    } catch (error) {
        return error;
    }
};

export default requestStructure;
