import styled from 'styled-components/macro';

export const ManagerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    /* height: 90%; */
    flex-grow: 1;
    margin-bottom: 10px;
`;
export const Column = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--color-secondary-darker);
    overflow: auto;
    gap: 5px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* height: 70vh; */
    min-width: 500px;

    justify-content: space-between;
`;
