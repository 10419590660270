import { CellWrapper, RowWrapper } from './styles';
import { FC, useMemo } from 'react';

import { ChartDot } from '../styles';
import { ITooltipBodyProps } from './interfaces';
import { isNumber, isUndefined } from 'lodash';
import { valueFormatter } from '../../../tools/Strings/valueFormatter';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для отображения "тела" тултипа
 * @param showSeriesName Флаг, отвечающий за отобажение названия серии
 * @param points Все элементы тултипа
 */
const TooltipBody: FC<ITooltipBodyProps> = ({ points, showSeriesName = false }) => {
    const { t } = useTranslation();
    const rows = useMemo(() => {
        return points && points.length
            ? points.map((point) => {
                  const units = point?.point?.['units'] || '';
                  let precision = 2;
                  const additionalText = point?.point?.['additionalText'] || '';
                  const showTooltipBody = point?.point?.['showTooltipBody'];
                  const valueBy = point?.point?.['valueBy'] || 'y';
                  const showUnits = point?.point?.['showUnits'] || false;

                  if (isNumber(point?.point?.['precision'])) {
                      precision = point?.point?.['precision'];
                  }

                  if (!isUndefined(showTooltipBody) && !showTooltipBody) return null;

                  let value: number | null | undefined = null;

                  if (valueBy !== 'y') {
                      value = point?.point?.[valueBy];
                  } else {
                      value = point.y;
                  }

                  return (
                      <RowWrapper key={point.key || point.colorIndex + Math.random()}>
                          <CellWrapper>
                              <ChartDot newColor={point.color} />
                              <span>{showSeriesName ? point.series.name : point.key}</span>
                          </CellWrapper>
                          <CellWrapper>{`${valueFormatter({
                              precision,
                              value,
                              units,
                              showUnits,
                              t,
                          })} ${additionalText}`}</CellWrapper>
                      </RowWrapper>
                  );
              })
            : null;
    }, [points, t]);

    return <>{rows}</>;
};

export default TooltipBody;
