import styled from 'styled-components/macro';

export const MapsWrapper = styled.div``;

export const StageWrapper = styled.div`
    /* display: flex; */
`;

export const SelectWrapper = styled.div`
    margin-bottom: var(--space-xxs);

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        margin-top: 15px;
    }
`;

export const RatingsWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;
