import { useCallback } from 'react';
import { useTranslation as useI18Translation } from 'react-i18next';
import { TFunction } from 'i18next';

import { TTranslationNS } from './interfaces';

/**
 * Кастомный хук для перевода.
 * @param nameSpaces Пространство имен, которые нужно скачать с сервера. Каждое имя содержит специализированные переводы
 */
const useTranslation = (nameSpaces: TTranslationNS | TTranslationNS[] = 'translation') => {
    // nameSpaces = Array.isArray(nameSpaces) ? ['metrics', ...nameSpaces] : ['metrics', nameSpaces];

    const { t } = useI18Translation(nameSpaces);

    // fetch('/js/focus-sw-handlers.js');
    const stringifiedNamespace = Array.isArray(nameSpaces) ? nameSpaces.join('') : nameSpaces;
    const extendedT: TFunction = useCallback(
        (text: string) => {
            return t(text, { ns: nameSpaces });
        },
        [stringifiedNamespace, t],
    ) as TFunction<'translation'>;

    return { t: extendedT };
};

export default useTranslation;
