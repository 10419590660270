import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IDynamicsPMOReducerState } from './interfaces';
import { ITabListOptionsItem } from '../../../../../components/TabList/interfaces';

const availableChartTypes: ITabListOptionsItem[] = [
    { text: 'Periods', id: 'periods' },
    { text: 'Metrics', id: 'metrics' },
    { text: 'Objects', id: 'objects' },
];

const initialState: IDynamicsPMOReducerState = {
    selectedChartType: availableChartTypes[0],
    availableChartTypes,
};

const Performance_Dashboard_DynamicsPMO_Widget_Reducer = createSlice({
    name: 'Performance_Dashboard_DynamicsPMO_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Запись выбранного типа графика
         */
        storeSelectedChartType: (state, action: PayloadAction<string>) => {
            const currentChartType = availableChartTypes?.find((element) => element.id === action.payload);
            state.selectedChartType = currentChartType || availableChartTypes[0];
        },
    },
});

export const { storeSelectedChartType } = Performance_Dashboard_DynamicsPMO_Widget_Reducer.actions;

export const Performance_Dashboard_DynamicsPMO_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_Dashboard_DynamicsPMO_Widget_Reducer;

export default Performance_Dashboard_DynamicsPMO_Widget_Reducer.reducer;
