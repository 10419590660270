import styled from 'styled-components/macro';

export const ClearAllWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const Click = styled.span`
    margin-right: var(--space-xxxs);
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-primary);
    :hover {
        color: var(--color-primary-darker);
    }
`;
