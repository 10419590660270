import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

import { useGeneralSelector, useWidgetCurrentOptions } from 'src/hooks';
import {
    emptyArray,
    emptyObject,
    // filterValidDateRanges,
    getReportingObjects,
    IOptionsOfReportingObjects,
} from 'src/tools';
import { IReportingObject } from 'src/General.interfaces';
import filterObjects from 'src/components/Selects/ObjectsSelect/tools/filterObjects';
import { CATEGORY_WORD, ZONES_WORD } from 'src/constants';

import { TDataObjectsFilterTypes } from '../interfaces';

// TODO: required refactoring; required to check options
type FilterOptions = {
    mode?: 'single' | 'multi';
    /**
     * Фильтры, которые отобразить в селекте
     * @default []
     */
    dataObjectFilters?: TDataObjectsFilterTypes[];
    validOptions?: string[];
    /**
     * Функция для кастомной фильтрации отчетных объектов в опциях селекта
     */
    validateObjects?: (reportingObjectsByType: { [x: string]: IReportingObject[] }) => {
        [x: string]: IReportingObject[];
    };
}

type ObjectKey =
    // | 'floor'
    // | 'passway'
    'tenant'
    // | 'Zones: Zones'
    // | 'Zones: IKEA Commercial'
    // | 'place'
    // | 'location'
    // | 'Trade category'
    // | 'Sub-category'
    // | 'Price-segment';

export const useFilteredObjects = (objectKey: ObjectKey, filterOptions: FilterOptions = emptyObject) => {
    const {
        currentModuleID,
        extendedDataObjectsSelectFiltersByType,
        cfg: {
            reportingObjectsByType,
            tenant2PlaceByTenantId,
            reportingObjectsByTypeAndMarker,
            tenant2FloorByTenantId,
        },
        src: { reportingObjects, projectCategories },
        structures,
        // lang,
        // mainPeriodDateRange,
    } = useGeneralSelector();

    const {
        mode = 'multi',
        validateObjects,
        dataObjectFilters = emptyArray,
        validOptions,
    } = filterOptions;

    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const mainPeriod = localCurrentOptions?.mainPeriod;
    const mainDateRanges = localCurrentOptions?.mainDateRanges;

    // const mainPeriodDateRange = mainDateRanges?.find((element) => element.id === mainPeriod?.id)?.period;

    // const activeFilters: IDataObjectsSelectFilter[] = useMemo(() => {
    const activeFilters = useMemo(() => {
        return Object.values(extendedDataObjectsSelectFiltersByType).filter((element) => element.active);
    }, [extendedDataObjectsSelectFiltersByType]);

    /** Настройки для селекта (опции селекта) */
    const options = useMemo(() => {
        const result: Array<IOptionsOfReportingObjects> = [];

        let filteredReportingObjectsByType = validateObjects
            ? validateObjects(reportingObjectsByType)
            : reportingObjectsByType;

        if (structures && mainDateRanges && mainPeriod && activeFilters.length) {
            filteredReportingObjectsByType = filterObjects({
                passway2DataObj: structures.relations_passway2dataobj,
                dataObjects: structures.elements_ms_data_objects,
                reportingObjectsByTypeAndMarker,
                reportingObjectsByType: filteredReportingObjectsByType,
                tenant2FloorByTenantId,
                tenant2PlaceByTenantId,
                dataObjectFilters,
                mainDateRanges,
                activeFilters,
                mainPeriod,
                t,
            });
        }

        if (!validOptions) {
            result.push(...getReportingObjects(filteredReportingObjectsByType, t));
        } else {
            result.push(
                ...getReportingObjects(filteredReportingObjectsByType, t)?.filter((item) => {
                    return (
                        validOptions?.map((item) => t(item)?.toLowerCase())?.includes(item.section?.toLowerCase()) ||
                        (validOptions?.map((item) => item?.toLowerCase())?.includes('zones') &&
                            item.section?.includes(t(ZONES_WORD))) ||
                        (validOptions?.map((item) => item?.toLowerCase())?.includes('categories') &&
                            item.section?.includes(t(CATEGORY_WORD)))
                    );
                }),
            );
        }

        const objects = result.find((item) => item.key === objectKey)?.objects || emptyArray;

        // if (mode === 'single' && validOptions?.length === 1) {
        //     return sortBy(result[0]?.objects || [], (item) => item.order);
        // } else {
        //     return sortBy(result, (item) => item.order);
        // }
        return sortBy(objects, (item) => item.order);
    }, [
        reportingObjectsByTypeAndMarker,
        reportingObjectsByType,
        tenant2FloorByTenantId,
        tenant2PlaceByTenantId,
        // projectCategories,
        dataObjectFilters,
        activeFilters,
        validOptions,
        structures,
        mainPeriod,
        t,
        mainDateRanges,
        // mode,
        validateObjects,
        objectKey,
    ]);

    return options;
    // const objects = reportingObjectsByType?.[objectName];
    // if (!objects) {
    //     return emptyArray;
    // }

    // if (!mainPeriodDateRange || !Object.keys(tenant2PlaceByTenantId).length) {
    //     return emptyArray;
    // }

    // const activeFilterType = filterState.tenant_filter_types.find((element) => element.active);

    // if (activeFilterType) {
    //     switch (activeFilterType.key) {
    //         case 'showActiveTenants': {
    //             if (result['tenant']) {
    //                 const activeTenants = result['tenant'].filter(
    //                     (element) =>
    //                         tenant2PlaceByTenantId[element.id] &&
    //                         filterValidDateRanges(tenant2PlaceByTenantId[element.id], mainPeriodDateRange)
    //                             .length,
    //                 );

    //                 result['tenant'] = activeTenants;
    //             }
    //             break;
    //         }
    //         case 'showInactiveTenants': {
    //             if (result['tenant']) {
    //                 const inactiveTenants = result['tenant'].filter(
    //                     (element) =>
    //                         !tenant2PlaceByTenantId[element.id] ||
    //                         (tenant2PlaceByTenantId[element.id] &&
    //                             !filterValidDateRanges(tenant2PlaceByTenantId[element.id], mainPeriodDateRange)
    //                                 .length),
    //                 );

    //                 result['tenant'] = inactiveTenants;
    //             }
    //             break;
    //         }

    //         default:
    //             break;
    //     }
    // }

    // return result;
}