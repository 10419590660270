import styled from 'styled-components/macro';

export const TextWrapper = styled.div`
    color: var(--color-secondary-darker);
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    
    span {
        font-weight: 500;
    }
    div {
        display: inline-block;
    }
`;
