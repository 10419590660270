import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getHeaders } from './getHeaders';
import { TRequestMetricsArgs } from './interfaces';

const requestObjectMetrics = async (args: TRequestMetricsArgs, url?: string) => {
    const requests = args.map((request) => {
        const { signal, token, alias, metric, obj_ids, object_aggregation, time_range, time_freq, metric_params } =
            request;

        if (!obj_ids.length || !token) return;

        // const headers = {
        //     'Content-Type': 'application/json',
        //     'x-token': token
        // };

        const headers = getHeaders(token);

        axiosRetry(axios, { retries: 3, retryDelay: () => 3000, retryCondition: (_error) => true });

        return axios({
            method: 'POST',
            headers,
            signal,
            url: `${url}`,
            data: JSON.stringify({
                input_parameters: {
                    metric_params,
                    alias,
                    metric,
                    obj_ids,
                    object_aggregation,
                    time_range,
                    time_freq,
                },
            }),
        });
    });

    try {
        const response = await axios.all(requests).then(
            axios.spread((...res) => {
                return res;
            }),
        );
        return response;
    } catch (err) {
        return err;
    }
};

export default requestObjectMetrics;
