import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Preset from '../../../../../components/Preset/Preset';
import MainPeriodSelect from '../../../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { ISettings } from '../../../../../components/UserSettings/interfaces';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { PresetsWrapper } from '../../../../../components/Wrappers/PresetsWrapper/styles';
import { SelectsWrapper } from '../../../../../components/Wrappers/SelectsWrapper/styles';
import { DS } from '../../../../../constants/constants';

import { generalReducerValues } from '../../../../../General.reducer';
import { Reports_Intersections_Widget_Reducer_Values } from './reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import LayoutSelect, { DEFAULT_OPTIONS } from '../../../components/LayoutSelect/LayoutSelect';
import { generateIntersectionsReportAddFields } from './constants/intersectionsReportAdditionalFields';
import ObjectsSelect from '../../../../../components/Selects/ObjectsSelect/ObjectsSelect';
import { IReportingObject } from '../../../../../General.interfaces';

const IntersectionOptions = () => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const { keyWord } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const validOptions = ['Location', 'Tenants', 'Zones'];

    const validateShopsterObjects = (reportingObjectsByType: {
        [x: string]: IReportingObject[];
    }): { [x: string]: IReportingObject[] } => {
        return Object.entries(reportingObjectsByType).reduce((acc, [type, reportingObjects]) => {
            const filteredReportingObjects = reportingObjects.filter((item) => {
                return item.external_ids.some((exId) => exId.data_source === 'shopster');
            });
            acc[type] = filteredReportingObjects;
            return acc;
        }, {});
    };

    return (
        <>
            <SelectsWrapper>
                <MainPeriodSelect
                    name={`mainPeriod${DS}${keyWord}`}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <ObjectsSelect
                    name={`mainObjectsIds${DS}${keyWord}`}
                    validOptions={validOptions}
                    localCurrentOptions={localCurrentOptions}
                    dataObjectFilters={['data_objects_tenant', 'data_objects_floor']}
                    maxSelected={1}
                    minSelected={1}
                    label={t('Objects')}
                    canSelectEntireSection={true}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                    // validateObjects={validateShopsterObjects}
                />
                <ObjectsSelect
                    name={`compareObjectsIds${DS}${keyWord}`}
                    validOptions={validOptions}
                    dataObjectFilters={['data_objects_tenant', 'data_objects_floor']}
                    localCurrentOptions={localCurrentOptions}
                    minSelected={1}
                    label={t('Objects to compare')}
                    canSelectEntireSection={true}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                    // validateObjects={validateShopsterObjects}
                />

                <UniversalSelect
                    options={generateIntersectionsReportAddFields(t)}
                    name={`additionalFields${DS}${keyWord}`}
                    labelText={t('Additional settings')}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <LayoutSelect
                    options={DEFAULT_OPTIONS.filter((option) => option.id !== 'landscape')}
                    name={`reportFormat${DS}${keyWord}`}
                />
            </SelectsWrapper>
            <PresetsWrapper>
                <Preset
                    currentOptionsKeys={
                        [
                            `mainPeriod${DS}${keyWord}`,
                            `mainObjects${DS}${keyWord}`,
                            `compareObjects${DS}${keyWord}`,
                            `reportFormat${DS}${keyWord}`,
                            `additionalFields${DS}${keyWord}`,
                        ] as (keyof ISettings)[]
                    }
                />
            </PresetsWrapper>
        </>
    );
};

export default IntersectionOptions;
