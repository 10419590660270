import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReducerState } from './interfaces';
import { RootState } from '../../../store';

const initialState: IReducerState = {
    moduleId: 'network_tenants_overview',
};

const Network_Tenants_Overview_Module_Reducer = createSlice({
    name: 'Network_Tenants_Overview_Module_Reducer',
    initialState,
    reducers: {},
});

export const {} = Network_Tenants_Overview_Module_Reducer.actions;

export const Network_Tenants_Overview_Module_Reducer_Values = (state: RootState) =>
    state.Network_Tenants_Overview_Module_Reducer;

export default Network_Tenants_Overview_Module_Reducer.reducer;
