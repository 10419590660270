import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import { IValidateFooResult } from '../../interfaces';
import { WrapperTable } from './styles';

interface IProps {
    data: Omit<IValidateFooResult, 'isValid'>[];
}

export function Table({ data }: IProps) {
    const { t } = useTranslation();
    return (
        <WrapperTable>
            <thead>
                <tr>
                    <th>{t('Object id')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('Error description')}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, i) => {
                    return (
                        <tr key={`${item.data_object}++${i}`}>
                            <td>{item.data_object}</td>
                            <td>{item.date}</td>
                            <td>{t(item.text)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </WrapperTable>
    );
}
