import cloneDeep from 'lodash/cloneDeep';
import { ICellBillData, IMetricCell, TOperationType } from '../interfaces';
import { COLUMNS } from '../constants/constants';
import { useAppDispatch } from '../../../../hooks/useSettings';
import {
    storeDataFromFile,
    storeDateRangeFromFile,
    storeObjIdsFromFile,
    toggleValidationModalStatus,
} from '../reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useSelector } from 'react-redux';
import { DS } from '../../../../constants/constants';
import { getInfo } from './getInfo';
import { useValidate } from './useValidate';
import { isValidDate, isValidObjectId } from './validationFoos';
import { DateTime } from 'luxon';
import { getDateRange } from './getDateRange';

export const useHandleDataFromFile = () => {
    const { currencyCode } = useSelector(generalReducerValues);

    const { validate } = useValidate({
        validateFoos: [isValidDate, isValidObjectId],
        validateReadOnlyCells: true,
        tableKey: 'dataFromFile',
    });

    const dispatch = useAppDispatch();

    const handleDataFromFile = (rawData: string[][]) => {
        if (rawData) {
            const cloned = cloneDeep(rawData).filter((row) => row.length === COLUMNS.length);
            cloned.shift();

            const { timeFreq, objIds } = getInfo(cloned);

            const dataFromFile: IMetricCell[][] = cloned.map((row: string[], rowIndex) => {
                return row.map((cell: string, i) => {
                    const colId = COLUMNS.find((item, j) => j === i)?.colId ?? '';
                    const colName = COLUMNS.find((item, j) => j === i)?.colName ?? '';
                    const readOnly = COLUMNS.find((item, j) => j === i)?.readOnly ?? false;
                    const metric = colId.split(DS)[1];
                    const objName = row[1];

                    const obj: IMetricCell = {
                        value: cell,
                        initialValue: cell,
                        isValid: true,
                        colId,
                        colName,
                        objName,
                        readOnly,
                        metric,
                        data_object: Number(row[0]),
                        date: row[2],
                        changeType: readOnly ? undefined : 'POST',
                    };
                    return obj;
                });
            });
            const headerRow: IMetricCell[] = COLUMNS.map((item) => {
                return {
                    ...item,
                    value: item.colName,
                    objName: '',
                    isValid: true,
                    initialValue: '',
                    isHeader: true,
                };
            });

            const validateResult = validate(dataFromFile);

            if (validateResult.isValid) {
                const result: IMetricCell[][] = dataFromFile.map((row) => {
                    let isoDate = '';
                    const dateCell = row.find((cell) => {
                        return cell.colId === 'date';
                    });

                    if (dateCell?.value) {
                        if (DateTime.fromISO(dateCell.value).isValid) {
                            isoDate = dateCell.value;
                        } else if (
                            navigator.language.includes('ru') &&
                            DateTime.fromFormat(dateCell.value, 'dd.MM.yyyy').isValid
                        ) {
                            isoDate = String(DateTime.fromFormat(dateCell.value, 'dd.MM.yyyy').toISODate());
                        } else if (
                            navigator.language.includes('en') &&
                            DateTime.fromFormat(dateCell.value, 'MM.dd.yyyy').isValid
                        ) {
                            isoDate = String(DateTime.fromFormat(dateCell.value, 'MM.dd.yyyy').toISODate());
                        }
                    }

                    return row.map((cell) => {
                        if (cell.colId === 'date') {
                            return {
                                ...cell,
                                value: isoDate,
                            };
                        } else {
                            return { ...cell, billData: { ...cell.billData, date: isoDate } } as IMetricCell;
                        }
                    });
                });

                const dateRange = getDateRange(result);

                result.unshift(headerRow);
                dispatch(storeDateRangeFromFile(dateRange));
                dispatch(storeObjIdsFromFile(objIds));
                dispatch(storeDataFromFile(result));
            } else {
                dispatch(storeDateRangeFromFile(null));
                dispatch(storeObjIdsFromFile([]));
                dispatch(storeDataFromFile([]));
                dispatch(toggleValidationModalStatus({ show: true, data: validateResult.data }));
            }
        }
    };
    return { handleDataFromFile };
};
