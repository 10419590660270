import { LegendOptions, Point, Series } from 'highcharts';
import { renderToString } from 'react-dom/server';

import { theme } from 'src/theme';

import Legend from '../../../../../Charts/Dynamics/components/Legend/Legend';

interface IArgs {
    legendOptions?: LegendOptions;
}

const generateLegendOptions = (args: IArgs): LegendOptions => {
    return {
        labelFormatter: function (this: Point | Series) {
            return renderToString(
                <Legend
                    color={typeof this.color === 'string' ? this.color : theme.seriesColors[this.index % theme.seriesColors.length]}
                    visible={this.visible}
                    name={this.name}
                    data={this}
                />,
            );
        },
        itemMarginBottom: 10,
        squareSymbol: false,
        alignColumns: false,
        itemDistance: 10,
        symbolPadding: 0,
        symbolHeight: 0,
        symbolRadius: 0,
        align: 'left',
        symbolWidth: 0,
        maxHeight: 100,
        useHTML: true,
        margin: 27,
        ...args.legendOptions,
    };
};

export default generateLegendOptions;
