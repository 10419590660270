import { ChartOptions } from 'highcharts';

import { isMobileInboundWidth } from 'src/theme';

import { CHART_DESKTOP_HEIGHT, CHART_MOBILE_HEIGHT } from '../../../../../constants/constants';

/**
 * Функция для генерация настроек чарта
 * @param inverted Флаг отвечающий за смену осей
 * @param windowDimensions Размеры окна
 * @param customChartDimensions Произвольные размеры чарта
 * @returns Настройки чарта
 */
const generateChart = (
    inverted: boolean,
    windowDimensions: { width: number; height: number },
    customChartDimensions?: { width: number; height: number },
    chartOptions?: ChartOptions,
): ChartOptions => {
    const isMobile = isMobileInboundWidth(windowDimensions.width);

    return {
        height: customChartDimensions
            ? customChartDimensions.height
            : isMobile
            ? CHART_MOBILE_HEIGHT
            : CHART_DESKTOP_HEIGHT,
        style: { fontFamily: 'Roboto' },
        animation: true,
        width: customChartDimensions ? customChartDimensions.width : undefined,
        inverted,
        ...chartOptions,
    };
};

export default generateChart;
