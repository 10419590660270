import { IProjectCategory, IReportingObject } from '../General.interfaces';
import { objectsOrder } from '../constants/objectsOrder';

export const modifyCategories = (categories: Array<IProjectCategory>, filledCategoriesIds: number[]) => {
    const result: IReportingObject[] = categories
        ?.filter((item) => item.parent_id)
        // Отсекаем категории, не содержащие объектов
        ?.filter((item) => filledCategoriesIds.includes(item.id))
        .map((item) => {
            const parentName = categories?.find((cat) => item.parent_id === cat.id)?.object_name || '';
            return {
                ...item,
                order: objectsOrder['project_category'],
                object_type: parentName,
                block_type: 'project_category',
                pl_id: 0,
                object_params: {
                    marker: '',
                },
                external_ids: [
                    {
                        data_source: '',
                        external_id: '',
                    },
                ],
                is_active: true,
                marker: '',
                object_name: item.object_name,
                name: item.object_name,
            };
        });

    return result;
};
