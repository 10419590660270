import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    increaseShoudUpdateMetricsCounter,
    toggleMetricsFetching,
    storeRowMetricsData,
    Cases_Benchmarks_Sales_Widget_Reducer_Values,
} from '../reducer';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { TTimeFreq } from '../../../../../../tools/API/interfaces';
import { Cases_Benchmarks_Module_Reducer_Values } from '../../../reducer';
import { DS } from '../../../../../../constants/constants';

/**
 * Кастомный хук для получения метрик с сервера
 */
export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();
    const { moduleName } = useSelector(Cases_Benchmarks_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { dataRefetchObject } = useSelector(Cases_Benchmarks_Sales_Widget_Reducer_Values);

    const { token } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Инициирует перезапрос метрик, если изменился период сравнения на вкладке Периодов
    useEffect(() => {
        dispatch(increaseShoudUpdateMetricsCounter());
    }, [localCurrentOptions?.comparePeriods]);

    useEffect(() => {
        if (!localCurrentOptions) return;
        const controller = new AbortController();
        const signal = controller.signal;

        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
        const mainTimeRange = [mainDateRange?.period?.dateFrom, mainDateRange?.period?.dateTo];

        const aliasMap = {
            12044: '12032:12038:12016',
            12045: '12032:12039:12016',
            12046: '12033:12038:12016',
            12047: '12033:12039:12016',

            12048: '12032:12038:12028',
            12049: '12032:12039:12028',
            12050: '12033:12038:12028',
            12051: '12033:12039:12028',

            12052: '12032:12038:12019',
            12053: '12032:12039:12019',
            12054: '12033:12038:12019',
            12055: '12033:12039:12019',
        };

        const tenantId = localCurrentOptions?.['tenant']?.[0]?.id;
        const aliasParams = aliasMap[tenantId];

        // let categoryId = '12016';
        // if (localCurrentOptions?.['tenant']?.[0]?.text.split(': ')[1] === 'Электроника') {
        //     categoryId = '12028';
        // } else if (localCurrentOptions?.['tenant']?.[0]?.text.split(': ')[1] === 'Питание') {
        //     categoryId = '12019';
        // }

        if (!aliasParams) return;

        const benchAlias = `benchmark${DS}${localCurrentOptions?.['myDetail']?.[0]?.id}${DS}${localCurrentOptions?.['geoOptions']?.[0]?.id}${DS}${localCurrentOptions?.['mallTypeOptions']?.[0]?.id}${DS}${localCurrentOptions?.['category']?.[0]?.id}`;
        const tenantAlias = `tenant${DS}${localCurrentOptions?.['myDetail']?.[0]?.id}${DS}${aliasParams}`;

        const tenant = localCurrentOptions?.['tenant']?.[0];

        if (localCurrentOptions.comparePeriods && localCurrentOptions.mainPeriod) {
            dispatch(toggleMetricsFetching(true));

            const benchmarkRequest = {
                signal,
                token,
                alias: benchAlias,
                metric: 'fsf_sales_novat',
                obj_ids: [666000001],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: 'W-MON' as TTimeFreq,
            };

            const tenantRequest = {
                signal,
                token,
                alias: tenantAlias,
                metric: 'fsf_sales_novat',
                obj_ids: [666000001],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: 'W-MON' as TTimeFreq,
            };

            const queryData = [tenantRequest, benchmarkRequest];

            dispatch(storeRowMetricsData({ status: 'Loading', message: t('Loading...') }));
            fetchData(queryData)
                .then((res: TServerResponse) => {
                    handleServerResponse({
                        responseAnalyzer: responseAnalyzer,
                        success: storeRowMetricsData,
                        error: storeRowMetricsData,
                        dispatch,
                        res,
                    });
                })
                .catch((error: any) => {
                    console.log('DynamicsPMO useGetMetrics >>>>>', error);
                });
        }

        return () => {
            controller.abort();
        };
    }, [
        localCurrentOptions?.mainPeriod,
        localCurrentOptions?.['geoOptions']?.[0]?.id,
        localCurrentOptions?.['mallTypeOptions']?.[0]?.id,
        localCurrentOptions?.['tenant']?.[0]?.id,
        localCurrentOptions?.['category']?.[0]?.id,
        localCurrentOptions?.['myDetail']?.[0]?.id,
        localCurrentOptions?.currentModuleID,
        dataRefetchObject,
    ]);
};
