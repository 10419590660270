import { useTranslation } from 'react-i18next';
import Tooltip from '../../../Tooltip/Tooltip';
import { Wrapper } from './styles';
import IconButton from '../../../IconButton/IconButton';
import { Settings24Regular } from '@fluentui/react-icons';
interface IProps {
    toggleModal: (x: boolean) => () => void;
}

/**
 * Компонент для отображения шестеренки с настройками кабинета для пользователя
 */
const CabinetPreferencesMenu = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Tooltip content={t('Cabinet preferences')}>
                <IconButton onClick={props.toggleModal(true)} icon={<Settings24Regular />} size={24} />
            </Tooltip>
        </Wrapper>
    );
};

export default CabinetPreferencesMenu;
