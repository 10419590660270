import styled from 'styled-components/macro';

export const ObjectWrapper = styled.div`
    margin-bottom: var(--space-sm);
`;
export const Row = styled.div<{ numCompPeriods: number }>`
    -moz-column-gap: 3px;
    column-gap: 3px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${(p) => {
        return 'repeat(' + p.numCompPeriods + ', 210px)';
    }};
`;

export const CellWrapper = styled.div<{ isHeader?: boolean }>`
    align-items: stretch;
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    border-top: ${(p) => (p.isHeader ? 'auto' : 'none')};
    display: flex;
    flex: 1;
    font-size: 14px;
`;

export const Percent = styled.div<{ percent: string }>`
    white-space: nowrap;
    background-color: hsla(0, 0%, 92.2%, 0.3);
    flex: 0;
    justify-content: flex-end;
    max-width: var(--space-xxxl);
    min-width: var(--space-xxxl);
    font-weight: 600;
    align-items: center;
    display: flex;
    padding: var(--space-xxxs) var(--space-xxs);
    color: ${(p) => {
        if (Number(p.percent) > 3) {
            return 'var(--color-success)';
        } else if (Number(p.percent) < -3) {
            return 'var(--color-accent)';
        } else if (Number(p.percent) >= -3 && Number(p.percent) <= 3) {
            return 'var(--color-warning)';
        } else {
            return 'black';
        }
    }};
`;
export const Value = styled.div<{ isMain?: boolean }>`
    /* flex: 0; */
    width: 100%;
    justify-content: ${(p) => (p.isMain ? 'flex-end' : 'flex-start')};
    /* max-width: var(--space-xxxl);
    min-width: var(--space-xxxl); */
    align-items: center;

    display: flex;
    font-weight: ${(p) => (p.isMain ? '600' : '400')};
    padding: var(--space-xxxs) var(--space-xxs);
`;

export const Period = styled.div`
    /* font-size: var(--text-sm); */
    width: 100%;
    padding: var(--space-xxxs) var(--space-xxs);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
`;

export const Metric = styled.div`
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding: var(--space-xxxs) var(--space-xxs);
`;

export const Table = styled.div`
    td {
        white-space: nowrap !important;
    }
`;

export const Footer = styled.div`
    margin-top: var(--space-sm);
    /* display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: var(--space-sm); */
`;

export const TableWrapper = styled.div`
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    padding: var(--space-xxs);
    /* margin-top: var(--space-sm); */
    max-height: 500px;
    overflow: auto;
    table {
        /* width: 100%; */
        overflow: auto;
    }
    td {
        padding: var(--space-xxxs) !important;
        overflow: hidden;
    }
`;

export const Marker = styled.div`
    padding: var(--space-xxs);
    display: block;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
`;
