import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    IMetricCell,
    IReducerState,
    IValidateFooResult,
    IValidationModalStatus,
    TSelectedTab,
    TTimeFreq,
} from './interfaces';
import { RootState } from '../../../store';
import ReactDataSheet from 'react-datasheet';
import { cloneDeep, isString } from 'lodash';

const initialState: IReducerState = {
    selectedTab: 'summaryTable',
    dataToEdit: [],
    dataFromFile: [],
    timeFreq: 'mixed',
    dateRangeFromFile: null,
    objIdsFromFile: [],
    validationModalStatus: { show: false, data: [] },
};

const Configuration_SalesMetricsUpload_Module_Reducer = createSlice({
    name: 'Configuration_SalesMetricsUpload_Module_Reducer',
    initialState,
    reducers: {
        storeSelectedTab: (state, action: PayloadAction<TSelectedTab>) => {
            state.selectedTab = action.payload;
        },

        storeDataToEdit: (state, action: PayloadAction<IMetricCell[][]>) => {
            state.dataToEdit = action.payload;
        },

        storeDataFromFile: (state, action: PayloadAction<IMetricCell[][]>) => {
            state.dataFromFile = action.payload;
        },

        storeTimeFreq: (state, action: PayloadAction<TTimeFreq>) => {
            state.timeFreq = action.payload;
        },

        storeDateRangeFromFile: (state, action: PayloadAction<{ dateFrom: string; dateTo: string } | null>) => {
            state.dateRangeFromFile = action.payload;
        },

        storeObjIdsFromFile: (state, action: PayloadAction<number[]>) => {
            state.objIdsFromFile = action.payload;
        },

        toggleValidationModalStatus: (state, action: PayloadAction<IValidationModalStatus>) => {
            state.validationModalStatus = action.payload;
        },

        updateTableData: (
            state,
            action: PayloadAction<{ data: ReactDataSheet.CellsChangedArgs<IMetricCell>; tableKey: string }>,
        ) => {
            const { data, tableKey } = action.payload;
            const dataToEdit = cloneDeep(state[tableKey]);

            if (dataToEdit) {
                data.forEach((args) => {
                    const { cell, row, col, value } = args;

                    if (cell && !cell.readOnly && isString(value)) {
                        const prevValue = dataToEdit[row]?.[col] as IMetricCell;
                        if (prevValue && prevValue?.initialValue !== value) {
                            dataToEdit[row][col] = {
                                ...prevValue,
                                value,
                                changeType: cell.value ? 'PATCH' : 'POST',
                            };
                        } else {
                            dataToEdit[row][col] = {
                                ...prevValue,
                                value,
                                changeType: undefined,
                            };
                        }
                    }
                });

                state[tableKey] = dataToEdit;
            }
        },

        invalidateTableData: (state, action: PayloadAction<{ data: IValidateFooResult[]; tableKey: string }>) => {
            const { data, tableKey } = action.payload;
            const dataToEdit = cloneDeep(state[tableKey]) as IMetricCell[][] | undefined;

            if (dataToEdit) {
                const result = dataToEdit.map((row) => {
                    return row.map((cell) => {
                        const isValid = !data.some(
                            (item) =>
                                item.data_object === cell.billData?.data_object && item.date === cell.billData?.date,
                        );
                        return { ...cell, isValid: cell.readOnly ? true : isValid };
                    });
                });

                state[tableKey] = result;
            }
        },
    },
});

export const {
    storeSelectedTab,
    storeDataToEdit,
    updateTableData,
    storeTimeFreq,
    storeDateRangeFromFile,
    storeDataFromFile,
    invalidateTableData,
    toggleValidationModalStatus,
    storeObjIdsFromFile,
} = Configuration_SalesMetricsUpload_Module_Reducer.actions;

export const Configuration_SalesMetricsUpload_Module_Reducer_Values = (state: RootState) =>
    state.Configuration_SalesMetricsUpload_Module_Reducer;

export default Configuration_SalesMetricsUpload_Module_Reducer.reducer;
