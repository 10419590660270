import React from 'react';

import { ButtonProps } from './interfaces';
import { StyledButton } from './styles';

const Button: React.FC<ButtonProps> = ({ baseColor, text, testId = 'Button', children, ...props }) => {
    return (
        <StyledButton data-testid={testId} baseColor={baseColor} {...props}>
            {children ? children : text}
        </StyledButton>
    );
};

export default Button;
