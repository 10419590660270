import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../../store';
import { patchRequest } from '../../tools/API/appBackendAPI';
import { mapFront2Back } from '../../tools/mappingFrontAndBack';
import { cabinetPreferencesMapping } from '../../constants/keysMapping';

import { ICabinetPreferencesState, IGeneralPreferences, ISidebarSettings, TUseBusinessDays } from './interfaces';
import { DEFAULT_GENERAL_CABINET_PREFERENCES, DEFAULT_SIDEBAR_PREFERENCES } from './constants/constants';
import { TComebackWindow, TEngagementThreshold } from './components/Shopster/interfaces';

const initialState: ICabinetPreferencesState = {
    preferences: DEFAULT_GENERAL_CABINET_PREFERENCES,
    dataReceived: false,
    sidebar: DEFAULT_SIDEBAR_PREFERENCES,
};

export const CabinetPreferencesReducer = createSlice({
    name: 'cabinetPreferences',
    initialState,
    reducers: {
        /**
         * Запись настроек кабинета
         */
        storeCabinetPreferences: (state, action: PayloadAction<{ preferences: IGeneralPreferences }>) => {
            state.preferences = action.payload.preferences;
            state.dataReceived = true;
        },

        /**
         * Запись sidebar
         */
        storeSidebar: (state, action: PayloadAction<ISidebarSettings>) => {
            state.sidebar = action.payload;
        },

        modifySidebar: (state, action: PayloadAction<Partial<ISidebarSettings>>) => {
            const { sidebar } = cloneDeep(state);
            state.sidebar = { ...sidebar, ...action.payload };
        },

        storeBusinessDays: (state, action: PayloadAction<TUseBusinessDays>) => {
            const { preferences } = cloneDeep(state);
            state.preferences = { ...preferences, useBusinessDays: action.payload };
        },

        /**
         * Запись порога вовлеченности (Engagement threshold)
         */
        storeEngagementThreshold: (state, action: PayloadAction<TEngagementThreshold>) => {
            //TODO: расскоментить, когда бэк появится
            // const { preferences } = cloneDeep(state);
            // state.preferences = {...preferences, engagementThreshold: action.payload}
        },

        /**
         * Запись периода возврата (Comeback window)
         */
        storeComebackWindow: (state, action: PayloadAction<TComebackWindow>) => {
            //TODO: расскоментить, когда бэк появится
            // const { preferences } = cloneDeep(state);
            // state.preferences = {...preferences, comebackWindow: action.payload}
        },
    },
});

export const {
    storeCabinetPreferences,
    storeBusinessDays,
    modifySidebar,
    storeSidebar,
    storeEngagementThreshold,
    storeComebackWindow,
} = CabinetPreferencesReducer.actions;

export const cabinetPreferencesValues = (state: RootState) => state.CabinetPreferencesReducer;

export default CabinetPreferencesReducer.reducer;

export const patchUserPreferences =
    (args: Partial<IGeneralPreferences>): AppThunk =>
    async (dispatch, getState) => {
        const { token, user, urlsByServices, currentModuleID } = getState().GeneralReducer;
        const { preferences } = getState().CabinetPreferencesReducer;
        const url = urlsByServices?.['app/app-backend']?.CABINET_PREFERENCES_URL;

        if (!token || !url || !user?.id || !preferences.id) return;

        patchRequest({
            url: `${url}${preferences.id}/`,
            token,
            data: mapFront2Back(cabinetPreferencesMapping, args),
        }).then(() => window.location.reload());
    };

export const patchSidebar =
    (args: Partial<ISidebarSettings>): AppThunk =>
    async (dispatch, getState) => {
        const { token, user, urlsByServices } = getState().GeneralReducer;
        const { preferences, sidebar } = getState().CabinetPreferencesReducer;
        const url = urlsByServices?.['app/app-backend']?.CABINET_PREFERENCES_URL;

        if (!token || !url || !user?.id || !preferences.id) return;
        dispatch(modifySidebar(args));

        patchRequest({
            url: `${url}${preferences.id}/`,
            token,
            data: { sidebar: { ...sidebar, ...args } },
        });
    };
