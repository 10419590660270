import axios, { AxiosError, AxiosResponse } from 'axios';
import { TRequestAverageIntersectionsArgs, IRequestAverageIntersectionsResponse } from './interfaces';
import { getHeaders } from './getHeaders';

const requestAverageIntersections = async (
    args: TRequestAverageIntersectionsArgs,
): Promise<
    | AxiosResponse<IRequestAverageIntersectionsResponse>
    | AxiosResponse<IRequestAverageIntersectionsResponse>[]
    | AxiosError<IRequestAverageIntersectionsResponse>
> => {
    if (Array.isArray(args)) {
        const requests = args.map((request) => {
            const { signal, token, time_range, main_obj_id, contra_obj_ids, url } = request;
            const headers = getHeaders(token);

            return axios({
                method: 'POST',
                signal,
                url,
                headers,
                data: {
                    input_parameters: {
                        contra_obj_ids: contra_obj_ids,
                        main_obj_id: main_obj_id,
                        time_range,
                    },
                },
            });
        });

        try {
            const response = await axios.all(requests).then(
                axios.spread((...res) => {
                    return res;
                }),
            );
            return response;
        } catch (err) {
            return err as AxiosError<IRequestAverageIntersectionsResponse>;
        }
    } else {
        const { signal, token, time_range, main_obj_id, contra_obj_ids, url } = args;
        const headers = getHeaders(token);

        try {
            const response = await axios({
                method: 'POST',
                headers,
                signal,
                url,
                data: {
                    input_parameters: {
                        contra_obj_ids: contra_obj_ids,
                        main_obj_id: main_obj_id,
                        time_range,
                    },
                },
            });

            return response;
        } catch (err) {
            return err as AxiosError<IRequestAverageIntersectionsResponse>;
        }
    }
};

export default requestAverageIntersections;
