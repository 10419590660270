import React, { useMemo } from 'react';
import { Menu, MenuTrigger, MenuList, MenuItem, MenuPopover } from '@fluentui/react-components';
import { LangValue, LangWrapper, useOverrides, Wrapper } from './styles';
import { Props } from './configurations';
import { TLang } from '../../../../General.interfaces';
import { flagIcons } from '../../../../assets/flagIcons';

/**
 * Компонент переключения языка
 */
const LangSelectMenu: React.FC<Props> = ({ lang, appCoreData, handleClickItem }) => {
    const overrides = useOverrides();
    const onItemClick = (key: string) => () => {
        handleClickItem(key as TLang);
    };

    /**
     * Конфигурация элементов переключения языка
     */
    const menuItems = useMemo(() => {
        if (appCoreData?.languages.length) {
            return appCoreData.languages.map((element) => {
                const lang = element.split('-')[0];
                return {
                    text: lang.charAt(0).toUpperCase() + lang.slice(1),
                    key: lang,
                };
            });
        }
        return [];
    }, [appCoreData]);

    return (
        <Wrapper>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <LangWrapper data-test-id={'LangSelect'}>
                        {lang && <div>{flagIcons[lang]}</div>}
                        <LangValue className="LangValue">{lang}</LangValue>
                    </LangWrapper>
                </MenuTrigger>
                <MenuPopover className={overrides.list}>
                    <MenuList>
                        {menuItems.map((item) => {
                            return (
                                <MenuItem
                                    className={overrides.item}
                                    style={{ minWidth: 'au' }}
                                    key={item.key}
                                    data-test-id={item.key}
                                    icon={<div>{flagIcons[item.key]}</div>}
                                    onClick={onItemClick(item.key)}
                                >
                                    {item.text}
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </Wrapper>
    );
};
export default LangSelectMenu;
