import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TPresets } from '../components/Preset/interfaces';
import { storeUserPresets } from '../components/Preset/reducer';
import { generalReducerValues } from '../General.reducer';
import { getRequest, postRequest } from '../tools/API/appBackendAPI';

/**
 * Временный Кастомный хук для пкрквода стврых пресетов на новые
 */
const useRelocatePresets = async () => {
    const dispatch = useDispatch();
    const { locations, user, urlsByServices, token } = useSelector(generalReducerValues);
    useEffect(() => {
        let presets: TPresets = localStorage.getItem('presets');
        if (presets && locations.length && user && urlsByServices && token) {
            try {
                const url = urlsByServices?.['app/app-backend'].USER_PRESETS_URL;
                presets = JSON.parse(presets);

                const newPresetsArr = Object.keys(presets as object).reduce((acc, key: any) => {
                    const module_id = key;
                    const arr = Object.keys(presets![module_id]).reduce((acc, key) => {
                        const locationID = Number(key);
                        const location = locations.find((item) => item.id === locationID);
                        const pl = [{ id: key, text: location?.name, section: '' }];

                        const arr1 = Object.entries(presets?.[module_id][String(locationID)])?.map((entry: any) => {
                            const [name, settings] = entry;
                            return { name, module_id, user_id: user.id, settings: { ...settings!, pl } };
                        });
                        return [...acc, ...arr1];
                    }, []);
                    return [...acc, ...arr];
                }, []);

                if (newPresetsArr.length) {
                    const requests = newPresetsArr.map((data) => {
                        return postRequest(url, token, data);
                    });
                    axios.all(requests).then(() => {
                        getRequest(`${url}?user_id=${user?.id}`, token).then((response) => {
                            dispatch(storeUserPresets(response.data));
                            localStorage.removeItem('presets');
                        });
                    });
                }
            } catch (error) {}
        }
    }, [localStorage.getItem('presets'), locations, user, urlsByServices, token]);
};

export default useRelocatePresets;
