import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    height: auto;
`;

export const MainValueWrapper = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MainValue = styled.div`
    font-weight: 600;
    white-space: nowrap;
    line-height: normal;
    font-size: 16px;
    color: var(--color-secondary-darker);
    display: inline-block;
`;
export const PercentDifferenceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 5px; */
    gap: 5px;
`;

export const PercentDifferenceValue = styled.div<{ color: string }>`
    color: ${(p) => p.color};
    font-size: 24px;
    line-height: normal;
`;

export const WidgetTitle = styled.div`
    color: var(--color-primary-darker);
    font-size: 1rem;
    margin-top: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    z-index: 20;
    position: relative;
`;

export const NumberWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const DaysOfTheWeekWrapper = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 3px;
`;

export const Label = styled.span<{ isRequired?: boolean }>`
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    max-width: max-content;
    &:after {
        content: '*';
        display: ${(p) => (p.isRequired ? 'inline-block' : 'none')};
        position: absolute;
        right: -8px;
        color: red;
        top: -3px;
        opacity: 1;
    }
`;

export const WorkingModeWrapper = styled.div`
    display: flex;
    span {
        font-weight: 500;
    }
`;
