import { ReactNode, useContext, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components/macro';

import { isTabletInboundWidth } from 'src/theme';

import { GeneralContext } from '../../General.context';
import { generalReducerValues, setOptionsPanelHeight, storeGeneralSearch } from '../../General.reducer';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { Container, ModuleOptionsWrapper, SearchWrapper } from './styles';
import Search from '../Search/Search';
import useTranslation from 'src/hooks/useTranslation/useTranslation';

interface IProps {
    children: ReactNode;
}

const PANEL_ANIMATION_TIME = 0.3;

const ModuleOptionsPanel = (props: IProps) => {
    const myRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mainAreaSize, generalSearch } = useSelector(generalReducerValues);
    const generalContext = useContext(GeneralContext);
    const direction = useScrollDirection({ ref: generalContext?.mainBoxRef, threshold: 10 });

    useLayoutEffect(() => {
        if (!myRef.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            const height = myRef?.current?.clientHeight;
            if (height) {
                dispatch(setOptionsPanelHeight(height));
            }
        });
        resizeObserver.observe(myRef.current);

        // const timeout = setTimeout(() => {

        // }, PANEL_ANIMATION_TIME);

        return () => {
            resizeObserver.disconnect();
            // clearTimeout(timeout);
        };
    }, [myRef.current, dispatch]);

    const isTablet = isTabletInboundWidth(mainAreaSize.width);

    const handleSearchChange = ({ value }: { value: string }) => {
        dispatch(storeGeneralSearch(value));
    };

    return (
        <ModuleOptionsWrapper scrollDirection={direction} isTablet={isTablet} ref={myRef}>
            {!isTablet && (
                <SearchWrapper>
                    <Search
                        maxLength={220}
                        placeholder={t('Search')}
                        handleChange={handleSearchChange}
                        fullWidth
                        outsideValue={generalSearch}
                    />
                </SearchWrapper>
            )}
            <Container>{props.children}</Container>
        </ModuleOptionsWrapper>
    );
};

export default ModuleOptionsPanel;
