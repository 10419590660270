import { DateTime } from 'luxon';

import { COLUMNS } from '../constants/constants';
import { TValidateFoo } from '../interfaces';

export const isNumbersInCell: TValidateFoo = ({ row }) => {
    if (
        row.every((item) => {
            return item.value ? isFinite(Number(item.value)) : true;
        })
    ) {
        return { isValid: true, text: '' };
    } else {
        const date = row[0].billData?.date;
        const data_object = row[0].billData?.data_object;
        return { isValid: false, text: `Not a number cell`, date, data_object };
    }
};

export const isValidDate: TValidateFoo = ({ row }) => {
    const dateCell = row.find((cell) => cell.colId === 'date');

    if (dateCell?.value) {
        if (
            DateTime.fromISO(dateCell.value).isValid ||
            (navigator.language.includes('ru') && DateTime.fromFormat(dateCell.value, 'dd.MM.yyyy').isValid) ||
            (navigator.language.includes('en') && DateTime.fromFormat(dateCell.value, 'MM.dd.yyyy').isValid)
        ) {
            return { isValid: true, text: '' };
        }
    }
    const data_object = row[0].billData?.data_object;
    return { isValid: false, text: `Invalid date format`, date: dateCell?.value || '', data_object };
};

export const isValidObjectId: TValidateFoo = ({ row, reportingObjectsById }) => {
    const objIdCell = row.find((cell) => cell.colId === 'data_object');
    const objId = objIdCell?.value !== null ? Number(objIdCell?.value) : null;

    if (objId !== null && isFinite(objId) && reportingObjectsById[objId]?.object_type === 'tenant') {
        return { isValid: true, text: '' };
    }
    const date = row[0].billData?.date;
    const data_object = row[0].billData?.data_object;
    return { isValid: false, text: `Invalid tenant id`, date, data_object };
};

export const isValidColumnsNumber: TValidateFoo = ({ row }) => {
    if (row.length === COLUMNS.length) {
        return { isValid: true, text: '' };
    }
    return { isValid: false, text: `Invalid columns number`, date: '' };
};
