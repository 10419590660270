import { isNumber, orderBy } from 'lodash';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';
import getSeriesTickInterval from './getSeriesTickInterval';

interface IArgs {
    series: Array<TDynamicsSeries>;
}

const alignSeriesXAxisByMain = (args: IArgs): Array<TDynamicsSeries> => {
    const { series } = args;
    const TICK_INTERVAL = getSeriesTickInterval({ series });

    const mainSeries = series.find((item) => Boolean(item['isMain'])) || series[0];

    if (mainSeries) {
        if ('data' in mainSeries && Array.isArray(mainSeries.data)) {
            // Получение значений x из главной серии
            const mainSeriesXValues: number[] = [];
            mainSeries.data.forEach((item: IExtendedChartPoint) => {
                if (isNumber(item.x)) {
                    mainSeriesXValues.push(item.x);
                }
            });

            return orderBy(series, (item) => Boolean(item.isMain), 'desc').map((item) => {
                const convertedData = item.data?.map((point: IExtendedChartPoint, index) => {
                    /**
                     * Так как у неглавных серий может быть больше значений, чем в основной,
                     * то искусственно добавляются новые значения в массив из значений
                     * оси X основной серии, путем добавления TICK_INTERVAL к последнему значению в массиве
                     */
                    if (index + 1 > mainSeriesXValues.length) {
                        const [lastXValue] = mainSeriesXValues.slice(-1);
                        mainSeriesXValues.push(lastXValue + TICK_INTERVAL);
                    }

                    return {
                        ...point,
                        x: mainSeriesXValues[index],
                        initialX: point.x,
                    };
                });
                return { ...item, data: convertedData };
            });
        }
    }
    return series;
};

export default alignSeriesXAxisByMain;
