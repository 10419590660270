import styled from 'styled-components/macro';

export const RatingsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-md);
    overflow: hidden;

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        grid-template-columns: 1fr;
    }
`;
