import { DateTime, Interval } from 'luxon';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { rawData } from '../../rawData';
import { Cases_Calculator_Calculator_Widget_Reducer_Values } from '../../reducer';
import { Cell, Row, TableWrapper } from './styles';
import { canShowOCR, namesMap, tenantsOptions } from '../../tenantsOptions';

const FinalTable: React.FC = () => {
    const { moduleName } = useSelector(Cases_Calculator_Calculator_Widget_Reducer_Values);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const tenantId = localCurrentOptions?.['tenant']?.[0]?.id;
    const discount = Number(localCurrentOptions?.[`discount`]);
    const discountPeriod = localCurrentOptions?.[`discountPeriod`];
    const mainPeriodId = localCurrentOptions?.mainPeriod?.id;
    const mainPeriod = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriodId)[0]?.period!;

    const rent = localCurrentOptions?.['rentInMonth'];
    const indexPercent = localCurrentOptions?.['indexPercent'];
    const indexingDate = localCurrentOptions?.['indexingDate'];

    const show = rawData[tenantId] && discount && canShowOCR(tenantId, rent, indexingDate, indexPercent);

    if (!show || !discountPeriod)
        return (
            <TableWrapper>
                <table>
                    <tbody>
                        <Row isTitleRow={true}>
                            <Cell isTitle={true} color={'#222529'} bgColor={'transparent'}>
                                {t('Recommended discount for the period, %')}
                            </Cell>
                            <Cell isTitle={true} color={'#222529'} bgColor={'transparent'}>
                                {'--'}
                            </Cell>
                        </Row>
                        <Row isTitleRow={true}>
                            <Cell isTitle={true} color={'#222529'} bgColor={'transparent'}>
                                {t('Loss in income at the recommended discount, ₽')}
                            </Cell>
                            <Cell isTitle={true} color={'#222529'} bgColor={'transparent'}>
                                {'--'}
                            </Cell>
                        </Row>
                    </tbody>
                </table>
            </TableWrapper>
        );

    const mainInterval = Interval.fromISO(`${mainPeriod.dateFrom}/${mainPeriod.dateTo}`);
    const discountInterval = Interval.fromISO(`${discountPeriod[0]?.text}/${discountPeriod[1].text}`);

    const inInterval = (date: string, interval: Interval): boolean => {
        return (
            interval.start !== null &&
            interval.end !== null &&
            DateTime.fromISO(date) <= interval.end &&
            DateTime.fromISO(date) >= interval.start
        );
    };

    const rows: any[][] = [];

    const profit = rawData[tenantId]['profit']
        ?.filter((item) => inInterval(item.date, mainInterval))
        .map((item) => ({ date: item.date, value: item[2023].value }));
    const sales = rawData[tenantId]['sales_no_vat']
        ?.filter((item) => inInterval(item.date, mainInterval))
        .map((item) => ({ ...item[2023], date: item.date }));

    const getPeriodDiscount = (tenantId: string) => {
        const salesSum =
            _.sum(sales?.filter((item) => inInterval(item.date, discountInterval)).map((item) => Number(item.value))) *
            tenantsOptions[tenantId].percent;
        const profitSum = _.sum(
            profit?.filter((item) => inInterval(item.date, discountInterval)).map((item) => Number(item.value)),
        );

        const length = sales?.filter((item) => inInterval(item.date, discountInterval)).length;

        let koeff = 0;

        if (length === 3 && discountPeriod[0]?.text === '2023-01-01') {
            koeff = tenantsOptions[tenantId].koeff_3;
        } else if (length === 6 && discountPeriod[0]?.text === '2023-01-01') {
            koeff = tenantsOptions[tenantId].koeff_6;
        }

        return koeff !== 0 ? Math.floor((salesSum / profitSum) * koeff) : 0;
    };

    const getPeriodLoss = (tenantId: string) => {
        const profitSum = _.sum(
            profit?.filter((item) => inInterval(item.date, discountInterval)).map((item) => Number(item.value)),
        );
        return (profitSum * getPeriodDiscount(tenantId)) / 100;
    };

    let row1: any[] = [
        { title: true, value: t('Recommended discount for the period, %') },
        { value: getPeriodDiscount(tenantId) ? getPeriodDiscount(tenantId).toFixed(0) + '%' : '--' },
    ];

    let row2: any[] = [
        { title: true, value: t('Loss in income at the recommended discount, ₽') },
        { value: valueFormatter({ value: getPeriodLoss(tenantId), precision: 0 }) },
    ];

    rows.push(row1, row2);

    const table = rows.map((row, j) => {
        let isTitleRow = false;
        const cells = row.map((cell, i) => {
            if (cell.title) {
                isTitleRow = true;
            }
            let color = '#222529';
            let bgColor = 'transparent';

            return (
                <Cell key={`${j}++${i}`} isTitle={cell.title} color={color} bgColor={bgColor}>
                    {cell.value}
                </Cell>
            );
        });
        return (
            <Row key={`${j}++row`} isTitleRow={isTitleRow}>
                {cells}
            </Row>
        );
    });

    return (
        <TableWrapper>
            <table>
                <tbody>{table}</tbody>
            </table>
        </TableWrapper>
    );
};

export default FinalTable;
