import { FC } from 'react';
import { IProps } from './interfaces';
import NetworkObjectsByLocationsOnBoardSelects from './components/NetworkObjectsByLocationsOnBoardSelects/NetworkObjectsByLocationsOnBoardSelects';

const OnBoardSelects: FC<IProps> = (props) => {
    return (
        <>
            {props.widgetType === 'networkObjectsByLocations' && <NetworkObjectsByLocationsOnBoardSelects {...props} />}
        </>
    );
};

export default OnBoardSelects;
