import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    flex: 0 0 100%;
    margin-right: var(--space-md);
    border: 1px solid #ebebeb;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    :last-child {
        margin-right: 0px;
    }
`;

export const Table = styled.table`
    width: 100%;
`;
