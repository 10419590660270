import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

import { ISheetCell } from '../../../../../General.interfaces';

const initialState: IReducerState = {
    downloadSignal: {},
    keyWord: 'Barthel',
};

export const Reports_Bartel_Widget_Reducer = createSlice({
    name: 'Reports_Bartel_Widget_Reducer',
    initialState,

    reducers: {
        /**
         * Сигнал на скачивание
         */
        downloadSignal: (state, action: PayloadAction) => {
            state.downloadSignal = {};
        },
    },
});

export const { downloadSignal } = Reports_Bartel_Widget_Reducer.actions;

export const Reports_Bartel_Widget_Reducer_Values = (state: RootState) => state.Reports_Bartel_Widget_Reducer;

export default Reports_Bartel_Widget_Reducer.reducer;
