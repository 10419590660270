import { DS } from '../../../../constants/constants';

export const CORRECTION_BILLS_QUERY_KEY = 'CORRECTION_BILLS_QUERY_KEY';
export const POST_CORRECTION_BILLS_QUERY_KEY = 'POST_CORRECTION_BILLS_QUERY_KEY';
export const METRIC_KEYS = ['sum_vat', 'sum_novat', 'bill_count'];
export const COLUMNS = [
    { colId: 'data_object', colName: 'Object id', readOnly: true },
    { colId: 'objName', colName: 'Object name', readOnly: true },
    { colId: 'date', colName: 'Date', readOnly: true },
    { colId: `income${DS}sum_vat`, colName: 'Sales (Inc. VAT)' },
    { colId: `income${DS}sum_novat`, colName: 'Sales (Ex. VAT)' },
    { colId: `income${DS}bill_count`, colName: 'Num of Receipts' },
    // { colId: `income_return${DS}sum_vat`, colName: 'Returns (Inc. VAT)' },
    // { colId: `income_return${DS}sum_novat`, colName: 'Returns (Ex. VAT)' },
    // { colId: `income_return${DS}bill_count`, colName: 'Num of Return Receipts' },
];
export const SUMMARY_CELL_WITH_DATA_COLOR_GREEN = 'hsl(134, 61%, 81%)';
export const SUMMARY_CELL_WITH_DATA_COLOR_YELLOW = 'hsl(60, 100%, 70%)';
