import axios, { AxiosError, AxiosResponse } from 'axios';
import { generalReducerValues } from '../../../General.reducer';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { userSettingsReducerValues } from '../../../components/UserSettings/reducer';
import requestStructure from '../requestStructure';
import {
    Network_Section_Reducer_Values,
    storeAllCategories,
    storeAllEvents,
    storeAllObjects,
    storeAllObjects2Categories,
} from '../../../Chapters/Network/reducer';
import { useQueries } from '@tanstack/react-query';
import { CategoriesAPI } from '../categoriesAPI';
import { ICategory, IDataObj2ProjectCategory, IReportingObject } from '../../../General.interfaces';
import { ILocation } from '../../../components/SideBar/configurations';
import { getRequest } from '../appBackendAPI';
import { IEvent } from '../../../Chapters/Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { useFetchGroupDataObjects } from './groupDataObjects/useFetchGroupDataObjects';
import { IStoreAllObjectsArgs } from '../../../Chapters/Network/interfaces';

export var NETWORK_GENERAL_QUERY_KEY = 'NETWORK_GENERAL_QUERY_KEY';

export const useNetworkQuery = () => {
    const dispatch = useDispatch();
    const { token, user, urlsByServices, locations, currentModuleID } = useSelector(generalReducerValues);
    const {
        src: { categories },
    } = useSelector(Network_Section_Reducer_Values);
    const allowedModules: string[] = ['Network:Locations overview', 'network_tenants_overview'];
    const isNetworkSection: boolean = allowedModules.includes(currentModuleID);

    useFetchGroupDataObjects();

    const { currentOptions } = useSelector(userSettingsReducerValues);
    const pls = currentOptions.pls ?? [];

    const plIds = pls.map((pl) => Number(pl.id));

    const selectedLocations = locations.filter((location) => plIds.includes(Number(location.id)));
    const selectedProjectId = Array.from(new Set(selectedLocations.map((pl) => pl.project_id)));

    const [structureUrl, categoriesUrl, eventsUrl] = useMemo(() => {
        if (urlsByServices?.['core/structure-service'])
            return [
                urlsByServices['core/structure-service'].CACHED_STRUCTURE_URL,
                urlsByServices['core/admin-service'].CATEGORIES_URL,
                urlsByServices['app/app-backend'].LOCATION_EVENTS_URL,
            ];
        return [];
    }, [urlsByServices]);

    //-----------------------------------------

    var accessors = {
        fetchObjects: function (pl: ILocation) {
            const objectsPromise = requestStructure({
                token,
                queries: [
                    {
                        pl_id: Number(pl.id),
                        structure_section: 'core',
                        structure_type: 'elements_admin_data_objects',
                    },
                ],
                jq: `map(.[]) | first`,
                url: structureUrl,
            });

            return axios.all([objectsPromise]);
        },
        fetchObjects2Categories: function (pl: ILocation) {
            const dataObj2ProjectCategoryPromise = CategoriesAPI({
                user,
                token,
                chapter: 'data-obj-2-project-category',
                method: 'GET',
                urlParams: { project_id: pl.project_id, project_location_id: pl.id },
                url: categoriesUrl,
            });

            return axios.all([dataObj2ProjectCategoryPromise]);
        },
        fetchCategories: function (id: string | number | null) {
            return CategoriesAPI({
                user,
                token,
                chapter: 'project-categories',
                method: 'GET',
                urlParams: { project_id: id },
                url: categoriesUrl,
            });
        },
        fetchEvents: function (id: string | number | null) {
            const url = `${eventsUrl}?event_type__pl_id=${id}`;
            return getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                return response['data'];
            });
        },
    };

    var adapters = {
        objectsAdapter: function (response: AxiosResponse, plId: string | number | null): { data: IReportingObject[] } {
            return response;
        },
        objects2CategoriesAdapter: function (
            response: AxiosResponse,
            plId: string | number | null,
        ): { data: IDataObj2ProjectCategory[] } {
            return response;
        },
        categoriesAdapter: function (response: AxiosResponse, prId: string | number | null): { data: ICategory[] } {
            return response;
        },
        eventsAdapter: function (response: IEvent[], plId: number): (IEvent & { plId: number })[] {
            return response.map((item) => ({ ...item, plId }));
        },
    };

    var objectsData = useQueries({
        queries: [
            ...selectedLocations?.map((pl) => {
                return {
                    queryKey: [NETWORK_GENERAL_QUERY_KEY, 'objects', pl.id],
                    queryFn: () => accessors.fetchObjects(pl),
                    enabled: isNetworkSection,
                    select: (response: AxiosResponse) => adapters.objectsAdapter(response, pl.id),
                };
            }),
        ],
    });

    var eventsData = useQueries({
        queries: [
            ...selectedLocations?.map((pl) => {
                return {
                    queryKey: [NETWORK_GENERAL_QUERY_KEY, 'events', pl.id],
                    queryFn: () => accessors.fetchEvents(pl.id),
                    enabled: isNetworkSection,
                    select: (response: IEvent[]) => adapters.eventsAdapter(response, pl.id),
                };
            }),
        ],
    });

    var objects2CategoriesData = useQueries({
        queries: [
            ...selectedLocations?.map((pl) => {
                return {
                    queryKey: [NETWORK_GENERAL_QUERY_KEY, 'objects2Categories', pl.id],
                    queryFn: () => accessors.fetchObjects2Categories(pl),
                    enabled: isNetworkSection,
                    select: (response: AxiosResponse) => adapters.objects2CategoriesAdapter(response, pl.id),
                };
            }),
        ],
    });

    var categoriesData = useQueries({
        queries: [
            ...selectedProjectId.map((prId) => {
                return {
                    queryKey: [NETWORK_GENERAL_QUERY_KEY, 'categories', prId],
                    queryFn: () => accessors.fetchCategories(prId),
                    enabled: isNetworkSection,
                    select: (response: AxiosResponse) => adapters.categoriesAdapter(response, prId),
                };
            }),
        ],
    });

    useEffect(() => {
        if (objectsData.length && objectsData.every((item) => item.data !== undefined)) {
            const reportingObjects = objectsData.reduce((acc, item) => {
                if (item?.data?.[0]?.['data']) {
                    return [...acc, ...item?.data?.[0].data];
                } else {
                    return acc;
                }
            }, []);

            dispatch(storeAllObjects({ reportingObjects } as IStoreAllObjectsArgs));
        }
    }, [JSON.stringify(objectsData)]);

    useEffect(() => {
        if (eventsData.length && eventsData.every((item) => item.data !== undefined)) {
            const events = eventsData.reduce((acc, item) => {
                if (item?.data) {
                    return [...acc, ...item?.data];
                } else {
                    return acc;
                }
            }, []);

            dispatch(storeAllEvents(events));
        }
    }, [JSON.stringify(eventsData)]);

    useEffect(() => {
        if (
            objects2CategoriesData.length &&
            categories.length &&
            objects2CategoriesData.every((item) => item.data !== undefined)
        ) {
            const objects2Categories = objects2CategoriesData.reduce((acc, item) => {
                if (item?.data?.[0]?.['data']) {
                    return [...acc, ...item?.data?.[0].data];
                } else {
                    return acc;
                }
            }, []);

            dispatch(storeAllObjects2Categories(objects2Categories));
        }
    }, [JSON.stringify(objects2CategoriesData), categories]);

    useEffect(() => {
        if (categoriesData.length && categoriesData.every((item) => item.data !== undefined)) {
            const categories = categoriesData.reduce((acc, item) => {
                if (item?.data?.['data']) {
                    return [...acc, ...item?.data.data];
                } else {
                    return acc;
                }
            }, []);

            dispatch(storeAllCategories(categories));
        }
    }, [JSON.stringify(categoriesData)]);
};
