import { useMemo } from 'react';

import { IDateRange } from '../../General.interfaces';
import { useWidgetCurrentOptions } from '../useWidgetCurrentOptions';

/**
 * Кастомный хук для для получения периодов по текущему модуля
 */
const usePeriods = (moduleId: string): { mainPeriod: IDateRange | null; comparePeriods: IDateRange[] } => {
    const localCurrentOptions = useWidgetCurrentOptions(moduleId);

    const [mainPeriod, comparePeriods] = useMemo(() => {
        const mainPeriod =
            localCurrentOptions?.mainDateRanges?.find(
                (item) => item.id === localCurrentOptions?.mainPeriod?.id && item.period,
            ) || null;

        const comparePeriods: IDateRange[] = [];

        localCurrentOptions?.comparePeriods?.forEach((comparePeriod) => {
            const period = localCurrentOptions?.compareDateRanges?.find((element) => element.id === comparePeriod.id);

            if (period?.period) comparePeriods.push(period);
        });

        return [mainPeriod, comparePeriods];
    }, [localCurrentOptions]);

    return { mainPeriod, comparePeriods };
};

export default usePeriods;
