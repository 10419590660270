import { isNull, isUndefined } from 'lodash';
import { IdsOfFunnelSettings } from '../../../../../constants/constants';

interface IArgs {
    funnelSettings: IdsOfFunnelSettings;
    mainValue?: number | null;
    prevValue?: number | null;
    locationValue?: number | null;
}

const getPercentage = (args: IArgs): number | '—' => {
    const { funnelSettings, mainValue, prevValue, locationValue } = args;

    if (funnelSettings === IdsOfFunnelSettings.PercentageOfTheMall) {
        if (!isNumberValid(locationValue) || !isNumberValid(mainValue)) return '—';
        const result = getPercentageOfNumber(mainValue, locationValue);
        return isNumberValid(result) ? result : '—';
    }

    if (funnelSettings === IdsOfFunnelSettings.PercentageOfThePreviousStep) {
        if (!isNumberValid(mainValue) || !isNumberValid(prevValue)) return '—';
        const result = getPercentageOfNumber(mainValue, prevValue);
        return isNumberValid(result) ? result : '—';
    }

    return '—';
};

const isNumberValid = (value: unknown): value is number =>
    !isUndefined(value) && !isNull(value) && isFinite(Number(value));
const getPercentageOfNumber = (a: number, b: number): number => (a * 100) / b;

export default getPercentage;
