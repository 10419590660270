import styled from 'styled-components/macro';
import { TSpinnerLabelPosition, TSpinnerSize } from './interfaces';
import {
    SPINNER_DIMENSIONS_BY_SIZE,
    SPINNER_WRAPPER_STYLES_BY_LABEL_POSITION,
    SPINNER_WRAPPER_STYLES_BY_TYPE,
} from './constants/constants';

export const SpinnerWrapper = styled.div<{ size: TSpinnerSize }>`
    width: ${(p) => `${SPINNER_DIMENSIONS_BY_SIZE[p.size]?.width}px`};
    height: ${(p) => `${SPINNER_DIMENSIONS_BY_SIZE[p.size]?.height}px`};
`;

export const Wrapper = styled.div<{ labelPosition: TSpinnerLabelPosition; type: string }>`
    display: flex;
    gap: ${(p) => SPINNER_WRAPPER_STYLES_BY_TYPE[p.type]?.gap || '10px'};
    align-items: center;
    flex-direction: ${(p) => SPINNER_WRAPPER_STYLES_BY_LABEL_POSITION[p.labelPosition]?.flexDirection};
`;

export const SpinnerLabel = styled.label`
    font-weight: 500;
    font-size: 16px;
    color: #242424;
`;
