import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';

import { ISheetCell } from '../../../../../General.interfaces';
import { IReducerState, TRawMetricsData } from './interfaces';

const initialState: IReducerState = {
    grid: [],
    downloadSignal: {},
    rawMetricsData: null,
    keyWord: 'Comparison',
};

export const Reports_Comparison_Widget_Reducer = createSlice({
    name: 'Reports_Comparison_Widget_Reducer',
    initialState,

    reducers: {
        /**
         * Сигнал на скачивание
         */
        downloadSignal: (state, action: PayloadAction) => {
            state.downloadSignal = {};
        },
        /**
         * Сохранение табличных данных
         */
        storeGrid: (state, action: PayloadAction<ISheetCell[][]>) => {
            state.grid = action.payload;
        },
        /**
         * Сохранение сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },
        /**
         * Обнуление редьюсера
         */
        resetReducer: (state) => {
            state.rawMetricsData = null;
            state.grid = [];
        },
    },
});

export const { downloadSignal, storeGrid, resetReducer, storeRawMetricsData } =
    Reports_Comparison_Widget_Reducer.actions;

export const Reports_Comparison_Widget_Reducer_Values = (state: RootState) => state.Reports_Comparison_Widget_Reducer;

export default Reports_Comparison_Widget_Reducer.reducer;
