import styled from 'styled-components/macro';
import hex2hsl from '../../../../../tools/hex2hls';
import setHslLightness from '../../../../../tools/setHslLightness';

export const Wrapper = styled.div<{ hex: string }>`
    padding-top: 40px;
    background: ${(p) =>
        `linear-gradient(to left, ${setHslLightness(hex2hsl(p.hex), 70)}, ${setHslLightness(hex2hsl(p.hex), 90)})`};
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* margin-top: calc(70px); */
`;

export const EventTypeImage = styled.img`
    z-index: 1;
    position: absolute;
    top: 0px;
    right: 20px;
    z-index: 0;
    /* transform: scale(80%); */

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        width: 330px;
        right: 0;
        top: 10px;
    }
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        display: none;
    }
`;

export const ContentWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const EventNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const EventName = styled.h1`
    font-size: 2.125rem;
    line-height: 2.5rem;
    margin: 0;
    font-weight: 500;
    margin-right: 15px;
    display: inline-block;
`;

export const EventTypeColor = styled.div<{ color: string }>`
    flex: 0 0 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
`;

export const EventPeriod = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
`;

export const ReportingObjects = styled.div`
    margin-top: 15px;
    font-size: 1rem;
    span {
        dispaly: inline-block;
        margin-right: 10px;
        font-weight: 600;
    }
`;

export const OptionsWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top: 25px;
`;

export const Comment = styled.div`
    z-index: 2;
    margin-top: 15px;
    font-size: 1rem;
    /* color: var(--color-secondary-darker); */
    span {
        dispaly: inline-block;
        margin-right: 10px;
        font-weight: 600;
    }
`;

export const MainContentWrapper = styled.div`
    z-index: 2;
    position: relative;
`;

export const TextInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 50%;

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        max-width: 70%;
    }

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        max-width: 100%;
    }
`;
