import {
    IDataObj2ProjectCategory,
    IPlace2ZoneRelation,
    IProjectCategory,
    ISheetCell,
    ITenant2PlaceRelation,
} from '../../../../../../General.interfaces';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { generalReducerValues } from '../../../../../../General.reducer';
import { stringDate } from '../../../../../../tools/Strings/stringDate';
import { IPreparedTenantData, ITenantCurrentDateData, ITenantsReportAdditionalFieldOption } from '../interfaces';
import getDateColumnDataByDetail from '../tools/getDateColumnDataByDetail';
import { storeGrid, Reports_ByTenants_Widget_Reducer_Values } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';
import generateGridRow from '../tools/generateGridRow';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { AdditionalFieldsIds } from '../../../../interfaces';
import { TTimeFreq } from '../../../../../../tools/API/interfaces';
import { tenantsReportAdditionalFieldsOptionsById } from '../../../constants/constants';
import { DS } from '../../../../../../constants/constants';
import _ from 'lodash';

/**
 * Кастомный хук для получения самой таблицы
 */
const useGridGenerator = (): void => {
    const { preparedGridData, keyWord } = useSelector(Reports_ByTenants_Widget_Reducer_Values);
    const {
        allMetrics,
        lang,
        structures,
        currentModuleID,
        cfg: { projectCategoriesById, dataObj2ProjectCategoryByObjId },
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const rest = `${DS}${keyWord}`;
        const metrics = localCurrentOptions?.[`selectedMetrics${rest}`];
        const objects = localCurrentOptions?.[`selectedReportingObjectsIds${rest}`];
        const detail = localCurrentOptions?.[`detail${rest}`];
        const additionalFields: undefined | ITenantsReportAdditionalFieldOption[] =
            localCurrentOptions?.[`additionalFields${rest}`];

        if (localCurrentOptions && preparedGridData && metrics && objects && detail && structures) {
            const metricsData: { [metricName: string]: string } = metrics.reduce((acc: any, value: any) => {
                const name = allMetrics?.find((element) => element.id === value)?.text || value;
                const units = allMetrics?.find((m) => m.id === value)?.units || '';
                acc[value] = `${t(name, { ns: 'metrics' })}, ${t(units)}`;
                return acc;
            }, {});

            const selectedGroup = localCurrentOptions['mainZoneGroup']?.[0]?.id;

            const grid: ISheetCell[][] = [];
            const mainPeriodDateRnage = localCurrentOptions?.[`mainDateRanges${rest}`]?.find(
                (element: { id: string }) => element.id === localCurrentOptions?.[`mainPeriod${rest}`]?.id,
            );

            const headerDateColumnData = getDateColumnDataByDetail(
                (detail?.id || null) as TTimeFreq,
                `${mainPeriodDateRnage?.period.dateFrom} - ${mainPeriodDateRnage?.period.dateTo}`,
            );

            const parentCategories = Object.values(projectCategoriesById).filter((value) => {
                return !value.parent_id && value.is_active;
            });

            const headerRow = generateGridRow({
                additionalFields: additionalFields || [],
                timeZone: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.AddTimeZone].columnName),
                placeName: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.AddPlace].columnName),
                weekday: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.AddWeekDay].columnName),
                zonesNames: [t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.AddZone].columnName)],
                floor: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.AddFloor].columnName),
                categories: parentCategories,
                showWeekDay: detail?.id === null ? false : true,
                mainPeriod: t(headerDateColumnData.columnHeader),
                metricsData,
                tenantName: t('Tenant'),
                isFieldReadOnly: true,
                tenantId: t('Id'),
                area: t('Area'),
                isHeader: true,
                allMetrics,
                selectedMetrics: metrics,
            });
            /** хеддер */

            headerRow && grid.push(headerRow);

            /** тело таблицы */
            _.sortBy(Object.keys(preparedGridData), (tenantId: string) => {
                const { tenantData }: IPreparedTenantData = preparedGridData[tenantId];
                return tenantData?.name || '';
            }).forEach((tenantId: string) => {
                const { tenantData, tenantTableDataByDate }: IPreparedTenantData = preparedGridData[tenantId];
                const tenantCategories: (IProjectCategory | null)[] = [];

                // Связи арендатора с категориями
                const currentTenantCategoryRelations = dataObj2ProjectCategoryByObjId[tenantId] as
                    | undefined
                    | IDataObj2ProjectCategory[];

                // Итерация по родительским категориям
                parentCategories.forEach((parentCategory) => {
                    if (currentTenantCategoryRelations) {
                        // Поиск связка для родительской категории
                        const parentCategoryTenantRelation = currentTenantCategoryRelations.find((tenantCategory) => {
                            return projectCategoriesById[tenantCategory.category_id]?.parent_id === parentCategory.id;
                        });
                        // Если связка найдена добавляем категорию в массив
                        if (parentCategoryTenantRelation) {
                            tenantCategories.push(projectCategoriesById[parentCategoryTenantRelation.category_id]);
                        } else {
                            tenantCategories.push(null);
                        }
                    } else {
                        tenantCategories.push(null);
                    }
                });

                Object.keys(tenantTableDataByDate).forEach((date) => {
                    const currentDate = DateTime.fromISO(date);

                    const metricDateColumnData = getDateColumnDataByDetail((detail?.id || null) as TTimeFreq, date);

                    const { metricsData, additionalFieldsData }: ITenantCurrentDateData = tenantTableDataByDate[date];
                    const convertedMetricData = Object.keys(metricsData).reduce((acc, value) => {
                        acc[value] = metricsData[value];
                        return acc;
                    }, {});

                    if (additionalFieldsData['relations_tenant2place'].length) {
                        additionalFieldsData['relations_tenant2place'].forEach(
                            (tenant2place: ITenant2PlaceRelation) => {
                                const place2ZoneRelation: IPlace2ZoneRelation[] | undefined =
                                    structures[`relations_place2zone`];

                                const suitableZones = place2ZoneRelation?.filter((place2zone: IPlace2ZoneRelation) => {
                                    const place2zoneDateFrom = DateTime.fromISO(place2zone.date_from);
                                    const place2zoneDateTo = DateTime.fromISO(place2zone.date_to);

                                    let flag = false;
                                    if (
                                        tenant2place.place_marker === place2zone.place_marker &&
                                        currentDate >= place2zoneDateFrom &&
                                        currentDate <= place2zoneDateTo &&
                                        (!selectedGroup ||
                                            selectedGroup === 'All' ||
                                            place2zone.group_marker === selectedGroup)
                                    ) {
                                        flag = true;
                                    }
                                    return flag;
                                });
                                if (suitableZones?.length) {
                                    const zonesNames = suitableZones.map((zone: IPlace2ZoneRelation) => zone.zone_name);

                                    const row = generateGridRow({
                                        showWeekDay: detail?.id === null ? false : true,
                                        additionalFields: additionalFields || [],
                                        selectedMetrics: metrics,
                                        mainPeriod:
                                            detail?.id === null
                                                ? headerDateColumnData.columnValue
                                                : metricDateColumnData.columnValue,
                                        placeName: tenant2place.place_name,
                                        zonesNames,
                                        floor: String(tenant2place.floor),
                                        metricsData: convertedMetricData,
                                        tenantId: String(tenantData.id),
                                        area: String(tenant2place.area),
                                        categories: tenantCategories,
                                        timeZone: tenantData.timezone,
                                        tenantName: tenantData.name,
                                        isFieldReadOnly: false,
                                        weekday:
                                            detail?.id === null
                                                ? stringDate(headerDateColumnData.columnValue, lang, '', 'EEEE')
                                                : stringDate(date, lang, '', 'EEEE'),
                                        allMetrics,
                                    });
                                    row && grid.push(row);
                                } else {
                                    const row = generateGridRow({
                                        showWeekDay: detail?.id === null ? false : true,
                                        additionalFields: additionalFields || [],
                                        selectedMetrics: metrics,
                                        mainPeriod:
                                            detail?.id === null
                                                ? headerDateColumnData.columnValue
                                                : metricDateColumnData.columnValue,
                                        placeName: tenant2place.place_name,
                                        floor: String(tenant2place.floor),
                                        metricsData: convertedMetricData,
                                        categories: tenantCategories,
                                        tenantId: String(tenantData.id),
                                        area: String(tenant2place.area),
                                        timeZone: tenantData.timezone,
                                        tenantName: tenantData.name,
                                        isFieldReadOnly: false,
                                        zonesNames: ['--'],
                                        weekday:
                                            detail?.id === null
                                                ? stringDate(headerDateColumnData.columnValue, lang, '', 'EEEE')
                                                : stringDate(date, lang, '', 'EEEE'),
                                        allMetrics,
                                    });
                                    row && grid.push(row);
                                }
                            },
                        );
                    } else {
                        const row = generateGridRow({
                            showWeekDay: detail?.id === null ? false : true,
                            additionalFields: additionalFields || [],
                            selectedMetrics: metrics,
                            mainPeriod:
                                detail?.id === null
                                    ? headerDateColumnData.columnValue
                                    : metricDateColumnData.columnValue,
                            metricsData: convertedMetricData,
                            tenantId: String(tenantData.id),
                            timeZone: tenantData.timezone,
                            categories: tenantCategories,
                            tenantName: tenantData.name,
                            isFieldReadOnly: false,
                            floor: String('--'),
                            placeName: '--',
                            zonesNames: ['--'],
                            area: '--',
                            weekday:
                                detail?.id === null
                                    ? stringDate(headerDateColumnData.columnValue, lang, '', 'EEEE')
                                    : stringDate(date, lang, '', 'EEEE'),
                            allMetrics,
                        });
                        row && grid.push(row);
                    }
                });
            });

            dispatch(storeGrid(grid));
        }
    }, [
        localCurrentOptions?.[`additionalFields${DS}${keyWord}`],
        localCurrentOptions?.['mainZoneGroup'],
        preparedGridData,
        t,
        lang,
        structures,
    ]);
};

export default useGridGenerator;
