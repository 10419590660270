import React, { useRef } from 'react';
import { Props, Size } from './configurations';

import cn from 'classnames';
import * as constants from '../../constants/constants';
import useStyles from '../stylesHooks/useStyles';

const Icon: React.FC<Props> = ({ size = Size.Base, type = 'arrow-left', ...props }) => {
    const ref = useRef<SVGSVGElement>(null);
    const mergedStyles = useStyles({ ref, props, constants });

    const className = React.useMemo(() => {
        const cssClassAlign: string = '';
        const { DEFAULT_CLASS_ICON_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } = constants;
        const cssClassSize = `${DEFAULT_CLASS_ICON_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${size}`;
        return cn([DEFAULT_CLASS_ICON_PREFIX, cssClassSize, cssClassAlign, ...mergedStyles]);
    }, [props, size]);

    return (
        <svg ref={ref} className={className}>
            <use href={`#${type}`} />
        </svg>
    );
};

export default Icon;
