import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { IProps } from '../interfaces';
import { useRequestMetrics } from '../../../../../../../../../tools/API/hooks/useRequestMetrics';
import { IPeriodObj, TMetricResponse } from '../../../../../../../../../General.interfaces';
import metricsDataAccessor, {
    IMetricsDAArgs,
} from '../../../../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { IRequestMetricsArgs } from '../../../../../../../../../tools/API/interfaces';
import defaultDataAdapter from './dataAdapter';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { cabinetPreferencesValues } from '../../../../../../../../CabinetPreferences/reducer';
import { TABLE_REFERENCE_BY_LOCATION_QUERY_KEY } from '../../../constants/constants';
import { useWidgetCurrentOptions } from 'src/hooks';

interface IArgs extends IProps {}

const useFetchData = (args: IArgs) => {
    const { token, currentModuleID } = useSelector(generalReducerValues);
    const fetchData = useRequestMetrics({ isNetwork: args.isNetwork });

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        const obj_ids = args.reportingObjects.map((item) => item.id).sort();

        const generateRequestArgs = (options: { alias: string; period: IPeriodObj }): IRequestMetricsArgs => {
            return {
                time_range: [options.period.dateFrom, options.period.dateTo],
                object_aggregation: false,
                alias: options.alias,
                metric: args.metric,
                time_freq: null,
                obj_ids,
                token,
            };
        };

        const requestArgs: IRequestMetricsArgs[] = [];

        requestArgs.push(generateRequestArgs({ alias: 'main', period: args.mainPeriod }));

        const dataAccessorArgs: IMetricsDAArgs = {
            requestArgs,
            fetchData,
        };

        return [
            [
                TABLE_REFERENCE_BY_LOCATION_QUERY_KEY,
                args.moduleId,
                args.widgetId,
                args.metric,
                args.mainPeriod,
                obj_ids,
            ],
            () => metricsDataAccessor(dataAccessorArgs),
            (response: TMetricResponse[]) => defaultDataAdapter(response, args.reportingObjectId),
        ];
    }, [args, token, fetchData]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter) && Boolean(localCurrentOptions),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;
