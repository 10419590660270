import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem } from '../../styles';
import { MAIN_RATING_INDICATORS } from '../../../../constants/constants';
import {
    ISelectProps,
    ISelectedOption,
    TSelectedOptions,
} from '../../../../../../../../../components/Selects/Select/interfaces';
import Select from '../../../../../../../../../components/Selects/Select/Select';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { useSelector } from 'react-redux';

const SelectMainIndicator: React.FC<
    ISelectProps & { selectedId: string | undefined; handleSelectValue: (selects: TSelectedOptions) => void }
> = ({ handleSelectValue, selectedId = '', ...props }) => {
    const [selectedMainIndicator, setSelectedMetrics] = useState<TSelectedOptions>([]);
    const { t } = useTranslation();
    const { currentModuleID } = useSelector(generalReducerValues);
    const options = useMemo(() => {
        return _.cloneDeep(MAIN_RATING_INDICATORS).map((item) => {
            return { ...item, text: t(item.text) };
        });
    }, [t]);

    const handleSelect = (args: TSelectedOptions) => {
        if (args.length) {
            setSelectedMetrics([args[0]]);
            handleSelectValue(args);
        }
    };

    const metricSelect = useMemo(() => {
        let outsideSelected: TSelectedOptions = [];
        const outsideSelect = options?.find((x: ISelectedOption) => x.id === selectedId);
        if (outsideSelect) {
            outsideSelected = [outsideSelect];
        } else {
            outsideSelected = [options[0]];
        }

        return (
            <Select
                testId={`${currentModuleID}_Build rating by`}
                options={options}
                showFilter={false}
                labelText={t('Build rating by')}
                iconType={'chevron-down'}
                mode="single"
                fullWidth={props.fullWidth}
                shadow="md"
                handleSelect={handleSelect}
                outsideSelected={outsideSelected}
            />
        );
    }, [selectedMainIndicator, options, t, selectedId, props.fullWidth]);

    return <FormItem>{metricSelect}</FormItem>;
};
export default SelectMainIndicator;
