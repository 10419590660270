import Filter from './components/Filter/Filter';
import { DataObjectsFilter } from '../DataObjectsFilter';
import { IFilterDataObjectsArgs, IGetComponentProps, IGetFilterDataArgs } from './interfaces';
import { ReactNode } from 'react';
import { IDataObjectsFloorFilterState, IDataObjectsSelectFilter, TDataObjectsFilterTypes } from '../../interfaces';
import { IReportingObject } from '../../../../General.interfaces';
import { dateRangesFilter } from '../../../../tools/filterValidDateRanges';
import { cloneDeep } from 'lodash';

/**
 * Класс, реализующий логику работы фильтра этажей
 */
export class DataObjectsFloorsFilter implements DataObjectsFilter {
    name: TDataObjectsFilterTypes = 'data_objects_floor';
    active = false;

    getFilterData(args: IGetFilterDataArgs): IDataObjectsSelectFilter {
        const { rawData, floors } = args;

        const floorsByFloor: { [floor: string]: { active: boolean; name: string } } | undefined = (
            rawData?.filter_state as IDataObjectsFloorFilterState
        )?.floors?.reduce((acc, value) => {
            acc[value.name] = value;
            return acc;
        }, {});

        const extendedFloors = floors?.map((element) => {
            const state = floorsByFloor?.[element.marker];

            return {
                name: element.marker,
                active: state ? state.active : true,
            };
        }, []);

        return {
            filter_type: this.name,
            active: rawData?.active || this.active,
            filter_state: { floors: extendedFloors || [] },
        };
    }

    getComponent(props: IGetComponentProps): ReactNode {
        return <Filter {...props} />;
    }

    filterDataObjects(args: IFilterDataObjectsArgs): { [reportingObjectType: string]: IReportingObject[] } {
        const { reportingObjectsByType, mainDateRanges, dataObjects, filterState, mainPeriod, tenant2FloorByTenantId } =
            args;

        const mainPeriodDateRange = mainDateRanges?.find((element) => element.id === mainPeriod?.id)?.period;
        const result = cloneDeep(reportingObjectsByType);

        if (mainPeriodDateRange && dataObjects) {
            const availableFloors =
                filterState.floors?.filter((element) => element.active).map((element) => element.name) || [];

            const filteredDataObjectsMarkers: string[] = [];

            dataObjects.forEach((item) => {
                if (dateRangesFilter(item, mainPeriodDateRange) && availableFloors.includes(String(item.floor))) {
                    filteredDataObjectsMarkers.push(item.marker);
                }
            });

            Object.keys(reportingObjectsByType).forEach((reportingObjectType) => {
                const filteredReportingObjects = reportingObjectsByType[reportingObjectType].filter(
                    (reportingObject) => {
                        switch (reportingObject.block_type) {
                            case 'project_category':
                                return true;

                            default:
                                switch (reportingObject.object_type) {
                                    case 'tenant':
                                        if (tenant2FloorByTenantId[reportingObject.id]?.length) {
                                            let flag = false;

                                            tenant2FloorByTenantId[reportingObject.id].forEach((item) => {
                                                if (
                                                    dateRangesFilter(item, mainPeriodDateRange) &&
                                                    availableFloors.includes(String(item.floor))
                                                ) {
                                                    flag = true;
                                                    return;
                                                }
                                            });
                                            return flag;
                                        }

                                        return false;

                                    default:
                                        return filteredDataObjectsMarkers.includes(reportingObject.marker);
                                }
                        }
                    },
                );
                result[reportingObjectType] = filteredReportingObjects;
            });
        }

        return result;
    }
}
