import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../Tooltip/Tooltip';
import IconButton from '../../../IconButton/IconButton';
import { DocumentTable24Regular } from '@fluentui/react-icons';

interface CSVDownloadProps {
    onDownload?: () => void;
    tableName?: string;
}

/**
 * Компонент для отображения кнопки с загрузкой xlsx файла
 */
export const CSVDownload: FC<CSVDownloadProps> = ({ tableName, onDownload }) => {
    const { t } = useTranslation();

    const onClick = () => {
        console.log('excel icon click');
    };

    const tooltipText = tableName ? `${t('Download CSV')} (${tableName})` : t('Download CSV');

    return (
        <Tooltip content={tooltipText}>
            <IconButton onClick={onDownload || onClick} icon={<DocumentTable24Regular />} />
        </Tooltip>
    );
};
