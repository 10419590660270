import { FC, useMemo } from 'react';
import { IProps } from './interfaces';
import { DS } from '../../../../../../constants/constants';
import ObjectsSelect from '../../../../../Selects/ObjectsSelect/ObjectsSelect';
import UniversalSelect from '../../../../../Selects/UniversalSelect/UniversalSelect';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';

/**
 * Селект выбора отчетного объекта в зависимости от
 * типа микро виджета
 */
const PieChartObjectsSelect: FC<IProps> = ({ widgetConfig, updateWidgetOptions }) => {
    const {
        src: { projectCategories },
        currentModuleID,
    } = useSelector(generalReducerValues);

    const widgetType = widgetConfig?.type?.split(DS)?.[1];

    const distribByCatsOptions = useMemo(() => {
        return (
            projectCategories
                ?.filter((item) => !item.parent_id)
                ?.map((item) => ({ id: item.id, text: item.object_name })) || []
        );
    }, [projectCategories]);

    if (widgetType === 'categories') {
        return (
            <ObjectsSelect
                localCurrentOptions={{ ...widgetConfig?.options, currentModuleID }}
                name={`mainObject`}
                changeOptions={updateWidgetOptions}
                validOptions={['categories']}
                maxSelected={1}
            />
        );
    }

    if (widgetType === 'distribByCats') {
        return (
            <UniversalSelect
                localCurrentOptions={{ ...widgetConfig?.options, currentModuleID }}
                changeOptions={updateWidgetOptions}
                options={distribByCatsOptions}
                name={`selectedCategories`}
                labelText={'Categories'}
                maxWidth={130}
                mode="single"
            />
        );
    }
    return null;
};

export default PieChartObjectsSelect;
