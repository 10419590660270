import React from 'react';
import { useSelector } from 'react-redux';
import EventTypes from './widgets/EventTypes/EventTypes';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { Configuration_EventsConfig_Module_Redcuer_Values } from './reducer';
import EventsManagement from './widgets/EventsManagement/EventsManagement';
import EventsFileUpload from './widgets/EventsFileUpload/EventsFileUpload';

const EventsConfig: React.FC = () => {
    const { selectedWidgetKey } = useSelector(Configuration_EventsConfig_Module_Redcuer_Values);

    return (
        <ModuleWrapper>
            <WrapperContainer style={{ maxWidth: 'none' }}>
                {selectedWidgetKey === 'eventsManagement' && <EventsManagement />}
                {selectedWidgetKey === 'eventsFileUpload' && <EventsFileUpload />}
                {selectedWidgetKey === 'eventTypes' && <EventTypes />}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default EventsConfig;
