import { FC } from 'react';
import { IDefaultSelectProps } from '../../interfaces';
import UniversalSelect from '../../../../../Selects/UniversalSelect/UniversalSelect';
import { IChangeOptionsArgs } from '../../../../../UserSettings/interfaces';
import { OBJECTS_LOCATIONS_USE_METRICS_FROM_OPTIONS } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { changeRequiredParams } from '../../../../../../Dashboards.reducer';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';

const UseMetricFromSelect: FC<IDefaultSelectProps> = (props) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updateWidgetVisual = (visualParamKey: string) => (args: IChangeOptionsArgs) => {
        props.updateWidget('visual', { [visualParamKey]: args.newOptions?.[visualParamKey] });

        const newChoice = args.newOptions[visualParamKey];
        if (visualParamKey === 'useMetricFrom' && props.widgetConfig?.id) {
            if (Array.isArray(newChoice) && newChoice.length) {
                const [data] = newChoice;
                const paramsMap = { useMetricFrom: 'selectedSingleMetric' };
                const mode = data.id === 'fromPanel' ? 'add' : 'remove';

                dispatch(
                    changeRequiredParams({
                        param: paramsMap[visualParamKey],
                        id: props.widgetConfig?.id,
                        dashboardKey: props.dashboardKey,
                        moduleId: props.moduleId,
                        mode,
                    }),
                );
            }
        }
    };

    return (
        <UniversalSelect
            mode="single"
            labelText={t('Use metric from')}
            name="useMetricFrom"
            options={OBJECTS_LOCATIONS_USE_METRICS_FROM_OPTIONS}
            localCurrentOptions={{ ...props.widgetConfig?.visual, currentModuleID } || {}}
            changeOptions={updateWidgetVisual('useMetricFrom')}
        />
    );
};

export default UseMetricFromSelect;
