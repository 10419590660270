import { DateTime } from 'luxon';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';

interface IArgs {
    series: Array<TDynamicsSeries>;
}

const alignSeriesByWeekday = (args: IArgs): Array<TDynamicsSeries> => {
    const { series } = args;
    const mainSeriesXAxisValues: number[] =
        (series.find((item) => Boolean(item.isMain)) || series[0]).data?.map((item: IExtendedChartPoint) => {
            return item.x as number;
        }) || [];

    const newSeries = series.map((element) => {
        if (
            DateTime.fromMillis(mainSeriesXAxisValues[0])?.weekday !==
            DateTime.fromMillis((element.data as IExtendedChartPoint[])?.[0]?.['initialX'])?.weekday
        ) {
            const daysCounter =
                DateTime.fromMillis(mainSeriesXAxisValues[0])?.weekday -
                DateTime.fromMillis((element.data as IExtendedChartPoint[])?.[0]['initialX']).weekday;

            element.data = element.data?.map((item: IExtendedChartPoint) => ({
                ...item,
                x: DateTime.fromMillis(item.x as number)
                    .minus({ days: daysCounter < 0 ? 7 + daysCounter : daysCounter })
                    .toMillis(),
            }));
        }

        return element;
    });

    return newSeries;
};

export default alignSeriesByWeekday;
