import React, { FC, useEffect, useState } from 'react';
import { IProps } from './interfaces';
import { Td, Tr } from '../../styles';
import { IReportingObject } from '../../../../../../../../General.interfaces';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import DataCell from '../DataCell/DataCell';
import sortHandler from './tools/sortHandler';
import { DS } from '../../../../../../../../constants/constants';

/**
 * Тело таблицы
 */
const Body: FC<IProps> = (props) => {
    const [sortedReportingObjectIds, setSortedReportingObjectIds] = useState<number[]>([]);

    const { t } = useTranslation();

    /** Сортировка */
    useEffect(() => {
        if (props.orderingData) {
            setSortedReportingObjectIds(
                sortHandler({
                    reportingObjects: props.reportingObjects,
                    orderingData: props.orderingData,
                    moduleId: props.moduleId,
                    widgetId: props.widgetId,
                    mode: props.mode,
                }),
            );
        } else {
            setSortedReportingObjectIds(props.reportingObjects.map((item) => item.id));
        }
    }, [props.mode, props.moduleId, props.orderingData, props.reportingObjects, props.widgetId]);

    return (
        <tbody>
            {sortedReportingObjectIds.map((reportingObjectId) => {
                const reportingObject = props.reportingObjects.find((item) => item.id === reportingObjectId) as
                    | undefined
                    | IReportingObject;

                return (
                    <Tr key={reportingObjectId}>
                        <Td>{reportingObject?.name || t('Unknown reporting object')}</Td>
                        {props.metrics.map((metric) => {
                            return (
                                <React.Fragment key={`${reportingObjectId}${DS}${metric}`}>
                                    {[props.mainPeriod, ...props.comparePeriods].map((period, index) => {
                                        return (
                                            <DataCell
                                                key={`${reportingObjectId}${DS}${metric}${DS}${JSON.stringify(
                                                    period,
                                                )}${DS}${index}`}
                                                reportingObjectId={reportingObjectId}
                                                metric={metric}
                                                period={{
                                                    range: period,
                                                    type: index === 0 ? 'main' : 'compare',
                                                }}
                                                {...props}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </Tr>
                );
            })}
        </tbody>
    );
};

export default Body;
