import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import axios from 'axios';
import { TLang } from '../../../../General.interfaces';
import { TLoading } from '../../interfaces';
import { storeNewAlert } from '../../../../components/Alert/reducer';
import { DS } from '../../../../constants/constants';
import { TTimeFreq } from '../../../../tools/API/interfaces';
import { DateTime, Interval } from 'luxon';
import { cabinetPreferencesValues } from 'src/components/CabinetPreferences/reducer';

export interface IReportParams {
    reportAlias: string;
    useBusinessDays: 'yes' | 'no';
    xToken: string;
    language: TLang;
    plId?: number;
    currencyCode: number;
    mainDateRange?: string[];
    compareDateRanges?: (string | undefined)[][];
    objectIds?: number[];
    years?: number[];
    compareObjectsIds?: number[];
    metrics?: string[];
    tableSettings?: string[];
    colorSettings?: string[];
    detail?: string | null;
    additionalFields?: string[];
    pageLayout?: string;
    mainZoneGroup?: string;
}

const checkCorrectTimeFreq = (time_range: string[] | undefined, time_freq: string | null | undefined): boolean => {
    if (!time_range) return false;

    const start = DateTime.fromISO(time_range[0]);
    const end = DateTime.fromISO(time_range[1]).plus({ days: 1 });

    const interval = Interval.fromDateTimes(start, end);
    const subIntervals = interval.splitBy({ days: 1 });
    const monthsStarts = subIntervals.reduce((acc, subInt) => {
        const d = subInt.start;
        return d?.day === 1 ? acc + 1 : acc;
    }, 0);

    if (time_freq === 'MS' && monthsStarts === 0) {
        return false;
    } else return true;
};

/**
 * Кастомный хук для загрузки XLS отчета при срабатывании триггера
 */
export const useGenerateBackXLS = (
    counter: number,
    currentModuleID: string,
    reportFileName: string,
    keyWord?: string,
) => {
    const { token, lang, currencyCode } = useSelector(generalReducerValues);
    const {
        preferences: { useBusinessDays },
    } = useSelector(cabinetPreferencesValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const [status, setStatus] = useState<TLoading>('Download');
    const dispatch = useDispatch();

    const commonSelectNames = ['mainPeriod', 'comparePeriods', 'pl', 'reportFormat'];

    useEffect(() => {
        if (counter === 0 || !localCurrentOptions) return;

        setStatus(() => 'Loading...');

        const reportAlias = keyWord ? `${currentModuleID}:${keyWord}` : currentModuleID;

        const reportParams: IReportParams = {
            reportAlias,
            xToken: token,
            language: lang,
            currencyCode,
            useBusinessDays,
        };

        const mainDateRange = localCurrentOptions?.[
            keyWord ? `mainDateRanges${DS}${keyWord}` : 'mainDateRanges'
        ]?.filter(
            (item: { id: string }) =>
                item.id === localCurrentOptions?.[keyWord ? `mainPeriod${DS}${keyWord}` : 'mainPeriod']?.id,
        )[0];

        Object.entries(localCurrentOptions)
            .filter((entry) => {
                const [key, value] = entry;
                if (!keyWord) {
                    return true;
                } else {
                    return key.includes(keyWord) || commonSelectNames.includes(key);
                }
            })
            .forEach((entry) => {
                const [key, value] = entry;
                const justKey = key.split(DS)[0];

                switch (justKey) {
                    case 'mainPeriod':
                        const time_range = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];
                        reportParams.mainDateRange = time_range;
                        break;

                    case 'comparePeriods':
                        const compareDateRanges = localCurrentOptions?.[
                            keyWord ? `comparePeriods${DS}${keyWord}` : 'comparePeriods'
                        ]?.map((item: { id: string }) => {
                            const range = localCurrentOptions?.[
                                keyWord ? `compareDateRanges${DS}${keyWord}` : 'compareDateRanges'
                            ]?.find((r: { id: string }) => r.id === item.id)?.period;
                            return [range?.dateFrom, range?.dateTo];
                        });
                        if (compareDateRanges) {
                            reportParams.compareDateRanges = compareDateRanges;
                        }
                        break;

                    case 'pl':
                        reportParams.plId = Number(value?.[0]?.id);
                        break;

                    case 'mainZoneGroup':
                        reportParams.mainZoneGroup = value?.[0]?.id;
                        break;

                    case 'selectedReportingObjectsIds':
                        reportParams.objectIds = value;
                        break;

                    case 'mainObjects':
                        reportParams.objectIds = value.map((item: any) => item.id);
                        break;

                    case 'compareObjects':
                        reportParams.compareObjectsIds = value?.map((item: any) => item.id);
                        break;

                    case 'years':
                    case 'tableSettings':
                    case 'colorSettings':
                    case 'additionalFields':
                    case 'metrics':
                        reportParams[justKey] = value?.map((item: any) => item.id);
                        break;

                    case 'selectedMetrics':
                        reportParams.metrics = value;
                        break;

                    case 'detail':
                        reportParams.detail = value?.id;
                        break;

                    case 'reportFormat':
                        reportParams.pageLayout = value?.[0]?.id;
                        break;

                    default:
                        break;
                }
            });

        const headers = {
            'Content-type': 'application/json',
        };

        if (!checkCorrectTimeFreq(reportParams.mainDateRange, reportParams.detail)) {
            setStatus(() => 'Download');
            dispatch(
                storeNewAlert({ type: 'warning', text: 'Incorrect detail', duration: 5, alias: 'Incorrect detail' }),
            );
        }

        checkCorrectTimeFreq(reportParams.mainDateRange, reportParams.detail) &&
            axios({
                method: 'POST',
                url: 'https://d5dcvjg4i3p24aocdtqj.apigw.yandexcloud.net',
                data: reportParams,
                headers,
            })
                .then((res) => {
                    const downloadURL = res.data.url;

                    axios({
                        method: 'GET',
                        url: downloadURL,
                        responseType: 'arraybuffer',
                        headers: { 'Content-Type': 'blob' },
                    })
                        .then((res) => {
                            const url = window.URL.createObjectURL(new Blob([res?.['data']]));
                            const link = document.createElement('a');

                            link.href = url;
                            link.setAttribute('download', `${reportFileName}.xlsx`);

                            document.body.appendChild(link);
                            link.click();

                            link.remove();
                            setStatus(() => 'Download');
                            dispatch(
                                storeNewAlert({
                                    type: 'success',
                                    text: `Report downloaded`,
                                    duration: 15,
                                }),
                            );
                        })
                        .catch(() => {
                            dispatch(
                                storeNewAlert({
                                    type: 'error',
                                    text: `Report downloading Error`,
                                    duration: 30,
                                }),
                            );
                            setStatus(() => 'Download');
                        });
                })
                .catch(() => {
                    dispatch(
                        storeNewAlert({
                            type: 'error',
                            text: `Report downloading Error`,
                            duration: 30,
                        }),
                    );
                    setStatus(() => 'Download');
                });
    }, [counter]);

    return status;
};
