import RatingItem from '../Rating/Rating';
import React from 'react';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { Performance_Dashboard_ByArea_Widget_Reducer_Values } from '../../reducer';
import { RatingsWrapper } from './styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useFetchData from '../../api/useFetchData';
import { IDefaultDAResponse, ITreeMapData } from '../../interfaces';
import { ResponseStatus } from '../../../../../../../tools/API/constants';
import useFilterAndExtendTenants from '../../hooks/useFilterAndExtendTenants';

interface IProps {
    itemsToShow: number | 'all';
}

/**
 * Компонент для отображения рейтингов для отчетных объектов выбранного типа
 */
const Ratings: React.FC<IProps> = ({ itemsToShow }) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { colorMetric, objectsType, selectedParentId, tenantsView } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const { isFetching, error } = useFetchData();
    const extendedData = useFilterAndExtendTenants();

    const { t } = useTranslation();
    const metricName = allMetrics?.filter((item) => item.id === colorMetric)?.[0]?.text;

    const list = (trend: string, selectedParentId: string | null, extendedData: IDefaultDAResponse | undefined) => {
        const seriesPoints: undefined | ITreeMapData[] = extendedData?.treeMapOptions?.series?.[0]?.['data'];

        if (isFetching) {
            return ResponseStatus.Loading;
        }
        if (error) {
            return ResponseStatus.Error;
        }
        if (Array.isArray(seriesPoints)) {
            const list = seriesPoints
                ?.filter((item) => {
                    let flag = true;
                    if (!isFinite(item.colorValue)) {
                        flag = false;
                    }
                    if (objectsType === 'tenant' && tenantsView.id !== 'all') {
                        if (selectedParentId) {
                            flag = item.parent ? item.parent === selectedParentId : false;
                        } else if (!item.objectType) {
                            flag = false;
                        }
                    }
                    if (trend === 'Growing') {
                        flag = Number(item.colorValue) >= 0 && flag;
                    } else {
                        flag = Number(item.colorValue) < 0 && flag;
                    }
                    return flag;
                })
                .sort((a, b) => Math.abs(Number(b.colorValue)) - Math.abs(Number(a.colorValue)));

            return list;
        }
        return ResponseStatus.NoData;
    };

    return (
        <RatingsWrapper>
            <RatingItem
                itemsToShow={itemsToShow}
                data={list('Growing', selectedParentId, extendedData)}
                title={`${t('Growing')} ${t('by metric')} "${t(metricName, { ns: 'metrics' })}"`}
            />
            <RatingItem
                itemsToShow={itemsToShow}
                data={list('Falling', selectedParentId, extendedData)}
                title={`${t('Falling')} ${t('by metric')} "${t(metricName, { ns: 'metrics' })}"`}
            />
        </RatingsWrapper>
    );
};

export default Ratings;
