import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Td } from '../../styles';
import { IProps } from './interfaces';
import useFetchData from './api/useFetchData';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import { Value } from './styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { IValueFormatter, valueFormatter } from '../../../../../../../../tools/Strings/valueFormatter';
import Stack from '../../../../../../../Stack/Stack';
import { ObjByMeticsTableViewOptionIds } from '../../../../enums';
import { useIsFetching } from '@tanstack/react-query';
import { TABLE_QUERY_KEY } from './constants/constants';
import getPercentOfTheMain from './tools/getPercentOfTheMain';
import { getColorFromPercent } from '../../../../../../../../tools/getColorFromPercent';

/**
 * Ячейка с данными по метрики для отчетного объекта
 */
const DataCell: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { data, isFetching, isError } = useFetchData(props);
    const [percentOfTheMain, setPercentOfTheMain] = useState<number | null>(null);
    const isFetchingMainPeriod = useIsFetching({
        queryKey: [TABLE_QUERY_KEY, props.moduleId, props.widgetId, 'main', props.metric, props.mainPeriod],
    });

    const { t } = useTranslation();
    const metricFromStore = allMetrics.find((item) => item.id === props.metric);

    useEffect(() => {
        if (!isFetchingMainPeriod && data && props.period.type === 'compare') {
            setPercentOfTheMain(
                getPercentOfTheMain({
                    compareValue: data.value,
                    mainPeriodQueryKey: [
                        TABLE_QUERY_KEY,
                        props.moduleId,
                        props.widgetId,
                        'main',
                        props.metric,
                        props.mainPeriod,
                    ],
                    reportingObjectId: props.reportingObjectId,
                }),
            );
        } else {
            setPercentOfTheMain(null);
        }
    }, [isFetchingMainPeriod, data, props]);

    const [valueFormatterData, valueProps]: [IValueFormatter, { color?: string; fontWeight?: string }] = useMemo(() => {
        var value: null | number = null;
        var units: undefined | string = metricFromStore?.units;
        var showUnits: boolean = false;
        var precision: number | undefined = metricFromStore?.round_decimal_places;
        var color: undefined | string;
        var fontWeight: undefined | string;
        if (props.period.type === 'main') {
            value = data?.value || null;
            fontWeight = '500';
        }

        if (props.period.type === 'compare') {
            if (props.mode === ObjByMeticsTableViewOptionIds.ShowAbsoluteValues) {
                value = data?.value || null;
            }

            if (
                props.mode === ObjByMeticsTableViewOptionIds.ShowRelativeValues ||
                props.mode === ObjByMeticsTableViewOptionIds.ShowAll
            ) {
                color = getColorFromPercent(percentOfTheMain);
                value = percentOfTheMain;
                showUnits = true;
                precision = 1;
                units = '%';
            }
        }

        return [
            {
                precision,
                showUnits,
                value,
                units,
            },
            { color, fontWeight },
        ];
    }, [
        metricFromStore?.units,
        metricFromStore?.round_decimal_places,
        props.period.type,
        props.mode,
        data?.value,
        percentOfTheMain,
    ]);

    // if (props.period.type === 'main' && props.mode === ObjByMeticsTableViewOptionIds.ShowRelativeValues) {
    //     return null;
    // }

    if (isFetching) {
        return (
            <Td>
                <Stack alignItems={'center'} justifyContent={'flex-end'}>
                    <span>{t('Loading...')}</span>
                </Stack>
            </Td>
        );
    }

    if (isError) {
        return (
            <Td>
                <Stack alignItems={'center'} justifyContent={'flex-end'}>
                    <span>{t('An error accrued while getting data')}</span>
                </Stack>
            </Td>
        );
    }

    if (!data) {
        return (
            <Td>
                <Stack alignItems={'center'} justifyContent={'flex-end'}>
                    <span>{t('No data')}</span>;
                </Stack>
            </Td>
        );
    }

    return (
        <Td bgColor={props.period.type === 'main' ? 'var(--color-secondary-light)' : 'inherit'}>
            <Stack alignItems={'center'} justifyContent={'flex-end'}>
                <Value {...valueProps}>{valueFormatter(valueFormatterData)}</Value>
            </Stack>
        </Td>
    );
};

export default memo(DataCell);
