import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues, storeAllMetrics, storeCurrencyCode } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { currenciesMap } from '../../../constants/currencies';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';

const useCurrencies = () => {
    const { locations, rawMetrics, selectedLocationId, currentModuleID } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const dispatch = useAppDispatch();

    /**
     * Проставление валют в метрики
     */
    useEffect(() => {
        if (locations.length && selectedLocationId && rawMetrics.length) {
            let currencyCode: number = locations.find((item) => item.id === selectedLocationId)?.currency_code ?? 0;

            // Если сетевая секция, то берем валюту из первой в списке локации
            if (currentModuleID.split(':')[0] === 'Network') {
                const baseCurrencyPL = Number(localCurrentOptions?.['pls']?.[0].id);
                currencyCode = locations.find((item) => item.id === baseCurrencyPL)?.currency_code ?? 0;
            }

            const currencySymbol: string =
                currencyCode && currenciesMap[currencyCode] ? currenciesMap[currencyCode].symbol : '₽';

            const metrics = rawMetrics.map((element) => ({
                ...element,
                units: element.units.replace('currency', currencySymbol),
            }));

            dispatch(storeCurrencyCode(currencyCode));
            dispatch(storeAllMetrics(metrics));
        }
    }, [locations, selectedLocationId, currentModuleID, rawMetrics, dispatch, localCurrentOptions?.['pls']]);
};

export default useCurrencies;
