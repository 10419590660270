import { XAxisPlotBandsOptions } from 'highcharts';
import { IArgs as IDataAdapterArgs } from '../../api/dataAdapter';
import getPlotBandEventsForMetricResponseItem from './getPlotBandEventsForMetricResponseItem';
import generatePlotBandsForWeekends from './generatePlotBandsForWeekends';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import { uniqBy } from 'lodash';

interface IArgs extends Omit<IDataAdapterArgs, 'getObjNameById' | 'weatherResponse' | 'allMetrics' | 'chartType'> {
    series: TDynamicsSeries[];
}

const generateFinalPlotBands = (args: IArgs): XAxisPlotBandsOptions[] => {
    const plotBands: XAxisPlotBandsOptions[] = [];

    if (args.viewSettings?.showEvents) {
        const eventsPlotBands: XAxisPlotBandsOptions[] = [];
        args.response.forEach((metricResponse) => {
            metricResponse.forEach((metricResponseItem) => {
                eventsPlotBands.push(
                    ...getPlotBandEventsForMetricResponseItem({
                        eventTypes: args.eventTypes,
                        events: args.events,
                        metricResponseItem,
                        lang: args.lang,
                        t: args.t,
                    }),
                );
            });
        });
        plotBands.push(...uniqBy(eventsPlotBands, (item) => item.id));
    }

    if (args.viewSettings?.showWeekends && args.detail.id === 'D') {
        plotBands.push(...generatePlotBandsForWeekends({ series: args.series }));
    }

    return plotBands;
};

export default generateFinalPlotBands;
