import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Title, Modal, Header, Body, GlobalStyle, CloseIconWrapper, Overlay } from './Modals.styles';
import { IModalProps } from './Modals.interfaces';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { Portal } from '@fluentui/react-components';

/**
 * Обертка для всех модалок.
 * @param modalStatus Состояние модалки, управляемое извне.
 * @param title Название модалки.
 */
const Modals: React.FC<IModalProps> = ({ modalStatus, topColor, closeModal, title, ...props }) => {
    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (overlayRef.current?.getBoundingClientRect()) {
            const { x, y } = overlayRef.current?.getBoundingClientRect();
            overlayRef.current.style.left = `${-x}px`;
            overlayRef.current.style.top = `${-y}px`;
        }
    }, [overlayRef, modalStatus.show]);

    useEffect(() => {
        function listener(e: globalThis.KeyboardEvent) {
            if (e.code === 'Escape') closeModal();
        }
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, []);

    const onModalClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    if (!modalStatus.show) return null;

    return (
        <Portal>
            <Overlay onClick={closeModal} isModalOpen={modalStatus.show} ref={overlayRef}>
                <GlobalStyle isModalOpen={modalStatus.show} />
                <Modal topColor={topColor} onClick={onModalClick}>
                    <Header>
                        <Title>{title}</Title>
                        <CloseIconWrapper onClick={closeModal}>
                            <Dismiss24Regular />
                        </CloseIconWrapper>
                    </Header>
                    <Body>{props.children}</Body>
                </Modal>
            </Overlay>
        </Portal>
    );
};

export default Modals;
