import { IObject, TNestedOptions } from '../interfaces';
import { isSimple } from './getOptionsType';

export const getFilteredOptions = (options: IObject[] | TNestedOptions, filter: string) => {
    if (isSimple(options)) {
        return options?.filter(
            (option) => filter === '' || option.text.toLowerCase().includes(filter.toLowerCase()),
        ) as IObject[];
    } else {
        return options
            .map((item) => {
                const objects = item.objects?.filter(
                    (option) => filter === '' || option.text.toLowerCase().includes(filter.toLowerCase()),
                );
                return { ...item, objects };
            })
            ?.filter((item) => item.objects.length) as TNestedOptions;
    }
};
