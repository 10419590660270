import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { cabinetPreferencesValues } from '../../../../CabinetPreferences/reducer';
import { useMemo } from 'react';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../tools/API/hooks/useRequestMetrics';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../../tools/API/interfaces';
import {
    IDataObj2ProjectCategory,
    IPeriodObj,
    IProjectCategory,
    TMetricResponse,
} from '../../../../../General.interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { DS } from '../../../../../constants/constants';
import { useQuery } from '@tanstack/react-query';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import piechartDefaultDataAdapter from '../dataAdapters/piechartDefaultDataAdapter';
import { omit } from 'lodash';
import { TWidgetConfig } from '../interfaces';

interface IArgs {
    /** Конфигурация виджета */
    widgetConfig: TWidgetConfig;
    /** ID текущего модуля */
    moduleId: string;
    /** Ключ текущего dashboard */
    dashboardKey: string | null;
    /** Размеры виджета */
    widgetDimensions: null | { width: number; height: number } | undefined;
}

const useFetchData = (args: IArgs) => {
    const { widgetConfig, moduleId, dashboardKey, widgetDimensions } = args;
    const {
        token,
        allMetrics,
        cfg: { dataObj2ProjectCategoryByCatId },
        src: { projectCategories },
    } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(moduleId);
    const fetchData = useRequestMetrics();
    const { t } = useTranslation(['translation', 'translationCategories']);

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        const mainDatePeriod = localCurrentOptions?.mainDateRanges?.find(
            (item) => item.id === localCurrentOptions?.mainPeriod?.id,
        )?.period;

        const compareDatePeriods: IPeriodObj[] = [];

        localCurrentOptions?.comparePeriods?.forEach((comparePeriod) => {
            const period = localCurrentOptions?.compareDateRanges?.find(
                (element) => element.id === comparePeriod.id,
            )?.period;

            if (period) compareDatePeriods.push(period);
        });

        if (
            widgetConfig?.options?.metrics?.length &&
            widgetConfig?.sources?.length &&
            widgetConfig?.id &&
            mainDatePeriod &&
            dashboardKey &&
            moduleId &&
            token
        ) {
            switch (widgetConfig.sources[0]) {
                case 'default': {
                    const requestArgs: IRequestMetricsArgs[] = [];

                    const widgetType = widgetConfig.type.split(DS)?.[1];

                    const objIds = getObjIds({
                        dataObj2ProjectCategoryByCatId,
                        options: widgetConfig.options,
                        projectCategories,
                        widgetType,
                    });

                    const mainPeriodArgs = prepareArgs({
                        timeRange: [mainDatePeriod.dateFrom, mainDatePeriod.dateTo],
                        alias: `mainPeriod${DS}${widgetConfig.id}`,
                        metric: widgetConfig.options?.metrics?.[0],
                        timeFreq: null,
                        objIds,
                        token,
                    });

                    switch (widgetType) {
                        case 'distribByCats': {
                            if (compareDatePeriods.length) {
                                const comparePeriodArgs = prepareArgs({
                                    timeRange: [compareDatePeriods[0].dateFrom, compareDatePeriods[0].dateTo],
                                    alias: `comparePeriod${DS}${widgetConfig.id}`,
                                    metric: widgetConfig.options?.metrics?.[0],
                                    timeFreq: null,
                                    objIds,
                                    token,
                                });
                                requestArgs.push(comparePeriodArgs);
                            }
                            break;
                        }
                        case 'categories':
                        default:
                            break;
                    }

                    requestArgs.push(mainPeriodArgs);

                    const args: IMetricsDAArgs = {
                        requestArgs,
                        fetchData,
                    };
                    return [
                        [
                            'default',
                            'piechart',
                            objIds,
                            mainDatePeriod,
                            moduleId,
                            dashboardKey,
                            omit(widgetConfig, ['title']),
                            widgetType === 'distribByCats' ? compareDatePeriods : null,
                        ],
                        () => metricsDataAccessor(args),
                        (response: TMetricResponse[]) =>
                            piechartDefaultDataAdapter({ widgetDimensions, response, allMetrics, widgetType, t }),
                    ];
                }

                default:
                    return [[]];
            }
        }
        return [[]];
    }, [
        localCurrentOptions?.mainDateRanges,
        localCurrentOptions?.comparePeriods,
        localCurrentOptions?.mainPeriod,
        localCurrentOptions?.compareDateRanges,
        widgetConfig,
        dashboardKey,
        moduleId,
        token,
        dataObj2ProjectCategoryByCatId,
        projectCategories,
        fetchData,
        allMetrics,
        t,
    ]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter) && Boolean(localCurrentOptions),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
        retry: 2,
    });
};

export default useFetchData;

interface IPrepareArgs {
    timeRange: [string, string];
    timeFreq: TTimeFreq;
    objIds: number[];
    metric: string;
    alias: string;
    token: string;
}

const prepareArgs = (args: IPrepareArgs): IRequestMetricsArgs => {
    const { timeRange, alias, metric, objIds, token, timeFreq } = args;
    return {
        object_aggregation: false,
        time_range: timeRange,
        time_freq: timeFreq,
        obj_ids: objIds,
        metric,
        alias,
        token,
    };
};

interface IGetObjIdsArgs {
    dataObj2ProjectCategoryByCatId: {
        [catId: string]: IDataObj2ProjectCategory[];
    };
    projectCategories: IProjectCategory[];
    widgetType: string | undefined;
    options:
        | {
              [x: string]: any;
          }
        | undefined;
}

const getObjIds = (args: IGetObjIdsArgs): number[] => {
    const { widgetType, options, dataObj2ProjectCategoryByCatId, projectCategories } = args;

    if (widgetType) {
        switch (widgetType) {
            case 'categories': {
                const selectedSubCategoryId: undefined | number = options?.['mainObject']?.[0];
                return (
                    (selectedSubCategoryId &&
                        dataObj2ProjectCategoryByCatId?.[selectedSubCategoryId]?.map((item) => item.data_object_id)) ||
                    []
                );
            }
            case 'distribByCats': {
                const selectedCategoryId: undefined | number = options?.selectedCategories?.[0]?.id;

                return (
                    projectCategories
                        ?.filter((item) => item.parent_id === selectedCategoryId)
                        ?.map((item) => item.id) || []
                );
            }

            default:
                return [];
        }
    }

    return [];
};
