import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Preset from '../../../../../components/Preset/Preset';
import DetailSelect from '../../../../../components/Selects/DetailSelect/DetailSelect';
import MainPeriodSelect from '../../../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../../../components/Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../../../components/Selects/ObjectsSelect/ObjectsSelect';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { ISettings } from '../../../../../components/UserSettings/interfaces';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { PresetsWrapper } from '../../../../../components/Wrappers/PresetsWrapper/styles';
import { SelectsWrapper } from '../../../../../components/Wrappers/SelectsWrapper/styles';
import { DS } from '../../../../../constants/constants';

import { generalReducerValues } from '../../../../../General.reducer';
import { Reports_Basic_Widget_Reducer_Values } from './reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import LayoutSelect from '../../../components/LayoutSelect/LayoutSelect';
import { AdditionalFieldsIds } from '../../../interfaces';
import { additionalFieldsOptions } from '../../constants/constants';

const BasicOptions = () => {
    const { currentModuleID, user } = useSelector(generalReducerValues);
    const { keyWord } = useSelector(Reports_Basic_Widget_Reducer_Values);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    return (
        <>
            <SelectsWrapper>
                <MainPeriodSelect
                    name={`mainPeriod${DS}${keyWord}`}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <ObjectsSelect
                    canSelectEntireSection={true}
                    name={`selectedReportingObjectsIds${DS}${keyWord}`}
                    dataObjectFilters={['data_objects_floor', 'data_objects_passway_type', 'data_objects_tenant']}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <MetricsSelect
                    name={`selectedMetrics${DS}${keyWord}`}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <DetailSelect
                    name={`detail${DS}${keyWord}`}
                    restrictedOptions={['15MIN']}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <UniversalSelect
                    defaultSelected={additionalFieldsOptions?.filter(
                        (element) => element.id === AdditionalFieldsIds.AddId,
                    )}
                    name={`additionalFields${DS}${keyWord}`}
                    labelText={t('Additional settings')}
                    options={additionalFieldsOptions}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <LayoutSelect name={`reportFormat${DS}${keyWord}`} />
            </SelectsWrapper>
            <PresetsWrapper>
                <Preset
                    currentOptionsKeys={[
                        `mainPeriod${DS}${keyWord}`,
                        `selectedReportingObjectsIds${DS}${keyWord}`,
                        `selectedMetrics${DS}${keyWord}`,
                        `detail${DS}${keyWord}`,
                        `reportFormat${DS}${keyWord}`,
                        `additionalFields${DS}${keyWord}` as keyof ISettings,
                    ]}
                />
            </PresetsWrapper>
        </>
    );
};

export default BasicOptions;
