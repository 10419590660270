import React, { useEffect, useState } from 'react';
import { HeaderContainer, IconContainer } from './styles';
import Icon from '../../../../../../../components/Icon/Icon';

interface IProps {
    /** Название рейтинга */
    text: string;
    /** Обработчик нажатия на шапку */
    handleHeaderClick: (x: boolean) => void;
}

/**
 * Компонент для отображения шапки рейтинга
 */
const Header: React.FC<IProps> = ({ text, handleHeaderClick }) => {
    let asc: boolean = true;
    const [ascStatus, setAscSort] = useState(asc);
    useEffect(() => {
        setAscSort(() => asc);
    }, [asc]);

    const onHeaderClick = () => {
        handleHeaderClick(!ascStatus);
        setAscSort(!ascStatus);
    };
    return (
        <HeaderContainer onClick={onHeaderClick}>
            {text}
            <IconContainer ascStatus={ascStatus}>
                <Icon size="xxs" type="caret-down" color="secondary-darker" />
            </IconContainer>
        </HeaderContainer>
    );
};
export default Header;
