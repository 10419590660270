import styled from 'styled-components/macro';
import { Star20Filled } from '@fluentui/react-icons';

export const BodyTr = styled.tr<{ selectedColor: string | null }>`
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    cursor: pointer;
    background: ${({ selectedColor }) => selectedColor || 'white'};
    :hover {
        background: ${({ selectedColor }) => selectedColor || 'rgba(42, 126, 236, 0.1)'};
    }
`;

export const BodyTd = styled.td`
    padding: 8px 8px;
    vertical-align: right;
    :first-child {
        padding-left: 20px;
    }

    :last-child {
        padding-right: 20px;
    }
`;

export const BodyIntersectionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const PercentValue = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin-right: 10px;
    white-space: nowrap;
`;

export const PaiwiseValue = styled.div`
    font-size: 12px;
    color: #abafb3;
    font-weight: 500;
    white-space: nowrap;
`;

export const BodyDefaultText = styled.div`
    font-size: 12px;
    text-align: right;
`;

export const SubHeaderIntersectionsDataWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 0 0 50%;
`;
export const IntersectionDataWrapper = styled.div`
    margin-right: 20px;
    width: 100%;
    :last-child {
        margin-right: 0px;
    }
`;

export const StyledIcon = styled(Star20Filled)<{ color: string }>`
    color: ${({ color }) => color};
`;

export const RowReportingObjectName = styled.div`
    flex: 0 0 50%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
