import { useId, Input, Label, InputOnChangeData } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { IPresetModalBodyProps } from './interfaces';
import { useState, useEffect, FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, Wrapper, useStyles, DescriptionWrapper, NoPermissionsWrapper } from './styles';
import Button from '../../../Button/Button';
import { postUserPreset, PresetReducerValues } from '../../reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useAppDispatch } from '../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../UserSettings/reducer';

const SharedPresetModalBody: FC<IPresetModalBodyProps> = ({ sharedPreset, closeModal }) => {
    const underlineId = useId('input-underline');
    const descriptionId = useId('input-description');
    const styles = useStyles();

    const [isValidPreset, setIsValidPreset] = useState(true);
    const [presetName, setPresetName] = useState('');
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { currentModuleID, modulesConfig, user, locations } = useSelector(generalReducerValues);
    const currentOptions = useWidgetCurrentOptions(currentModuleID);

    useEffect(() => {
        sharedPreset?.name && setPresetName(() => sharedPreset?.name);
    }, [sharedPreset?.name]);

    useEffect(() => {
        /**
         * Проверяем, есть ли локации из пресета в списке локаций для текущего юзера
         */
        if (sharedPreset?.settings?.pl) {
            if (!locations.some((location) => location.id === Number(sharedPreset?.settings?.pl?.[0].id))) {
                setIsValidPreset(() => false);
            }
        } else if (sharedPreset?.settings?.pls) {
            if (
                !sharedPreset?.settings?.pls.every((pl) => {
                    return locations.some((loc) => loc.id === Number(pl.id));
                })
            ) {
                setIsValidPreset(() => false);
            }
        }
    }, [dispatch, locations, sharedPreset?.settings?.pl, sharedPreset?.settings?.pls]);

    useEffect(() => {
        /**
         * Проверяем, есть ли все пермишшены для модуля из пресета в пермишшенах юзера
         */
        const presetMpdulePermissions = modulesConfig.find(
            (item) => item.module_id === sharedPreset?.module_id,
        )?.permissions;
        const userPermissions = user?.permissions;

        if (presetMpdulePermissions?.some((permission) => !userPermissions?.includes(permission))) {
            setIsValidPreset(() => false);
        }
    }, [modulesConfig, sharedPreset?.module_id, user?.permissions]);

    /**
     * Удаление или сохранение пресета
     */
    const onButtonClick = () => {
        if (sharedPreset) {
            const { settings, description, module_id } = sharedPreset;
            dispatch(postUserPreset({ name: presetName, settings, description, moduleId: module_id }));

            /**
             * Если текущая локация не из пресета - репеводим туда юзера
             */
            if (
                sharedPreset.settings?.pl &&
                Number(sharedPreset.settings?.pl[0].id) !== Number(currentOptions?.pl?.[0].id)
            ) {
                dispatch(changeOptions({ newOptions: { pl: sharedPreset.settings?.pl } }));
            }
        }
        closeModal();
    };

    const onCancelClick = () => {
        closeModal();
    };

    const handleNameInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
        setPresetName(() => data.value);
    if (!currentOptions || !sharedPreset) return null;

    return isValidPreset ? (
        <Wrapper>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId} size="large">
                    {t('Save the preset?')}
                </Label>
            </div>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId}>
                    {t('Name')}
                </Label>
                <Input
                    onChange={handleNameInput}
                    value={presetName}
                    placeholder={t('Name')}
                    appearance="underline"
                    id={underlineId}
                />
            </div>

            {sharedPreset.description && (
                <div className={styles.field}>
                    <br />
                    <Label weight="semibold" htmlFor={descriptionId}>
                        {t('Description')}
                    </Label>
                    <DescriptionWrapper>{sharedPreset.description}</DescriptionWrapper>
                </div>
            )}

            {/* <SettingsWrapper>
                <SettingsTitle>{t('Shared preset settings:')}</SettingsTitle>
                <SettingsBodyWrapper>
                    {Object.entries(sharedPreset?.settings).map((entry) => {
                        const [key, value] = entry;
                        const valueText = Array.isArray(value)
                            ? value.map((item) => JSON.stringify(item) + '\n')
                            : JSON.stringify(value || {});

                        return (
                            <SettingsBodyItem key={key}>
                                <SettingsSubtitle>{key}</SettingsSubtitle>
                                <SettingsText>{valueText}</SettingsText>
                            </SettingsBodyItem>
                        );
                    })}
                </SettingsBodyWrapper>
            </SettingsWrapper> */}
            <ButtonWrapper>
                <Button text={t('Cancel')} onClick={onCancelClick} />
                <Button text={t('Save')} appearance="primary" onClick={onButtonClick} />
            </ButtonWrapper>
        </Wrapper>
    ) : (
        <NoPermissionsWrapper>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId}>
                    {t('You have no permissions to use this preset.')}
                </Label>
            </div>

            <ButtonWrapper>
                <Button text={t('Cancel')} appearance="primary" onClick={onCancelClick} />
            </ButtonWrapper>
        </NoPermissionsWrapper>
    );
};

export default SharedPresetModalBody;
