import ContextualMenu from '../../../../ContextualMenu/ContextualMenu';
import DropdownBody from '../DropdownBody/DropdownBody';
import React, { useState } from 'react';
import { ContentWrapper, Wrapper } from './styles';
import { generalReducerValues } from '../../../../../General.reducer';
import { IProps } from './interfaces';
import { IReportingObject } from '../../../../../General.interfaces';
import { changeOptions, userSettingsReducerValues } from '../../../../UserSettings/reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getObjSectionName } from '../../../../../tools/Strings/getObjSectionName';
import { Dismiss12Regular } from '@fluentui/react-icons';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { getObjectName } from '../../../../../hooks/useObjectTranslation';

const Bubble: React.FC<IProps> = ({ objects, testId = '' }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        cfg: { reportingObjectsById, reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);
    const selectedFilterIds = currentOptions?.['filters'] || [];
    const selectedFilteringObjects: IReportingObject[] = selectedFilterIds.map(
        (id: number) => reportingObjectsById[id],
    );
    const [isContextMenuOpen, setContextMenuOpen] = useState(false);

    const { selectedLocationId } = currentOptions;
    const objType = objects[0].object_type;

    const dispatchFilters = (ids: number[]) => {
        selectedLocationId &&
            dispatch(
                changeOptions({
                    newOptions: {
                        filters: ids,
                    },
                }),
            );
    };

    const handleSelect = (ids: number[]) => {
        dispatchFilters(ids);
        setContextMenuOpen(false);
    };

    const onIconClick = () => {
        const newIds = selectedFilteringObjects
            ?.filter((obj: IReportingObject) => obj?.object_type !== objType)
            .map((obj) => obj.id);
        dispatchFilters(newIds);
    };

    const toggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
        setContextMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextMenuOpen(false);
    };

    return (
        <ContextualMenu
            contextualMenuBody={
                <DropdownBody
                    reportingObjects={reportingObjectsByType[objType]}
                    selectedReportingObjects={selectedFilteringObjects}
                    handleSelect={handleSelect}
                    filterType={objType}
                />
            }
            isContextualMenuOpen={isContextMenuOpen}
            closeContextualMenu={closeContextualMenu}
            gapSpace={10}
        >
            <Wrapper>
                <ContentWrapper>
                    <div onClick={toggleDropdown} data-test-id={testId}>
                        {`${getObjSectionName({ t, reportingObject: objects[0] })}: ${
                            objects.length > 3
                                ? objects.length
                                : objects.reduce((acc, value) => {
                                      const name = getObjectName({ t, reportingObject: value });
                                      if (acc) return acc + `, ${name}`;
                                      return acc + `${name}`;
                                  }, '')
                        }`}
                    </div>
                    <Dismiss12Regular onClick={onIconClick} />
                </ContentWrapper>
            </Wrapper>
        </ContextualMenu>
    );
};

export default Bubble;
