import axios, { AxiosError } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { storeNewAlert } from '../../components/Alert/reducer';
import { isObject } from 'lodash';
import { getHeaders } from './getHeaders';

/**
 * Фукция для GET запроса с обработкой ошибки, если был передан dispatch
 */
export const getRequest = async (url: string, token: string, dispatch?: Dispatch, throwError = false) => {
    const headers = getHeaders(token);

    try {
        const response = await axios({
            method: 'GET',
            headers,
            url: `${url}`,
        });

        return response;
    } catch (error) {
        if (throwError) {
            throw error;
        }
        handleAxiosError(error, dispatch);
        return error;
    }
};

/**
 * Фукция для POST запроса с обработкой ошибки, если был передан dispatch
 */
export const postRequest = async (
    url: string,
    token: string,
    data: object,
    dispatch?: Dispatch,
    throwError = false,
) => {
    const headers = getHeaders(token);

    try {
        const response = await axios({
            method: 'POST',
            headers,
            url: `${url}`,
            data,
        });
        return response;
    } catch (error) {
        if (throwError) {
            throw error;
        }
        handleAxiosError(error, dispatch);
        return error;
    }
};

interface IPatchRequestArgs {
    /** URL для запроса */
    url: string;
    /** Токен авторизации */
    token: string;
    /** Тело запроса */
    data: unknown;
    /** Dispatch */
    dispatch?: Dispatch;
    throwError?: boolean;
}

/**
 * Функция для PATCH запроса с обработкой ошибки, если был передан dispatch
 */
export const patchRequest = async (args: IPatchRequestArgs) => {
    const { url, token, data, dispatch, throwError = false } = args;
    const headers = getHeaders(token);

    try {
        const response = await axios({
            method: 'PATCH',
            headers,
            url,
            data,
        });
        return response;
    } catch (error) {
        if (throwError) {
            throw error;
        }
        handleAxiosError(error, dispatch);
        return error;
    }
};

/**
 * Фукция для DELETE запроса с обработкой ошибки, если был передан dispatch
 */
export const deleteRequest = async (
    url: string,
    token: string,
    id: number,
    dispatch?: Dispatch,
    throwError = false,
) => {
    const headers = getHeaders(token);

    try {
        const response = await axios({
            method: 'DELETE',
            headers,
            url: `${url}${id}/`,
        });
        return response;
    } catch (error) {
        if (throwError) {
            throw error;
        }
        handleAxiosError(error, dispatch);
        return error;
    }
};

/**
 * Функция для обработки ошибки, если был передан dispatch, то вызывает алерты с сообщением об ошибке
 */
const handleAxiosError = (error: AxiosError, dispatch?: Dispatch) => {
    const errors: string[] = [];
    if (isObject(error.response?.data)) {
        try {
            Object.values(error.response?.data as {})?.forEach((element) => {
                errors.push(JSON.stringify(element));
            });
        } catch (error) {
            errors.push('Some error accured');
        }
    } else {
        errors.push('Some error accured');
    }

    const result = errors.reduce((acc, value) => {
        acc += `${value}.  `;
        return acc;
    }, '');

    dispatch &&
        dispatch(
            storeNewAlert({
                type: 'error',
                text: result,
                duration: 10,
            }),
        );
};
