interface ICellData {
    percent: boolean;
    ocr?: boolean;
    value: number;
}

export const rawData: {
    [x: string]: {
        [x: string]: Array<{ date: string; ['2023']: ICellData; ['Dev. 23/22']: ICellData; ['Dev. 23/21']: ICellData }>;
    };
} = {
    Henderson: {
        traffic: [
            {
                date: '2023-01-01',
                '2023': { ocr: true, percent: false, value: 4791 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: -10 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 4317 },
                'Dev. 23/22': { percent: true, value: 2 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 4553 },
                'Dev. 23/22': { percent: true, value: 13 },
                'Dev. 23/21': { percent: true, value: 14 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 4635 },
                'Dev. 23/22': { percent: true, value: 21 },
                'Dev. 23/21': { percent: true, value: 27 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 4442 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 24 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 4789 },
                'Dev. 23/22': { percent: true, value: 34 },
                'Dev. 23/21': { percent: true, value: 7 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 4911 },
                'Dev. 23/22': { percent: true, value: 29 },
                'Dev. 23/21': { percent: true, value: 9 },
            },
        ],
        sales_no_vat: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 5790907 },
                'Dev. 23/22': { percent: true, value: -1 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 5207307 },
                'Dev. 23/22': { percent: true, value: -21 },
                'Dev. 23/21': { percent: true, value: -12 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 6950001 },
                'Dev. 23/22': { percent: true, value: 17 },
                'Dev. 23/21': { percent: true, value: -3 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 7200764 },
                'Dev. 23/22': { percent: true, value: -2 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 8958225 },
                'Dev. 23/22': { percent: true, value: -1 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 8342704 },
                'Dev. 23/22': { percent: true, value: 52 },
                'Dev. 23/21': { percent: true, value: -10 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 6780632 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: -8 },
            },
        ],
        sales_per_area_no_vat: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 28248 },
                'Dev. 23/22': { percent: true, value: -1 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 25401 },
                'Dev. 23/22': { percent: true, value: -21 },
                'Dev. 23/21': { percent: true, value: -12 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 33902 },
                'Dev. 23/22': { percent: true, value: 17 },
                'Dev. 23/21': { percent: true, value: -3 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 35077 },
                'Dev. 23/22': { percent: true, value: -2 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 43699 },
                'Dev. 23/22': { percent: true, value: -1 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 40696 },
                'Dev. 23/22': { percent: true, value: 52 },
                'Dev. 23/21': { percent: true, value: -10 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 33076 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: -8 },
            },
        ],
        sales_per_area_uniq_no_vat: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 27036 },
                'Dev. 23/22': { percent: true, value: -8 },
                'Dev. 23/21': { percent: true, value: -23 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 22102 },
                'Dev. 23/22': { percent: true, value: -4 },
                'Dev. 23/21': { percent: true, value: -4 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 29358 },
                'Dev. 23/22': { percent: true, value: 2 },
                'Dev. 23/21': { percent: true, value: -13 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 33564 },
                'Dev. 23/22': { percent: true, value: 6 },
                'Dev. 23/21': { percent: true, value: -8 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 32440 },
                'Dev. 23/22': { percent: true, value: 5 },
                'Dev. 23/21': { percent: true, value: -8 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 30725 },
                'Dev. 23/22': { percent: true, value: 5 },
                'Dev. 23/21': { percent: true, value: -16 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 23245 },
                'Dev. 23/22': { percent: true, value: -1 },
                'Dev. 23/21': { percent: true, value: -34 },
            },
        ],
        profit: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 1573000 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 22 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 1573000 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 22 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 1573000 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 22 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 1573000 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 22 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 1730300 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 22 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 1730300 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 22 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 1730300 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: 22 },
            },
        ],
        ocr: [
            {
                date: '2023-01-01',
                '2023': { ocr: true, percent: true, value: 27 },
                'Dev. 23/22': { ocr: true, percent: true, value: 3 },
                'Dev. 23/21': { ocr: true, percent: true, value: 9 },
            },
            {
                date: '2023-02-01',
                '2023': { ocr: true, percent: true, value: 30 },
                'Dev. 23/22': { ocr: true, percent: true, value: 8 },
                'Dev. 23/21': { ocr: true, percent: true, value: 8 },
            },
            {
                date: '2023-03-01',
                '2023': { ocr: true, percent: true, value: 23 },
                'Dev. 23/22': { ocr: true, percent: true, value: -2 },
                'Dev. 23/21': { ocr: true, percent: true, value: 4 },
            },
            {
                date: '2023-04-01',
                '2023': { ocr: true, percent: true, value: 23 },
                'Dev. 23/22': { ocr: true, percent: true, value: 2 },
                'Dev. 23/21': { ocr: true, percent: true, value: 7 },
            },
            {
                date: '2023-05-01',
                '2023': { ocr: true, percent: true, value: 21 },
                'Dev. 23/22': { ocr: true, percent: true, value: 2 },
                'Dev. 23/21': { ocr: true, percent: true, value: 6 },
            },
            {
                date: '2023-06-01',
                '2023': { ocr: true, percent: true, value: 22 },
                'Dev. 23/22': { ocr: true, percent: true, value: -8 },
                'Dev. 23/21': { ocr: true, percent: true, value: 5 },
            },
            {
                date: '2023-07-01',
                '2023': { ocr: true, percent: true, value: 26 },
                'Dev. 23/22': { ocr: true, percent: true, value: 0 },
                'Dev. 23/21': { ocr: true, percent: true, value: 6 },
            },
        ],
    },
    'Burger King': {
        traffic: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 37738 },
                'Dev. 23/22': { percent: true, value: 8 },
                'Dev. 23/21': { percent: true, value: -10 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 34004 },
                'Dev. 23/22': { percent: true, value: 1 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 45530 },
                'Dev. 23/22': { percent: true, value: 42 },
                'Dev. 23/21': { percent: true, value: 45 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 46350 },
                'Dev. 23/22': { percent: true, value: 49 },
                'Dev. 23/21': { percent: true, value: -2 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 44420 },
                'Dev. 23/22': { percent: true, value: 38 },
                'Dev. 23/21': { percent: true, value: -4 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 47890 },
                'Dev. 23/22': { percent: true, value: 67 },
                'Dev. 23/21': { percent: true, value: -18 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 49110 },
                'Dev. 23/22': { percent: true, value: 61 },
                'Dev. 23/21': { percent: true, value: -16 },
            },
        ],
        sales_no_vat: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 7320166 },
                'Dev. 23/22': { percent: true, value: 23 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 6570176 },
                'Dev. 23/22': { percent: true, value: 26 },
                'Dev. 23/21': { percent: true, value: -12 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 12078446 },
                'Dev. 23/22': { percent: true, value: 51 },
                'Dev. 23/21': { percent: true, value: -3 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 14181240 },
                'Dev. 23/22': { percent: true, value: 44 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 15984606 },
                'Dev. 23/22': { percent: true, value: 40 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 14594880 },
                'Dev. 23/22': { percent: true, value: 33 },
                'Dev. 23/21': { percent: true, value: -10 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 13112069 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: -8 },
            },
        ],
        sales_per_area_no_vat: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 29277 },
                'Dev. 23/22': { percent: true, value: 23 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 26281 },
                'Dev. 23/22': { percent: true, value: 26 },
                'Dev. 23/21': { percent: true, value: -12 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 47914 },
                'Dev. 23/22': { percent: true, value: 51 },
                'Dev. 23/21': { percent: true, value: -3 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 56725 },
                'Dev. 23/22': { percent: true, value: 44 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 63938 },
                'Dev. 23/22': { percent: true, value: 40 },
                'Dev. 23/21': { percent: true, value: -15 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 58380 },
                'Dev. 23/22': { percent: true, value: 33 },
                'Dev. 23/21': { percent: true, value: -10 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 52448 },
                'Dev. 23/22': { percent: true, value: 10 },
                'Dev. 23/21': { percent: true, value: -8 },
            },
        ],
        sales_per_area_uniq_no_vat: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 33924 },
                'Dev. 23/22': { percent: true, value: 11 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 37156 },
                'Dev. 23/22': { percent: true, value: -13 },
                'Dev. 23/21': { percent: true, value: -9 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 46031 },
                'Dev. 23/22': { percent: true, value: 21 },
                'Dev. 23/21': { percent: true, value: -4 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 45290 },
                'Dev. 23/22': { percent: true, value: 37 },
                'Dev. 23/21': { percent: true, value: -9 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 44854 },
                'Dev. 23/22': { percent: true, value: 22 },
                'Dev. 23/21': { percent: true, value: 1 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 43538 },
                'Dev. 23/22': { percent: true, value: 18 },
                'Dev. 23/21': { percent: true, value: -14 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 43614 },
                'Dev. 23/22': { percent: true, value: 11 },
                'Dev. 23/21': { percent: true, value: -21 },
            },
        ],
        profit: [
            {
                date: '2023-01-01',
                '2023': { percent: false, value: 1454750 },
                'Dev. 23/22': { percent: true, value: 15 },
                'Dev. 23/21': { percent: true, value: 32 },
            },
            {
                date: '2023-02-01',
                '2023': { percent: false, value: 1454750 },
                'Dev. 23/22': { percent: true, value: 15 },
                'Dev. 23/21': { percent: true, value: 32 },
            },
            {
                date: '2023-03-01',
                '2023': { percent: false, value: 1672963 },
                'Dev. 23/22': { percent: true, value: 15 },
                'Dev. 23/21': { percent: true, value: 32 },
            },
            {
                date: '2023-04-01',
                '2023': { percent: false, value: 1672963 },
                'Dev. 23/22': { percent: true, value: 15 },
                'Dev. 23/21': { percent: true, value: 32 },
            },
            {
                date: '2023-05-01',
                '2023': { percent: false, value: 1672963 },
                'Dev. 23/22': { percent: true, value: 15 },
                'Dev. 23/21': { percent: true, value: 32 },
            },
            {
                date: '2023-06-01',
                '2023': { percent: false, value: 1672963 },
                'Dev. 23/22': { percent: true, value: 15 },
                'Dev. 23/21': { percent: true, value: 32 },
            },
            {
                date: '2023-07-01',
                '2023': { percent: false, value: 1672963 },
                'Dev. 23/22': { percent: true, value: 15 },
                'Dev. 23/21': { percent: true, value: 32 },
            },
        ],
        ocr: [
            {
                date: '2023-01-01',
                '2023': { ocr: true, percent: true, value: 21 },
                'Dev. 23/22': { ocr: true, percent: true, value: -1 },
                'Dev. 23/21': { ocr: true, percent: true, value: 8 },
            },
            {
                date: '2023-02-01',
                '2023': { ocr: true, percent: true, value: 23 },
                'Dev. 23/22': { ocr: true, percent: true, value: -2 },
                'Dev. 23/21': { ocr: true, percent: true, value: 7 },
            },
            {
                date: '2023-03-01',
                '2023': { ocr: true, percent: true, value: 14 },
                'Dev. 23/22': { ocr: true, percent: true, value: -4 },
                'Dev. 23/21': { ocr: true, percent: true, value: 4 },
            },
            {
                date: '2023-04-01',
                '2023': { ocr: true, percent: true, value: 12 },
                'Dev. 23/22': { ocr: true, percent: true, value: -3 },
                'Dev. 23/21': { ocr: true, percent: true, value: 4 },
            },
            {
                date: '2023-05-01',
                '2023': { ocr: true, percent: true, value: 10 },
                'Dev. 23/22': { ocr: true, percent: true, value: -2 },
                'Dev. 23/21': { ocr: true, percent: true, value: 4 },
            },
            {
                date: '2023-06-01',
                '2023': { ocr: true, percent: true, value: 11 },
                'Dev. 23/22': { ocr: true, percent: true, value: -2 },
                'Dev. 23/21': { ocr: true, percent: true, value: 4 },
            },
            {
                date: '2023-07-01',
                '2023': { ocr: true, percent: true, value: 13 },
                'Dev. 23/22': { ocr: true, percent: true, value: 1 },
                'Dev. 23/21': { ocr: true, percent: true, value: 4 },
            },
        ],
    },
};
