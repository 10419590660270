import { createContext } from 'react';
import {
    IDataObj2ProjectCategory,
    IDateRange,
    IPeriod,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2ZoneRelation,
} from '../../../../../General.interfaces';
import { IExtendedReportingObject } from './interfaces';
import { ISelectedOption } from '../../../../../components/Selects/Select/interfaces';

interface IContext {
    storeSelectedReportingObjectsTypeTrans: (value: ISelectedOption) => void;
    storeTableItemsToShowTrans: (value: number | 'all') => void;
    selectedReportingObjectsType: ISelectedOption;
    dataObj2ProjectCategory: IDataObj2ProjectCategory[];
    widgetName: 'Intersections with tenants';
    tableItemsToShow: number | 'all';
    mainDateRanges?: IDateRange[];
    sendYaAnalytics: boolean;
    mainPeriod?: IPeriod;
    daysInPeriod: number;
    isLoading: boolean;
    moduleName: string;
    filters?: number[];
    isMobile: boolean;
    isError: boolean;
    tenant2FloorByTenantId: {
        [tenantId: string]: ITenant2FloorRelation[];
    };
    tenant2ZoneByTenantId: {
        [tenantId: string]: ITenant2ZoneRelation[];
    };
    reportingObjectsByType: {
        [x: string]: IReportingObject[];
    };
    reportingObjectsById: {
        [id: string]: IReportingObject;
    };
    extendedReportingObjectsById: {
        [id: string]: IExtendedReportingObject;
    } | null;
}

export const IntersectionsWithTenantsContext = createContext<null | IContext>(null);
