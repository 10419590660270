import { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import ContextualMenu from '../../../../../../../../../components/ContextualMenu/ContextualMenu';
import { Events_EventsMap_Widget_Reducer_Values } from '../../../../../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import { EventsManagementContext } from '../../../../context';
import { ITableCellData } from '../../../../interfaces';
import ContextualMenuBody from './components/ContextualMenuBody/ContextualMenuBody';
import { Wrapper } from './styles';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';

interface IProps {
    /** Данные ячейки таблицы */
    data: ITableCellData;
}

/**
 * Компонент для отображения ячейки с типов событий
 */
const EventType: FC<IProps> = ({ data }) => {
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const context = useContext(EventsManagementContext);
    const { t } = useTranslation(['translationEvents']);

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const onTypeChange = (value: number) => {
        context?.updateCellValueTrans({ frontId: data.frontId, key: data.key, value });
    };

    const eventType = eventTypes.find((item) => item.id === data.value)?.name;

    return (
        <ContextualMenu
            contextualMenuBody={<ContextualMenuBody onTypeChange={onTypeChange} />}
            isContextualMenuOpen={isContextualMenuOpen}
            closeContextualMenu={closeContextualMenu}
        >
            <Wrapper onDoubleClick={toggleContextualMenu}>{t(eventType || '')}</Wrapper>
        </ContextualMenu>
    );
};

export default EventType;
