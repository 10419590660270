import styled from 'styled-components/macro';

export const Container = styled.div<{ maxWidth?: string; paddingTop?: number }>`
    max-width: ${(p) => (p.maxWidth ? p.maxWidth : '1230px')};
    width: 100%;
    background-color: ${(p) => p.theme.colors.secondaryLight};
    padding-right: var(--space-md);
    padding-left: var(--space-md);
    padding-bottom: var(--space-md);
    padding-top: ${(p) => {
        // TODO прокинуть сайзы в темы
        // src/App.styles.ts margin-top: 65px
        const permanentPadding = 28;
        return p.paddingTop ? `${p.paddingTop + permanentPadding}px` : permanentPadding + 'px';
    }};
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        padding-right: var(--space-xs);
        padding-left: var(--space-xs);
        padding-bottom: var(--space-xs);
    }
    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
        padding-right: var(--space-xxs);
        padding-left: var(--space-xxs);
        padding-bottom: var(--space-xxs);
    }
`;

// export const Container = styled.div`
//     padding-right: var(--space-md);
//     padding-left: var(--space-md);
//     padding-bottom: var(--space-md);
// `;
