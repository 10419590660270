import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowClockwise24Regular } from '@fluentui/react-icons';

import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import Tooltip from '../../../Tooltip/Tooltip';
import IconButton from '../../../IconButton/IconButton';

interface IProps {
    reloadHandler?: () => AnyAction;
    /** Функция для перезагрузки виджета, но без диспатча */
    reloadHandlerNoDispatch?: () => void;
    widgetName: string;
}

const Reload: FC<IProps> = ({ reloadHandler, reloadHandlerNoDispatch, widgetName }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onReloadClick = () => {
        reloadHandler && dispatch(reloadHandler());
        reloadHandlerNoDispatch && reloadHandlerNoDispatch();
    };

    return (
        <Tooltip content={t('Reload widget')}>
            <IconButton onClick={onReloadClick} icon={<ArrowClockwise24Regular />} testId={`RELOAD-${widgetName}`} />
        </Tooltip>
    );
};

export default Reload;
