import { isNumber, orderBy } from 'lodash';
import { IReportingObject } from '../../../../../../../../../../General.interfaces';
import { TableSettingsIds } from '../../../../../enums';
import { ITableOrderData, ITableSetting, TCellData, TTenantsData } from '../../../../../interfaces';

interface IArgs {
    tenants: IReportingObject[];
    tableOrderData: ITableOrderData;
    tenantsById: TTenantsData;
    selectedTenantId: number;
    selectedTableSetting: ITableSetting;
}

/**
 * Функция для сортировки арнедаторов
 */
const sortTenants = (args: IArgs): IReportingObject[] => {
    const { tenants, tableOrderData, tenantsById, selectedTenantId, selectedTableSetting } = args;

    const orderingMap = {
        down: 'desc',
        up: 'asc',
    };

    const nullValuesMap = {
        down: -Infinity,
        up: Infinity,
    };

    const selectedTenant = tenants.find((item) => item.id === selectedTenantId);

    const arrayWithoutSelectedTenant = tenants.filter((item) => item.id !== selectedTenantId);

    if (tableOrderData.columnId === 'tenant') {
        const result = orderBy(
            arrayWithoutSelectedTenant,
            (tenant) => tenant.name,
            orderingMap[tableOrderData.ordering] || 'asc',
        );
        if (selectedTenant) {
            return [selectedTenant, ...result];
        }
        return result;
    }

    const result = orderBy(
        arrayWithoutSelectedTenant,
        (tenant) => {
            const data = tenantsById[tenant.id]?.metricsDataByColumnId?.[tableOrderData.columnId] as
                | TCellData
                | undefined;
            if (tableOrderData?.period === 'compare') {
                return isNumber(data?.compareValue?.percentageOfTheMain)
                    ? data?.compareValue?.percentageOfTheMain
                    : nullValuesMap[tableOrderData.ordering] || 'asc';
            }

            return isNumber(data?.mainValue?.value) && selectedTableSetting.id === TableSettingsIds.ShowAbsoluteValues
                ? data?.mainValue?.value
                : isNumber(data?.mainValue?.percentageOfTheRowTotal) &&
                  selectedTableSetting.id === TableSettingsIds.ShowRelativeValues
                ? data?.mainValue?.percentageOfTheRowTotal
                : nullValuesMap[tableOrderData.ordering] || 'asc';
        },
        [orderingMap[tableOrderData.ordering] || 'asc', 'desc'],
    );

    if (selectedTenant) {
        return [selectedTenant, ...result];
    }
    return result;
};

export default sortTenants;
