import { FC } from 'react';
import { IProps } from './interfaces';
import Stack from '../../../../../Stack/Stack';
import MetricsSelect from '../../../../../Selects/MetricsSelect/MetricsSelect';
import { Divider } from '@fluentui/react-components';
import { IChangeOptionsArgs } from '../../../../../UserSettings/interfaces';
import { DS } from '../../../../../../constants/constants';
import { TWidgetType } from '../../interfaces';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import UniversalSelect from '../../../../../Selects/UniversalSelect/UniversalSelect';
import DetailSelect from '../DetailSelect/DetailSelect';
import ObjectsTypeOptionsSelects from '../ObjectsTypeSelects/OptionsSelects/OptionsSelects';
import ObjectsTypeVisualSelects from '../ObjectsTypeSelects/VisualSelects/VisualSelects';
import UseMetricFromSelect from '../UseMetricFromSelect/UseMetricFromSelect';

const Selects: FC<IProps> = (props) => {
    const { currentModuleID } = useSelector(generalReducerValues);

    const widgetType = props.widgetConfig?.type?.split(DS)?.[1] as TWidgetType;

    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        props.updateWidget('options', args.newOptions);
    };

    const additionalVisualOptions = [
        { id: 'showWeekends', text: 'Show weekends' },
        { id: 'showTrends', text: 'Show trends' },
        { id: 'showAverage', text: 'Show average' },
        { id: 'showWeather', text: 'Show weather' },
    ];

    const onChangeVisual = (visualParamKey: string) => (args: IChangeOptionsArgs) => {
        props.updateWidget('visual', { [visualParamKey]: args.newOptions?.[visualParamKey] });
    };

    const widgetTypesWithUseMetricFromSelect = ['periodsLocations', 'objectsLocations', 'objectsReportingObjects'];
    const widgetTypesWithDetailsSelect = ['periods', 'metrics', 'objects'];
    const widgetTypesWithSingleMetricSelect = ['periods', 'objects'];
    const widgetTypesWithMultiMetricSelect = ['metrics'];
    const widgetTypesWithSectionDivider = ['periods', 'metrics', 'objects'];
    const widgetTypesWithAvailableEvents = ['periods', 'metrics', 'objects'];

    if (widgetTypesWithAvailableEvents.includes(widgetType)) {
        additionalVisualOptions.push({ id: 'showEvents', text: 'Show events' });
    }

    return (
        <>
            <Stack styles={{ padding: '0px 12px', maxWidth: '350px' }} gap={10} flexWrap="wrap">
                {widgetTypesWithSingleMetricSelect.includes(widgetType) && (
                    <MetricsSelect
                        name={'metrics'}
                        maxSelected={1}
                        localCurrentOptions={props.widgetConfig?.options}
                        changeOptions={updateWidgetOptions}
                    />
                )}
                {widgetTypesWithMultiMetricSelect.includes(widgetType) && (
                    <MetricsSelect
                        name={'severalMetrics'}
                        localCurrentOptions={props.widgetConfig?.options}
                        changeOptions={updateWidgetOptions}
                        maxSelected={5}
                    />
                )}

                {widgetTypesWithDetailsSelect.includes(widgetType) && <DetailSelect {...props} />}

                {widgetType === 'objects' && <ObjectsTypeOptionsSelects {...props} />}
            </Stack>

            {widgetTypesWithSectionDivider.includes(widgetType) && <Divider style={{ margin: '5px 0px' }} />}

            <Stack styles={{ padding: '0px 12px', maxWidth: '350px' }} gap={10} flexWrap="wrap">
                {widgetType === 'objects' && <ObjectsTypeVisualSelects {...props} />}

                {widgetTypesWithUseMetricFromSelect.includes(widgetType) && <UseMetricFromSelect {...props} />}

                <UniversalSelect
                    defaultSelected={[additionalVisualOptions[0]]}
                    mode="multi"
                    labelText="Additional options"
                    name="additionalVisualOptions"
                    options={additionalVisualOptions}
                    localCurrentOptions={{ ...props.widgetConfig?.visual, currentModuleID } || {}}
                    changeOptions={onChangeVisual('additionalVisualOptions')}
                />
            </Stack>
        </>
    );
};

export default Selects;
