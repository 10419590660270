import React, { useEffect, useState } from 'react';
import { Props } from './configurations';
import Icon from '../Icon/Icon';
import './styles/styles.scss';

import { DateTime, Interval } from 'luxon';
import { CalendarWrapper, Footer, IconWrapper, Main, MainWrapper } from './styles/styles';
import { getRanges, getResult, shiftHelper } from './core';
import MonthsGrid from './components/MonthsGrid';
import Header from './components/Header';
import YearsGrid from './components/YearsGrid';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

const DateRangePicker: React.FC<Props> = ({
    handleDateRangeChange,
    oneDayOnly = false,
    yearsOnly = false,
    showFutureDates = false,
}) => {
    const [shift, setShift] = useState(0);
    const [mode, setMode] = useState('months');
    const [shiftOnModeChange, setShiftOnModeChange] = useState(true);
    const [firstSelectedDate, setFirstSelectedDate] = useState<DateTime | null>(null);
    const [secondSelectedDate, setSecondSelectedDate] = useState<DateTime | null>(null);
    const { t } = useTranslation();

    /**
     * При выборе обеих дат происходит их обработка таким образом, чтобы
     * дата, которая меньше всегда была dateFrom, a дата, которая больше всегда
     * была dateTo
     */
    useEffect(() => {
        if (firstSelectedDate && secondSelectedDate) {
            const res = getResult(firstSelectedDate, secondSelectedDate, mode, showFutureDates, oneDayOnly);
            setFirstSelectedDate(res.dateFrom);
            setSecondSelectedDate(res.dateTo);
        }
    }, [firstSelectedDate?.toISO(), secondSelectedDate?.toISO()]);

    /**
     * При переключении между режимами устанавливается
     * нужный shift, чтобы выбранный промежуток был по середине
     */
    useEffect(() => {
        if (firstSelectedDate) {
            let shift = 0;
            switch (mode) {
                case 'months': {
                    shift = shiftHelper(firstSelectedDate, 'month') + shiftHelper(firstSelectedDate, 'year') * 12;
                    break;
                }
                case 'years': {
                    shift = shiftHelper(firstSelectedDate, 'year');
                    break;
                }

                default:
                    break;
            }
            shiftOnModeChange && setShift(shift);
        } else {
            shiftOnModeChange && setShift(0);
        }
        setShiftOnModeChange(true);
    }, [mode]);

    useEffect(() => {
        if (yearsOnly) {
            setMode(() => 'years');
        }
    }, [yearsOnly]);

    const now = DateTime.now();

    let end = now.plus({ [mode]: 1 + shift });
    let start = end.minus({ [mode]: 2 });
    let interval = Interval.fromDateTimes(start, end);

    const allMonthsOfCalendar = Array.from(getRanges(interval, 'year')).map((startDay) => {
        const endDay = startDay.endOf('year');
        const range = Interval.fromDateTimes(startDay, endDay);
        return Array.from(getRanges(range, 'month'));
    });

    const allDaysOfCalendar = Array.from(getRanges(interval, 'month')).map((startDay) => {
        const endDay = startDay.endOf('month');
        const range = Interval.fromDateTimes(startDay, endDay);
        return Array.from(getRanges(range, 'day')).map((day) => {
            return { day, weekDay: day.weekday };
        });
    });

    const onShiftClick = (shift: number) => () => {
        setShift((prev) => prev + shift);
    };

    const onApplyClick = () => {
        if (firstSelectedDate && secondSelectedDate) {
            if (handleDateRangeChange) {
                let result = {
                    dateFrom: firstSelectedDate.toISODate() ?? '',
                    dateTo: secondSelectedDate.toISODate() ?? '',
                };
                handleDateRangeChange(result);
            }
        }
    };

    const onYearHeaderClick = (year: number) => {
        if (!yearsOnly) {
            const currentYearJan = DateTime.fromObject({ year, month: 1, day: 1 });
            setShift(shiftHelper(currentYearJan, 'month') + shiftHelper(currentYearJan, 'year') * 12);
            setShiftOnModeChange(false);
            setMode('months');
        }
    };

    function firstDateDidChanged(d: DateTime | null): void {
        setFirstSelectedDate(d);
    }

    function secondDateDidChanged(d: DateTime | null): void {
        setSecondSelectedDate(d);
    }

    function modeDidChanged(mode: string): void {
        setMode(() => mode);
    }

    return (
        <MainWrapper>
            {!yearsOnly && (
                <Header
                    mode={mode}
                    modeDidChanged={modeDidChanged}
                    firstSelectedDate={firstSelectedDate}
                    secondSelectedDate={secondSelectedDate}
                />
            )}
            <Main>
                <CalendarWrapper>
                    <IconWrapper hand="left" onClick={onShiftClick(-1)}>
                        <Icon type={document.dir === 'rtl' ? 'chevron-right' : 'chevron-left'} size="xxs" />
                    </IconWrapper>
                    {(showFutureDates || shift < 0) && (
                        <IconWrapper hand="right" onClick={onShiftClick(1)}>
                            <Icon type={document.dir === 'rtl' ? 'chevron-left' : 'chevron-right'} size="xxs" />
                        </IconWrapper>
                    )}
                    {mode === 'months' ? (
                        <MonthsGrid
                            firstSelectedDate={firstSelectedDate}
                            secondSelectedDate={secondSelectedDate}
                            allDaysOfCalendar={allDaysOfCalendar}
                            showFutureDates={showFutureDates}
                            firstDateDidChanged={firstDateDidChanged}
                            secondDateDidChanged={secondDateDidChanged}
                            oneDayOnly={oneDayOnly}
                        />
                    ) : (
                        <YearsGrid
                            firstSelectedDate={firstSelectedDate}
                            secondSelectedDate={secondSelectedDate}
                            allMonthsOfCalendar={allMonthsOfCalendar}
                            showFutureDates={showFutureDates}
                            firstDateDidChanged={firstDateDidChanged}
                            secondDateDidChanged={secondDateDidChanged}
                            oneDayOnly={oneDayOnly}
                            onYearHeaderClick={onYearHeaderClick}
                        />
                    )}
                </CalendarWrapper>
            </Main>
            <Footer>
                <Button
                    testId="DateRangePickerButton"
                    onClick={onApplyClick}
                    text={t('Apply')}
                    appearance="primary"
                    disabled={!firstSelectedDate || !secondSelectedDate}
                />
            </Footer>
        </MainWrapper>
    );
};

export default DateRangePicker;
