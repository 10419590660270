import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Group, Shape } from 'react-konva';
import { scaleTransformFoo } from '../../../../../../../tools/scaleTransformFoo';
import { POLYGON_NEUTRAL_COLOR } from '../../../../constants';
import { Props } from './interfaces';

const Place: React.FC<Props> = ({
    object,
    selectObject,
    selectedMarker,
    onMouseOver: onMouseOverTrans,
    onMouseLeave: onMouseLeaveTrans,
    heatColor,
    stageScale = 1,
    availableMarkers,
    changeStageCursor,
    stageIndex,
}) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        if (selectObject) {
            if (Array.isArray(availableMarkers)) {
                availableMarkers.includes(object.marker) && selectObject(object.marker);
            } else {
                selectObject(object.marker);
            }
        }
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        if (Array.isArray(availableMarkers) && !availableMarkers.includes(object.marker)) {
            changeStageCursor(stageIndex, 'not-allowed')();
        }
        e.cancelBubble = true;
        onMouseOverTrans && onMouseOverTrans(object.marker);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        if (Array.isArray(availableMarkers)) {
            !availableMarkers.includes(object.marker) && changeStageCursor(stageIndex, 'pointer')();
        }
        e.cancelBubble = true;
        onMouseLeaveTrans && onMouseLeaveTrans(null);
    };

    return (
        <Shape
            id={'Shape!@##11'}
            key={'Shape!@##11'}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onObjectClick}
            onTap={onObjectClick}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                object.coords.coordinates.forEach((coord: number[], i: number) => {
                    if (i > 0) {
                        context.lineTo(coord[0], coord[1]);
                    }
                });
                context.lineTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                context.fillStrokeShape(shape);
            }}
            opacity={1}
            fill={selectedMarker !== object.marker ? heatColor || POLYGON_NEUTRAL_COLOR : 'rgba(85, 125, 249, .7)'}
            // fillPatternImage={selectedMarker === object.marker ? hatchingLines : undefined}
            stroke={'white'}
            // strokeWidth={scaleTransformFoo(25, stageScale)}
        />
    );
};

export default Place;
