import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IReducerState, TRawMetricsData } from './interfaces';

const initialState: IReducerState = {
    tab: 'DetailedBills',
    moduleId: 'Reports:Advanced reports',
};

export const Advanced_Reports_Module_Reducer = createSlice({
    name: 'Advanced_Reports_Module_Reducer',
    initialState,

    reducers: {
        toggleTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },
    },
});

export const { toggleTab } = Advanced_Reports_Module_Reducer.actions;

export const Advanced_Reports_Module_Reducer_Values = (state: RootState) => state.Advanced_Reports_Module_Reducer;

export default Advanced_Reports_Module_Reducer.reducer;
