import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { Performance_Dashboard_ByArea_Widget_Reducer_Values, storeComparePeriod } from '../reducer';

import { Performance_Dashboard_Module_Reducer_Values } from '../../../reducer';
import { isEqual } from 'lodash';

/** Кастомный хук для подготовки сырых данных */
export const usePrepareData = () => {
    const { comparePeriod } = useSelector(Performance_Dashboard_ByArea_Widget_Reducer_Values);
    const { moduleName } = useSelector(Performance_Dashboard_Module_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const dispatch = useDispatch();

    /** Запись выбранного периода сравнения */
    useEffect(() => {
        if (localCurrentOptions?.compareDateRanges && localCurrentOptions?.comparePeriods) {
            const isExists = !!localCurrentOptions.comparePeriods?.find((element) => element.id === comparePeriod.id);
            const firstSelectedPeriod = localCurrentOptions.compareDateRanges?.find(
                (element) => element.id === localCurrentOptions?.comparePeriods?.[0]?.id,
            );
            /**
             * Если текущий выбранный период не совпадает с теми, которые выбраны в глобальных селектов,
             * то записываем первый из выбранных
             */
            if (!isExists && firstSelectedPeriod) {
                dispatch(storeComparePeriod(firstSelectedPeriod));
            }

            /**
             * Если текущий выбранный период совпадает с глобальными селектами, но его период не совпадает (такое может быть,  когда выбран кастом)
             * то обновляем выбранные период
             */
            if (isExists) {
                const isPeriodEqual = !!localCurrentOptions.compareDateRanges?.find(
                    (element) => element.id === comparePeriod.id && isEqual(element.period, comparePeriod.period),
                );
                const currentPeriodDateRanges = localCurrentOptions?.compareDateRanges?.find(
                    (period) => period.id === comparePeriod.id,
                );

                if (!isPeriodEqual && currentPeriodDateRanges) {
                    dispatch(storeComparePeriod(currentPeriodDateRanges));
                }
            }
        }
    }, [localCurrentOptions?.compareDateRanges, localCurrentOptions?.comparePeriod]);
};
