import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ customHeight?: number }>`
    position: relative;
    height: ${(p) => (p.customHeight ? `${p.customHeight}px` : '100%')};
    > div {
        overflow: visible !important;
        height: 100%;
    }
`;
