import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import generatePercentIntersectionsQueryData from '../tools/generateIntersectionsQueryData';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import usePrepareData from './usePrepareData';
import { useEffect } from 'react';
import {
    Leasing_CrossVisits_Widget_Reducer_Values,
    storeRawPercentAverageIntersections,
    storeRawPaiwiseIntersections,
    storeIntersectionsDataById,
    toggleLoading,
    toggleError,
} from '../reducer';
import requestAverageIntersections from '../../../../../../tools/API/requestAverageIntersections';
import requestPaiwiseIntersections from '../../../../../../tools/API/requestPaiwiseIntersections';
import { Leasing_CrossVisits_Module_Reducer_Values } from '../../../reducer';

/** Кастомный хук получения данных */
export const useGetRawData = () => {
    const { moduleName } = useSelector(Leasing_CrossVisits_Module_Reducer_Values);
    const { extendedReportingObjectsById, dataRefetchObject } = useSelector(Leasing_CrossVisits_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { token, urlsByServices } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    usePrepareData();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (
            extendedReportingObjectsById &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            urlsByServices &&
            token
        ) {
            const percentQueryData = generatePercentIntersectionsQueryData({
                extendedReportingObjectsById,
                token,
                mainPeriod: localCurrentOptions.mainPeriod,
                mainDateRanges: localCurrentOptions.mainDateRanges,
            }).map((element) => ({
                ...element,
                signal,
                url: urlsByServices['core/structure-service'].AVERAGE_INTERSECTIONS_REQUEST_URL,
            }));

            const paiwiseQueryData = generatePercentIntersectionsQueryData({
                extendedReportingObjectsById,
                token,
                mainPeriod: localCurrentOptions.mainPeriod,
                mainDateRanges: localCurrentOptions.mainDateRanges,
            }).map((element) => ({
                ...element,
                signal,
                url: urlsByServices['core/structure-service'].PAIWISE_INTERSECTIONS_URL,
            }));
            dispatch(toggleLoading(true));
            dispatch(toggleError(false));
            dispatch(storeIntersectionsDataById(null));

            dispatch(storeRawPercentAverageIntersections({ status: ResponseStatus.Loading, message: 'Loading...' }));
            requestAverageIntersections(percentQueryData).then((responses: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawPercentAverageIntersections,
                    error: storeRawPercentAverageIntersections,
                    res: responses,
                    dispatch,
                });
            });

            dispatch(storeRawPaiwiseIntersections({ status: ResponseStatus.Loading, message: 'Loading...' }));
            requestPaiwiseIntersections(paiwiseQueryData).then((responses: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawPaiwiseIntersections,
                    error: storeRawPaiwiseIntersections,
                    res: responses,
                    dispatch,
                });
            });
        }
        return () => controller.abort();
    }, [extendedReportingObjectsById, localCurrentOptions?.mainPeriod, token, dataRefetchObject]);
};
