import styled from 'styled-components/macro';

export const ModalNewRatingWrapper = styled.div`
    width: 500px;
    max-width: 90vw;
    z-index: 100;
`;

export const FormItem = styled.div<{
    $hideMarginBottom?: boolean
}>`
    display: block;
    margin-bottom: ${({ $hideMarginBottom }) => ($hideMarginBottom ? null : 'var(--space-sm)')};
`;
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-xs);
`;
export const CheckboxItem = styled.div`
    display: flex;
    margin-bottom: var(--space-xxxs);
`;

export const Label = styled.div`
    font-size: 12px;
    opacity: 0.4;
`;
export const Column = styled.div`
    :first-child {
        padding-right: var(--space-xs);

        @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
            padding-right: 0;
        }
    }
`;
export const GridTemplate = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        grid-template-columns: 1fr;
    }
`;
