import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Configuration_Categories_Widget_Reducer_Values, storeChapter } from './reducer';
import Table from './components/Table/Table';
import Tree from './components/Tree/Tree';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

const Categories: React.FC = ({}) => {
    const ref = useRef(null);
    const { chapter } = useSelector(Configuration_Categories_Widget_Reducer_Values);
    const {
        src: { categories },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tabListItems = [
        {
            id: 'table',
            text: t('Table'),
        },
        {
            id: 'projectCategories',
            text: t('Project categories'),
        },
    ];

    const tabListSelectHandler = (id: string) => {
        dispatch(storeChapter(id));
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Summary table of categories')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName={'Summary table of categories'}
                        // reloadHandler={reloadCategories}
                    />
                </WidgetTitle>
                <TabList
                    widgetName="SummaryTableOfCategories"
                    selectHandler={tabListSelectHandler}
                    selectedValue={chapter}
                    options={tabListItems}
                />
            </WidgetTitleWrapper>
            {chapter === 'table' && <Table />}
            {chapter === 'projectCategories' && <Tree />}
        </WidgetWrapper>
    );
};

export default Categories;
