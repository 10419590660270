import React from 'react';
import { getColorFromPercent } from '../../../../../../../tools/getColorFromPercent';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { CellWrapper, MainCellWrapper, ReferenceCompareCellValue } from './styles';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useSelector } from 'react-redux';

const Cell: React.FC<{
    value: number | string;
    type: string;
    name?: string;
    metric?: string;
    referenceCompare: number;
    showReferenceCompareValues: boolean;
    isSelectedTenant: boolean;
    deviationByReferenceCompare?: string;
}> = ({
    value,
    type,
    metric,
    referenceCompare,
    showReferenceCompareValues,
    isSelectedTenant,
    deviationByReferenceCompare,
}) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const cellValue = (value: number | string, type: string) => {
        switch (type) {
            case 'name': {
                return value;
            }
            case 'deviation': {
                let units = '';
                let result = value;

                if (showReferenceCompareValues && deviationByReferenceCompare) {
                    units = '%';
                } else if (!isNaN(Number(value))) {
                    units = '%';
                }

                if (showReferenceCompareValues && deviationByReferenceCompare && !isSelectedTenant) {
                    result = deviationByReferenceCompare;
                }
                return valueFormatter({ value: result, units });
            }
            case 'main': {
                const units = allMetrics?.find((item) => item.id === metric)?.units || '';
                const mainValue = valueFormatter({ value, units, showUnits: false });
                const referenceCompareResult = valueFormatter({ value: referenceCompare, units: '%' });

                return (
                    <MainCellWrapper>
                        {(isSelectedTenant || !showReferenceCompareValues) && <span>{mainValue}</span>}
                        {!isSelectedTenant && showReferenceCompareValues ? (
                            <ReferenceCompareCellValue value={referenceCompare}>
                                {referenceCompareResult}
                            </ReferenceCompareCellValue>
                        ) : null}
                    </MainCellWrapper>
                );
            }

            default:
                return value;
        }
    };

    const styles = (value: number | string, type: string) => {
        if (type === 'name') {
            return { textAlign: 'left', fontWeight: '400', color: 'black' };
        } else if (type === 'main') {
            return { textAlign: 'right', fontWeight: '400', color: 'black' };
        } else {
            let result = value;
            if (showReferenceCompareValues && deviationByReferenceCompare && !isSelectedTenant) {
                result = deviationByReferenceCompare;
            }
            const color = getColorFromPercent(result);
            return { textAlign: 'right', fontWeight: '600', color };
        }
    };

    return <CellWrapper styles={styles(value, type)}>{cellValue(value, type)}</CellWrapper>;
};

export default Cell;
