import { FC, useMemo } from 'react';
import { Wrapper } from './styles/styles';
import './styles/styles.scss';
import { IProps } from './interfaces';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import Stack from '../../../Stack/Stack';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import Dynamics from '../../../Charts/Dynamics/Dynamics';
import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import useFetchData from './hooks/useFetchData';
import { isMetricErrorGuard } from '../../../../tools/API/dataAccessors/metricsDataAccessor';
import { ResponseStatus } from '../../../../tools/API/constants';
import { generalReducerValues } from '../../../../General.reducer';
import { useSelector } from 'react-redux';
import { IChangeOptionsArgs } from '../../../UserSettings/interfaces';
import PieChartObjectsSelect from './components/PieChartObjectsSelect/PieChartObjectsSelect';

/**
 * виджет типа "PieChart"
 */
const PieChart: FC<IProps> = ({ dashboardKey, moduleId, widgetConfig, widgetDimensions, updateWidgetTrans }) => {
    const { data, isFetching, error, refetch } = useFetchData({
        widgetDimensions,
        widgetConfig,
        dashboardKey,
        moduleId,
    });
    const { allMetrics, currentModuleID } = useSelector(generalReducerValues);

    const { t } = useTranslation(['metrics', 'translation']);

    /** Круговая диаграмма */
    const chart = useMemo(() => {
        if (data) {
            return (
                <Dynamics
                    {...data}
                    customChartDimensions={
                        widgetDimensions
                            ? { width: widgetDimensions.width - 16, height: widgetDimensions.height - 60 }
                            : undefined
                    }
                />
            );
        }
        return null;
    }, [data, widgetDimensions]);

    /** Имя текущей метрики */
    const [currentMetricName, currentMetricUnits] = useMemo(() => {
        if (allMetrics.length && widgetConfig?.options?.metrics?.length) {
            const currentMetricId = widgetConfig?.options?.metrics?.[0] || '';
            const currentMetric = allMetrics.find((metric) => metric.id === currentMetricId);
            if (currentMetric) {
                return [currentMetric.text, currentMetric.units];
            }
        }
        return [];
    }, [allMetrics, widgetConfig?.options?.metrics]);

    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        if (widgetConfig && widgetConfig?.id) {
            updateWidgetTrans({
                moduleId,
                dashboardKey,
                data: {
                    widgetId: widgetConfig?.id,
                    key: 'options',
                    value: { ...(widgetConfig.options || {}), ...args.newOptions },
                },
            });
        }
    };

    const reloadWidget = () => {
        refetch();
    };

    const widgetSelects = useMemo(() => {
        return (
            <Stack className={'micro-pie-chart-selects__wrapper'} direction="column" gap={5}>
                <MetricsSelect
                    localCurrentOptions={{ ...widgetConfig?.options, currentModuleID }}
                    changeOptions={updateWidgetOptions}
                    name={`metrics`}
                    maxSelected={1}
                />
                <PieChartObjectsSelect updateWidgetOptions={updateWidgetOptions} widgetConfig={widgetConfig} />
            </Stack>
        );
    }, [currentModuleID, widgetConfig]);

    return (
        <WidgetWrapper
            titleAdditionalText={`${t(currentMetricName || '')}, ${t(currentMetricUnits || '')}`}
            isNoData={isMetricErrorGuard(error) && error.status === ResponseStatus.NoData}
            isError={isMetricErrorGuard(error) && error.status === ResponseStatus.Error}
            contentWrapperClassName="micro-pie-chart-content__wrapper"
            updateWidgetTrans={updateWidgetTrans}
            title={widgetConfig?.title || ''}
            widgetSelects={widgetSelects}
            widgetId={widgetConfig?.id}
            dashboardKey={dashboardKey}
            reloadWidget={reloadWidget}
            isLoading={isFetching}
            moduleId={moduleId}
        >
            <Wrapper>{chart}</Wrapper>
        </WidgetWrapper>
    );
};

export default PieChart;
