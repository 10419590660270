import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    overflow: auto;
    height: 100%;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border: 1px solid var(--default-border-color);
    border-top: none;
    position: relative;

    th,
    td {
        border: 1px solid var(--default-border-color);
        padding: 10px;
        :first-child {
            min-width: 200px;
        }
    }
`;

export const Tr = styled.tr<{ bgColor?: string }>`
    background-color: ${(p) => p.bgColor || 'inherit'};
`;

export const Th = styled.th`
    text-align: center;
    background-color: inherit;
    :first-child {
        left: 0;
        z-index: 15;
        position: sticky;
    }
`;

export const Td = styled.td<{ bgColor?: string }>`
    background-color: ${(p) => p.bgColor || 'inherit'};
    :first-child {
        position: sticky;
        top: auto;
        left: 0;
        background-color: white;
        z-index: 5;
    }
`;
