import { FC, useState } from 'react';
import { IProps } from './interfaces';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import AccordionItem from '../../../../../components/Accordion/components/AccordionItem';
import { ChevronRightFilled } from '@fluentui/react-icons';

const Metric: FC<IProps> = ({ metric }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation(['translationHelp']);

    const toggleOpen = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <AccordionItem
            content={<div>{t(metric.description)}</div>}
            handleHeaderClick={toggleOpen}
            label={t(metric.title)}
            icon={<ChevronRightFilled fontSize={20} />}
            id={metric.title}
            isOpened={isOpen}
        />
    );
};

export default Metric;
