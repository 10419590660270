import { ChartOptions } from 'highcharts';
import { IHeatMapCustomChartDimensions } from '../../interfaces';

interface IArgs {
    customChartDimensions?: IHeatMapCustomChartDimensions;
    chartOptions?: ChartOptions;
}

const generateChart = (args: IArgs) => {
    const { customChartDimensions, chartOptions } = args;
    return {
        height: customChartDimensions ? customChartDimensions.height : undefined,
        style: { fontFamily: 'Roboto' },
        animation: true,
        width: customChartDimensions ? customChartDimensions.width : undefined,
        ...chartOptions,
    };
};

export default generateChart;
