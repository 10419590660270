import ContextualMenu from '../../../../../../../components/ContextualMenu/ContextualMenu';
import DropdownBody from './components/DropdownBody/DropdownBody';
import filterReportingObjects from '../../../../../../../components/Filters/tools/filterReportingObjects';
import getDifferenceBetweenNumbers from '../../../../../../../tools/getDifferenceBetweenNumbers';
import OrderSwitcher from '../../../../../../../components/OrderSwitcher/OrderSwitcher';
import sortTenants from './tools/sortTenants';
import { FC, memo, useEffect, useState } from 'react';
import { IProps } from './interfaces';
import { IReportingObject } from '../../../../../../../General.interfaces';
import { ITableSortData } from '../../interfaces';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { useTranslation } from 'react-i18next';
import {
    DefaultText,
    Dot,
    DotsWrapper,
    HeaderCellWrapper,
    MetricValuesWrapper,
    NameWrapper,
    StyledTable,
    THead,
    Td,
    Th,
    Tr,
} from './styles';
import { isNumber } from 'lodash';

/**
 * Комопонент для отображения таблицы
 * @param openAdditionalTenantInformation функция для перехода на новую страницу с другим виджетом
 * @param storeTableSortDataTrans функция для записи новых данных сортировки таблицы
 * @param tenant2FloorByTenantId Связь арендатора и этажа
 * @param tenant2ZoneByTenantId Связь арендатора и зоны
 * @param dataObj2ProjectCategory Связь всех объектов с категорями
 * @param reportingObjectsById Все отчетные объекты, где ключ это id отчетного объекта
 * @param extendedTenantsById расширенные данные арендаторов, где ключ - это id арнедатора
 * @param selectedMetricsIds id выбранных метрик
 * @param tableItemsToShow Кол-во отображаемых элементов таблицы
 * @param extendedTenants расширенные арендаторы
 * @param mainDateRanges Периоды дат для главного периода
 * @param tableSortData Данные для сортировки таблицы
 * @param allMetrics все метрики
 * @param mainPeriod Основной период

 * @param filters id всех фильтров
 */
const Table: FC<IProps> = memo(
    ({
        openAdditionalTenantInformation,
        storeTableSortDataTrans,
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        extendedTenantsById,
        selectedMetricsIds,
        tableItemsToShow,
        extendedTenants,
        mainDateRanges,
        tableSortData,
        allMetrics,
        moduleName,
        mainPeriod,
        filters,
    }) => {
        const [contextMenuStatus, setContextMenuStatus] = useState<{ show: boolean; tenantId: null | number }>({
            show: false,
            tenantId: null,
        });
        const [filteredTenants, setFilteredTenants] = useState<IReportingObject[]>([]);
        const [sortedTenants, setSortedTenants] = useState<IReportingObject[]>([]);
        const { t } = useTranslation();

        /** Получение отфильтрованных арендаторов */
        useEffect(() => {
            if (mainDateRanges && mainPeriod) {
                const tenants = filterReportingObjects({
                    initialArray: extendedTenants.map((element) => element.tenantData),
                    tenant2FloorByTenantId,
                    tenant2ZoneByTenantId,
                    dataObj2ProjectCategory,
                    mainDateRanges,
                    filters,
                    mainPeriod,
                    reportingObjectsById,
                });
                setFilteredTenants(tenants);
            } else {
                setFilteredTenants(extendedTenants.map((element) => element.tenantData));
            }
        }, [filters, tenant2FloorByTenantId, tenant2ZoneByTenantId, extendedTenants, dataObj2ProjectCategory]);

        /** Сортировка арендаторов */
        useEffect(() => {
            if (tableSortData) {
                setSortedTenants(() => {
                    const result = filteredTenants.map((element) => ({ ...extendedTenantsById[element.id] }));
                    return sortTenants({
                        dataForSort: tableSortData,
                        tenants: result,
                    })
                        .slice(0, tableItemsToShow === 'all' ? filteredTenants.length : tableItemsToShow)
                        .map((element) => element.tenantData);
                });
            } else {
                setSortedTenants(
                    filteredTenants.slice(0, tableItemsToShow === 'all' ? filteredTenants.length : tableItemsToShow),
                );
            }
        }, [tableSortData, filteredTenants, tableItemsToShow]);

        const handleSelect = (data: {
            tenantData: IReportingObject;
            module: 'Leasing:Tenant overview' | 'Performance:Year over year';
            path: string;
        }) => {
            let selectedMetrics = [selectedMetricsIds[0]];
            switch (data.module) {
                case 'Leasing:Tenant overview':
                    selectedMetrics = selectedMetricsIds;
                    break;

                default:
                    break;
            }
            openAdditionalTenantInformation({ ...data, selectedMetrics });
            closeContextualMenu();
        };

        const handleOrderToggle = (data: ITableSortData) => {
            storeTableSortDataTrans(data);
        };

        const closeContextualMenu = () => {
            setContextMenuStatus((prevState) => ({ ...prevState, show: false, tenantId: null }));
        };

        const toggleContextualMenu = (tenantId: number) => (e: React.MouseEvent<HTMLElement>) => {
            setContextMenuStatus((prevState) => {
                const show = !prevState.show;
                return {
                    show,
                    tenantId: show ? tenantId : null,
                };
            });
        };

        return (
            <StyledTable>
                <THead>
                    <Tr>
                        <Th>
                            <DefaultText>{t('Name')}</DefaultText>
                        </Th>
                        {selectedMetricsIds.map((selectedMetric) => {
                            const text = allMetrics?.find((element) => element.id === selectedMetric)?.text;
                            const units = allMetrics?.find((element) => element.id === selectedMetric)?.units;
                            const orderingMain =
                                tableSortData?.metricId === selectedMetric && tableSortData?.periodType === 'mainPeriod'
                                    ? tableSortData.ordering
                                    : 'none';

                            const orderingCompare =
                                tableSortData?.metricId === selectedMetric &&
                                tableSortData?.periodType === 'comaprePeriod'
                                    ? tableSortData.ordering
                                    : 'none';
                            return (
                                <Th key={selectedMetric}>
                                    <HeaderCellWrapper>
                                        <OrderSwitcher
                                            ordering={orderingMain}
                                            id="mainPeriod"
                                            handleOrderToggle={(data) =>
                                                handleOrderToggle({
                                                    ...data,
                                                    metricId: selectedMetric,
                                                    periodType: data.id,
                                                })
                                            }
                                            widgetName={'Tenants'}
                                            position="left"
                                        />

                                        <DefaultText>{`${t(text || selectedMetric, { ns: 'metrics' })}, ${t(
                                            units || '',
                                        )}`}</DefaultText>
                                        <OrderSwitcher
                                            id="comaprePeriod"
                                            ordering={orderingCompare}
                                            handleOrderToggle={(data) =>
                                                handleOrderToggle({
                                                    ...data,
                                                    metricId: selectedMetric,
                                                    periodType: data.id,
                                                })
                                            }
                                            widgetName={'Tenants'}
                                            position="right"
                                        />
                                    </HeaderCellWrapper>
                                </Th>
                            );
                        })}
                    </Tr>
                </THead>
                <tbody>
                    {sortedTenants.map((element) => {
                        const extendedTenant = extendedTenantsById[element.id];

                        return extendedTenant?.tenantData ? (
                            <Tr key={extendedTenant?.tenantData?.id}>
                                <Td>
                                    <NameWrapper>
                                        <DefaultText>{extendedTenant?.tenantData?.name}</DefaultText>

                                        <ContextualMenu
                                            isContextualMenuOpen={
                                                contextMenuStatus.tenantId === extendedTenant.tenantData.id
                                                    ? contextMenuStatus.show
                                                    : false
                                            }
                                            contextualMenuBody={
                                                <DropdownBody
                                                    handleSelect={handleSelect}
                                                    tenantData={extendedTenant.tenantData}
                                                />
                                            }
                                            closeContextualMenu={closeContextualMenu}
                                            gapSpace={10}
                                        >
                                            <DotsWrapper onClick={toggleContextualMenu(extendedTenant.tenantData.id)}>
                                                <Dot />
                                                <Dot />
                                                <Dot />
                                            </DotsWrapper>
                                        </ContextualMenu>
                                    </NameWrapper>
                                </Td>
                                {selectedMetricsIds.map((selectedMetric) => {
                                    const mainValue: number | undefined | null =
                                        extendedTenant.metrics?.[selectedMetric]?.mainPeriod?.items[0].value;
                                    const units = allMetrics?.find((element) => element.id === selectedMetric)?.units;

                                    let compareValue = undefined;
                                    if (mainValue !== undefined && mainValue !== null) {
                                        const value =
                                            extendedTenant.metrics?.[selectedMetric]?.comparePeriod?.items[0].value;
                                        if (isNumber(value)) {
                                            const difference = getDifferenceBetweenNumbers(mainValue, value);
                                            compareValue = difference.percentDifference;
                                        }
                                    }
                                    return (
                                        <Td key={extendedTenant.tenantData.id + selectedMetric}>
                                            {extendedTenant.metricsLoading ? (
                                                t('Loading...')
                                            ) : extendedTenant.metricsError ? (
                                                t('Unable to retrieve data')
                                            ) : (
                                                <MetricValuesWrapper>
                                                    <DefaultText>
                                                        {valueFormatter({
                                                            value: mainValue,
                                                            showUnits: false,
                                                            units,
                                                        })}
                                                    </DefaultText>
                                                    <DefaultText value={compareValue}>
                                                        {valueFormatter({ value: compareValue, units: '%' })}
                                                    </DefaultText>
                                                </MetricValuesWrapper>
                                            )}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ) : null;
                    })}
                </tbody>
            </StyledTable>
        );
    },
);

export default Table;
