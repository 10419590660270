import {
    ChartOptions,
    ColorAxisOptions,
    NoDataOptions,
    PlotSeriesOptions,
    PlotTreemapOptions,
    SeriesTreemapOptions,
} from 'highcharts';
import { PercentColor } from '../enums';

export const COLOR_AXIS_OPTIONS: ColorAxisOptions = {
    startOnTick: false,
    endOnTick: false,
    min: -100,
    max: 100,
    stops: [
        [0.35, PercentColor.Negative1], // red
        [0.4, PercentColor.Negative2], // red-
        [0.45, PercentColor.Negative3], // red--
        [0.475, PercentColor.Negative4], // red---
        [0.485, PercentColor.Negative5], // red----
        [0.51499, PercentColor.Neutral], // yellow
        [0.52499, PercentColor.Positive5], // green----
        [0.54999, PercentColor.Positive4], // green---
        [0.59999, PercentColor.Positive3], // green--
        [0.64999, PercentColor.Positive2], // green-
        [1, PercentColor.Positive1], // green
    ],
    labels: {
        step: 1,
    },
    maxPadding: 1,
};

export const TREEMAP_PLOT_OPTIONS: PlotTreemapOptions = {
    layoutAlgorithm: 'strip',
    // animation: false,
    linecap: 'butt',
    cursor: 'pointer',
    color: 'rgba(255, 255, 255, 0.57)',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    borderWidth: 3,
    states: {
        select: {
            borderWidth: 2,
            borderColor: 'var(--color-primary)',
        },
        hover: {
            borderWidth: 0,
            shadow: true,
            brightness: 0.1,
        },
    },
};

export const SERIES_PLOT_OPTIONS: PlotSeriesOptions = {
    allowPointSelect: true,
    stickyTracking: false,
    dataLabels: {
        padding: 8,
        style: {
            color: '000000',
            textOutline: 'none',
            fontSize: '12px',
        },
        verticalAlign: 'middle',
    },
};

export const NO_DATA_OPTIONS: NoDataOptions = {
    style: {
        fontWeight: 'normal',
        fontSize: '16px',
        color: 'rgba(0,0,0,.4)',
    },
};

export const TREEMAP_SERIES_OPTIONS: SeriesTreemapOptions = {
    layoutAlgorithm: 'strip',
    colorKey: 'colorValue',
    type: 'treemap',
    levelIsConstant: false,
    dataLabels: {
        enabled: true,
    },
};

export const TREEMAP_CHART_OPTIONS: ChartOptions = {
    animation: true,
    zooming: {
        type: 'xy',
    },
    style: {
        fontFamily: 'Roboto',
    },
    spacingLeft: 0,
    spacingRight: 0,
};
