import { Text, Label, Tag } from 'react-konva';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { IObjectLabelProps } from './interfaces';
import { scaleTransformFoo } from '../../tools/scaleTransformFoo';

export const ObjectLabel = ({
    x,
    y,
    value,
    name,
    showTips = true,
    stageScale = 1,
    planScale = 1,
    selected = false,
    onMouseOver: onMouseOverTrans,
    ...props
}: IObjectLabelProps) => {
    const nameText = name ? `${name}` : '';
    const valueText = value !== undefined ? `\n(${value})` : '';
    const foo = (num: number) => scaleTransformFoo(num, stageScale, 0.8, planScale);
    const fill = selected ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,0.8)';
    // const fill = selected ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)';

    const onMouseOver = () => {
        onMouseOverTrans && onMouseOverTrans(name || '');
    };

    return x && y ? (
        <Label
            x={x}
            y={y}
            onMouseOver={onMouseOver}
            // opacity={selected ? 0.8 : 0.5}
        >
            <Tag
                fill={fill}
                pointerDirection={'down'}
                pointerWidth={foo(selected ? 10 : 10)}
                pointerHeight={foo(selected ? 10 : 10)}
                lineJoin={'round'}
            />
            <Text
                text={nameText + valueText}
                fontSize={foo(selected ? 10 : 10)}
                lineHeight={1.2}
                padding={foo(selected ? 6 : 3)}
                fill={'black'}
            />
        </Label>
    ) : null;
};
