import { isNumber, isUndefined } from 'lodash';
import { queryClient } from '../../../../../../../..';
import { TMetricResponse } from '../../../../../../../../General.interfaces';
import getDifferenceBetweenNumbers from '../../../../../../../../tools/getDifferenceBetweenNumbers';

interface IArgs {
    compareValue: number | null;
    mainPeriodQueryKey: unknown[];
    reportingObjectId: number;
}

/**
 * Получение разницы в процентах основного периода от периода сравнения
 */
const getPercentOfTheMain = (args: IArgs): number | null => {
    if (isNumber(args.compareValue)) {
        const mainPeriodQueryData = queryClient
            .getQueriesData({ queryKey: args.mainPeriodQueryKey })
            .filter((item) => Boolean(item[1]))
            .map((item) => item[1]) as TMetricResponse[][];

        let mainValue: number | null | undefined;

        for (const queries of mainPeriodQueryData) {
            if (!isUndefined(mainValue)) break;

            for (const metricResponse of queries) {
                if (!isUndefined(mainValue)) break;

                for (const metricResponseItem of metricResponse) {
                    if (!isUndefined(mainValue)) break;

                    if (metricResponseItem.context.data_objects[0].id === args.reportingObjectId) {
                        mainValue = metricResponseItem.items[0].value;
                    }
                }
            }
        }

        if (isNumber(mainValue)) {
            return Number(getDifferenceBetweenNumbers(mainValue, args.compareValue).percentDifference);
        }
    }
    return null;
};

export default getPercentOfTheMain;
