import { isNull, isNumber } from 'lodash';
import { IMetric, IPassway2DataObjRow, IPeriod, TMetricResponse } from '../../../../../General.interfaces';
import { IRatingsDataAdapterResponseItem, TVisual } from '../interfaces';
import { filterValidDateRanges } from '../../../../../tools/filterValidDateRanges';
import { RatingPasswaysTrafficIds } from '../../../WidgetsManager/enums';
import getDifferenceBetweenNumbers from '../../../../../tools/getDifferenceBetweenNumbers';
import { valueFormatter } from '../../../../../tools/Strings/valueFormatter';
import { getColorFromPercent } from '../../../../../tools/getColorFromPercent';
import { ZONES_WORD } from '../../../../../constants/constants';

interface IArgs {
    numberToShow?: number;
    /** Ответ от сервера. Сюда приходит ответ от запроса метрик */
    response: TMetricResponse[];
    /** Все метрики */
    allMetrics: IMetric[];
    /** Визуальные настройки виджета */
    visual: TVisual;
    /** Связи passway с dataobj */
    passway2DataObj?: IPassway2DataObjRow[];
    /** Основной период */
    mainPeriod: IPeriod;
}

const ratingDefaultPasswaysTrafficAdapter = (args: IArgs): IRatingsDataAdapterResponseItem[] => {
    const { response, allMetrics, visual, passway2DataObj, mainPeriod, numberToShow = 5 } = args;

    const metric = allMetrics.find((metric) => metric.id === response?.[0]?.[0]?.context?.metric);

    const mainArray = response.find((item) => {
        return item?.[0]?.['context']?.alias ? item?.[0]?.['context']?.alias.includes('mainPeriod') : false;
    });

    const compareArray = response.find((item) => {
        return item?.[0]?.['context']?.alias ? item?.[0]?.['context']?.alias.includes('comparePeriod') : false;
    });

    const filteredPassway2DataObjIds = filterValidDateRanges(passway2DataObj || [], mainPeriod.period)
        ?.filter((relation) => {
            const passwayType = String(visual?.passwaysTrafficType?.[0]?.id);
            switch (true) {
                case passwayType === RatingPasswaysTrafficIds.Floor: {
                    if (Number(relation.dataobj_marker) === Number(visual?.passwaysTrafficFloor?.[0]?.text)) {
                        return true;
                    }
                    break;
                }
                case passwayType === RatingPasswaysTrafficIds.Location: {
                    if (relation.dataobj_type === RatingPasswaysTrafficIds.Location) {
                        return true;
                    }
                    break;
                }

                case passwayType === RatingPasswaysTrafficIds.All: {
                    return true;
                }

                case passwayType.includes(ZONES_WORD): {
                    if (relation.dataobj_marker === visual?.passwaysTrafficZone?.[0]?.text) {
                        return true;
                    }
                    break;
                }
                default:
                    return true;
            }
            return false;
        })
        .map((item) => item.passway_dataobj_id);

    const filteredMainArray =
        mainArray
            ?.sort((a, b) => {
                return Number(b.items[0].value) - Number(a.items[0].value);
            })
            .filter((item, i) => {
                const itemValue = item.items?.[0]?.value as number | null;
                const dataObjectId = item.context?.data_objects?.[0]?.id;

                return i < numberToShow && !isNull(itemValue) && filteredPassway2DataObjIds.includes(dataObjectId);
            }) || [];

    const mainArrayTotal = filteredMainArray.reduce((acc, value) => {
        const metricValue = value.items[0]?.value as number | null;
        if (metricValue) {
            acc += metricValue;
        }
        return acc;
    }, 0);

    const max = filteredMainArray[0]?.items?.[0]?.value as number | null | undefined;

    const dataStructure = filteredMainArray.map((item) => {
        const { id, name } = item.context.data_objects[0];
        const mainValue = item.items?.[0]?.value as number | null | undefined;
        const percentOfMax = (isNumber(mainValue) && isNumber(max) && Math.round((100 * mainValue) / max)) || 0;
        const percentOfTotal =
            (isNumber(mainValue) && isNumber(mainArrayTotal) && (100 * mainValue) / mainArrayTotal) || 0;
        const compareData = compareArray?.find((item) => item.context.data_objects[0].id === id);
        const compareValue = compareData?.items[0].value as number | null | undefined;
        const diff =
            (isNumber(mainValue) && isNumber(compareValue) && getDifferenceBetweenNumbers(mainValue, compareValue)) ||
            null;

        return {
            name,
            mainValue: {
                value: valueFormatter({ value: mainValue, precision: metric?.round_decimal_places }),
                percentOfMax,
                percentOfTotal,
            },
            diff: {
                value: valueFormatter({
                    value: diff?.percentDifference,
                    precision: 1,
                    showSpaceBetweenNumberAndUnit: false,
                    units: '%',
                }),
                color: getColorFromPercent(diff?.percentDifference),
            },
        };
    });

    return dataStructure;
};

export default ratingDefaultPasswaysTrafficAdapter;
