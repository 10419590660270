import { FC } from 'react';
import { ITableCustomComponentProps } from '../../interfaces';
import { ValueWrapper } from './styles';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import Tooltip from '../../../../../../../components/Tooltip/Tooltip';

const TableCompareValue: FC<ITableCustomComponentProps> = (props) => {
    const { t } = useTranslation();

    const onCellClick = () => {
        props.openDetailedPeriodModal && props.openDetailedPeriodModal(props.cell.additionalOptions);
    };

    return (
        <span onClick={onCellClick}>
            <Tooltip contentWrapperStyles={{ width: '100%', height: '100%' }} content={t('Show details')}>
                <ValueWrapper value={props.cell.additionalOptions?.compareValue?.percentDifference}>
                    {props.cell.value}
                </ValueWrapper>
            </Tooltip>
        </span>
    );
};

export default TableCompareValue;
