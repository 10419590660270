import { IPeriod } from '../../../../../../General.interfaces';
import { IRequestAverageIntersectionsArgs } from '../../../../../../tools/API/interfaces';

interface IArgs {
    mainReportingObjectId: number;
    contraReportingObjectsIds: number[];
    signal: AbortSignal;
    token: string;
    dateRanges: IPeriod[];
    period: IPeriod;
}

const generateAverageIntersectionsQueryData = (args: IArgs): IRequestAverageIntersectionsArgs | null => {
    const { dateRanges, period, contraReportingObjectsIds, mainReportingObjectId, signal, token } = args;
    const periodDateRnage = dateRanges?.find((element) => element.id === period.id);

    if (periodDateRnage) {
        return {
            time_range: [periodDateRnage?.period!.dateFrom, periodDateRnage?.period!.dateTo],
            contra_obj_ids: contraReportingObjectsIds,
            main_obj_id: mainReportingObjectId,
            signal,
            token,
        };
    }

    return null;
};

export default generateAverageIntersectionsQueryData;
