import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PanelManagerWrapper, StyledMenuItem } from './styles/styles';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import './styles/styles.scss';
import _ from 'lodash';
import { useAppDispatch } from '../../../hooks/useSettings';
import { Divider, MenuList } from '@fluentui/react-components';
import {
    AddCircle20Regular,
    Edit20Regular,
    AddSquareMultipleRegular,
    ArrowReset20Regular,
    DocumentPdf20Regular,
    Save20Regular,
    ArrowShuffleFilled,
    ArrowShuffleOffFilled,
    EditFilled,
    Share20Regular,
    CopySelect20Regular,
} from '@fluentui/react-icons';
import IconButton from '../../IconButton/IconButton';
import Tooltip from '../../Tooltip/Tooltip';
import ContextualMenu from '../../ContextualMenu/ContextualMenu';
import {
    Dashboards_Reducer_Values,
    patchProjectDashboard,
    resetToDefaultDashboards,
    resetToProjectDashboard,
    toggleEditPanelMode,
} from '../../../Dashboards.reducer';
import { generalReducerValues } from '../../../General.reducer';
import Stack from '../../Stack/Stack';
import PDFDownload from '../../WidgetAdditionalControls/components/PDFDownload/PDFDownload';
import { IProps } from './interfaces';
import Modals from 'src/components/Modals/Modals';
import MakeSharedModalBody from './components/MakeSharedModalBody/MakeSharedModalBody';
import DuplicateModalBody from './components/DuplicateModalBody/DuplicateModalBody';

const PanelsManager: React.FC<IProps> = ({
    setManageModalStatus,
    userDashboards,
    setAddNewStatus,
    setEditStatus,
    selectedKey,
    menuPermissions,
    panelRef,
}) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const { editPanelMode } = useSelector(Dashboards_Reducer_Values);
    const dispatch = useAppDispatch();
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const [shareModalStatus, setShareModalStatus] = useState({ show: false });
    const [duplicateModalStatus, setDuplicateModalStatus] = useState({ show: false });

    const { t } = useTranslation();

    useEffect(() => {
        if (editPanelMode) {
            dispatch(toggleEditPanelMode());
        }
    }, [currentModuleID, selectedKey]);

    const tabListItems = useMemo(() => {
        const result = userDashboards
            .filter((item) => item.module_id === currentModuleID)
            .map((item) => {
                return { id: item.dashboard_key, text: item.dashboard_name };
            });
        return _.sortBy(result, 'text');
    }, [currentModuleID, userDashboards]);

    const onChangeDashboardClick = () => {
        closeContextualMenu();
        setManageModalStatus({ show: true });
    };

    const resetDashboard = () => {
        if (selectedKey) {
            closeContextualMenu();
            dispatch(
                resetToDefaultDashboards({
                    dashboardKey: selectedKey,
                    moduleId: currentModuleID,
                }),
            );
        }
    };

    const resetToProject = () => {
        if (selectedKey) {
            closeContextualMenu();
            dispatch(
                resetToProjectDashboard({
                    dashboardKey: selectedKey,
                    moduleId: currentModuleID,
                }),
            );
        }
    };

    const onSaveAsProjectClick = () => {
        if (selectedKey) {
            const currentDashboard = userDashboards.find(
                (item) => item.dashboard_key === selectedKey && item.module_id === currentModuleID,
            );

            closeContextualMenu();
            currentDashboard && dispatch(patchProjectDashboard({ dashboard: currentDashboard }));
        }
    };

    const onAddNewDashboardClick = () => {
        closeContextualMenu();
        setAddNewStatus({ show: true });
    };

    const onEditClick = () => {
        closeContextualMenu();
        setEditStatus({ show: true });
    };

    const onSharePanelClick = () => {
        closeContextualMenu();
        setShareModalStatus({ show: true });
    };

    const onDuplicatePanelClick = () => {
        closeContextualMenu();
        setDuplicateModalStatus({ show: true });
    };

    const openContextualMenu = () => {
        setContextualMenuOpen(true);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const isNonDefaultExist = tabListItems.filter((item) => !item.id.includes('default')).length > 0;
    const currentPanelName = tabListItems.find((item) => item.id === selectedKey)?.text ?? '';

    return (
        <PanelManagerWrapper>
            <Modals
                title={t('Share current panel')}
                closeModal={() => setShareModalStatus({ show: false })}
                modalStatus={shareModalStatus}
            >
                <MakeSharedModalBody closeModal={() => setShareModalStatus({ show: false })} />
            </Modals>
            <Modals
                title={t('Duplicate current panel')}
                closeModal={() => setDuplicateModalStatus({ show: false })}
                modalStatus={duplicateModalStatus}
            >
                <DuplicateModalBody closeModal={() => setDuplicateModalStatus({ show: false })} />
            </Modals>
            <Tooltip content={t('Panels management')} showTooltip={!isContextualMenuOpen} positioning="bottom">
                <ContextualMenu
                    isContextualMenuOpen={isContextualMenuOpen}
                    closeContextualMenu={closeContextualMenu}
                    contextualMenuBody={
                        <MenuList>
                            {menuPermissions.addWidgets && (
                                <StyledMenuItem data-test-id={`Add widgets`} onClick={onChangeDashboardClick}>
                                    <Stack alignItems="center" gap={5}>
                                        <AddSquareMultipleRegular style={{ fontSize: 20 }} />
                                        <span>{t('Add widgets')}</span>
                                    </Stack>
                                </StyledMenuItem>
                            )}

                            <Divider />

                            {menuPermissions.addNewPanel && (
                                <StyledMenuItem
                                    data-test-id={`Add new dashboard panel`}
                                    onClick={onAddNewDashboardClick}
                                >
                                    <Stack alignItems="center" gap={5}>
                                        <AddCircle20Regular />
                                        <span>{t('Add new panel')}</span>
                                    </Stack>
                                </StyledMenuItem>
                            )}

                            {isNonDefaultExist && menuPermissions.editPanels && !selectedKey?.includes('default') && (
                                <StyledMenuItem data-test-id={`Edit dashboard panels`} onClick={onEditClick}>
                                    <Stack alignItems="center" gap={5}>
                                        <Edit20Regular />
                                        <span>{t('Edit panels')}</span>
                                    </Stack>
                                </StyledMenuItem>
                            )}

                            {/* {menuPermissions.saveAsProject && (
                                <StyledMenuItem data-test-id={`Save for all users`} onClick={onSaveAsProjectClick}>
                                    <Stack alignItems="center" gap={5}>
                                        <Save20Regular />
                                        <span>{t('Save for all users')}</span>
                                    </Stack>
                                </StyledMenuItem>
                            )} */}

                            <StyledMenuItem data-test-id={`Duplicate current panel`} onClick={onDuplicatePanelClick}>
                                <Stack alignItems="center" gap={5}>
                                    <CopySelect20Regular />
                                    <span>{t('Duplicate current panel')}</span>
                                </Stack>
                            </StyledMenuItem>

                            {menuPermissions.saveAsShared && (
                                <StyledMenuItem data-test-id={`Share current panel`} onClick={onSharePanelClick}>
                                    <Stack alignItems="center" gap={5}>
                                        <Share20Regular />
                                        <span>{t('Share current panel')}</span>
                                    </Stack>
                                </StyledMenuItem>
                            )}

                            {menuPermissions.resetToDefault && (
                                <>
                                    <Divider />

                                    <StyledMenuItem data-test-id={`Reset to default`} onClick={resetDashboard}>
                                        <Stack alignItems="center" gap={5}>
                                            <ArrowReset20Regular />
                                            <span>{t('Reset to default')}</span>
                                        </Stack>
                                    </StyledMenuItem>

                                    {/* {menuPermissions.resetToProject && (
                                        <StyledMenuItem data-test-id={`Reset to last saved`} onClick={resetToProject}>
                                            <Stack alignItems="center" gap={5}>
                                                <ArrowReset20Regular />
                                                <span>{t('Reset to last saved')}</span>
                                            </Stack>
                                        </StyledMenuItem>
                                    )} */}
                                </>
                            )}
                            <Divider />

                            <StyledMenuItem data-test-id={`Download dashboard PDF`} onClick={closeContextualMenu}>
                                <PDFDownload
                                    customContent={
                                        <Stack alignItems="center" gap={5}>
                                            <DocumentPdf20Regular />
                                            <span>{t('Download PDF')}</span>
                                        </Stack>
                                    }
                                    showTooltip={false}
                                    targetRef={panelRef}
                                    fileName={currentPanelName}
                                />
                            </StyledMenuItem>

                            {menuPermissions.moveMode && (
                                <StyledMenuItem
                                    data-test-id={`Toggle editPanelMode`}
                                    onClick={() => {
                                        dispatch(toggleEditPanelMode());
                                        closeContextualMenu();
                                    }}
                                >
                                    <Stack alignItems="center" gap={5}>
                                        {editPanelMode ? (
                                            <ArrowShuffleOffFilled fontSize={20} />
                                        ) : (
                                            <ArrowShuffleFilled fontSize={20} />
                                        )}
                                        <span>
                                            {editPanelMode ? t('Disable moving mode') : t('Enable moving mode')}
                                        </span>
                                    </Stack>
                                </StyledMenuItem>
                            )}
                        </MenuList>
                    }
                >
                    <IconButton
                        styles={{ paddingBottom: 7, paddingRight: 5 }}
                        size={20}
                        onClick={openContextualMenu}
                        icon={<EditFilled />}
                    />
                </ContextualMenu>
            </Tooltip>
        </PanelManagerWrapper>
    );
};

export default PanelsManager;
