import { createSlice } from '@reduxjs/toolkit';
import { IReducerState } from './interfaces';
import { RootState } from '../../../store';

const initialState: IReducerState = {
    moduleName: 'Performance:Ratings',
};

const Performance_Ratings_Module_Reducer = createSlice({
    name: 'Performance_Ratings_Module_Reducer',
    initialState,
    reducers: {},
});

// export const {} = Performance_Ratings_Module_Reducer.actions;

export const Performance_Ratings_Module_Reducer_Values = (state: RootState) => state.Performance_Ratings_Module_Reducer;

export default Performance_Ratings_Module_Reducer.reducer;
