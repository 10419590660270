import React, { useEffect, useMemo } from 'react';
import { IProps } from './interfaces';
import { StIcon, Wrapper } from './styles';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import Select from '../Select/Select';
import { TNestedOptions, TSelectedOptions } from '../Select/interfaces';
import { useAppDispatch } from '../../../hooks/useSettings';
import useTranslation from 'src/hooks/useTranslation/useTranslation';

/**
 * Компонент для отображения селекта с выбором метрик
 * @param dropdownWidth Ширина селекта
 * @param showFilter Флаг, отвечающий за показ фильтра
 * @param mode Режим работы селетка
 */
const MetricsSelect: React.FC<IProps> = ({
    dropdownWidth = 'md',
    showFilter = true,
    name = 'selectedMetrics',
    maxSelected,
    mode = 'multi',
    localCurrentOptions,
    changeOptions,
    disabled = false,
    label = 'Metrics',
    ...props
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['metrics', 'translation']);
    const { allMetrics, currentModuleID, selectedLocationId } = useSelector(generalReducerValues);
    // const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    /**
     * Если у юзера пропали пермишшены на какието метрики, которые были ранее выбраны,
     * то этот хук исключает их и перезаписывает settings
     */

    useEffect(() => {
        if (localCurrentOptions?.[name] && allMetrics.length && selectedLocationId) {
            const allPermittedMetrics = allMetrics.map((item) => item.id);
            const permittedMetrics = localCurrentOptions?.[name].filter((item: string) =>
                allPermittedMetrics.includes(item),
            );
            if (localCurrentOptions?.[name]?.some((item: string) => !permittedMetrics.includes(item))) {
                changeOptions({
                    newOptions: {
                        [name]: permittedMetrics,
                    },
                });
            }
        }
    }, [allMetrics, currentModuleID, JSON.stringify(localCurrentOptions?.[name]), selectedLocationId]);

    const options = useMemo(() => {
        const obj = {};
        allMetrics
            .map((item) => {
                return { ...item, text: t(item.text), section: t(item.section) };
            })
            .forEach((item) => {
                if (obj[item.section]) {
                    obj[item.section] = [...obj[item.section], item];
                } else {
                    obj[item.section] = [item];
                }
            });
        const result: TNestedOptions = Object.keys(obj).map((key) => {
            const objects = obj[key];
            const section = key;
            return { section, objects };
        });
        return result;
    }, [allMetrics, t]);

    const outsideSelected: TSelectedOptions = useMemo(() => {
        const metricIds = localCurrentOptions?.[name] || [];
        return allMetrics
            ?.filter((item) => metricIds.includes(item.id))
            .map((item) => ({
                ...item,
                section: t(item.section),
                text: t(item.text),
            }));
    }, [localCurrentOptions?.[name], t, allMetrics]);

    const handleSelect = (args: TSelectedOptions) => {
        const selectedMetrics = args.map((item) => String(item.id));
        props.handleSelect && props.handleSelect(selectedMetrics);
        selectedLocationId &&
            changeOptions({
                newOptions: {
                    [name]: selectedMetrics,
                },
            });
    };

    useEffect(() => {
        if (localCurrentOptions && (!localCurrentOptions?.[name] || !localCurrentOptions?.[name]?.length)) {
            handleSelect([allMetrics.filter((item) => item.section !== 'Common')[0]]);
        }
    }, [allMetrics, localCurrentOptions]);

    return (
        <Wrapper>
            <Select
                testId={`${currentModuleID}_selectedMetrics`}
                options={options}
                dropdownWidth={dropdownWidth}
                text={t('Selected')}
                minSelected={1}
                maxSelected={maxSelected}
                dropdownAlign="left"
                showFilter={showFilter}
                mode={mode}
                labelText={`${t(label)}${maxSelected ? ` (${t('max')}: ${maxSelected})` : ''}`}
                iconType="chevron-down"
                handleSelect={handleSelect}
                outsideSelected={outsideSelected}
                additionalControl={<StIcon />}
                disabled={disabled}
            />
        </Wrapper>
    );
};

export default MetricsSelect;
