import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../../../components/Button/Button';

import MainPeriodSelect from '../../../../../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import ObjectsSelect from '../../../../../../../components/Selects/ObjectsSelect/ObjectsSelect';
import { changeOptions } from '../../../../../../../components/UserSettings/reducer';
import { DS } from '../../../../../../../constants/constants';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useAppDispatch } from '../../../../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from '../../reducer';
import ArchiveTable from './components/ArchiveTable/ArchiveTable';
import { Footer, ModalWrapper, SelectsWrapper } from './styles';

interface IProps {
    onCloseModal: () => void;
    modalStatus: { show: boolean };
}

const ArchiveModal: React.FC<IProps> = ({ onCloseModal, modalStatus }) => {
    const dispatch = useAppDispatch();
    const [showPreview, setShowPreview] = useState(false);

    const { keyWord } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);
    const { currentModuleID } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const validObjOptions = [t('Tenants')];

    return (
        <ModalWrapper showPreview={showPreview}>
            <SelectsWrapper>
                <MainPeriodSelect
                    name={`mainPeriod${DS}${keyWord}Archive`}
                    label={`Show reports of period`}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <ObjectsSelect
                    name={`objectsToShow${DS}${keyWord}`}
                    label={`Show reports of tenants`}
                    validOptions={validObjOptions}
                    canSelectEntireSection={true}
                    dataObjectFilters={['data_objects_tenant']}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
            </SelectsWrapper>
            <ArchiveTable />
            <Footer>
                <Button onClick={onCloseModal} text={t('Close')} />
            </Footer>
        </ModalWrapper>
    );
};

export default ArchiveModal;
