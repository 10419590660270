import { ReactNode } from 'react';
import { IDataObjectsSelectFilter, TDataObjectsFilterTypes } from '../../interfaces';
import { DataObjectsFilter } from '../DataObjectsFilter';
import {
    IDataObjectsTenantFilterState,
    IFilterDataObjectsArgs,
    IGetComponentProps,
    IGetFilterDataArgs,
    ITenantFilterType,
} from './interfaces';
import { IReportingObject } from '../../../../General.interfaces';
import { DEFAULT_TENANT_FILTER_STATE } from './constants/constants';
import Filter from './components/Filter/Filter';
import { cloneDeep, isBoolean } from 'lodash';
import { filterValidDateRanges } from '../../../../tools/filterValidDateRanges';

export class DataObjectsTenantFilter implements DataObjectsFilter {
    name: TDataObjectsFilterTypes = 'data_objects_tenant';
    active = true;

    getFilterData(args: IGetFilterDataArgs): IDataObjectsSelectFilter {
        const { rawData } = args;

        const rawTenantFilterTypesByKey: undefined | { [key: string]: ITenantFilterType } = (
            rawData?.filter_state as IDataObjectsTenantFilterState | undefined
        )?.tenant_filter_types?.reduce((acc, value) => {
            acc[value.key] = value;
            return acc;
        }, {});

        const filterTypes = DEFAULT_TENANT_FILTER_STATE.map((element) => {
            const state: undefined | ITenantFilterType = rawTenantFilterTypesByKey?.[element.key];
            return { ...element, active: state ? state.active : element.active };
        });
        return {
            active: isBoolean(rawData?.active) ? Boolean(rawData?.active) : this.active,
            filter_state: {
                tenant_filter_types: filterTypes,
            },
            filter_type: this.name,
        };
    }

    getComponent(props: IGetComponentProps): ReactNode {
        return <Filter {...props} />;
    }

    filterDataObjects(args: IFilterDataObjectsArgs): { [reportingObjectType: string]: IReportingObject[] } {
        const { reportingObjectsByType, tenant2PlaceByTenantId, filterState, mainDateRanges, mainPeriod } = args;
        const mainPeriodDateRange = mainDateRanges?.find((element) => element.id === mainPeriod?.id)?.period;
        const result = cloneDeep(reportingObjectsByType);

        if (mainPeriodDateRange && Object.keys(tenant2PlaceByTenantId).length) {
            const activeFilterType = filterState.tenant_filter_types.find((element) => element.active);

            if (activeFilterType) {
                switch (activeFilterType.key) {
                    case 'showActiveTenants': {
                        if (result['tenant']) {
                            const activeTenants = result['tenant'].filter(
                                (element) =>
                                    tenant2PlaceByTenantId[element.id] &&
                                    filterValidDateRanges(tenant2PlaceByTenantId[element.id], mainPeriodDateRange)
                                        .length,
                            );

                            result['tenant'] = activeTenants;
                        }
                        break;
                    }
                    case 'showInactiveTenants': {
                        if (result['tenant']) {
                            const inactiveTenants = result['tenant'].filter(
                                (element) =>
                                    !tenant2PlaceByTenantId[element.id] ||
                                    (tenant2PlaceByTenantId[element.id] &&
                                        !filterValidDateRanges(tenant2PlaceByTenantId[element.id], mainPeriodDateRange)
                                            .length),
                            );

                            result['tenant'] = inactiveTenants;
                        }
                        break;
                    }

                    default:
                        break;
                }
            }
        }
        return result;
    }
}
