import React, { useEffect, useState } from 'react';
import { ButtonsWrapper, HeaderWrapper, TextWrapper } from './styles/styles';
import { IProps } from './interfaces';
import Button from '../../../Button/Button';
import './styles/styles.scss';
import { getHeaderText } from '../../core';
import { useTranslation } from 'react-i18next';

const Header: React.FC<IProps> = ({ modeDidChanged, mode = 'months', firstSelectedDate, secondSelectedDate }) => {
    const [text, setText] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (firstSelectedDate && secondSelectedDate) {
            setText(() =>
                getHeaderText({
                    dateFrom: firstSelectedDate,
                    dateTo: secondSelectedDate,
                    mode,
                    t,
                }),
            );
        } else {
            setText(() => '');
        }
    }, [firstSelectedDate, mode, secondSelectedDate]);

    const onClick = (mode: string) => () => {
        modeDidChanged(mode);
    };

    const buttons = (
        <ButtonsWrapper>
            <span>{`${t('Show by')}:`}</span>
            <Button
                appearance={mode === 'years' ? 'primary' : 'outline'}
                text={t('Years')}
                size="small"
                onClick={onClick('years')}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            />
            <Button
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                appearance={mode === 'months' ? 'primary' : 'outline'}
                text={t('Months')}
                size="small"
                onClick={onClick('months')}
            />
        </ButtonsWrapper>
    );
    return (
        <HeaderWrapper>
            {buttons}
            <TextWrapper>{text}</TextWrapper>
        </HeaderWrapper>
    );
};

export default Header;
