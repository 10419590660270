import { useSelector } from 'react-redux';
import { Dashboards_Reducer_Values, patchUserDashboards } from '../../../Dashboards.reducer';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks/useSettings';
import { usePrevious } from '../../../hooks/usePrevious';
import { differenceWith, isEqual, isUndefined, omit, omitBy } from 'lodash';
import { IDashboard, TLayouts } from '../interfaces';

/**
 * Кастомный хук для запроса данных
 */
export const usePatchUserDashboards = () => {
    const { userDashboards } = useSelector(Dashboards_Reducer_Values);
    const prevUserDashboards = usePrevious(userDashboards) as IDashboard[] | undefined;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (prevUserDashboards?.length && userDashboards.length) {
            const current = userDashboards.map(dashboardMapHelper);
            const prev = (prevUserDashboards || []).map(dashboardMapHelper);

            const changedDashboards = differenceWith(current, prev, isEqual);

            if (changedDashboards.length) {
                dispatch(patchUserDashboards({ dashboards: changedDashboards }));
            }
        }
    }, [userDashboards, prevUserDashboards]);
};

const dashboardMapHelper = (item: IDashboard) => {
    return omit(
        {
            ...item,
            layouts: Object.entries(item.layouts).reduce((acc, [key, layout]) => {
                acc[key] = layout.map((item) => omitBy(omit(item, ['static']), isUndefined));
                return acc;
            }, {} as TLayouts),
        },
        ['updated_at', 'created_at'],
    );
};
