import { isNumber, isUndefined, max, sum } from 'lodash';
import { IReportingObject, TMetricResponse } from '../../../../General.interfaces';
import { DS } from '../../../../constants/constants';
import { IDataAdapterResponseItem } from '../interfaces';
import getDifferenceBetweenNumbers from '../../../../tools/getDifferenceBetweenNumbers';

interface IArgs {
    response: TMetricResponse[];
    reportingObjects?: IReportingObject[];
}

const dataAdapter = (args: IArgs): IDataAdapterResponseItem[] => {
    var { response, reportingObjects } = args;
    var result: IDataAdapterResponseItem[] = [];

    var tempResult: { [reportingObjectId: string]: Partial<IDataAdapterResponseItem> } = {};

    response.forEach((metricResponse) => {
        metricResponse.forEach((metricResponseItem) => {
            var { context, items } = metricResponseItem;
            var splittedAlias = (context.alias || '').split(DS);
            if (splittedAlias.length) {
                var periodType = splittedAlias[0] as 'mainPeriod' | 'comparePeriod';
                const reportingObject = reportingObjects?.find((item) => item.id === context.data_objects[0].id);
                var item = tempResult[context.data_objects[0].id] || {
                    name: reportingObject?.name || context.data_objects[0].name,
                    id: context.data_objects[0].id,
                };

                var period = { dateFrom: context.time_range[0], dateTo: context.time_range[1] };
                switch (periodType) {
                    case 'mainPeriod':
                        item.mainValue = {
                            value: items[0].value,
                            percentOfTotal: 0,
                            percentOfMax: 0,
                            period,
                        };
                        break;
                    case 'comparePeriod':
                        item.compareValues = [
                            ...(item.compareValues || []),
                            {
                                value: items[0].value,
                                percentDiffWithMain: 0,
                                period,
                            },
                        ];
                        break;

                    default:
                        break;
                }
                tempResult[context.data_objects[0].id] = item;
            }
        });
    });

    var mainValues = Object.values(tempResult).map((item) => item.mainValue?.value);

    var mainTotal = sum(mainValues);
    var mainMax = max(mainValues);

    Object.values(tempResult).forEach((item) => {
        const { name, mainValue, compareValues, id } = item;
        if (
            !isUndefined(name) &&
            !isUndefined(mainValue) &&
            !isUndefined(id) &&
            !isUndefined(compareValues) &&
            compareValues?.length
        ) {
            const resultItem: IDataAdapterResponseItem = {
                name,
                id,
                mainValue: {
                    ...mainValue,
                    percentOfTotal: mainValue.value ? (mainValue.value * 100) / mainTotal : null,
                    percentOfMax: mainValue.value && isNumber(mainMax) ? (mainValue.value * 100) / mainMax : null,
                },
                compareValues: compareValues.map((compareValue) => {
                    return {
                        ...compareValue,
                        percentDiffWithMain:
                            isNumber(mainValue?.value) && isNumber(compareValue.value) && compareValue.value > 0
                                ? Number(
                                      getDifferenceBetweenNumbers(mainValue!.value as number, compareValue.value)
                                          .percentDifference,
                                  )
                                : null,
                    };
                }),
            };
            result.push(resultItem);
        }
    });

    return result;
};

export default dataAdapter;
