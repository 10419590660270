import { useSelector } from 'react-redux';
import { userSettingsReducerValues } from '../../../../components/UserSettings/reducer';
import { useMemo } from 'react';
import { Network_Section_Reducer_Values } from '../../../../Chapters/Network/reducer';
import { QueryKey, useQueries, UseQueryOptions } from '@tanstack/react-query';
import { NETWORK_GENERAL_QUERY_KEY } from '../useNetworkQuery';
import { IGroupDataObjectsResponse, IReportingObject } from '../../../../General.interfaces';
import axios from 'axios';
import { generalReducerValues } from '../../../../General.reducer';
import { IGroupDataObjectsDAResult } from './interfaces';

export const AVAILABLE_OBJ_TYPES_FOR_GROUP = [
    { objectType: 'tenant', section: 'Tenants' },
    // { objectType: 'passway', section: 'Passways' },
];

/**
 * Хук для создания стуктуры соответствий имен однотипных объектов для разных локаций
 */
type TQueries = UseQueryOptions<IGroupDataObjectsResponse[], unknown, IGroupDataObjectsDAResult, QueryKey>[];

export var useFetchGroupDataObjects = () => {
    const allowedModules: string[] = ['network_tenants_overview'];

    var { urlsByServices, currentModuleID } = useSelector(generalReducerValues);

    var {
        currentOptions: { pls },
    } = useSelector(userSettingsReducerValues);
    var {
        cfg: { reportingObjectsByType },
    } = useSelector(Network_Section_Reducer_Values);

    const [groupsUrl] = useMemo(() => {
        if (urlsByServices?.['core/admin-service'])
            return [urlsByServices['core/admin-service'].GROUP_DATA_OBJECTS_URL];
        return [''];
    }, [urlsByServices]);

    var groupsDataAccessor = (reportingObjects: IReportingObject[]): Promise<IGroupDataObjectsResponse[]> => {
        const data = reportingObjects.map((obj) => {
            return { id: obj.id, object_name: obj.object_name, pl_id: obj.pl_id };
        });
        return axios.post(groupsUrl, data).then((response) => response.data);
    };

    var groupsDataAdapter = (response: IGroupDataObjectsResponse[]) => {
        return response;
    };

    return useQueries<TQueries>({
        queries: AVAILABLE_OBJ_TYPES_FOR_GROUP.filter((item) => {
            return Boolean(reportingObjectsByType[item.objectType]?.length);
        }).map((item) => {
            const objects = reportingObjectsByType[item.objectType];

            return {
                queryKey: [
                    NETWORK_GENERAL_QUERY_KEY,
                    'GROUP_DATA_OBJECTS',
                    item.objectType,
                    objects.map((item) => item.id),
                ],
                queryFn: () => groupsDataAccessor(objects),
                enabled: allowedModules.includes(currentModuleID),
                select: (response: IGroupDataObjectsResponse[]) => ({
                    objectType: item,
                    data: groupsDataAdapter(response),
                }),
            };
        }),
    });
};
