import { FC } from 'react';
import { IProps as ISelectsProps } from '../../interfaces';
import Stack from '../../../../../../../Stack/Stack';
import OnBoardMetricsSelect from '../OnBoardMetricsSelect/OnBoardMetricsSelect';
import OnBoardNetworkGroupObjectsSelect from '../OnBoardNetworkGroupObjectsSelect/OnBoardNetworkGroupObjectsSelect';

const NetworkObjectsByLocationsOnBoardSelects: FC<ISelectsProps> = (props) => {
    return (
        <Stack styles={{ padding: '0px 12px' }} gap={5}>
            <OnBoardMetricsSelect {...props} />
            <OnBoardNetworkGroupObjectsSelect {...props} />
        </Stack>
    );
};

export default NetworkObjectsByLocationsOnBoardSelects;
