import { TableCellLayout } from '@fluentui/react-components';
import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
    overflow: auto;
`;

export const StyledTableCellLayout = styled(TableCellLayout)<{
    align?: 'center' | 'left' | 'right';
    whiteSpace?: string;
}>`
    overflow: hidden;
    white-space: ${(p) => p.whiteSpace || 'normal'};
    text-align: ${(p) => p.align || 'initial'};
    justify-content: ${({ align = 'left' }) => {
        switch (align) {
            case 'right':
                return 'flex-end';
            case 'center':
                return 'center';
            case 'left':
            default:
                return 'flex-start';
        }
    }};
`;
