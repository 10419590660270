import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { useMemo } from 'react';
import { getRequest } from '../../../tools/API/appBackendAPI';
import { DECISION_TYPES_QUERY_KEY } from '../constants/constants';
import { useQuery } from '@tanstack/react-query';
import { IDecisionType } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Получение типов решений
 */
const useGetDecisionTypes = () => {
    const { selectedLocationId, token, urlsByServices } = useSelector(generalReducerValues);

    const [DecisionTypesUrl] = useMemo(() => {
        if (urlsByServices?.['app/app-backend']) {
            return [urlsByServices['app/app-backend'].MANAGEMENT_DECISION_TYPES_URL];
        }
        return [];
    }, [urlsByServices]);

    const [queryKey, queryFn] = useMemo(() => {
        if (selectedLocationId && DecisionTypesUrl) {
            return [
                [DECISION_TYPES_QUERY_KEY, selectedLocationId],
                () => getRequest(`${DecisionTypesUrl}?pl_id=${selectedLocationId}`, token, undefined, true),
            ];
        }
        return [[]];
    }, [DecisionTypesUrl, selectedLocationId, token]);

    return useQuery<AxiosResponse<IDecisionType[]>, unknown, IDecisionType[]>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn),
        select: (response) => response.data,
        staleTime: 6 * 3600 * 1000,
        retry: 0,
    });
};

export default useGetDecisionTypes;
