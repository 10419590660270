import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IExtendedDecision, IState } from './interfaces';

const initialState: IState = {
    moduleId: 'management-decisions/decisions-map',
    extendedDecisions: [],
};

const Decisions_DecisionsMap_Widget_Reducer = createSlice({
    name: 'Decisions_DecisionsMap_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Запись расширенных решений
         */
        storeExtendedDecisions: (state, action: PayloadAction<IExtendedDecision[]>) => {
            state.extendedDecisions = action.payload;
        },
    },
});

export const { storeExtendedDecisions } = Decisions_DecisionsMap_Widget_Reducer.actions;

export const Decisions_DecisionsMap_Widget_Reducer_Values = (state: RootState) =>
    state.Decisions_DecisionsMap_Widget_Reducer;

export default Decisions_DecisionsMap_Widget_Reducer.reducer;
