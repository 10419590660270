import { useEffect, useState } from 'react';
import { IDefaultDAResponse, ITreeMapData } from '../interfaces';
import { SeriesTreemapOptions } from 'highcharts';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import filterResponseResult from '../tools/filterResponseResult';
import { Performance_Dashboard_ByArea_Widget_Reducer_Values } from '../reducer';
import generateTreemapParentsById from '../tools/generateTreemapParentsById';
import useObjectTranslation from '../../../../../../hooks/useObjectTranslation';
import getParentIdByView from '../tools/getParentIdByView';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';
import { cloneDeep, sum } from 'lodash';
import useFetchData from '../api/useFetchData';

const useFilterAndExtendTenants = (): IDefaultDAResponse | undefined => {
    const { data } = useFetchData();
    const {
        currentModuleID,
        cfg: {
            dataObj2ProjectCategoryByObjId,
            reportingObjectsByType,
            tenant2FloorByTenantId,
            tenant2ZoneByTenantId,
            reportingObjectsById,
        },
        src: { dataObj2ProjectCategory },
        allMetrics,
    } = useSelector(generalReducerValues);

    const { objectsType, tenantsView, colorMetric } = useSelector(Performance_Dashboard_ByArea_Widget_Reducer_Values);
    const { getObjName } = useObjectTranslation(['translation', 'translationCategories']);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const [extendedData, setExtendedData] = useState<ITreeMapData[]>([]);

    /**
     * Фильтрация арендаторов
     */
    useEffect(() => {
        const dataToFilter = (data?.treeMapOptions.series?.[0] as SeriesTreemapOptions | undefined)?.data as
            | ITreeMapData[]
            | undefined;

        if (dataToFilter) {
            const filteredData = filterResponseResult({
                data: cloneDeep(dataToFilter),
                mainDateRanges: localCurrentOptions?.mainDateRanges,
                mainPeriod: localCurrentOptions?.mainPeriod,
                filters: localCurrentOptions?.['filters'],
                selectedObjectsType: objectsType,
                dataObj2ProjectCategory,
                tenant2FloorByTenantId,
                tenant2ZoneByTenantId,
                reportingObjectsById,
            });
            const colorMetricFromStore = allMetrics.find((item) => item.id === colorMetric);

            if (filteredData.length && objectsType === 'tenant' && tenantsView.id !== 'all' && colorMetricFromStore) {
                const parentsById: { [id: string]: ITreeMapData } = generateTreemapParentsById({
                    metricName: colorMetricFromStore.text,
                    units: colorMetricFromStore.units,
                    reportingObjectsByType,
                    tenantsView,
                    objectsType,
                    getObjName,
                });
                const extendedData: ITreeMapData[] = [];

                filteredData.forEach((item) => {
                    if (item.objectId) {
                        const parentId = getParentIdByView({
                            mainDateRanges: localCurrentOptions?.mainDateRanges,
                            mainPeriod: localCurrentOptions?.mainPeriod,
                            dataObj2ProjectCategoryByObjId,
                            objectId: item.objectId,
                            reportingObjectsByType,
                            tenant2ZoneByTenantId,
                            tenantsView,
                            parentsById,
                        });

                        if (parentId) {
                            parentsById[parentId].value = sum([parentsById[parentId].value, item.value]);
                            parentsById[parentId].mainValue = sum([parentsById[parentId].mainValue, item.mainValue]);
                            parentsById[parentId].compareValue = sum([
                                parentsById[parentId].compareValue,
                                item.compareValue,
                            ]);

                            const percentDifference = getDifferenceBetweenNumbers(
                                Number(parentsById[parentId].mainValue),
                                Number(parentsById[parentId].compareValue),
                            ).percentDifference;

                            if (isFinite(Number(percentDifference))) {
                                parentsById[parentId].colorValue = Number(percentDifference);
                            }
                            extendedData.push({
                                ...item,
                                parent: parentId,
                            });
                        }
                    }
                });

                extendedData.push(...Object.values(parentsById));

                setExtendedData(() => extendedData);
            } else {
                setExtendedData(() => filteredData);
            }
        }
    }, [
        data,
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        JSON.stringify(localCurrentOptions?.mainDateRanges),
        JSON.stringify(localCurrentOptions?.mainPeriod),
        JSON.stringify(localCurrentOptions?.['filters']),
        objectsType,
        allMetrics,
        tenantsView,
        colorMetric,
        reportingObjectsByType,
        dataObj2ProjectCategoryByObjId,
    ]);

    if (!data) {
        return undefined;
    }

    return {
        ...data,
        treeMapOptions: {
            ...data.treeMapOptions,
            series: [
                {
                    ...((data?.treeMapOptions.series?.[0] as SeriesTreemapOptions) || {}),
                    name: objectsType === 'tenant' ? tenantsView?.text : '',
                    allowTraversingTree: objectsType === 'tenant',
                    dataLabels: {
                        enabled: objectsType !== 'tenant',
                    },
                    levels:
                        objectsType === 'tenant'
                            ? [
                                  {
                                      level: 1,
                                      dataLabels: {
                                          enabled: true,
                                      },
                                  },
                              ]
                            : undefined,
                    data: extendedData,
                },
            ],
        },
    };
};

export default useFilterAndExtendTenants;
