import { FC } from 'react';
import { ItemsWrapper, Wrapper } from './styles';
import { IExtendedOption } from './hooks/interfaces';
import { IProps } from './interfaces';
import { TChartSettingsKeys } from '../../../../../../ChartSettings/interfaces';
import usePrepareData from './hooks/usePrepareData';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../../Selects/Select/Select';
import Checkbox from '../../../../../../Checkbox/Checkbox';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useSelector } from 'react-redux';

/**
 * Меню настроек диаграммы
 */
const ContextualMenuBody: FC<IProps> = ({
    updateChartSettingsByIdTrans,
    weekendsHighlightPossibility,
    chartSettings,
    chartId,
    series,
}) => {
    const { seriesSelectOptions } = usePrepareData({ series });
    const { t } = useTranslation();
    const { currentModuleID } = useSelector(generalReducerValues);

    const handleToggleHighlightWeekend = () => {
        chartSettings &&
            updateChartSettingsByIdTrans({
                value: !chartSettings.highlightWeekends,
                key: 'highlightWeekends',
                chartId,
            });
    };

    const onSelect = (key: TChartSettingsKeys) => (args: IExtendedOption[]) => {
        const selectedSeriesIds = args.map((series) => series.seriesData.id);
        updateChartSettingsByIdTrans({
            value: selectedSeriesIds,
            chartId,
            key,
        });
    };

    return (
        <Wrapper>
            <ItemsWrapper>
                <Checkbox
                    handleToggle={handleToggleHighlightWeekend}
                    selected={chartSettings?.highlightWeekends}
                    disabled={!weekendsHighlightPossibility}
                    label={t('Highlight weekend')}
                />
            </ItemsWrapper>
            <ItemsWrapper>
                <Select
                    testId={`${currentModuleID}_selectedSeriesIdsForTrend`}
                    handleSelect={onSelect('selectedSeriesIdsForTrend')}
                    outsideSelected={seriesSelectOptions?.filter((element) =>
                        chartSettings?.selectedSeriesIdsForTrend.includes(element.id),
                    )}
                    options={seriesSelectOptions as IExtendedOption[]}
                    labelText={t('Trend for')}
                    text={t('Selected')}
                    showFilter={false}
                    dropdownWidth="md"
                    mode="multi"
                />
            </ItemsWrapper>
            <ItemsWrapper>
                <Select
                    testId={`${currentModuleID}_selectedSeriesIdsForAverage`}
                    handleSelect={onSelect('selectedSeriesIdsForAverage')}
                    options={seriesSelectOptions as IExtendedOption[]}
                    outsideSelected={seriesSelectOptions?.filter((element) =>
                        chartSettings?.selectedSeriesIdsForAverage.includes(element.id),
                    )}
                    labelText={t('Average for')}
                    text={t('Selected')}
                    showFilter={false}
                    dropdownWidth="md"
                    mode="multi"
                />
            </ItemsWrapper>
        </Wrapper>
    );
};

export default ContextualMenuBody;
