import { FC } from 'react';
import { useSelector } from 'react-redux';

import { isMobileInboundWidth } from 'src/theme';

import DynamicsPMO from '../../../../../../../components/CommonWidgets/DynamicsPMO/DynamicsPMO';
import { CHART_DESKTOP_HEIGHT, CHART_MOBILE_HEIGHT, DS } from '../../../../../../../constants/constants';
import { generalReducerValues } from '../../../../../../../General.reducer';

import { IProps } from './interfaces';
import { Wrapper } from './styles';

const DetailedPeriodModal: FC<IProps> = (props) => {
    const {
        currentModuleID,
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const isMobile = isMobileInboundWidth(width);

    if (
        !props.additionalOptions ||
        !props.additionalOptions?.reportingObjectId ||
        !props.additionalOptions?.metric ||
        !props.additionalOptions.mainPeriod ||
        !props.additionalOptions.comparePeriod ||
        !reportingObjectsById[props.additionalOptions.reportingObjectId]
    ) {
        return <Wrapper />;
    }
    return (
        <Wrapper>
            <DynamicsPMO
                reportingObjects={[reportingObjectsById[props.additionalOptions.reportingObjectId]]}
                comparePeriods={[props.additionalOptions.comparePeriod]}
                widgetId={`DynamicsByPeriods${DS}DetailedPeriodModal`}
                mainPeriod={props.additionalOptions.mainPeriod}
                metrics={[props.additionalOptions.metric]}
                moduleId={currentModuleID}
                chartType={'periods'}
                viewSettings={{
                    showObjectSelect: false,
                    showMetricsSelect: false,
                    isShowTitle: false,
                    chartWrapperStyles: {
                        marginTop: 'var(--space-md)',
                        height: isMobile ? `${CHART_MOBILE_HEIGHT}px` : `${CHART_DESKTOP_HEIGHT}px`,
                    },
                }}
            />
        </Wrapper>
    );
};

export default DetailedPeriodModal;
