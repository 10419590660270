import { useSelector } from 'react-redux';
import { Wrapper, Footer, TabListWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { generalReducerValues } from '../../General.reducer';
import Shopster from './components/Shopster/Shopster';
import General from './components/General/General';
import Button from '../Button/Button';
import { ITabListOptionsItem } from '../TabList/interfaces';
import TabList from '../TabList/TabList';
import Stack from '../Stack/Stack';
import { userSettingsReducerValues } from '../UserSettings/reducer';
import { PresetReducerValues } from '../Preset/reducer';
import { patchRequest } from '../../tools/API/appBackendAPI';

export interface IExtendedTabListOptionsItem extends ITabListOptionsItem {
    component?: JSX.Element;
}

interface IProps {
    /** Закрытие модального окна  */
    closeModal: () => void;
}

/**
 * Модальное окно для отображения настроек кабинета для пользователя
 * @info Хук useCabinetPreferences Вызывается в App.tsx
 */
const CabinetPreferences: FC<IProps> = ({ closeModal }) => {
    const { settings, accParamsID } = useSelector(userSettingsReducerValues);
    const { token, user, urlsByServices, initialDataReceived } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const TAB_LIST_ITEMS: IExtendedTabListOptionsItem[] = [
        { id: 'preferences', text: 'General', component: <General closeModal={closeModal} /> },
        { id: 'metricsSettingsWiFi', text: 'Metrics settings (Wi-Fi)', component: <Shopster /> },
    ];
    const [selectedTab, setSelectedTab] = useState(TAB_LIST_ITEMS[0]);
    const [tabListItems, setTabListItems] = useState<IExtendedTabListOptionsItem[]>(TAB_LIST_ITEMS);

    // useEffect(() => {
    //     if (user?.permissions.includes('app/client | cabinet-settings-button | feature/view')) {
    //         setTabListItems([
    //             ...TAB_LIST_ITEMS,
    //             // { id: 'option 3', text: 'Option 3' },
    //         ]);
    //     }
    // }, [user?.permissions]);

    const baseUrl = useMemo(() => {
        if (urlsByServices) return urlsByServices?.['app/app-backend'].ACCOUNT_PARAMETERS_URL;
        return null;
    }, [urlsByServices]);

    const tabListSelectHandler = (id: string) => {
        const currentTab = tabListItems?.find((element) => element.id === id);
        currentTab && setSelectedTab(currentTab);
    };

    const onOKClick = () => {
        if (user?.id && token && initialDataReceived && baseUrl) {
            patchRequest({ url: `${baseUrl}${accParamsID}/`, token, data: { id: accParamsID, params: settings } }).then(
                () => {
                    window.location.reload();
                },
            );
        }
    };

    return (
        <Wrapper>
            <TabListWrapper>
                <TabList selectedValue={selectedTab.id} selectHandler={tabListSelectHandler} options={tabListItems} />
            </TabListWrapper>

            {selectedTab.component}
            {selectedTab.id === 'metricsSettingsWiFi' && (
                <Footer>
                    <Stack gap={10}>
                        <Button onClick={closeModal} text={t('Cancel')} />
                        <Button appearance="primary" onClick={onOKClick} text={t('Apply and restart')} />
                    </Stack>
                </Footer>
            )}
        </Wrapper>
    );
};

export default CabinetPreferences;
