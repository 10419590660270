import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TLoadingData } from '../../../../../General.interfaces';
import { AppThunk, RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

const initialState: IReducerState = {
    preparedData: { message: 'Loading', status: 'Loading' },
};

export const Leasing_TenantOverview_Info_Widget_Reducer = createSlice({
    name: 'Leasing_TenantOverview_Info_Widget_Reducer',
    initialState,

    reducers: {
        storePreraredData: (state, action: PayloadAction<TLoadingData>) => {
            state.preparedData = action.payload;
        },
    },
});

export const { storePreraredData } = Leasing_TenantOverview_Info_Widget_Reducer.actions;

export const Leasing_TenantOverview_Info_Widget_Reducer_Values = (state: RootState) => state.Leasing_TenantOverview_Info_Widget_Reducer;

export default Leasing_TenantOverview_Info_Widget_Reducer.reducer;
