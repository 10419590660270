import { useSelector } from 'react-redux';
import { Dashboards_Reducer_Values } from '../../../Dashboards.reducer';
import { generalReducerValues } from '../../../General.reducer';
import { useEffect, useState } from 'react';
import { IDashboard } from '../interfaces';

/**
 * Кастомный хук для получения обязательных глобальных селектов, необходимых для работы виджетов
 * @param dashboardKey ключ текущего Dashboard
 */
const useRequiredSelects = (userDashboards: IDashboard[], dashboardKey?: string | null): string[] => {
    const { currentModuleID } = useSelector(generalReducerValues);

    const [requiredSelects, setRequiredSelects] = useState<string[]>([]);

    useEffect(() => {
        const currentUserDashboard = userDashboards
            .filter((item) => item.module_id === currentModuleID)
            .filter((item) => item.dashboard_key === dashboardKey);
        if (currentUserDashboard.length) {
            const requiredSelects = Array.from(
                new Set(
                    currentUserDashboard[0]?.widgets?.reduce((acc, item) => {
                        return [...acc, ...(item?.requiredParams || [])];
                    }, []),
                ),
            );
            setRequiredSelects(requiredSelects);
        }
    }, [currentModuleID, dashboardKey, userDashboards]);

    return requiredSelects;
};

export default useRequiredSelects;
