import WrapperContainer from 'src/components/Wrappers/WrapperContainer/WrapperContainer';
import { Container } from './styles';
import EventsMapWidget from './widgets/EventsMapWidget/EventsMapWidget';
import ModuleOptionsPanel from 'src/components/ModuleOptionsPanel/ModuleOptionsPanel';
import ModuleWrapper from 'src/components/Wrappers/ModuleWrapper/ModuleWrapper';

/**
 * Компонент для отображения карты событий (календаря)
 */
const EventsMap = () => {
    return (
        <ModuleWrapper>
            <WrapperContainer style={{ maxWidth: 'none' }}>
                <EventsMapWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default EventsMap;
