import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Network_Section_Reducer_Values } from '../../../../../Chapters/Network/reducer';
import { DS } from '../../../../../constants/constants';
import { IReportingObject } from '../../../../../General.interfaces';
import { generalReducerValues } from '../../../../../General.reducer';
import usePeriods from '../../../../../hooks/common/usePeriods';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { TTimeFreq } from '../../../../../tools/API/interfaces';
import { TDynamicsSeries } from '../../../../CommonWidgets/DynamicsPMO/components/Dynamics/interfaces';
import {
    AVERAGE_SERIES_ID_PART,
    TREND_SERIES_ID_PART,
} from '../../../../CommonWidgets/DynamicsPMO/constants/constants';
import { DynamicsPMOProps } from '../../../../CommonWidgets/DynamicsPMO/interfaces';
import { IProps, TWidgetType } from '../interfaces';
import { AxisLabelsFormatterContextObject } from 'highcharts';
import datetimeFormatHelper from 'src/tools/Strings/datetimeFormatHelper';
import useTranslation from 'src/hooks/useTranslation/useTranslation';

type TOmittedDynamicsPMOProps = Omit<DynamicsPMOProps, 'moduleId' | 'widgetId'>;

interface IArgs extends IProps {
    series: TDynamicsSeries[];
}

const useDynamicsProps = (args: IArgs): DynamicsPMOProps | null => {
    const {
        cfg: { reportingObjectsById },
        selectedLocationId,
        lang,
    } = useSelector(generalReducerValues);
    const networkReducerValues = useSelector(Network_Section_Reducer_Values);
    const { t } = useTranslation();

    const [dynamicsProps, setDynamicsProps] = useState<null | TOmittedDynamicsPMOProps>(null);

    const localCurrentOptions = useWidgetCurrentOptions(args.moduleId);
    const { mainPeriod, comparePeriods } = usePeriods(args.moduleId);

    const xAxisLabelsFormatter = function (this: AxisLabelsFormatterContextObject) {
        return datetimeFormatHelper({
            selectedChartDetailing: {
                ...args.widgetConfig?.options.details!,
                id: String(args.widgetConfig?.options.details?.id!),
            },
            isShortDate: true,
            dateInMillis: this.value as number,
            isCategory: true,
            lang,
            t,
        });
    };

    useEffect(() => {
        if (mainPeriod && args.widgetConfig) {
            const seriesIds = args.series.reduce((acc: string[], series) => {
                if (
                    series.id &&
                    !series.id.includes(TREND_SERIES_ID_PART) &&
                    !series.id.includes(AVERAGE_SERIES_ID_PART)
                ) {
                    acc.push(series.id);
                }
                return acc;
            }, []);
            const widgetType = args.widgetConfig?.type?.split(DS)?.[1] as TWidgetType;

            let props: TOmittedDynamicsPMOProps = {
                mainPeriod: { dateFrom: mainPeriod.period.dateFrom, dateTo: mainPeriod.period.dateTo },
                reportingObjects: [],
                metrics: [],
                comparePeriods: [],
                chartType: 'metrics',
                detail: (args.widgetConfig.options?.details?.id ?? null) as TTimeFreq,
                viewSettings: {
                    showDetailsSelect: false,
                    showMetricsSelect: false,
                    showLoadingBorder: false,
                    loadingBoxHeight: '100%',
                    showObjectSelect: false,
                    isShowTitle: false,
                    chartWrapperStyles: { marginTop: '10px' },
                    seriesIdsForAverage: Boolean(
                        args.widgetConfig?.visual?.additionalVisualOptions?.find((item) => item.id === 'showAverage'),
                    )
                        ? seriesIds
                        : [],
                    seriesIdsForTrend: Boolean(
                        args.widgetConfig?.visual?.additionalVisualOptions?.find((item) => item.id === 'showTrends'),
                    )
                        ? seriesIds
                        : [],
                    showWeekends:
                        Boolean(
                            args.widgetConfig?.visual?.additionalVisualOptions?.find(
                                (item) => item.id === 'showWeekends',
                            ),
                        ) || false,
                    showEvents:
                        Boolean(
                            args.widgetConfig?.visual?.additionalVisualOptions?.find(
                                (item) => item.id === 'showEvents',
                            ),
                        ) || false,
                    showWeather:
                        Boolean(
                            args.widgetConfig?.visual?.additionalVisualOptions?.find(
                                (item) => item.id === 'showWeather',
                            ),
                        ) || false,
                    additionalChartOptions: {
                        options: {
                            xAxis: {
                                labels: { formatter: xAxisLabelsFormatter, style: { fontSize: '10px' } },
                                tickLength: 5,
                            },
                            tooltip: { outside: true },
                            legend: { margin: 10, itemMarginBottom: 2, padding: 5, itemDistance: 5 },
                        },
                    },
                },
            };
            switch (widgetType) {
                case 'metrics': {
                    props = {
                        ...props,
                        chartType: 'metrics',
                        metrics: args.widgetConfig.options?.severalMetrics ?? [],
                        reportingObjects: (localCurrentOptions?.['mainObject'] ?? []).reduce(
                            (acc: IReportingObject[], id: number) => {
                                if (reportingObjectsById[id]) {
                                    acc.push(reportingObjectsById[id]);
                                }
                                return acc;
                            },
                            [],
                        ),
                    };
                    break;
                }
                case 'objects': {
                    const objIds: number[] = [];

                    /** Выбор объектов для сравнения */
                    switch (args.widgetConfig?.visual?.useComparisonFrom?.[0]?.id) {
                        case 'widget':
                            objIds.push(
                                ...(args.widgetConfig?.options?.selectedObjectsIdsByPlId?.[selectedLocationId || '']
                                    ?.comparisonObjectsIds || []),
                            );
                            break;
                        case 'panel':
                            objIds.push(...(localCurrentOptions?.['comparisonObjects'] || []));
                            break;

                        default:
                            break;
                    }

                    switch (args.widgetConfig?.visual?.useMainFrom?.[0]?.id) {
                        case 'panel':
                            if (localCurrentOptions?.['mainObject']?.[0]) {
                                objIds.unshift(localCurrentOptions?.['mainObject']?.[0]);
                            }
                            break;
                        case 'widget':
                            if (
                                args.widgetConfig?.options?.selectedObjectsIdsByPlId?.[selectedLocationId || '']
                                    ?.mainObjectIds?.[0]
                            ) {
                                objIds.unshift(
                                    args.widgetConfig?.options?.selectedObjectsIdsByPlId?.[selectedLocationId || '']
                                        ?.mainObjectIds?.[0],
                                );
                            }
                            break;

                        default:
                            break;
                    }

                    props = {
                        ...props,
                        chartType: 'objects',
                        metrics: args.widgetConfig?.options?.metrics?.[0]
                            ? [args.widgetConfig?.options?.metrics?.[0]]
                            : [],
                        reportingObjects: Array.from(new Set(objIds)).reduce((acc: IReportingObject[], id: number) => {
                            if (reportingObjectsById[id]) {
                                acc.push(reportingObjectsById[id]);
                            }
                            return acc;
                        }, []),
                    };
                    break;
                }
                case 'periods': {
                    props = {
                        ...props,
                        chartType: 'periods',
                        metrics: args.widgetConfig?.options?.metrics?.[0]
                            ? [args.widgetConfig?.options?.metrics?.[0]]
                            : [],
                        reportingObjects: (localCurrentOptions?.['mainObject'] ?? []).reduce(
                            (acc: IReportingObject[], id: number) => {
                                if (reportingObjectsById[id]) {
                                    acc.push(reportingObjectsById[id]);
                                }
                                return acc;
                            },
                            [],
                        ),
                        comparePeriods: comparePeriods.map((period) => ({
                            dateFrom: period.period.dateFrom,
                            dateTo: period.period.dateTo,
                        })),
                    };
                    break;
                }
                case 'objectsLocations': {
                    let metric = args.widgetConfig?.options?.metrics?.[0];

                    if (args.widgetConfig?.visual?.useMetricFrom?.[0]?.id === 'fromPanel') {
                        metric = localCurrentOptions?.['selectedSingleMetric']?.[0];
                    }

                    props = {
                        ...props,
                        chartType: 'objects',
                        metrics: metric ? [metric] : [],
                        reportingObjects: networkReducerValues?.cfg?.reportingObjectsByType?.['location'] ?? [],
                        isNetwork: true,
                        viewSettings: {
                            ...props.viewSettings,
                            showEvents: false,
                        },
                    };
                    break;
                }
                case 'periodsLocations': {
                    let metric = args.widgetConfig?.options?.metrics?.[0];

                    if (args.widgetConfig?.visual?.useMetricFrom?.[0]?.id === 'fromPanel') {
                        metric = localCurrentOptions?.['selectedSingleMetric']?.[0];
                    }

                    const selectedLocationId = Number(args.widgetConfig?.options?.selectedLocation?.[0]?.id);
                    const locationReportingObject = networkReducerValues?.cfg?.reportingObjectsByType?.[
                        'location'
                    ]?.find((location) => location.pl_id === selectedLocationId);

                    props = {
                        ...props,
                        chartType: 'periods',
                        metrics: metric ? [metric] : [],
                        reportingObjects: locationReportingObject ? [locationReportingObject] : [],
                        isNetwork: true,
                        comparePeriods: comparePeriods.map((period) => ({
                            dateFrom: period.period.dateFrom,
                            dateTo: period.period.dateTo,
                        })),
                        viewSettings: {
                            ...props.viewSettings,
                            showEvents: false,
                        },
                    };
                    break;
                }
                case 'objectsReportingObjects': {
                    let metric = args.widgetConfig?.options?.metrics?.[0];

                    if (args.widgetConfig?.visual?.useMetricFrom?.[0]?.id === 'fromPanel') {
                        metric = localCurrentOptions?.['selectedSingleMetric']?.[0];
                    }

                    props = {
                        ...props,
                        chartType: 'objects',
                        metrics: metric ? [metric] : [],
                        reportingObjects: args.reportingObjects ?? [],
                        isNetwork: true,
                        viewSettings: {
                            ...props.viewSettings,
                            showEvents: false,
                        },
                    };
                    break;
                }

                default: {
                    break;
                }
            }
            setDynamicsProps(props);
        }
    }, [
        localCurrentOptions,
        mainPeriod,
        reportingObjectsById,
        selectedLocationId,
        args.widgetConfig,
        comparePeriods,
        networkReducerValues?.cfg?.reportingObjectsByType,
        args.reportingObjects,
        args.series,
    ]);

    return dynamicsProps ? { ...dynamicsProps, widgetId: args.widgetConfig?.id || '', moduleId: args.moduleId } : null;
};

export default useDynamicsProps;
