import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { filterValidDateRanges } from 'src/tools/filterValidDateRanges';
import { emptyArray } from 'src/tools';
import { useGeneralSelector, useFilteredObjects } from 'src/hooks';

import UniversalSelect from '../../../../../../../components/Selects/UniversalSelect/UniversalSelect';
// import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../../reducer';
import {
    Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    toggleShowAverageValues,
    toggleShowReferenceCompareValues,
} from '../../reducer';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { TSelectedOptions } from '../../../../../../../components/Selects/Select/interfaces';
import Checkbox from '../../../../../../../components/Checkbox/Checkbox';
import Stack from '../../../../../../../components/Stack/Stack';
import { changeOptions } from '../../../../../../../components/UserSettings/reducer';
import { useAppDispatch } from '../../../../../../../hooks/useSettings';

import { CheckboxWrapper, MainPeriodText, Wrapper } from './styles';

const Selects: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
    const { selectedTab, showAverageValues, showReferenceCompareValues } = useSelector(
        Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    );
    const {
        currentModuleID,
        lang,
    } = useGeneralSelector();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const filteredTenants = useFilteredObjects('tenant', {
        mode: 'multi',
        validOptions: [t('Tenants')],
        dataObjectFilters: ['data_objects_tenant'],
    });

    const selectedTenantId = localCurrentOptions?.selectedReportingObjectsIds?.[0];

    const compareOptions =
        localCurrentOptions?.comparePeriods?.map((item) => {
            const [range] = localCurrentOptions?.compareDateRanges?.filter((r) => r.id === item.id);
            const text = range?.period ? `${range?.period.dateFrom} – ${range?.period.dateTo}` : '';
            return { id: item.id, text };
        }) || [];

    const otherTenantsOptions: TSelectedOptions = useMemo(
        () => {
            return filteredTenants
                .filter((tenant) => tenant.id !== selectedTenantId)
                .map((tenant) => {
                    return {
                        id: tenant.id,
                        text: tenant.name,
                    };
                }) || emptyArray;
        },
        [filteredTenants, selectedTenantId],
    );

    const selectedComparePeriodID = localCurrentOptions?.['selectedComparePeriod']?.[0]?.id;
    const outsideText = localCurrentOptions?.compareDateRanges?.find((r) => r.id === selectedComparePeriodID)?.text;

    const mainPeriod = localCurrentOptions?.mainPeriod?.id;
    const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];

    const onAverageToggle = (value: { checked: boolean }) => {
        dispatch(toggleShowAverageValues(value.checked));

        if (value.checked === false) {
            dispatch(toggleShowReferenceCompareValues(false));
        }
    };

    const onAverageCompareToggle = (value: { checked: boolean }) => {
        dispatch(toggleShowReferenceCompareValues(value.checked));
    };

    return (
        <Wrapper>
            <Stack alignItems={'flex-end'} flexWrap={'wrap'} gap={20}>
                <MainPeriodText>{shortStringDate(mainDateRange?.period, lang)}</MainPeriodText>
                <UniversalSelect
                    mode="single"
                    options={compareOptions}
                    name={'selectedComparePeriod'}
                    labelText={t('Comparison period')}
                    text={t('Select period to compare')}
                    outsideText={outsideText}
                    maxWidth={isMobile ? 130 : undefined}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                {selectedTab === 'otherTenants' && (
                    <UniversalSelect
                        showFilter={true}
                        mode="multi"
                        options={otherTenantsOptions}
                        name={'otherTenants'}
                        labelText={t('Other tenants')}
                        text={t('Select tenants to compare')}
                        maxWidth={isMobile ? 130 : undefined}
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                    />
                )}

                {selectedTab === 'refGroups' && (
                    <CheckboxWrapper>
                        <Checkbox handleToggle={onAverageToggle} selected={showAverageValues} label={t('AVG')} />
                    </CheckboxWrapper>
                )}
                <CheckboxWrapper>
                    <Checkbox
                        disabled={!showAverageValues}
                        handleToggle={onAverageCompareToggle}
                        selected={showReferenceCompareValues}
                        label={t('Show reference compare')}
                    />
                </CheckboxWrapper>
            </Stack>
        </Wrapper>
    );
};

export default Selects;
