import styled from 'styled-components/macro';

export const Color = styled.div<{ color: string }>`
    flex: 0 0 25px;
    height: 25px;
    border-radius: 5px;
    background-color: ${(p) => p.color};
    cursor: pointer;
`;

export const Name = styled.div``;

export const Wrapper = styled.div`
    min-height: 30px;
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
