import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DecisionsConfigWidgetIds } from './enums';
import { IReducerState } from './interfaces';
import { RootState } from '../../../store';

const initialState: IReducerState = {
    selectedWidgetKey: DecisionsConfigWidgetIds.DecisionTypes,
};

const Decisions_DecisionsConfig_Module_Reducer = createSlice({
    name: 'Decisions_DecisionsConfig_Module_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение ключа выбранного виджета
         */
        storeSelectedWidgetKey: (state, action: PayloadAction<DecisionsConfigWidgetIds>) => {
            state.selectedWidgetKey = action.payload;
        },
    },
});

export const { storeSelectedWidgetKey } = Decisions_DecisionsConfig_Module_Reducer.actions;

export const Decisions_DecisionsConfig_Module_Reducer_Values = (state: RootState) =>
    state.Decisions_DecisionsConfig_Module_Reducer;

export default Decisions_DecisionsConfig_Module_Reducer.reducer;
