import { PlotLineOrBand, XAxisPlotBandsOptions, color } from 'highcharts';
import { IMetricResponseItem, TLang } from '../../../../../General.interfaces';
import { IEvent, IEventType } from '../../../../../Chapters/Events/interfaces';
import { DateTime, Interval } from 'luxon';
import { keyBy } from 'lodash';
import { renderToString } from 'react-dom/server';
import Tooltip from '../../../../Charts/Tooltip/Tooltip';
import { shortStringDate } from '../../../../../tools/Strings/shortStringDate';
import { TFunction } from 'i18next';
import { EventTooltipBodyItemWrapper, EventTypeCircle } from '../../styles';
import Stack from '../../../../Stack/Stack';

interface IArgs {
    metricResponseItem: IMetricResponseItem;
    events?: IEvent[];
    eventTypes?: IEventType[];
    t: TFunction;
    lang: TLang;
}

const getPlotBandEventsForMetricResponseItem = (args: IArgs): XAxisPlotBandsOptions[] => {
    const { metricResponseItem, events, eventTypes, t, lang } = args;

    const eventTypesById = keyBy(eventTypes || [], 'id');
    const plotBands: XAxisPlotBandsOptions[] = [];

    const metricResponseItemPeriodInterval = Interval.fromDateTimes(
        DateTime.fromISO(metricResponseItem.context.time_range[0]),
        DateTime.fromISO(metricResponseItem.context.time_range[1]).plus({ days: 1 }),
    );

    if (events?.length && eventTypes?.length) {
        events.forEach((event) => {
            const eventPeriodInterval = Interval.fromDateTimes(
                DateTime.fromISO(event.date_from),
                DateTime.fromISO(event.date_to),
            );

            if (
                event.data_objects_ids.includes(metricResponseItem.context.data_objects[0].id) &&
                Boolean(eventPeriodInterval.intersection(metricResponseItemPeriodInterval))
            ) {
                plotBands.push({
                    from: DateTime.fromISO(
                        DateTime.fromISO(event.date_from, { zone: event.timezone }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                    ).toMillis(),
                    to: DateTime.fromISO(
                        DateTime.fromISO(event.date_to, { zone: event.timezone }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                    ).toMillis(),
                    color: color(eventTypesById[event.event_type].color || 'red')
                        .setOpacity(0.3)
                        .get('rgba'),
                    label: {
                        text: renderToString(
                            <div style={{ padding: '15px 15px 15px 0' }}>
                                <Tooltip title={event.name} subTitle="">
                                    <Stack direction="column" gap={5}>
                                        <EventTooltipBodyItemWrapper>
                                            <span>{args.t('Period')}:&nbsp;</span>
                                            <div>
                                                {shortStringDate(
                                                    {
                                                        dateFrom: DateTime.fromISO(event.date_from, {
                                                            zone: event.timezone,
                                                        }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                                                        dateTo: DateTime.fromISO(event.date_to, {
                                                            zone: event.timezone,
                                                        }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                                                    },
                                                    lang,
                                                )}
                                            </div>
                                        </EventTooltipBodyItemWrapper>
                                        <EventTooltipBodyItemWrapper>
                                            <span>{args.t('Event type')}:&nbsp;</span>
                                            <Stack alignItems="center" gap={5}>
                                                <div>{eventTypesById[event.event_type].name}</div>
                                                <EventTypeCircle color={eventTypesById[event.event_type].color} />
                                            </Stack>
                                        </EventTooltipBodyItemWrapper>
                                    </Stack>
                                </Tooltip>
                            </div>,
                        ),

                        y: -20,
                        x: 10,
                        textAlign: 'right',
                        align: 'left',
                        style: {
                            display: 'none',
                        },
                        useHTML: true,
                    },
                    zIndex:
                        1000 -
                        Math.round(
                            DateTime.fromISO(event.date_to).diff(DateTime.fromISO(event.date_from), ['days']).toObject()
                                .days || 0,
                        ),
                    id: event.id.toString(),
                    events: {
                        mouseover: function (this: PlotLineOrBand) {
                            this.label.css({ display: 'block' });
                        },
                        mouseout: function (this: PlotLineOrBand) {
                            this.label.css({ display: 'none' });
                        },
                    },
                });
            }
        });
    }
    return plotBands;
};

export default getPlotBandEventsForMetricResponseItem;
