import styled from 'styled-components/macro';
import setHslLightness from '../../tools/setHslLightness';
import hex2hsl from '../../tools/hex2hls';

export const Wrapper = styled.div<{ color: string }>`
    padding: 10px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    cursor: pointer;
    background: ${(p) =>
        `linear-gradient(to left, ${setHslLightness(hex2hsl(p.color), 70)}, ${setHslLightness(hex2hsl(p.color), 90)})`};
    border-left: ${(p) => `5px solid  ${setHslLightness(hex2hsl(p.color), 80)}`};
    :hover {
        /* border: 1px solid var(--color-primary); */
        border-left: ${(p) => `5px solid  ${p.color}`};
    }
`;

export const EventName = styled.div`
    font-size: 1rem;
    font-weight: 500;
`;

// export const EventTypeColor = styled.div<{ color: string }>`
//     flex: 0 0 15px;
//     flex-shrink: 0 !important;
//     height: 15px;
//     border-radius: 50%;
//     background-color: ${(p) => p.color};
// `;
