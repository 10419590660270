import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0px;
    transform: translateY(-25%);
    z-index: 5;
    background-color: #ffff;
`;
