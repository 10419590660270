import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileInboundWidth } from 'src/theme';

import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

import { Leasing_TenantOverview_Reference_Widget_Reducer_Values, reloadWidget, storeSelectedTab } from './reducer';
import ComplexTable from './components/ComplexTable/ComplexTable';
import Selects from './components/Selects/Selects';
import Table from './components/Table/Table';
import { TSelectedTab } from './interfaces';

const Reference: React.FC = () => {
    const { selectedTab } = useSelector(Leasing_TenantOverview_Reference_Widget_Reducer_Values);
    const {
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);

    const isMobile = isMobileInboundWidth(width);

    const tabListItems = [
        {
            id: 'refGroups',
            text: t('Reference groups'),
        },
        {
            id: 'otherTenants',
            text: t('Other tenants'),
        },
    ];

    const tabListSelectHandler = (id: string) => {
        dispatch(storeSelectedTab(id as TSelectedTab));
    };

    const tabList = useMemo(() => {
        return (
            <TabList
                widgetName="ComparisonWithReferenceGroups"
                selectedValue={selectedTab}
                selectHandler={tabListSelectHandler}
                options={tabListItems}
            />
        );
    }, [selectedTab, t, tabListItems]);

    const widgetBody = useMemo(() => {
        if (selectedTab === 'otherTenants') return <Table />;
        if (selectedTab === 'refGroups') return <ComplexTable />;
        return null;
    }, [selectedTab]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Comparison with reference groups')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName={'Comparison with reference groups'}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            <Selects isMobile={isMobile} />
            {widgetBody}
        </WidgetWrapper>
    );
};

export default Reference;
