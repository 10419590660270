import axios, { AxiosResponse } from 'axios';
import { DateTime, Interval } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Network_Section_Reducer_Values } from '../../../Chapters/Network/reducer';
import { storeNewAlert } from '../../../components/Alert/reducer';
import { cabinetPreferencesValues } from '../../../components/CabinetPreferences/reducer';
import { DS } from '../../../constants/constants';
import {
    IDataObj2ProjectCategory,
    IDataObject,
    IMetricResponseItem,
    IReportingObject,
    IResponseContext,
} from '../../../General.interfaces';
import { generalReducerValues, toggleModuleLoading } from '../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { IRequestMetricsArgs, TRequestMetricsArgs } from '../interfaces';
import requestObjectMetrics from '../requestObjectMetrics';

/**
 * Кастомный хук для запроса метрик для различного типа отчетных объектов
 */
export const useRequestMetrics = (args: { requestsHandler?: (data: any) => void; isNetwork?: boolean } | void) => {
    const { requestsHandler, isNetwork } = args || {};
    const {
        src: { dataObj2ProjectCategory },
        cfg: { reportingObjectsById },
        urlsByServices,
        allMetrics,
        currentModuleID,
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const {
        preferences: { useBusinessDays },
    } = useSelector(cabinetPreferencesValues);

    const {
        cfg: { reportingObjectsById: retailReportingObjectsById },
    } = useSelector(Network_Section_Reducer_Values);
    const dispatch = useDispatch();

    const result = (args: IRequestMetricsArgs[]) => {
        const globalMetricParams = { business_days: String(useBusinessDays) };

        //Итерируемся по массиву и дописываем metric_params

        const metricsWithParams = args.map((item) => {
            const metricParams = allMetrics.find((m) => m.id === item.metric)?.optional_metric_params;
            const optionalMetricParams = {};
            metricParams &&
                Object.keys(metricParams).forEach((key) => {
                    if (localCurrentOptions?.[key]) {
                        optionalMetricParams[key] = localCurrentOptions?.[key][0].id;
                    }
                });
            return {
                ...item,
                metric_params: { ...item.metric_params, ...optionalMetricParams, ...globalMetricParams },
            };
        });

        if (!checkCorrectTimeFreq(args)) {
            dispatch(
                storeNewAlert({ type: 'warning', text: 'Incorrect detail', duration: 5, alias: 'Incorrect detail' }),
            );
        }

        //--------------------------------------------------
        // Создание невидимого элемента
        // dispatch(toggleModuleLoading({}));

        return requestMiddleware(
            metricsWithParams,
            dataObj2ProjectCategory,
            isNetwork ? retailReportingObjectsById : reportingObjectsById,
            urlsByServices?.['core/structure-service'].METRIC_DYNAMICS_URL,
            requestsHandler,
        );
    };

    return result;
};

/**
 * Функция проверки корректности time_freq
 * @param args
 * @returns
 */

const checkCorrectTimeFreq = (args: IRequestMetricsArgs[]): boolean => {
    if (
        args.some((item) => {
            const start = DateTime.fromISO(item.time_range[0]);
            const end = DateTime.fromISO(item.time_range[1]).plus({ days: 1 });

            const interval = Interval.fromDateTimes(start, end);
            const subIntervals = interval.splitBy({ days: 1 });
            const monthsStarts = subIntervals.reduce((acc, subInt) => {
                const d = subInt.start;
                return d?.day === 1 ? acc + 1 : acc;
            }, 0);

            return item.time_freq === 'MS' && monthsStarts === 0;
        })
    ) {
        return false;
    } else {
        return true;
    }
};

const requestMiddleware = async (
    args: IRequestMetricsArgs[],
    dataObj2ProjectCategory: IDataObj2ProjectCategory[],
    reportingObjectsById: {
        [x: string]: IReportingObject;
    },
    url: string | undefined,
    requestsHandler?: (data: any) => void,
) => {
    const getIdsOfBlock = (ids: number[], blockType: string) => {
        return ids?.filter((id) => {
            return reportingObjectsById?.[id]?.block_type === blockType;
        });
    };
    const getPlId = (id: number) => {
        return reportingObjectsById?.[id]?.pl_id;
    };

    const all = [];

    //--------------- Создание запроса для отчетных объектов

    const objectMetricsArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const reportingObjectIds = getIdsOfBlock(request.obj_ids, 'reporting_object');
        reportingObjectIds?.length && objectMetricsArgs.push({ ...request, obj_ids: reportingObjectIds });
    });

    if (objectMetricsArgs.length && checkCorrectTimeFreq(args)) {
        const defaultRequests: IRequestMetricsArgs[] = [];
        const salesRequests: IRequestMetricsArgs[] = [];
        objectMetricsArgs.forEach((element) => {
            requestsHandler && requestsHandler(element);
            if (element.alias?.split(DS)[0] === 'sales') {
                salesRequests.push(element);
            } else {
                defaultRequests.push(element);
            }
        });

        all.push(requestObjectMetrics(defaultRequests, url));
        // all.push(requestObjectMetrics(salesRequests, 'http://130.193.54.34:8000/api/dataobj_metrics/metric_dynamics'));
        all.push(requestObjectMetrics(salesRequests, url));
    }

    //--------------- Создание запроса для категорий

    const categoriesMetricsArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const categoryIds = getIdsOfBlock(request.obj_ids, 'category');
        categoryIds?.forEach((id) => {
            const obj_ids = dataObj2ProjectCategory
                ?.filter((item) => item.category_id === id)
                .map((item) => item.data_object_id);

            obj_ids?.length &&
                categoriesMetricsArgs.push({
                    ...request,
                    obj_ids,
                    object_aggregation: true,
                    alias: request.alias + `∧category∧${id}`,
                });
        });
    });

    categoriesMetricsArgs.length && all.push(requestCategoryMetrics(categoriesMetricsArgs, url));

    //--------------- Создание запроса для проектных категорий

    const projectCategoriesMetricsArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const projectCategoryIds = getIdsOfBlock(request.obj_ids, 'project_category');
        projectCategoryIds?.forEach((id) => {
            const obj_ids = dataObj2ProjectCategory
                ?.filter((item) => item.category_id === id)
                .map((item) => item.data_object_id);

            if (obj_ids.length) {
                const data = {
                    ...request,
                    obj_ids,
                    object_aggregation: true,
                    alias: request.alias + `∧project_category∧${id}`,
                };
                requestsHandler && requestsHandler(data);

                projectCategoriesMetricsArgs.push(data);
            }
        });
    });

    projectCategoriesMetricsArgs.length && all.push(requestCategoryMetrics(projectCategoriesMetricsArgs, url));

    //--------------- Создание запроса для бенчмаков

    const benchArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const benchIds = request.obj_ids?.filter((id) => id >= 6660000);
        benchIds?.length && benchArgs.push({ ...request, obj_ids: benchIds });
    });

    benchArgs.length && all.push(requestBenchmark(benchArgs));

    //--------------- Подгонка ответов под существующий фронт (убрать, когда появятся нужные ручки)

    try {
        const response = await axios.all(all).then(
            axios.spread((...res) => {
                const newRes = {};
                const responses = res.reduce((acc, item) => {
                    if (item?.name === 'AxiosError') {
                        newRes['error'] = item;
                        return [...acc, item];
                    } else {
                        return [...acc, ...item];
                    }
                }, []);

                responses.forEach((item: AxiosResponse, i: number) => {
                    if (
                        item?.data?.result?.[0]?.context?.alias?.split('∧')[2] &&
                        item?.data?.result?.[0]?.context?.alias?.split('∧')[0] !== 'benchmark'
                    ) {
                        const arr = item.data.result[0].context.alias.split('∧');
                        const id = item.data.result[0].context.alias.split('∧')[arr.length - 1];
                        arr.splice(-2, 2);
                        const alias = arr.join(DS);
                        const name = reportingObjectsById[id].name;
                        item.data.result[0].context.alias = alias;
                        item.data.result[0].context.data_objects = [{ id: Number(id), name }];

                        if (newRes[alias]) {
                            newRes[alias].data.result.push(item.data.result[0]);
                        } else {
                            newRes[alias] = item;
                        }
                    } else {
                        const alias = item?.data?.result?.[0]?.context?.alias || `${i}`;

                        // Дописываем pl_id
                        const objData = item.data.result.map((item: IMetricResponseItem) => {
                            const context: IResponseContext = {
                                ...item.context,
                                data_objects: item.context.data_objects.map((obj) => {
                                    return { ...obj, pl_id: getPlId(obj.id) };
                                }),
                            };
                            return { ...item, context };
                        });
                        item.data.result = objData;

                        newRes[`${alias}${i}`] = item;
                    }
                });
                return Object.values(newRes).map((value) => value);
            }),
        );

        return response;
    } catch (err) {
        return err;
    }
};

const requestCategoryMetrics = (args: TRequestMetricsArgs, url?: string) => {
    return requestObjectMetrics(args, url);
};

const requestBenchmark = async (args: TRequestMetricsArgs) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    const requests = args.map((request) => {
        const { signal, token, alias, metric, obj_ids, object_aggregation, time_range, time_freq } = request;
        const url = `https://storage.yandexcloud.net/index.focustech.xyz/${alias?.split(DS).join('_')}.json`;

        if (!obj_ids.length) return;

        return axios({
            method: 'GET',
            headers,
            signal,
            url,
        });
    });

    try {
        const response = await axios.all(requests).then(
            axios.spread((...res) => {
                const newRes = res.map((item: any, i) => {
                    const items = item.data.result[0].items?.filter((data: any) => {
                        return (
                            DateTime.fromISO(data.time).toMillis() >=
                                DateTime.fromISO(args[i].time_range[0]).toMillis() &&
                            DateTime.fromISO(data.time).toMillis() <= DateTime.fromISO(args[i].time_range[1]).toMillis()
                        );
                    });

                    item.data.result[0].items = items;
                    item.data.result[0].context.alias = args[i].alias;

                    return item;
                });
                return newRes;
            }),
        );
        return response;
    } catch (err) {
        return err;
    }
};
