import React, { FC } from 'react';
import { IProps } from './interfaces';
import { Th } from '../../styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import Stack from '../../../../../../../Stack/Stack';
import OrderSwitcher from '../../../../../../../OrderSwitcher/OrderSwitcher';
import { HeaderText, THead, Tr, Units } from './styles';
import { DS } from '../../../../../../../../constants/constants';
import TotalCell from './components/TotalCell/TotalCell';

/**
 * Шапка таблицы
 */
const Header: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation(['metrics', 'translation']);
    const { mainPeriod, reportingObjects, moduleId, widgetId } = props;

    return (
        <THead>
            <Tr>
                <Th>
                    <Stack alignItems="center" gap={5}>
                        <span>{t('Name')}</span>
                        <OrderSwitcher
                            ordering={props.orderingData?.columnId === 'name' ? props.orderingData.ordering : undefined}
                            handleOrderToggle={props.handleOrderToggle}
                            id={'name'}
                            position="right"
                        />
                    </Stack>
                </Th>
                {props.metrics.map((metric) => {
                    const metricFromStore = allMetrics.find((item) => item.id === metric);
                    return (
                        <Th key={metric}>
                            <Stack alignItems="center" justifyContent="center">
                                <OrderSwitcher
                                    ordering={
                                        props.orderingData?.columnId === `${metric}${DS}${'main'}`
                                            ? props.orderingData.ordering
                                            : undefined
                                    }
                                    handleOrderToggle={props.handleOrderToggle}
                                    id={`${metric}${DS}${'main'}`}
                                    position="left"
                                />
                                <Stack direction="column" alignItems="center" justifyContent="center">
                                    <HeaderText>{t(metricFromStore?.text || metric)}</HeaderText>

                                    <Units>{t(metricFromStore?.units || '')}</Units>
                                </Stack>
                                {props.selectedReportingObjectId && (
                                    <OrderSwitcher
                                        ordering={
                                            props.orderingData?.columnId === `${metric}${DS}${'compare'}`
                                                ? props.orderingData.ordering
                                                : undefined
                                        }
                                        handleOrderToggle={props.handleOrderToggle}
                                        id={`${metric}${DS}${'compare'}`}
                                        position="right"
                                    />
                                )}
                            </Stack>
                        </Th>
                    );
                })}
            </Tr>
            {/* <Tr bgColor={'#fffded'}>
                <Th>
                    <Stack alignItems="center" gap={5}>
                        <span>{t('Total')}</span>
                    </Stack>
                </Th>
                {props.metrics.map((metric) => {
                    const metricFromStore = allMetrics.find((item) => item.id === metric);
                    return (
                        <TotalCell
                            key={metric}
                            period={mainPeriod}
                            mainPeriod={mainPeriod}
                            metric={metric}
                            reportingObjects={reportingObjects}
                            moduleId={moduleId}
                            widgetId={widgetId}
                        />
                    );
                })}
            </Tr> */}
        </THead>
    );
};

export default Header;
