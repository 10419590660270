import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import CrossVisitsWidget from './widgets/CrossVisitsWidget/CrossVisitsWidget';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import { changeOptions, userSettingsReducerValues } from '../../../components/UserSettings/reducer';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import { CROSS_VISITS_MAPS_SETTINGS } from './constants/constants';
import { ISettings } from '../../../components/UserSettings/interfaces';
import { useSelector } from 'react-redux';
import usePrepareData from './hooks/usePrepareData';
import Preset from '../../../components/Preset/Preset';
import {
    crossVisitsMapsSettingsOptions,
    CrossVisitsMapsSettingsIds,
    ICrossVisitsSelectOption,
} from './constants/crossVisitsMapsSettings';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../General.reducer';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';

/**
 * Модуль для отображения кросс-визитов
 */
const CrossVisits = () => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    // const { versionSelectOptions, selectedVersionOption } = useSelector(crossVisitsModuleReducerValues);
    // const dispatch = useDispatch();
    usePrepareData();

    // const handleVersionSelect = (args: TSelectedOptions) => {
    //     dispatch(storeSelectedVersionId(Number(args[0].id)));
    // };

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        {!!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                            (element: ICrossVisitsSelectOption) => element.id === CrossVisitsMapsSettingsIds.Comparison,
                        ) && (
                            <ComparePeriodSelect
                                mode="single"
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                            />
                        )}

                        {/* {versionSelectOptions && (
                            <Select
                                outsideSelected={selectedVersionOption || []}
                                handleSelect={handleVersionSelect}
                                options={versionSelectOptions}
                                labelText={t('Versions')}
                                iconType="chevron-down"
                                showFilter={false}
                                mode="single"
                            />
                        )} */}
                        <UniversalSelect
                            options={crossVisitsMapsSettingsOptions}
                            name={CROSS_VISITS_MAPS_SETTINGS}
                            labelText={'Settings'}
                            iconType={'setting'}
                            defaultSelected={[
                                { id: 'showRatings', text: 'Show ratings' },
                                { id: 'showPerimeters', text: 'Show Perimeters' },
                            ]}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                CROSS_VISITS_MAPS_SETTINGS as keyof ISettings,
                                'comparePeriods',
                                'mainPeriod',
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer style={{ maxWidth: 'unset' }}>
                <CrossVisitsWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default CrossVisits;
