import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { isMobileInboundWidth } from 'src/theme';

import TreeMap from '../../../../../components/Charts/TreeMap/Treemap';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import { getReportingObjects } from '../../../../../tools/getReportingObjects';
import { withLoading } from '../../../../../tools/API/withLoading';
import NumberToShow from '../../../../../components/NumberToShow/NumberToShow';
import TabList from '../../../../../components/TabList/TabList';
import { ITabListOptionsItem } from '../../../../../components/TabList/interfaces';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import getResponseStatus from '../../../../../tools/API/helpers/getResponseStatus';

import { Performance_Dashboard_ByArea_Widget_Reducer_Values, storeObjectsType } from './reducer';
import useFetchData from './api/useFetchData';
import { usePrepareData } from './hooks/usePrepareData';
import useFilterAndExtendTenants from './hooks/useFilterAndExtendTenants';
import ObjectsRatings from './components/ObjectsRatings/ObjectsRatings';
import Ratings from './components/Ratings/Ratings';
import Selects from './components/Selects/Selects';
import { FooterWrapper, NumberToShowTitle, Title, TreeMapWrapper } from './styles';

/**
 * Компонент для отображения графика "tree-map" для анализа метрик по плошади (высчитанной из метрики)
 */
const AnalysisByArea: React.FC = () => {
    const [tabListItems, setTabListItems] = useState<ITabListOptionsItem[]>([]);
    const { objectsType, selectedObjectId } = useSelector(Performance_Dashboard_ByArea_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsByType },
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);
    const [itemsToShow, setItemsToShow] = useState<number | 'all'>(10);
    usePrepareData();

    const { data, isFetching, error, refetch } = useFetchData();
    const extendedData = useFilterAndExtendTenants();

    const { t } = useTranslation(['metrics', 'translation']);
    const dispatch = useDispatch();
    const ref = useRef(null);

    /** Получение табов из типов отчетных объектов */
    useEffect(() => {
        const lockedFields = ['location', 'passway', 'place'];
        const tabListItems = getReportingObjects(reportingObjectsByType, t)
            ?.filter((item) => {
                let flag = false;
                if (!lockedFields.includes(item.key)) flag = true;
                return flag;
            })
            .map((item) => ({
                id: item.key,
                text: item.section,
            }));

        tabListItems.length && setTabListItems(_.sortBy(tabListItems, (item) => item.id === 'tenant').reverse());
    }, [reportingObjectsByType, t]);

    /** Автоматический выбор таба (если он не был выбран) */
    useEffect(() => {
        const tabListValues = tabListItems.map((element) => element.id);
        if (tabListItems.length) {
            if (!objectsType || !tabListValues.includes(objectsType)) {
                dispatch(storeObjectsType(tabListItems[0].id));
            }
        }
    }, [dispatch, objectsType, tabListItems]);

    const isMobile = isMobileInboundWidth(width);

    const tabListSelectHandler = (id: string) => {
        dispatch(storeObjectsType(id));
    };

    const onItemsToShowSelect = (value: number | 'all') => {
        setItemsToShow(value);
    };

    const reloadWidget = () => {
        refetch();
    };

    const WithLoading = useMemo(() => {
        const Component = withLoading(TreeMapWrapper, {
            data: getResponseStatus({
                isFetching,
                error,
                data,
            }),
            height: 400,
        });

        return Component;
    }, [error, data, isFetching]);

    const tabList = useMemo(() => {
        return (
            <TabList
                widgetName="AnalysisByArea"
                selectHandler={tabListSelectHandler}
                selectedValue={objectsType}
                options={tabListItems}
            />
        );
    }, [objectsType, tabListItems, t]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title>{t('Analysis of shopping center objects by area')}</Title>
                    <WidgetAdditionalControls
                        widgetName="Analysis of shopping center objects by area"
                        pdfDownloadData={{
                            targetRef: ref,
                        }}
                        reloadHandlerNoDispatch={reloadWidget}
                    />
                </WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            <Selects />
            <WithLoading>
                <TreeMap options={{ ...extendedData?.treeMapOptions }} />
            </WithLoading>
            {selectedObjectId === null ? (
                <Ratings itemsToShow={itemsToShow} />
            ) : (
                <ObjectsRatings itemsToShow={itemsToShow} />
            )}
            <FooterWrapper>
                <NumberToShowTitle>{t('Show first')}</NumberToShowTitle>
                <NumberToShow
                    testId={'Analysis of shopping center objects by area'}
                    handleSelect={onItemsToShowSelect}
                />
            </FooterWrapper>
        </WidgetWrapper>
    );
};

export default AnalysisByArea;
