import { FC, FormEvent, useMemo, useRef } from 'react';
import { IProps } from './interfaces';
import {
    DaysOfTheWeekWrapper,
    Label,
    MainValue,
    MainValueWrapper,
    NumberWrapper,
    PercentDifferenceValue,
    PercentDifferenceWrapper,
    Wrapper,
    TitleWrapper,
} from './styles';
import { valueFormatter } from '../../../../tools/Strings/valueFormatter';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { useStretchingText } from './hooks/useStretchingText';
import { getColorFromPercent } from '../../../../tools/getColorFromPercent';
import Icon from '../../../Icon/Icon';
import useFetchData from './hooks/useFetchData';
import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { ResponseStatus } from '../../../../tools/API/constants';
import { isMetricErrorGuard } from '../../../../tools/API/dataAccessors/metricsDataAccessor';
import Stack from '../../../Stack/Stack';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import { IChangeOptionsArgs } from '../../../UserSettings/interfaces';
import { Divider, Radio, RadioGroup, RadioGroupOnChangeData } from '@fluentui/react-components';
import { DS } from '../../../../constants/constants';
import { cloneDeep } from 'lodash';
import {
    NUMBERS_DAYS_OF_THE_WEEK_OPTIONS,
    NUMBERS_WORKING_MODE_OPTIONS,
} from '../../../../Chapters/Dashboards/ObjectPalnels/widgetsConfig';
import WeekdaysInfo from './components/WeekdaysInfo/WeekdaysInfo';
import SmartSwitch from '../../../SmartSwitch/SmartSwitch';
import SmartCheckbox from '../../../SmartCheckbox/SmartCheckbox';
import { WidgetTitle } from '../../../../Chapters/Dashboards/components/WidgetWrapper/styles';
import Tooltip from '../../../Tooltip/Tooltip';

/**
 * Виджет для отображения значения метрики основного периода и процент отклонения
 * от периода сравнения
 */
const Number: FC<IProps> = ({ moduleId, widgetConfig, dashboardKey, updateWidgetTrans }) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { data, isFetching, error, refetch } = useFetchData({
        widgetConfig,
        dashboardKey,
        moduleId,
    });
    const mainTextRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    useStretchingText(mainTextRef, containerRef, data?.mainPeriodValue, isFetching);
    const { t } = useTranslation(['metrics', 'translation']);

    const widgetType = widgetConfig?.type?.split(DS)?.[1] as undefined | 'simple' | 'weekDays';

    const metricFromStore = useMemo(() => {
        return allMetrics.find((metric) => metric.id === widgetConfig?.options?.metrics?.[0]);
    }, [allMetrics, widgetConfig?.options?.metrics]);

    const percentDifferenceColor = useMemo(() => {
        return getColorFromPercent(data?.percentDifference, 'object');
    }, [data?.percentDifference]);

    const widgetTitle = useMemo(() => {
        const metricText = metricFromStore
            ? `${t(metricFromStore.text)}, ${t(metricFromStore.units)}`
            : t(widgetConfig?.title || '');
        const mode = widgetConfig?.visual?.workingMode;
        switch (widgetType) {
            case 'simple':
                return metricText;
            case 'weekDays':
                // return `${t(NUMBERS_WORKING_MODE_MAP[mode || '']?.short || '')}, ${metricText}`;
                return `${metricText}`;

            default:
                return t(widgetConfig?.title || '');
        }
    }, [metricFromStore, t, widgetConfig?.title, widgetConfig?.visual?.workingMode, widgetType]);

    const reloadWidget = () => {
        refetch();
    };

    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        updateWidget('options', args.newOptions);
    };

    const onRadioChange = (_: FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
        updateWidget('visual', { workingMode: data.value });
    };

    const onAllDaysOfTheWeekChange = (args: { name: string; checked: boolean }) => {
        if (args.checked) {
            updateWidget('visual', { selectedDaysOfTheWeek: NUMBERS_DAYS_OF_THE_WEEK_OPTIONS.map((day) => day.id) });
        } else {
            updateWidget('visual', { selectedDaysOfTheWeek: [] });
        }
    };

    const onDayOfTheWeekChange = (args: { name: string; checked: boolean }) => {
        const selectedDays: string[] = cloneDeep(widgetConfig?.visual?.['selectedDaysOfTheWeek'] || []);
        if (args.checked) {
            updateWidget('visual', { selectedDaysOfTheWeek: [...selectedDays, args.name] });
        } else {
            updateWidget('visual', { selectedDaysOfTheWeek: selectedDays.filter((day) => day !== args.name) });
        }
    };

    const updateWidget = (key: string, value: { [x: string]: unknown }) => {
        if (widgetConfig && widgetConfig?.id) {
            updateWidgetTrans({
                moduleId,
                dashboardKey,
                data: {
                    widgetId: widgetConfig?.id,
                    key: key,
                    value: { ...(widgetConfig[key] || {}), ...value },
                },
            });
        }
    };

    const onSmartSwitchChange = (args: { name: string; checked: boolean }) => {
        updateWidget('visual', { [args.name]: args.checked });
    };

    const widgetSelects = useMemo(() => {
        return (
            <>
                <Stack styles={{ padding: '0px 12px' }} direction="column" gap={1}>
                    <MetricsSelect
                        name={'metrics'}
                        maxSelected={1}
                        localCurrentOptions={widgetConfig?.options}
                        changeOptions={updateWidgetOptions}
                    />
                </Stack>
                {widgetType === 'weekDays' && (
                    <>
                        <Divider style={{ margin: '10px 0' }} />
                        <Stack styles={{ padding: '0px 12px' }} direction="column" gap={1}>
                            <Label>{t('Working mode')}</Label>
                            <RadioGroup
                                onChange={onRadioChange}
                                value={widgetConfig?.visual?.['workingMode']}
                                aria-labelledby={'workingMode'}
                            >
                                {NUMBERS_WORKING_MODE_OPTIONS.map((mode) => (
                                    <Radio key={mode.id} label={t(mode.text)} value={mode.id} />
                                ))}
                            </RadioGroup>
                            <Label>{t('Days of the week')}</Label>
                            <DaysOfTheWeekWrapper>
                                <SmartCheckbox
                                    label={t('All')}
                                    storePlace={widgetConfig?.visual?.['selectedDaysOfTheWeek']}
                                    checked={NUMBERS_DAYS_OF_THE_WEEK_OPTIONS.every((day) =>
                                        (widgetConfig?.visual?.['selectedDaysOfTheWeek'] || []).includes(day.id),
                                    )}
                                    onChange={onAllDaysOfTheWeekChange}
                                    name=""
                                />
                                {NUMBERS_DAYS_OF_THE_WEEK_OPTIONS.map((day) => (
                                    <SmartCheckbox
                                        label={t(day.text)}
                                        storePlace={widgetConfig?.visual?.['selectedDaysOfTheWeek']}
                                        checked={widgetConfig?.visual?.['selectedDaysOfTheWeek']?.includes(day.id)}
                                        onChange={onDayOfTheWeekChange}
                                        name={day.id}
                                        key={day.id}
                                    />
                                ))}
                            </DaysOfTheWeekWrapper>
                        </Stack>
                    </>
                )}
                <Stack styles={{ padding: '0px 4px' }} direction="column" gap={1}>
                    <SmartSwitch
                        label={t('Shorten large numbers')}
                        storePlace={widgetConfig?.visual}
                        onChange={onSmartSwitchChange}
                        name="shortenNumbers"
                        defaultValue={true}
                    />
                </Stack>
            </>
        );
    }, [t, widgetConfig?.options, widgetConfig?.visual, widgetType]);

    return (
        <WidgetWrapper
            isNoData={isMetricErrorGuard(error) && error.status === ResponseStatus.NoData}
            isError={isMetricErrorGuard(error) && error.status === ResponseStatus.Error}
            contentWrapperStyles={{ marginTop: '0px' }}
            widgetId={widgetConfig?.id}
            widgetSelects={widgetSelects}
            dashboardKey={dashboardKey}
            reloadWidget={reloadWidget}
            isTitleChangeable={false}
            isLoading={isFetching}
            moduleId={moduleId}
            title={''}
            hideTitle
        >
            <NumberWrapper>
                <Wrapper>
                    <div style={{ position: 'relative', width: '100%', height: '30px' }}>
                        <TitleWrapper>
                            <WidgetTitle size={'small'}>
                                <Tooltip
                                    contentWrapperStyles={{
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        minWidth: 'unset',
                                        maxWidth: '100%',
                                    }}
                                    content={widgetTitle}
                                >
                                    {widgetTitle}
                                </Tooltip>
                            </WidgetTitle>
                            {widgetType === 'weekDays' && <WeekdaysInfo widgetConfig={widgetConfig} />}
                        </TitleWrapper>
                    </div>
                    <MainValueWrapper ref={containerRef}>
                        <MainValue ref={mainTextRef}>
                            {valueFormatter({
                                precision: metricFromStore?.round_decimal_places,
                                value: data?.mainPeriodValue,
                                shortenNumbers: widgetConfig?.visual?.['shortenNumbers'] === true,
                                units: metricFromStore?.units,
                                showUnits: false,
                                t,
                            })}
                        </MainValue>
                    </MainValueWrapper>
                    <PercentDifferenceWrapper>
                        {percentDifferenceColor.direction === 'down' && (
                            <Icon type="caret-down" size="sm" color={'accent'} />
                        )}
                        {percentDifferenceColor.direction === 'up' && (
                            <Icon type="caret-up" size="sm" color={'success'} />
                        )}

                        <PercentDifferenceValue color={percentDifferenceColor.color}>
                            {valueFormatter({
                                value: data?.percentDifference,
                                showSpaceBetweenNumberAndUnit: false,
                                units: '%',
                            })}
                        </PercentDifferenceValue>
                    </PercentDifferenceWrapper>
                </Wrapper>
            </NumberWrapper>
        </WidgetWrapper>
    );
};

export default Number;
