import { memo, FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import {
    ContentWrapper,
    HeaderWrapper,
    Label,
    NotificationsWrapper,
    StyledMenuItem,
    WidgetAdditionalControlsWrapper,
    WidgetTitle,
    Wrapper,
} from './styles';
import { IProps } from './interfaces';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { Divider, MenuList } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../../../components/IconButton/IconButton';
import {
    ArrowClockwise20Regular,
    Delete20Regular,
    DocumentPdf20Regular,
    Heart20Regular,
    OptionsFilled,
} from '@fluentui/react-icons';
import { Dashboards_Reducer_Values, removeUserDashboardWidget } from '../../../../Dashboards.reducer';
import ContextualMenu from '../../../../components/ContextualMenu/ContextualMenu';
import Stack from '../../../../components/Stack/Stack';
import FeedbackButton from '../../../../components/WidgetAdditionalControls/components/FeedbackButton/FeedbackButton';
import PDFDownload from '../../../../components/WidgetAdditionalControls/components/PDFDownload/PDFDownload';
import TextInput from '../../../../components/TextInput/TextInput';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Spinner from '../../../../components/Spinner/Spinner';
import { generalReducerValues } from '../../../../General.reducer';

/**
 * Обертка всех виджетов (Предоставляет общий функционал по типу перезагрузки, удаления и тд)
 */
const WidgetWrapper: FC<PropsWithChildren<IProps>> = ({
    contentWrapperClassName,
    contentWrapperStyles,
    titleAdditionalText,
    widgetSelects,
    dashboardKey,
    hideTitle,
    isLoading,
    isNoData,
    children,
    widgetId,
    moduleId,
    isError,
    title,
    updateWidgetTrans,
    reloadWidget,
    wrapperPaddingSize = 'small',
    isTitleChangeable = true,
    titleSize = 'small',
    ...props
}) => {
    const { editPanelMode } = useSelector(Dashboards_Reducer_Values);
    const { domain } = useSelector(generalReducerValues);

    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [newTitle, setNewTitle] = useState('');

    const { t } = useTranslation(['metrics', 'translation']);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (headerRef?.current && props.getHeaderHeight) {
            props.getHeaderHeight(headerRef.current.clientHeight);
        }
    }, [headerRef?.current?.clientHeight]);

    useEffect(() => {
        setNewTitle(title);
    }, [title]);

    useEffect(() => {
        if (newTitle.length > 1 && newTitle !== title && dashboardKey && widgetId && updateWidgetTrans) {
            updateWidgetTrans({
                moduleId,
                dashboardKey,
                data: {
                    widgetId,
                    key: 'title',
                    value: newTitle,
                },
            });
        }
    }, [newTitle]);

    const status = useMemo(() => {
        if (isLoading) {
            return (
                <NotificationsWrapper>
                    {/* <AnimationWrapper>
                        <Lottie animationData={loading_animation_1} />
                    </AnimationWrapper> */}
                    <Spinner type={domain} labelPosition={'below'} label={t('Loading...')} />
                </NotificationsWrapper>
            );
        }

        if (isError) {
            return <NotificationsWrapper>{t('An error accrued while getting data')}</NotificationsWrapper>;
        }

        if (isNoData) {
            return <NotificationsWrapper>{t('No data')}</NotificationsWrapper>;
        }

        return null;
    }, [isError, isLoading, isNoData, t]);

    const onWidgetRemove = () => {
        if (dashboardKey && widgetId) {
            setContextualMenuOpen(false);
            dispatch(
                removeUserDashboardWidget({
                    dashboardKey,
                    widgetId,
                    moduleId,
                }),
            );
        }
    };

    const handleTitleChange = (data: { value: string }) => {
        setNewTitle(data.value);
    };

    const onWidgetReload = () => {
        setContextualMenuOpen(false);
        reloadWidget();
    };

    const openContextualMenu = () => {
        setContextualMenuOpen(true);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const onMouseEnter = () => {
        setIsHovered(true);
    };

    const onMouseLeave = () => {
        if (!isContextualMenuOpen) {
            setIsHovered(false);
        }
    };

    const titleText = `${t(title || '')}${titleAdditionalText ? `, ${titleAdditionalText}` : ''}`;

    return (
        <Wrapper paddingSize={wrapperPaddingSize} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div style={{ display: 'none' }}>
                {widgetSelects}
                {props.widgetOnBoardSelects}
            </div>
            {isHovered && !editPanelMode && (
                <ContextualMenu
                    contextualMenuBody={
                        <MenuList>
                            {isTitleChangeable && (
                                <HeaderWrapper>
                                    <Label isRequired={true}>{t('Title')}</Label>
                                    <TextInput
                                        handleChange={handleTitleChange}
                                        outsideValue={newTitle}
                                        hasError={newTitle.length < 2}
                                        placeholder={t('Title')}
                                        fullWidth={true}
                                    />
                                    <Divider style={{ margin: '10px 0px' }} />
                                </HeaderWrapper>
                            )}

                            <StyledMenuItem onClick={closeContextualMenu}>
                                <PDFDownload
                                    customContent={
                                        <Stack alignItems="center" gap={5}>
                                            <DocumentPdf20Regular />
                                            <span>{t('Download PDF')}</span>
                                        </Stack>
                                    }
                                    showTooltip={false}
                                    targetRef={ref}
                                    fileName={title}
                                />
                            </StyledMenuItem>

                            <StyledMenuItem data-test-id={`RELOAD-${widgetId}`} onClick={onWidgetReload}>
                                <Stack alignItems="center" gap={5}>
                                    <ArrowClockwise20Regular />
                                    <span>{t('Reload widget')}</span>
                                </Stack>
                            </StyledMenuItem>
                            {widgetId && (
                                <StyledMenuItem>
                                    <FeedbackButton
                                        widgetName={widgetId}
                                        triggerContent={
                                            <Stack alignItems="center" gap={5}>
                                                <Heart20Regular />
                                                <span>{t('Feedback')}</span>
                                            </Stack>
                                        }
                                        showTooltip={false}
                                    />
                                </StyledMenuItem>
                            )}
                            {widgetSelects && (
                                <>
                                    <Divider style={{ margin: '5px 0px' }} />
                                    {widgetSelects}
                                    <Divider style={{ margin: '5px 0px' }} />
                                </>
                            )}
                            <StyledMenuItem onClick={onWidgetRemove}>
                                <Stack alignItems="center" gap={5}>
                                    <Delete20Regular />
                                    <span>{t('Remove widget')}</span>
                                </Stack>
                            </StyledMenuItem>
                        </MenuList>
                    }
                    isContextualMenuOpen={isContextualMenuOpen}
                    closeContextualMenu={closeContextualMenu}
                >
                    <WidgetAdditionalControlsWrapper>
                        <IconButton icon={<OptionsFilled />} onClick={openContextualMenu} />
                    </WidgetAdditionalControlsWrapper>
                </ContextualMenu>
            )}
            <Stack
                ref={headerRef}
                direction="row"
                alignItems="flex-start"
                flexWrap="wrap"
                justifyContent="space-between"
                gap={10}
            >
                {!hideTitle && (
                    <WidgetTitle size={titleSize}>
                        <Tooltip
                            contentWrapperStyles={{
                                display: 'inline-block',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                minWidth: 'unset',
                                maxWidth: '100%',
                            }}
                            content={titleText}
                        >
                            {titleText}
                        </Tooltip>
                    </WidgetTitle>
                )}
                {Boolean(props.widgetOnBoardSelects) && props.widgetOnBoardSelects}
            </Stack>

            {status || (
                <ContentWrapper
                    className={contentWrapperClassName}
                    hideTitle={Boolean(hideTitle)}
                    style={contentWrapperStyles}
                >
                    {children}
                </ContentWrapper>
            )}
        </Wrapper>
    );
};

export default memo(WidgetWrapper);
