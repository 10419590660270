import { TDataObjectsFilterTypes } from '../../interfaces';
import { DataObjectsFilter } from '../DataObjectsFilter';
import { DataObjectsFloorsFilter } from '../DataObjectsFloorsFilter/DataObjectsFloorsFilter';
import { DataObjectsPasswayType } from '../DataObjectsPasswayType/DataObjectsPasswayType';
import { DataObjectsTenantFilter } from '../DataObjectsTenantFilter/DataObjectsTenantFilter';

/**
 * Функция для получения экземпляра фильтра
 */
const getFilterInstance = (filterType: TDataObjectsFilterTypes): null | DataObjectsFilter => {
    if (filterType === 'data_objects_floor') {
        return new DataObjectsFloorsFilter();
    }

    if (filterType === 'data_objects_passway_type') {
        return new DataObjectsPasswayType();
    }

    if (filterType === 'data_objects_tenant') {
        return new DataObjectsTenantFilter();
    }

    return null;
};

export default getFilterInstance;
