import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetsNavigation from '../../components/WidgetsNavigation/WidgetsNavigation';

const EventsFileUpload = () => {
    const { t } = useTranslation();
    const ref = useRef(null);

    return (
        <div ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Events file upload')}
                    <WidgetAdditionalControls pdfDownloadData={{ targetRef: ref }} widgetName={'Events file upload'} />
                </WidgetTitle>
                <WidgetsNavigation />
            </WidgetTitleWrapper>
            <div>File upload</div>
        </div>
    );
};

export default EventsFileUpload;
