import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../../../../components/Button/Button';

import { ButtonsWrapper, ContentWrapper, Wrapper } from './styles';
interface IProps {
    /** Закрытие модального окна  */
    closeModal: () => void;

    /** Удаление*/
    onDelete: () => void;
}

/**
 * Компонент для подтверждения удаления типа события
 */
const DeleteConfirmModalBody: FC<IProps> = ({ closeModal, onDelete }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <ContentWrapper>
                <div>{t('You are going to delete the event type. Continue?')}</div>
            </ContentWrapper>
            <ButtonsWrapper>
                <Button onClick={closeModal} style={{ marginLeft: 'var(--space-xxs)' }} appearance="outline">
                    {t('Cancel')}
                </Button>
                <Button appearance="primary" onClick={onDelete} style={{ marginLeft: 'var(--space-xxs)' }}>
                    {t('Ok')}
                </Button>
            </ButtonsWrapper>
        </Wrapper>
    );
};

export default DeleteConfirmModalBody;
