import { useCallback, useDeferredValue, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { emptyObject, emptyArray } from 'src/tools';

import { IDataAdapterResponse, DynamicsPMOProps } from '../interfaces';
// import { cabinetPreferencesValues } from '../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useRequestMetrics } from '../../../../tools/API/hooks/useRequestMetrics';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../tools/API/interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../tools/API/dataAccessors/metricsDataAccessor';
import { TMetricResponse } from '../../../../General.interfaces';
import { DYNAMICS_PMO_QUERY_KEY } from '../constants/constants';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { DS } from '../../../../constants/constants';
import { ISelectedOption } from '../../../Selects/Select/interfaces';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { useObjectNameById } from '../../../../hooks/useObjectTranslation';
import useGetEvents from '../../../../Chapters/Events/api/useGetEvents';
import useGetEventTypes from '../../../../Chapters/Events/api/useGetEventTypes';
import { IEvent, IEventType } from '../../../../Chapters/Events/interfaces';

import useFetchWeather from './useFetchWeather';
import defaultDataAdapter from './dataAdapter';

const useFetchData = (args: DynamicsPMOProps) => {
    const { token, lang, allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation(['metrics', 'translation']);
    const fetchData = useRequestMetrics({ isNetwork: args.isNetwork });
    const { getObjNameById } = useObjectNameById();

    const [hiddenSeries, setHiddenSeries] = useState<string[]>([]);
    const deferredHiddenSeries = useDeferredValue(hiddenSeries);

    const onSeriesHide = useCallback((id: string) => {
        setHiddenSeries((prevState) => {
            if (prevState.includes(id)) {
                return prevState.filter((seriesId) => seriesId !== id);
            }
            return [...prevState, id];
        });
    }, []);

    const eventTypesQueryResponse = useGetEventTypes({
        isQueryEnabled: Boolean(args.viewSettings?.showEvents),
        isNetwork: args.isNetwork,
    });
    const eventsQueryResponse = useGetEvents({
        isQueryEnabled: Boolean(args.viewSettings?.showEvents),
        isNetwork: args.isNetwork,
    });
    const weatherQueryResponse = useFetchWeather(args);

    const localCurrentOptions = useWidgetCurrentOptions(args.moduleId);

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        const getLocalCurrentOptionValue = (prefix: string, isArray = true): ISelectedOption | undefined => {
            const result = localCurrentOptions?.[`${prefix}${DS}${args.moduleId}${DS}${args.widgetId}`];
            return isArray ? result?.[0] : result;
        };

        const detail = getLocalCurrentOptionValue('detailSelect', false) ?? { id: null };
        const metric = getLocalCurrentOptionValue('metricSelect') ?? { id: args.metrics[0] };
        const object = getLocalCurrentOptionValue('objectSelect') ?? { id: args.reportingObjects[0]?.id };

        if (token) {
            const requestArgs: IRequestMetricsArgs[] = [];

            const requestTemplate: IRequestMetricsArgs = {
                time_freq: !isUndefined(args.detail) ? args.detail : (detail.id as TTimeFreq),
                object_aggregation: false,
                time_range: [],
                alias: null,
                obj_ids: [],
                metric: '',
                token,
            };

            switch (args.chartType) {
                case 'metrics': {
                    args.metrics.forEach((metric) => {
                        requestArgs.push({
                            ...requestTemplate,
                            time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                            obj_ids: [object.id as number],
                            alias: 'main',
                            metric,
                        });
                    });

                    break;
                }

                case 'objects': {
                    if (metric && typeof metric.id === 'string') {
                        requestArgs.push({
                            ...requestTemplate,
                            time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                            obj_ids: args.reportingObjects.map((item) => item.id),
                            metric: metric.id,
                            alias: 'main',
                        });
                    }
                    break;
                }

                case 'periods': {
                    if (object && typeof object.id === 'number' && metric && typeof metric.id === 'string') {
                        [args.mainPeriod, ...args.comparePeriods].forEach((period, index) => {
                            requestArgs.push({
                                ...requestTemplate,
                                time_range: [period.dateFrom, period.dateTo],
                                obj_ids: [object.id as number],
                                metric: metric.id as string,
                                alias: index === 0 ? 'main' : 'compare',
                            });
                        });
                    }
                    break;
                }

                case 'pos_metrics': {
                    if (metric && typeof metric.id === 'string' && args.metricParams?.posIds) {
                        const { posIds = emptyArray, aliases = emptyObject } = args.metricParams;
                        posIds.forEach((posId) => {
                            requestArgs.push({
                                ...requestTemplate,
                                time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                                obj_ids: args.reportingObjects.map((item) => item.id),
                                metric: metric.id as string,
                                alias: (aliases[posId] || posId).toString(),
                                metric_params: { point_of_sale_ids: posId },
                            });
                        });
                    }
                    break;
                }

                default:
                    break;
            }

            const dataAccessorArgs: IMetricsDAArgs = {
                requestArgs,
                fetchData,
            };

            return {
                queryKey: [DYNAMICS_PMO_QUERY_KEY, args.moduleId, args.widgetId, args.chartType, requestArgs],
                queryFn: () => metricsDataAccessor(dataAccessorArgs),
                dataAdapter: (response: TMetricResponse[]) =>
                    defaultDataAdapter({
                        detail: !isUndefined(args.detail)
                            ? ({ id: args.detail, text: args.detail } as ISelectedOption)
                            : { ...detail, text: '' },
                        weatherResponse: weatherQueryResponse.data,
                        eventTypes: eventTypesQueryResponse
                            .filter((response) => response.data)
                            .flatMap((response) => response.data as IEventType[]),
                        events: eventsQueryResponse
                            .filter((response) => response.data)
                            .flatMap((response) => response.data as IEvent[]),
                        viewSettings: args.viewSettings,
                        chartType: args.chartType,
                        reportingObjects: args.reportingObjects,
                        hiddenSeries: deferredHiddenSeries,
                        isNetwork: args.isNetwork,
                        onSeriesHide,
                        getObjNameById,
                        allMetrics,
                        response,
                        lang,
                        t,
                    }),
            };
        }
        return {};
    }, [
        args.metrics,
        args.metricParams,
        args.reportingObjects,
        args.moduleId,
        args.widgetId,
        args.detail,
        args.chartType,
        args.mainPeriod,
        args.comparePeriods,
        args.viewSettings,
        args.isNetwork,
        token,
        localCurrentOptions,
        fetchData,
        weatherQueryResponse.data,
        eventTypesQueryResponse,
        eventsQueryResponse,
        deferredHiddenSeries,
        onSeriesHide,
        getObjNameById,
        allMetrics,
        lang,
        t,
    ]);

    const queryResult = useQuery<TMetricResponse[], unknown, IDataAdapterResponse, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });

    return { ...queryResult, isFetching: queryResult.isFetching || weatherQueryResponse.isFetching };
};

export default useFetchData;
