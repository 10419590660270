import { Dispatch } from '@reduxjs/toolkit';
import { IMetric, IReportingObject, TMetricResponse } from '../../../../../../../General.interfaces';
import { BreadcrumbOptions, TooltipFormatterContextObject } from 'highcharts';
import {
    COLOR_AXIS_OPTIONS,
    NO_DATA_OPTIONS,
    SERIES_PLOT_OPTIONS,
    TREEMAP_CHART_OPTIONS,
    TREEMAP_PLOT_OPTIONS,
    TREEMAP_SERIES_OPTIONS,
} from '../../constants/treeMap';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { TFunction } from 'i18next';
import Tooltip from '../../../../../../../components/Charts/Tooltip/Tooltip';
import TooltipBody from '../../../../../../../components/Charts/TreeMap/components/TooltipBody/TooltipBody';
import { renderToString } from 'react-dom/server';
import { CHART_MOBILE_HEIGHT, CHART_DESKTOP_HEIGHT, DS } from '../../../../../../../constants/constants';
import { storeSelectedObjectId, storeSelectedParentId } from '../../reducer';
import { IDefaultDAResponse, ITreeMapData } from '../../interfaces';
import { PercentColor } from '../../enums';
import getDifferenceBetweenNumbers from '../../../../../../../tools/getDifferenceBetweenNumbers';

interface IArgs {
    /** Ответ от сервера */
    response: TMetricResponse[];
    /** Флаг (мобилка или нет) */
    isMobile: boolean;
    /** Все метрики */
    allMetrics: IMetric[];
    /** Объект отчетных объектов, где ключ - это ID отчетного объекта */
    reportingObjectsById: { [id: string]: IReportingObject };
    getObjName: (obj: IReportingObject, showType?: boolean, addFloor?: boolean | undefined) => string;
    dispatch: Dispatch;
    t: TFunction;
}

const defaultDataAdapter = (args: IArgs): IDefaultDAResponse => {
    const responses = args.response.reduce(
        (
            acc: {
                areaResponse: TMetricResponse;
                mainColorResponse: TMetricResponse;
                compareColorResponse: TMetricResponse;
            },
            metricResponse,
        ) => {
            const [metricResponseItem] = metricResponse;
            const [periodType, metricType] = metricResponseItem?.context?.alias?.split(DS) || [];

            if (periodType && metricType) {
                if (metricType === 'areaMetric') {
                    acc.areaResponse = metricResponse;
                }
                if (periodType === 'main' && metricType === 'colorMetric') {
                    acc.mainColorResponse = metricResponse;
                }
                if (periodType === 'compare' && metricType === 'colorMetric') {
                    acc.compareColorResponse = metricResponse;
                }
            }
            return acc;
        },
        { areaResponse: [], mainColorResponse: [], compareColorResponse: [] },
    );

    const colorMetricFromStore = args.allMetrics.find(
        (item) => item.id === responses.mainColorResponse[0]?.context.metric,
    );

    const seriesData: ITreeMapData[] = responses.areaResponse.map((item) => {
        const objectId = item.context.data_objects[0].id;
        const obj = args.reportingObjectsById[objectId];
        const mainValue = responses.mainColorResponse.filter((m) => m.context.data_objects[0].id === objectId)[0]
            ?.items[0].value;
        const compareValue = responses.compareColorResponse.filter((m) => m.context.data_objects[0].id === objectId)[0]
            ?.items[0].value;

        const percentValue = getDifferenceBetweenNumbers(Number(mainValue), Number(compareValue)).percentDifference;
        const name = args.getObjName(obj);
        const value = item.items[0].value;
        const colorValue = Number(percentValue);

        const areaResult: ITreeMapData = {
            id: `${objectId}${DS}${colorMetricFromStore?.id}${DS}${name}`,
            metricName: args.t(colorMetricFromStore?.text || ''),
            units: colorMetricFromStore?.units || '',
            compareValue,
            colorValue,
            mainValue,
            objectId,
            value,
            name,
        };
        if (isNaN(colorValue) || colorValue === Infinity) {
            areaResult.color = PercentColor.Empty;
        }

        return areaResult;
    });

    return {
        treeMapOptions: {
            title: {
                text: '',
            },
            credits: {
                enabled: false,
            },
            noData: NO_DATA_OPTIONS,
            colorAxis: COLOR_AXIS_OPTIONS,
            tooltip: {
                formatter: function (this: TooltipFormatterContextObject) {
                    let percent = '–';
                    if (!isNaN(Number(this.point?.['colorValue']))) {
                        percent = valueFormatter({ value: this.point['colorValue'], units: '%' });
                    }
                    return renderToString(
                        <Tooltip title={args.t(String(this.key))} subTitle={''}>
                            <TooltipBody
                                showSeriesName={false}
                                body={[
                                    {
                                        title: args.t(this?.point?.['metricName']),
                                        data: [
                                            { value: percent },
                                            {
                                                value: valueFormatter({
                                                    value: this.point?.['mainValue'],
                                                    units: this.point?.['units'],
                                                    t: args.t,
                                                }),
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </Tooltip>,
                    );
                },
                backgroundColor: 'transparent',
                animation: false,
                borderWidth: 0,
                useHTML: true,
                shadow: false,
                hideDelay: 0,
                shared: true,
                padding: 0,
            },
            chart: {
                ...TREEMAP_CHART_OPTIONS,
                height: args.isMobile ? CHART_MOBILE_HEIGHT : CHART_DESKTOP_HEIGHT,
                events: {
                    /**
                     * Отображение лейблов у элементов внутри группы (уровня)
                     */
                    redraw: function () {
                        if (this.series[0]?.['rootNode']) {
                            document.querySelector('.highcharts-data-label-color-0')?.setAttribute('display', 'none');
                        } else {
                            document
                                .querySelector('.highcharts-data-label-color-0')
                                ?.setAttribute('display', 'initial');
                        }
                    },
                },
            },
            plotOptions: {
                treemap: {
                    ...TREEMAP_PLOT_OPTIONS,
                    events: {
                        click: function (e) {
                            const id: number | undefined = e.point.options?.['objectId'];
                            id && args.dispatch(storeSelectedObjectId(id));
                        },
                    },
                },

                series: {
                    ...SERIES_PLOT_OPTIONS,
                    point: {
                        events: {
                            click: function (e) {
                                const parentId: string | undefined = e.point.options['parentId'];
                                parentId && args.dispatch(storeSelectedParentId(parentId));
                            },
                        },
                    },
                },
            },

            series: [
                {
                    ...TREEMAP_SERIES_OPTIONS,
                    data: seriesData,
                    breadcrumbs: {
                        events: {
                            click: (_: Event, options: BreadcrumbOptions) => {
                                if (options.level === 0) {
                                    args.dispatch(storeSelectedParentId(null));
                                }
                            },
                        },
                    },
                },
            ],
        },
    };
};

export default defaultDataAdapter;
