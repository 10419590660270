import { ISettings } from '../../../UserSettings/interfaces';
import { useSelector } from 'react-redux';
import { IPresetModalBodyProps } from './interfaces';
import { useState, useEffect, FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, Wrapper, Name } from './styles';
import Button from '../../../Button/Button';
import { deleteUserPreset, postUserPreset, PresetReducerValues } from '../../reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useAppDispatch } from '../../../../hooks/useSettings';
import { Input, InputOnChangeData, Label } from '@fluentui/react-components';
import useUserPresetData from '../../hooks/useUserPresetData';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';

const UserPresetModalBody: FC<IPresetModalBodyProps> = ({
    currentOptionsKeys,
    sendYaAnalytics,
    currentOptions,
    deletePreset,
    closeModal,
}) => {
    const sendAnalytics = useSendSimpleAnalytics();

    const [presetNameError, setPresetNameError] = useState(false);
    const [presetName, setPresetName] = useState('');
    const { t } = useTranslation();
    const { selectedUserPresetIds, userPresets } = useSelector(PresetReducerValues);
    const dispatch = useAppDispatch();
    const { currentModuleID } = useSelector(generalReducerValues);
    const userPresetsNames = useUserPresetData().map((item) => item.text);

    /**
     * Установка значения названия пресета при флаге удаления
     */
    useEffect(() => {
        if (deletePreset && userPresets.length) {
            const name = userPresets.find((item) => item.id === selectedUserPresetIds[currentModuleID])?.name;
            setPresetName(name || '');
            setPresetNameError(false);
        }
    }, [currentModuleID, deletePreset, selectedUserPresetIds, userPresets]);

    /**
     * Проверка валидности введенного названия пресета
     */
    useEffect(() => {
        setPresetNameError(false);

        !presetName || (userPresetsNames.includes(presetName) && setPresetNameError(true));
    }, [presetName]);

    /**
     * Удаление или сохранение пресета
     */
    const onButtonClick = () => {
        if (deletePreset) {
            if (selectedUserPresetIds[currentModuleID] !== null) {
                dispatch(deleteUserPreset(selectedUserPresetIds[currentModuleID] || 0));
                closeModal();
            }
        } else {
            const settings = currentOptionsKeys.reduce((acc: ISettings, value) => {
                acc[value as string] = currentOptions[value];
                return acc;
            }, {});

            dispatch(postUserPreset({ name: presetName, settings }));
            sendAnalytics('preset_creation');
            closeModal();
        }
    };

    const handleNameInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setPresetName(data.value);

    return (
        <Wrapper>
            {!deletePreset && (
                <Name>
                    <Label weight="semibold" htmlFor={'99underlineId'}>
                        {t('Name')}
                    </Label>
                    <Input
                        onChange={handleNameInput}
                        disabled={deletePreset}
                        value={presetName}
                        // placeholder={t('Name')}
                        appearance="underline"
                        id={'99underlineId'}
                    />
                </Name>
            )}
            {/* <TextInput
                handleChange={handleNameInput}
                hasError={presetNameError}
                outsideValue={presetName}
                placeholder={t('Name')}
                disabled={deletePreset}
                fullWidth
            /> */}
            <br />
            {/* <Label weight="semibold" htmlFor={'99underlineId'}>
                {t('Current settings:')}
            </Label>
            <SettingsWrapper>
                <SettingsBodyWrapper>
                    {currentOptionsKeys.map((element) => {
                        const valueFromKey = getCurrentOptionValueFromKey({
                            reportingObjectsById,
                            currentOptions,
                            key: element,
                            allMetrics,
                        });
                        return (
                            <SettingsBodyItem key={element}>
                                <SettingsSubtitle>
                                    {t(currentOptionNameByKey[element]) || t(element as string)}
                                </SettingsSubtitle>
                                {typeof valueFromKey === 'object' ? (
                                    valueFromKey.map((element) => (
                                        <SettingsText key={element + Math.random()}>{t(element)}</SettingsText>
                                    ))
                                ) : (
                                    <SettingsText>{t(valueFromKey)}</SettingsText>
                                )}
                            </SettingsBodyItem>
                        );
                    })}
                </SettingsBodyWrapper>
            </SettingsWrapper> */}
            <ButtonWrapper>
                <Button text={t('Cancel')} onClick={closeModal} />
                <Button
                    text={deletePreset ? t('Delete') : t('Save')}
                    disabled={presetNameError}
                    appearance={deletePreset ? undefined : 'primary'}
                    baseColor={deletePreset ? 'accent' : undefined}
                    onClick={onButtonClick}
                />
            </ButtonWrapper>
        </Wrapper>
    );
};

export default UserPresetModalBody;
