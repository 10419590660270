import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    overflow: auto;
    margin-top: var(--space-xxs);
    position: relative;
`;

export const Table = styled.table`
    width: 100%;
    height: 100%;
`;

export const Th = styled.th<{ paddingLeft?: string; paddingRight?: string }>`
    white-space: nowrap;
    padding-left: ${(p) => p.paddingLeft || '10px'};
    padding-right: ${(p) => p.paddingRight || '10px'};
`;

export const Td = styled.td<{ paddingTop?: string; paddingLeft?: string; paddingRight?: string }>`
    padding-top: ${(p) => p.paddingTop || '20px'};
    padding-left: ${(p) => p.paddingLeft || '10px'};
    padding-right: ${(p) => p.paddingRight || '10px'};
    :first-child {
        width: 100%;
        min-width: 150px;
    }
`;

export const Rating = styled.div<{ width: number | null }>`
    width: ${(p) => (p.width ? p.width + '%' : '0px')};
    height: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-info);
    height: 10px;
`;

export const RatingWrapper = styled.div<{ text: string }>`
    background-color: var(--color-secondary);
    border-radius: 5px;
    position: relative;

    &:before {
        content: ${(props) => (props.text ? `"${props.text}"` : '')};
        position: absolute;
        top: -20px;
        left: 0;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const Value = styled.div<{ color?: string; fontWeight?: string }>`
    white-space: nowrap;
    line-height: 14px;
    color: ${(p) => p.color || 'inherit'};
    font-weight: ${(p) => p.fontWeight || 'normal'};
`;
export const RatingHeader = styled.div`
    margin: 0 var(--space-xxxxs) 0 var(--space-sm);
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const DivBoxSC = styled.div<{
    $columns: number;
}>`
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
    grid-template-rows: 100%;
`;

export const DivWrapperHeaderCellSC = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content;
`;

export const DivWrapperSecondTextSC = styled.div`
    display: grid;
    color: var(--color-secondary-darker);
    font-size: xx-small;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    justify-content: end;
    justify-items: end;
`;
export const DivWrapperHeaderFullCellSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: end;
    align-items: center;
`;
export const DivWrapperContentDateTitleSC = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: max-content max-content;
    grid-template-rows: 100%;
    justify-content: end;
    gap: 10px;
`;
export const DivDateWrapperSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;
    font-weight: bold;
`;

export const DivWrapperBodyAndFooterSC = styled.div`
    border-right: 1px solid rgb(235, 235, 235);
    border-bottom: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);
    border-image: initial;
    border-radius: 0px 0px 5px 5px;
    border-top: none;
    box-sizing: border-box;
    display: grid;
    min-height: 200px;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content max-content;
`;

export const DivWrapperSpinnerSC = styled.div`
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgb(255, 255, 255);
`;
