import { FC, useMemo } from 'react';
import MetricsSelect from '../../../../../../../Selects/MetricsSelect/MetricsSelect';
import UniversalSelect from '../../../../../../../Selects/UniversalSelect/UniversalSelect';
import Stack from '../../../../../../../Stack/Stack';
import { IProps as ISelectsProps } from '../../interfaces';
import { IChangeOptionsArgs } from '../../../../../../../UserSettings/interfaces';
import { DS } from '../../../../../../../../constants/constants';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';

const TopByMetricsSelects: FC<ISelectsProps> = ({ widgetConfig, updateWidget }) => {
    const PRODUCT_CATEGORY_ID = 'Product Category';

    const {
        currentModuleID,
        src: { projectCategories },
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();

    /** Если Id виджета заканчивается на 0, то такой виджет считается дефолтным */
    const isDefaultRating = useMemo(() => {
        if (widgetConfig?.id) {
            const splittedId = widgetConfig.id.split(DS);
            return splittedId[splittedId.length - 1] === '0';
        }
        return false;
    }, [widgetConfig?.id]);

    /** Опции для селекта типа объектов в рейтингах */
    const parentCategoryOptions = useMemo(() => {
        return (
            projectCategories
                ?.filter((item) => !item.parent_id)
                ?.map((item) => ({ id: item.object_name, text: item.object_name })) || []
        );
    }, [projectCategories]);

    /** Дефолтный выбор для селекта выбора типа отчетных объектов */
    const defaultSelected = useMemo(() => {
        let result = { id: 'tenant', text: 'Tenants' };

        if (isDefaultRating && parentCategoryOptions?.length) {
            const productCategory = parentCategoryOptions.find((element) => element.id === PRODUCT_CATEGORY_ID);
            if (productCategory) {
                result = productCategory;
            } else {
                result = parentCategoryOptions[0];
            }
        }
        return result;
    }, [isDefaultRating, parentCategoryOptions]);

    const onSelectChange = (key: string) => (args: IChangeOptionsArgs) => {
        updateWidget(key, args.newOptions);
    };

    return (
        <Stack styles={{ padding: '0px 12px' }} direction="column" gap={5}>
            <MetricsSelect
                name={'metrics'}
                maxSelected={1}
                localCurrentOptions={widgetConfig?.options}
                changeOptions={onSelectChange('options')}
            />
            <UniversalSelect
                options={[...parentCategoryOptions, { id: 'tenant', text: 'Tenants' }]}
                localCurrentOptions={{ ...widgetConfig?.options, currentModuleID }}
                name={`selectedReportingObjectsType`}
                defaultSelected={[defaultSelected]}
                changeOptions={onSelectChange('options')}
                labelText={t('Objects type')}
                maxWidth={130}
                mode="single"
            />
        </Stack>
    );
};

export default TopByMetricsSelects;
