import { FC } from 'react';
import Tooltip from '../../../../../../../../../components/Tooltip/Tooltip';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';
import { Edit24Regular } from '@fluentui/react-icons';
import { useCustomFocusIndicatorStyles } from '../../../../../../../../../App.styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { IEventTypeColumnComponentProps } from '../../interfaces';
import { Button } from 'src/components/Button';

const EditEventType: FC<IEventTypeColumnComponentProps> = ({ eventType, openEventTypeModal }) => {
    const { user } = useSelector(generalReducerValues);
    const focusIndicatorStyles = useCustomFocusIndicatorStyles();
    const { t } = useTranslation();

    const onEdit = () => {
        openEventTypeModal(eventType);
    };

    return (
        <Tooltip content={t('Edit')}>
            <Button
                disabled={!user?.permissions.includes('app/client | modules/configuration/events | feature/edit')}
                className={focusIndicatorStyles.focusIndicator}
                appearance="transparent"
                icon={<Edit24Regular />}
                onClick={onEdit}
            />
        </Tooltip>
    );
};

export default EditEventType;
