import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { useMemo } from 'react';
import { getRequest } from '../../../../tools/API/appBackendAPI';
import { RENTS_QUERY_KEY } from '../constants/constants';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { IObject } from '../../../../components/Selects/Select/interfaces';
import { IAdapterResults, IRentPayment, TTF } from '../interfaces';
import _, { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { adapter } from './adapter';

export const useGetRents = () => {
    const {
        selectedLocationId,
        token,
        urlsByServices,
        currentModuleID,
        currencyCode,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const period: IObject[] | undefined = localCurrentOptions?.['period'];
    const data_object_ids = localCurrentOptions?.selectedReportingObjectsIds;

    const userSelectedTimeFreq: TTF = localCurrentOptions?.['detailing']?.[0]?.id || 'MS';

    const dates: object | undefined = useMemo(() => {
        if (period) {
            return period.reduce((acc, item: IObject) => {
                return { ...acc, [String(item.id)]: item.text };
            }, {});
        } else {
            return undefined;
        }
    }, [period]);

    const url = useMemo(() => {
        if (urlsByServices) {
            return urlsByServices['core/client-metrics'].CLIENT_METRIC_VALUES_URL;
        } else return undefined;
    }, [urlsByServices]);

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        if (selectedLocationId && url && data_object_ids && token && dates?.['dateFrom'] && dates?.['dateTo']) {
            const sortedIds = cloneDeep(data_object_ids);
            sortedIds.sort();
            return {
                queryKey: [RENTS_QUERY_KEY, selectedLocationId, sortedIds, dates?.['dateFrom'], dates?.['dateTo']],
                queryFn: () =>
                    getRequest(
                        `${url}?data_object_ids=${data_object_ids.join(',')}&date_from=${dates?.['dateFrom']}&date_to=${
                            dates?.['dateTo']
                        }`,
                        token,
                        undefined,
                        true,
                    ),
                dataAdapter: (response: AxiosResponse<IRentPayment[], any>) =>
                    adapter({
                        response,
                        ids: data_object_ids,
                        dates,
                        reportingObjectsById,
                        currencyCode,
                        t,
                    }),
            };
        }
        return {};
    }, [
        selectedLocationId,
        url,
        data_object_ids,
        token,
        dates,
        reportingObjectsById,
        currencyCode,
        userSelectedTimeFreq,
        t,
    ]);

    return useQuery<AxiosResponse<IRentPayment[]>, unknown, IAdapterResults, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
        retry: 3,
    });
};
