import { HeaderWrapper, SelectsWrapper, SelectsWrapperItem } from './styles';
import { FC } from 'react';
import { IChartOptionsProps } from './interfaces';
import { useTranslation } from 'react-i18next';
import UniversalTextInput from '../../../../../../../components/Selects/UniversalTextInput/UniversalTextInput';
import UniversalDatePicker from '../../../../../../../components/Selects/UniversalDatePicker/UniversalDatePicker';

/**
 * Компонент для отображения селектов, для выбора настроек графика
 * @param showEntitiesSelect Флаг, отвечающий за отображения селекта с объектами
 * @param showMetricsSelect Флаг, отвечающий за отображение селекта с метриками
 * @param showDatePeriod Флаг, отвечающий за отображение селекта с периодом
 * @param title Заголовок
 */
const MainSelects: FC<IChartOptionsProps> = ({
    showEntitiesSelect = true,
    showMetricsSelect = true,
    showDatePeriod = false,
    title,
}) => {
    const { t } = useTranslation();

    return (
        <HeaderWrapper>
            <SelectsWrapper>
                <SelectsWrapperItem>
                    <UniversalTextInput name={'rentInMonth'} labelText={t('Month rent, ₽')} type="number" min={0} />
                </SelectsWrapperItem>
                <SelectsWrapperItem>
                    <UniversalDatePicker name={'indexingDate'} labelText={t('Indexing date')} oneDayOnly />
                </SelectsWrapperItem>
                <SelectsWrapperItem>
                    <UniversalTextInput name={'indexPercent'} labelText={t('Index percent, %')} type="number" min={0} />
                </SelectsWrapperItem>
                <SelectsWrapperItem>
                    <UniversalTextInput name={'receivables'} labelText={t('Receivables, ₽')} type="number" min={0} />
                </SelectsWrapperItem>
            </SelectsWrapper>
        </HeaderWrapper>
    );
};

export default MainSelects;
