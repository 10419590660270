import { PresetReducerValues, resetSelectedUserPresetIds, storeSelectedUserPresetIds } from '../reducer';
import { createNewOptionId, deleteOptionId } from '../components/PresetModalBody/contants/constants';
import { changeOptions, userSettingsReducerValues } from '../../UserSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TPresets } from '../interfaces';
import { difference, isEqual } from 'lodash';
import { IObject } from '../../Selects/Select/interfaces';
import { generalReducerValues } from '../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { useAppDispatch } from '../../../hooks/useSettings';
import { patchRequest } from '../../../tools/API/appBackendAPI';

/**
 * Кастомный хук для работы с данными пресета
 * @returns Настройки для селекта пресета
 */
const useUserPresetData = () => {
    const { currentModuleID, selectedLocationId, urlsByServices, user, token, initialDataReceived } =
        useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { selectedUserPresetIds, userPresets } = useSelector(PresetReducerValues);
    const [options, setOptions] = useState<IObject[]>([]);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    /**
     * Получение опций селекта пресета
     */
    useEffect(() => {
        const createNewOption = {
            id: createNewOptionId,
            text: t('Create new'),
        };
        const deleteOption = {
            id: deleteOptionId,
            text: t('Delete selected'),
        };

        const result: IObject[] = [];
        userPresets
            .filter((preset) => preset?.settings?.pl?.[0].id === localCurrentOptions?.pl?.[0].id)
            .filter((preset) => preset.module_id === currentModuleID)
            .filter((preset) => {
                const presetPl = preset.settings?.['pl']?.map((element) => element.id) || [];
                const presetPls = preset.settings?.['pls']?.map((element) => element.id) || [];
                const localPl = localCurrentOptions?.pl?.map((element) => element.id) || [];
                const localPls = localCurrentOptions?.pls?.map((element) => element.id) || [];

                return !difference(presetPl, localPl).length || !difference(presetPls, localPls).length;
            })
            .forEach((preset) => {
                result.push({ id: preset.id, text: preset.name });
            });
        selectedUserPresetIds[currentModuleID] && result.push(deleteOption);
        result.push(createNewOption);
        setOptions(result);
    }, [currentModuleID, userPresets, localCurrentOptions?.pl, localCurrentOptions?.pls, t, selectedUserPresetIds]);

    return options;
};

export default useUserPresetData;
