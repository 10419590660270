import {
    PATH_TO_PURCHASE_FUNNEL_SETTINGS,
    PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS,
    PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS,
    optionsOfFunnelSteps,
} from './constants/constants';

import { ISettings } from '../../../components/UserSettings/interfaces';
import { useSelector } from 'react-redux';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import PathToPurchaseWidget from './widgets/PathToPurchaseWidget/PathToPurchaseWidget';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { memo } from 'react';
import usePrepareData from './hooks/usePrepareData';
import { useTranslation } from 'react-i18next';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';

/**
 * Компонент модуля "Путь к покупке"
 */
const PathToPurchase = memo(() => {
    const { groupsOfZonesSelectOptions, defaultSelectedGroup } = usePrepareData();
    const { t } = useTranslation();
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const validObjectTypes = [t('Tenants'), 'Zones', t('Floors')];

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ObjectsSelect
                            dataObjectFilters={['data_objects_floor', 'data_objects_tenant']}
                            maxSelected={8}
                            validOptions={validObjectTypes}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            name={PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS}
                            options={groupsOfZonesSelectOptions}
                            labelText={t('Group of zones')}
                            defaultSelected={defaultSelectedGroup ? [defaultSelectedGroup] : undefined}
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            name={PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS}
                            options={optionsOfFunnelSteps}
                            defaultSelected={optionsOfFunnelSteps.slice(0, 2)}
                            labelText={t('Funnel steps')}
                            minSelected={2}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS,
                                    PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS,
                                    PATH_TO_PURCHASE_FUNNEL_SETTINGS,
                                    'selectedReportingObjectsIds',
                                    'mainPeriod',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                <PathToPurchaseWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
});

export default PathToPurchase;
