import React, { memo, FC, useEffect, useState } from 'react';
import { IProps } from './interfaces';
import { Td, Tr } from '../../styles';
import { IReportingObject } from '../../../../../../../../General.interfaces';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import DataCell from '../DataCell/DataCell';
import sortHandler from './tools/sortHandler';
import { DS } from '../../../../../../../../constants/constants';

/**
 * Тело таблицы
 */
const Body: FC<IProps> = (props) => {
    const [sortedReportingObjectIds, setSortedReportingObjectIds] = useState<number[]>([]);

    const { t } = useTranslation();

    /** Сортировка */
    useEffect(() => {
        if (props.orderingData) {
            setSortedReportingObjectIds(
                sortHandler({
                    selectedReportingObjectId: props.selectedReportingObjectId,
                    reportingObjects: props.reportingObjects,
                    orderingData: props.orderingData,
                    moduleId: props.moduleId,
                    widgetId: props.widgetId,
                }),
            );
        } else {
            const result = props.reportingObjects
                .map((item) => item.id)
                .filter((item) => item !== props.selectedReportingObjectId);
            if (props.selectedReportingObjectId) {
                result.unshift(props.selectedReportingObjectId);
            }
            setSortedReportingObjectIds(result);
        }
    }, [props.moduleId, props.orderingData, props.reportingObjects, props.widgetId, props.selectedReportingObjectId]);

    const onRowClick = (reportingObjectId: number) => () => {
        props.setSelectedReportingObjectId(reportingObjectId);
    };

    return (
        <tbody>
            {sortedReportingObjectIds.map((reportingObjectId) => {
                const reportingObject = props.reportingObjects.find((item) => item.id === reportingObjectId) as
                    | undefined
                    | IReportingObject;

                return (
                    <Tr
                        key={reportingObjectId}
                        isSelected={reportingObjectId === props.selectedReportingObjectId}
                        onClick={onRowClick(reportingObjectId)}
                    >
                        <Td>{reportingObject?.name || t('Unknown reporting object')}</Td>
                        {props.metrics.map((metric) => {
                            return (
                                <DataCell
                                    key={`${reportingObjectId}${DS}${metric}`}
                                    reportingObjectId={reportingObjectId}
                                    metric={metric}
                                    {...props}
                                />
                            );
                        })}
                    </Tr>
                );
            })}
        </tbody>
    );
};

export default memo(Body);
