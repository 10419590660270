import generateMetricsQueryData from '../../../../../../tools/API/queryDataGenerators/generateMetricsQueryData';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import {
    storeRawMetricsData,
    storeTableSortData,
    Events_Events_TableOfObjects_Widget_Reducer_Values,
} from '../reducer';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePrepareData from './usePrepareData';
import { useEffect, useMemo } from 'react';
import { Events_Events_Module_Reducer_Values } from '../../../reducer';

/** Кастомный хук для получения сырых данных*/
export const useGetRawData = () => {
    const { currentEvent, moduleName } = useSelector(Events_Events_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { idsOfReportingObjects, selectedComparePeriod, dataRefetchObject } = useSelector(
        Events_Events_TableOfObjects_Widget_Reducer_Values,
    );
    const { token } = useSelector(generalReducerValues);
    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePrepareData();

    const compareDateRangesPeriods = useMemo(() => {
        return localCurrentOptions?.compareDateRanges?.map((element) => element?.period);
    }, [localCurrentOptions?.compareDateRanges]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions?.compareDateRanges &&
            localCurrentOptions?.selectedMetrics &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            selectedComparePeriod &&
            idsOfReportingObjects &&
            currentEvent &&
            token
        ) {
            dispatch(storeTableSortData(null));
            const mainPeriodQueryData = generateMetricsQueryData({
                selectedMetrics: localCurrentOptions.selectedMetrics,
                dateRanges: localCurrentOptions.mainDateRanges,
                selectedReportingObjectsIds: idsOfReportingObjects,
                period: localCurrentOptions.mainPeriod,
                timeFreq: null,
                token,
            }).map((element) => ({ ...element, signal }));
            const comparePeriodQueryData = generateMetricsQueryData({
                selectedMetrics: localCurrentOptions.selectedMetrics,
                dateRanges: localCurrentOptions.compareDateRanges,
                period: selectedComparePeriod,
                selectedReportingObjectsIds: idsOfReportingObjects,
                timeFreq: null,
                token,
            }).map((element) => ({ ...element, signal }));
            const finalQueryData = mainPeriodQueryData.concat(comparePeriodQueryData);
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));
            fetchData(finalQueryData).then((response: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    res: response,
                    dispatch,
                });
            });
        }
        return () => controller.abort();
    }, [
        JSON.stringify(compareDateRangesPeriods),
        localCurrentOptions?.selectedMetrics,
        selectedComparePeriod,
        idsOfReportingObjects,
        dataRefetchObject,
        currentEvent,
        token,
    ]);
};
