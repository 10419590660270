import axios from 'axios';
import { getHeaders } from './getHeaders';
import { IRequestVersionsArgs } from './interfaces';

const requestVersions = async (args: IRequestVersionsArgs) => {
    const { mlId, token, url } = args;
    const headers = getHeaders(token);

    try {
        const response = await axios({
            method: 'GET',
            headers,
            url: `${url}?location_id=${mlId}`,
        });

        return response;
    } catch (error) {
        return error;
    }
};

export default requestVersions;
