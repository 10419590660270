import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { StIcon, Wrapper } from './styles';
import { getReportingObjects } from '../../../tools/getReportingObjects';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { userSettingsReducerValues, changeOptions } from '../../UserSettings/reducer';
import Select from '../../Selects/Select/Select';
import { TSelectedOptions } from '../../Selects/Select/interfaces';
import { getObjSectionName } from '../../../tools/Strings/getObjSectionName';
import { useAppDispatch } from '../../../hooks/useSettings';

const FiltersSelect: React.FC<IProps> = ({ maxSelected, label = 'Filters', dropdownAlign = 'left', validOptions }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        cfg: { reportingObjectsByType, reportingObjectsById },
    } = useSelector(generalReducerValues);

    const { currentOptions } = useSelector(userSettingsReducerValues);
    const { currentModuleID, selectedLocationId } = currentOptions;

    const options = useMemo(() => {
        let result = [];
        if (!validOptions) {
            result.push(...getReportingObjects(reportingObjectsByType, t));
        } else {
            result.push(
                ...getReportingObjects(reportingObjectsByType, t)?.filter((item) =>
                    validOptions.map((item) => item?.toLowerCase()).includes(item.section?.toLowerCase()),
                ),
            );
        }

        return result;
    }, [validOptions, reportingObjectsByType, t]);

    const outsideSelected: TSelectedOptions = [];

    const ids = currentOptions?.['filters'] || [];

    ids.forEach((id: number) => {
        const obj = reportingObjectsById[id];
        if (obj) {
            outsideSelected.push({
                ...obj,
                section: getObjSectionName({ reportingObject: obj, t }),
                text: obj.name,
            });
        }
    });

    const handleSelect = (args: { id: number | null | string; block_type?: string }[]) => {
        selectedLocationId &&
            dispatch(
                changeOptions({
                    newOptions: {
                        filters: args?.filter((item) => item !== null).map((item) => Number(item.id)),
                    },
                }),
            );
    };

    const max = maxSelected ? ` (${t('max')}: ${maxSelected})` : '';

    return (
        <Wrapper>
            <Select
                testId={`${currentModuleID}_filter`}
                options={options}
                dropdownWidth="md"
                text={t('Selected')}
                dropdownAlign={dropdownAlign}
                showFilter={true}
                mode="multi"
                bgColor="secondary-light"
                labelText={`${t(label)}${max}`}
                iconType="filter"
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                outsideSelected={outsideSelected}
                additionalControl={<StIcon />}
            />
        </Wrapper>
    );
};

export default FiltersSelect;
