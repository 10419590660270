import { makeStyles, shorthands } from '@fluentui/react-components';
import styled from 'styled-components/macro';

export const TablistWrapper = styled.div`
    height: fit-content;
    min-width: 150px;
    flex-grow: 1;
    overflow: hidden;
`;

export const useStyles = makeStyles({
    root: {
        // alignItems: 'flex-start',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'flex-start',
        ...shorthands.padding('50px', '20px'),
        // rowGap: '20px',
    },
});

export const useTabStyles = makeStyles({
    root: {
        paddingBottom: 'var(--space-xxxs)',
    },
});
