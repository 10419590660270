import React, { ComponentType, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGeneralSelector, useFilteredObjects } from 'src/hooks';

import HeaderCell from '../HeaderCell';
import { TOrdering } from '../../../../../../../components/OrderSwitcher/interfaces';
import { Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../../reducer';
import { withLoading } from '../../../../../../../tools/API/withLoading';

import { StyledHead, StyledTable, TableWrapper, Tr } from './styles';
import { IBuildTrArgs } from './interfaces';
import Cell from './Cell';

const ComplexTable: React.FC = ({}) => {
    const [expandedAliases, setExpandedAliases] = useState<string[]>([]);

    const { preparedData, allTenantsData, showAverageValues, showReferenceCompareValues } = useSelector(
        Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    );
    const { allMetrics } = useGeneralSelector();
    const { t } = useTranslation();

    const [ordering, setOrdering] = useState<{ ordering: TOrdering; primaryType: string }>({
        ordering: 'none',
        primaryType: 'percent',
    });

    const handleOrderToggle = (args: { ordering: TOrdering; id: string }) => {
        setOrdering({ ordering: args.ordering, primaryType: args.id });
    };

    const toggleCollapse = (alias: string) => {
        if (expandedAliases.includes(alias)) {
            setExpandedAliases(expandedAliases?.filter((item) => item !== alias));
        } else {
            setExpandedAliases([...expandedAliases, alias]);
        }
    };

    const filteredTenants = useFilteredObjects('tenant', {
        mode: 'multi',
        validOptions: [t('Tenants')],
        dataObjectFilters: ['data_objects_tenant'],
    });

    const filteredTenantsIds = filteredTenants.map(({ id }) => (id));

    const selectedTenantsData = !Array.isArray(allTenantsData)
        ? allTenantsData
        : allTenantsData.map((dataSeries) =>
            dataSeries.filter((data) => filteredTenantsIds.includes(data.context.data_objects?.[0].id)),
        )

    const table = useMemo(() => {
        const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: selectedTenantsData, height: 400 });
        if (Array.isArray(preparedData)) {
            const selectedTenants = preparedData.filter((element) => element.alias === 'selectedTenant');
            const header = (
                <StyledHead>
                    <Tr key={`ttss`}>
                        <HeaderCell
                            colSpan={1}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                        >
                            {t('Tenant')}
                        </HeaderCell>
                        {preparedData?.[0]?.metricsData?.map((item: any, i: number) => {
                            const meticName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
                            return (
                                <HeaderCell
                                    key={`${item?.metric}--${i}`}
                                    type={item.metric}
                                    colSpan={2}
                                    handleOrderToggle={handleOrderToggle}
                                    ordering={ordering.ordering}
                                    primaryType={ordering.primaryType}
                                >
                                    {t(meticName, { ns: 'metrics' })}
                                </HeaderCell>
                            );
                        })}
                    </Tr>
                    {selectedTenants.map(mapPreparedData(expandedAliases)).map(
                        buildTr({
                            showAverageValues,
                            showReferenceCompareValues,
                            toggleCollapse,
                        }),
                    )}
                </StyledHead>
            );

            const rows = preparedData
                .map(mapPreparedData(expandedAliases))
                ?.filter((row) => row[0]?.collapseable || expandedAliases.includes(row[0]?.alias))
                .map(buildTr({ showAverageValues, showReferenceCompareValues, toggleCollapse }));

            return (
                <WithLoadingTable>
                    <StyledTable>
                        {header}
                        <tbody style={{ position: 'relative' }}>{rows}</tbody>
                    </StyledTable>
                </WithLoadingTable>
            );
        } else {
            return <WithLoadingTable>{null}</WithLoadingTable>;
        }
    }, [showReferenceCompareValues, showAverageValues, expandedAliases, selectedTenantsData, preparedData, ordering, t]);

    return table;
};

export default ComplexTable;

const mapPreparedData = (expandedAliases: string[]) => (row: any) => {
    const data =
        row?.metricsData?.reduce((acc: any[], item: any) => {
            return [
                ...acc,
                {
                    value: item?.main,
                    mainAvg: item?.mainAvg,
                    referenceCompare: item?.referenceCompare,
                    referenceCompareByMainAvg: item?.referenceCompareByMainAvg,
                    type: 'main',
                    metric: item.metric,
                    collapseable: row.collapseable,
                    alias: row.alias,
                },
                {
                    value: item?.deviation,
                    type: 'deviation',
                    metric: item.metric,
                    deviationAvg: item?.deviationAvg,
                    collapseable: row.collapseable,
                    deviationByReferenceCompare: item?.deviationByReferenceCompare,
                    alias: row.alias,
                },
            ];
        }, []) || [];

    return [
        {
            value: row?.tenantName,
            isSelectedTenant: row.isSelectedTenant,
            type: 'name',
            collapseable: row.collapseable,
            alias: row.alias,
            isExpanded: expandedAliases.includes(row.alias),
        },
        ...data,
    ];
};

const buildTr = (args: IBuildTrArgs) => (row: any, i: number) => {
    const { showReferenceCompareValues, showAverageValues, toggleCollapse } = args;
    const isSelectedTenant = row[0]?.isSelectedTenant;
    const cells = row.map((cell: any, i: number) => {
        return (
            <Cell
                showReferenceCompareValues={showReferenceCompareValues}
                showAverageValues={showAverageValues}
                isSelectedTenant={isSelectedTenant}
                toggleCollapse={toggleCollapse}
                key={`${cell?.value}mm${i}`}
                {...cell}
            />
        );
    });

    return (
        <Tr isGroup={row[0]?.collapseable} key={`row{{}}${i}`} isSelectedTenant={isSelectedTenant}>
            {cells}
        </Tr>
    );
};
