import styled from 'styled-components/macro';
import Icon from '../../../../Icon/Icon';

export const HeaderWrapper = styled.div`
    height: 45px;
    margin-bottom: var(--space-xxxs);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

export const ButtonsWrapper = styled.div`
    span {
        margin-right: var(--space-sm);
    }
`;

export const TextWrapper = styled.div`
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
`;
