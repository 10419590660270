import { FC } from 'react';
import ObjectsSelect from '../../../../../../Selects/ObjectsSelect/ObjectsSelect';
import { IDefaultSelectProps } from '../../../interfaces';
import { IChangeOptionsArgs } from '../../../../../../UserSettings/interfaces';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useSelector } from 'react-redux';

const OptionsSelects: FC<IDefaultSelectProps> = (props) => {
    const { selectedLocationId } = useSelector(generalReducerValues);

    const updateWidgetLocalObjects = (selectName: string) => (args: IChangeOptionsArgs) => {
        if (selectedLocationId) {
            props.updateWidget('options', {
                selectedObjectsIdsByPlId: {
                    ...props.widgetConfig?.options?.selectedObjectsIdsByPlId,
                    [selectedLocationId]: {
                        ...props.widgetConfig?.options?.selectedObjectsIdsByPlId?.[selectedLocationId],
                        ...args.newOptions,
                    },
                },
            });
        }
    };

    return (
        <>
            <ObjectsSelect
                label="Main object"
                disabled={!(props.widgetConfig?.visual?.useMainFrom?.[0]?.id === 'widget')}
                name={'mainObjectIds'}
                localCurrentOptions={
                    props.widgetConfig?.options?.selectedObjectsIdsByPlId?.[selectedLocationId || ''] || {}
                }
                changeOptions={updateWidgetLocalObjects('mainObjectIds')}
                maxSelected={1}
            />
            <ObjectsSelect
                label="Comparison objects"
                disabled={!(props.widgetConfig?.visual?.useComparisonFrom?.[0]?.id === 'widget')}
                name={'comparisonObjectsIds'}
                localCurrentOptions={
                    props.widgetConfig?.options?.selectedObjectsIdsByPlId?.[selectedLocationId || ''] || {}
                }
                changeOptions={updateWidgetLocalObjects('comparisonObjectsIds')}
                maxSelected={10}
            />
        </>
    );
};

export default OptionsSelects;
