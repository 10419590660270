import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IReducerState } from './interfaces';

const initialState: IReducerState = {
    moduleName: 'Leasing:Tenant overview',
};

export const Leasing_TenantOverview_Module_Reducer = createSlice({
    name: 'Leasing_TenantOverview_Module_Reducer',
    initialState,

    reducers: {
        // storeTenantRelations: (state, action: PayloadAction<TLoadingData>) => {
        //     state.tenantRelations = action.payload;
        // },
    },
});

export const {} = Leasing_TenantOverview_Module_Reducer.actions;

export const Leasing_TenantOverview_Module_Reducer_Values = (state: RootState) => state.Leasing_TenantOverview_Module_Reducer;

export default Leasing_TenantOverview_Module_Reducer.reducer;
