import _ from 'lodash';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { usePrepareData } from './usePrepareData';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';

import {
    Performance_Ratings_RatingList_Widget_Reducer_Values,
    storeRatingsData,
    storeRatingsDataRecived,
    storeResponseResult,
} from '../reducer';
import { Performance_Ratings_Module_Reducer_Values } from '../../../reducer';
import { DS } from '../../../../../../constants/constants';

export const useFetchData = () => {
    usePrepareData();
    const { moduleName } = useSelector(Performance_Ratings_Module_Reducer_Values);
    const fetchData = useRequestMetrics();
    const {
        token,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { ratings, ratingsDataRecived, ratingsData } = useSelector(
        Performance_Ratings_RatingList_Widget_Reducer_Values,
    );
    const [periods, setPeriods] = useState('');
    const [ratingSwicher, setRatingSwicher] = useState<
        { mainMetric: string | undefined; mainObjects: string | undefined }[] | null
    >(null);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const dispatch = useDispatch();

    /**
     * Создает массив значений по которым стоит делать запрос при изменении рейтинга
     */
    useEffect(() => {
        const newRatingSwicher = ratings?.map((item) => {
            return {
                mainMetric: item.metric,
                mainObjects: item.dataobj_type,
                locationId: localCurrentOptions?.selectedLocationId,
            };
        });

        if (localCurrentOptions?.selectedLocationId) {
            if (newRatingSwicher && newRatingSwicher.length !== 0) {
                setRatingSwicher(newRatingSwicher);
            }
        }
    }, [localCurrentOptions?.selectedLocationId, ratings]);

    /**
     * Создает зависимость от редактирования периодов
     */
    useEffect(() => {
        /**
         * Создает зависимость от редактирования периодов
         */
        if (localCurrentOptions?.comparePeriods && localCurrentOptions?.mainPeriod) {
            dispatch(storeResponseResult(null));
            dispatch(storeRatingsDataRecived([]));
            dispatch(storeRatingsData([]));

            setPeriods(
                JSON.stringify([
                    localCurrentOptions?.comparePeriods,
                    localCurrentOptions?.mainPeriod,
                    localCurrentOptions?.selectedLocationId,
                ]),
            );
        }
    }, [localCurrentOptions?.comparePeriods, localCurrentOptions?.mainPeriod]);

    /**
     * Запрос рейтингов
     */
    useEffect(() => {
        if (!localCurrentOptions || !localCurrentOptions.mainDateRanges) return;
        const controller = new AbortController();
        const signal = controller.signal;

        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item?.id === mainPeriod);
        const mainTimeRange = [mainDateRange?.period?.dateFrom, mainDateRange?.period?.dateTo];
        const comparePeriod = localCurrentOptions.comparePeriods;
        let allObjectsIdRating: number[] = [];
        if (ratings && ratings.length && comparePeriod) {
            const filtered = ratings?.filter((item) => !ratingsDataRecived?.includes(item.id));
            const [compareDateRange] = localCurrentOptions.compareDateRanges?.filter(
                (item) => item.id === comparePeriod[0].id,
            );
            const compareTimeRange = [compareDateRange?.period.dateFrom, compareDateRange?.period.dateTo];

            const requestParams = {
                signal,
                token,
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };
            const filteredRatingsData = _.cloneDeep(ratingsData);
            _.forEach(filtered, function (item) {
                _.remove(filteredRatingsData, function (n) {
                    return n.id === item.id;
                });
            });
            dispatch(storeRatingsData(filteredRatingsData));
            _.forEach(filtered, function (item) {
                const objectsIds = _.map(reportingObjectsByType?.[item.dataobj_type ?? ''], 'id');
                allObjectsIdRating.push(...objectsIds);
                const mainMetric = item.metric ?? '';
                const metricMainRequest = {
                    ...requestParams,
                    time_range: mainTimeRange,
                    obj_ids: objectsIds,
                    metric: mainMetric,
                    alias: String(item.id) + `${DS}MainPeriod`,
                };
                const metricCompareRequest = {
                    ...requestParams,
                    time_range: compareTimeRange,
                    obj_ids: objectsIds,
                    metric: mainMetric,
                    alias: String(item.id) + `${DS}ComparePeriod`,
                };
                const areaRequest = {
                    ...requestParams,
                    time_range: mainTimeRange,
                    obj_ids: objectsIds,
                    metric: 'sum_area',
                    alias: String(item.id) + `${DS}Area`,
                };

                fetchData([metricMainRequest, metricCompareRequest, areaRequest])
                    .then((res: TServerResponse) => {
                        handleServerResponse({
                            responseAnalyzer: responseAnalyzer,
                            success: storeResponseResult,
                            error: storeResponseResult,
                            dispatch,
                            res,
                        });
                    })
                    .catch((error: any) => {
                        console.log(`Ratings usegetMetrics error: ${error}`);
                    });
            });
        }
        return () => {
            controller.abort();
        };
    }, [JSON.stringify(ratingSwicher), periods]);
};
