import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { cabinetPreferencesValues } from '../../../../CabinetPreferences/reducer';
import { useMemo } from 'react';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../tools/API/hooks/useRequestMetrics';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../../tools/API/interfaces';
import { IPeriodObj, TMetricResponse } from '../../../../../General.interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { DS } from '../../../../../constants/constants';
import { useQuery } from '@tanstack/react-query';
import { ratingDefaultTopAdapter } from '../dataAdapters/ratingDefaultTopAdapter';
import { ISelectedOption } from '../../../../Selects/Select/interfaces';
import { IRatingsDataAdapterResponseItem, TWidgetConfig, TWidgetType } from '../interfaces';
import ratingDefaultPasswaysTrafficAdapter from '../dataAdapters/ratingDefaultPasswaysTrafficAdapter';

interface IArgs {
    /** Конфигурация виджета */
    widgetConfig: TWidgetConfig;
    /** ID текущего модуля */
    moduleId: string;
    /** Ключ текущего dashboard */
    dashboardKey: string | null;
    /** Метрика для passwaysTraffic */
    passwaysTrafficMetric: string;
    /** Тип виджета */
    widgetType: TWidgetType;
}

const useFetchData = (args: IArgs) => {
    const { widgetConfig, moduleId, dashboardKey, passwaysTrafficMetric, widgetType } = args;
    const {
        token,
        allMetrics,
        cfg: { reportingObjectsByType },
        structures,
    } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(moduleId);
    const fetchData = useRequestMetrics();

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        const mainDate = localCurrentOptions?.mainDateRanges?.find(
            (item) => item.id === localCurrentOptions?.mainPeriod?.id,
        );

        const compareDatePeriods: IPeriodObj[] = [];

        localCurrentOptions?.comparePeriods?.forEach((comparePeriod) => {
            const period = localCurrentOptions?.compareDateRanges?.find(
                (element) => element.id === comparePeriod.id,
            )?.period;

            if (period) compareDatePeriods.push(period);
        });

        if (
            widgetConfig?.sources?.length &&
            compareDatePeriods.length &&
            widgetConfig?.id &&
            mainDate?.period &&
            dashboardKey &&
            moduleId &&
            token
        ) {
            let objIds: number[] = [];
            let metric = '';
            let dataAdapter: undefined | ((args: unknown) => IRatingsDataAdapterResponseItem[]);

            switch (widgetType) {
                case 'topByMetrics': {
                    const [selectedObjectsType]: (undefined | ISelectedOption)[] =
                        widgetConfig?.options?.selectedReportingObjectsType || [];

                    if (selectedObjectsType?.id && reportingObjectsByType?.[selectedObjectsType?.id]) {
                        objIds = reportingObjectsByType?.[selectedObjectsType?.id]?.map((item) => item.id) || [];
                    }

                    metric = widgetConfig.options?.metrics?.[0] || '';

                    dataAdapter = (response: TMetricResponse[]) =>
                        ratingDefaultTopAdapter({
                            numberToShow: objIds?.length,
                            allMetrics,
                            response,
                        });
                    break;
                }
                case 'passwaysTraffic': {
                    if (reportingObjectsByType?.['passway']?.length) {
                        objIds = reportingObjectsByType['passway'].map((passway) => passway.id) || [];
                    }
                    metric = passwaysTrafficMetric;
                    dataAdapter = (response: TMetricResponse[]) =>
                        ratingDefaultPasswaysTrafficAdapter({
                            numberToShow: objIds?.length,
                            allMetrics,
                            response,
                            mainPeriod: mainDate,
                            visual: widgetConfig?.visual,
                            passway2DataObj: structures?.relations_passway2dataobj,
                        });
                    break;
                }
                default:
                    break;
            }

            switch (widgetConfig.sources[0]) {
                case 'default': {
                    const mainPeriodArgs = prepareArgs({
                        timeRange: [mainDate.period.dateFrom, mainDate.period.dateTo],
                        objIds,
                        alias: `mainPeriod${DS}${widgetConfig.id}`,
                        timeFreq: null,
                        metric,
                        token,
                    });

                    const comparePeriodArgs = prepareArgs({
                        timeRange: [compareDatePeriods[0].dateFrom, compareDatePeriods[0].dateTo],
                        alias: `comparePeriod${DS}${widgetConfig.id}`,
                        objIds,
                        timeFreq: null,
                        metric,
                        token,
                    });
                    const args: IMetricsDAArgs = {
                        requestArgs: [mainPeriodArgs, comparePeriodArgs],
                        fetchData,
                    };
                    return [
                        [
                            'default',
                            'rating',
                            objIds,
                            compareDatePeriods,
                            mainDate?.period,
                            moduleId,
                            metric,
                            dashboardKey,
                            widgetConfig?.options,
                            widgetConfig?.id,
                        ],
                        () => metricsDataAccessor(args),
                        dataAdapter,
                    ];
                }

                default:
                    return [[]];
            }
        }
        return [[]];
    }, [
        localCurrentOptions?.mainDateRanges,
        localCurrentOptions?.comparePeriods,
        localCurrentOptions?.mainPeriod?.id,
        localCurrentOptions?.compareDateRanges,
        widgetConfig,
        dashboardKey,
        moduleId,
        token,
        widgetType,
        reportingObjectsByType,
        allMetrics,
        passwaysTrafficMetric,
        structures?.relations_passway2dataobj,
        fetchData,
    ]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter) && Boolean(localCurrentOptions),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
        retry: 2,
    });
};

export default useFetchData;

interface IPrepareArgs {
    timeRange: [string, string];
    timeFreq: TTimeFreq;
    objIds: number[];
    metric: string;
    alias: string;
    token: string;
}

const prepareArgs = (args: IPrepareArgs): IRequestMetricsArgs => {
    const { timeRange, alias, metric, objIds, token, timeFreq } = args;
    return {
        object_aggregation: false,
        time_range: timeRange,
        time_freq: timeFreq,
        obj_ids: objIds,
        metric,
        alias,
        token,
    };
};
