import { createNewOptionId, deleteOptionId } from './components/PresetModalBody/contants/constants';
import PresetModalBody from './components/PresetModalBody/PresetModalBody';
import { PresetReducerValues, storeSelectedPreset, storeSelectedUserPresetIds } from './reducer';
import { userSettingsReducerValues } from '../UserSettings/reducer';
import { generalReducerValues } from '../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';
import usePresetData from './hooks/usePresetData';
import { useTranslation } from 'react-i18next';
import { IPresetProps } from './interfaces';
import { FC, useMemo, useState } from 'react';
import { Share24Regular } from '@fluentui/react-icons';
import Select from '../Selects/Select/Select';
import { TSelectedOptions, ISelectedOption } from '../Selects/Select/interfaces';
import Modals from '../Modals/Modals';
import useUserPresetData from './hooks/useUserPresetData';
import UserPresetModalBody from './components/UserPresetModalBody/UserPresetModalBody';
import Stack from '../Stack/Stack';
import { ShareWrapper, Wrapper } from './styles';
import Tooltip from '../Tooltip/Tooltip';
import MakeSharedModalBody from './components/MakeSharedModalBody/MakeSharedModalBody';
import { useSendSimpleAnalytics } from '../../hooks/useAnalytics';

/**
 * Компонент для создания пресетов
 * @param currentOptionsKeys ключи из настроек currentOptions, которые необходимо поместить в пресет
 */
const Preset: FC<IPresetProps> = ({ currentOptionsKeys }) => {
    const {
        currentOptions,
        currentOptions: { currentModuleID, selectedLocationId },
    } = useSelector(userSettingsReducerValues);
    const {
        cfg: { reportingObjectsById },
        allMetrics,
        sendYaAnalytics,
    } = useSelector(generalReducerValues);
    const sendAnalytics = useSendSimpleAnalytics();

    // const [modalStatus, setModalStatus] = useState({ show: false });
    const [userPresetsModalStatus, setUserPresetsModalStatus] = useState({ show: false });
    const [shareModalStatus, setShareModalStatus] = useState({ show: false });

    const { selectedPreset, selectedUserPresetIds, userPresets } = useSelector(PresetReducerValues);

    // const [deletePreset, setDeletePreset] = useState(false);
    const [deleteUserPreset, setDeleteUserPreset] = useState(false);

    // const options = usePresetData();
    const userPresetsOptions = useUserPresetData();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    // const handleSelect = (value: TSelectedOptions) => {
    //     if (value[0].id === createNewOptionId) return setModalStatus({ show: true });
    //     if (value[0].id === deleteOptionId) {
    //         setModalStatus({ show: true });
    //         setDeletePreset(true);
    //         return;
    //     }
    //     dispatch(storeSelectedPreset(value[0]));
    // };

    const onSelect = (value: TSelectedOptions) => {
        if (value[0]?.id === createNewOptionId) return setUserPresetsModalStatus({ show: true });
        if (value[0]?.id === deleteOptionId) {
            setUserPresetsModalStatus({ show: true });
            setDeleteUserPreset(true);
            return;
        }
        sendAnalytics('preset_selection');
        dispatch(storeSelectedUserPresetIds({ moduleId: currentModuleID, presetId: Number(value[0]?.id) }));
    };

    // const storeSelectedPresetTrans = (value: ISelectedOption | null) => {
    //     dispatch(storeSelectedPreset(value));
    // };

    // const handleModalClose = () => {
    //     setModalStatus({ show: false });
    //     setDeletePreset(false);
    // };

    const onModalClose = () => {
        setUserPresetsModalStatus({ show: false });
        setShareModalStatus({ show: false });
        setDeleteUserPreset(false);
    };

    const outsideSelected = useMemo(() => {
        if (!selectedUserPresetIds[currentModuleID]) return [];
        const text = userPresets.find((pr) => pr.id === selectedUserPresetIds[currentModuleID])?.name || '';
        return [{ id: selectedUserPresetIds[currentModuleID], text }];
    }, [currentModuleID, selectedUserPresetIds, userPresets]);

    const openSharedModal = () => {
        setShareModalStatus({ show: true });
    };

    return (
        <>
            <Modals
                title={
                    deleteUserPreset ? t(`${t('Delete preset')} ${outsideSelected[0]?.text}?`) : t('Create new preset')
                }
                closeModal={onModalClose}
                modalStatus={userPresetsModalStatus}
                topColor={deleteUserPreset ? 'var(--color-accent-dark)' : undefined}
            >
                <UserPresetModalBody
                    reportingObjectsById={reportingObjectsById}
                    currentOptionsKeys={[...currentOptionsKeys, 'pl', 'pls']}
                    sendYaAnalytics={sendYaAnalytics}
                    currentOptions={currentOptions}
                    closeModal={onModalClose}
                    deletePreset={deleteUserPreset}
                    allMetrics={allMetrics}
                />
            </Modals>
            <Modals
                title={t('Share current preset')}
                closeModal={onModalClose}
                modalStatus={shareModalStatus}
                // topColor="fff"
            >
                <MakeSharedModalBody closeModal={onModalClose} />
            </Modals>
            <Wrapper>
                <Select
                    testId={`${currentModuleID}_preset`}
                    outsideText={outsideSelected?.length ? outsideSelected[0].text : t('No preset')}
                    outsideSelected={outsideSelected}
                    handleSelect={onSelect}
                    labelText={t('Presets')}
                    showFilter={false}
                    options={userPresetsOptions}
                    dropdownWidth="sm"
                    dropdownAlign="right"
                    mode="single"
                />
                <ShareWrapper disabled={!selectedUserPresetIds[currentModuleID]} onClick={openSharedModal}>
                    <Tooltip content={t('Share preset')}>
                        <div>
                            <Share24Regular />
                        </div>
                    </Tooltip>
                </ShareWrapper>
            </Wrapper>
        </>
    );
};

export default Preset;
