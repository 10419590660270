import { isNumber } from 'lodash';
import { IReportingObject } from '../../../../../../General.interfaces';
import { IEventType } from '../../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { ITableCellData } from '../interfaces';

/**
 * Функция для получения значения ячейки в виде строки
 * @param cell данные из самой ячейки
 * @param reportingObjectsById объект со всеми отчетными объектами, где ключ - то id отчетного объекта
 * @param eventTypesById объект со всеми типами событий, где ключ - это id события
 */
const getCellStringValue = (
    cell: ITableCellData,
    reportingObjectsById: { [id: string]: IReportingObject } | undefined,
    eventTypesById: { [id: string]: IEventType } | undefined,
) => {
    switch (cell.key) {
        case 'data_objects_ids':
            if (Array.isArray(cell.value) && reportingObjectsById) {
                return cell.value.reduce((acc, value) => {
                    return (acc += `${reportingObjectsById[value]?.name || ''} `);
                }, '');
            }
            return cell.value;
        case 'event_type':
            if (isNumber(cell.value) && eventTypesById) {
                return eventTypesById[cell.value]?.name || '';
            }
            return cell.value;

        default:
            return cell.value;
    }
};

export default getCellStringValue;
