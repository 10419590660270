import React, { memo, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isMobileInboundWidth } from 'src/theme';

import DynamicsPMOWidget from '../../../../../components/CommonWidgets/DynamicsPMO/DynamicsPMO';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../reducer';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import usePeriods from '../../../../../hooks/common/usePeriods';
import { TChartType } from '../../../../../components/CommonWidgets/DynamicsPMO/interfaces';
import { queryClient } from '../../../../..';
import { DYNAMICS_PMO_QUERY_KEY } from '../../../../../components/CommonWidgets/DynamicsPMO/constants/constants';
import { CHART_DESKTOP_HEIGHT, CHART_MOBILE_HEIGHT, DS } from '../../../../../constants/constants';
import { Dynamics_Settings_Reducer_Values } from '../../../../../components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/reducer';
import { IDynamicsSettings } from '../../../../../components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/interfaces';
import DynamicsSettings from '../../../../../components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/DynamicsSettings';
import { TDynamicsSeries } from '../../../../../components/CommonWidgets/DynamicsPMO/components/Dynamics/interfaces';

import { DynamicsSettingsWrapper } from './styles';
import { Leasing_TenantOverview_Dynamics_Widget_Reducer_Values, storeSelectedChartType } from './reducer';
/**
 * Компонент для отображения графиков динамики по периодам, метрикам и объектам
 */
const DynamicsPMO: React.FC = memo(() => {
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        mainAreaSize: { width },
        currentModuleID,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { availableChartTypes, selectedChartType } = useSelector(
        Leasing_TenantOverview_Dynamics_Widget_Reducer_Values,
    );

    const { dynamicsSettingsById } = useSelector(Dynamics_Settings_Reducer_Values);

    const { mainPeriod, comparePeriods } = usePeriods(currentModuleID);
    const [series, setSeries] = useState<TDynamicsSeries[]>([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);

    const DYNAMICS_PMO_ID = `${currentModuleID}${DS}DynamicsPMO`;

    const currentSettings = dynamicsSettingsById[DYNAMICS_PMO_ID] as IDynamicsSettings | undefined;

    const isMobile = isMobileInboundWidth(width);

    const tabList = useMemo(() => {
        const tabListSelectHandler = (id: string) => {
            dispatch(storeSelectedChartType(id));
        };
        return (
            <TabList
                selectHandler={tabListSelectHandler}
                selectedValue={selectedChartType.id}
                options={availableChartTypes}
                widgetName="DynamicsByPMO"
                addComponent={
                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                        <DynamicsSettings id={DYNAMICS_PMO_ID} series={series} />
                    </div>
                }
            />
        );
    }, [DYNAMICS_PMO_ID, availableChartTypes, dispatch, selectedChartType.id, series]);

    const reloadWidget = () => {
        queryClient.invalidateQueries({ queryKey: [DYNAMICS_PMO_QUERY_KEY, currentModuleID] });
    };
    const giveSeriesOutside = (series: TDynamicsSeries[]) => {
        setSeries(series);
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <div>{t('Dynamics by periods, metrics')}</div>
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName={'Dynamics by periods, metrics'}
                        reloadHandlerNoDispatch={reloadWidget}
                    />
                </WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>

            {isMobile && tabList}

            {mainPeriod && localCurrentOptions?.selectedMetrics && localCurrentOptions?.selectedReportingObjectsIds && (
                <DynamicsPMOWidget
                    reportingObjects={localCurrentOptions.selectedReportingObjectsIds
                        .filter((id) => Boolean(reportingObjectsById[id]))
                        .map((id) => reportingObjectsById[id])}
                    comparePeriods={comparePeriods.map((item) => item.period)}
                    chartType={selectedChartType.id as TChartType}
                    metrics={localCurrentOptions.selectedMetrics}
                    viewSettings={{
                        showObjectSelect: false,
                        seriesIdsForAverage: currentSettings?.averageSeries.selectedSeriesIds,
                        seriesIdsForTrend: currentSettings?.trendSeries.selectedSeriesIds,
                        showWeekends: currentSettings?.weekends.isActive,
                        showWeather: currentSettings?.weather.isActive,
                        showEvents: currentSettings?.events.isActive,
                        chartWrapperStyles: {
                            marginTop: 'var(--space-md)',
                            height: isMobile ? `${CHART_MOBILE_HEIGHT}px` : `${CHART_DESKTOP_HEIGHT}px`,
                        },
                    }}
                    mainPeriod={mainPeriod.period}
                    moduleId={currentModuleID}
                    widgetId={'DynamicsPMO'}
                    giveSeriesOutside={giveSeriesOutside}
                />
            )}
        </WidgetWrapper>
    );
});

export default DynamicsPMO;
