import { theme } from 'src/theme';
import styled from 'styled-components/macro';

export const FavoriteButton = styled.button<{ isFavoriteSection: boolean | undefined }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
    padding: ${(p) => (p.theme.isRtlLanguage ? '0 0 0 4px' : '0 4px 0 0')};
    visibility: ${(props) => (props.isFavoriteSection ? 'hidden' : 'hidden')};
    transition: visibility 0.2s ease-in-out;

    svg {
        transition: color 0.2s ease-in-out;
        width: 20px;
        height: 20px;
        color: var(--color-secondary-darker);
        :hover {
            color: whitesmoke;
        }
    }
`;

export const ElementWrapper = styled.li<{ disabled: boolean; selected: boolean }>`
    margin: 0;
    :hover ${FavoriteButton} {
        visibility: visible;
    }
    display: flex;
    padding: 0 var(--space-xs);
    width: 100%;

    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    opacity: ${(p) => (p.disabled ? 0.3 : 1)};
    :last-child {
        padding-bottom: var(--space-xs);
    }

    .NameWrapper {
        font-weight: ${(p) => (p.selected ? 500 : 'inherit')};
        :before {
            border-bottom: ${(p) => (p.selected ? '2px solid var(--color-primary)' : 'none')};
            content: '';
            height: 100%;
            z-index: 10;
            position: absolute;
            right: 0;
            bottom: -5px;
            width: 100%;
        }
    }

    :hover {
        .NameWrapper {
            :before {
                border-bottom: 2px solid var(--color-primary);
                content: '';
                height: 100%;
                z-index: 10;
                position: absolute;
                right: 0;
                bottom: -5px;
                width: 100%;
            }
        }
    }
`;

export const NameWrapper = styled.div`
    position: relative;
    font-size: 16px;
    flex-shrink: 1;
    -webkit-user-select: none;
    user-select: none;
    white-space: normal;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    padding: 0;

    sup {
        top: -5px;
        right: ${(p) => (p.theme.isRtlLanguage ? 'auto' : '-8px')};
        left: ${(p) => (!p.theme.isRtlLanguage ? 'auto' : '-8px')};
        font-size: 10px;
        color: var(--color-accent);
        position: absolute;
    }
`;
