import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    generalReducerValues,
    storeAllMetrics,
    storeRawMetrics,
    storeAppCoreData,
    storeLocations,
    storeInitialDataReceived,
    storeUser,
    storeLocalMetricsList,
    storeModulesConfig,
    storeStandardCategories,
    addNewGlobalSpinnerItem,
    deleteGlobalSpinnerItemById,
    updateGlobalSpinnerItemById,
    storeBackgroundReportTypes,
} from '../../../General.reducer';
import { getLocales } from '../getLocales';
import { getCurrent } from '../getCurrent';
import { getAny } from '../getAny';
import { IBgReportType, IMetric } from '../../../General.interfaces';
import { ILocation } from '../../../components/SideBar/configurations';
import { getRequest, postRequest } from '../appBackendAPI';
import { storeAccParams, userSettingsReducerValues } from '../../../components/UserSettings/reducer';
import { storeSavedMetricData } from '../../../Chapters/Configuration/Metrics/reducer';
import requestLocations from '../requestLocations';
import { useTranslation } from 'react-i18next';
import generateId from '../../generateId';
import { IPreset } from '../../../components/Preset/interfaces';
import { PresetReducerValues, storeUserPresets } from '../../../components/Preset/reducer';
import { IDashboard, IProjectDashboard } from '../../../components/DashboardsCommon/interfaces';
import {
    patchUserDashboards,
    postUserDashboards,
    storeDefaultDashboards,
    storeProjectDashboards,
    storeUserDashboards,
} from '../../../Dashboards.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { DASHBOARD_EDITOR_PERMISSION, DS } from '../../../constants/constants';

const textMap = {
    '0': 'Loading locations...',
    '1': 'Loading metrics...',
    '2': 'Loading standard categories...',
};
const errorTextMap = {
    '0': 'Loading locations error',
    '1': 'Loading metrics error',
    '2': 'Loading standard categories error',
};

const useInitialRequests = () => {
    const { token, user, urlsByServices, locations, allMetrics, modulesConfig, selectedLocationId, currentModuleID } =
        useSelector(generalReducerValues);

    const { settings, accParamsID } = useSelector(userSettingsReducerValues);
    const dispatch = useAppDispatch();

    /**
     * Получение локалей
     */
    useEffect(() => {
        const id = generateId();
        dispatch(addNewGlobalSpinnerItem({ id, text: 'Loading locales...' }));
        getLocales()
            .then((res) => {
                dispatch(storeAppCoreData(res));
                dispatch(deleteGlobalSpinnerItemById(id));
            })
            .catch(() => {
                dispatch(updateGlobalSpinnerItemById({ id, errorText: 'Loading locales error' }));
            });
    }, [dispatch]);

    /**
     * Получение данных пользователя
     */
    useEffect(() => {
        const currentUrl = urlsByServices?.['core/structure-service'].CURRENT_URL;

        if (token && currentUrl) {
            const id = generateId();
            // console.log('process.env: ', process.env);
            dispatch(addNewGlobalSpinnerItem({ id, text: 'Loading user data...' }));
            getCurrent(token, currentUrl).then((res) => {
                if (res.status === 200) {
                    dispatch(deleteGlobalSpinnerItemById(id));
                    dispatch(storeUser(res.data));
                } else if (res.response.status === 404) {
                    localStorage.removeItem('xtoken');
                    window.location.reload();
                } else {
                    dispatch(updateGlobalSpinnerItemById({ id, errorText: 'SSO ERROR!' }));
                    // alert('SSO ERROR!');
                }
            });
        }
    }, [token, urlsByServices]);

    /**
     * Запрос параметров аккаунта
     */
    useEffect(() => {
        if (!token || !urlsByServices || !user) return;

        const accParamsUrl = urlsByServices?.['app/app-backend'].ACCOUNT_PARAMETERS_URL;
        if (token && user?.id && accParamsUrl) {
            const url = `${accParamsUrl}?user_id=${user?.id}`;
            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                if (response?.['data']?.length) {
                    dispatch(storeAccParams(response['data'][0]));
                } else if (Array.isArray(response?.['data']) && !response?.['data']?.length) {
                    postRequest(url, token, { user_id: user?.id, params: { global: {} } }).then(
                        (_: AxiosError | AxiosResponse) => {
                            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                                dispatch(storeAccParams(response['data'][0]));
                            });
                        },
                    );
                }
            });
        }
    }, [token, urlsByServices, user?.id]);

    /**
     * Получение списка локаций
     * Получение списка метрик
     * Получение списка стандартных категорий
     */
    useEffect(() => {
        if (!token || !urlsByServices || !user || !accParamsID) return;
        const locationsUrl = urlsByServices?.['core/structure-service']?.LOCATIONS_URL;
        const metricsListUrl = urlsByServices?.['core/structure-service']?.METRICS_LIST_URL;
        const standardCategoriesListUrl = urlsByServices?.['core/structure-service']?.STANDARD_CATEGORIES_LIST_URL;
        const defaultDashboardsUrl = urlsByServices?.['app/app-backend']?.DEFAULT_DASHBOARDS_URL;
        const projectDashboardsUrl = `${urlsByServices?.['app/app-backend']?.PROJECT_DASHBOARDS_URL}?project_id=${
            user?.project_id ?? 94
        }`;
        const userDashboardsUrl = urlsByServices?.['app/app-backend']?.USER_DASHBOARDS_URL;
        const backgroundReportTypesUrl = urlsByServices?.['app/app-backend']?.BACKGROUND_REPORT_TYPES_URL;
        // const modulesConfigUrl = '/Users/victorganelin/Documents/Projects/Focus/focus-app-front/src/constants/modulesConfig.ts';
        if (
            token &&
            locationsUrl &&
            metricsListUrl &&
            standardCategoriesListUrl &&
            defaultDashboardsUrl &&
            projectDashboardsUrl &&
            backgroundReportTypesUrl &&
            userDashboardsUrl
        ) {
            const promises = [
                requestLocations(token, locationsUrl),
                getAny(metricsListUrl, token),
                getAny(standardCategoriesListUrl, token),
                getAny(backgroundReportTypesUrl, token),
                // getAny(modulesConfigUrl),
            ];

            // Дашборды запрашиваем
            // if (user.permissions.includes('app/client | modules/dashboard-designer/object-panels | feature/view')) {
            // }
            promises.push(
                getAny(defaultDashboardsUrl, token),
                getAny(userDashboardsUrl, token),
                getAny(projectDashboardsUrl, token),
            );

            const locationsId = generateId();
            const metricsId = generateId();
            const standardCategoriesId = generateId();
            const modulesConfigId = generateId();

            const idsMap = {
                '0': locationsId,
                '1': metricsId,
                '2': standardCategoriesId,
                // '3': modulesConfigId,
            };

            promises.forEach((_, index) => {
                idsMap[index] &&
                    textMap[index] &&
                    dispatch(addNewGlobalSpinnerItem({ id: idsMap[index], text: textMap[index] }));
            });

            axios.all(promises).then((res: (AxiosResponse | AxiosError)[]) => {
                res.forEach((response, index) => {
                    if (response.status !== 200) {
                        dispatch(updateGlobalSpinnerItemById({ id: idsMap[index], errorText: errorTextMap[index] }));
                        console.log('useInitialRequests ERROR', response);
                    } else {
                        dispatch(deleteGlobalSpinnerItemById(idsMap[index]));
                    }
                });

                res[0].status === 200 && dispatch(storeLocations(res[0]?.['data'] as ILocation[]));

                const metricsListResponse: IMetric[] = res[1]?.['data'];
                if (user?.permissions && metricsListResponse.length) {
                    let metrics = metricsListResponse.filter((m) => {
                        return m.available_for_permissions.some((permission) => user.permissions.includes(permission));
                    });

                    // Получение данных о локальных метриках из Локалстореджа
                    let localMetricsList: string | string[] | null = localStorage.getItem('localMetricsList');
                    if (localMetricsList) {
                        localMetricsList = JSON.parse(localMetricsList) as string[];
                        dispatch(storeLocalMetricsList(localMetricsList));
                    }
                    metrics = metrics.map((item) => {
                        let isLocal = false;
                        if (localMetricsList?.includes(item.id)) {
                            isLocal = true;
                        }
                        return { ...item, isLocal };
                    });
                    // ------------------------------------------------------

                    dispatch(storeAllMetrics(metrics));
                    dispatch(storeRawMetrics(metrics));
                }

                if (res[2].status === 200) {
                    dispatch(storeStandardCategories(res[2]?.['data']));
                }

                // Анализ ответа по background report types

                if (res[3].status === 200) {
                    const backgroundReportTypes: undefined | IBgReportType[] = res[3]?.['data'];
                    if (backgroundReportTypes?.length) {
                        dispatch(storeBackgroundReportTypes(backgroundReportTypes));
                    }
                }

                //Анализ ответов по дашбордам

                if (res[4]?.status === 200 && res[5]?.status === 200 && res[6]?.status === 200) {
                    const defaultDashboards: Array<IDashboard> = res[4]?.['data'];
                    const userDashboards: Array<IDashboard> = res[5]?.['data'];
                    const projectsDashboards: Array<IProjectDashboard> = res[6]?.['data'];

                    const allowedDefaultDashboards = defaultDashboards.filter((item) => {
                        if (
                            (item.dashboard_key.includes('fsf') &&
                                user.permissions.includes('core/data-source | fsf | api/read')) ||
                            (item.dashboard_key.includes('fpc') &&
                                user.permissions.includes('core/data-source | fpc | api/read')) ||
                            item.dashboard_key.includes('project')
                            // &&
                            //     user.permissions.includes(DASHBOARD_EDITOR_PERMISSION)
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    dispatch(storeDefaultDashboards(allowedDefaultDashboards));
                    dispatch(storeProjectDashboards(projectsDashboards));

                    if (allowedDefaultDashboards.length) {
                        const userDashboardsToPost: IDashboard[] = [];
                        const userDashboardsToPatch: IDashboard[] = [];

                        allowedDefaultDashboards.forEach((item) => {
                            const dashboard_key = item.dashboard_key;
                            if (!userDashboards.find((ds) => ds.dashboard_key === dashboard_key)) {
                                userDashboardsToPost.push(item);
                            }
                        });

                        projectsDashboards.forEach((item) => {
                            const dashboard_key = item.dashboard_key;
                            if (!userDashboards.find((ds) => ds.dashboard_key === dashboard_key)) {
                                const cloned: { project_id?: number } = structuredClone(item);
                                delete cloned.project_id;
                                userDashboardsToPost.push(item as IDashboard);
                            }
                        });

                        // Работа с версиями ProjectDashboards

                        projectsDashboards.forEach((item) => {
                            const dashboard_key = item.dashboard_key;
                            let id = item.id;
                            if (
                                userDashboards.find((ds) => {
                                    id = ds.id;
                                    return ds.dashboard_key === dashboard_key && ds.version !== item.version;
                                })
                            ) {
                                const cloned: { project_id?: number } = structuredClone(item);
                                delete cloned.project_id;
                                userDashboardsToPatch.push({ ...item, id } as IDashboard);
                            }
                        });

                        userDashboardsToPost.length
                            ? dispatch(postUserDashboards({ dashboards: userDashboardsToPost }))
                            : dispatch(storeUserDashboards(userDashboards));

                        userDashboardsToPatch.length &&
                            dispatch(patchUserDashboards({ dashboards: userDashboardsToPatch, makeGet: true }));
                    }
                }

                dispatch(storeModulesConfig(modulesConfig));
            });
        }
    }, [token, urlsByServices, user, accParamsID]);

    /**
     * Загрузка в стор локально сохоаненных данных по метрикам
     */
    useEffect(() => {
        const storageMetricsData = localStorage.getItem('localMetricsData');
        if (storageMetricsData && selectedLocationId && JSON.parse(storageMetricsData)[selectedLocationId]) {
            const data = JSON.parse(storageMetricsData)[selectedLocationId];
            dispatch(storeSavedMetricData(data));
        }
    }, [selectedLocationId]);

    /**
     * Запрос пресетов
     */
    useEffect(() => {
        if (!token || !urlsByServices || !user?.id) return;

        const presetsURL = urlsByServices?.['app/app-backend'].USER_PRESETS_URL;
        if (token && user?.id && presetsURL) {
            const url = `${presetsURL}?user_id=${user?.id}`;

            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                if (response?.['data']?.length) {
                    dispatch(storeUserPresets(response['data']));
                }
            });
        }
    }, [token, urlsByServices, user?.id]);

    /** Изменение флага о получении параметров аккаунта */
    useEffect(() => {
        const timer = setTimeout(() => {
            if (accParamsID && locations.length && allMetrics.length && user) {
                dispatch(storeInitialDataReceived());
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [accParamsID, allMetrics.length, dispatch, locations.length, user]);
};

export default useInitialRequests;
