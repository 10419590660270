import { useSelector } from 'react-redux';
import Preset from '../../../../../components/Preset/Preset';
import MainPeriodSelect from '../../../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { PresetsWrapper } from '../../../../../components/Wrappers/PresetsWrapper/styles';
import { SelectsWrapper } from '../../../../../components/Wrappers/SelectsWrapper/styles';
import { DS } from '../../../../../constants/constants';

import { generalReducerValues } from '../../../../../General.reducer';
import { Reports_Bartel_Widget_Reducer_Values } from './reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import ComparePeriodSelect from '../../../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';

// const DEFAULT_METRICS = [
//     'fpc_sum_pass_count_in_wh',
//     'fpc_avg_daily_pass_count_in_wh',
//     'fpc_avg_dwell_time',
//     'fwe_temp',
//     'fwe_prec_amount',
// ];

const BarthelOptions = () => {
    const {
        currentModuleID,
        user,
        allMetrics,
        locations,
        selectedLocationId,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { keyWord } = useSelector(Reports_Bartel_Widget_Reducer_Values);
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['metrics', 'translation']);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const metricOptions = useMemo(() => {
        return allMetrics
            .filter(
                (item) =>
                    item.id === 'fpc_sum_pass_count_out_wh' ||
                    item.id === 'fpc_sum_pass_count_in_wh / sum_area' ||
                    item.id === 'fpc_sum_pass_count_out_wh / sum_area',
            )
            .map((item) => ({ ...item, text: t(item.text), section: t(item.section) }));
    }, [allMetrics, t]);

    const locationStartYear = locations.find((item) => item.id === selectedLocationId)?.date_from;

    // const defaultSelected = useMemo(() => {
    //     return metricOptions.filter((item) => DEFAULT_METRICS.includes(item.id));
    // }, [metricOptions]);

    const yearOptions = () => {
        const result = [];
        const currentYear = DateTime.now().year;
        for (let year = DateTime.fromISO(locationStartYear || '2020').year; year <= currentYear; year++) {
            result.push({ id: year, text: String(year) });
        }
        return result.reverse();
    };

    const tableOptions = [
        { id: 'mall', text: 'Whole mall' },
        { id: 'passways', text: 'Entrance groups' },
    ];

    if (reportingObjectsByType?.['floor']?.length) {
        const floors = reportingObjectsByType['floor']
            .map((item) => {
                return { id: `floor_${item.object_name}`, text: `${t('Floor')} ${item.object_name}` };
            })
            .reverse();

        tableOptions.push(...floors);
    }

    const colorsOptions = [{ id: 'applyColors', text: 'Apply colors' }];

    return (
        <>
            <SelectsWrapper>
                <MainPeriodSelect
                    name={`mainPeriod${DS}${keyWord}`}
                    validOptions={['today', 'yesterday', 'selectCustom']}
                    defaultPeriod="today"
                    oneDayOnly
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <ComparePeriodSelect
                    name={`comparePeriods${DS}${keyWord}`}
                    defaultPeriods={['previous', 'alignedYoY']}
                    oneDayOnly
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <UniversalSelect
                    // defaultSelected={defaultSelected}
                    name={`metrics${DS}${keyWord}`}
                    labelText={t('Additional metrics')}
                    options={metricOptions}
                    dropdownWidth="auto"
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <UniversalSelect
                    defaultSelected={yearOptions()}
                    name={`years${DS}${keyWord}`}
                    labelText={t('Years')}
                    options={yearOptions()}
                    dropdownWidth="auto"
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />

                <UniversalSelect
                    defaultSelected={tableOptions}
                    minSelected={1}
                    name={`tableSettings${DS}${keyWord}`}
                    labelText={t('Table settings')}
                    options={tableOptions}
                    dropdownWidth="sm"
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <UniversalSelect
                    defaultSelected={[colorsOptions[0]]}
                    name={`colorSettings${DS}${keyWord}`}
                    labelText={t('Colors settings')}
                    options={colorsOptions}
                    dropdownWidth="sm"
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
            </SelectsWrapper>
            <PresetsWrapper>
                <Preset
                    currentOptionsKeys={[
                        `mainPeriod${DS}${keyWord}`,
                        `comparePeriods${DS}${keyWord}`,
                        `metrics${DS}${keyWord}`,
                        `years${DS}${keyWord}`,
                        `tableSettings${DS}${keyWord}`,
                        `colorSettings${DS}${keyWord}`,
                    ]}
                />
            </PresetsWrapper>
        </>
    );
};

export default BarthelOptions;
