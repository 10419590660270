import { useSelector } from 'react-redux';
import { usePrevious } from '../../../../hooks/usePrevious';
import { Leasing_CrossVisits_Module_Reducer_Values } from '../reducer';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE } from '../constants/constants';
import { useEffect } from 'react';
import { TSelectedOptions } from '../../../../components/Selects/Select/interfaces';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';

/**
 * Кастомный хук для отправки Аналитики
 */
const useAnalytics = () => {
    const { moduleName } = useSelector(Leasing_CrossVisits_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const sendAnalytics = useSendSimpleAnalytics();
    const prevReportingObjectType = usePrevious(localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE]);

    /**
     * Отправка Я.Метрики при смене типа отчетного объекта
     */
    useEffect(() => {
        if (prevReportingObjectType) {
            const prevValue = prevReportingObjectType as undefined | TSelectedOptions;
            const currentValue = localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE] as
                | undefined
                | TSelectedOptions;

            if (prevValue?.[0]?.id !== currentValue?.[0]?.id) {
                sendAnalytics(`object_type_changed_to_${currentValue?.[0]?.id}`);
            }
        }
    }, [prevReportingObjectType, localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE]]);
};

export default useAnalytics;
