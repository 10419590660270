import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IModuleConfig } from 'src/General.interfaces';
import useTranslation from 'src/hooks/useTranslation/useTranslation';
import { useResizeObserver } from 'src/hooks';
import { REACT_APP_REF } from 'src/constants';

import { generalReducerValues, changeIsSidebarOpen, storeGeneralSearch } from '../../General.reducer';
import { cabinetPreferencesValues } from '../CabinetPreferences/reducer';
import Search from '../Search/Search';

import generateMenu from './core/generateMenu';
import { SidebarProps, TMenu } from './configurations';
import { SearchWrapper, SidebarForm } from './styles';
import SideBarMenu from './components/SideBarMenu';

import './styles.scss';

const SideBar: React.FC<SidebarProps> = (props) => {
    const { t } = useTranslation(['translationSidebar', 'translation']);
    const { user, modulesConfig, isSidebarOpen, currentModuleID, optionPanelHeight, generalSearch } =
        useSelector(generalReducerValues);
    const dispatch = useDispatch();

    const {
        sidebar: { favoriteModules },
    } = useSelector(cabinetPreferencesValues);

    const [menu, setMenu] = useState<TMenu>([]);

    const filteredConfig: IModuleConfig[] = useMemo(() => {
        const validate = (text: string, filter: string): boolean => {
            return (
                text.toLowerCase().includes(filter.toLowerCase()) ||
                t(text).toLowerCase().includes(filter.toLowerCase())
            );
        };
        return modulesConfig.filter((item) => {
            if (!generalSearch) return item;
            const sectionName = item.module_name.split(':')[0];
            const moduleName = item.module_name.split(':')[1];
            return (
                validate(sectionName, generalSearch) ||
                validate(moduleName, generalSearch) ||
                item.tags.some((tag) => {
                    return validate(tag, generalSearch);
                })
            );
        });
    }, [generalSearch, modulesConfig, t]);

    const strFavoritrModules = favoriteModules.join();

    useResizeObserver({
        ref: REACT_APP_REF,
        onResize: () => {
            if (isSidebarOpen) {
                dispatch(changeIsSidebarOpen(false));
            }
        },
    });

    useEffect(() => {
        if (user?.permissions) {
            setMenu(generateMenu({ config: filteredConfig, permissions: user.permissions, favoriteModules }));
        }
    }, [strFavoritrModules, filteredConfig, user?.permissions]);

    const filterOnChange = ({ value }: { value: string }) => {
        dispatch(storeGeneralSearch(value));
    };

    const showSearch = useMemo(() => {
        const hasOptionsPanel = !modulesConfig.find((item) => item.module_id === currentModuleID)?.hasOptionsPanel;
        return Boolean(hasOptionsPanel);
    }, [currentModuleID, modulesConfig]);

    const paddingTop = useMemo(() => {
        const hasOptionsPanel = modulesConfig.find((item) => item.module_id === currentModuleID)?.hasOptionsPanel;
        if (!hasOptionsPanel || isSidebarOpen) {
            return 0;
        } else {
            // src/App.styles.ts margin-top: 65px
            return optionPanelHeight + 20;
        }
    }, [currentModuleID, isSidebarOpen, modulesConfig, optionPanelHeight]);

    return (
        <SidebarForm sidebarOpen={isSidebarOpen} style={{ paddingTop }}>
            {(showSearch || isSidebarOpen) && (
                <SearchWrapper isSidebarOpen={isSidebarOpen}>
                    <Search
                        maxLength={220}
                        placeholder={t('Search')}
                        handleChange={filterOnChange}
                        fullWidth
                        outsideValue={generalSearch}
                    />
                </SearchWrapper>
            )}

            <SideBarMenu {...props} menu={menu} />
        </SidebarForm>
    );
};

export default SideBar;
