import { FC, useMemo } from 'react';
import { IProps, TWidgetType } from './interfaces';

import { Table, TableWrapper, Wrapper } from './styles/styles';
import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import useFetchData from './hooks/useFetchData';
import { useSelector } from 'react-redux';
import Row from './components/Row/Row';
import Selects from './components/Selects/Selects';
import { DS } from '../../../../constants/constants';
import PasswaysTrafficInfo from './components/PasswaysTrafficInfo/PasswaysTrafficInfo';
import CommonRatingWidget from '../../../CommonWidgets/Rating/Rating';
import usePeriods from '../../../../hooks/common/usePeriods';
import { Network_Section_Reducer_Values } from '../../../../Chapters/Network/reducer';
import { queryClient } from '../../../..';
import { RATING_QUERY_QEY } from '../../../CommonWidgets/Rating/constants/constants';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import OnBoardSelects from './components/OnBoardSelects/OnBoardSelects';
import useWidgetWrapperSettings from './hooks/useWidgetWrapperSettings';
import { IReportingObject } from '../../../../General.interfaces';
import { cloneDeep } from 'lodash';
import { useFetchGroupDataObjects } from '../../../../tools/API/hooks/groupDataObjects/useFetchGroupDataObjects';
import useGDOSelectedGroup from '../../../../Chapters/Network/tools/useGDOSelectedGroup';

/**
 * Виджет типа "динамика"
 */
const Rating: FC<IProps> = ({ dashboardKey, moduleId, widgetConfig, updateWidgetTrans, reportingObjects }) => {
    const widgetType = widgetConfig?.type?.split(DS)?.[1] as TWidgetType;
    const PASSWAYS_TRAFFIC_METRIC = 'fpc_sum_pass_count_in_wh';

    const { data, refetch } = useFetchData({
        passwaysTrafficMetric: PASSWAYS_TRAFFIC_METRIC,
        widgetConfig,
        dashboardKey,
        widgetType,
        moduleId,
    });
    const widgetWrapperSettings = useWidgetWrapperSettings({
        passwaysTrafficMetric: PASSWAYS_TRAFFIC_METRIC,
        widgetConfig,
        dashboardKey,
        widgetType,
        moduleId,
    });
    const networkSectionReducerValues = useSelector(Network_Section_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleId);

    const groupDataObjects = useFetchGroupDataObjects();
    const selectedGroup = useGDOSelectedGroup(groupDataObjects, [
        widgetConfig?.options?.networkGroupObjects?.[0]?.id,
    ] as (string | number | undefined)[]);

    const { mainPeriod, comparePeriods } = usePeriods(moduleId);

    const content = useMemo(() => {

        switch (widgetType) {
            case 'passwaysTraffic':
            case 'topByMetrics':
                return (
                    <>
                        {widgetType === 'passwaysTraffic' && <PasswaysTrafficInfo widgetConfig={widgetConfig} />}
                        <TableWrapper>
                            <Table>
                                <tbody>
                                    {data?.map((row) => (
                                        <Row key={row.name} widgetType={widgetType} data={row} />
                                    ))}
                                </tbody>
                            </Table>
                        </TableWrapper>
                    </>
                );

            case 'topByMetricsNetworkObjects':
            case 'topByMetricsLocations':
                const metric =
                    widgetConfig?.visual.useMetricFrom?.[0]?.id === 'fromPanel'
                        ? localCurrentOptions?.['selectedSingleMetric']?.[0]
                        : widgetConfig?.options?.metrics?.[0] || '';

                let reportingObjectsData: IReportingObject[] = [];

                switch (widgetType) {
                    case 'topByMetricsLocations':
                        reportingObjectsData = cloneDeep(
                            networkSectionReducerValues.cfg.reportingObjectsByType?.['location'] || [],
                        );
                        break;
                    case 'topByMetricsNetworkObjects':
                        if (widgetConfig?.visual.useObjectsFrom?.[0]?.id === 'fromPanel') {
                            reportingObjectsData = cloneDeep(reportingObjects || []);
                        } else {
                            reportingObjectsData = cloneDeep(
                                selectedGroup.reduce((acc: IReportingObject[], value) => {
                                    acc.push(...value.items);
                                    return acc;
                                }, []),
                            );
                        }
                        break;

                    default:
                        break;
                }
                if (mainPeriod && comparePeriods.length && metric && reportingObjectsData.length) {
                    return (
                        <CommonRatingWidget
                            comparePeriods={comparePeriods.map((period) => period.period)}
                            widgetId={widgetConfig?.id || widgetType}
                            reportingObjects={reportingObjectsData}
                            mainPeriod={mainPeriod.period}
                            moduleId={moduleId}
                            isNetwork={true}
                            metric={metric}
                            viewSettings={{
                                showAbsoluteValues: widgetConfig?.visual?.tableView?.[0]?.id === 'showAbsoluteValues',
                                showRelativeValues: widgetConfig?.visual?.tableView?.[0]?.id === 'showRelativeValues',
                                showAllValues: widgetConfig?.visual?.tableView?.[0]?.id === 'showAll',
                                showPercentOfTotal: widgetConfig?.visual?.showPercentOfTotal,
                            }}
                        />
                    );
                }
                return null;

            default:
                return null;
        }
    }, [
        widgetType,
        widgetConfig,
        data,
        localCurrentOptions,
        mainPeriod,
        comparePeriods,
        moduleId,
        networkSectionReducerValues.cfg.reportingObjectsByType,
        reportingObjects,
        selectedGroup,
    ]);

    const reloadWidget = () => {
        switch (widgetType) {
            case 'passwaysTraffic':
            case 'topByMetrics':
                refetch();
                break;

            case 'topByMetricsNetworkObjects':
            case 'topByMetricsLocations':
                queryClient.invalidateQueries({
                    queryKey: [RATING_QUERY_QEY, moduleId, widgetConfig?.id || 'topByMetricsLocations'],
                });
                break;

            default:
                break;
        }
    };

    const updateWidget = (key: string, value: { [x: string]: unknown }) => {
        if (widgetConfig && widgetConfig?.id) {
            updateWidgetTrans({
                moduleId,
                dashboardKey,
                data: {
                    widgetId: widgetConfig?.id,
                    key: key,
                    value: { ...(widgetConfig[key] || {}), ...value },
                },
            });
        }
    };

    return (
        <WidgetWrapper
            {...widgetWrapperSettings}
            widgetSelects={
                <Selects
                    dashboardKey={dashboardKey}
                    widgetConfig={widgetConfig}
                    updateWidget={updateWidget}
                    widgetType={widgetType}
                    moduleId={moduleId}
                />
            }
            widgetOnBoardSelects={
                <OnBoardSelects
                    dashboardKey={dashboardKey}
                    widgetConfig={widgetConfig}
                    updateWidget={updateWidget}
                    widgetType={widgetType}
                    moduleId={moduleId}
                />
            }
            updateWidgetTrans={updateWidgetTrans}
            title={widgetConfig?.title || ''}
            widgetId={widgetConfig?.id}
            dashboardKey={dashboardKey}
            reloadWidget={reloadWidget}
            moduleId={moduleId}
        >
            <Wrapper>{content}</Wrapper>
        </WidgetWrapper>
    );
};

export default Rating;
