import { IReportingObject } from '../../../../../../../../General.interfaces';
import { IIntersectionData } from '../../../interfaces';
import { cloneDeep } from 'lodash';

interface IArgs {
    intersectionsDataById: { [contraId: string]: IIntersectionData } | null;
    reportingObjects: IReportingObject[];
}

/**
 * Функция для сортировки отчтеных объектов
 * @param intersectionsDataById данные пересечений
 * @param reportingObjects все отчтеные объекты
 * @returns отсортированные отчетные объекты
 */
const sortReportingObjects = (args: IArgs): IReportingObject[] => {
    const { reportingObjects, intersectionsDataById } = args;
    if (intersectionsDataById && reportingObjects.length) {
        const sorted = cloneDeep(reportingObjects).sort((a, b) => {
            let aIntersection = intersectionsDataById[a.id]?.intersection;
            let bIntersection = intersectionsDataById[b.id]?.intersection;
            if (aIntersection === undefined || aIntersection === null) aIntersection = -2;
            if (bIntersection === undefined || bIntersection === null) bIntersection = -2;

            return aIntersection > bIntersection ? -1 : 1;
        });
        return sorted;
    } else {
        return cloneDeep(reportingObjects);
    }
};

export default sortReportingObjects;
