import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import FiltersSelect from '../../../components/Filters/FiltersSelect/FiltersSelect';
import { ISettings } from '../../../components/UserSettings/interfaces';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../components/Selects/MetricsSelect/MetricsSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import TenantsWidget from './widgets/TenantsWidget/TenantsWidget';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../General.reducer';
import { getReportingObjects } from '../../../tools/getReportingObjects';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';

/**
 * Комопонет для отображения модуля Leasing:Tenants
 */
const Tenants = () => {
    const {
        currentModuleID,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const { t } = useTranslation();

    useEffect(() => {
        if (localCurrentOptions && !localCurrentOptions.selectedReportingObjectsIds?.length) {
            dispatch(
                changeOptions({
                    newOptions: {
                        selectedReportingObjectsIds: (reportingObjectsByType?.['tenant'] || []).map(
                            (tenant) => tenant.id,
                        ),
                    },
                    currentModuleID,
                }),
            );
        }
    }, [currentModuleID, dispatch, localCurrentOptions, reportingObjectsByType]);

    const filtersSelectValidOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place', 'tenant'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [reportingObjectsByType, t]);

    const objectsSelectValidOptions = [t('Tenants')];

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ComparePeriodSelect
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ObjectsSelect
                            mode="multi"
                            label={'Tenants'}
                            canSelectEntireSection={true}
                            validOptions={objectsSelectValidOptions}
                            dataObjectFilters={['data_objects_tenant']}
                            minSelected={1}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <MetricsSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <FiltersSelect label={t('Filters')} validOptions={filtersSelectValidOptions} />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'mainPeriod',
                                'comparePeriods',
                                'selectedMetrics',
                                'filters' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                <TenantsWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Tenants;
