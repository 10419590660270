import { useEffect } from 'react';

export const useObserverError = () => {
    useEffect(() => {
        window.addEventListener('error', (e) => {
            console.log(e);

            if (
                e.message === 'ResizeObserver loop limit exceeded' ||
                e.message === 'ResizeObserver loop completed with undelivered notifications.'
            ) {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
};
