import styled from 'styled-components/macro';

export const HeaderContainer = styled.div`
    border-bottom: 1px solid var(--color-secondary);
    padding-bottom: 10px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    cursor: pointer;
`;
export const IconContainer = styled.div<{ ascStatus: boolean }>`
    transform: ${(p) => (p.ascStatus ? 'none' : 'rotate(180deg)')};
`;
