import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { StIcon, Wrapper } from './styles';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';

import Select from '../Select/Select';
import { ISelectedOption, TSelectedOptions } from '../Select/interfaces';

const UniversalNestedSelect: React.FC<IProps> = ({
    name,
    options,
    iconType = 'chevron-down',
    maxSelected,
    dropdownAlign = 'left',
    showFilter = true,
    canSelectEntireSection = false,
    showClearAll = false,
    minSelected = 1,
    labelText = ' ',
    dropdownWidth,
    localCurrentOptions,
    changeOptions,
    ...props
}) => {
    const { t } = useTranslation();

    const { currentModuleID, selectedLocationId } = useSelector(generalReducerValues);

    /**
     * Если выбранный элемент не содержится в options, то происходит перезапись options
     */
    useEffect(() => {
        if (localCurrentOptions?.[name] && Array.isArray(localCurrentOptions?.[name]) && options?.length) {
            const availableOptionIds = options.reduce((acc: (string | number | null)[], item) => {
                acc.push(...item.objects.map((obj) => obj.id));
                return acc;
            }, []);

            if (
                localCurrentOptions?.[name]?.some((item: ISelectedOption) => !availableOptionIds.includes(item?.id)) &&
                options?.[0]?.objects?.length
            ) {
                changeOptions({
                    newOptions: {
                        [name]: [{ ...options?.[0]?.objects[0], section: options?.[0]?.section }],
                    },
                });
            }
        }
    }, [JSON.stringify(localCurrentOptions?.[name]), JSON.stringify(options)]);

    /**
     * Если в селекте ничего не выбрано, то выбираем по дефолту первый элемент
     */
    useEffect(() => {
        if (
            localCurrentOptions &&
            !localCurrentOptions?.[name] &&
            options?.[0]?.objects?.length &&
            selectedLocationId &&
            localCurrentOptions?.currentModuleID === currentModuleID
        ) {
            changeOptions({
                newOptions: {
                    [name]: [{ ...options?.[0]?.objects[0], section: options?.[0]?.section }],
                },
            });
        }
    }, [
        currentModuleID,
        localCurrentOptions?.currentModuleID,
        name,
        JSON.stringify(options),
        selectedLocationId,
        localCurrentOptions,
    ]);

    const handleSelect = (args: TSelectedOptions) => {
        selectedLocationId &&
            changeOptions({
                newOptions: {
                    [name]: args.map((item) => {
                        const nonTranslatedSection = options?.find((option) =>
                            option.objects.map((obj) => obj.id).includes(item.id),
                        )?.section;

                        return { id: item?.id, section: nonTranslatedSection || item?.section, text: item?.text };
                    }),
                },
            });
    };

    const max = maxSelected ? ` (${t('max')}: ${maxSelected})` : '';

    return (
        <Wrapper>
            <Select
                testId={`${currentModuleID}_${name}`}
                options={options.map((item) => ({ ...item, section: t(item.section) }))}
                dropdownWidth="md"
                text={t('Selected')}
                dropdownAlign={dropdownAlign}
                disabled={props.disabled}
                showFilter={showFilter}
                showClearAll={showClearAll}
                canSelectEntireSection={canSelectEntireSection}
                mode="multi"
                labelText={`${t(labelText)}${max}`}
                iconType={iconType}
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                outsideSelected={
                    (localCurrentOptions?.[name] as TSelectedOptions)?.map((element) => ({
                        ...element,
                        section: element.section ? t(element.section) : undefined,
                        text: t(element.text || ''),
                    })) || []
                }
                additionalControl={<StIcon />}
                minSelected={minSelected}
            />
        </Wrapper>
    );
};

export default UniversalNestedSelect;
