import { useAppDispatch } from '../../../../hooks/useSettings';
import { useMutateRentPayments } from '../api/useMutateRentPayments';
import { IMetricCell, IRentPayment, TOperationType } from '../interfaces';
import { toggleValidationModalStatus } from '../reducer';
import { useValidate } from './useValidate';
import { isNumbersInCell } from './validationFoos';

const useSaveTable = (tableKey: string) => {
    const { postMutation } = useMutateRentPayments();
    const { validate } = useValidate({
        validateFoos: [isNumbersInCell],
        tableKey,
    });
    const dispatch = useAppDispatch();

    const save = ({
        dataToSave,
        onSaveSuccess,
    }: {
        dataToSave: IMetricCell[][];
        onSaveSuccess?: (data: IRentPayment[]) => void;
    }) => {
        const changedRows = dataToSave.filter((row) => row.some((cell) => cell.changeType !== undefined));

        const getDataToPost = (changedRows: IMetricCell[][]): IRentPayment[] => {
            return changedRows.reduce((acc, row) => {
                if (row.some((cell) => cell.changeType !== undefined && cell.metric)) {
                    const dataCell = row.find((cell) => cell.metric);

                    return [
                        ...acc,
                        {
                            metric: dataCell?.metric,
                            data_object_id: dataCell?.data_object,
                            date: dataCell?.date,
                            time_freq: 'MS',
                            value: dataCell?.value ? Number(dataCell?.value) : null,
                        },
                    ];
                } else return acc;
            }, []) as IRentPayment[];
        };

        const rentData = getDataToPost(changedRows);

        const validateResult = validate(changedRows);

        if (validateResult.isValid) {
            postMutation.mutateAsync([...rentData]).then((data) => {
                onSaveSuccess && onSaveSuccess(data);
            });
        } else {
            dispatch(toggleValidationModalStatus({ show: true, data: validateResult.data }));
        }
    };
    return { save };
};

export default useSaveTable;
