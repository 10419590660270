import { TFunction } from 'i18next';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import { isNull } from 'lodash';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';
import { theme } from 'src/theme';
import { TREND_SERIES_ID_PART } from '../../constants/constants';
import { DS } from '../../../../../constants/constants';

interface IArgs {
    series: Array<TDynamicsSeries>;
    seriesIdsForTrend: string[];
    t: TFunction;
}

const generateTrendSeries = (args: IArgs): Array<TDynamicsSeries> => {
    const { series, seriesIdsForTrend, t } = args;

    return series.reduce((acc: TDynamicsSeries[], value, seriesIndex) => {
        if (value.id && seriesIdsForTrend.includes(value.id)) {
            const filteredData = (value.data || []) as IExtendedChartPoint[];

            if (filteredData.length && filteredData.length > 1) {
                const avgX =
                    filteredData.reduce((acc: number, _, i: number) => {
                        return (acc += i + 1);
                    }, 0) / filteredData.length;

                const squareAvgX = avgX ** 2;

                const avgY =
                    filteredData.reduce((acc: number, item) => {
                        return (acc += Number(item.y));
                    }, 0) / filteredData.length;

                const b =
                    (filteredData.reduce((acc: number, _, i: number) => {
                        return (acc = acc + (i + 1) * (filteredData[i].y as number));
                    }, 0) -
                        filteredData.length * avgX * avgY) /
                    (filteredData.reduce((acc: number, _, i: number) => {
                        return (acc = acc + (i + 1) * (i + 1));
                    }, 0) -
                        filteredData.length * squareAvgX);

                const a = avgY - b * avgX;

                const result: TDynamicsSeries = {
                    name: `${t('Trend for')} ${value.name || ''}`,
                    type: 'line',
                    // additionalSeriesId: AdditionalSeriesIds.Trend,
                    dashStyle: 'LongDash',
                    color: theme.seriesColors[seriesIndex % theme.seriesColors.length],
                    isMain: false,
                    id: `${TREND_SERIES_ID_PART}${DS}${value.name || ''}${DS}${seriesIndex}`,
                    yAxis: value.yAxis,
                    data: [
                        {
                            x: filteredData[0].x,
                            showTooltipBody: false,
                            name: '',
                            y: a + b,
                        },
                        {
                            x: filteredData[filteredData.length - 1].x,
                            y: a + b * (filteredData.length - 1),
                            name: '',
                            showTooltipBody: false,
                        },
                    ] as IExtendedChartPoint[],
                };
                acc.push(result);
            }
        }
        return acc;
    }, []);
};

export default generateTrendSeries;
