import { createContext } from 'react';

import { TDataAdapterResponse } from './interfaces';

export interface IContext {
    reloadWidget: () => void;
    data: TDataAdapterResponse;
    isMobile: boolean;
}

export const SalesOfTenantsContext = createContext<null | IContext>(null);
