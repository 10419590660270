import { DateTime } from 'luxon';
import { IColumn } from '../interfaces';
import DecisionTypesSelect from '../components/DecisionsTable/components/DecisionTypesSelect/DecisionTypesSelect';
import DecisionControls from '../components/DecisionsTable/components/DecisionControls/DecisionControls';
import ReportingObjectsSelect from '../components/DecisionsTable/components/ReportingObjectsSelect/ReportingObjectsSelect';

export const DECISIONS_TABLE_COLUMNS: IColumn[] = [
    {
        columnId: 'name',
        headerText: 'Decision name',
        decisionDataKey: 'name',
    },
    {
        columnId: 'date_from',
        headerText: 'Date from',
        getCellValue: (cell) => {
            if (!cell.decision) {
                return cell.value || (DateTime.now().toISODate() ?? '');
            }
            return DateTime.fromISO(cell.decision.date_from).toISODate() ?? '';
        },
    },
    {
        columnId: 'time_from',
        headerText: 'Time from',
        getCellValue: (cell) => {
            if (!cell.decision) {
                return cell.value || DateTime.now().startOf('day').toFormat('HH:mm:ss');
            }
            return DateTime.fromISO(cell.decision.date_from).toFormat('HH:mm:ss');
        },
    },
    {
        columnId: 'date_to',
        headerText: 'Date to',
        getCellValue: (cell) => {
            if (!cell.decision) {
                return cell.value || (DateTime.now().toISODate() ?? '');
            }
            return DateTime.fromISO(cell.decision.date_to).toISODate() ?? '';
        },
    },
    {
        columnId: 'time_to',
        headerText: 'Time to',
        getCellValue: (cell) => {
            if (!cell.decision) {
                return cell.value || DateTime.now().endOf('day').toFormat('HH:mm:ss');
            }
            return DateTime.fromISO(cell.decision.date_to).toFormat('HH:mm:ss');
        },
    },
    {
        columnId: 'decision_type',
        headerText: 'Decision type',
        forceComponent: true,
        component: DecisionTypesSelect,
        getCellValue: (cell) => {
            if (!cell.decision) {
                return cell.value;
            }
            return String(cell.decision.decision_type);
        },
    },
    {
        columnId: 'data_objects_ids',
        headerText: 'Reporting objects',
        forceComponent: true,
        component: ReportingObjectsSelect,
        getCellValue: (cell) => {
            if (!cell.decision) {
                return cell.value;
            }
            return String(cell.decision.data_objects_ids.join(','));
        },
    },
    {
        columnId: 'comment',
        headerText: 'Comment',
        decisionDataKey: 'comment',
    },
    {
        columnId: 'decision_controls',
        headerText: 'Controls',
        forceComponent: true,
        component: DecisionControls,
    },
];
