import { ReactNode } from 'react';
import { TFunction } from 'i18next';

import { idToTitle } from 'src/tools';

import { SortOrder, TableFilter } from './interfaces';

export function prepareFiltersByColumns(filters: TableFilter[]) {
    return filters.reduce(
        (acc, filter) => ({
            ...acc,
            [filter.columnId]: [...(acc[filter.columnId] || []), filter],
        }),
        {} as Record<string, Array<TableFilter>>,
    );
}

function switchSort(sortOrder?: SortOrder): SortOrder | undefined {
    return sortOrder === 'asc' ? 'desc' : 'asc';
}

function changeSort(sortOrder?: SortOrder): SortOrder | undefined {
    if (!sortOrder) {
        return 'asc';
    }

    if (sortOrder === 'asc') {
        return 'desc';
    }

    return;
}

export const SORT_SWITCH_WAY = {
    true: changeSort,
    false: switchSort,
};

export const getLabel = (id: string, label: ReactNode, t: TFunction) => {
    if (!label) {
        return t(idToTitle(id));
    }
    if (typeof label === 'string') {
        return t(label);
    }
    return label;
};
