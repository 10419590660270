import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { PeopleCommunity24Filled, Person24Filled } from '@fluentui/react-icons';
import { DateTime } from 'luxon';
import _, { isNull } from 'lodash';

import WrapperContainer from 'src/components/Wrappers/WrapperContainer/WrapperContainer';
import ModuleWrapper from 'src/components/Wrappers/ModuleWrapper/ModuleWrapper';

import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import TabList from '../../../components/TabList/TabList';
import Modals from '../../../components/Modals/Modals';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import Stack from '../../../components/Stack/Stack';
import useObjectTranslation from '../../../hooks/useObjectTranslation';
import WidgetTitle from '../../../components/Wrappers/WidgetTitle/WidgetTitle';
import DashboardLayout from '../../../components/DashboardsCommon/DashboardLayout/DashboardLayout';
import WidgetsManager from '../../../components/DashboardsCommon/WidgetsManager/WidgetsManager';
import AddNewBody from '../../../components/DashboardsCommon/AddNewBody/AddNewBody';
import EditBody from '../../../components/DashboardsCommon/EditBody/EditBody';
import PanelsManager from '../../../components/DashboardsCommon/PanelsManager/PanelsManager';
import useRequiredSelects from '../../../components/DashboardsCommon/hooks/useRequiredSelects';
import useGetAvailableWidgets from '../../../components/DashboardsCommon/WidgetsManager/hooks/useGetAvailableWidgets';
import { IUpdateWidgetPayload, TLayouts } from '../../../components/DashboardsCommon/interfaces';
import { Dashboards_Reducer_Values, storeSelectedKey, updateLayout, updateWidget } from '../../../Dashboards.reducer';
import { IMenuPermissions } from '../../../components/DashboardsCommon/PanelsManager/interfaces';
import MetricsSelect from '../../../components/Selects/MetricsSelect/MetricsSelect';
import { DASHBOARD_EDITOR_PERMISSION } from '../../../constants/constants';
import { ITabListOptionsItem } from '../../../components/TabList/interfaces';
import Spinner from '../../../components/Spinner/Spinner';
import { Network_Section_Reducer_Values } from '../reducer';
import { NETWORK_GENERAL_QUERY_KEY } from '../../../tools/API/hooks/useNetworkQuery';

import { OptionsWrapper, WidgetsWrapper } from './styles/styles';
import { Network_Portfolio_Overview_Module_Reducer_Values } from './reducer';
import { widgetTypes } from './widgetsConfig';

import './styles/styles.scss';

/**
 * Компонент для отображения модуля "Dashboard designer:Object panels"
 */
const PortfolioOverview: React.FC = () => {
    const { moduleId } = useSelector(Network_Portfolio_Overview_Module_Reducer_Values);
    const {
        src: { reportingObjects },
    } = useSelector(Network_Section_Reducer_Values);
    const { userDashboards, defaultDashboards, selectedKey: selectedKeys } = useSelector(Dashboards_Reducer_Values);
    const [manageModalStatus, setManageModalStatus] = useState({ show: false });
    const [addNewModalStatus, setAddNewStatus] = useState({ show: false });
    const [editStatus, setEditStatus] = useState({ show: false });
    const {
        currentModuleID,
        user,
        domain,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const selectedKey = selectedKeys[currentModuleID] ?? null;

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const requiredSelects = useRequiredSelects(userDashboards, selectedKey);
    const availableWidgets = useGetAvailableWidgets(widgetTypes);
    const isNetworkDataLoading = useIsFetching({ queryKey: [NETWORK_GENERAL_QUERY_KEY] });

    const ref = useRef<HTMLDivElement | null>(null);

    const { getObjName } = useObjectTranslation();

    const { t } = useTranslation();

    const layouts = useMemo(() => {
        if (userDashboards.length && selectedKey) {
            return (
                userDashboards.find(
                    (dashboard) => dashboard.module_id === currentModuleID && dashboard.dashboard_key === selectedKey,
                )?.layouts || null
            );
        }
        return null;
    }, [userDashboards, selectedKey, currentModuleID]);

    const tabListItems: ITabListOptionsItem[] = useMemo(() => {
        const result = userDashboards
            .filter((item) => item.module_id === moduleId)
            .sort(
                (a, b) =>
                    DateTime.fromISO(b.created_at || '').toMillis() - DateTime.fromISO(a.created_at || '').toMillis(),
            )
            .map((item) => {
                const text =
                    item.dashboard_key.includes('default') || item.dashboard_key.includes('project')
                        ? t(item.dashboard_name)
                        : item.dashboard_name;
                const tooltip =
                    item.dashboard_key.includes('default') || item.dashboard_key.includes('project')
                        ? t('Default panel')
                        : t('Custom panel');
                const icon =
                    item.dashboard_key.includes('default') || item.dashboard_key.includes('project') ? (
                        <PeopleCommunity24Filled />
                    ) : (
                        <Person24Filled />
                    );
                return { id: item.dashboard_key, text, icon, tooltip };
            });

        const isProjectExists = result.some((item) => item.id.includes(`project`) && !item.id.includes('default'));

        const filtered = result.filter((item) => {
            if (!isProjectExists) {
                return true;
            } else {
                return !item.id.includes('default');
            }
        });

        return _.sortBy(filtered, 'text');
    }, [moduleId, t, userDashboards]);

    useEffect(() => {
        if (tabListItems.length && !selectedKey) {
            dispatch(storeSelectedKey({ [currentModuleID]: tabListItems[tabListItems.length - 1].id }));
        }
    }, [currentModuleID, dispatch, selectedKey, tabListItems]);

    const selectedObjectText: null | string = useMemo(() => {
        const selectedReportingObjectId = localCurrentOptions?.['mainObject']?.[0];

        if (selectedReportingObjectId && reportingObjectsById?.[selectedReportingObjectId]) {
            return getObjName(reportingObjectsById[selectedReportingObjectId], true);
        }
        return null;
    }, [getObjName, localCurrentOptions?.['mainObject'], reportingObjectsById]);

    const tabListSelectHandler = (tabId: string) => {
        dispatch(storeSelectedKey({ [currentModuleID]: tabId }));
    };

    const changeUserLayoutsTrans = useCallback(
        (layouts: TLayouts) => {
            if (selectedKey) {
                dispatch(
                    updateLayout({
                        dashboardKey: selectedKey,
                        moduleId: currentModuleID,
                        layouts,
                    }),
                );
            }
        },
        [dispatch, selectedKey, currentModuleID],
    );

    const handleManageModalClose = () => {
        setManageModalStatus({ show: false });
    };

    const updateWidgetTrans = (data: IUpdateWidgetPayload) => {
        dispatch(updateWidget(data));
    };

    const handleAddNewModalClose = () => {
        setAddNewStatus({ show: false });
    };
    const handleEditClose = () => {
        setEditStatus({ show: false });
    };

    var addWidgets =
        user?.permissions.includes(DASHBOARD_EDITOR_PERMISSION) ||
        (selectedKey !== null && !selectedKey?.includes('project') && !selectedKey?.includes('default'));

    var moveMode = addWidgets;

    var resetToProject =
        user?.permissions?.includes(DASHBOARD_EDITOR_PERMISSION) &&
        selectedKey !== null &&
        (selectedKey?.includes('project') || selectedKey?.includes('default'));

    var saveAsShared = selectedKey !== null && !selectedKey.includes('project') && !selectedKey.includes('default');

    var resetToDefault = resetToProject ?? false;

    var saveAsProject: boolean =
        (user?.permissions.includes(DASHBOARD_EDITOR_PERMISSION) &&
            selectedKey !== null &&
            selectedKey?.includes('project')) ||
        false;

    const menuPermissions: IMenuPermissions = {
        addWidgets,
        saveAsProject,
        saveAsShared,
        addNewPanel: true,
        editPanels: true,
        moveMode,
        resetToDefault,
        resetToProject,
    };

    const isModuleLoading = !reportingObjects?.length || Boolean(isNetworkDataLoading);

    const style: React.CSSProperties = { maxWidth: 'none' };

    return (
        <div ref={ref}>
            <Modals title={t('Widgets manager')} closeModal={handleManageModalClose} modalStatus={manageModalStatus}>
                <WidgetsManager
                    userDashboards={userDashboards}
                    closeModal={handleManageModalClose}
                    availableWidgets={availableWidgets}
                    selectedKey={selectedKey}
                />
            </Modals>
            <Modals title={t('Add new panel')} closeModal={handleAddNewModalClose} modalStatus={addNewModalStatus}>
                <AddNewBody
                    defaultDashboards={defaultDashboards}
                    closeModal={handleAddNewModalClose}
                    selectedKey={selectedKey}
                />
            </Modals>
            <Modals title={t('Edit panels')} closeModal={handleEditClose} modalStatus={editStatus}>
                <EditBody userDashboards={userDashboards} closeModal={handleEditClose} />
            </Modals>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        {requiredSelects.includes('mainPeriod') && (
                            <MainPeriodSelect
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                            />
                        )}
                        {requiredSelects.includes('comparePeriods') && (
                            <ComparePeriodSelect
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                                maxSelected={2}
                            />
                        )}
                        {requiredSelects.includes('mainObject') && (
                            <ObjectsSelect
                                name={'mainObject'}
                                label={t('Main object')}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                                localCurrentOptions={localCurrentOptions}
                                dataObjectFilters={[
                                    'data_objects_tenant',
                                    'data_objects_floor',
                                    'data_objects_passway_type',
                                ]}
                                maxSelected={1}
                                minSelected={1}
                            />
                        )}
                        {requiredSelects.includes('comparisonObjects') && (
                            <ObjectsSelect
                                name={'comparisonObjects'}
                                label={t('Comparison objects')}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                                localCurrentOptions={localCurrentOptions}
                                dataObjectFilters={[
                                    'data_objects_tenant',
                                    'data_objects_floor',
                                    'data_objects_passway_type',
                                ]}
                                maxSelected={10}
                                minSelected={1}
                            />
                        )}
                        {requiredSelects.includes('selectedMetrics') && (
                            <MetricsSelect
                                changeOptions={(args) => dispatch(changeOptions(args))}
                                localCurrentOptions={localCurrentOptions}
                            />
                        )}
                        {requiredSelects.includes('selectedSingleMetric') && (
                            <MetricsSelect
                                name={'selectedSingleMetric'}
                                label={t('Single metric select')}
                                maxSelected={1}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                                localCurrentOptions={localCurrentOptions}
                            />
                        )}
                    </SelectsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>

            {!isModuleLoading ? (
                <WrapperContainer style={style}>
                    <WidgetsWrapper>
                        <Stack alignItems="center" justifyContent="space-between">
                            {selectedObjectText && <WidgetTitle>{selectedObjectText}</WidgetTitle>}

                            <Stack
                                className="general_tab-list__wrapper"
                                alignItems="center"
                                justifyContent="flex-end"
                                gap={10}
                            >
                                <TabList
                                    selectHandler={tabListSelectHandler}
                                    selectedValue={selectedKey}
                                    options={tabListItems}
                                    addComponent={
                                        <PanelsManager
                                            selectedKey={selectedKey}
                                            menuPermissions={menuPermissions}
                                            userDashboards={userDashboards}
                                            setAddNewStatus={setAddNewStatus}
                                            setEditStatus={setEditStatus}
                                            setManageModalStatus={setManageModalStatus}
                                            panelRef={ref}
                                        />
                                    }
                                />
                            </Stack>
                        </Stack>
                        {!isNull(selectedKey) && (
                            <DashboardLayout
                                userDashboards={userDashboards}
                                dashboardKey={selectedKey}
                                moduleId={currentModuleID}
                                layouts={layouts}
                                changeLayouts={changeUserLayoutsTrans}
                                updateWidgetTrans={updateWidgetTrans}
                            />
                        )}
                    </WidgetsWrapper>
                </WrapperContainer>
            ) : (
                <Stack alignItems="center" justifyContent="center" styles={{ height: '100vh' }}>
                    <Spinner type={domain} label={t('Loading...')} labelPosition="below" />
                </Stack>
            )}
        </div>
    );
};

export default PortfolioOverview;
