import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import {
    IExtendedReportingObject,
    IInitialState,
    IIntersectionData,
    ISelectedReportingObject,
    TRawPaiwiseIntersectionsData,
    TRawPercentAverageIntersectionsData,
} from './interfaces';

const initialState: IInitialState = {
    rawPecentAverageIntersections: null,
    extendedReportingObjectsById: null,
    rawPaiwiseIntersections: null,
    selectedReportingObjects: [],
    intersectionsDataById: null,
    isLoading: false,
    daysInPeriod: 0,
    isError: false,
    dataRefetchObject: {},
    tableItemsToShow: 'all',
};

const Leasing_CrossVisits_Widget_Reducer = createSlice({
    name: 'Leasing_CrossVisits_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение расширенной информации о выбранных отчтеных объектах
         */
        storeExtendedReportingObjectsById: (
            state,
            action: PayloadAction<null | { [id: string]: IExtendedReportingObject }>,
        ) => {
            state.extendedReportingObjectsById = action.payload;
        },

        /**
         * Изменение флага загрузки
         */
        toggleLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },

        /**
         * Изменение флага ошибки
         */
        toggleError: (state, action: PayloadAction<boolean>) => {
            state.isError = action.payload;
        },

        /**
         * Сохранение выбранных отчетных объектов
         */
        storeSelectedReportingObjects: (state, action: PayloadAction<ISelectedReportingObject[]>) => {
            state.selectedReportingObjects = action.payload;
        },

        /**
         * Сохранение сырых данных по пересечениям в процентах
         */
        storeRawPercentAverageIntersections: (state, action: PayloadAction<TRawPercentAverageIntersectionsData>) => {
            state.rawPecentAverageIntersections = action.payload;
        },

        /**
         * Сохранение сырых данных по пересеченяим не в процентах
         */
        storeRawPaiwiseIntersections: (state, action: PayloadAction<TRawPaiwiseIntersectionsData>) => {
            state.rawPaiwiseIntersections = action.payload;
        },

        /**
         * Сохранение значений метрики пересечений
         */
        storeIntersectionsDataById: (
            state,
            action: PayloadAction<{ [mainId: string]: { [contraId: string]: IIntersectionData } } | null>,
        ) => {
            state.intersectionsDataById = action.payload;
        },

        /**
         * Сохранение количество дней в периоде
         */
        storeDaysInPeriod: (state, action: PayloadAction<number>) => {
            state.daysInPeriod = action.payload;
        },

        /**
         * Изменение кол-ва отображаемых элементов таблиц
         */
        storeTableItemsToShow: (state, action: PayloadAction<number | 'all'>) => {
            state.tableItemsToShow = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },

        /**
         * Обнуление редьюсера
         */
        resetReducer: () => initialState,
    },
});

export const {
    storeExtendedReportingObjectsById,
    toggleLoading,
    toggleError,
    storeSelectedReportingObjects,
    storeRawPercentAverageIntersections,
    storeRawPaiwiseIntersections,
    storeIntersectionsDataById,
    storeDaysInPeriod,
    resetReducer,
    reloadWidget,
    storeTableItemsToShow,
} = Leasing_CrossVisits_Widget_Reducer.actions;

export const Leasing_CrossVisits_Widget_Reducer_Values = (state: RootState) => state.Leasing_CrossVisits_Widget_Reducer;

export default Leasing_CrossVisits_Widget_Reducer.reducer;
