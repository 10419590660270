import { PlotOptions } from 'highcharts';

export const DYNAMICS_DEFAULT_PLOT_OPTIONS_CONFIG: PlotOptions = {
    areaspline: {
        fillColor: {
            linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
            },
            stops: [
                [0, 'rgba(171, 198, 255, 0.7)'],
                [0.5, 'rgba(204, 220, 255, 0.5)'],
                [0.75, 'rgba(234, 241, 255, 0.3)'],
                [1, 'rgba(253, 254, 255, 0.1)'],
            ],
        },
    },

    series: {
        softThreshold: true,
        marker: { symbol: 'circle', lineColor: '#ffffff', lineWidth: 3, radius: 5 },
    },
};
