import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDynamicsSettings, IReducerState } from './interfaces';
import { RootState } from '../../../../../store';
import { TDynamicsSeries } from '../Dynamics/interfaces';
import { cloneDeep } from 'lodash';

const initialState: IReducerState = {
    dynamicsSettingsById: {},
};

const Dynamics_Settings_Reducer = createSlice({
    name: 'Dynamics_Settings_Reducer',
    initialState,
    reducers: {
        /** Заполнение начальных данных по ключу ID */
        fillInitialDynamicsSettingsById: (state, action: PayloadAction<{ id: string; series?: TDynamicsSeries[] }>) => {
            const initialData: IDynamicsSettings = {
                weekends: {
                    isActive: false,
                },
                events: {
                    isActive: false,
                },
                weather: {
                    isActive: false,
                },
                trendSeries: {
                    selectedSeriesIds: [],
                },
                averageSeries: {
                    selectedSeriesIds: [],
                },
                series: action.payload.series || [],
            };

            state.dynamicsSettingsById[action.payload.id] = initialData;
        },
        /** Обновление настроек по ключу */
        updateDynamicsSettingsById: (
            state,
            action: PayloadAction<{
                id: string;
                data: { key: string; value: object; shouldRewrite?: boolean };
            }>,
        ) => {
            const { id, data } = action.payload;
            const dynamicsSettingsById = cloneDeep(state.dynamicsSettingsById);

            const currentValue = dynamicsSettingsById[id] as IDynamicsSettings | undefined;

            if (currentValue) {
                state.dynamicsSettingsById[id] = {
                    ...currentValue,
                    [data.key]: data.shouldRewrite
                        ? data.value
                        : {
                              ...currentValue[data.key],
                              ...data.value,
                          },
                };
            }
        },
    },
});

export const { fillInitialDynamicsSettingsById, updateDynamicsSettingsById } = Dynamics_Settings_Reducer.actions;

export const Dynamics_Settings_Reducer_Values = (state: RootState) => state.DynamicsSettingsReducer;

export default Dynamics_Settings_Reducer.reducer;
