import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../../General.reducer';
import { IExtendedReportingObject, IIntersectionData } from '../interfaces';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../constants/constants';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import {
    Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer_Values,
    storeExtendedReportingObjectsById,
    storeIntersectionsDataById,
    storeDaysInPeriod,
    toggleLoading,
    toggleError,
    resetReducer,
} from '../reducer';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { IReportingObject } from '../../../../../../General.interfaces';
import { isNull } from 'lodash';

/**
 * Кастомный хук подготовки данных
 */
const usePrepareData = () => {
    const {
        cfg: { reportingObjectsByType, reportingObjectsById },
        selectedLocationId,
    } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const {
        rawPecentAverageIntersections,
        extendedReportingObjectsById,
        rawPaiwiseIntersections,
        selectedReportingObjectsType,
    } = useSelector(Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();

    /** Обнуление редьюсера при смене локации */
    useEffect(() => {
        dispatch(resetReducer());
    }, [selectedLocationId, dispatch]);

    /** Получение количества дней в периоде */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges) {
            const period = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions.mainPeriod!.id,
            )?.period;
            if (period) {
                const dateFrom = DateTime.fromISO(period.dateFrom);
                const dateTo = DateTime.fromISO(period.dateTo);
                const diff = dateTo.diff(dateFrom, ['days']).toObject();
                dispatch(storeDaysInPeriod(diff.days as number));
            }
        }
    }, [localCurrentOptions?.mainPeriod]);

    /** Получение расширенной информации о выбранных отчетных объектах */
    useEffect(() => {
        if (
            localCurrentOptions?.selectedReportingObjectsIds?.length &&
            Object.keys(reportingObjectsByType)?.length &&
            Object.keys(reportingObjectsById)?.length &&
            !isNull(selectedReportingObjectsType.id)
        ) {
            if (reportingObjectsByType[selectedReportingObjectsType.id]) {
                const extendedReportingObjects: { [id: string]: IExtendedReportingObject } =
                    localCurrentOptions.selectedReportingObjectsIds.reduce(
                        (
                            acc: {
                                [x: string]: {
                                    reportingObject: IReportingObject;
                                    contraReportingObjects: { reportingObject: IReportingObject }[];
                                    color: string;
                                };
                            },
                            value: number,
                            index: string | number,
                        ) => {
                            const mainReportingObject = reportingObjectsById[value];
                            if (mainReportingObject && !isNull(selectedReportingObjectsType.id)) {
                                const contraReportingObjects = reportingObjectsByType[selectedReportingObjectsType.id]
                                    ?.filter((element) => element.id !== value)
                                    .map((element) => ({ reportingObject: element }));

                                acc[value] = {
                                    reportingObject: mainReportingObject,
                                    contraReportingObjects,
                                    color: colors[index].color,
                                };
                            }

                            return acc;
                        },
                        {},
                    );

                dispatch(storeExtendedReportingObjectsById(extendedReportingObjects));
            } else {
                dispatch(storeExtendedReportingObjectsById(null));
            }
        }
    }, [
        selectedReportingObjectsType,
        JSON.stringify(localCurrentOptions?.selectedReportingObjectsIds),
        reportingObjectsByType,
        reportingObjectsById,
    ]);

    /** Получение данных пересечений для каждого отчетного объекта */
    useEffect(() => {
        if (rawPecentAverageIntersections && rawPaiwiseIntersections) {
            if (
                rawPecentAverageIntersections['status'] === ResponseStatus.Error ||
                rawPecentAverageIntersections['status'] === ResponseStatus.NoData ||
                rawPaiwiseIntersections['status'] === ResponseStatus.Error ||
                rawPaiwiseIntersections['status'] === ResponseStatus.NoData
            ) {
                dispatch(toggleError(true));
                dispatch(toggleLoading(false));
            }

            if (
                Array.isArray(rawPecentAverageIntersections) &&
                rawPecentAverageIntersections.every((item) => !item?.['error']) &&
                Array.isArray(rawPaiwiseIntersections) &&
                rawPaiwiseIntersections.every((item) => !item?.['error']) &&
                extendedReportingObjectsById
            ) {
                dispatch(toggleLoading(false));
                const result: { [mainId: string]: { [contraId: string]: IIntersectionData } } =
                    rawPecentAverageIntersections.reduce((acc, value) => {
                        const mainId = value?.[0]?.main_obj_id;
                        const piwiseValuesByMainId = rawPaiwiseIntersections?.find(
                            (element) => element[0].main_obj_id === mainId,
                        );
                        const result = value.reduce((acc, value) => {
                            let intersection = null;
                            if (piwiseValuesByMainId) {
                                const paiwiseValue = piwiseValuesByMainId?.find(
                                    (element) => element.contra_obj_id === value.contra_obj_id,
                                );
                                if (paiwiseValue) {
                                    intersection = paiwiseValue.intersection_count_unique;
                                }
                            }
                            acc[value.contra_obj_id] = {
                                percentIntersection: value.main_percent,
                                intersection,
                            };
                            return acc;
                        }, {});
                        acc[mainId] = result;
                        return acc;
                    }, {});

                dispatch(storeIntersectionsDataById(result));
            }
        }
    }, [rawPecentAverageIntersections, rawPaiwiseIntersections, extendedReportingObjectsById]);
};

export default usePrepareData;
