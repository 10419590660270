import { useContext } from 'react';
import OrderSwitcher from '../../../../../../../../../components/OrderSwitcher/OrderSwitcher';
import { TableOfObjectsContext } from '../../../../context';
import { ITableSortData } from '../../../../interfaces';
import { DefaultText, Tr } from '../../styles';
import { HeaderCellWrapper, Th, THead, UnitsText } from './styles';
import Stack from '../../../../../../../../../components/Stack/Stack';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';

/**
 * Шапка таблицы
 */
const Header = () => {
    const context = useContext(TableOfObjectsContext);
    const { t } = useTranslation(['metrics', 'translation']);

    const handleOrderToggle = (data: ITableSortData) => {
        if (context?.storeTableSortDataTrans) {
            context.storeTableSortDataTrans(data);
        }
    };

    return (
        <THead>
            <Tr>
                <Th>
                    <DefaultText>{t('Name')}</DefaultText>
                </Th>
                {context?.selectedMetrics?.map((selectedMetric) => {
                    const currentMetric = context?.allMetrics?.find((element) => element.id === selectedMetric);
                    const orderingMain =
                        context?.tableSortData?.metricId === selectedMetric &&
                        context?.tableSortData?.periodType === 'mainPeriod'
                            ? context?.tableSortData.ordering
                            : 'none';

                    const orderingCompare =
                        context?.tableSortData?.metricId === selectedMetric &&
                        context?.tableSortData?.periodType === 'comparePeriod'
                            ? context?.tableSortData.ordering
                            : 'none';
                    return (
                        <Th key={selectedMetric}>
                            <HeaderCellWrapper>
                                <OrderSwitcher
                                    handleOrderToggle={(data) =>
                                        handleOrderToggle({
                                            ...data,
                                            metricId: selectedMetric,
                                            periodType: data.id,
                                        })
                                    }
                                    ordering={orderingMain}
                                    id="mainPeriod"
                                    position="left"
                                />
                                <Stack direction={'column'} justifyContent={'center'}>
                                    <DefaultText>{t(currentMetric?.text || selectedMetric)}</DefaultText>
                                    <UnitsText>{`(${t(currentMetric?.units || '')})`}</UnitsText>
                                </Stack>

                                <OrderSwitcher
                                    handleOrderToggle={(data) =>
                                        handleOrderToggle({
                                            ...data,
                                            metricId: selectedMetric,
                                            periodType: data.id,
                                        })
                                    }
                                    ordering={orderingCompare}
                                    id="comparePeriod"
                                    position="right"
                                />
                            </HeaderCellWrapper>
                        </Th>
                    );
                })}
            </Tr>
        </THead>
    );
};

export default Header;
