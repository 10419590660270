import { TFunction } from 'i18next';

export interface ICrossVisitsSelectOption {
    id: CrossVisitsMapsSettingsIds;
    test: string;
}

export enum CrossVisitsMapsSettingsIds {
    ShowPerimeters = 'showPerimeters',
    ShowRatings = 'showRatings',
    ShowLabels = 'showLabels',
    Comparison = 'comparison',
    JoinFloors = 'joinFloors',
    ShowPlans = 'showPlans',
}

export const crossVisitsMapsSettingsOptions = [
    { id: CrossVisitsMapsSettingsIds.Comparison, text: 'Comparison' },
    { id: CrossVisitsMapsSettingsIds.ShowPlans, text: 'Show plans' },
    { id: CrossVisitsMapsSettingsIds.ShowPerimeters, text: 'Show Perimeters' },
    { id: CrossVisitsMapsSettingsIds.ShowLabels, text: 'Show labels' },
    { id: CrossVisitsMapsSettingsIds.ShowRatings, text: 'Show ratings' },
    { id: CrossVisitsMapsSettingsIds.JoinFloors, text: 'Join floors' },
];
