import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeOptions } from '../components/UserSettings/reducer';
import { generalReducerValues } from '../General.reducer';
import { useAppDispatch } from './useSettings';
import { useWidgetCurrentOptions } from './useWidgetCurrentOptions';

export const useEmptySettings = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { locations, selectedLocationId, currentModuleID, modulesConfig } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const name = modulesConfig.find((item) => item.module_id === currentModuleID)?.store_method.global.includes('pl')
        ? 'pl'
        : 'pls';

    useEffect(() => {
        if (!currentModuleID) return;
        if (!localCurrentOptions?.[name] && locations?.[0] && !selectedLocationId) {
            dispatch(
                changeOptions({
                    newOptions: {
                        [name]: [locations?.[0]],
                    },
                }),
            );
        }
    }, [currentModuleID, dispatch, localCurrentOptions, locations, name, selectedLocationId]);
};
