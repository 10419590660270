import { isNumber } from 'lodash';
import { TMetricResponse } from '../../../../../../../../../General.interfaces';
import { DS } from '../../../../../../../../../constants/constants';
import { IDataAdapterResult } from '../interfaces';
import getDifferenceBetweenNumbers from '../../../../../../../../../tools/getDifferenceBetweenNumbers';

const dataAdapter = (response: TMetricResponse[], objId: number): IDataAdapterResult => {
    const result: IDataAdapterResult = {
        value: null,
    };
    response.forEach((metricResponse) => {
        metricResponse
            .filter((item) => item.context.data_objects[0].id === objId)
            .forEach((metricResponseItem) => {
                result.value = metricResponseItem.items[0].value;
            });
    });

    return result;
};

export default dataAdapter;
