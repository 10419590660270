import React from 'react';
import { IPeriodObj } from '../../../../../../General.interfaces';
import { Td, Value } from '../../../styles';
import Stack from '../../../../../Stack/Stack';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';

export const CellDelta = (props: {
    showPercentOfTotal: boolean | undefined,
    style: React.CSSProperties | undefined,
    item: { value: number; name: string; period: IPeriodObj }
}) => {
    return (
        <Td

            paddingLeft={props.showPercentOfTotal ? undefined : '0px'}
            paddingRight={props.showPercentOfTotal ? undefined : '0px'}
        >
            {props.showPercentOfTotal && (
                <Stack styles={{ height: '100%' }} alignItems="flex-end">
                    <Value style={props.style} fontWeight={'500'}>
                        {valueFormatter({
                            value: props.item.value,
                            units: '',
                            precision: 0,
                        })}
                    </Value>
                </Stack>
            )}
        </Td>
    );
};