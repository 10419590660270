import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import useFetchData from './api/useFetchData';
import { withLoading } from '../../../../../tools/API/withLoading';
import { ContentWrapper, LoaderWrapper } from './styles/styles';
import getResponseStatus from '../../../../../tools/API/helpers/getResponseStatus';
import PieChart from '../../../../../components/Charts/PieChart/PieChart';
import Stack from '../../../../../components/Stack/Stack';
import Table from './components/Table/Table';
import { Divider, Text } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { paymentAnalysisTenantSelect } from '../../constants/constants';
import { theme } from 'src/theme';

/**
 * Компонент для отображения виджета структура платежей
 */
const PaymentStructure: React.FC = () => {
    const {
        currentModuleID,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const { data, isFetching, error, refetch } = useFetchData();

    const tenantId = localCurrentOptions?.[paymentAnalysisTenantSelect]?.[0]?.id as number | undefined;

    const widgetTitle = reportingObjectsById[tenantId || '']?.name
        ? `${reportingObjectsById[tenantId || '']?.name}: ${t('Sales structure')}`
        : t('Sales structure');

    const WithLoading = useMemo(() => {
        const Component = withLoading(LoaderWrapper, {
            data: getResponseStatus({
                isDataExists: data ? data?.isDataExists : false,
                isFetching,
                error,
                data,
            }),
            height: 400,
        });

        return Component;
    }, [error, JSON.stringify(data), isFetching]);

    const onReload = () => {
        refetch();
    };

    return (
        <WidgetWrapper styles={{ overflow: 'hidden' }} ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {widgetTitle}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        reloadHandlerNoDispatch={onReload}
                        widgetName={'Sales structure'}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <WithLoading>
                <Stack justifyContent="space-between" flexWrap="wrap" gap={5}>
                    <ContentWrapper maxWidth={'60%'}>
                        <PieChart
                            chartWrapperStyles={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                            options={data?.chartOptions}
                        />
                    </ContentWrapper>
                    <ContentWrapper maxWidth={'unset'} padding={'0 0 25px 0'}>
                        <Text weight={'bold'}>{data?.ratingItems.salesRating[0].operationName}</Text>
                        <Table ratingItems={data?.ratingItems.salesRating} />
                        <Divider style={{ margin: '15px 0px 10px 0px' }} />
                        <Text weight={'bold'}>{data?.ratingItems.returnsRating[0].operationName}</Text>
                        <Table ratingItems={data?.ratingItems.returnsRating} bgColor={theme.colors.warning} />
                    </ContentWrapper>
                </Stack>
            </WithLoading>
        </WidgetWrapper>
    );
};

export default PaymentStructure;
