import { TServerResponse, handleServerResponse } from '../../../../tools/API/handleServerResponse';
import {
    Maps_CrossVisits_Module_Reducer_Values,
    resetCrossVisitsModuleReducer,
    storeRawVersionsData,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { ResponseStatus } from '../../../../tools/API/constants';
import { generalReducerValues } from '../../../../General.reducer';
import { mlFromLocation } from '../../../../tools/mlFromLocation';
import responseAnalyzer from '../../../../tools/API/responseAnalyzer';
import { useEffect, useMemo } from 'react';
import { useGetRawData as useGetRawWidgetData } from '../widgets/CrossVisitsWidget/hooks/useFetchData';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import requestVersions from '../../../../tools/API/requestVersions';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const { locations, token, urlsByServices } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Maps_CrossVisits_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useGetRawWidgetData();

    const versionsUrl = useMemo(() => {
        if (urlsByServices?.['core/map-service-back']) return urlsByServices['core/map-service-back'].VERSIONS_URL;
        return null;
    }, [urlsByServices]);

    /** Получение и сохранение сырых данных для версий*/
    useEffect(() => {
        dispatch(resetCrossVisitsModuleReducer());

        if (
            !localCurrentOptions ||
            !localCurrentOptions.selectedLocationId ||
            !locations.length ||
            !token ||
            !versionsUrl
        )
            return;
        const location = locations?.find((item) => item.id === localCurrentOptions.selectedLocationId);
        const mlId = mlFromLocation(location);

        dispatch(storeRawVersionsData({ status: ResponseStatus.Loading, message: `${t(ResponseStatus.Loading)}...` }));

        mlId &&
            requestVersions({ mlId, token, url: versionsUrl }).then((res: TServerResponse) => {
                handleServerResponse({
                    success: storeRawVersionsData,
                    error: storeRawVersionsData,
                    responseAnalyzer,
                    dispatch,
                    res,
                });
            });
    }, [localCurrentOptions?.selectedLocationId, token, locations, versionsUrl]);
};
