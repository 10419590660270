import { FC } from 'react';
import { IProps as ISelectsProps } from '../../interfaces';
import UniversalSelect from '../../../../../../../Selects/UniversalSelect/UniversalSelect';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { IChangeOptionsArgs } from '../../../../../../../UserSettings/interfaces';

const UseObjectsFromSelect: FC<ISelectsProps> = (props) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const updateWidgetVisual = (visualParamKey: string) => (args: IChangeOptionsArgs) => {
        props.updateWidget('visual', { [visualParamKey]: args.newOptions?.[visualParamKey] });
    };

    const options = [
        { id: 'fromWidget', text: 'From widget' },
        { id: 'fromPanel', text: 'From panel' },
    ];

    return (
        <UniversalSelect
            localCurrentOptions={{ ...props.widgetConfig?.visual, currentModuleID } || {}}
            changeOptions={updateWidgetVisual('useObjectsFrom')}
            labelText={t('Use object from')}
            name="useObjectsFrom"
            options={options}
            mode="single"
        />
    );
};

export default UseObjectsFromSelect;
