import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import generateAverageIntersectionsQueryData from '../tools/generateAverageIntersectionsQueryData';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    storeExtendedAverageIntersectionsData,
    Reports_Intersections_Widget_Reducer_Values,
    storeRawAverageIntersectionsData,
    resetIntersewctionsWidgetReducer,
    storeGrid,
} from '../reducer';
import requestAverageIntersections from '../../../../../../tools/API/requestAverageIntersections';
import { DS } from '../../../../../../constants/constants';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const { downloadSignalCounter, keyWord } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const { token, selectedLocationId, urlsByServices, currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { t } = useTranslation();

    /** Обнуление редьюсера при выборе другой проектной локации */
    useEffect(() => {
        dispatch(resetIntersewctionsWidgetReducer());
    }, [selectedLocationId]);

    useEffect(() => {
        if (!localCurrentOptions || !token || !downloadSignalCounter || !urlsByServices) return;
        const rest = `${DS}${keyWord}`;
        dispatch(storeExtendedAverageIntersectionsData(null));
        dispatch(storeGrid(null));

        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions?.[`mainObjectsIds${rest}`] &&
            localCurrentOptions?.[`mainDateRanges${rest}`] &&
            localCurrentOptions?.[`mainPeriod${rest}`] &&
            localCurrentOptions?.[`compareObjectsIds${rest}`]
        ) {
            const queryData = generateAverageIntersectionsQueryData({
                selectedCompareReportingObjectsIds: localCurrentOptions?.[`compareObjectsIds${rest}`] || [],
                selectedReportingObjectsIds: localCurrentOptions?.[`mainObjectsIds${rest}`] || [],
                mainDateRanges: localCurrentOptions?.[`mainDateRanges${rest}`],
                mainPeriod: localCurrentOptions?.[`mainPeriod${rest}`],
                token,
            }).map((element) => ({
                ...element,
                signal,
                url: urlsByServices['core/structure-service'].AVERAGE_INTERSECTIONS_REQUEST_URL,
            }));

            dispatch(storeRawAverageIntersectionsData({ status: ResponseStatus.Loading, message: t('Loading...') }));
            requestAverageIntersections(queryData).then((responses: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawAverageIntersectionsData,
                    error: storeRawAverageIntersectionsData,
                    res: responses,
                    dispatch,
                });
            });
        }

        return () => controller.abort();
    }, [downloadSignalCounter, token, urlsByServices]);
};
