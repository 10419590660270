import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IEvent, IEventType, IExtendedEvent, IState, IEventTypeToUpdate } from './interfaces';

const initialState: IState = {
    moduleId: 'Events:Events map',
    newEvents: [],
    rawEventsData: [],
    extendedEventsDataById: {},
    eventTypesById: {},
    eventsToUpdate: [],
    eventTypes: [],
    eventTypeToUpdate: null,
    selectedEventId: null,
    eventIdToDelete: null,
    selectedEventIdToEdit: null,
    dataRefetchObject: {},
};

const Events_EventsMap_Widget_Reducer = createSlice({
    name: 'Events_EventsMap_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение новых событий
         */
        storeNewEvents: (state, action: PayloadAction<IEvent[]>) => {
            state.newEvents = action.payload;
        },

        /**
         * Сохранение сырых данных событий
         */
        storeRawEventsData: (state, action: PayloadAction<IEvent[]>) => {
            state.rawEventsData = action.payload;
        },

        /**
         * Сохранение расширенных данных событий, где ключ это ID события (название + период)
         */
        storeExtendedEventsDataById: (
            state,
            action: PayloadAction<{ [name_dateFrom_dateTo: string]: IExtendedEvent }>,
        ) => {
            state.extendedEventsDataById = action.payload;
        },

        /**
         * Сохранение типа события для обновления его цвета
         */
        storeEventTypeToUpdate: (state, action: PayloadAction<IEventTypeToUpdate | null>) => {
            state.eventTypeToUpdate = action.payload;
        },

        /**
         * Сохранение событий, которые нужно обновить
         */
        storeEventsToUpdate: (state, action: PayloadAction<IExtendedEvent[]>) => {
            state.eventsToUpdate = action.payload;
        },

        /**
         * Сохранение id выбранного события
         */
        storeSelectedEventId: (state, action: PayloadAction<number | null>) => {
            state.selectedEventId = action.payload;
        },

        /**
         * Сохранение события для удаления
         */
        storeEventIdToDelete: (state, action: PayloadAction<number | null>) => {
            state.eventIdToDelete = action.payload;
        },

        /**
         * Сохранение id события, которое нужно отредактировать
         */
        storeSelectedEventIdToEdit: (state, action: PayloadAction<number | null>) => {
            state.selectedEventIdToEdit = action.payload;
        },

        /**
         * Сохранение типов событий
         */
        storeEventTypes: (state, action: PayloadAction<IEventType[]>) => {
            state.eventTypes = action.payload;
            const eventTypesById = {};
            action.payload.forEach((item) => {
                eventTypesById[item.id] = item;
            });
            state.eventTypesById = eventTypesById;
        },

        /**
         * Перезапрос событий и их типов
         */
        reloadWidgets: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storeNewEvents,
    storeRawEventsData,
    storeExtendedEventsDataById,
    storeEventTypeToUpdate,
    storeEventsToUpdate,
    storeSelectedEventId,
    storeEventIdToDelete,
    storeSelectedEventIdToEdit,
    storeEventTypes,
    reloadWidgets,
} = Events_EventsMap_Widget_Reducer.actions;

export const Events_EventsMap_Widget_Reducer_Values = (state: RootState) => state.Events_EventsMap_Widget_Reducer;

export default Events_EventsMap_Widget_Reducer.reducer;
