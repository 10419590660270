import { TFunction } from 'i18next';
import { IMetric, TMetricResponse } from '../../../../../General.interfaces';
import HighchartsReact from 'highcharts-react-official';
import { DateTime, Info } from 'luxon';
import { valueFormatter } from '../../../../../tools/Strings/valueFormatter';
import _, { isBoolean, isNumber } from 'lodash';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';
import { DataLabelsOptions, Options, PointLabelObject, SeriesOptionsType } from 'highcharts';
import { TWidgetConfig } from '../interfaces';
import { theme } from 'src/theme';
import { stringHSL2RGB } from 'src/tools/hls2rgb';
import { stringHsl2Hex } from 'src/tools/hsl2hex';

export interface IWDBHArgs {
    /** Ответ от сервера. Сюда приходит ответ от запроса метрик */
    response: TMetricResponse[];
    /** Все метрики */
    allMetrics: IMetric[];
    /** Функция перевода */
    t: TFunction;
    /** Id выбранной метрики */
    selectedMetricId?: string;
    /** Конфигурация виджета */
    widgetConfig: TWidgetConfig;
}

/**
 * Подготовка данных для виджета. Функция возвращает конфигурацию для компонента динамики
 */
const heatmapWeekdaysByHoursAdapter = (
    args: IWDBHArgs,
): { main: HighchartsReact.Props; summary: HighchartsReact.Props; avg: HighchartsReact.Props } => {
    const { response, allMetrics, t, selectedMetricId, widgetConfig } = args;
    const MAX_COLOR = stringHsl2Hex(theme.colors.primary);
    const BORDER_COLOR = stringHsl2Hex(theme.colors.primaryLighter);

    const showLabels = isBoolean(widgetConfig?.visual?.showLabels) ? widgetConfig?.visual?.showLabels : true;

    const metricFromStore = allMetrics.find((metric) => metric.id === selectedMetricId);

    const mainPeriodData = response.filter((item) => item[0].context.alias?.includes('mainPeriod'))[0][0];
    const workingHoursData = response.filter((item) => item[0].context.alias?.includes('workingHours'))[0][0];

    const longWeekdays = Info.weekdays('long');

    const workingHours = Array.from(
        new Set(
            workingHoursData.items
                .filter((item) => (isNumber(item.value) ? item.value > 0 : false))
                .map((item) => Number(DateTime.fromISO(item.time).hour)),
        ),
    );

    const sumOfTwo = (a?: number | null, b?: number | null): number | null => {
        let sum = null;
        if (isNumber(a)) {
            if (isNumber(b)) {
                sum = a + b;
            } else {
                sum = a;
            }
        } else if (isNumber(b)) {
            sum = b;
        }
        return sum;
    };

    /**
     * Получение avg данных по дням недели и часам за переданный период
     */
    const data: IExtendedChartPoint[] = mainPeriodData.items
        .reduce((acc: Array<{ hour: number; weekday: number; sum: number | null; count: number }>, item) => {
            const hour = DateTime.fromISO(item.time).hour;
            const weekday = DateTime.fromISO(item.time).weekday - 1;
            const value = item.value as number | null;

            const existingCell = acc.find((item) => item.hour === hour && item.weekday === weekday);
            if (existingCell) {
                const sum = sumOfTwo(existingCell.sum, value);
                return acc.map((item) => {
                    if (item.hour === hour && item.weekday === weekday) {
                        return { ...item, sum, count: existingCell.count + 1 };
                    } else {
                        return item;
                    }
                });
            } else {
                return [...acc, { hour, weekday, sum: value, count: 1 }];
            }
        }, [])
        .filter((item) => workingHours.includes(item.hour))
        .map((item) => {
            const xAxisIndex = workingHours.findIndex((wh) => wh === item.hour);
            return {
                value: isNumber(item.sum)
                    ? (valueFormatter({
                          value: item.sum / item.count,
                          precision: metricFromStore?.round_decimal_places,
                          numericOutput: true,
                      }) as number)
                    : null,

                title: t(String(longWeekdays[item.weekday])) as string,
                precision: metricFromStore?.round_decimal_places,
                subTitle: t(metricFromStore?.units || ''),
                showUnits: false,
                name: `${item.hour}:00`,
                units: metricFromStore?.units,
                valueBy: 'value',
                y: 6 - item.weekday,
                x: xAxisIndex,
            };
        });

    /**
     * Если выбранный период меньше недели, заполняем недостающее нуллами
     */
    const allWeekdays = [0, 1, 2, 3, 4, 5, 6];
    const weekdaysInData = Array.from(new Set(data.map((item) => (isNumber(item.y) ? 6 - item.y : null))));
    const diff = _.difference(allWeekdays, weekdaysInData);

    if (diff.length) {
        diff.forEach((weekday) => {
            workingHours.forEach((hour, index) => {
                data.push({
                    title: t(String(longWeekdays[isNumber(weekday) ? weekday : ''])) as string,
                    precision: metricFromStore?.round_decimal_places,
                    subTitle: t(metricFromStore?.units || ''),
                    name: `${hour}:00`,
                    valueBy: 'value',
                    units: metricFromStore?.units,
                    showUnits: false,
                    value: null,
                    y: isNumber(weekday) ? 6 - weekday : null,
                    x: index,
                });
            });
        });
    }

    /**
     * Создаем сводные данные
     */
    const summaryData: IExtendedChartPoint[] = data.reduce((acc, item) => {
        const weekday = item.y;
        const value = item.value;

        const defaultValue: IExtendedChartPoint = {
            title: t(String(longWeekdays[isNumber(weekday) ? 6 - weekday : -1])) as string,
            precision: metricFromStore?.round_decimal_places,
            subTitle: t(metricFromStore?.units || ''),
            y: weekday,
            valueBy: 'value',
            units: metricFromStore?.units,
            showUnits: false,
            name: String(t(`Sum`)),
            value,
            x: 0,
        };

        const existingCell = acc.find((item) => item.y === weekday);
        if (existingCell) {
            let sum = sumOfTwo(existingCell.value, value);
            return acc.map((item) => {
                if (item.y === weekday) {
                    return {
                        ...defaultValue,
                        value: isNumber(sum)
                            ? (valueFormatter({
                                  value: sum,
                                  precision: metricFromStore?.round_decimal_places,
                                  numericOutput: true,
                              }) as number)
                            : null,
                    };
                } else {
                    return item;
                }
            });
        } else {
            return [
                ...acc,
                {
                    ...defaultValue,
                },
            ];
        }
    }, [] as IExtendedChartPoint[]);

    const avgData = summaryData.map((item) => ({
        ...item,
        name: String(t(`Average`)),
        value: item?.value ? Math.round(item?.value / 24) : null,
    }));

    function dataLabelFormatter(this: PointLabelObject, _: DataLabelsOptions) {
        return valueFormatter({ value: this.point.value, units: metricFromStore?.units, showUnits: false });
    }

    const series: SeriesOptionsType[] = [
        {
            name: '',
            nullColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: BORDER_COLOR,
            data,
            dataLabels: {
                enabled: showLabels,
                color: '#000000',
                formatter: dataLabelFormatter,
            },
            type: 'heatmap',
        },
    ];

    const summarySeries: SeriesOptionsType[] = [
        {
            name: '',
            nullColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: BORDER_COLOR,
            data: summaryData,
            dataLabels: {
                enabled: showLabels,
                color: '#000000',
                formatter: dataLabelFormatter,
            },
            type: 'heatmap',
        },
    ];

    const avgSeries: SeriesOptionsType[] = [
        {
            name: '',
            nullColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: BORDER_COLOR,
            data: avgData,
            dataLabels: {
                enabled: showLabels,
                color: '#000000',
                formatter: dataLabelFormatter,
            },
            type: 'heatmap',
        },
    ];

    const defaultOptions: Options = {
        chart: {
            type: 'heatmap',
            className: 'heatmap-weekdays-by-hours__summary-chart',
            plotBorderColor: BORDER_COLOR,
            plotBorderWidth: 2,
        },

        legend: {
            align: 'center',
            layout: 'horizontal',
            margin: 0,
            verticalAlign: 'bottom',
            y: 20,
            symbolHeight: 10,
        },

        title: {
            text: '',
        },
        yAxis: {
            categories: [t('Mo'), t('Tu'), t('We'), t('Th'), t('Fr'), t('Sa'), t('Su')].reverse(),
            title: { text: '' },
        },
        xAxis: {
            categories: workingHours.map((item) => `${item}:00`),
            title: { text: '' },
            labels: { rotation: -45 },
        },
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: MAX_COLOR,
        },
        lang: {
            noData: t('No data'),
        },
        tooltip: {
            shared: false,
            outside: true,
            className: 'heatmap-weekdays-by-hours__summary-chart__tooltip',
        },
    };

    return {
        main: {
            options: {
                series,
                ...defaultOptions,
            },
        },
        summary: {
            options: {
                series: summarySeries,
                ...defaultOptions,
                colorAxis: {
                    ...defaultOptions.colorAxis,
                    className: 'heatmap-weekdays-by-hours__summary-chart_opacity ',
                },

                xAxis: {
                    ...defaultOptions.xAxis,
                    className: 'heatmap-weekdays-by-hours__summary-chart_opacity ',
                    categories: ['00:00'], // сделано, чтобы столбцы были одной высоты (это значение не видно)
                },
                yAxis: {
                    ...defaultOptions.yAxis,
                    visible: false,
                },
            },
        },
        avg: {
            options: {
                series: avgSeries,
                ...defaultOptions,
                colorAxis: {
                    ...defaultOptions.colorAxis,
                    className: 'heatmap-weekdays-by-hours__summary-chart_opacity ',
                },

                xAxis: {
                    ...defaultOptions.xAxis,
                    className: 'heatmap-weekdays-by-hours__summary-chart_opacity ',
                    categories: ['00:00'], // сделано, чтобы столбцы были одной высоты (это значение не видно)
                },
                yAxis: {
                    ...defaultOptions.yAxis,
                    visible: false,
                },
            },
        },
    };
};

export default heatmapWeekdaysByHoursAdapter;
