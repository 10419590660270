import { FC } from 'react';
import { IProps } from './interfaces';
import { useSelector } from 'react-redux';
import { Network_Section_Reducer_Values } from '../../../../../../Chapters/Network/reducer';
import dataAdapter from './tools/dataAdapter';
import { ContentWrapper, NoEventsText, Wrapper } from './styles';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import Card from './components/Card/Card';
import Stack from '../../../../../Stack/Stack';

/**
 * Отображение количества событий, которые происходили в выбранных Локаций
 */
const NetworkEventsByPl: FC<IProps> = (props) => {
    var { cfg } = useSelector(Network_Section_Reducer_Values);

    var { t } = useTranslation();

    var data = dataAdapter({ ...props, reportingObjects: cfg.reportingObjectsByType['location'] || [] });

    if (!data.data.length) {
        return <NoEventsText>{t('No events during the period')}</NoEventsText>;
    }

    return (
        <Wrapper>
            <ContentWrapper>
                <Stack justifyContent="space-around" gap={15} flexWrap="wrap" alignItems="center">
                    {data.data.map((item) => {
                        return <Card key={item.reportingObject.id} {...item} />;
                    })}
                </Stack>
            </ContentWrapper>
        </Wrapper>
    );
};

export default NetworkEventsByPl;
