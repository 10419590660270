import { IModuleConfig } from '../General.interfaces';

export const getStoreMethod = (modulesConfig: IModuleConfig[], moduleId: string, selectName: string) => {
    const module = modulesConfig?.find((item) => item.module_id === moduleId);
    let result = '';
    if (module) {
        Object.keys(module.store_method).forEach((key) => {
            if (module.store_method[key].includes(selectName)) {
                result = key;
            }
        });
    }
    return result;
};
