import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Props } from './configurations';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import './styles/styles.scss';
import * as constants from '../../constants/constants';
import useStyles from '../stylesHooks/useStyles';
import { DivCollapseBodySC, NameWrapper, Wrapper } from './styles/styles';

const Collapse: React.FC<Props> = ({
    isCollapsed = true,
    toggleCollapse,
    actingAllHeader = true,
    iconPosition = 'left',
    iconJustify = '',
    icon,
    label,
    collapseHeader,
    children,
    handleAdditionalControlClick,
    additionalControl,
    id,
    additionalControlPosition = 'after',
    ...props
}) => {
    const [collapsed, setCollapsed] = useState(isCollapsed);

    useEffect(() => {
        setCollapsed(() => isCollapsed);
    }, [isCollapsed]);

    const ref = useRef<HTMLDivElement>(null);

    const mergedStyles = useStyles({ ref, props, constants });

    const onHeaderClick = () => {
        actingAllHeader && setCollapsed(() => !collapsed);
        toggleCollapse && toggleCollapse(!collapsed);
    };

    const onButtonClick = () => {
        !actingAllHeader && setCollapsed(() => !collapsed);
    };

    const headerClassName = useMemo(() => {
        return cn(['molecules-collapse__header', { 'molecules-collapse--open': !collapsed }]);
    }, [collapsed]);

    const mainClassName = useMemo(() => {
        return cn(['molecules-collapse', ...mergedStyles]);
    }, [mergedStyles]);

    const iconClassName = useMemo(() => {
        return cn(
            'molecules-collapse__icon',
            'collapse__icon--color',
            { icon__padding__left: iconPosition === 'right' },
            { icon__padding__right: iconPosition === 'left' },
            // { 'molecules-collapse__icon--left': iconPosition === 'left' },
            // { 'molecules-collapse__icon--right': iconPosition === 'right' },
            { 'collapse__icon--rotate': !collapsed },
        );
    }, [collapsed]);

    const onAdditionalControlClick = (id: string | undefined) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        handleAdditionalControlClick && id && handleAdditionalControlClick(id);
    };

    const iconButton = useMemo(() => {
        return (
            <div className={iconClassName} onClick={onButtonClick}>
                <Icon type={icon} size="xxs" />
            </div>
        );
    }, [icon, iconClassName]);

    const _additionalControl = useMemo(() => {
        return (
            additionalControl && (
                <div style={{ display: 'flex' }} onClick={onAdditionalControlClick(id)}>
                    {additionalControl}
                </div>
            )
        );
    }, [additionalControl, onAdditionalControlClick, id]);

    return (
        <div className={mainClassName}>
            <Wrapper
                justifyContent={iconJustify}
                ref={ref}
                className={headerClassName}
                onClick={onHeaderClick}
                data-test-name={id}
            >
                {icon && iconPosition === 'left' && iconButton}

                <NameWrapper>
                    {additionalControlPosition === 'before' && _additionalControl}
                    {label && <div className="molecules-collapse__label">{label}</div>}
                    {additionalControlPosition === 'after' && _additionalControl}
                    {collapseHeader && collapseHeader}
                </NameWrapper>

                {icon && iconPosition === 'right' && iconButton}
            </Wrapper>

            {children && (
                <DivCollapseBodySC
                    collapsed={collapsed}
                    isRtl={document.dir === 'rtl'}
                    className="molecules-collapse__body"
                >
                    {children}
                </DivCollapseBodySC>
            )}
        </div>
    );
};

export default Collapse;
