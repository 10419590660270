import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../General.reducer';

/**
 * для обеспечения показа толко разрешенных модулей
 * @returns showModule:boolean
 */

export const useModulePermissions = () => {
    const { currentModuleID, user, modulesConfig } = useSelector(generalReducerValues);
    const [showModule, setShowModule] = useState(false);

    useEffect(() => {
        if (user?.permissions && currentModuleID) {
            const currentModulePermissions =
                modulesConfig.find((item) => item.module_id === currentModuleID)?.permissions || [];
            if (currentModulePermissions.every((item) => user.permissions.includes(item))) {
                setShowModule(() => true);
            }
        }
    }, [currentModuleID, user?.permissions]);

    return showModule;
};
