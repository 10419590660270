import React, { FC, useEffect, useState } from 'react';
import { IProps } from './interfaces';
import { isNumber, orderBy } from 'lodash';
import { getColorFromPercent } from '../../../../../tools/getColorFromPercent';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';

import { IDataAdapterResponseItem } from '../../interfaces';
import { CellRating } from './components/CellRating';
import { PercentOfTotal } from './components/PercentOfTotal';
import { CellAbsolute } from './components/CellAbsolute';
import { CellDelta } from './components/CellDelta';
import { CellArea } from './components/CellArea';
import { CellDeviation } from './components/CellDeviation';

/**
 * Тело таблицы
 */

const Body: FC<IProps> = ({ comparePeriods, data, metric, viewSettings, allMetrics }) => {
    const { t } = useTranslation(['metrics', 'translation']);

    const metricFromStore = allMetrics?.find((item) => item.id === metric);

    const nodeComponents = [
        {
            name: '',
            isMain: false,
            component: (item: IDataAdapterResponseItem) => <CellRating item={item} key={`222${item.id}`} />,
        },
        {
            name: '',
            isMain: false,
            component: (item: IDataAdapterResponseItem) => (
                <PercentOfTotal
                    key={`PercentOfTotal_isMain: false${item.id}`}
                    showPercentOfTotal={viewSettings?.showPercentOfTotal}
                    style={viewSettings?.mainValuePercentOfTotalStyles}
                    item={item}
                />
            ),
        },
        {
            name: 'absolute',
            isMain: true,
            component: (item: IDataAdapterResponseItem) => (
                <CellAbsolute
                    key={`CellAbsolute_ isMain: true${item.id}`}
                    showAllValues={viewSettings?.showAllValues}
                    showAbsoluteValues={viewSettings?.showAbsoluteValues}
                    metricFromStore={metricFromStore}
                    item={item}
                    value={viewSettings?.showMainValueUnits}
                    t={t}
                />
            ),
        },
        {
            name: 'delta',
            isMain: true,
            component: (item2: IDataAdapterResponseItem) =>
                item2.deltas.map((item, i) => {
                    return (
                        <CellDelta
                            key={`deltas item_isMain: true${i}`}
                            showPercentOfTotal={viewSettings?.showPercentOfTotal}
                            style={viewSettings?.mainValuePercentOfTotalStyles}
                            item={item}
                        />
                    );
                }),
        },
        {
            name: 'area',
            isMain: true,
            component: (item: IDataAdapterResponseItem) => (
                <CellArea
                    key={`CellArea_isMain: true${item.id}`}
                    showPercentOfTotal={viewSettings?.showPercentOfTotal}
                    style={viewSettings?.mainValuePercentOfTotalStyles}
                    item={item}
                />
            ),
        },
        {
            name: 'deviation',
            isMain: true,
            component: (item: IDataAdapterResponseItem) =>
                [...comparePeriods].map((period, index) => {
                    const color = getColorFromPercent(item.deviation ? item.deviation[index] : 0, 'object');

                    return (
                        <CellDeviation
                            key={JSON.stringify(period) + index}
                            style={viewSettings?.compareValueStyles}
                            showAbsoluteValues={viewSettings?.showAbsoluteValues}
                            color={color}
                            metricFromStore={metricFromStore}
                            item={item}
                            index={index}
                            showCompareValueArrow={viewSettings?.showCompareValueArrow}
                        />
                    );
                }),
        },
        {
            name: 'area',
            isMain: false,
            component: (item: IDataAdapterResponseItem) => (
                <CellArea
                    key={`CellArea_isMain: false${item.id}`}
                    showPercentOfTotal={viewSettings?.showPercentOfTotal}
                    style={viewSettings?.mainValuePercentOfTotalStyles}
                    item={item}
                />
            ),
        },
        {
            name: 'delta',
            isMain: false,
            component: (item2: IDataAdapterResponseItem) =>
                item2.deltas.map((item, i) => {
                    return (
                        <CellDelta
                            key={`deltas item_isMain: false${i}`}
                            showPercentOfTotal={viewSettings?.showPercentOfTotal}
                            style={viewSettings?.mainValuePercentOfTotalStyles}
                            item={item}
                        />
                    );
                }),
        },
        {
            name: 'deviation',
            isMain: false,
            component: (item: IDataAdapterResponseItem) =>
                [...comparePeriods].map((period, index) => {
                    const color = getColorFromPercent(item.deviation ? item.deviation[index] : 0, 'object');

                    return (
                        <CellDeviation
                            key={JSON.stringify(period) + index}
                            style={viewSettings?.compareValueStyles}
                            showAbsoluteValues={viewSettings?.showAbsoluteValues}
                            color={color}
                            metricFromStore={metricFromStore}
                            item={item}
                            index={index}
                            showCompareValueArrow={viewSettings?.showCompareValueArrow}
                        />
                    );
                }),
        },
        {
            name: 'absolute',
            isMain: false,
            component: (item: IDataAdapterResponseItem) => (
                <CellAbsolute
                    key={`CellAbsolute_ isMain: false${item.id}`}
                    showAllValues={viewSettings?.showAllValues}
                    showAbsoluteValues={viewSettings?.showAbsoluteValues}
                    metricFromStore={metricFromStore}
                    item={item}
                    value={viewSettings?.showMainValueUnits}
                    t={t}
                />
            ),
        },
    ];

    return (
        <tbody>
            {orderBy(
                data || [],
                (item) =>
                    viewSettings?.mainColumn === 'area'
                        ? item.area.value
                        : viewSettings?.mainColumn === 'deviation'
                        ? item[viewSettings?.mainColumn ? viewSettings?.mainColumn : ''][0]
                        : item[viewSettings?.mainColumn ? viewSettings?.mainColumn : ''] || '',
                viewSettings?.sortType,
            )
                .slice(0, isNumber(viewSettings?.itemsToShow) ? viewSettings?.itemsToShow : (data || []).length + 1)
                .map((item) => {
                    return (
                        <tr key={item.name}>
                            {nodeComponents.map((_item, i) => {
                                if (_item.name === '') {
                                    return _item.component(item);
                                }
                                if (_item.name !== '' && !_item.isMain) {
                                    return (
                                        viewSettings?.additionalColumns?.find(
                                            (x) => x.id === _item.name && viewSettings?.mainColumn !== _item.name,
                                        )?.checked && _item.component(item)
                                    );
                                } else if (_item.name !== '' && _item.isMain) {
                                    return viewSettings?.mainColumn === _item.name && _item.component(item);
                                }
                                return null;
                            })}
                        </tr>
                    );
                })}
        </tbody>
    );
};

export default Body;
