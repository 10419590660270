import styled from 'styled-components/macro';

export const Units = styled.span`
    color: var(--color-secondary-darker);
    line-height: 14px;
`;

export const THead = styled.thead`
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
`;

export const HeaderText = styled.span`
    white-space: nowrap;
`;

export const Tr = styled.tr<{ bgColor?: string }>`
    background-color: ${(p) => p.bgColor || 'inherit'};
`;
