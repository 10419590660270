import { setIsRTLLang } from '../General.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TLang } from '../General.interfaces';

/**
 * Кастомный хук для работы настройками пользователя (выбранный язык, выбраннвя локация, выбранный модуль)
 */

const objRTLLang = {
    ar: 'ar',
};

const useRTLLang = (): void => {
    const { i18n } = useTranslation();

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();

    /** Запись языка и id проектной локации из localStorage */
    useEffect(() => {
        const lang: string | null = localStorage.getItem('lang');
        if (lang) {
            dispatch(setIsRTLLang(!!objRTLLang[lang]));
        }
    }, [localStorage.getItem('lang')]);
};

export default useRTLLang;
