import { DateTime } from 'luxon';
import generateId from '../../../../../../tools/generateId';
import { columns } from '../components/Table/constants/constants';
import { IRowValues, ITableCellData } from '../interfaces';

/**
 * Функция для генерации заполненной или пустой строки
 * @param data Значения строки
 */
const generateRowData = (data?: IRowValues) => {
    const frontId = generateId();
    const row: ITableCellData[] = [];

    const dateFrom = DateTime.now().set({ hour: 0, minute: 0 });
    const dateTo = DateTime.now().endOf('day');
    columns.forEach((cell) => {
        const result: ITableCellData = {
            ...cell,
            value: data ? data[cell.key] : '',
            type: 'body',
            readOnly: false,
            frontId,
        };
        switch (cell.key) {
            case 'controls':
                result.readOnly = true;
                break;
            case 'event_type':
                result.readOnly = true;
                break;
            case 'data_objects_ids':
                result.readOnly = true;
                break;
            case 'date_from':
                result.value = data ? data[cell.key] : dateFrom.toFormat('yyyy-MM-dd');
                break;
            case 'time_from':
                result.value = data ? data[cell.key] : dateFrom.toFormat('HH:mm');
                break;
            case 'date_to':
                result.value = data ? data[cell.key] : dateTo.toFormat('yyyy-MM-dd');
                break;
            case 'time_to':
                result.value = data ? data[cell.key] : dateTo.toFormat('HH:mm');
                break;
            default:
                break;
        }
        row.push(result);
    });

    return row;
};

export default generateRowData;
