import React, { Fragment, useRef, useMemo } from 'react';
import {
    PositioningImperativeRef,
    PositioningVirtualElement,
    Menu,
    MenuGroup,
    MenuGroupHeader,
    MenuPopover,
    MenuTrigger,
    Divider,
    MenuList,
} from '@fluentui/react-components';
import { t } from 'i18next';

import { ContextualMenu } from 'src/components/ContextualMenu';
import { Identity } from 'src/types';

import {
    CellEvent,
    TableMenuItem,
    GroupMenuItem,
    MenuItem,
    SubMenuItem,
    MenuConfig,
    MenuSplitter,
    TableContext,
} from '../interfaces';
import { getLabel } from '../utils';

import { getMenuConfig } from './menuConfig';
import { StyledMenuItem } from './styles';

const getRect = (x = 0, y = 0) => {
    return () => ({
        height: 0,
        bottom: y,
        right: x,
        width: 0,
        left: x,
        top: y,
        x,
        y,
    });
};

export function isMenuItem<I extends string, R extends Identity<I>>(item: TableMenuItem<I, R>): item is MenuItem<I, R> {
    return item !== null && typeof item === 'object' && 'id' in item && !('subItems' in item);
}

export function isSubMenuItem<I extends string, R extends Identity<I>>(
    item: TableMenuItem<I, R>,
): item is SubMenuItem<I, R> {
    return item !== null && typeof item === 'object' && 'id' in item && 'subItems' in item;
}

export function isGroupMenuItem<I extends string, R extends Identity<I>>(
    item: TableMenuItem<I, R>,
): item is GroupMenuItem<I, R> {
    return item !== null && typeof item === 'object' && 'id' in item && 'groupItems' in item;
}

export function isMenuSplitter(item: MenuSplitter | MenuItem<any, any> | SubMenuItem<any, any>): item is MenuSplitter {
    return item === null || item === undefined || item === '';
}

const findRowAndCol = (element: HTMLElement | null): { row: number; col: number } => {
    while (element) {
        const row = element.getAttribute('data-row');
        const col = element.getAttribute('data-col');
        if (row !== null && col !== null) {
            return { row: parseInt(row, 10), col: parseInt(col, 10) };
        }
        element = element.parentElement;
    }
    return { row: -1, col: -1 }; // Return default values if not found
};

const prepareCellEvent = <I extends string, R extends Identity<I>>(
    contextRef: React.RefObject<TableContext<I, R>>,
    contextMenuEvent: MouseEvent,
): CellEvent<I, R> => {
    if (!contextRef?.current) {
        throw new Error('Table context is not available');
    }

    const tableContext = contextRef.current;
    const targetElement = contextMenuEvent.target as HTMLElement;
    const { row, col } = findRowAndCol(targetElement);

    if (row === -1 || col === -1) {
        throw new Error('Row or column not found');
    }

    const rowData = tableContext.data[row];
    const column = tableContext.visibleColumns[col];
    const value = rowData ? rowData[column.id as keyof R] : null;

    return {
        row,
        col,
        column,
        value,
    };
};

type ContextMenuProps<
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
> = {
    contextRef: React.RefObject<TableContext<I, R>>;
    menuConfig?: MenuConfig<I, R>;

    contextMenuEvent: MouseEvent | null;
};

const renderMenuItem = <I extends string, R extends Identity<I>>(
    menuItem: MenuItem<I, R> | SubMenuItem<I, R> | MenuSplitter,
    itemIndex: number,
    contextRef: React.RefObject<TableContext<I, R>>,
    contextMenuEvent: MouseEvent,
    deep = 0,
): React.ReactNode => {
    const context = contextRef.current!;

    if (isMenuSplitter(menuItem)) {
        return <Divider key={`${context?.tableId || ''}-divider-${deep}-${itemIndex}`} />;
    }

    const cellEvent = prepareCellEvent(contextRef, contextMenuEvent);

    const label = getLabel(menuItem.id, menuItem.label, t);

    if (isSubMenuItem(menuItem)) {
        return (
            <Menu persistOnItemClick hoverDelay={10} key={`${context?.tableId || ''}-submenu-${menuItem.id}-${deep}`}>
                <MenuTrigger disableButtonEnhancement>
                    <StyledMenuItem key={`${menuItem.id}-${itemIndex}`}>{label}</StyledMenuItem>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {menuItem.subItems.map((subItem, index) =>
                            renderMenuItem(subItem, index, contextRef, contextMenuEvent, deep + 1),
                        )}
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    }

    if (isGroupMenuItem(menuItem)) {
        return (
            <MenuGroup key={`${context?.tableId || ''}-menu-group-${menuItem.id}-${deep}`}>
                <MenuGroupHeader key={`${context?.tableId || ''}-group-${menuItem.id}-${deep}-${itemIndex}`}>
                    {label}
                </MenuGroupHeader>
                {menuItem.groupItems.map((groupItem, index) =>
                    renderMenuItem(groupItem, index, contextRef, contextMenuEvent, deep + 1),
                )}
            </MenuGroup>
        );
    }

    const isVisible =
        menuItem?.checkVisibility?.({
            cell: cellEvent,
            rowData: context.data[cellEvent.row || 0],
            context,
            menuItemId: menuItem.id,
        }) ?? true;

    if (menuItem.renderer && isVisible) {
        return (
            <StyledMenuItem key={`${context?.tableId || ''}-custom-item-${menuItem.id}-${deep}-${itemIndex}`}>
                {menuItem.renderer({
                    cell: cellEvent,
                    rowData: context.data[cellEvent.row || 0],
                    context,
                    menuItemId: menuItem.id,
                    label,
                })}
            </StyledMenuItem>
        );
    }

    return (
        <StyledMenuItem
            key={`${context?.tableId || ''}-menu-item-${menuItem.id}-${deep}-${itemIndex}`}
            onClick={() => {
                menuItem?.onItemClick?.({
                    cell: cellEvent,
                    rowData: context.data[cellEvent.row || 0],
                    context,
                    menuItemId: menuItem.id,
                });

                context.closeMenu();
            }}
        >
            {label}
        </StyledMenuItem>
    );
};

export const getContextualMenuBody = <I extends string, R extends Identity<I>>(
    menuConfig: MenuConfig<I, R>,
    contextRef: React.RefObject<TableContext<I, R>>,
    contextClickEvent: MouseEvent,
): React.ReactElement<MenuConfig<I, R>, typeof MenuList> => {
    return (
        <MenuList key={`${contextRef?.current?.tableId || ''}-menu-list`}>
            {menuConfig.map((menuItem, index) => (
                <Fragment key={`${contextRef?.current?.tableId || ''}-menu-list-item-fragment`}>
                    {renderMenuItem(menuItem, index, contextRef, contextClickEvent)}
                </Fragment>
            ))}
        </MenuList>
    );
};

const defaultMenuConfig = getMenuConfig();

export const ContextMenu = <I extends string = string, R extends Identity<I> = Identity<I>>({
    contextMenuEvent,
    contextRef,
}: // menuConfig,
ContextMenuProps<I, R>) => {
    if (!contextRef?.current) {
        throw new Error('Table context is not available');
    }

    const contextMenuTargetRef = useRef<PositioningImperativeRef>(null);

    const isOpened = useMemo(() => {
        const virtualElement: PositioningVirtualElement = {
            getBoundingClientRect: getRect(contextMenuEvent?.clientX, contextMenuEvent?.clientY),
        };
        contextMenuTargetRef.current?.setTarget(virtualElement);

        return contextMenuEvent !== null;
    }, [contextMenuEvent]);

    // TODO: join with menuConfig
    const menuBody = isOpened ? getContextualMenuBody(defaultMenuConfig, contextRef, contextMenuEvent!) : null;

    return (
        <ContextualMenu
            key={`table-menu-${contextRef.current.tableId}`}
            isContextualMenuOpen={isOpened}
            closeContextualMenu={contextRef.current.closeMenu}
            contextualMenuBody={menuBody!}
            positioningRef={contextMenuTargetRef}
        />
    );
};
