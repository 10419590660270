import { useTranslation } from 'react-i18next';
import { ClearAllWrapper, Click } from './styles';

interface IProps {
    optionsNumber: number;
    selectedNumber: number;
    onSelectAllClick: () => void;
    onClearAllClick: () => void;
}

const MAX_NUMBER = 20;

const SelectAllClearAll: React.FC<IProps> = ({ optionsNumber, selectedNumber, onSelectAllClick, onClearAllClick }) => {
    const { t } = useTranslation();
    return (
        <ClearAllWrapper>
            {optionsNumber <= MAX_NUMBER && <Click onClick={onSelectAllClick}>{t('Select all')}</Click>}
            {selectedNumber > 0 && <Click onClick={onClearAllClick}>{t('Clear all')}</Click>}
        </ClearAllWrapper>
    );
};

export default SelectAllClearAll;
