import React, { memo, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isMobileInboundWidth } from 'src/theme';

import WidgetAdditionalControls from 'src/components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitleWrapper from 'src/components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import DynamicsPMOWidget from 'src/components/CommonWidgets/DynamicsPMO/DynamicsPMO';
import WidgetWrapper from 'src/components/Wrappers/WidgetWrapper/WidgetWrapper';
import WidgetTitle from 'src/components/Wrappers/WidgetTitle/WidgetTitle';
import TabList from 'src/components/TabList/TabList';
import usePeriods from 'src/hooks/common/usePeriods';
import { DYNAMICS_PMO_QUERY_KEY } from 'src/components/CommonWidgets/DynamicsPMO/constants/constants';
import { TChartType } from 'src/components/CommonWidgets/DynamicsPMO/interfaces';
import { useWidgetCurrentOptions } from 'src/hooks/useWidgetCurrentOptions';
import { modulesConfig } from 'src/constants/modulesConfig';
// import { Sales_PosAnalysis_Module_Reducer } from '../../reducer';
import { generalReducerValues } from 'src/General.reducer';
import { queryClient } from 'src/index';
import { TDynamicsSeries } from 'src/components/CommonWidgets/DynamicsPMO/components/Dynamics/interfaces';
import DynamicsSettings from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/DynamicsSettings';
import { CHART_DESKTOP_HEIGHT, CHART_MOBILE_HEIGHT, DS } from 'src/constants/constants';
import { Dynamics_Settings_Reducer_Values } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/reducer';
import { IDynamicsSettings } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/interfaces';
import {
    // storeActivePosOfTenantsIds,
    // storeSelectedPosOfTenantsRanges,
    Sales_PosAnalysis_Module_Reducer_Values,
} from 'src/Chapters/Sales/PosAnalysis/reducer';
import { emptyArray, emptyObject } from 'src/tools';

import { posEntitiesByTenantAdapter } from '../../api/dataAdapters/posEntitiesByTenantAdapter';
import useFetchData from '../../api/useFetchData';
import { SALES_SECTION } from '../../../../constants';
import { TenantPosInfo } from '../../api/interfaces';

// import { Performance_Dashboard_DynamicsPMO_Widget_Reducer_Values, storeSelectedChartType } from './reducer';
import { ChartWrapper, DynamicsSettingsWrapper, Title } from './styles';

/**
 * Компонент для отображения графиков динамики по периодам, метрикам и объектам
 */
// const DynamicsPMO: React.FC = memo(() => {
export const DynamicsPos: React.FC = () => {

    // const { moduleName } = useSelector(Sales_PosAnalysis_Module_Reducer);
    const {
        mainAreaSize: { width },
        currentModuleID,
        cfg: { reportingObjectsById },
        rawMetrics,
    } = useSelector(generalReducerValues);
    const { activeTenantIds } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);
    const [selectedTenantId] = activeTenantIds || [];


    const { data } = useFetchData(posEntitiesByTenantAdapter);
    const possByTenant = data || emptyObject;

    const metricParams = useMemo(
        () => {
            const [posIds, aliases] = ((possByTenant?.[selectedTenantId] || []) as TenantPosInfo[]).reduce(
                ([accIds, accAliases], pos) => [
                    [...accIds, ...([pos?.id] || [])],
                    {
                        ...accAliases,
                        [pos?.id]: pos?.registrationId || pos?.factoryId,
                    },
                ],
                [[], {}],
            );

            return {
                posIds,
                aliases,
            }
        },
        [selectedTenantId, possByTenant],
    );
    const salesMetrics = useMemo(
        () => rawMetrics
            .filter(({ section }) => section === SALES_SECTION)
            .map(({ id }) => (id)),
        [rawMetrics],
    );

    const currentModuleConfig = modulesConfig.find(
        (item) => item.module_id === currentModuleID,
    );

    const moduleName = currentModuleConfig!['module_name'];

    // const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    // const { availableChartTypes, selectedChartType } = useSelector(
    //     Sales_PosAnalysis_Module_Reducer,
    // );
    const { dynamicsSettingsById } = useSelector(Dynamics_Settings_Reducer_Values);

    const { mainPeriod, comparePeriods } = usePeriods(currentModuleID);

    const [series, setSeries] = useState<TDynamicsSeries[]>([]);

    const { t } = useTranslation();
    // const dispatch = useDispatch();
    const ref = useRef(null);

    const DYNAMICS_PMO_ID = `${currentModuleID}${DS}DynamicsPMO`;

    const currentSettings = dynamicsSettingsById[DYNAMICS_PMO_ID] as IDynamicsSettings | undefined;

    const isMobile = isMobileInboundWidth(width);

    // const tabList = useMemo(() => {
    //     const tabListSelectHandler = (id: string) => {
    //         dispatch(storeSelectedChartType(id));
    //     };
    //     return (
    //         <TabList
    //             selectHandler={tabListSelectHandler}
    //             selectedValue={selectedChartType.id}
    //             options={availableChartTypes}
    //             widgetName={'DynamicsByPMO'}
    //         />
    //     );
    // }, [availableChartTypes, dispatch, selectedChartType.id]);

    const reloadWidget = () => {
        queryClient.invalidateQueries({ queryKey: [DYNAMICS_PMO_QUERY_KEY, currentModuleID] });
    };

    const giveSeriesOutside = (series: TDynamicsSeries[]) => {
        setSeries(series);
    };

    const reportingObjects = selectedTenantId ? [reportingObjectsById?.[selectedTenantId]] : []

    if (activeTenantIds?.length !== 1) {
        return null;
    }


    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title> {t('Dynamics by periods, metrics, objects')}</Title>
                    <WidgetAdditionalControls
                        widgetName={'Dynamics by periods, metrics, objects'}
                        reloadHandlerNoDispatch={reloadWidget}
                        pdfDownloadData={{ targetRef: ref }}
                    />
                </WidgetTitle>
                {/* {!isMobile && tabList} */}
            </WidgetTitleWrapper>
            <DynamicsSettingsWrapper>
                <DynamicsSettings id={DYNAMICS_PMO_ID} series={series} />
            </DynamicsSettingsWrapper>
            {/* {isMobile && tabList} */}
            {mainPeriod && salesMetrics && reportingObjects && (
                <ChartWrapper>
                    <DynamicsPMOWidget
                        reportingObjects={reportingObjects}
                        comparePeriods={emptyArray}
                        chartType="pos_metrics"
                        metrics={salesMetrics}
                        metricParams={metricParams}
                        mainPeriod={mainPeriod.period}
                        moduleId={currentModuleID}
                        widgetId="POS-Dynamics"
                        viewSettings={{
                            isShowTitle: false,
                            seriesIdsForAverage: currentSettings?.averageSeries.selectedSeriesIds,
                            seriesIdsForTrend: currentSettings?.trendSeries.selectedSeriesIds,
                            showWeekends: currentSettings?.weekends.isActive,
                            showWeather: currentSettings?.weather.isActive,
                            showEvents: currentSettings?.events.isActive,
                            chartWrapperStyles: {
                                marginTop: 'var(--space-md)',
                                height: isMobile ? `${CHART_MOBILE_HEIGHT}px` : `${CHART_DESKTOP_HEIGHT}px`,
                            },
                        }}
                        giveSeriesOutside={giveSeriesOutside}
                    />
                </ChartWrapper>
            )}
        </WidgetWrapper>
    );
};