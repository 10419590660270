import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProps as ISelectsProps } from '../../interfaces';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import { IChangeOptionsArgs } from '../../../../../../../UserSettings/interfaces';
import { changeRequiredParams } from '../../../../../../../../Dashboards.reducer';
import UniversalSelect from '../../../../../../../Selects/UniversalSelect/UniversalSelect';

const UseMetricFromSelect: FC<ISelectsProps> = (props) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updateWidgetVisual = (visualParamKey: string) => (args: IChangeOptionsArgs) => {
        props.updateWidget('visual', { [visualParamKey]: args.newOptions?.[visualParamKey] });

        const newChoice = args.newOptions[visualParamKey];
        if (visualParamKey === 'useMetricFrom' && props.widgetConfig?.id) {
            if (Array.isArray(newChoice) && newChoice.length) {
                const [data] = newChoice;
                const paramsMap = { useMetricFrom: 'selectedSingleMetric' };
                const mode = data.id === 'fromPanel' ? 'add' : 'remove';

                dispatch(
                    changeRequiredParams({
                        param: paramsMap[visualParamKey],
                        id: props.widgetConfig?.id,
                        dashboardKey: props.dashboardKey,
                        moduleId: props.moduleId,
                        mode,
                    }),
                );
            }
        }
    };

    const options = [
        { id: 'fromWidget', text: 'From widget' },
        { id: 'fromPanel', text: 'From panel' },
    ];

    return (
        <UniversalSelect
            localCurrentOptions={{ ...props.widgetConfig?.visual, currentModuleID } || {}}
            changeOptions={updateWidgetVisual('useMetricFrom')}
            labelText={t('Use metric from')}
            name="useMetricFrom"
            options={options}
            mode="single"
        />
    );
};

export default UseMetricFromSelect;
