import Tooltip from '../../../Tooltip/Tooltip';
import TooltipBody from '../../../TooltipBody/TooltipBody';
import { TooltipFormatterContextObject, TooltipOptions } from 'highcharts';
import { renderToString } from 'react-dom/server';

export interface IArgs {
    tooltipTitleFormatter?: (value: number | string) => string;
    showSeriesNameInTooltipBody: boolean;
    tooltipSubTitle: string;
    tooltipTitle: string;
    sharedTooltips: boolean;
    tooltipOptions?: TooltipOptions;
}

/**
 * Функция для генерации тултипа
 * @param showSeriesNameInTooltipBody Флаг отвечающий за отображение названия серии в теле тултипа
 * @param tooltipTitleFormatter Функция для форматирования заголовка тултипа
 * @param tooltipSubTitle Подзаголовок тултипа
 * @param tooltipTitle Заголовок тултипа
 * @returns объект настроек тултипа для графика
 */
const generateTooltip = (args: IArgs) => {
    const {
        showSeriesNameInTooltipBody,
        tooltipTitleFormatter,
        tooltipSubTitle,
        tooltipTitle,
        sharedTooltips,
        tooltipOptions,
    } = args;

    return {
        formatter: function (this: TooltipFormatterContextObject) {
            return renderToString(
                <Tooltip
                    title={
                        tooltipTitle
                            ? tooltipTitle
                            : tooltipTitleFormatter
                            ? tooltipTitleFormatter(this.x as number)
                            : this.point?.['title'] || this.point?.name || String(this.x)
                    }
                    subTitle={this.point?.['subTitle'] || tooltipSubTitle}
                >
                    <TooltipBody showSeriesName={showSeriesNameInTooltipBody} points={this.points || [this]} />
                </Tooltip>,
            );
        },
        backgroundColor: 'transparent',
        animation: false,
        borderWidth: 0,
        useHTML: true,
        shadow: false,
        hideDelay: 0,
        shared: sharedTooltips,
        padding: 0,
        ...tooltipOptions,
    };
};

export default generateTooltip;
