import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import {
    storeAllTenantsData,
    Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    storePreraredData,
} from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { usePrepareData } from './usePrepareData';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { TRequestMetricsArgs } from '../../../../../../tools/API/interfaces';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { DS } from '../../../../../../constants/constants';

export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();

    usePrepareData();
    const {
        token,
        structures,
        selectedLocationId,
        src: { dataObj2ProjectCategory, projectCategories },
        cfg: { reportingObjectsByType, reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const { selectedTab, dataRefetchObject } = useSelector(Leasing_TenantOverview_Reference_Widget_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    useEffect(() => {
        if (
            !localCurrentOptions?.selectedReportingObjectsIds?.length ||
            !localCurrentOptions.selectedMetrics ||
            !token
        ) {
            dispatch(storeAllTenantsData({ status: ResponseStatus.NoData, message: t('No data') }));
            dispatch(storePreraredData({ status: ResponseStatus.NoData, message: t('No data') }));
            return;
        }

        dispatch(storeAllTenantsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

        const controller = new AbortController();
        const signal = controller.signal;
        const mainPeriod = localCurrentOptions.mainPeriod?.id;

        const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item.id === mainPeriod);
        const mainTimeRange = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];

        const comparePeriodId = localCurrentOptions?.['selectedComparePeriod']?.[0]?.id;
        const compareDateRange = localCurrentOptions.compareDateRanges?.find((item) => item.id === comparePeriodId);

        if (!compareDateRange?.period) return;

        const compareTimeRange = [compareDateRange?.period.dateFrom, compareDateRange?.period.dateTo];

        const obj_ids = reportingObjectsByType?.['tenant']?.map((item) => item.id);

        const requests: TRequestMetricsArgs = localCurrentOptions.selectedMetrics?.reduce((acc, metric) => {
            const result = [
                {
                    signal,
                    token,
                    alias: `${metric}${DS}main`,
                    metric,
                    obj_ids,
                    object_aggregation: false,
                    time_range: mainTimeRange,
                    time_freq: null,
                },
                {
                    signal,
                    token,
                    alias: `${metric}${DS}comparison`,
                    metric,
                    obj_ids,
                    object_aggregation: false,
                    time_range: compareTimeRange,
                    time_freq: null,
                },
            ];
            return [...acc, ...result];
        }, []);

        fetchData(requests).then((res: TServerResponse) => {
            handleServerResponse({
                responseAnalyzer,
                success: storeAllTenantsData,
                error: storeAllTenantsData,
                dispatch,
                res,
            });
        });

        return () => {
            controller.abort();
        };
    }, [
        dispatch,
        JSON.stringify(localCurrentOptions?.['selectedComparePeriod']),
        JSON.stringify(localCurrentOptions?.selectedMetrics),
        JSON.stringify(localCurrentOptions?.mainPeriod),
        reportingObjectsByType,
        token,
        dataRefetchObject,
    ]);
};
