import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: var(--space-xxxs);
    margin-top: var(--space-md);
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export const Ul = styled.ul`
    padding-left: var(--space-xxxs);
    margin: 0;

    li {
        font-weight: 500;
    }
`;

export const ContentWrapper = styled.div`
    max-width: 800px;
    div {
        font-size: medium;
    }
`;

export const WrapperTable = styled.table`
    margin: var(--space-xs) 0;
    td,
    th {
        padding: var(--space-xxxs);
    }
    tr {
        border-bottom: 1px solid var(--color-secondary-dark);
    }
`;
