import { DS } from '../../../../constants/constants';

export const RENTS_QUERY_KEY = 'RENTS_QUERY_KEY';
export const POST_RENTS_QUERY_KEY = 'POST_RENTS_QUERY_KEY';
export const METRIC_KEYS = ['client_total_rent_income'];
export const COLUMNS = [
    { colId: 'data_object', colName: 'Object id', readOnly: true },
    { colId: 'objName', colName: 'Object name', readOnly: true },
    { colId: 'date', colName: 'Date', readOnly: true },
    { colId: `metrics${DS}${METRIC_KEYS[0]}`, colName: 'Total rent income (Inc. VAT)' },
];
export const SUMMARY_CELL_WITH_DATA_COLOR_GREEN = 'hsl(134, 61%, 81%)';
export const SUMMARY_CELL_WITH_DATA_COLOR_YELLOW = 'hsl(60, 100%, 70%)';
