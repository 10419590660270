import React, { useEffect, useState } from 'react';
import { Props } from './configurations';
import * as constants from '../../constants/constants';

import cn from 'classnames';

import './styles/styles.scss';
import _ from 'lodash';
import { useDebounce } from './core/useDebounce';

const TextInput: React.FC<Props> = ({
    disabled = false,
    id = undefined,
    type = 'text',
    name = undefined,
    maxLength = constants.DEFAULT_INPUT_MAX_LENGTH,
    minLength = 0,
    placeholder = '',
    pattern = undefined,
    size = 'md',
    fullWidth = false,
    handleChange,
    outsideValue,
    hasError = false,
    isMultiLine = false,
    minNumber,
}) => {
    const { DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_INPUT_MAX_LENGTH } = constants;
    const [value, setValue] = useState(outsideValue || '');

    useEffect(() => {
        outsideValue !== undefined && setValue(() => outsideValue);
    }, [outsideValue]);

    const debouncedValue = useDebounce(value, 300);

    useEffect(() => {
        handleChange && handleChange({ id, name, value: debouncedValue || '' });
    }, [debouncedValue]);

    /**
     * получить класс размера кнопки
     * @param size - размер кнопки
     */
    const getSizeCss = (size: string): string => {
        return `${DEFAULT_CLASS_INPUT_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}size${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${size}`;
    };

    const onChange = (e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.currentTarget.value;
        setValue(() => newValue);
    };

    const className = React.useMemo(() => {
        const cssClassSize = getSizeCss(size);
        return cn(
            DEFAULT_CLASS_INPUT_PREFIX,
            cssClassSize,
            {
                [`${DEFAULT_CLASS_INPUT_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}full-width`]: fullWidth,
            },
            { error: hasError },
            'transition',
            { resize: isMultiLine },
        );
    }, [DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, getSizeCss, size, disabled]);

    return (
        <>
            {isMultiLine ? (
                <textarea
                    placeholder={placeholder}
                    maxLength={maxLength}
                    minLength={minLength}
                    disabled={disabled}
                    className={className}
                    value={value}
                    onChange={onChange}
                />
            ) : (
                <input
                    type={type}
                    placeholder={placeholder}
                    pattern={pattern}
                    maxLength={maxLength}
                    minLength={minLength}
                    disabled={disabled}
                    className={className}
                    value={value}
                    onChange={onChange}
                    min={minNumber}
                />
            )}
        </>
    );
};

export default TextInput;
