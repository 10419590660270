import { IObject, TNestedOptions } from '../interfaces';

/**
 * Функция для определения типа опций для селекта
 * @returns {boolean} Если true, то селект должен иметь плоскую структуру
 */
export const isSimple = (options: any[]): options is IObject[] => {
    return options.every((item) => item.hasOwnProperty('id') && item?.hasOwnProperty('text'));
};

/**
 * Функция для определения типа опций для селекта
 * @returns {boolean} Если true, то селект должен иметь вложенную структуру
 */
export const isNested = (options: any[]): options is TNestedOptions => {
    return options.every((item) => item.hasOwnProperty('section') && item?.hasOwnProperty('objects'));
};
