import { createSlice } from '@reduxjs/toolkit';
import { IReducerState } from './interfaces';
import { RootState } from '../../../store';

const initialState: IReducerState = {
    moduleName: 'Performance:Dashboard',
};

const Performance_Dashboard_Module_Reducer = createSlice({
    name: 'Performance_Dashboard_Module_Reducer',
    initialState,
    reducers: {},
});

// export const {} = Performance_Dashboard_Module_Reducer.actions;

export const Performance_Dashboard_Module_Reducer_Values = (state: RootState) =>
    state.Performance_Dashboard_Module_Reducer;

export default Performance_Dashboard_Module_Reducer.reducer;
