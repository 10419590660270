import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 16px 20px 0 20px;
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        padding: 10px 10px 0 10px;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;

    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
`;

export const WrappedItem = styled.div`
    display: flex;
`;

export const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #000;
    max-width: 100%;
    word-wrap: break-word;
`;

export const FiltersMonitorWrapper = styled.div`
    margin-top: 10px;
    div {
        margin: 0;
    }
`;

export const SelectsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;
