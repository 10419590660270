import { useEffect, useState } from 'react';
import { IProps as IWidgetWrapperProps } from '../../../../../Chapters/Dashboards/components/WidgetWrapper/interfaces';
import { TWidgetConfig, TWidgetType } from '../interfaces';
import useFetchData from './useFetchData';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { isMetricErrorGuard } from '../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { ResponseStatus } from '../../../../../tools/API/constants';

interface IArgs {
    /** Конфигурация виджета */
    widgetConfig: TWidgetConfig;
    /** ID текущего модуля */
    moduleId: string;
    /** Ключ текущего dashboard */
    dashboardKey: string | null;
    /** Метрика для passwaysTraffic */
    passwaysTrafficMetric: string;
    /** Тип виджета */
    widgetType: TWidgetType;
}

const useWidgetWrapperSettings = (args: IArgs): Partial<IWidgetWrapperProps> => {
    const { data, isFetching, error } = useFetchData({
        passwaysTrafficMetric: args.passwaysTrafficMetric,
        widgetConfig: args.widgetConfig,
        dashboardKey: args.dashboardKey,
        widgetType: args.widgetType,
        moduleId: args.moduleId,
    });

    const { allMetrics } = useSelector(generalReducerValues);
    const [settings, setSettings] = useState<Partial<IWidgetWrapperProps>>({});

    const localCurrentOptions = useWidgetCurrentOptions(args.moduleId);

    const { t } = useTranslation(['metrics', 'translation']);

    useEffect(() => {
        const result: Partial<IWidgetWrapperProps> = {
            titleAdditionalText: '',
            wrapperPaddingSize: undefined,
            titleSize: undefined,
            isNoData: false,
            isError: false,
            isLoading: isFetching,
        };

        let metricId = args.passwaysTrafficMetric;

        if (isMetricErrorGuard(error)) {
            result.isNoData = error.status === ResponseStatus.NoData;
            result.isError = error.status === ResponseStatus.Error;
        } else if (data) {
            result.isNoData = !data.length;
        }

        switch (args.widgetType) {
            case 'passwaysTraffic':
                break;
            case 'topByMetrics':
                metricId = args.widgetConfig?.options?.metrics?.[0] || '';
                break;

            case 'topByMetricsNetworkObjects':
            case 'topByMetricsLocations':
                metricId = args.widgetConfig?.options?.metrics?.[0] || '';
                if (args.widgetConfig?.visual.useMetricFrom?.[0]?.id === 'fromPanel') {
                    metricId = localCurrentOptions?.['selectedSingleMetric']?.[0];
                }
                result.wrapperPaddingSize = 'normal';
                result.titleSize = 'normal';
                result.isNoData = false;
                result.isError = false;
                break;

            default:
                break;
        }
        const currentMetric = allMetrics.find((metric) => metric.id === metricId);
        result.titleAdditionalText = currentMetric ? `${t(currentMetric.text)}, ${t(currentMetric.units)}` : '';

        setSettings(result);
    }, [
        allMetrics,
        args.passwaysTrafficMetric,
        args.widgetConfig?.options?.metrics,
        args.widgetConfig?.visual?.useMetricFrom,
        args.widgetType,
        JSON.stringify(data),
        error,
        isFetching,
        localCurrentOptions?.['selectedSingleMetric'],
        t,
    ]);

    return settings;
};

export default useWidgetWrapperSettings;
