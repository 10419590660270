import styled from 'styled-components/macro';
import { Checkbox } from '@fluentui/react-components';

export const FluentCheckbox = styled(Checkbox)`
    label {
        padding: 2px;
    }
    div {
        margin: 6px;
    }
`;
