import { forwardRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Wrapper } from './styles/styles';
import { IProps } from './interfaces';
import useChartProps from './hooks/useChartProps';
import './styles/styles.scss';

const Dynamics = forwardRef<HighchartsReact.RefObject, IProps>((props, ref) => {
    const chartProps = useChartProps(props);

    return (
        <Wrapper customHeight={props.customChartDimensions?.height}>
            <HighchartsReact highcharts={Highcharts} ref={ref} {...chartProps} />
        </Wrapper>
    );
});

export default Dynamics;
