import { orderBy } from 'lodash';
import {
    IMetricResponseItem,
    IPeriodObj,
    IReportingObject,
    TMetricResponse,
} from '../../../../../../../../../General.interfaces';
import { TOrdering } from '../../../../../../../../OrderSwitcher/interfaces';
import { queryClient } from '../../../../../../../../..';
import { TABLE_QUERY_KEY } from '../../DataCell/constants/constants';
import { DS } from '../../../../../../../../../constants/constants';
import getDifferenceBetweenNumbers from '../../../../../../../../../tools/getDifferenceBetweenNumbers';
import { ObjByMeticsTableViewOptionIds } from '../../../../../enums';

interface IArgs {
    reportingObjects: IReportingObject[];
    orderingData: { ordering: TOrdering; columnId: string };
    moduleId: string;
    widgetId: string;
    mode: ObjByMeticsTableViewOptionIds;
}

const sortHandler = (args: IArgs): number[] => {
    const orderingMap = {
        up: 'asc',
        down: 'desc',
    };

    const findPeriodDataInMetricResponse = (args: {
        reportingObjectId: number;
        metric: string;
        response: TMetricResponse[][];
        periodType: string;
        period?: IPeriodObj;
    }): null | IMetricResponseItem => {
        let result: null | IMetricResponseItem = null;
        for (const item of args.response) {
            if (result) break;

            for (const metricResponse of item) {
                if (result) break;

                for (const metricResponseItem of metricResponse) {
                    const { alias, data_objects, metric, time_range } = metricResponseItem.context;

                    var periodCondition = true;

                    if (args.period) {
                        periodCondition =
                            time_range[0] === args.period.dateFrom && time_range[1] === args.period.dateTo;
                    }

                    if (
                        alias?.includes(args.periodType) &&
                        data_objects[0].id === args.reportingObjectId &&
                        metric === args.metric &&
                        periodCondition
                    ) {
                        result = metricResponseItem;
                        break;
                    }
                }
            }
        }

        return result;
    };

    /** Сортировка столбца по имени отчетного объекта*/
    if (args.orderingData.columnId === 'name') {
        return orderBy(
            args.reportingObjects.map((item) => item.id),
            (id) => {
                return args.reportingObjects.find((item) => item.id === id)?.name;
            },
            orderingMap[args.orderingData.ordering],
        );
    }

    const activeQueryData = queryClient
        .getQueriesData({ queryKey: [TABLE_QUERY_KEY, args.moduleId, args.widgetId], type: 'active' })
        .filter((item) => Boolean(item[1]))
        .map((item) => item[1]) as TMetricResponse[][];

    return orderBy(
        args.reportingObjects.map((item) => item.id),
        (id) => {
            const splittedColumnId = args.orderingData.columnId.split(DS);

            const responseItem = findPeriodDataInMetricResponse({
                metric: splittedColumnId[1],
                reportingObjectId: id,
                response: activeQueryData,
                periodType: splittedColumnId[0],
                period: JSON.parse(splittedColumnId[2]),
            });

            if (splittedColumnId[0]?.includes('compare')) {
                const mainResponseItem = findPeriodDataInMetricResponse({
                    metric: splittedColumnId[1],
                    reportingObjectId: id,
                    response: activeQueryData,
                    periodType: 'main',
                });

                if (args.mode === ObjByMeticsTableViewOptionIds.ShowAbsoluteValues) {
                    return Number(responseItem?.items[0].value);
                }

                return Number(
                    getDifferenceBetweenNumbers(
                        Number(mainResponseItem?.items[0]?.value),
                        Number(responseItem?.items[0].value),
                    ).percentDifference,
                );
            }
            return responseItem?.items[0].value;
        },
        orderingMap[args.orderingData.ordering],
    );
};

export default sortHandler;
