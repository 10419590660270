import styled, { css, keyframes } from 'styled-components';

const flashingAnimation = keyframes`
  0%, 5%, 95%, 100% {
    border-color: #ffcdcd;
  }
  10%, 15% {
    border-color: #ff9999; /* Lightest red */
  }
  20%, 25% {
    border-color: #ff8080; /* Even lighter red */
  }
  30%, 35% {
    border-color: #ff6666; /* Lighter red */
  }
  40%, 45% {
    border-color: #ff3333; /* Darker red */
  }
  50%, 55% {
    border-color: #ff4d4d; /* Medium red */
  }
  60%, 65% {
    border-color: #ff6666; /* Lighter red */
  }
  70%, 75% {
    border-color: #ff8080; /* Even lighter red */
  }
  80%, 85% {
    border-color: #ff9999; /* Lightest red */
  }
`;

export const PlateWrapper = styled.div<{ editPanelMode?: boolean }>`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    border: 2px dashed transparent;

    ${(p) => {
        return (
            p.editPanelMode &&
            css`
                animation: ${flashingAnimation} 1s ease infinite;
            `
        );
    }}
`;
