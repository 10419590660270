import { memo, FC, useEffect, useState } from 'react';
import { IProps } from './interfaces';
import { StyledTable, Wrapper } from './styles';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import { TOrdering } from '../../../../../OrderSwitcher/interfaces';

/**
 * Виджет таблицы с данными по метрикам для отчетных объектов
 */
const ReferenceByLocation: FC<IProps> = (props) => {
    const [orderingData, setOrderingData] = useState<null | { ordering: TOrdering; columnId: string }>(null);
    const [selectedReportingObjectId, setSelectedReportingObjectId] = useState<number | null>(null);

    useEffect(() => {
        const ids = props.reportingObjects.map((item) => item.id);
        if (
            (!selectedReportingObjectId && ids.length) ||
            (selectedReportingObjectId && !ids.includes(selectedReportingObjectId))
        ) {
            setSelectedReportingObjectId(ids[0]);
        }
    }, [props.reportingObjects, selectedReportingObjectId]);

    const handleOrderToggle = (data: { ordering: TOrdering; id: string }) => {
        setOrderingData({ ordering: data.ordering, columnId: data.id });
    };

    return (
        <Wrapper>
            <StyledTable>
                <Header
                    {...props}
                    handleOrderToggle={handleOrderToggle}
                    orderingData={orderingData}
                    selectedReportingObjectId={selectedReportingObjectId}
                />
                <Body
                    {...props}
                    orderingData={orderingData}
                    selectedReportingObjectId={selectedReportingObjectId}
                    setSelectedReportingObjectId={setSelectedReportingObjectId}
                />
            </StyledTable>
        </Wrapper>
    );
};

export default memo(ReferenceByLocation);
