import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    z-index: 20;
    position: relative;
`;

export const TableWrapper = styled.div`
    overflow: auto;
    margin-top: var(--space-xxs);
`;

export const Table = styled.table`
    width: 100%;
    height: 100%;
`;
export const Tr = styled.tr`
    /* height: 45px; */
`;

export const Td = styled.td<{ width?: string; minWidth?: string }>`
    position: relative;
    width: ${(p) => (p.width ? p.width : 'min-content')};
    min-width: ${(p) => (p.minWidth ? p.minWidth : 'min-content')};
    vertical-align: bottom;
    font-size: 13px;
    line-height: 13px;
    padding: 15px 0px 5px 0px;
`;
export const Name = styled.div<{ text: string }>`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-weight: 600;
    padding-bottom: 2px;

    &:before {
        content: ${(props) => (props.text ? `"${props.text}"` : '')};
        position: absolute;
        top: -15px;
        left: 0;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const Value = styled.div<{ color?: string }>`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    color: ${(p) => (p.color ? p.color : 'initial')};
    width: 100%;
    padding: 0px var(--space-xxxs);
    white-space: nowrap;
    height: 100%;
    font-weight: bold;
`;

export const Percent = styled.div<{ color: string }>`
    color: ${(p) => p.color};
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding: 0px var(--space-xxxs);
    white-space: nowrap;
    height: 100%;
    font-weight: bold;
`;

export const Rating = styled.div<{ width: number }>`
    width: ${(p) => p.width + '%'};
    height: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-info);
`;

export const RatingWrapper = styled.div`
    background-color: var(--color-secondary);
    border-radius: 5px;
`;
