import { FC } from 'react';
import { IProps } from './interfaces';
import { Wrapper } from './styles';

/**
 * Компонент для отображения "бургера" для открывания/закрывания бокового меню
 */
const Burger: FC<IProps> = ({ sidebarOpen, toggleSidebarOpen }) => {
    return (
        <Wrapper onClick={toggleSidebarOpen} open={sidebarOpen}>
            <div />
            <div />
            <div />
        </Wrapper>
    );
};

export default Burger;
