import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { Reports_Intersections_Widget_Reducer_Values, storeGrid } from '../reducer';
import { ISheetCell } from '../../../../../../General.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { IIntersectionsReportAddFieldOption } from '../constants/intersectionsReportAdditionalFields';
import { AdditionalFieldsIds } from '../../../../interfaces';
import { generalReducerValues } from '../../../../../../General.reducer';
import { DS } from '../../../../../../constants/constants';

interface IExtendedCell {
    fieldId?: AdditionalFieldsIds;
    readOnly: boolean;
    value: string | number;
}

/**
 * Кастомный хук для генерации таблицы
 */
const useGridGenerator = () => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const { extendedAverageIntersectionsData, keyWord } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (extendedAverageIntersectionsData) {
            const additionalFieldsIds: string[] =
                localCurrentOptions?.[`additionalFields${DS}${keyWord}`]?.map(
                    (element: IIntersectionsReportAddFieldOption) => element.id,
                ) || [];

            const grid: ISheetCell[][] = [];

            /** хеддер */
            const header: IExtendedCell[] = [
                { value: t('Base object id'), readOnly: true, fieldId: AdditionalFieldsIds.AddId },
                { value: t('Base object'), readOnly: true },
                {
                    value: t('Intersecting object id'),
                    readOnly: true,
                    fieldId: AdditionalFieldsIds.AddId,
                },
                { value: t('Intersecting object'), readOnly: true },
                { value: `${t('Base to Intersecting')}, %`, readOnly: true },
                { value: `${t('Intersecting to Base')}, %`, readOnly: true },
            ];

            const body: IExtendedCell[][] = [];

            /** тело таблицы */
            extendedAverageIntersectionsData.forEach((intersections) => {
                intersections.forEach((element) => {
                    let flag = true;
                    if (!additionalFieldsIds.includes('showEmpty')) {
                        if (
                            isFinite(Number(element.main_percent || undefined)) &&
                            isFinite(Number(element.contra_percent || undefined))
                        ) {
                            flag = true;
                        } else {
                            flag = false;
                        }
                    }

                    if (flag) {
                        body.push([
                            {
                                value: String(element.mainReportingObjectData?.id),
                                readOnly: false,
                                fieldId: AdditionalFieldsIds.AddId,
                            },
                            { value: element.mainReportingObjectData?.name, readOnly: false },
                            {
                                value: String(element.contraReportingObjectData?.id),
                                readOnly: false,
                                fieldId: AdditionalFieldsIds.AddId,
                            },
                            { value: element.contraReportingObjectData?.name, readOnly: false },
                            {
                                value: valueFormatter({
                                    value: element.main_percent,
                                    units: '%',
                                    numericOutput: true,
                                    showUnits: false,
                                }),
                                readOnly: false,
                            },
                            {
                                value: valueFormatter({
                                    value: element.contra_percent,
                                    units: '%',
                                    numericOutput: true,
                                    showUnits: false,
                                }),
                                readOnly: false,
                            },
                        ]);
                    }
                });
            });

            grid.push(header);
            grid.push(...body);

            /** Удаление невыбранных дополнительных полей */
            grid.forEach((gridRow) => {
                Object.keys(AdditionalFieldsIds).forEach((key) => {
                    const val = AdditionalFieldsIds[key];
                    if (!additionalFieldsIds.includes(val)) {
                        const elementsToDelete = gridRow?.filter((element: IExtendedCell) => element.fieldId === val);

                        elementsToDelete.forEach(() => {
                            const index = gridRow.findIndex((element: IExtendedCell) => element.fieldId === val);
                            if (index > -1) gridRow.splice(index, 1);
                        });
                    }
                });
            });

            dispatch(storeGrid(grid));
        }
    }, [extendedAverageIntersectionsData, t, localCurrentOptions?.[`additionalFields${DS}${keyWord}`]]);
};

export default useGridGenerator;
