import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ hoverColor: string; size: number; disabled: boolean }>`
    display: flex;
    align-items: center;
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'all')};

    svg {
        color: var(--color-secondary-darker);
        width: ${(p) => p.size + 'px'};
        height: ${(p) => p.size + 'px'};

        :hover {
            color: ${(p) => p.hoverColor};
        }
    }
`;
