import React from 'react';

import Logo from './components/Logo';
import { LogoWrapper } from './styles';

const HeaderLogo: React.FC = () => {
    return (
        <LogoWrapper>
            <Logo />
        </LogoWrapper>
    );
};

export default HeaderLogo;
