import { IPeriodObj, TLang } from '../../../../../../General.interfaces';
import { DivWrapperHeaderCellSC, DivWrapperHeaderFullCellSC, DivWrapperSecondTextSC, Th } from '../../../styles';
import { shortStringDate } from '../../../../../../tools/Strings/shortStringDate';
import React from 'react';

export const CellHeaderDelta = (props: {
    s: string,
    s1: string,
    length: number,
    item: { value: number; name: string; period: IPeriodObj },
    lang: TLang
}) => {
    return (
        <Th
        >
            <DivWrapperHeaderCellSC>
                <div>
                    <DivWrapperHeaderFullCellSC>
                        {`${props.s}, ${props.s1}`}
                    </DivWrapperHeaderFullCellSC>

                </div>
                {
                    props.length > 1 && <DivWrapperSecondTextSC>
                        <span>{shortStringDate(props.item.period, props.lang)}</span>
                    </DivWrapperSecondTextSC>
                }


            </DivWrapperHeaderCellSC>

        </Th>
    );
};