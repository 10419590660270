import styled from 'styled-components/macro';

export const CardsWrapper = styled.div`
    width: 100%;
    overflow: auto;
    display: flex;
    margin-top: 15px;
`;

export const CurrentDate = styled.div`
    font-size: 1rem;
    font-weight: 500;
`;
