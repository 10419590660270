import { DS } from '../../../../constants/constants';
import { useMemo } from 'react';
import { IWidget, IWidgetType } from '../../interfaces';

/**
 * Кастомный хук для генерации древовидной структуры виджетов
 */
const useGetAvailableWidgets = (widgetTypes: IWidgetType[]): IWidget[] => {
    const result = useMemo(() => {
        return widgetTypes.reduce((acc, wt) => {
            const children =
                wt?.children?.map((section) => {
                    const type = `${wt.type}${DS}${section.type}`;
                    return {
                        ...section,
                        type,
                        id: `${type}${DS}0`,
                        icon: wt.icon,
                        defaultLayout: wt.defaultLayout,
                        typeTitle: wt.title,
                    };
                }) ?? [];
            return [...acc, ...children];
        }, []);

        //     if (wt.type === 'number') {
        //         const children: IWidgetType[] | undefined = wt?.children?.map((section) => {
        //             let children: IWidgetType[] = [];
        //             const commonVisual = { shortenNumbers: true };
        //             switch (section.type) {
        //                 case 'simple': {
        //                     const type = `${wt.type}${DS}${section.type}`;

        //                     children = [
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}0`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                             },
        //                             visual: { ...commonVisual },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: '',
        //                         },
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}add`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                             },
        //                             visual: { ...commonVisual },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }
        //                 case 'weekDays': {
        //                     const type = `${wt.type}${DS}${section.type}`;
        //                     children = [
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}0`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                             },
        //                             visual: {
        //                                 workingMode: NUMBERS_WORKING_MODE_OPTIONS[0]?.id,
        //                                 selectedDaysOfTheWeek: NUMBERS_DAYS_OF_THE_WEEK_OPTIONS.map((day) => day.id),
        //                                 ...commonVisual,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: '',
        //                         },
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}add`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                             },
        //                             visual: {
        //                                 workingMode: NUMBERS_WORKING_MODE_OPTIONS[0]?.id,
        //                                 selectedDaysOfTheWeek: NUMBERS_DAYS_OF_THE_WEEK_OPTIONS.map((day) => day.id),
        //                                 ...commonVisual,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }

        //                 default:
        //                     children = [];
        //                     break;
        //             }
        //             return {
        //                 type: `${wt.type}${DS}${section.type}`,
        //                 title: section.title,
        //                 children,
        //                 colIndex: section.colIndex,
        //             };
        //         });

        //         return { ...wt, children };
        //     } else if (wt.type === 'dynamics') {
        //         const children: IWidgetType[] | undefined = wt?.children?.map((section) => {
        //             let children: IWidgetType[] = [];
        //             switch (section.type) {
        //                 case 'periods':
        //                 case 'metrics':
        //                 case 'objects': {
        //                     const type = `${wt.type}${DS}${section.type}`;

        //                     let additionalVisual: { [x: string]: unknown } = {};
        //                     let additionalOptions: { [x: string]: unknown } = {};

        //                     switch (section.type) {
        //                         case 'objects':
        //                             additionalVisual = {};
        //                             additionalOptions = {
        //                                 selectedObjectsIdsByPlId: null,
        //                             };
        //                             break;

        //                         case 'metrics':
        //                             additionalOptions = {
        //                                 severalMetrics: null,
        //                             };
        //                             break;

        //                         default:
        //                             break;
        //                     }

        //                     children = [
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}0`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`details`]: null,
        //                                 ...additionalOptions,
        //                             },
        //                             visual: {
        //                                 ...additionalVisual,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: section.title,
        //                         },
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}add`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`details`]: null,
        //                                 ...additionalOptions,
        //                             },
        //                             visual: {
        //                                 ...additionalVisual,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }

        //                 default:
        //                     children = [];
        //                     break;
        //             }
        //             return {
        //                 type: `${wt.type}${DS}${section.type}`,
        //                 title: section.title,
        //                 children,
        //                 colIndex: section.colIndex,
        //             };
        //         });
        //         return { ...wt, children };
        //     } else if (wt.type === 'piechart') {
        //         const children: IWidgetType[] | undefined = wt?.children?.map((section) => {
        //             let children: IWidgetType[] = [];
        //             const type = `${wt.type}${DS}${section.type}`;
        //             switch (section.type) {
        //                 case 'categories': {
        //                     children = [
        //                         {
        //                             ...section,
        //                             type,
        //                             id: `${type}${DS}0`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`mainObject`]: null,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: section.title,
        //                         },
        //                         {
        //                             ...section,
        //                             type,
        //                             id: `${type}${DS}add`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`mainObject`]: null,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }
        //                 case 'distribByCats': {
        //                     children = [
        //                         {
        //                             ...section,
        //                             type,
        //                             id: `${type}${DS}0`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`selectedCategories`]: null,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: section.title,
        //                         },
        //                         {
        //                             ...section,
        //                             type,
        //                             id: `${type}${DS}add`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`selectedCategories`]: null,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }

        //                 default:
        //                     children = [];
        //                     break;
        //             }
        //             return {
        //                 type,
        //                 title: section.title,
        //                 children,
        //                 colIndex: section.colIndex,
        //             };
        //         });
        //         return { ...wt, children };

        //         // const children: IWidgetType[] = piechartTypes.map((piechartType) => {
        //         //     return {
        //         //         type: `${wt.type}${DS}${piechartType.type}`,
        //         //         id: `${wt.type}${DS}${piechartType.type}`,
        //         //         options: {
        //         //             [`metrics`]: null,
        //         //             [`selectedReportingObjectsIds`]: null,
        //         //         },
        //         //         colIndex: piechartType.colIndex,
        //         //         sources: ['default'],
        //         //         title: `Piechart by ${piechartType.title}`,
        //         //     };
        //         // });
        //         // return { ...wt, children };
        //     } else if (wt.type === 'rating') {
        //         const children: IWidgetType[] | undefined = wt?.children?.map((section) => {
        //             let children: IWidgetType[] = [];
        //             switch (section.type) {
        //                 case 'topByMetrics': {
        //                     const type = `${wt.type}${DS}${section.type}`;

        //                     children = [
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}0`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`selectedReportingObjectsType`]: null,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: section.title,
        //                         },
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}add`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                                 [`selectedReportingObjectsType`]: null,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }
        //                 case 'passwaysTraffic': {
        //                     const type = `${wt.type}${DS}${section.type}`;

        //                     children = [
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}0`,
        //                             options: {},
        //                             visual: {
        //                                 passwaysTrafficType: [RATING_PASSWAYS_TRAFFIC_TYPE_OPTIONS[0]],
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: section.title,
        //                         },
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}add`,
        //                             options: {},
        //                             visual: {},
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }

        //                 default:
        //                     children = [];
        //                     break;
        //             }
        //             return {
        //                 type: `${wt.type}${DS}${section.type}`,
        //                 title: section.title,
        //                 children,
        //                 colIndex: section.colIndex,
        //             };
        //         });
        //         return { ...wt, children };
        //     } else if (wt.type === 'heatmap') {
        //         const children: IWidgetType[] | undefined = wt?.children?.map((section) => {
        //             let children: IWidgetType[] = [];
        //             switch (section.type) {
        //                 case 'weekdaysByHours': {
        //                     const type = `${wt.type}${DS}${section.type}`;

        //                     children = [
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}0`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                             },
        //                             visual: {
        //                                 showLabels: true,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                         },
        //                         {
        //                             ...section,
        //                             type: type,
        //                             id: `${type}${DS}add`,
        //                             options: {
        //                                 [`metrics`]: null,
        //                             },
        //                             visual: {
        //                                 showLabels: true,
        //                             },
        //                             sources: ['default'],
        //                             colIndex: 2,
        //                             title: 'Add new',
        //                         },
        //                     ];

        //                     break;
        //                 }

        //                 default:
        //                     children = [];
        //                     break;
        //             }
        //             return {
        //                 type: `${wt.type}${DS}${section.type}`,
        //                 title: section.title,
        //                 children,
        //                 colIndex: section.colIndex,
        //             };
        //         });
        //         return { ...wt, children };
        //     } else {
        //         return { ...wt, children: [] };
        //     }
        // });
    }, [widgetTypes]);

    return result;
};

export default useGetAvailableWidgets;
