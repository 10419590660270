import styled from 'styled-components/macro';
import { TMode, SelectSizeType } from '../interfaces';
import { ArrowSort16Regular } from '@fluentui/react-icons';

export const IconWrapper = styled.span<{ isRtl?: boolean | undefined }>`
    top: 50%;
    margin-top: -5px;
    ${(p) => (p.isRtl ? 'left: 14px;' : 'right: 14px;')}
    //right: 14px;
    position: absolute;
    line-height: 9px;
    color: var(--color-contrast-medium);
`;

export const CollapseWrapper = styled.div<{ selectEntireSection: boolean }>`
    padding-top: ${(p) => (p.selectEntireSection ? '4px' : 0)};
    width: 100%;
`;

export const SelectWrapper = styled.div<{ disabled: boolean; fullWidth: boolean; maxWidth?: number }>`
    position: relative;
    display: inline-block;
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'initial')};
    width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
    max-width: ${(p) => (p.maxWidth ? p.maxWidth + 'px' : 'none')};

    .ms-TooltipHost {
        max-width: ${(p) => (p.maxWidth ? p.maxWidth + 'px' : 'none')};
    }
`;

export const CheckBoxWrapper = styled.div`
    padding-bottom: var(--space-xxxxs);
    display: flex;
    align-items: center;
`;

export const TextWrapper = styled.div<{ isRtl?: boolean | undefined }>`
    ${(p) => (p.isRtl ? 'padding-left: var(--space-xxxs);' : 'padding-right: var(--space-xxxs);')}
    //padding-right: var(--space-xxxs);
    overflow: hidden;
    display: flex;
    span {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
    }
`;

export const OptionWrapper = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: var(--space-xxxxs) var(--space-xs);
    cursor: pointer;
    border: 1px solid transparent;
    background-color: ${(p) => (p.selected ? 'var(--color-primary-lighter)' : 'transparent')};
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    :hover {
        background-color: var(--color-primary-lighter);
        color: white;
    }
`;

export const List = styled.div<{ mode: TMode; maxHeight: number }>`
    max-height: ${(p) => p.maxHeight + 'px'};
    margin-left: ${(p) => (p.mode === 'single' ? '-16px' : '0')};
    overflow: auto;
    div {
        white-space: pre-wrap;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
`;

export const SearchWrapper = styled.div`
    margin-bottom: var(--space-xxs);
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    align-items: center;
`;

export const FilterWrapper = styled.div`
    flex-grow: 1;
`;

export const StDropdown = styled.div<{ width: SelectSizeType }>`
    /* z-index: 100;
    top: 50px;
    /* will-change: transform; */
    margin-top: 0;
    position: relative;
    /* transform: translate(0px, 36px); */
    width: ${(p) => {
        if (p.width === 'sm') {
            return 12.5 + 'rem';
        } else if (p.width === 'md') {
            return 17.5 + 'rem';
        } else if (p.width === 'lg') {
            return 22.5 + 'rem';
        } else if (p.width === 'auto') {
            return 'auto';
        } else {
            return 'auto';
        }
    }};
`;

export const SelectButton = styled.button<{ fullWidth: boolean; isRtl?: boolean | undefined }>`
    ${(p) =>
        p.isRtl
            ? 'padding-left: 26px !important; padding-right: 8px !important;'
            : 'padding-right: 26px !important; padding-left: 8px !important;'}
    width: ${(p) => (p.fullWidth ? '100%' : '100%')};
    border-radius: 10px;
    display: block;
`;

export const calloutStyles = {
    root: {
        borderRadius: '5px',
    },
};

export const SortIcon = styled(ArrowSort16Regular)`
    color: var(--color-secondary-darker);
    cursor: pointer;
    :hover {
        color: var(--color-primary);
    }
`;

export const AdditionalControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const SelectTooltipItem = styled.div``;
