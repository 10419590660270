import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState, TSelectedTab } from './interfaces';

import { TLoadingData, TMetricResponse } from '../../../../../General.interfaces';

const initialState: IReducerState = {
    preparedData: { message: 'Loading', status: 'Loading' },
    allTenantsData: { message: 'Loading', status: 'Loading' },
    selectedTab: 'refGroups',
    tenantId2zoneId: null,
    dataRefetchObject: {},
    showAverageValues: true,
    showReferenceCompareValues: false,
};

export const Leasing_TenantOverview_Reference_Widget_Reducer = createSlice({
    name: 'Leasing_TenantOverview_Reference_Widget_Reducer',
    initialState,

    reducers: {
        storePreraredData: (state, action: PayloadAction<TLoadingData>) => {
            state.preparedData = action.payload;
        },

        storeSelectedTab: (state, action: PayloadAction<TSelectedTab>) => {
            state.selectedTab = action.payload;
        },

        storeAllTenantsData: (
            state,
            action: PayloadAction<TMetricResponse[] | { status: string; message?: string; alias?: string }>,
        ) => {
            state.allTenantsData = action.payload;
        },

        storeTenantId2zoneId: (
            state,
            action: PayloadAction<{ tenantId: number; zoneId: number | undefined }[] | null>,
        ) => {
            state.tenantId2zoneId = action.payload;
        },

        toggleShowAverageValues: (state, action: PayloadAction<boolean>) => {
            state.showAverageValues = action.payload;
        },

        toggleShowReferenceCompareValues: (state, action: PayloadAction<boolean>) => {
            state.showReferenceCompareValues = action.payload;
        },

        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storePreraredData,
    storeAllTenantsData,
    toggleShowAverageValues,
    toggleShowReferenceCompareValues,
    storeTenantId2zoneId,
    storeSelectedTab,
    reloadWidget,
} = Leasing_TenantOverview_Reference_Widget_Reducer.actions;

export const Leasing_TenantOverview_Reference_Widget_Reducer_Values = (state: RootState) =>
    state.Leasing_TenantOverview_Reference_Widget_Reducer;

export default Leasing_TenantOverview_Reference_Widget_Reducer.reducer;
