import styled from 'styled-components/macro';
import { TRowData } from '../../interfaces';

export const TableWrapper = styled.div`
    padding: var(--space-xxs) 0;
    overflow: auto;
    table {
        width: 100%;
        table-layout: auto !important;
    }
`;

const DefaultCell = styled.td`
    max-width: 200px;
    min-width: 100px;
    overflow: auto;
    text-align: right !important;
    > span {
        display: flex !important;
        align-items: center !important;
        vertical-align: middle !important;
        padding: 10px;
        color: var(--color-contrast-higher) !important;
        height: 100% !important;
    }

    input {
        height: 100% !important;
        width: 100% !important;
    }
`;

export const HeaderCell = styled(DefaultCell)`
    font-size: 14px;
    font-weight: 700;
    text-align: center !important;
    span {
        justify-content: center;
    }
`;

export const DataCell = styled(DefaultCell)<{ changedType?: TRowData }>`
    font-size: 12px;
    background: ${(p) => {
        switch (p.changedType) {
            case 'PATCH':
            case 'POST':
                return '#ffff005e !important';

            case 'DELETE':
            case 'ERROR':
                return '#ff000036 !important';

            default:
                return 'white !important';
        }
    }};
    text-align: right;
    span {
        justify-content: flex-end;
    }
    :first-child {
        span {
            justify-content: flex-start !important;
            text-align: left;
        }
    }
`;
