import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import generateMetricsQueryData from '../tools/generateMetricsQueryData';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    Reports_ByTenants_Widget_Reducer_Values,
    resetTenantsWidgetReducer,
    storePreparedGridData,
    storeRawMetricsData,
    storeGrid,
} from '../reducer';
import { DS } from '../../../../../../constants/constants';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const fetchData = useRequestMetrics();

    const { downloadSignalCounter, keyWord } = useSelector(Reports_ByTenants_Widget_Reducer_Values);
    const { token, currentModuleID } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { selectedLocationId } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();


    /** Обнуление редьюсера при выборе другой проектной локации */
    useEffect(() => {
        dispatch(resetTenantsWidgetReducer());
    }, [selectedLocationId]);

    useEffect(() => {
        if (!localCurrentOptions || !downloadSignalCounter) return;
        const rest = `${DS}${keyWord}`;
        dispatch(storePreparedGridData(null));
        dispatch(storeGrid(null));
        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions?.[`selectedReportingObjectsIds${rest}`] &&
            localCurrentOptions?.[`selectedMetrics${rest}`] &&
            localCurrentOptions?.[`mainPeriod${rest}`] &&
            localCurrentOptions?.[`mainDateRanges${rest}`] &&
            localCurrentOptions?.[`detail${rest}`] &&
            token
        ) {
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

            const metricsQueryData = generateMetricsQueryData({
                selectedReportingObjectsIds: localCurrentOptions?.[`selectedReportingObjectsIds${rest}`],
                selectedMetrics: localCurrentOptions?.[`selectedMetrics${rest}`],
                mainDateRanges: localCurrentOptions?.[`mainDateRanges${rest}`],
                mainPeriod: localCurrentOptions?.[`mainPeriod${rest}`],
                token,
                detail: localCurrentOptions?.[`detail${rest}`],
            }).map((element) => ({ ...element, signal }));

            fetchData(metricsQueryData).then((responses: TServerResponse) => {
                /** Обработка ответа для метрик */
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    res: responses,
                    dispatch,
                });
            });
        }
        return () => controller.abort();
    }, [downloadSignalCounter, token]);
};
