import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';
import Spinner from '../../../../components/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';

const Loader = () => {
    const { t } = useTranslation();
    const { domain } = useSelector(generalReducerValues);
    return (
        <Wrapper>
            <Spinner type={domain} label={t('Loading...')} labelPosition={'below'} />
        </Wrapper>
    );
};

export default Loader;
