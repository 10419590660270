import React, { FC } from 'react';
import { IProps } from './interfaces';
import { Th, Tr } from '../../styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import Stack from '../../../../../../../Stack/Stack';
import OrderSwitcher from '../../../../../../../OrderSwitcher/OrderSwitcher';
import { PeriodText, THead, Units } from './styles';
import { shortStringDate } from '../../../../../../../../tools/Strings/shortStringDate';
import { DS } from '../../../../../../../../constants/constants';
import { omit } from 'lodash';
import AverageCell from './AverageCell/AverageCell';
import TotalCell from './TotalCell/TotalCell';

/**
 * Шапка таблицы
 */
const Header: FC<IProps> = (props) => {
    const { allMetrics, lang } = useSelector(generalReducerValues);
    const { t } = useTranslation(['metrics', 'translation']);

    const haveSumAggregatedMetrics = props.metrics.some((metric) => {
        const metricFromStore = allMetrics.find((item) => item.id === metric);
        return metricFromStore?.aggregation_objects === 'sum';
    });

    return (
        <THead>
            <Tr>
                <Th>
                    <Stack alignItems="center" gap={5}>
                        <span>{t('Name')}</span>
                        <OrderSwitcher
                            ordering={props.orderingData?.columnId === 'name' ? props.orderingData.ordering : undefined}
                            handleOrderToggle={props.handleOrderToggle}
                            id={'name'}
                            position="right"
                        />
                    </Stack>
                </Th>
                {props.metrics.map((metric) => {
                    const metricFromStore = allMetrics.find((item) => item.id === metric);
                    return (
                        <Th colSpan={props.comparePeriods.length + 1} key={metric}>
                            <Stack alignItems="center" justifyContent="center">
                                <Stack direction="column" alignItems="center" justifyContent="center">
                                    <span>{t(metricFromStore?.text || metric)}</span>
                                    <Units>{t(metricFromStore?.units || '')}</Units>
                                </Stack>
                            </Stack>
                        </Th>
                    );
                })}
            </Tr>
            <Tr>
                <Th>
                    <Stack alignItems="center" gap={5}>
                        <span>{t('Periods')}</span>
                    </Stack>
                </Th>
                {props.metrics.map((metric) => {
                    return (
                        <React.Fragment key={metric}>
                            {[props.mainPeriod, ...props.comparePeriods].map((period, index) => {
                                const periodType = index === 0 ? 'main' : 'compare';
                                // if (props.mode === ObjByMeticsTableViewOptionIds.ShowRelativeValues && index === 0) {
                                //     return null;
                                // }
                                return (
                                    <Th key={JSON.stringify(period) + metric + index}>
                                        <Stack
                                            key={JSON.stringify(period)}
                                            alignItems={'center'}
                                            justifyContent={'flex-end'}
                                        >
                                            <OrderSwitcher
                                                ordering={
                                                    props.orderingData?.columnId ===
                                                    `${periodType}${DS}${metric}${DS}${JSON.stringify(period)}`
                                                        ? props.orderingData.ordering
                                                        : undefined
                                                }
                                                handleOrderToggle={props.handleOrderToggle}
                                                id={`${periodType}${DS}${metric}${DS}${JSON.stringify(period)}`}
                                                position="left"
                                            />
                                            <PeriodText>{shortStringDate(period, lang)}</PeriodText>
                                        </Stack>
                                    </Th>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </Tr>
            <Tr bgColor={'#fffded'}>
                <Th>
                    <Stack alignItems="center" gap={5}>
                        <span>{t('Average')}</span>
                    </Stack>
                </Th>
                {props.metrics.map((metric) => {
                    return (
                        <React.Fragment key={metric}>
                            {[props.mainPeriod, ...props.comparePeriods].map((period, index) => {
                                return (
                                    <AverageCell
                                        {...omit(props, ['metrics', 'comparePeriods', 'isNetwork'])}
                                        periodType={index === 0 ? 'main' : 'compare'}
                                        period={period}
                                        metric={metric}
                                        key={`${period.dateFrom}${DS}${period.dateTo}${DS}${index}`}
                                    />
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </Tr>
            {haveSumAggregatedMetrics && (
                <Tr bgColor={'#fffded'}>
                    <Th>
                        <Stack alignItems="center" gap={5}>
                            <span>{t('Total')}</span>
                        </Stack>
                    </Th>
                    {props.metrics.map((metric) => {
                        return (
                            <React.Fragment key={metric}>
                                {[props.mainPeriod, ...props.comparePeriods].map((period, index) => {
                                    return (
                                        <TotalCell
                                            {...omit(props, ['metrics', 'comparePeriods', 'isNetwork'])}
                                            periodType={index === 0 ? 'main' : 'compare'}
                                            period={period}
                                            metric={metric}
                                            key={`${period.dateFrom}${DS}${period.dateTo}${DS}${index}`}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </Tr>
            )}
        </THead>
    );
};

export default Header;
