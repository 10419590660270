import { DateTime } from 'luxon';
import {
    IDataObj2ProjectCategory,
    IPeriod,
    IReportingObject,
    ITenant2ZoneRelation,
} from '../../../../../../General.interfaces';
import { ZONES_MIN_CONTENT_PERCENTAGE, ZONES_WORD } from '../../../../../../constants/constants';
import { ITenantsView, ITreeMapData } from '../interfaces';

export interface IArgs {
    reportingObjectsByType: { [type: string]: IReportingObject[] };
    dataObj2ProjectCategoryByObjId: {
        [objId: string]: IDataObj2ProjectCategory[];
    };
    tenantsView: ITenantsView;
    objectId: number;
    parentsById: { [id: string]: ITreeMapData };
    tenant2ZoneByTenantId: {
        [tenantId: string]: ITenant2ZoneRelation[];
    };
    mainDateRanges?: IPeriod[];
    mainPeriod?: IPeriod;
}

/**
 * Получение id родительского элемента, в зависимости от выбранного
 * режима отображения графика
 */
const getParentIdByView = (args: IArgs): null | undefined | string => {
    const {
        reportingObjectsByType,
        tenantsView,
        objectId,
        dataObj2ProjectCategoryByObjId,
        parentsById,
        tenant2ZoneByTenantId,
        mainDateRanges,
        mainPeriod,
    } = args;

    const selectedMainPeriod = mainDateRanges?.find((element) => element.id === mainPeriod?.id)?.period;

    const idsOfParents = Object.keys(parentsById);

    /** Если выбрана одна из категорий */
    if (reportingObjectsByType?.[tenantsView.id]?.[0]?.block_type === 'project_category') {
        return dataObj2ProjectCategoryByObjId[objectId]
            ?.filter((element) => idsOfParents.includes(element.category_id.toString()))?.[0]
            ?.category_id.toString();
    }

    /** Если выбрана группа зон */
    if (reportingObjectsByType?.[tenantsView.id]?.[0]?.object_type.includes(ZONES_WORD)) {
        let parentId = null;
        reportingObjectsByType[tenantsView.id].forEach((parentElement) => {
            const currentTenantRelations = tenant2ZoneByTenantId[objectId];
            if (currentTenantRelations?.length && selectedMainPeriod) {
                const availableRelation = currentTenantRelations.find((relation) => {
                    const relationDateFrom = DateTime.fromISO(relation.date_from).toMillis();
                    const relationDateTo = DateTime.fromISO(relation.date_to).toMillis();

                    const selectedDateFrom = DateTime.fromISO(selectedMainPeriod.dateFrom).toMillis();
                    const selectedPeriodDateTo = DateTime.fromISO(selectedMainPeriod.dateTo).toMillis();
                    return (
                        relation.content_percentage > ZONES_MIN_CONTENT_PERCENTAGE &&
                        relation.tenant_id === objectId &&
                        relation.zone_marker === parentElement.marker &&
                        relationDateFrom <= selectedDateFrom &&
                        relationDateTo >= selectedPeriodDateTo
                    );
                });
                if (availableRelation) {
                    parentId = parentElement.id.toString();
                    return;
                }
            }
        });

        return parentId;
    }
    return null;
};

export default getParentIdByView;
