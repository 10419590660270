import { memo, useCallback, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { emptyArray } from 'src/tools';

import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import { generalReducerValues } from '../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { IReportingObject } from '../../../../General.interfaces';
import { Network_Section_Reducer_Values } from '../../../../Chapters/Network/reducer';
import Stack from '../../../Stack/Stack';
import { queryClient } from '../../../..';
import usePeriods from '../../../../hooks/common/usePeriods';
// import { IProps as IWidgetWrapperProps } from '../../../../Chapters/Dashboards/components/WidgetWrapper/interfaces';
import useGDOSelectedGroup from '../../../../Chapters/Network/tools/useGDOSelectedGroup';
import { useFetchGroupDataObjects } from '../../../../tools/API/hooks/groupDataObjects/useFetchGroupDataObjects';
// import useTranslation from '../../../../hooks/useTranslation/useTranslation';

import Selects from './components/Selects/Selects';
import OnBoardSelects from './components/OnBoardSelects/OnBoardSelects';
import TableObjectsByMetrics from './components/TableObjectsByMetrics/TableObjectsByMetrics';
import { TABLE_QUERY_KEY } from './components/TableObjectsByMetrics/components/DataCell/constants/constants';
import { TABLE_REFERENCE_BY_LOCATION_QUERY_KEY } from './components/ReferenceByLocation/constants/constants';
import ReferenceByLocation from './components/ReferenceByLocation/ReferenceByLocation';
import { ObjByMeticsTableViewOptionIds, ObjByMeticsUseMetricsFromIds } from './enums';
import { ContentWrapper, Wrapper } from './styles/styles';
import { IProps } from './interfaces';
import './styles/styles.scss';
import { DS } from 'src/constants';

const configTypes = ['locationsByMetrics', 'objectsByMetrics', 'referenceByLocation', 'referenceByObject'];
type ConfigTypes = (typeof configTypes)[number];

const componentsMap = {
    locationsByMetrics: TableObjectsByMetrics,
    objectsByMetrics: TableObjectsByMetrics,
    referenceByLocation: ReferenceByLocation,
    referenceByObject: ReferenceByLocation,
};

const Table: FC<IProps> = ({ dashboardKey, moduleId, widgetConfig, updateWidgetTrans, reportingObjects }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(Network_Section_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const { mainPeriod, comparePeriods } = usePeriods(currentModuleID);
    const groupDataObjects = useFetchGroupDataObjects();
    const selectedGroup = useGDOSelectedGroup(groupDataObjects, [
        localCurrentOptions?.['groupObjectsOptions']?.[0]?.id,
    ]);

    const longType = widgetConfig?.type || '';

    const reloadWidget = useCallback(() => {
        switch (true) {
            case longType.includes('locationsByMetrics') || longType.includes('objectsByMetrics'):
                queryClient.invalidateQueries({ queryKey: [TABLE_QUERY_KEY, moduleId, widgetConfig?.id] });
                break;
            case longType.includes('referenceByLocation'):
                queryClient.invalidateQueries({
                    queryKey: [TABLE_REFERENCE_BY_LOCATION_QUERY_KEY, moduleId, widgetConfig?.id],
                });
                break;

            default:
                break;
        }
    }, [longType, moduleId, widgetConfig?.id]);

    const updateWidget = useCallback(
        (key: string, value: { [x: string]: unknown }) => {
            if (widgetConfig && widgetConfig?.id) {
                updateWidgetTrans({
                    moduleId,
                    dashboardKey,
                    data: {
                        widgetId: widgetConfig?.id,
                        key: key,
                        value: { ...(widgetConfig[key] || {}), ...value },
                    },
                });
            }
        },
        [widgetConfig, dashboardKey, moduleId, updateWidgetTrans],
    );

    const getReportingObjectsFuncs = {
        locationsByMetrics: () => reportingObjectsByType?.['location'],
        objectsByMetrics: () => (reportingObjects ? reportingObjects : reportingObjectsByType?.['location']),
        referenceByLocation: () => reportingObjectsByType?.['location'],
        referenceByObject: () => reportingObjects ?? [],
    } as Record<ConfigTypes, () => IReportingObject[]>;

    const widgetId = widgetConfig?.id;

    const metrics =
        widgetConfig?.visual.useMetricsFrom?.[0]?.id === ObjByMeticsUseMetricsFromIds.FromPanel
            ? localCurrentOptions?.selectedMetrics ?? emptyArray
            : widgetConfig?.options.metrics ?? emptyArray;

    const [_, shortType] = longType.split(DS);
    const isMetricType = ['locationsByMetrics', 'objectsByMetrics'].includes(shortType);
    const mode = isMetricType ? (widgetConfig?.visual.tableView?.[0]?.id as ObjByMeticsTableViewOptionIds) : undefined;

    const comparePeriodsOfMetrics = useMemo(
        () => (isMetricType ? comparePeriods.map((item) => item.period) : undefined),
        [isMetricType, comparePeriods],
    );

    const TableComponent = componentsMap[shortType];

    const commonProps = {
        reportingObjects: getReportingObjectsFuncs?.[shortType]?.(),
        metrics,
        mainPeriod: mainPeriod?.period,
        moduleId: currentModuleID,
        widgetId,
        isNetwork: true,
    };

    const titleAdditionalText =
        shortType === 'objectsByMetrics' ? selectedGroup.map((group) => group.group_name).join(', ') : '';

    const tableComponent = shortType ? (
        <Stack direction={'column'} gap={15} styles={{ height: '100%', overflow: 'hidden' }}>
            <ContentWrapper>
                {mainPeriod && commonProps?.reportingObjects?.length && comparePeriods?.length && (
                    <TableComponent comparePeriods={comparePeriodsOfMetrics} mode={mode} {...commonProps} />
                )}
            </ContentWrapper>
        </Stack>
    ) : null;

    return (
        <WidgetWrapper
            titleAdditionalText={titleAdditionalText}
            title={widgetConfig?.title || ''}
            isNoData={false}
            isError={false}
            widgetSelects={
                <Selects
                    moduleId={moduleId}
                    dashboardKey={dashboardKey}
                    widgetConfig={widgetConfig}
                    updateWidget={updateWidget}
                />
            }
            updateWidgetTrans={updateWidgetTrans}
            widgetId={widgetConfig?.id}
            dashboardKey={dashboardKey}
            reloadWidget={reloadWidget}
            isLoading={false}
            moduleId={moduleId}
            titleSize={'normal'}
            wrapperPaddingSize={'normal'}
            widgetOnBoardSelects={
                <OnBoardSelects currentModuleId={moduleId} widgetConfig={widgetConfig} updateWidget={updateWidget} />
            }
        >
            <Wrapper>{tableComponent}</Wrapper>
        </WidgetWrapper>
    );
};

export default memo(Table);
