import { isNumber } from 'lodash';
import { IMetricResponseItem, TMetricResponse } from '../../../../../General.interfaces';
import aggregateMetricResponseByPeriodAndMetric from '../../tools/aggregateMetricResponseByPeriodAndMetric';
import getDifferenceBetweenNumbers from '../../../../../tools/getDifferenceBetweenNumbers';
import { TMetricsDAResponse } from '../interfaces';

/**
 * Функция для подготовки данных с сервера
 * @param response ответ от сервера
 * @returns подготовленные данные
 */
const numbersDefaultDataAdapter = (response: TMetricResponse[]): TMetricsDAResponse => {
    let mainPeriodValue: number | null = null;
    let percentDifference: string | null = null;
    const aggregatedRawData = aggregateMetricResponseByPeriodAndMetric(response);

    const metric: string | undefined = Object.keys(Object.values(aggregatedRawData.mainPeriod)?.[0])?.[0];
    if (metric) {
        const mainPeriodMetricData: undefined | IMetricResponseItem = Object.values(aggregatedRawData.mainPeriod)[0]?.[
            metric
        ]?.[0];
        const comparePeriodMetricData: undefined | IMetricResponseItem = Object.values(
            aggregatedRawData.comparePeriod,
        )[0]?.[metric]?.[0];

        let mainPeriodMetricValue: number | null = null;
        let comparePeriodMetricValue: number | null = null;

        if (mainPeriodMetricData) {
            mainPeriodMetricValue = mainPeriodMetricData.items?.[0]?.value || null;
        }

        if (comparePeriodMetricData) {
            comparePeriodMetricValue = comparePeriodMetricData.items?.[0]?.value || null;
        }

        if (isNumber(mainPeriodMetricValue) && isNumber(comparePeriodMetricValue)) {
            const percentResult = getDifferenceBetweenNumbers(
                mainPeriodMetricValue,
                comparePeriodMetricValue,
            ).percentDifference;

            percentDifference = percentResult;
        }

        mainPeriodValue = mainPeriodMetricValue;
    }
    return { mainPeriodValue, percentDifference };
};

export default numbersDefaultDataAdapter;
