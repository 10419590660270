import { FC, useMemo } from 'react';
import ReactDataSheet from 'react-datasheet';
import { omit } from 'lodash';
import { IRentPayment, IMetricCell, ISummaryCell } from '../../../../interfaces';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { Td, Th, Ul, ValueWrapper } from './styles';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import {
    SUMMARY_CELL_WITH_DATA_COLOR_GREEN,
    SUMMARY_CELL_WITH_DATA_COLOR_YELLOW,
} from '../../../../constants/constants';
import { DateTime } from 'luxon';
import { METRIC_KEYS } from 'src/Chapters/Configuration/SalesMetricsUpload/constants/constants';

const CellRenderer: FC<ReactDataSheet.CellRendererProps<IMetricCell | ISummaryCell>> = (props) => {
    const { t } = useTranslation(['metrics', 'translation']);
    const { allMetrics } = useSelector(generalReducerValues);

    const colorsByChangeType = {
        DELETE: '#ff000036',
        POST: '#ffff005e',
        PATCH: '#ffff005e',
    };

    const timeFreqMap = { D: t('daily'), MS: t('monthly') };

    const getBg = (): string => {
        if (props.cell?.['summaryCellData']?.length) {
            const billsData = props.cell?.['summaryCellData'] as IRentPayment[];
            const tf = billsData[0].time_freq;
            const daysInMonth = DateTime.fromISO(billsData[0].date).daysInMonth;
            if (tf === 'MS' && billsData.length === 1) {
                return SUMMARY_CELL_WITH_DATA_COLOR_GREEN;
            } else {
                return SUMMARY_CELL_WITH_DATA_COLOR_YELLOW;
            }
        } else {
            if (props.cell?.['isValid'] === false && props.cell?.['changeType']) {
                return '#ff990099';
            } else if (props.cell?.['isValid'] === false) {
                return '#ff000036';
            } else if (props.cell?.['changeType']) {
                return colorsByChangeType[props.cell?.['changeType'] || ''];
            } else {
                return 'transparent';
            }
        }
    };

    const modifiedProps = {
        ...omit(props, ['cell', 'updated', 'editing', 'selected', 'attributesRenderer']),
        style: {
            ...(props.style || {}),
            background: getBg(),
        },
    };

    const content = useMemo(() => {
        if (props.cell?.['summaryCellData']?.length) {
            const metric = allMetrics.find((item) => item.id === METRIC_KEYS[0]);
            const sum = props.cell?.['summaryCellData'].reduce((acc: number, item: IRentPayment) => {
                return acc + Number(item.value || 0);
            }, 0);

            return (
                <span>
                    <Ul>
                        <li>{valueFormatter({ value: sum, units: metric?.units })}</li>
                    </Ul>
                </span>
            );
        }
        return null;
    }, [allMetrics, props.cell, t, timeFreqMap]);

    if (props.cell.isHeader) {
        return (
            <Th {...modifiedProps}>
                <span>{t(props.cell.value || '')}</span>
            </Th>
        );
    }

    return (
        <Td {...modifiedProps} isFirstColumn={Boolean(props.cell.isFirstColumn)}>
            {content ? content : props.children}
        </Td>
    );
};

export default CellRenderer;
