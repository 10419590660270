import ReactDataSheet from 'react-datasheet';

import { TableRange } from '../interfaces';
import { TABLE_UNSELECTED } from '../constants';

function isTableRange(input: TableRange | ReactDataSheet.Selection | null | undefined): input is TableRange {
    return input != null && 'row' in input.start && 'col' in input.start;
}

function isReactDataSheetSelection(
    input: TableRange | ReactDataSheet.Selection | null | undefined,
): input is ReactDataSheet.Selection {
    return input != null && 'i' in input.start && 'j' in input.start;
}

export class SelectionRanger {
    private range: TableRange;

    constructor(input: TableRange | ReactDataSheet.Selection | null | undefined) {
        if (isTableRange(input)) {
            this.range = input;
        } else if (isReactDataSheetSelection(input)) {
            this.range = {
                start: { row: input.start.i, col: input.start.j },
                end: { row: input.end.i, col: input.end.j },
            };
        } else {
            this.range = TABLE_UNSELECTED;
        }
    }

    getRowsCount = () => {
        return this.range.end.row - this.range.start.row + 1;
    };

    getColsCount = () => {
        return this.range.end.col - this.range.start.col + 1;
    };

    hasRow = (rowIndex: number) => rowIndex >= this.range.start.row && rowIndex <= this.range.end.row;

    hasCol = (colIndex: number) => colIndex >= this.range.start.col && colIndex <= this.range.end.col;

    checkIsSelected = () => {
        return this.getRowsCount() > 1 || this.getColsCount() > 1;
    };
}
