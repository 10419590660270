import { DateTime } from 'luxon';
import { DS } from '../../../../constants/constants';
import { COLUMNS } from '../constants/constants';
import { IMetricCell, IValidateFooArgs, IValidateFooResult, TOperationType, TValidateFoo } from '../interfaces';

export const isNumbersInCell: TValidateFoo = ({ row }) => {
    if (
        row.every((item) => {
            return item.value ? isFinite(Number(item.value)) : true;
        })
    ) {
        return { isValid: true, text: '' };
    } else {
        const date = row[0].billData?.date;
        const data_object = row[0].billData?.data_object;
        return { isValid: false, text: `Not a number cell`, date, data_object };
    }
};

export const isIntegerBillCount: TValidateFoo = ({ row }) => {
    if (
        row.every((item) => {
            if (item.colId.includes('bill_count') && item.value) {
                const value = Number(item.value);
                return isFinite(value) && Number.isInteger(value) && value >= 0;
            } else {
                return true;
            }
        })
    ) {
        return { isValid: true, text: '' };
    } else {
        const date = row[0].billData?.date;
        const data_object = row[0].billData?.data_object;
        return { isValid: false, text: `Bill count is not integer`, date, data_object };
    }
};

export const isValidDate: TValidateFoo = ({ row }) => {
    const dateCell = row.find((cell) => cell.colId === 'date');

    if (dateCell?.value) {
        if (
            DateTime.fromISO(dateCell.value).isValid ||
            (navigator.language.includes('ru') && DateTime.fromFormat(dateCell.value, 'dd.MM.yyyy').isValid) ||
            (navigator.language.includes('en') && DateTime.fromFormat(dateCell.value, 'MM.dd.yyyy').isValid)
        ) {
            return { isValid: true, text: '' };
        }
    }
    const data_object = row[0].billData?.data_object;
    return { isValid: false, text: `Invalid date format`, date: dateCell?.value || '', data_object };
};

export const isValidObjectId: TValidateFoo = ({ row, reportingObjectsById }) => {
    const objIdCell = row.find((cell) => cell.colId === 'data_object');
    const objId = objIdCell?.value !== null ? Number(objIdCell?.value) : null;

    if (objId !== null && isFinite(objId) && reportingObjectsById[objId]?.object_type === 'tenant') {
        return { isValid: true, text: '' };
    }
    const date = row[0].billData?.date;
    const data_object = row[0].billData?.data_object;
    return { isValid: false, text: `Invalid tenant id`, date, data_object };
};

export const isValidColumnsNumber: TValidateFoo = ({ row }) => {
    if (row.length === COLUMNS.length) {
        return { isValid: true, text: '' };
    }
    return { isValid: false, text: `Invalid columns number`, date: '' };
};

export const isSalesBillCountMoreThanOne: TValidateFoo = ({ row }) => {
    return isBillCountMoreThanOne(row, 'income');
};

export const isReturnsBillCountMoreThanOne: TValidateFoo = ({ row }) => {
    return isBillCountMoreThanOne(row, 'income_return');
};

export const isValidSalesNoVatToVatRatio: TValidateFoo = ({ row }) => {
    return isValidNoVatToVatRatio(row, 'income');
};

export const isValidReturnsNoVatToVatRatio: TValidateFoo = ({ row }) => {
    return isValidNoVatToVatRatio(row, 'income_return');
};

export const isNonZeroVatIncome: TValidateFoo = ({ row }) => {
    return isNonZeroVat(row, 'income');
};

export const isNonZeroVatReturn: TValidateFoo = ({ row }) => {
    return isNonZeroVat(row, 'income_return');
};

export const isBillFilledIncome: TValidateFoo = ({ row }) => {
    return isBillFilled(row, 'income');
};

export const isBillFilledReturn: TValidateFoo = ({ row }) => {
    return isBillFilled(row, 'income_return');
};

const isBillCountMoreThanOne = (row: IMetricCell[], operation_type: TOperationType): IValidateFooResult => {
    const vat = row.find((item) => item.colId === `${operation_type}${DS}sum_vat`)?.value;
    const billsValue = row.find((item) => item.colId === `${operation_type}${DS}bill_count`)?.value;

    if (vat && (!billsValue || Number(billsValue) < 1)) {
        const date = row[0].billData?.date;
        const data_object = row[0].billData?.data_object;
        return { isValid: false, text: `Bill count is not correct`, date, data_object };
    } else {
        return { isValid: true, text: '' };
    }
};
const isBillFilled = (row: IMetricCell[], operation_type: TOperationType): IValidateFooResult => {
    const vat = row.find((item) => item.colId === `${operation_type}${DS}sum_vat`)?.value;
    const noVat = row.find((item) => item.colId === `${operation_type}${DS}sum_novat`)?.value;
    const billsValue = row.find((item) => item.colId === `${operation_type}${DS}bill_count`)?.value;

    if ([vat, noVat, billsValue].some((value) => value) && ![vat, noVat, billsValue].every((value) => value)) {
        const date = row[0].billData?.date;
        const data_object = row[0].billData?.data_object;
        return { isValid: false, text: `All fields of bill should be filled`, date, data_object };
    } else {
        return { isValid: true, text: '' };
    }
};

const isValidNoVatToVatRatio = (row: IMetricCell[], operation_type: TOperationType): IValidateFooResult => {
    const vat = row.find((item) => item.colId === `${operation_type}${DS}sum_vat`)?.value;
    const noVat = row.find((item) => item.colId === `${operation_type}${DS}sum_novat`)?.value;

    if (vat && Number(vat) > 0 && (!noVat || Number(noVat) / Number(vat) < 0.5 || Number(noVat) / Number(vat) > 1)) {
        const date = row[0].billData?.date;
        const data_object = row[0].billData?.data_object;
        return { isValid: false, text: `NoVat/Vat ratio is not correct`, date, data_object };
    } else {
        return { isValid: true, text: '' };
    }
};

const isNonZeroVat = (row: IMetricCell[], operation_type: TOperationType): IValidateFooResult => {
    const vat = row.find((item) => item.colId === `${operation_type}${DS}sum_vat`)?.value;
    const noVat = row.find((item) => item.colId === `${operation_type}${DS}sum_novat`)?.value;

    if (vat && Number(vat) === 0 && (!noVat || Number(noVat) !== 0)) {
        const date = row[0].billData?.date;
        const data_object = row[0].billData?.data_object;
        return { isValid: false, text: `NoVat value should be zero with zero Vat value`, date, data_object };
    } else {
        return { isValid: true, text: '' };
    }
};
