import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { storeMainAreaSize } from '../General.reducer';

/**
 * Кастомный хук для получения текущего разрешения браузера
 */
export const useWindowDimensions = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        function handleResize() {
            timeoutId && clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                dispatch(storeMainAreaSize({ width: window.innerWidth, height: window.innerHeight }));
            }, 500);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
};
