import { IPeriod } from '../../../../../../General.interfaces';
import { getHeaders } from '../../../../../../tools/API/getHeaders';
import { IRequestAverageIntersectionsArgs } from '../../../../../../tools/API/interfaces';
import { IExtendedReportingObject } from '../interfaces';

interface IArgs {
    extendedReportingObjectsById: {
        [id: string]: IExtendedReportingObject;
    };
    mainDateRanges: IPeriod[];
    mainPeriod: IPeriod;
    token: string;
}

/**
 * Функция генерации квери параметров для запроса пересечений
 * @param extendedReportingObjectsById Расширенный объект с отчетными объектами
 * @param mainDateRanges периоды
 * @param mainPeriod основной период
 * @param token токен авторизации
 */
const generateIntersectionsQueryData = (args: IArgs): IRequestAverageIntersectionsArgs[] => {
    const { extendedReportingObjectsById, mainPeriod, mainDateRanges, token } = args;
    const period = mainDateRanges?.find((element) => element.id === mainPeriod.id)?.period;
    const queryData: IRequestAverageIntersectionsArgs[] = [];
    if (period) {
        Object.keys(extendedReportingObjectsById).forEach((id) => {
            const extendedReportingObject = extendedReportingObjectsById[id];

            if (
                extendedReportingObject.reportingObject.external_ids?.find(
                    (element) => element.data_source === 'shopster',
                )
            ) {
                queryData.push({
                    token,
                    main_obj_id: extendedReportingObject.reportingObject.id,
                    contra_obj_ids: extendedReportingObject.contraReportingObjects
                        ?.filter((element) =>
                            element.reportingObject.external_ids?.find((element) => element.data_source === 'shopster'),
                        )
                        .map((element) => element.reportingObject.id),
                    time_range: [period.dateFrom, period.dateTo],
                });
            }
        });
    }
    return queryData;
};

export default generateIntersectionsQueryData;
