import { FC } from 'react';
import { IProps } from './interfaces';
import Stack from '../../../../components/Stack/Stack';
import { isNull } from 'lodash';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import IconButton from '../../../../components/IconButton/IconButton';
import { ArrowDownload24Filled, Eye24Filled } from '@fluentui/react-icons';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';

/**
 * Отображение ячейки "output" для "background reports"
 */
const BgReportOutput: FC<IProps> = ({ files, output, status, openFilesModal, openOutputModal }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const sendAnalytics = useSendSimpleAnalytics();
    const { t } = useTranslation();

    /**
     * Загрузка файлов, путем создания "a" и события "click" на них
     */
    const onFilesDownload = () => {
        if (files.length === 1) {
            const link = document.createElement('a');
            link.href = files[0];
            document.body.appendChild(link);
            link.click();
            link.remove();
            sendAnalytics(`report_DetailedByBills_downloaded`, 'reachGoal', currentModuleID);
        } else {
            openFilesModal(files);
        }
    };

    if (status !== 'success') return null;

    return (
        <Stack alignItems="center">
            {!isNull(output) && (
                <Tooltip content={t('Show result')}>
                    <IconButton icon={<Eye24Filled />} onClick={() => openOutputModal(output)} size={32} />
                </Tooltip>
            )}
            {Boolean(files?.length) && (
                <Tooltip content={t('Download')}>
                    <IconButton icon={<ArrowDownload24Filled />} size={32} onClick={onFilesDownload} />
                </Tooltip>
            )}
        </Stack>
    );
};

export default BgReportOutput;
