import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { ZONES_WORD } from '../../constants/constants';
import { objectsOrder } from '../../constants/objectsOrder';
import { ICategory, IDataObj2ProjectCategory, IProjectCategory, IReportingObject } from '../../General.interfaces';
import { RootState } from '../../store';
import { ILevensteinById, IState, IStoreAllObjectsArgs } from './interfaces';
import { IEvent } from '../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { intersection, keyBy } from 'lodash';
import { modifyCategories } from '../../tools/modifyCategories';

var initialState: IState = {
    allStructuresFetching: false,
    allStructuresFetchingError: false,
    src: {
        reportingObjects: [],
        categories: [],
        objects2Categories: [],
        events: [],
    },
    cfg: {
        reportingObjectsById: {},
        reportingObjectsByType: {},
        levensteinByObjId: {},
        levensteinByObjType: {},
        dataObj2ProjectCategoryByObjId: {},
        dataObj2ProjectCategoryByCatId: {},
    },
    selectedLocationObjsIds: [],
};

var Network_Section_Reducer = createSlice({
    name: 'Network_Section_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение объектов для выбранных локаций
         */
        storeAllObjects: (state, action: PayloadAction<IStoreAllObjectsArgs>) => {
            let { reportingObjects } = action.payload;
            var { src, cfg } = cloneDeep(state);

            reportingObjects = reportingObjects.map((item) => {
                if (item.object_type === 'zone') {
                    return {
                        ...item,
                        order: objectsOrder['zone'],
                        object_type: `${ZONES_WORD}: ${item.object_params.group_marker}`,
                        block_type: 'reporting_object',
                    };
                } else {
                    return {
                        ...item,
                        order: objectsOrder[item.object_type] || 1000,
                        block_type: 'reporting_object',
                    };
                }
            });

            state.src = {
                ...src,
                reportingObjects,
            };

            var reportingObjectsById = {};
            var reportingObjectsByType = {};

            reportingObjects.forEach((element) => {
                reportingObjectsById[element.id] = element;

                if (element.block_type !== 'category') {
                    if (reportingObjectsByType[element.object_type]) {
                        reportingObjectsByType[element.object_type].push(element);
                    } else {
                        reportingObjectsByType[element.object_type] = [element];
                    }
                }
            });

            var selectedLocationObjsIds = reportingObjectsByType['location']?.map(
                (element: IReportingObject) => element.id,
            );

            state.cfg = {
                ...cfg,
                reportingObjectsById,
                reportingObjectsByType,
            };

            state.selectedLocationObjsIds = selectedLocationObjsIds;
        },

        storeAllEvents: (state, action: PayloadAction<(IEvent & { plId: number })[]>) => {
            var { src } = cloneDeep(state);
            state.src = {
                ...src,
                events: action.payload,
            };
        },

        storeAllCategories: (state, action: PayloadAction<ICategory[]>) => {
            var { src } = cloneDeep(state);
            state.src = {
                ...src,
                categories: action.payload,
            };
        },

        storeAllLevenstein: (state, action: PayloadAction<ILevensteinById>) => {
            var { cfg } = cloneDeep(state);
            var levensteinByObjType: { [x: string]: ILevensteinById } = {};

            Object.values(action.payload).forEach((item) => {
                if (levensteinByObjType[item.mainObject.object_type]) {
                    levensteinByObjType[item.mainObject.object_type] = {
                        ...levensteinByObjType[item.mainObject.object_type],
                        [item.mainObject.id]: item,
                    };
                } else {
                    levensteinByObjType[item.mainObject.object_type] = {
                        [item.mainObject.id]: item,
                    };
                }
            });

            state.cfg = {
                ...cfg,
                levensteinByObjId: action.payload,
                levensteinByObjType,
            };
        },

        storeAllObjects2Categories: (state, action: PayloadAction<IDataObj2ProjectCategory[]>) => {
            const dataObj2ProjectCategoryByObjId = {};
            const dataObj2ProjectCategoryByCatId = {};
            const projectCategories = cloneDeep(state.src.categories) as IProjectCategory[];

            action.payload.forEach((element) => {
                if (dataObj2ProjectCategoryByObjId[element.data_object_id]) {
                    dataObj2ProjectCategoryByObjId[element.data_object_id].push(element);
                } else {
                    dataObj2ProjectCategoryByObjId[element.data_object_id] = [element];
                }

                if (dataObj2ProjectCategoryByCatId[element.category_id]) {
                    dataObj2ProjectCategoryByCatId[element.category_id].push(element);
                } else {
                    dataObj2ProjectCategoryByCatId[element.category_id] = [element];
                }
            });

            // Создаем массив ID категорий, имеющих объекты

            const filledCategoriesIds = Object.keys(dataObj2ProjectCategoryByCatId).map((key) => Number(key));

            const modProjectCategories = modifyCategories(projectCategories, filledCategoriesIds);
            // console.log(modProjectCategories);

            const reportingObjectsByType = {};


            state.src.objects2Categories = action.payload;

            state.cfg.dataObj2ProjectCategoryByObjId = dataObj2ProjectCategoryByObjId;
            state.cfg.dataObj2ProjectCategoryByCatId = dataObj2ProjectCategoryByCatId;
        },

        /**
         * Изменение флага загрузки структур по всем выбранным локациям
         */
        toggleAllStructuresFetching: (state, action: PayloadAction<boolean>) => {
            state.allStructuresFetching = action.payload;
        },

        /**
         * Изменение флага ошибки загрузки структур по всем выбранным локациям
         */
        toggleAllStructuresFetchingError: (state, action: PayloadAction<boolean>) => {
            state.allStructuresFetchingError = action.payload;
        },
    },
});

export var {
    storeAllObjects,
    storeAllCategories,
    storeAllLevenstein,
    storeAllEvents,
    storeAllObjects2Categories,
    toggleAllStructuresFetching,
    toggleAllStructuresFetchingError,
} = Network_Section_Reducer.actions;

export var Network_Section_Reducer_Values = (state: RootState) => state.Network_Section_Reducer;

export default Network_Section_Reducer.reducer;
