import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../store';

import { IReducerState } from './interfaces';

const initialState: IReducerState = {
    activeTenantIds: null,
    selectedTenantsRanges: null,

    activePosOfTenantsIds: null,
    selectedPosOfTenantsRanges: null,
};

const Sales_PosAnalysis_Module_Reducer = createSlice({
    name: 'Sales_PosAnalysis_Module_Reducer',
    initialState,
    reducers: {
        storeActiveTenantId: (state, action: PayloadAction<null | number[]>) => {
            state.activeTenantIds = action.payload;
        },
        storeSelectedTenantsRanges: (state, action: PayloadAction<null | number[]>) => {
            state.selectedTenantsRanges = action.payload;
        },

        storeActivePosOfTenantsIds: (state, action: PayloadAction<null | number[]>) => {
            state.activePosOfTenantsIds = action.payload;
        },
        storeSelectedPosOfTenantsRanges: (state, action: PayloadAction<null | number[]>) => {
            state.selectedPosOfTenantsRanges = action.payload;
        },
    },
});

export const {
    storeActiveTenantId,
    storeSelectedTenantsRanges,
    storeActivePosOfTenantsIds,
    storeSelectedPosOfTenantsRanges,
} = Sales_PosAnalysis_Module_Reducer.actions;

export const Sales_PosAnalysis_Module_Reducer_Values = (state: RootState) => state.Sales_PosAnalysis_Module_Reducer;

export default Sales_PosAnalysis_Module_Reducer.reducer;
