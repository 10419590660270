import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isMobileInboundWidth } from 'src/theme';

import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../reducer';
import { stringDate } from '../../../../../tools/Strings/stringDate';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { ISelectedOption } from '../../../../../components/Selects/Select/interfaces';

import { IntersectionsWithTenantsContext } from './context';
import { CardsWrapper, CurrentDate } from './styles';
import Footer from './components/Footer/Footer';
import {
    Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer_Values,
    reloadWidget,
    storeSelectedReportingObjectsType,
    storeTableItemsToShow,
} from './reducer';
import Card from './components/Card/Card';
/**
 * Компонент для отображения виджета Leasing:Cross-visits
 */
const IntersectionsWithTenants = () => {
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        selectedReportingObjectsType,
        extendedReportingObjectsById,
        intersectionsDataById,
        tableItemsToShow,
        daysInPeriod,
        widgetName,
        isLoading,
        isError,
    } = useSelector(Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer_Values);
    const {
        lang,
        cfg: { reportingObjectsById, tenant2FloorByTenantId, tenant2ZoneByTenantId, reportingObjectsByType },
        src: { dataObj2ProjectCategory },
        mainAreaSize: { width },
        sendYaAnalytics,
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);

    const isMobile = isMobileInboundWidth(width);

    const storeSelectedReportingObjectsTypeTrans = (value: ISelectedOption) => {
        dispatch(storeSelectedReportingObjectsType(value));
    };

    const storeTableItemsToShowTrans = (value: number | 'all') => {
        dispatch(storeTableItemsToShow(value));
    };

    const cards = useMemo(() => {
        if (extendedReportingObjectsById) {
            return Object.keys(extendedReportingObjectsById).map((key) => {
                const extendedReportingObject = extendedReportingObjectsById[key];

                return (
                    <Card
                        intersectionsDataById={
                            intersectionsDataById
                                ? intersectionsDataById[extendedReportingObject.reportingObject.id]
                                : null
                        }
                        extendedReportingObject={extendedReportingObject}
                        key={extendedReportingObject.reportingObject.id}
                    />
                );
            });
        }
        return null;
    }, [intersectionsDataById, daysInPeriod, extendedReportingObjectsById]);

    const currentPeriod = useMemo(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges) {
            const period = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions?.mainPeriod?.id,
            )?.period;
            if (period) {
                return stringDate(period, lang, '', 'dd MMM yyyy');
            }
        }
        return '';
    }, [localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges, lang]);

    const context = {
        mainDateRanges: localCurrentOptions?.mainDateRanges,
        mainPeriod: localCurrentOptions?.mainPeriod,
        filters: localCurrentOptions?.['filters'],
        extendedReportingObjectsById,
        selectedReportingObjectsType,
        dataObj2ProjectCategory,
        reportingObjectsByType,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        sendYaAnalytics,
        tableItemsToShow,
        daysInPeriod,
        moduleName,
        widgetName,
        isLoading,
        isMobile,
        isError,
        storeSelectedReportingObjectsTypeTrans,
        storeTableItemsToShowTrans,
    };

    return (
        <IntersectionsWithTenantsContext.Provider value={context}>
            <WidgetWrapper ref={ref}>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t(widgetName)}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref }}
                            reloadHandler={reloadWidget}
                            widgetName={widgetName}
                        />
                    </WidgetTitle>
                </WidgetTitleWrapper>
                <CurrentDate>{currentPeriod}</CurrentDate>
                <CardsWrapper>{cards}</CardsWrapper>

                {Boolean(extendedReportingObjectsById) && <Footer testId={widgetName} />}
            </WidgetWrapper>
        </IntersectionsWithTenantsContext.Provider>
    );
};

export default IntersectionsWithTenants;
