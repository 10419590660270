import React, { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import { AnimationWrapper, FeadbackWrapper, Smiles, Title } from './styles';
import Lottie from 'lottie-react';
import smileBad from './accets/bad.json';
import smileNormal from './accets/normal.json';
import smileGood from './accets/good.json';
import smileGreat from './accets/great.json';

interface Props {
    children?: ReactNode;
    styles?: React.CSSProperties;
    onClick: (rating: string) => void;
}

export type Ref = HTMLDivElement;

const Feadback = forwardRef<Ref, Props>((props, ref) => {
    const { isRtlLanguage } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const onSmileClick = (rating: string) => () => {
        props.onClick(rating);
    };
    return (
        <FeadbackWrapper ref={ref} isRtlLanguage={isRtlLanguage} style={{ ...props.styles }}>
            <Title>{t('Please rate the widget')}</Title>
            <Smiles>
                <AnimationWrapper onClick={onSmileClick('bad')}>
                    <Lottie animationData={smileBad} />
                </AnimationWrapper>
                <AnimationWrapper onClick={onSmileClick('normal')}>
                    <Lottie animationData={smileNormal} />
                </AnimationWrapper>
                <AnimationWrapper onClick={onSmileClick('good')}>
                    <Lottie animationData={smileGood} />
                </AnimationWrapper>
                <AnimationWrapper onClick={onSmileClick('great')}>
                    <Lottie animationData={smileGreat} />
                </AnimationWrapper>
            </Smiles>
        </FeadbackWrapper>
    );
});

export default Feadback;
