import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { patchUserDashboards } from '../../../Dashboards.reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import Stack from '../../Stack/Stack';
import Button from '../../Button/Button';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import TextInput from '../../TextInput/TextInput';
import { useAppDispatch } from '../../../hooks/useSettings';
import { IDashboard } from '../interfaces';
import generateId from '../../../tools/generateId';
import Tooltip from '../../Tooltip/Tooltip';
import IconButton from '../../IconButton/IconButton';
import { Delete28Filled } from '@fluentui/react-icons';
import { differenceBy } from 'lodash';
import Modals from '../../Modals/Modals';
import DeleteBody from '../../../Chapters/Dashboards/components/DeleteBody/DeleteBody';

const EditBody: React.FC<IProps> = ({ closeModal, userDashboards }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const [dataToEdit, setDataToEdit] = useState<IDashboard[]>([]);

    useEffect(() => {
        setDataToEdit(
            userDashboards
                .filter((item) => item.module_id === currentModuleID)
                .filter((item) => !item.dashboard_key.includes('default'))
                .filter((item) => !item.dashboard_key.includes('project')),
        );
    }, [currentModuleID, userDashboards]);

    const [deleteModalStatus, setDeleteModalStatus] = useState<{
        show: boolean;
        id?: number | undefined;
        name?: string;
    }>({ show: false });

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onCancelClick = () => {
        closeModal();
    };

    const onApplyClick = () => {
        const changedDashboards = differenceBy(
            dataToEdit,
            userDashboards
                .filter((item) => item.module_id === currentModuleID)
                .filter((item) => !item.dashboard_key.includes('default_fsf')),
            'dashboard_name',
        );
        dispatch(patchUserDashboards({ dashboards: changedDashboards, makeGet: true }));
        closeModal();
    };

    const onDeleteClick =
        ({ id, name }: { id: number | undefined; name: string }) =>
        () => {
            setDeleteModalStatus({ show: true, id, name });
        };

    const handleChange = (id: number | undefined) => (args: { value: string }) => {
        args.value &&
            setDataToEdit(
                dataToEdit.map((item) => {
                    if (item?.id !== id) {
                        return item;
                    } else {
                        return { ...item, dashboard_name: args.value };
                    }
                }),
            );
    };

    const handleDeleteModalClose = () => {
        setDeleteModalStatus({ show: false });
    };

    const dashboardsToEdit = useMemo(() => {
        return dataToEdit.length ? (
            dataToEdit.map((item) => {
                return (
                    <Stack alignItems="center" justifyContent="flex-end" gap={10} key={item.dashboard_key}>
                        <TextInput
                            fullWidth
                            placeholder={t('Type custom name')}
                            handleChange={handleChange(item.id)}
                            outsideValue={item.dashboard_name}
                            hasError={item.dashboard_name.length < 2}
                        />
                        <Tooltip content={t('Delete dashboard')}>
                            <IconButton
                                hoverColor="var(--color-accent)"
                                onClick={onDeleteClick({ id: item.id, name: item.dashboard_name })}
                                icon={<Delete28Filled />}
                            />
                        </Tooltip>
                    </Stack>
                );
            })
        ) : (
            <div>{t('There are no editable panels.')}</div>
        );
    }, [dataToEdit, handleChange, t]);

    return (
        <Wrapper>
            {dashboardsToEdit}
            <Modals
                title={`${t('Delete dashboard')} ${deleteModalStatus.name}`}
                closeModal={handleDeleteModalClose}
                modalStatus={deleteModalStatus}
                topColor={'var(--color-accent)'}
            >
                <DeleteBody closeModal={handleDeleteModalClose} id={deleteModalStatus.id} />
            </Modals>
            <Stack alignItems="center" justifyContent="flex-end" gap={10}>
                <Button onClick={onCancelClick}>{t('Cancel')}</Button>
                <Button
                    appearance="primary"
                    onClick={onApplyClick}
                    disabled={dataToEdit.map((item) => item.dashboard_name).some((name) => name.length < 2)}
                >
                    {t('Apply')}
                </Button>
            </Stack>
        </Wrapper>
    );
};

export default EditBody;
