import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import { IReportingObject } from '../../../../../../../../../General.interfaces';
import { colors } from '../../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { cloneDeep } from 'lodash';
import {
    BodyIntersectionWrapper,
    RowReportingObjectName,
    BodyDefaultText,
    PaiwiseValue,
    PercentValue,
    StyledIcon,
    BodyTd,
    BodyTr,
} from './styles';
import { FC } from 'react';

/**
 * Компонент для отображения тела таблицы
 * @param storeSelectedReportingObjectsTrans Функция для записи выбранных отчетных объектов
 * @param extendedReportingObjectsById Объект с расширенными выбранными отчетными объектми
 * @param selectedReportingObjects выбранные отчетные объекты
 * @param sortedReportingObjects Отсортированные отчетные объекты
 * @param intersectionsDataById Данные по пересечениям
 * @param tableItemsToShow Кол-во отображаемых элементов в таблице
 * @param isLoading Флаг загрузки
 * @param isError Флаг ошибки
 * @param days количество дней в выбранном периоде
 */
const Body: FC<IProps> = ({
    storeSelectedReportingObjectsTrans,
    extendedReportingObjectsById,
    selectedReportingObjects,
    sortedReportingObjects,
    intersectionsDataById,
    tableItemsToShow,
    isLoading,
    isError,
    days,
}) => {
    const { t } = useTranslation();

    const onRowClick = (reportingObject: IReportingObject) => () => {
        const copy = cloneDeep(selectedReportingObjects);
        const index = copy.findIndex((element) => element.reportingObject.id === reportingObject.id);

        if (index > -1) {
            copy.splice(index, 1);
            storeSelectedReportingObjectsTrans(copy);
        } else {
            const allColors = colors.map((element) => element.selectedColor);
            const selectedColors = copy.map((element) => element.color);
            const availableColors = allColors?.filter((color) => !selectedColors.includes(color));
            if (availableColors.length) {
                copy.push({ reportingObject, color: availableColors[0] });
                storeSelectedReportingObjectsTrans(copy);
            }
        }
    };

    return (
        <tbody>
            {sortedReportingObjects
                .slice(0, tableItemsToShow === 'all' ? sortedReportingObjects.length : tableItemsToShow)
                .map((reportingObject) => {
                    const selectedColor =
                        selectedReportingObjects?.find(
                            (selectedObject) => selectedObject.reportingObject.id === reportingObject.id,
                        )?.color || null;

                    return (
                        <BodyTr
                            onClick={onRowClick(reportingObject)}
                            selectedColor={selectedColor}
                            key={reportingObject.id}
                        >
                            <BodyTd>
                                <RowReportingObjectName>
                                    <div>{reportingObject.name}</div>
                                    {extendedReportingObjectsById[reportingObject.id] && (
                                        <StyledIcon color={extendedReportingObjectsById[reportingObject.id].color} />
                                    )}
                                </RowReportingObjectName>
                            </BodyTd>
                            <BodyTd>
                                {isLoading ? (
                                    <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                                ) : isError ? (
                                    <BodyDefaultText>{t('Some error')}</BodyDefaultText>
                                ) : (
                                    <BodyIntersectionWrapper>
                                        <PercentValue>
                                            {valueFormatter({
                                                units: '%',
                                                value:
                                                    intersectionsDataById?.[reportingObject?.id]?.percentIntersection! /
                                                    days,
                                            })}
                                        </PercentValue>
                                        <PaiwiseValue>
                                            {valueFormatter({
                                                precision: 0,
                                                value:
                                                    intersectionsDataById?.[reportingObject?.id]?.intersection! / days,
                                            })}
                                        </PaiwiseValue>
                                    </BodyIntersectionWrapper>
                                )}
                            </BodyTd>
                            <BodyTd>
                                {isLoading ? (
                                    <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                                ) : isError ? (
                                    <BodyDefaultText>{t('Some error')}</BodyDefaultText>
                                ) : (
                                    <BodyIntersectionWrapper>
                                        <PercentValue>
                                            {valueFormatter({
                                                units: '%',
                                                value: intersectionsDataById?.[reportingObject?.id]
                                                    ?.percentIntersection,
                                            })}
                                        </PercentValue>
                                        <PaiwiseValue>
                                            {valueFormatter({
                                                value: String(
                                                    intersectionsDataById?.[reportingObject.id]?.intersection!.toFixed(
                                                        0,
                                                    ),
                                                ),
                                            })}
                                        </PaiwiseValue>
                                    </BodyIntersectionWrapper>
                                )}
                            </BodyTd>
                        </BodyTr>
                    );
                })}
        </tbody>
    );
};

export default Body;
