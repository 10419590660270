import { FC, useMemo } from 'react';
import { IDefaultSelectProps } from '../../interfaces';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import getChartDataDetailing from '../../../../../../constants/chartDetailing';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { detailOptions } from '../../../../../../constants/detailOptions';
import { IChangeOptionsArgs } from '../../../../../UserSettings/interfaces';
import DefaultDetailsSelect from '../../../../../Selects/DetailSelect/DetailSelect';

const DetailSelect: FC<IDefaultSelectProps> = (props) => {
    const localCurrentOptions = useWidgetCurrentOptions(props.moduleId);
    const { t } = useTranslation();

    /** Запрещенная детализация */
    const restrictedDetailSelectOptions = useMemo(() => {
        if (localCurrentOptions?.mainDateRanges?.length && localCurrentOptions?.mainPeriod) {
            const availableDetailingIds = getChartDataDetailing(
                t,
                localCurrentOptions?.mainDateRanges || [],
                localCurrentOptions?.mainPeriod,
            ).map((detail) => detail.id);
            return detailOptions
                .filter((detail) => !availableDetailingIds.includes(detail.id))
                .map((detail) => detail.id);
        }
        return null;
    }, [localCurrentOptions?.mainDateRanges, localCurrentOptions?.mainPeriod, t]);

    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        props.updateWidget('options', args.newOptions);
    };

    return (
        <DefaultDetailsSelect
            name={'details'}
            restrictedOptions={restrictedDetailSelectOptions || undefined}
            localCurrentOptions={props.widgetConfig?.options}
            changeOptions={updateWidgetOptions}
        />
    );
};

export default DetailSelect;
