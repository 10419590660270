import { IMetric } from '../../../../../../General.interfaces';
import { IDataAdapterResponseItem } from '../../../interfaces';
import { Td, Value } from '../../../styles';
import Stack from '../../../../../Stack/Stack';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import React from 'react';

export const CellAbsolute = (props: {
    showAllValues: boolean | undefined,
    showAbsoluteValues: boolean | undefined,
    metricFromStore: IMetric | undefined,
    item: IDataAdapterResponseItem,
    value: boolean | undefined,
    t: any
}) => {
    return (
        <Td
            paddingLeft={
                props.showAllValues || props.showAbsoluteValues ? undefined : '0px'
            }
            paddingRight={
                props.showAllValues || props.showAbsoluteValues ? undefined : '0px'
            }
        >
            {(props.showAllValues || props.showAbsoluteValues) && (
                <Stack styles={{ height: '100%' }} alignItems="flex-end" justifyContent="flex-end">
                    <Value color={'var(--color-secondary-darker)'}>
                        {valueFormatter({
                            precision: props.metricFromStore?.round_decimal_places,
                            value: props.item.mainValue.value,
                            units: '',
                            showUnits: Boolean(props.value),
                            t: props.t,
                        })}
                    </Value>
                </Stack>
            )}
        </Td>
    );
};