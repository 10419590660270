import { DateTime } from 'luxon';
import { IChartDetailing } from '../../constants/chartDetailing';
import { TLang } from '../../General.interfaces';
import getDateFormatByChartDetailing from './getDateFormatByChartDetailing';
import { stringDate } from './stringDate';
import { TFunction } from 'i18next';

interface IArgs {
    selectedChartDetailing: IChartDetailing | null;
    dateInMillis: number;
    isCategory?: boolean;
    isShortDate?: boolean;
    t: TFunction;
    lang: TLang;
}

/**
 * Функция для получения даты
 * @param selectedChartDetailing Детализация графика
 * @param dateInMillis время в миллисекундах
 * @param isCategory флга категории (лейбла на оси x)
 * @param lang Язык приложения
 * @param t функция для перевода
 * @returns строку даты
 */
const datetimeFormatHelper = (args: IArgs): string => {
    const { selectedChartDetailing, isShortDate = false, isCategory = false, dateInMillis, lang, t } = args;
    if (selectedChartDetailing) {
        const dateFormat = getDateFormatByChartDetailing(selectedChartDetailing, isCategory, isShortDate, t);

        const date = stringDate(
            DateTime.fromMillis(dateInMillis).toISO(),
            selectedChartDetailing.id === 'MS' ? 'en' : lang,
            dateFormat.preset,
            dateFormat.token,
        );
        switch (selectedChartDetailing.id) {
            case 'MS': {
                const splittedDate = date.split(' ');
                if (splittedDate.length === 2) {
                    return `${t(splittedDate[0])} ${splittedDate[1]}`;
                }
                return date;
            }
            default:
                return date;
        }
    }
    return stringDate(DateTime.fromMillis(dateInMillis).toISO(), lang, '', 'MMM dd, yyyy');
};

export default datetimeFormatHelper;
