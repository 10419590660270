import { useSelector } from 'react-redux';
import { cabinetPreferencesValues } from '../../../CabinetPreferences/reducer';
import { IDataAdapterResponseItem, IProps } from '../interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { useRequestMetrics } from '../../../../tools/API/hooks/useRequestMetrics';
import { useQuery } from '@tanstack/react-query';
import defaultDataAdapter from './dataAdapter';
import { useMemo } from 'react';
import { RATING_QUERY_QEY } from '../constants/constants';
import { TMetricResponse } from '../../../../General.interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../tools/API/dataAccessors/metricsDataAccessor';
import { IRequestMetricsArgs } from '../../../../tools/API/interfaces';
import { DS } from '../../../../constants/constants';

interface IArgs extends Omit<IProps, 'viewSettings'> {}

const useFetchData = (args: IArgs) => {

    var { token } = useSelector(generalReducerValues);
    var fetchData = useRequestMetrics({ isNetwork: args.isNetwork });

    var [queryKey, queryFn, dataAdapter] = useMemo(() => {
        if (token) {
            var requestArgs: IRequestMetricsArgs[] = [];

            const objIds: number[] = args.reportingObjects?.map((item) => item.id) ?? [];

            [args.mainPeriod, ...args.comparePeriods].forEach((period, index) => {
                var periodType = index === 0 ? 'mainPeriod' : 'comparePeriod';
                requestArgs.push({
                    alias: `${periodType}${DS}${args.moduleId}${DS}${args.widgetId}`,
                    time_range: [period.dateFrom, period.dateTo],
                    obj_ids: objIds,
                    object_aggregation: false,
                    metric: args.metric,
                    time_freq: null,
                    token,
                });
            });

            var dataAccessorArgs: IMetricsDAArgs = {
                requestArgs,
                fetchData,
            };

            return [
                [
                    RATING_QUERY_QEY,
                    args.moduleId,
                    args.widgetId,
                    args.metric,
                    args.mainPeriod,
                    args.comparePeriods,
                    objIds,
                    requestArgs,
                ],
                () => metricsDataAccessor(dataAccessorArgs),
                (response: TMetricResponse[]) =>
                    defaultDataAdapter({ response, reportingObjects: args.reportingObjects }),
            ];
        }
        return [];
    }, [args, token, fetchData]);

    return useQuery<TMetricResponse[], unknown, IDataAdapterResponseItem[], any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;
