import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TDataObjectsFilterTypes } from 'src/hooks/dataObjectsSelectFilters/interfaces';
import Checkbox from 'src/components/Checkbox/Checkbox';
import {
    storeActiveTenantId,
    storeSelectedTenantsRanges,
} from 'src/Chapters/Sales/PosAnalysis/reducer';
import { IChangeOptionsArgs } from 'src/components/UserSettings/interfaces';
import ModuleWrapper from 'src/components/Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from 'src/components/Selects/ObjectsSelect/ObjectsSelect';

import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../General.reducer';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';

import { TenantsPosWidget } from './widgets/TenantsPosWidget/TenantsPosWidget';

const style: React.CSSProperties = { maxWidth: 'none', height: '300px' };

const TENANTS_FILTER = ['data_objects_tenant'] as TDataObjectsFilterTypes[];

export const PosAnalysis: React.FC = () => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { t } = useTranslation();

    const handleToggle = useCallback(
        ({ checked, id }: { checked: boolean; id: string }) => {
            dispatch(changeOptions({ newOptions: { [id]: checked } }));

            dispatch(storeActiveTenantId(null));
            dispatch(storeSelectedTenantsRanges(null));
        },
        [dispatch],
    );
    const handleChangeOptions = useCallback(
        (args: IChangeOptionsArgs) => {
            dispatch(changeOptions(args))
            dispatch(storeActiveTenantId(null));
            dispatch(storeSelectedTenantsRanges(null));
        },
        [dispatch],
    );

    const disabled = localCurrentOptions?.['allTime'];

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={handleChangeOptions}
                            disabled={disabled}
                        />
                        <Checkbox
                            id={'allTime'}
                            label={t('All time')}
                            selected={localCurrentOptions?.['allTime']}
                            handleToggle={handleToggle}
                            style={{ paddingBottom: 5 }}
                        />

                        <ObjectsSelect
                            canSelectEntireSection
                            dataObjectFilters={TENANTS_FILTER}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={handleChangeOptions}
                            validOptions={['Tenants']}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset currentOptionsKeys={['selectedReportingObjectsIds', 'mainPeriod']} />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>

            <WrapperContainer style={style}>
                <TenantsPosWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};
