import getDifferenceBetweenNumbers from '../../../../../../../../tools/getDifferenceBetweenNumbers';
import sortValues from '../../../../../../../../tools/sortValues';
import { IExtendedTenant } from '../../../interfaces';
import { IDataForSort } from '../interfaces';
import { cloneDeep, isNull, isUndefined } from 'lodash';

interface IArgs {
    tenants: IExtendedTenant[];
    dataForSort: IDataForSort;
}

/**
 * Функция для сортировки арендаторов
 * @param dataForSort данные для сортировки
 * @param tenants все арендаторы
 */
const sortTenants = (args: IArgs): IExtendedTenant[] => {
    const { tenants, dataForSort } = args;

    const isValidNumber = (a: number | undefined | null): a is number => {
        return !isNull(a) && !isUndefined(a) && !isNaN(a);
    };

    return cloneDeep(tenants).sort((a, b) => {
        if (a.metrics && b.metrics) {
            const aValue: number | null | undefined = a.metrics[dataForSort.metricId]?.mainPeriod?.items[0]?.value;
            const bValue: number | null | undefined = b.metrics[dataForSort.metricId]?.mainPeriod?.items[0]?.value;

            let aCompareValue = null;
            let bCompareValue = null;

            if (
                isValidNumber(aValue) &&
                isValidNumber(a.metrics[dataForSort.metricId]?.comparePeriod?.items[0]?.value)
            ) {
                const difference = getDifferenceBetweenNumbers(
                    aValue,
                    a.metrics[dataForSort.metricId]?.comparePeriod?.items[0]?.value as number,
                );
                aCompareValue = +difference.percentDifference;
            }

            if (
                isValidNumber(bValue) &&
                isValidNumber(b.metrics[dataForSort.metricId]?.comparePeriod?.items[0].value)
            ) {
                const difference = getDifferenceBetweenNumbers(
                    bValue,
                    b.metrics[dataForSort.metricId]?.comparePeriod?.items[0].value as number,
                );
                bCompareValue = +difference.percentDifference;
            }

            if (dataForSort.periodType === 'mainPeriod') {
                return sortValues({ a: aValue, b: bValue, sortOrder: dataForSort.ordering === 'up' ? -1 : 1 });
            }

            if (dataForSort.periodType === 'comaprePeriod') {
                return sortValues({
                    a: aCompareValue,
                    b: bCompareValue,
                    sortOrder: dataForSort.ordering === 'up' ? -1 : 1,
                });
            }
        }
        return 1;
    });
};

export default sortTenants;
