import React, { useMemo } from 'react';

import { Props } from './configurations';
import { FluentCheckbox } from './styles';

import type { CheckboxOnChangeData } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

const Checkbox: React.FC<Props> = ({ selected = false, disabled = false, handleToggle, id, label, style }) => {
    const { t } = useTranslation();
    const onChange = (ev: React.ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        if (!disabled) {
            handleToggle({ checked: data.checked, id });
        }
    };

    return (
        <FluentCheckbox
            label={label ? t(label) : undefined}
            disabled={disabled}
            checked={selected}
            onChange={onChange}
            style={style}
        />
    );
};

export default Checkbox;
