import styled from 'styled-components/macro';

export const Title = styled.div``;

export const ChartWrapper = styled.div`
    position: relative;
`;

export const DynamicsSettingsWrapper = styled.div`
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: white;
`;
