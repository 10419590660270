import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { MainWrapper } from './styles';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import { Cases_Calculator_Calculator_Widget_Reducer_Values, storeChartMode } from './reducer';
import MainSelects from './components/MainSelects/MainSelects';
import CalculatorSelects from './components/CalculatorSelects/CalculatorSelects';
import MainTable from './components/MainTable/MainTable';
import ColumnChart from './components/ColumnChart/ColumnChart';
import DiscountTable from './components/DiccountTable/DiscountTable';
import FinalTable from './components/FinalTable/FinalTable';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

/**
 * Комопнент для отображения графиков динамики по периодам, метрикам и объектам
 */
const CalculatorWidget: React.FC = ({}) => {
    const ref = useRef(null);

    const { moduleName, chartMode } = useSelector(Cases_Calculator_Calculator_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const rent: string = localCurrentOptions?.['rentInMonth'];
    const showData = Number(rent) === 800000;
    const tenantName = localCurrentOptions?.['tenant']?.[0]?.text;
    const tenantArea = tenantName === 'Henderson' ? 205 : 250;
    const tenantCategory = tenantName === 'Henderson' ? 'Одежда' : 'Фудкорт';

    const tabListItems = [
        {
            id: '2023',
            text: t('2023'),
        },
        {
            id: 'Dev. 23/22',
            text: `${t('Dev.')} 23/22`,
        },
        {
            id: 'Dev. 23/21',
            text: `${t('Dev.')} 23/21`,
        },
    ];

    const tabListSelectHandler = (id: string) => {
        dispatch(storeChartMode(id));
    };

    return (
        <>
            <WidgetWrapper ref={ref}>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {`${tenantName}: ${tenantArea} m², категория: ${tenantCategory}`}

                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref }}
                            widgetName={`${t('Calculator')}: ${tenantName}: ?? m²`}
                        />
                    </WidgetTitle>
                </WidgetTitleWrapper>
                <MainSelects title={''} />
                <MainTable />
            </WidgetWrapper>
            <WidgetWrapper>
                <WidgetTitleWrapper>
                    <WidgetTitle>{t('Dynamics by periods, main metrics')}</WidgetTitle>
                    <TabList selectHandler={tabListSelectHandler} selectedValue={chartMode} options={tabListItems} />
                </WidgetTitleWrapper>
                <ColumnChart />
            </WidgetWrapper>

            <WidgetWrapper>
                <WidgetTitleWrapper>
                    <WidgetTitle>{`${tenantName}: ${t('discount calculation')}`}</WidgetTitle>
                </WidgetTitleWrapper>
                <CalculatorSelects title={''} />
                <DiscountTable />
                <FinalTable />
            </WidgetWrapper>
        </>
    );
};

export default CalculatorWidget;
