import { FC, useMemo } from 'react';
import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import { IProps, TWidgetType } from './interfaces';
import { DS } from '../../../../constants/constants';
import Selects from './components/Selects/Selects';
import { omit } from 'lodash';
import OnBoardSelects from './components/OnBoardSelects/OnBoardSelects';
import { useFetchGroupDataObjects } from '../../../../tools/API/hooks/groupDataObjects/useFetchGroupDataObjects';
import useGDOSelectedGroup from '../../../../Chapters/Network/tools/useGDOSelectedGroup';
import { queryClient } from '../../../..';
import { STACKED_BAR_CHART_BY_OBJECTS_QUERY_KEY } from '../../../CommonWidgets/StackedBarChartByObjectsGroup/constants/constants';
import StackedBarChartByObjectsGroup from '../../../CommonWidgets/StackedBarChartByObjectsGroup/StackedBarChartByObjectsGroup';
import usePeriods from '../../../../hooks/common/usePeriods';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { IReportingObject } from '../../../../General.interfaces';
import { Network_Section_Reducer_Values } from '../../../../Chapters/Network/reducer';
import { IReportingObject2Group } from '../../../CommonWidgets/StackedBarChartByObjectsGroup/interfaces';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';

const StackedBarChart: FC<IProps> = (props) => {
    const widgetType = props.widgetConfig?.type?.split(DS)?.[1] as TWidgetType;

    const { currentModuleID, allMetrics } = useSelector(generalReducerValues);
    const networkSectionReducerValues = useSelector(Network_Section_Reducer_Values);

    const groupDataObjects = useFetchGroupDataObjects();
    const selectedGroups = useGDOSelectedGroup(
        groupDataObjects,
        (props.widgetConfig?.options?.networkGroupObjects || []).map((item) => item.id) as (
            | string
            | number
            | undefined
        )[],
    );

    const { mainPeriod } = usePeriods(currentModuleID);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { t } = useTranslation(['metrics', 'translation']);

    const content = useMemo(() => {
        switch (widgetType) {
            case 'networkObjectsByLocations': {
                const metric =
                    props.widgetConfig?.visual.useMetricFrom?.[0]?.id === 'fromPanel'
                        ? localCurrentOptions?.['selectedSingleMetric']?.[0]
                        : props.widgetConfig?.options?.metrics?.[0] || '';

                const reportingObjects = selectedGroups.reduce((acc: IReportingObject[], value) => {
                    acc.push(...value.items);
                    return acc;
                }, []);
                if (mainPeriod && metric) {
                    return (
                        <StackedBarChartByObjectsGroup
                            metric={metric}
                            reportingObjects={reportingObjects}
                            categories={(networkSectionReducerValues.cfg.reportingObjectsByType['location'] ?? []).map(
                                (location) => ({ text: location.name, id: location.pl_id }),
                            )}
                            reportingObjects2Categories={reportingObjects.map((item) => ({
                                categoryId: item.pl_id,
                                reportingObjectId: item.id,
                            }))}
                            reportingObjects2Groups={selectedGroups.reduce((acc: IReportingObject2Group[], value) => {
                                value.items.forEach((reportingObject) => {
                                    acc.push({ groupId: value.group_name, reportingObjectId: reportingObject.id });
                                });
                                return acc;
                            }, [])}
                            groups={selectedGroups.map((item) => ({ id: item.group_name, text: item.group_name }))}
                            mainPeriod={mainPeriod.period}
                            isNetwork={true}
                            widgetId={props.widgetConfig?.id}
                        />
                    );
                }

                return null;
            }

            default: {
                return null;
            }
        }
    }, [
        localCurrentOptions,
        mainPeriod,
        networkSectionReducerValues.cfg.reportingObjectsByType,
        props.widgetConfig?.id,
        props.widgetConfig?.options?.metrics,
        props.widgetConfig?.visual?.useMetricFrom,
        selectedGroups,
        widgetType,
    ]);

    const reloadWidget = () => {
        switch (widgetType) {
            case 'networkObjectsByLocations':
                queryClient.invalidateQueries({
                    queryKey: [STACKED_BAR_CHART_BY_OBJECTS_QUERY_KEY, props.widgetConfig?.id],
                });
                break;

            default:
                break;
        }
    };

    const updateWidget = (key: string, value: { [x: string]: unknown }) => {
        if (props.widgetConfig && props.widgetConfig?.id) {
            props.updateWidgetTrans({
                moduleId: props.moduleId,
                dashboardKey: props.dashboardKey,
                data: {
                    widgetId: props.widgetConfig?.id,
                    key: key,
                    value: { ...(props.widgetConfig[key] || {}), ...value },
                },
            });
        }
    };

    const widgetWrapperSettings = useMemo(() => {
        let titleAdditionalText = '';
        switch (widgetType) {
            case 'networkObjectsByLocations':
                {
                    const metric =
                        props.widgetConfig?.visual.useMetricFrom?.[0]?.id === 'fromPanel'
                            ? localCurrentOptions?.['selectedSingleMetric']?.[0]
                            : props.widgetConfig?.options?.metrics?.[0] || '';
                    const metricFromStore = allMetrics.find((item) => item.id === metric);

                    if (metricFromStore) {
                        titleAdditionalText = `${t(metricFromStore.text)}, ${t(metricFromStore.units)}`;
                    }
                }

                break;

            default:
                break;
        }
        return { titleAdditionalText };
    }, [
        allMetrics,
        localCurrentOptions,
        props.widgetConfig?.options?.metrics,
        props.widgetConfig?.visual.useMetricFrom,
        t,
        widgetType,
    ]);

    return (
        <WidgetWrapper
            {...widgetWrapperSettings}
            updateWidgetTrans={props.updateWidgetTrans}
            title={t(props.widgetConfig?.title || '')}
            widgetId={props.widgetConfig?.id}
            dashboardKey={props.dashboardKey}
            reloadWidget={reloadWidget}
            moduleId={props.moduleId}
            wrapperPaddingSize={'normal'}
            titleSize={'normal'}
            widgetOnBoardSelects={
                <OnBoardSelects
                    {...omit(props, ['updateWidgetTrans'])}
                    updateWidget={updateWidget}
                    widgetType={widgetType}
                />
            }
            widgetSelects={
                <Selects {...omit(props, ['updateWidgetTrans'])} widgetType={widgetType} updateWidget={updateWidget} />
            }
        >
            {content}
        </WidgetWrapper>
    );
};

export default StackedBarChart;
