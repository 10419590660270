import { ITabListOptionsItem } from '../../../../components/TabList/interfaces';
import { TabListItemIds } from '../enums';

export const paymentAnalysisTenantSelect = 'paymentAnalysisTenantSelect';
export const vatSelect = 'useVAT';

// export const salesMetricsNames = ['fsf_sales_', 'fsf_sales_return_'];
export const salesMetricsNames = ['fsf_sum_'];
export const paymentTypes = ['ecash', 'cash', 'credit', 'prepaid', 'provision'];
export const operationTypes = ['income', 'income_return'];
export const PAYMENT_TYPE_TEXT_MAP = {
    ecash: 'E-Cash',
    cash: 'Cash',
    credit: 'Credit',
    prepaid: 'Prepaid',
    provision: 'Provision',
};

export const CORRECTION_MAP = {
    withoutCorrection: 'bill|bso',
    addCorrection: 'bill|bso|bill_correction|bso_correction',
    correctionOnly: 'bill_correction|bso_correction',
};

export const TAB_LIST_ITEMS: ITabListOptionsItem[] = [
    { id: TabListItemIds.PaymentType, text: 'Payment type' },
    { id: TabListItemIds.OperationType, text: 'Operation type' },
];
