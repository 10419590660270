import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { Dashboards_Reducer_Values, deleteDashboard } from '../../../../Dashboards.reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import Stack from '../../../../components/Stack/Stack';
import Button from '../../../../components/Button/Button';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { useAppDispatch } from '../../../../hooks/useSettings';

const DeleteBody: React.FC<IProps> = ({ closeModal, id }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const { userDashboards, defaultDashboards } = useSelector(Dashboards_Reducer_Values);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onCancelClick = () => {
        closeModal();
    };

    const onDeleteClick = () => {
        if (id) {
            dispatch(deleteDashboard({ id }));
        }
        closeModal();
    };

    return (
        <Wrapper>
            <Stack alignItems="center" justifyContent="flex-end" gap={10}>
                <Button onClick={onCancelClick}>{t('Cancel')}</Button>
                <Button appearance="primary" onClick={onDeleteClick}>
                    {t('Delete')}
                </Button>
            </Stack>
        </Wrapper>
    );
};

export default DeleteBody;
