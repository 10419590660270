import { DateTime } from 'luxon';
import {
    IAdapterArgs,
    IAdapterResults,
    ISummaryCell,
    TOperationType,
    IMetricCell,
    TTF,
    ICellBillData,
} from '../interfaces';
import { generateDates } from '../tools/generateDates';
import { COLUMNS } from '../constants/constants';
import _ from 'lodash';
import { DS } from '../../../../constants/constants';

export const adapter = (args: IAdapterArgs): IAdapterResults => {
    const monthsStartDates = generateDates({ from: args.dates['dateFrom'], to: args.dates['dateTo'], timeFreq: 'MS' });
    // Фильтруем ответ с сервера по актуальной для локации валюте
    const filteredResults = args.response.data;

    const summaryTableData: ISummaryCell[][] = args.ids
        // Формируем тело таблицы
        .map((objId) => {
            return monthsStartDates.map((startDate) => {
                const summaryCellData = filteredResults.filter((item) => {
                    return (
                        DateTime.fromISO(item.date).month === DateTime.fromISO(startDate).month &&
                        DateTime.fromISO(item.date).year === DateTime.fromISO(startDate).year &&
                        item.data_object_id === objId
                    );
                });
                return {
                    startDate,
                    summaryCellData,
                    isHeader: false,
                    readOnly: true,
                } as ISummaryCell;
            });
        })
        // Добавляем первый столбец с именами объектов
        .map((row, i) => {
            const objName = args.reportingObjectsById[args.ids[i]].object_name;
            return [{ value: objName, isFirstColumn: true, readOnly: true }, ...row];
        });

    // Сортируем по имени объекта
    const sorted = _.sortBy(summaryTableData, (item) => item[0].value);

    // Формируем хедер таблицы
    const headerRow: ISummaryCell[] = [
        { value: '', isHeader: true },
        ...monthsStartDates.map((startDate) => {
            return {
                value:
                    args.t(DateTime.fromISO(startDate).toFormat('LLLL')) +
                    ' ' +
                    DateTime.fromISO(startDate).toFormat('y'),
                isHeader: true,
            } as ISummaryCell;
        }),
    ];
    sorted.unshift(headerRow);

    const generateEmptyStructure = (timeFreq: TTF = 'MS'): IMetricCell[][] => {
        const sortedObjects = _.sortBy(args.ids, (id) => {
            const objName = args.reportingObjectsById[id].object_name;
            return objName;
        });

        const dates = generateDates({ from: args.dates['dateFrom'], to: args.dates['dateTo'], timeFreq });

        return sortedObjects.reduce((acc: IMetricCell[][], objId) => {
            const objName = args.reportingObjectsById[objId].object_name;
            const objRows: IMetricCell[][] = dates.map((date) => {
                return COLUMNS.map((item) => {
                    const data: IMetricCell = {
                        ...item,
                        isValid: true,
                        objName,
                        value: null,
                        initialValue: null,
                        data_object: objId,
                        date,
                    };

                    if (item.colId.includes('metrics')) {
                        data.metric = item.colId.split(DS)[1];
                    }

                    return data;
                });
            });
            acc.push(...objRows);
            return acc;
        }, []);
    };

    const getMetircsTableData = (timeFreq: TTF = 'MS'): IMetricCell[][] => {
        const rows: IMetricCell[][] = generateEmptyStructure(timeFreq).map((row) => {
            return row.map((cell) => {
                let value: string | null = cell?.[cell.colId] || null;
                if (filteredResults && cell?.['metric']) {
                    value =
                        filteredResults
                            .find((item) => {
                                return (
                                    item.data_object_id === cell?.['data_object'] &&
                                    cell?.['date'] &&
                                    DateTime.fromISO(item.date).toISODate() ===
                                        DateTime.fromISO(cell?.['date']).toISODate()
                                );
                            })
                            ?.value?.toString() || '';
                }
                return {
                    ...cell,
                    value,
                    initialValue: value,
                };
            });
        });

        // Формируем хедер таблицы
        const headerRow: IMetricCell[] = COLUMNS.map((col) => {
            return {
                ...col,
                value: col.colName,
                isValid: true,
                initialValue: null,
                isHeader: true,
                data_object: 0,
                date: '',
                objName: '',
            };
        });

        rows.unshift(headerRow);
        return rows;
    };

    return {
        summaryTableData: sorted,
        metricsTableData: getMetircsTableData(),
        rawData: filteredResults,
    };
};
