import { IModuleConfig } from '../../../General.interfaces';
import { ISection, ISubsection, TMenu } from '../configurations';

interface IArgs {
    /** Конфигурация модулей */
    config: IModuleConfig[];
    permissions: string[];
    favoriteModules: string[];
}

/**
 * Функция генерации данных для бокового меню
 */
const generateMenu = (args: IArgs): TMenu => {
    const { config, permissions, favoriteModules } = args;

    const favoriteSubsections = config
        .filter((sub) => favoriteModules.includes(sub.module_id))
        .map((sub) => {
            const clonedSub = { ...sub };
            const [, item] = clonedSub.module_name.split(':');
            clonedSub.module_name = `Favorites:${item}`;
            return clonedSub;
        });

    const combinedModulesConfig = [...favoriteSubsections, ...config];

    const result = combinedModulesConfig
        .filter((item) => {
            return item.permissions.some((perm) => permissions.includes(perm));
        })
        .reduce((acc: { [chapterName: string]: ISection | ISubsection }, value) => {
            const chapterName = value.module_name.split(':')[0];
            const moduleName = value.module_name.split(':')[1];
            const module_id = value.module_id;

            if (chapterName === 'none') {
                acc[chapterName] = {
                    type: 'module',
                    module_id,
                    name: 'Import metrics',
                    servicePath: value.service.path,
                    icon: value.icon,
                    version: value.version,
                    chapterName: chapterName,
                };
            } else {
                const subsection: ISubsection = {
                    type: 'module',
                    module_id,
                    name: moduleName,
                    servicePath: value.service.path,
                    icon: value.icon,
                    version: value.version,
                    disabled: value.disabled ?? false,
                    chapterName: chapterName,
                };

                if (acc[chapterName]) {
                    (acc[chapterName] as ISection).subsections.push(subsection);
                } else {
                    acc[chapterName] = {
                        type: 'chapter',
                        name: chapterName,
                        subsections: [subsection],
                    };
                }
            }

            return acc;
        }, {});

    return Object.values(result);
};

export default generateMenu;
