import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';
import { modulesConfig } from 'src/constants/modulesConfig';
import { generalReducerValues } from 'src/General.reducer';
import { useSelector } from 'react-redux';

const WrapperContainer: React.FC<{ children: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
    const { currentModuleID, optionPanelHeight, modulesConfig } = useSelector(generalReducerValues);

    const paddingTop = useMemo(() => {
        const hasOptionsPanel = modulesConfig.find((item) => item.module_id === currentModuleID)?.hasOptionsPanel;
        if (!hasOptionsPanel) {
            return 0;
        } else {
            return optionPanelHeight;
        }
    }, [currentModuleID, modulesConfig, optionPanelHeight]);

    return (
        <Container maxWidth={style?.maxWidth as string} style={style} paddingTop={paddingTop}>
            {children}
        </Container>
    );
};

export default WrapperContainer;
