import { Button } from '@fluentui/react-components';
import styled from 'styled-components';

import { StyledBaseColor } from 'src/types';

export const StyledButton = styled(Button)<StyledBaseColor>`
    white-space: nowrap;
    ${(p) => {
        return p.baseColor
            ? `
            background-color: ${p.theme.colors[p.baseColor]} !important;
            color: ${p.theme.colors.white} !important;
            border: none;
            &:active {
                background-color: ${p.theme.colors[`${p.baseColor}Dark`]} !important;
                opacity: 1;
            }
            &: hover {
                background-color: ${p.theme.colors[`${p.baseColor}Darker`]} !important;
            }`
            : ``;
    }}
`;
