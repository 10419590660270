import { FC } from 'react';
import { IProps } from './interfaces';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { valueFormatter } from '../../../../../tools/Strings/valueFormatter';
import { isNumber, orderBy } from 'lodash';
import Stack from '../../../../Stack/Stack';
import { Rating, RatingWrapper, Td, Value } from '../../styles';
import { getColorFromPercent } from '../../../../../tools/getColorFromPercent';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import Icon from '../../../../Icon/Icon';

/**
 * Тело таблицы
 */
const Body: FC<IProps> = ({ comparePeriods, data, metric, viewSettings }) => {
    var { allMetrics } = useSelector(generalReducerValues);
    var { t } = useTranslation(['metrics', 'translation']);

    var metricFromStore = allMetrics.find((item) => item.id === metric);

    return (
        <tbody>
            {orderBy(data || [], (item) => item.mainValue.value || '', 'desc')
                .slice(0, isNumber(viewSettings?.itemsToShow) ? viewSettings?.itemsToShow : (data || []).length + 1)
                .map((item) => {
                    return (
                        <tr key={`${item.name}--${item.id}`}>
                            <Td>
                                <Stack direction="column" gap={3} justifyContent="flex-end">
                                    <RatingWrapper text={item.name}>
                                        <Rating width={item.mainValue.percentOfMax} />
                                    </RatingWrapper>
                                </Stack>
                            </Td>
                            <Td
                                paddingLeft={viewSettings?.showPercentOfTotal ? undefined : '0px'}
                                paddingRight={viewSettings?.showPercentOfTotal ? undefined : '0px'}
                            >
                                {viewSettings?.showPercentOfTotal && (
                                    <Stack styles={{ height: '100%' }} alignItems="flex-end">
                                        <Value style={viewSettings?.mainValuePercentOfTotalStyles} fontWeight={'500'}>
                                            {valueFormatter({
                                                value: item.mainValue.percentOfTotal,
                                                units: '%',
                                                precision: 1,
                                            })}
                                        </Value>
                                    </Stack>
                                )}
                            </Td>
                            <Td
                                paddingLeft={
                                    viewSettings?.showAllValues || viewSettings?.showAbsoluteValues ? undefined : '0px'
                                }
                                paddingRight={
                                    viewSettings?.showAllValues || viewSettings?.showAbsoluteValues ? undefined : '0px'
                                }
                            >
                                {(viewSettings?.showAllValues || viewSettings?.showAbsoluteValues) && (
                                    <Stack styles={{ height: '100%' }} alignItems="flex-end" justifyContent="flex-end">
                                        <Value color={'var(--color-secondary-darker)'}>
                                            {valueFormatter({
                                                precision: metricFromStore?.round_decimal_places,
                                                value: item.mainValue.value,
                                                units: metricFromStore?.units,
                                                showUnits: Boolean(viewSettings?.showMainValueUnits),
                                                t,
                                            })}
                                        </Value>
                                    </Stack>
                                )}
                            </Td>
                            {comparePeriods.map((period, index) => {
                                const currentPeriodData = item.compareValues.find(
                                    (itemComparePeriod) =>
                                        itemComparePeriod.period.dateFrom === period.dateFrom &&
                                        itemComparePeriod.period.dateTo === period.dateTo,
                                );

                                if (!currentPeriodData) return null;

                                const value = viewSettings?.showAbsoluteValues
                                    ? currentPeriodData.value
                                    : currentPeriodData.percentDiffWithMain;

                                const color = getColorFromPercent(value, 'object');

                                return (
                                    <Td key={JSON.stringify(period) + index}>
                                        <Stack
                                            styles={{ height: '100%' }}
                                            alignItems="center"
                                            justifyContent="flex-end"
                                            gap={5}
                                        >
                                            <Value
                                                style={viewSettings?.compareValueStyles}
                                                color={viewSettings?.showAbsoluteValues ? undefined : color.color}
                                            >
                                                {valueFormatter({
                                                    precision: viewSettings?.showAbsoluteValues
                                                        ? metricFromStore?.round_decimal_places
                                                        : 1,
                                                    value: value,
                                                    units: viewSettings?.showAbsoluteValues
                                                        ? metricFromStore?.units
                                                        : '%',
                                                    showUnits: !viewSettings?.showAbsoluteValues,
                                                })}
                                            </Value>
                                            {viewSettings?.showCompareValueArrow && color.direction !== 'usual' && (
                                                <Icon
                                                    type={
                                                        color.direction === 'down'
                                                            ? 'short-arrow-down'
                                                            : 'short-arrow-up'
                                                    }
                                                    size="xxs"
                                                    color={color.direction === 'down' ? 'accent' : 'success'}
                                                />
                                            )}
                                        </Stack>
                                    </Td>
                                );
                            })}
                        </tr>
                    );
                })}
        </tbody>
    );
};

export default Body;
