import { FC, useMemo } from 'react';
import { IProps } from './interfaces';
import { TextWrapper } from './styles';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import {
    NUMBERS_DAYS_OF_THE_WEEK_OPTIONS,
    NUMBERS_WORKING_MODE_MAP,
} from '../../../../../../Chapters/Dashboards/ObjectPalnels/widgetsConfig';

const WeekdaysInfo: FC<IProps> = ({ widgetConfig }) => {
    const DAYS_IN_WEEK = 7;

    const { t } = useTranslation(['translation']);

    const weekdaysText = useMemo(() => {
        const mode = widgetConfig?.visual?.workingMode;
        if (widgetConfig?.visual?.selectedDaysOfTheWeek?.length === DAYS_IN_WEEK) {
            return `${t('All days')} (${t(NUMBERS_WORKING_MODE_MAP[mode || '']?.short || '')})`;
        }
        if (widgetConfig?.visual?.selectedDaysOfTheWeek?.length) {
            const text = NUMBERS_DAYS_OF_THE_WEEK_OPTIONS.reduce((acc, value) => {
                if (widgetConfig?.visual?.selectedDaysOfTheWeek?.includes(value.id)) {
                    if (!acc) {
                        acc = t(value.text);
                    } else {
                        acc += ` ${t(value.text)}`;
                    }
                }
                return acc;
            }, '');
            return `${text} (${t(NUMBERS_WORKING_MODE_MAP[mode || '']?.short || '')})`;
        }
        return null;
    }, [widgetConfig?.visual?.workingMode, widgetConfig?.visual?.selectedDaysOfTheWeek, t]);

    return (
        <TextWrapper>
            {weekdaysText}
        </TextWrapper>
    );
};

export default WeekdaysInfo;
