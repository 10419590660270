import styled from 'styled-components/macro';
import { TAlignItems, TDirection, TFlexWrap, TJustifyContent } from './interfaces';

export const Wrapper = styled.div<{
    justifyContent?: TJustifyContent;
    alignItems?: TAlignItems;
    verticalFill?: boolean;
    direction: TDirection;
    flexWrap?: TFlexWrap;
    padding?: string;
    gap?: number;
}>`
    display: flex;
    flex-direction: ${(p) => p.direction};
    ${(p) => (p.alignItems ? `align-items: ${p.alignItems}` : null)};
    ${(p) => (p.justifyContent ? `justify-content: ${p.justifyContent}` : null)};
    ${(p) => (p.flexWrap ? `flex-wrap: ${p.flexWrap}` : null)};
    ${(p) => (p.gap ? `gap: ${p.gap}px` : null)};
    ${(p) => (p.verticalFill ? `height: 100%` : null)};
    ${(p) => (p.padding ? `padding: ${p.padding}` : null)};
`;
