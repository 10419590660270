import { ResponseStatus } from '../constants';
import { isAxiosError, isErrorResponseGuard } from '../guards/isErrorResponse';

interface IArgs {
    isLoading?: boolean;
    isFetching?: boolean;
    error: unknown;
    data: unknown;
    showSpinnerOnFetching?: boolean;
    isDataExists?: boolean;
}

const getResponseStatus = (args: IArgs): null | { status: ResponseStatus; message: string } => {
    const { isFetching, error, data, isLoading, showSpinnerOnFetching = true, isDataExists = true } = args;

    if (isLoading) {
        return { status: ResponseStatus.Loading, message: 'Loading...' };
    }

    if (isFetching && showSpinnerOnFetching) {
        return { status: ResponseStatus.Loading, message: 'Loading...' };
    }

    if (error) {
        let message = 'An error accrued while getting data!';

        if (isErrorResponseGuard(error)) {
            message = error.message;
        } else if (isAxiosError(error) && error.response?.data) {
            message = JSON.stringify(error.response?.data);
        }

        return { status: ResponseStatus.Error, message };
    }
    if (!isDataExists) {
        return { status: ResponseStatus.NoData, message: 'No data' };
    }

    if (data) {
        return { status: ResponseStatus.Success, message: 'Success' };
    }

    return null;
};

export default getResponseStatus;
