import _ from 'lodash';
import { Radio, RadioGroup, RadioGroupOnChangeData } from '@fluentui/react-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { isMobileInboundWidth } from 'src/theme';

import { DEFAULT_RATING_SETTING } from '../../constants/constants';
import Panel from '../../../../../../../components/Panel/Panel';
import { ICheckboxItem } from '../../interfaces';
import { Performance_Ratings_RatingList_Widget_Reducer_Values, storeNewRating } from '../../reducer';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { getReportingObjects } from '../../../../../../../tools/getReportingObjects';
import { TSelectedOptions } from '../../../../../../../components/Selects/Select/interfaces';
import Button from '../../../../../../../components/Button/Button';
import TextInput from '../../../../../../../components/TextInput/TextInput';
import { useSendSimpleAnalytics } from '../../../../../../../hooks/useAnalytics';
import MetricsSelect from '../../../../../../../components/Selects/MetricsSelect/MetricsSelect';
import { IChangeOptionsArgs } from '../../../../../../../components/UserSettings/interfaces';
import { TSort } from '../../../../../../../components/CommonWidgets/RatingWithAreaByObjects/interfaces';

import CheckboxGroupObjectType from './components/CheckboxGroupObjectType/CheckboxGroupObjectType';
import SelectMainIndicator from './components/SelectMainIndicator/SelectMainIndicator';
import SelectObjectType from './components/SelectObjectType/SelectObjectType';
import { ButtonWrapper, Column, FormItem, GridTemplate, Label, ModalNewRatingWrapper } from './styles';

const ModalNewRating: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const {
        mainAreaSize: { width },
        cfg: { reportingObjectsByType },
        currentModuleID,
    } = useSelector(generalReducerValues);
    const sendAnalytics = useSendSimpleAnalytics();
    const { editRatingId, ratings } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);
    const { t } = useTranslation();
    const [rating, setValueRating] = useState(_.cloneDeep(DEFAULT_RATING_SETTING));
    const dispatch = useDispatch();
    const [selected, setSelected] = useState('asc');
    const objectsTypes = getReportingObjects(reportingObjectsByType, t).map((item) => ({
        id: item.key,
    }));
    const optionsSort = [
        {
            key: 'desc',
            text: 'Desc',
        },
        {
            key: 'asc',
            text: 'Asc',
        },
    ];

    /**
     * Обновление конфигурации рейтинга при редактировании
     */
    useEffect(() => {
        if (editRatingId) {
            const editRatingItem = ratings?.find((x) => x.id === editRatingId);

            if (editRatingItem) {
                setValueRating(editRatingItem);
            }
        }
    }, [editRatingId]);

    useEffect(() => {
        if (rating.sort_method) {
            setSelected(rating.sort_method);
        }
    }, [rating.sort_method]);

    const isMobile = isMobileInboundWidth(width);

    /**
     * Обработка выбора сортировки
     */
    const onChangeSorting = React.useCallback((_: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
        setValueRating((prevState) => ({
            ...prevState,
            sort_method: String(data.value) as TSort,
        }));
        setSelected(data.value);
    }, []);

    /**
     * Обработка выбора метрики
     */
    const handleMetricSelectValue = (args: IChangeOptionsArgs) => {
        const { newOptions } = args as { newOptions: { selectedMetrics: string[] } };
        setValueRating((prevState) => ({
            ...prevState,
            metric: newOptions.selectedMetrics?.[0] || '',
        }));
    };

    /**
     * Обработка выбора основного индикатора
     */
    const handleMainIndicatorSelectValue = (selects: TSelectedOptions) => {
        setValueRating((prevState) => ({
            ...prevState,
            main_column: String(selects[0].id),
            additional_columns: prevState.additional_columns?.map((item) => {
                if (item.id === selects[0].id) {
                    return {
                        ...item,
                        checked: true,
                    };
                }
                return item;
            }),
        }));
    };

    /**
     * Обработка ввода названия рейтинга
     */
    const handleSetName = (data: { value: string }) => {
        setValueRating({
            ...rating,
            name: data.value,
        });
    };

    /**
     * Обработка выбора типа объектов
     */
    const handleSetObjectType = (selects: TSelectedOptions) => {
        setValueRating((prevState) => {
            return {
                ...prevState,
                dataobj_type: String(selects[0].id),
            };
        });
    };

    /**
     * Обработка выбора дополнительных индикаторов
     */
    const handleSecondIndicatorsValue = (checkboxs: ICheckboxItem[]) => {
        setValueRating((prevState) => ({
            ...prevState,
            additional_columns: checkboxs,
        }));
    };

    const buttonClick = () => {
        if (editRatingId) {
            sendAnalytics('rating_editing');
        } else {
            sendAnalytics('rating_creation');
        }
        if (rating.dataobj_type === '') {
            dispatch(storeNewRating({ ...rating, dataobj_type: String(objectsTypes[0].id) }));
        } else {
            dispatch(storeNewRating(rating));
            // dispatch(storeEditingRating(rating));
        }

        closeModal();

        setValueRating(_.cloneDeep(DEFAULT_RATING_SETTING));
    };

    const modalForm = useMemo(() => {
        return (
            <GridTemplate>
                <Column>
                    <FormItem $hideMarginBottom={true}>
                        <Label>{t('Rating title')}</Label>
                        <TextInput
                            handleChange={handleSetName}
                            fullWidth={true}
                            id="ratingName"
                            name="ratingName"
                            hasError={rating.name === '' ? true : false}
                            outsideValue={rating.name}
                        />
                    </FormItem>
                    <MetricsSelect
                        localCurrentOptions={{ selectedMetrics: [rating.metric] }}
                        changeOptions={handleMetricSelectValue}
                        maxSelected={1}
                    />

                    <SelectMainIndicator
                        testId={`${currentModuleID}_MainIndicator`}
                        fullWidth={true}
                        handleSelectValue={handleMainIndicatorSelectValue}
                        selectedId={rating.main_column}
                    />
                    <Panel title={t('Sorting')}>
                        <RadioGroup value={selected} onChange={onChangeSorting}>
                            {optionsSort.map((option) => (
                                <Radio key={option.key} value={option.key} label={t(option.text)} />
                            ))}
                        </RadioGroup>
                    </Panel>
                </Column>
                <Column>
                    <SelectObjectType
                        testId={`${currentModuleID}_ObjectType`}
                        fullWidth={true}
                        handleSelectValue={handleSetObjectType}
                        selectedId={rating.dataobj_type}
                    />
                    <Panel title={t('Show additional')}>
                        <CheckboxGroupObjectType
                            defaultRatingIndicators={rating.additional_columns ?? []}
                            mainIndicator={rating.main_column}
                            handleCheckboxsValues={handleSecondIndicatorsValue}
                        />
                    </Panel>
                </Column>
            </GridTemplate>
        );
    }, [rating, isMobile, t]);

    return (
        <ModalNewRatingWrapper>
            {modalForm}
            <ButtonWrapper>
                {rating.id !== 0 ? (
                    <Button appearance="primary" onClick={buttonClick}>
                        {t('Save')}
                    </Button>
                ) : (
                    <Button appearance="primary" disabled={rating.name === '' ? true : false} onClick={buttonClick}>
                        {t('Add rating')}
                    </Button>
                )}
            </ButtonWrapper>
        </ModalNewRatingWrapper>
    );
};
export default ModalNewRating;
