import React from 'react';
import WrapperContainer from '../../../../../components/Wrappers/WrapperContainer/WrapperContainer';
import RatingList from './components/RatingList/RatingList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';


const RatingListWidget: React.FC = () => {

    return (
        <>
            <WrapperContainer style={{ maxWidth: '100%' }}>
                <WidgetWrapper>
                    <RatingList />
                </WidgetWrapper>
            </WrapperContainer>

        </>
    );
};
export default RatingListWidget;
