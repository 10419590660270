import { useId, Input, Label, InputOnChangeData, Textarea } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { IProps } from './interfaces';
import { useState, useEffect, FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, Wrapper, useStyles } from './styles';
import Button from '../../../Button/Button';
import { PresetReducerValues } from '../../reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useAppDispatch } from '../../../../hooks/useSettings';
import { ISettings } from '../../../UserSettings/interfaces';
import { IPreset } from '../../interfaces';
import { postRequest } from '../../../../tools/API/appBackendAPI';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import { storeNewAlert } from '../../../Alert/reducer';

const MakeSharedModalBody: FC<IProps> = ({ closeModal }) => {
    const [value, copy] = useCopyToClipboard();
    const underlineId = useId('input-underline');
    const descriptionId = useId('input-description');
    const styles = useStyles();

    const [presetName, setPresetName] = useState('');
    const [description, setDescription] = useState('');
    const { t } = useTranslation();
    const { selectedUserPresetIds, userPresets } = useSelector(PresetReducerValues);
    const dispatch = useAppDispatch();
    const { currentModuleID, token, user, urlsByServices, initialDataReceived } = useSelector(generalReducerValues);

    const currentPreset = userPresets.find((item) => item.id === selectedUserPresetIds[currentModuleID]);

    useEffect(() => {
        currentPreset?.name && setPresetName(() => currentPreset?.name);
    }, [currentPreset?.name]);

    const onButtonClick = () => {
        if (currentPreset) {
            const { settings, module_id } = currentPreset;
            postSharedPreset({ name: presetName, settings, description, moduleId: module_id });
        }
        closeModal();
    };

    const handleNameInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
        setPresetName(() => data.value);

    const handleDescriptionInput = (ev: ChangeEvent<HTMLTextAreaElement>, data: InputOnChangeData) =>
        setDescription(() => data.value);

    const postSharedPreset = ({
        name,
        settings,
        description = '',
        moduleId,
    }: {
        name: string;
        settings: ISettings;
        description?: string;
        moduleId?: string;
    }) => {
        const url = urlsByServices?.['app/app-backend'].SHARED_PRESETS_URL;

        if (!user?.id || !token || !initialDataReceived || !url) return;
        const data: Partial<IPreset> = {
            description: description || '',
            name,
            module_id: moduleId ? moduleId : currentModuleID,
            settings,
        };
        // const spinnerId = generateId();
        // dispatch(addNewGlobalSpinnerItem({ id: spinnerId, text: 'Loading presets...' }));
        postRequest(url, token, data).then((res) => {
            const hash = res.data.hash;
            copy(`${document.location.href}?shared_preset=${hash}`);
            dispatch(
                storeNewAlert({
                    type: 'success',
                    text: `${t('Link copied to clipboard')}`,
                    duration: 30,
                }),
            );
        });
    };

    return (
        <Wrapper>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId}>
                    {t('Name')}
                </Label>
                <Input
                    onChange={handleNameInput}
                    value={presetName}
                    placeholder={t('Name')}
                    appearance="underline"
                    id={underlineId}
                />
            </div>

            <div className={styles.field}>
                <Label weight="semibold" htmlFor={descriptionId}>
                    {t('Description')}
                </Label>
                <Textarea
                    onChange={handleDescriptionInput}
                    value={description}
                    // appearance="underline"
                    id={descriptionId}
                />
            </div>
            {/* 
            <SettingsWrapper>
                <SettingsTitle>{t('Shared preset settings:')}</SettingsTitle>
                <SettingsBodyWrapper>
                    {currentPreset?.settings
                        ? Object.entries(currentPreset?.settings).map((entry) => {
                              const [key, value] = entry;
                              const valueText = Array.isArray(value)
                                  ? value.map((item) => JSON.stringify(item) + '\n')
                                  : JSON.stringify(value || {});

                              return (
                                  <SettingsBodyItem key={key}>
                                      <SettingsSubtitle>{key}</SettingsSubtitle>
                                      <SettingsText>{valueText}</SettingsText>
                                  </SettingsBodyItem>
                              );
                          })
                        : null}
                </SettingsBodyWrapper>
            </SettingsWrapper> */}
            <ButtonWrapper>
                <Button text={t('Share preset')} appearance={'primary'} onClick={onButtonClick} />
            </ButtonWrapper>
        </Wrapper>
    );
};

export default MakeSharedModalBody;
