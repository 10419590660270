import Cell from './Cell';
import HeaderCell from '../HeaderCell';
import React, { ComponentType, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../../reducer';
import { StyledHead, StyledTable, TableWrapper, Tr } from './styles';
import { TOrdering } from '../../../../../../../components/OrderSwitcher/interfaces';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withLoading } from '../../../../../../../tools/API/withLoading';

const Table: React.FC = ({}) => {
    const { preparedData, allTenantsData, showReferenceCompareValues } = useSelector(
        Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    );
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const [ordering, setOrdering] = useState<{ ordering: TOrdering; primaryType: string }>({
        ordering: 'none',
        primaryType: 'percent',
    });

    const handleOrderToggle = (args: { ordering: TOrdering; id: string }) => {
        setOrdering({ ordering: args.ordering, primaryType: args.id });
    };

    const table = useMemo(() => {
        const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: allTenantsData, height: 400 });

        if (Array.isArray(preparedData)) {
            const header = (
                <StyledHead>
                    <Tr>
                        <HeaderCell
                            handleOrderToggle={handleOrderToggle}
                            primaryType={ordering.primaryType}
                            ordering={ordering.ordering}
                            colSpan={1}
                        >
                            {t('Tenant')}
                        </HeaderCell>
                        {preparedData?.[0]?.metricsData?.map((item: any) => {
                            const meticName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
                            return (
                                <HeaderCell
                                    key={`${item.metric}---${Math.random()}`}
                                    handleOrderToggle={handleOrderToggle}
                                    primaryType={ordering.primaryType}
                                    ordering={ordering.ordering}
                                    type={item.metric}
                                    colSpan={2}
                                    hasSorting
                                >
                                    {t(meticName, { ns: 'metrics' })}
                                </HeaderCell>
                            );
                        })}
                    </Tr>
                    {preparedData
                        .filter((element) => element.isSelectedTenant)
                        .map(mapPreparedData)
                        .map(buildTr(showReferenceCompareValues))}
                </StyledHead>
            );

            const sortedData = (
                preparedData: any[],
                ordering: {
                    ordering: TOrdering;
                    primaryType: string;
                },
            ) => {
                if (ordering.ordering === 'none') {
                    return cloneDeep(preparedData)?.sort((rowA: any, rowB: any) => {
                        const isSelectedTenant = rowA[0]?.isSelectedTenant;
                        return isSelectedTenant ? -1 : 1;
                    });
                } else {
                    const multiplier = ordering.ordering === 'up' ? 1 : -1;
                    const getNumericData = (row: any) => {
                        return Number(row.metricsData?.find((item: any) => item.metric === ordering.primaryType).main);
                    };
                    return cloneDeep(preparedData)?.sort((rowA: any, rowB: any) => {
                        if (isNaN(getNumericData(rowB))) {
                            return -1;
                        } else {
                            return multiplier * (getNumericData(rowB) - getNumericData(rowA));
                        }
                    });
                }
            };

            const rows = sortedData(preparedData, ordering)
                .filter((element) => !element.isSelectedTenant)
                .map(mapPreparedData)
                .map(buildTr(showReferenceCompareValues));

            return (
                <WithLoadingTable>
                    <StyledTable>
                        {header}
                        <tbody>{rows}</tbody>
                    </StyledTable>
                </WithLoadingTable>
            );
        } else {
            return <WithLoadingTable>{null}</WithLoadingTable>;
        }
    }, [ordering, preparedData, t, allTenantsData, showReferenceCompareValues]);

    return table;
};

export default Table;

const mapPreparedData = (row: any) => {
    const data =
        row?.metricsData?.reduce((acc: any[], item: any) => {
            return [
                ...acc,
                {
                    value: item?.main,
                    type: 'main',
                    metric: item.metric,
                    referenceCompare: item?.referenceCompare,
                    isSelectedTenant: row.isSelectedTenant,
                },
                {
                    value: item?.deviation,
                    type: 'deviation',
                    deviationByReferenceCompare: item?.deviationByReferenceCompare,
                    isSelectedTenant: row.isSelectedTenant,
                },
            ];
        }, []) || [];

    return [{ value: row?.tenantName, isSelectedTenant: row.isSelectedTenant, type: 'name' }, ...data];
};

const buildTr = (showReferenceCompareValues: boolean) => (row: any) => {
    const cells = row.map((cell: any, i: number) => {
        return <Cell key={`${cell?.value}mm${i}`} showReferenceCompareValues={showReferenceCompareValues} {...cell} />;
    });
    const isSelectedTenant = row[0].isSelectedTenant;
    return (
        <Tr key={Math.random()} isSelectedTenant={isSelectedTenant}>
            {cells}
        </Tr>
    );
};
