import filterTenantValidZone from './filterTenantValidZone';
import filterZoneValidFloors from './filterZoneValidFloors';
import {
    ITenant2ZoneRelation,
    IReportingObject,
    IMsDataObject,
    IPeriod,
    ITenant2FloorRelation,
} from '../../../../../../General.interfaces';
import { cloneDeep } from 'lodash';
import { ZONES_WORD } from '../../../../../../constants/constants';
import filterTenantValidFloors from './filterTenantValidFloors';

interface IArgs {
    reportingObjectsById: { [reportingObjectId: string]: IReportingObject };
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    tenant2FloorRelations: ITenant2FloorRelation[];
    tenant2ZoneRelations: ITenant2ZoneRelation[];
    selectedGroupMarker: string | undefined;
    selectedReportingObjectsIds: number[];
    msDataObjects: IMsDataObject[];
    dateRanges: IPeriod[];
    period: IPeriod;
}

/**
 * Функция для получения дополнительных id для запроса метрик отчетных объектов
 * @param reportingObjectsByTypeAndMarker Объект отчетных объектов где ключ это [тип:маркер]
 * @param selectedReportingObjectsIds id выбранных отчетных объектов
 * @param tenant2FloorRelations Взаимосвязь арендатора и этажей
 * @param tenant2ZoneRelations Взаимосвязь арендатора и зоны
 * @param reportingObjectsById Отчетные объекты, где ключ это id отчетного объекта
 * @param selectedGroupMarker Выбранный маркер группы
 * @param msDataObjects Данные для отчетных объектов
 * @param dateRanges Периоды дат для главного периода
 * @param period Главный период
 */
const getAdditionalIdsForMetricsRequest = (args: IArgs): number[] => {
    const {
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectsIds,
        tenant2FloorRelations,
        tenant2ZoneRelations,
        reportingObjectsById,
        selectedGroupMarker,
        msDataObjects,
        dateRanges,
        period,
    } = args;
    const ids = cloneDeep(selectedReportingObjectsIds);

    const locationId = reportingObjectsByTypeAndMarker['location:location']?.id;
    locationId && ids.push(locationId);

    selectedReportingObjectsIds.forEach((id) => {
        const reportingObject = reportingObjectsById[id];

        if (reportingObject) {
            if (reportingObject.object_type === 'tenant') {
                const validZone = filterTenantValidZone({
                    reportingObjectsByTypeAndMarker,
                    mainDateRanges: dateRanges,
                    tenant: reportingObject,
                    tenant2ZoneRelations,
                    selectedGroupMarker,
                    mainPeriod: period,
                });

                const tenantValidFloors = filterTenantValidFloors({
                    reportingObjectsByTypeAndMarker,
                    mainDateRanges: dateRanges,
                    tenant: reportingObject,
                    tenant2FloorRelations,
                    mainPeriod: period,
                });

                if (validZone) ids.push(validZone.id);
                if (tenantValidFloors) tenantValidFloors.forEach((element) => ids.push(element.id));
            } else if (reportingObject.object_type.includes(ZONES_WORD)) {
                ids.push(reportingObject.id);

                const zonesValidFloors = filterZoneValidFloors({
                    zoneMarker: reportingObject.marker,
                    reportingObjectsByTypeAndMarker,
                    mainDateRanges: dateRanges,
                    mainPeriod: period,
                    msDataObjects,
                });

                if (zonesValidFloors) zonesValidFloors.forEach((element) => ids.push(element.id));
            } else if (reportingObject.object_type === 'floor') {
                ids.push(reportingObject.id);
            }
        }
    });

    return Array.from(new Set(ids));
};

export default getAdditionalIdsForMetricsRequest;
