import styled from 'styled-components/macro';

export const Wrapper = styled.div<{
    $isBorder?: boolean;
}>`
    /* display: none; */
    margin-top: var(--space-xs);
    border-left:${({ $isBorder = true }) => ($isBorder ? ' 1px solid var(--color-secondary)' : 'none')};
    border-top: 1px solid var(--color-secondary);
    border-right: ${({ $isBorder = true }) => ($isBorder ? ' 1px solid var(--color-secondary)' : 'none')};
    border-bottom: ${({ $isBorder = true }) => ($isBorder ? ' 1px solid var(--color-secondary)' : 'none')};
    
    padding: var(--space-xs);
    display: flex;
    align-items: center;
    border-radius:${({ $isBorder = true }) => ($isBorder ? '5px' : '0')};
    column-gap: 16px;

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        padding: 0;
        border: none;
        border-radius: 0px;
        column-gap: 0px;
    }
`;

export const NumberToShowTitle = styled.div`
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        display: none;
    }
`;
