import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { ICell, IFilter, IReducerState } from './interfaces';
import generateDecisionTableRow from './tools/generateDecisionTableRow';
import { cloneDeep, isString } from 'lodash';
import ReactDataSheet from 'react-datasheet';

const initialState: IReducerState = {
    decisionsTableData: [],
    decisionsTableFilters: null,
};

const Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer = createSlice({
    name: 'Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение данных для таблицы решений
         */
        storeDecisionsTableData: (state, action: PayloadAction<ICell[][]>) => {
            state.decisionsTableData = action.payload;
        },
        /**
         * Добавление новой строки
         */
        addNewDecisionsTableRow: (state) => {
            const row = generateDecisionTableRow({ changeType: 'POST' });
            state.decisionsTableData.splice(1, 0, row);
        },

        /**
         * Копирование строки
         */
        copyDecisionTableRow: (state, action: PayloadAction<{ frontId: string }>) => {
            const decisionsTableData = cloneDeep(state.decisionsTableData);
            const { frontId } = action.payload;

            const indexToInsert = decisionsTableData.findIndex((row) => row[0].frontId === frontId);
            if (indexToInsert > -1) {
                const row = generateDecisionTableRow({ row: decisionsTableData[indexToInsert], changeType: 'POST' });
                state.decisionsTableData.splice(indexToInsert, 0, row);
            }
        },

        /**
         * Удаление строки
         */
        deleteDecisionTableRowRow: (state, action: PayloadAction<{ frontId: string }>) => {
            const decisionsTableData = cloneDeep(state.decisionsTableData);
            const { frontId } = action.payload;

            const rowIndex = decisionsTableData.findIndex((row) => row[0].frontId === frontId);

            if (rowIndex > -1 && decisionsTableData[rowIndex]?.[0].decision) {
                decisionsTableData[rowIndex] = decisionsTableData[rowIndex].map((cell) => ({
                    ...cell,
                    changeType: 'DELETE',
                }));
                state.decisionsTableData = decisionsTableData;
            } else {
                state.decisionsTableData = decisionsTableData.filter((row) => {
                    return row[0].frontId !== frontId;
                });
            }
        },

        /**
         * Изменения нескольких строк  и значений в их колонках
         */
        batchDecisionsTableUpdate: (state, action: PayloadAction<ReactDataSheet.CellsChangedArgs<ICell>>) => {
            const decisionsTableData = cloneDeep(state.decisionsTableData);

            action.payload.forEach((args) => {
                const { cell, row, col, value } = args;

                if (cell && isString(value)) {
                    const prevValue = decisionsTableData[row]?.[col] as undefined | ICell;

                    if (prevValue) {
                        decisionsTableData[row][col] = {
                            ...prevValue,
                            value,
                        };
                        decisionsTableData[row] = decisionsTableData[row].map((cell) => ({
                            ...cell,
                            changeType: cell.decision ? 'PATCH' : 'POST',
                        }));
                    }
                }
            });

            state.decisionsTableData = decisionsTableData;
        },

        /**
         * Добавление нового фильтра
         */
        addNewFilter: (state, action: PayloadAction<IFilter>) => {
            const decisionsTableFilters = cloneDeep(state.decisionsTableFilters);
            state.decisionsTableFilters = {
                ...(decisionsTableFilters || {}),
                [action.payload.columnId]: action.payload,
            };
        },

        /**
         * Обнуление всех фильтров
         */
        resetFilters: (state) => {
            state.decisionsTableFilters = null;
        },
    },
});

export const {
    storeDecisionsTableData,
    addNewDecisionsTableRow,
    copyDecisionTableRow,
    deleteDecisionTableRowRow,
    batchDecisionsTableUpdate,
    addNewFilter,
    resetFilters,
} = Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer.actions;

export const Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer_Values = (state: RootState) =>
    state.Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer;

export default Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer.reducer;
