import React, { useCallback, useMemo } from 'react';
import { ArrowSortUpLinesFilled, ArrowSortDownLinesFilled, ArrowSortFilled } from '@fluentui/react-icons';

import { toString, emptyArray } from 'src/tools';

import { TableContext } from '../interfaces';
import { SORT_SWITCH_WAY, prepareFiltersByColumns } from '../utils';

import { StyledHeaderCellContainer, StyledIcon } from './styles';
import { FilterMenu } from './FilterMenu';
import IconButton from 'src/components/IconButton/IconButton';

const SORT_ICONS = {
    asc: <ArrowSortUpLinesFilled />,
    desc: <ArrowSortDownLinesFilled />,
    undefined: <ArrowSortFilled />,
};

export type HeaderCellProps = {
    tableContextRef: React.MutableRefObject<TableContext>;
    columnId: string;
    hasSort: boolean;
    className?: string;
    isCancellableSort?: boolean;

    children: React.ReactNode;
};

export const HeaderCell: React.FC<HeaderCellProps> = ({
    columnId,
    hasSort,
    className,
    tableContextRef,
    isCancellableSort = false,

    children,
}) => {
    const { changeSorter, sorters, filters, changeFilter } = tableContextRef.current;

    const sorter = (sorters || emptyArray).find((sorter) => sorter.columnId === columnId);
    const sortOrder = sorter?.sortOrder;

    const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
        (event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();

            const getNewValue = SORT_SWITCH_WAY[toString(isCancellableSort)];
            const newSort = getNewValue(sortOrder);

            changeSorter([{ sortOrder: newSort, columnId }]);
        },
        [changeSorter, isCancellableSort, sortOrder, columnId],
    );

    const sortIcon = SORT_ICONS[toString(sortOrder)];

    const filtersRecord = useMemo(() => {
        return prepareFiltersByColumns(filters);
    }, [filters]);

    const columnFilters = filtersRecord[columnId];
    const hasFilters = columnFilters?.length > 0;
    return (
        <StyledHeaderCellContainer className={className}>
            {children}
            {hasSort && (
                <StyledIcon onClick={handleClick} sortOrder={sortOrder} style={{ marginLeft: 5 }}>
                    {sortIcon}
                </StyledIcon>
            )}
            {hasFilters && <FilterMenu columnFilters={columnFilters} changeFilter={changeFilter} />}
        </StyledHeaderCellContainer>
    );
};
