import React, { useRef, useState } from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { HotTable } from '@handsontable/react';

// import { isMobileInboundWidth } from 'src/theme';
// import { emptyObject } from 'src/tools';

import WidgetTitleWrapper from 'src/components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetWrapper from 'src/components/Wrappers/WidgetWrapper/WidgetWrapper';
import WidgetTitle from 'src/components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetAdditionalControls from 'src/components/WidgetAdditionalControls/WidgetAdditionalControls';
// import { generalReducerValues } from 'src/General.reducer';

import { WIDGET_NAME } from '../../constants';

import useFetchData from './api/useFetchData';
import { tenantsAndPosAdapter } from './api/dataAdapters/tenantsAndPosAdapter';
import { TenantPosList } from './components';
import { PosExportsContext, ICsvExportContext } from './context';
import { TenantsList } from './components/TenantsList/TenantsList';
import { DynamicsPos } from './components/DynamicsPos/DynamicsPos';

export const TenantsPosWidget: React.FC = () => {
    const [handleCsvExport, setHandleCsvExport] = useState<() => void>();
    const [tableName, setTableName] = useState<string>('');

    // const {
    //     mainAreaSize: { width },
    // } = useSelector(generalReducerValues);

    const { t } = useTranslation();
    const ref = useRef(null);

    const { refetch } = useFetchData(tenantsAndPosAdapter);

    const context: ICsvExportContext = {
        setCsvExportHandler: (handler?: () => void, tableName: string = '') => {
            if (handler) {
                setTableName(tableName);
            }
            setHandleCsvExport(() => handler);
        },
        csvExportHandler: handleCsvExport,
    };

    return (
        <PosExportsContext.Provider value={context}>
            <WidgetWrapper ref={ref}>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t(WIDGET_NAME)}

                        <WidgetAdditionalControls
                            widgetName={WIDGET_NAME}
                            pdfDownloadData={{
                                targetRef: ref,
                            }}
                            {...{ csvDownloadData: { onDownload: handleCsvExport, tableName } }}
                            reloadHandlerNoDispatch={refetch}
                            info="Click on any tenant (tenants) to get a detailed report of POS"
                        />
                    </WidgetTitle>
                </WidgetTitleWrapper>

                <TenantsList />
                <br />

                <TenantPosList />

                <DynamicsPos />
            </WidgetWrapper>
        </PosExportsContext.Provider>
    );
};
