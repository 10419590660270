import {
    AccordionIcon,
    AccordionItemBody,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemStyle,
} from '../styles/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { IAccordionItemProps } from '../configurations';
import Title from '../../Title/Title';
const AccordionItem: React.FC<IAccordionItemProps> = ({
    isOpened = false,
    icon = null,
    label,
    id,
    content,
    handleHeaderClick,
}) => {
    const [opened, setOpened] = useState(isOpened);
    useEffect(() => {
        setOpened(() => isOpened);
    }, [isOpened]);

    const onHeaderClick = (id: number | string) => () => {
        handleHeaderClick(id);
        setOpened(!opened);
    };

    return (
        <AccordionItemStyle>
            <AccordionItemHeading onClick={onHeaderClick(id)}>
                <AccordionItemButton>
                    <Title titleLevel="h4">{label}</Title>
                    <AccordionIcon opened={opened}>{icon}</AccordionIcon>
                </AccordionItemButton>
            </AccordionItemHeading>
            {opened && <AccordionItemBody>{content}</AccordionItemBody>}
        </AccordionItemStyle>
    );
};

export default AccordionItem;
