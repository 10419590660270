export const DYNAMICS_PMO_QUERY_KEY = 'DYNAMICS_PMO_QUERY_KEY';
export const DYNAMICS_PMO_WEATHER_QUERY_KEY = 'DYNAMICS_PMO_WEATHER_QUERY_KEY';

export const TITLE_MAP_BY_CHART_TYPE = {
    periods: 'For periods',
    metrics: 'Comparison of metrics for',
    objects: 'Objects comparison for',
};

export const WEATHER_METRICS_BY_NAME = {
    cloudCover: 'fwe_cloud * 100',
    precipitation: 'fwe_prec_amount * core_count_time_split{split_freq:H}',
    temperature: 'fwe_temp',
    pressure: 'fwe_press',
};

export const TREND_SERIES_ID_PART = 'TREND_SERIES_ID_PART';
export const AVERAGE_SERIES_ID_PART = 'AVERAGE_SERIES_ID_PART';
