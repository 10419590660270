import checkResponseForError from './checkResponseForError';
import { IResponseAnalyzerResult, TServerResponse } from './handleServerResponse';
import { AxiosResponse } from 'axios';

/**
 * Функциядля обработки ответа с сервера
 */
const responseAnalyzer = (response: TServerResponse): IResponseAnalyzerResult => {
    /** Обработка кейса, когда ответ от сервера является массивом */
    if (Array.isArray(response)) {
        if (response.length === 0) {
            return {
                isError: true,
                isCanceled: false,
                result: { status: 'No data', message: 'No data' },
                initialData: null,
            };
        }

        const checkResult = checkResponseForError(response[0]);
        if (checkResult.isError) return checkResult;

        return {
            result: response.map((element) => {
                let result = element.data;
                if (element.data.result) result = element.data.result;
                return result;
            }),
            isCanceled: false,
            isError: false,
            initialData: checkResult.initialData,
        };
    }

    /** Обработка дефолтного кейса (не массив) */
    const checkResult = checkResponseForError(response);
    if (checkResult.isError) return checkResult;

    const { data } = response as AxiosResponse;
    let result = data;

    if (data?.result) result = data.result;

    return { isError: false, isCanceled: false, result, initialData: checkResult.initialData };
};

export default responseAnalyzer;
