import { FC } from 'react';
import { IProps } from './interfaces';
import { Name, Percent, Rating, RatingWrapper, Td, Tr, Value } from '../../styles/styles';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';

/**
 * Компонент для отображения одной строки в рейтинге
 */
const Row: FC<IProps> = ({ data, widgetType }) => {
    return (
        <Tr>
            <Td width={'100%'} minWidth={'50px'}>
                <Name title={data.name} text={data.name}>
                    <RatingWrapper>
                        <Rating width={data.mainValue.percentOfMax} />
                    </RatingWrapper>
                </Name>
            </Td>
            {widgetType === 'passwaysTraffic' && (
                <Td>
                    <Value>
                        {valueFormatter({
                            value: data.mainValue.percentOfTotal,
                            showSpaceBetweenNumberAndUnit: false,
                            precision: 1,
                            units: '%',
                        })}
                    </Value>
                </Td>
            )}

            <Td>
                <Value color={widgetType === 'passwaysTraffic' ? 'var(--color-secondary-darker)' : 'inherit'}>
                    {data.mainValue.value}
                </Value>
            </Td>
            <Td>
                <Percent color={data.diff.color}>{data.diff.value}</Percent>
            </Td>
        </Tr>
    );
};

export default Row;
