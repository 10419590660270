import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useEffect } from 'react';
import { storeAllFloors, storeRatingType, storeSelectedFloor } from '../reducer';
import { cloneDeep } from 'lodash';

const usePrepareData = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);

    const dispatch = useDispatch();

    /** Получение начальных значений для зон, группы зон и этажа */
    useEffect(() => {
        if (!Object.keys(reportingObjectsByType).length) return;
        const allFloors = reportingObjectsByType['floor'];
        dispatch(storeRatingType('location'));

        if (!allFloors) {
            dispatch(storeAllFloors([]));
            dispatch(storeSelectedFloor(null));
        } else {
            const sorted = cloneDeep(allFloors).sort(
                (a, b) => Number(b.object_params.floor) - Number(a.object_params.floor),
            );
            dispatch(storeAllFloors(sorted));
            const selectedFloor = Number(sorted[0].object_params.floor);
            dispatch(storeSelectedFloor(selectedFloor));
        }
    }, [dispatch, reportingObjectsByType]);
};

export default usePrepareData;
