import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGroupDataObjectsResponse, IReportingObject } from '../../../General.interfaces';
import { generalReducerValues } from '../../../General.reducer';
import { IGroupDataObjectsDAResult } from '../../../tools/API/hooks/groupDataObjects/interfaces';
import { Network_Section_Reducer_Values } from '../reducer';
import getGroupObjectId from './getGroupObjectId';

/**
 * Хук для получения отчетных объектов из выбранной группы
 */
const useGDOSelectedGroup = (
    data: UseQueryResult<IGroupDataObjectsDAResult, Error>[],
    groupIds: (string | number | undefined)[],
): { group_name: string; items: IReportingObject[] }[] => {
    const {
        cfg: { reportingObjectsById },
    } = useSelector(Network_Section_Reducer_Values);
    const {
        cfg: { locationsById },
    } = useSelector(generalReducerValues);

    return useMemo(() => {
        const groups: IGroupDataObjectsResponse[] = [];

        data.forEach((item) => {
            item.data?.data.forEach((group) => {
                if (groupIds.includes(getGroupObjectId(item.data.objectType.section, group.group_name))) {
                    groups.push(group);
                }
            });
        });

        return groups.map((group) => {
            const items: IReportingObject[] = [];

            group.items.forEach((item) => {
                if (reportingObjectsById[item.id]) {
                    const name = `${item?.object_name} (${locationsById?.[String(item?.pl_id)]?.name ?? ''})`;
                    items.push({ ...reportingObjectsById[item.id], name });
                }
            });
            return {
                ...group,
                items,
            };
        });
    }, [data, groupIds, locationsById, reportingObjectsById]);
};

export default useGDOSelectedGroup;
