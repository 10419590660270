import { omit } from 'lodash';
import generateId from '../../../../../../tools/generateId';
import { IDecision } from '../../../../interfaces';
import { DECISIONS_TABLE_COLUMNS } from '../constants/constants';
import { ICell, TChangeType } from '../interfaces';

export interface IArgs {
    decision?: IDecision;
    isHeader?: boolean;
    row?: ICell[];
    changeType?: TChangeType;
}

const generateDecisionTableRow = (args: IArgs): ICell[] => {
    const { decision, isHeader, row, changeType } = args;
    const frontId = generateId();
    const result: ICell[] = DECISIONS_TABLE_COLUMNS.map((column) => {
        const cell = {
            ...omit(column, ['getCellValue', 'component']),
            frontId,
            decision,
            isHeader: Boolean(isHeader),
            readOnly: Boolean(isHeader),
            value: '',
            changeType,
        };
        const valueFromDecision = decision?.[column.decisionDataKey || ''];

        if (row) {
            const cellFromRow = row.find((cell) => cell.columnId === column.columnId);

            if (cellFromRow) {
                cell.value = cellFromRow.value;
            }
        }

        if (typeof valueFromDecision === 'string') {
            cell.value = valueFromDecision;
        }

        if (column.getCellValue) {
            cell.value = column.getCellValue(cell);
        }

        if (isHeader) {
            cell.value = column.headerText;
        }
        return cell;
    });
    return [...result];
};

export default generateDecisionTableRow;
