import { useMemo, useRef } from 'react';

import { ChartWrapper } from './styles';
import Dynamics from '../../../../../components/Charts/Dynamics/Dynamics';
import Funnel from './components/Funnel/Funnel';
import {
    IdsOfFunnelSettings,
    optionsOfFunnelSettings,
    PATH_TO_PURCHASE_FUNNEL_SETTINGS,
    PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS,
} from '../../constants/constants';
import { ResponseStatus } from '../../../../../tools/API/constants';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import getDataForLoadingWrapper from '../../../../Maps/CrossVisits/widgets/CrossVisitsWidget/tools/getDataForLoadingWrapper';
import { Performance_PathToPurchase_Widget_Reducer_Values, reloadWidget } from './reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { withLoading } from '../../../../../tools/API/withLoading';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { cloneDeep } from 'lodash';
import { TSeries } from '../../../../../components/Charts/Dynamics/interfaces';
import Stack from '../../../../../components/Stack/Stack';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { changeOptions } from '../../../../../components/UserSettings/reducer';

/**
 * Компонент для отображения виджета Путь к покупке
 */
const PathToPurchaseWidget = () => {
    const { rawMetricsData, chartOptionsGeneratorSettings, extendedReportingObjects } = useSelector(
        Performance_PathToPurchase_Widget_Reducer_Values,
    );
    const { structures } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const ref = useRef(null);
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const chart = useMemo(() => {
        const loadingData = getDataForLoadingWrapper([
            structures || { status: ResponseStatus.Error, message: t('Get structure error') },
            rawMetricsData,
        ]);
        const WithLoadingTable = withLoading(ChartWrapper, { height: 400, data: loadingData });
        const translatedChartOptions = cloneDeep(chartOptionsGeneratorSettings);
        if (translatedChartOptions) {
            const translatedSeries = chartOptionsGeneratorSettings?.series.map((element) => {
                return {
                    ...element,
                    name: t(element.name),
                    data: element.data.map((element) => ({ ...element, name: t(element.name) })),
                };
            }) as TSeries[];
            translatedChartOptions.series = translatedSeries;
        }

        return (
            <WithLoadingTable>{translatedChartOptions && <Dynamics {...translatedChartOptions} />}</WithLoadingTable>
        );
    }, [rawMetricsData, structures, chartOptionsGeneratorSettings, t]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Path to purchase')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName={'Path to purchase'}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>

            {chart}
            {extendedReportingObjects && (
                <UniversalSelect
                    defaultSelected={[optionsOfFunnelSettings[0]]}
                    name={PATH_TO_PURCHASE_FUNNEL_SETTINGS}
                    options={optionsOfFunnelSettings}
                    labelText={t('Funnel settings')}
                    mode="single"
                    dropdownWidth="md"
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
            )}

            <Stack flexWrap={'wrap'} gap={10} padding={'15px 0'}>
                {(extendedReportingObjects || []).map((element) => (
                    <Funnel
                        funnelSteps={localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS] || []}
                        funnelSettings={
                            localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_SETTINGS]?.[0]?.id ||
                            IdsOfFunnelSettings.PercentageOfTheMall
                        }
                        key={element.reportingObjectData.id}
                        data={element}
                    />
                ))}
            </Stack>
        </WidgetWrapper>
    );
};

export default PathToPurchaseWidget;
