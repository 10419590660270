import { DateTime } from 'luxon';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { rawData } from '../../rawData';
import { Cases_Calculator_Calculator_Widget_Reducer_Values } from '../../reducer';
import { Cell, Row, TableWrapper } from './styles';
import { canShowOCR, namesMap } from '../../tenantsOptions';

const MainTable: React.FC = () => {
    const { moduleName } = useSelector(Cases_Calculator_Calculator_Widget_Reducer_Values);
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const tenantId = localCurrentOptions?.['tenant']?.[0]?.id;
    const tenantName = localCurrentOptions?.['tenant']?.[0]?.text;
    const ref = localCurrentOptions?.[`referenceGroup:${tenantId}`];
    const rent = localCurrentOptions?.['rentInMonth'];
    const indexPercent = localCurrentOptions?.['indexPercent'];
    const indexingDate = localCurrentOptions?.['indexingDate'];
    const selectedMetricsIds = localCurrentOptions?.['myMetrics']?.map((item: any) => item.id);
    const mainPeriodId = localCurrentOptions?.mainPeriod?.id;
    const mainPeriod = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriodId)[0]?.period;

    const rows: any[][] = [];

    if (!rawData[tenantId]) return null;

    Object.keys(rawData[tenantId])
        ?.filter((key) => {
            switch (key) {
                case 'traffic':
                case 'sales_no_vat':
                case 'sales_per_area_no_vat':
                    return selectedMetricsIds?.includes(key);

                case 'sales_per_area_uniq_no_vat':
                    return selectedMetricsIds?.includes(key) && ref?.length > 0;

                case 'profit':
                case 'ocr':
                    return canShowOCR(tenantId, rent, indexingDate, indexPercent);

                default:
                    return false;
            }
        })
        .forEach((key) => {
            const group = rawData[tenantId][key]?.filter((item) => {
                return (
                    DateTime.fromISO(item.date).toMillis() >= DateTime.fromISO(mainPeriod?.dateFrom || '').toMillis() &&
                    DateTime.fromISO(item.date).toMillis() <= DateTime.fromISO(mainPeriod?.dateTo || '').toMillis()
                );
            });
            let row1: any[] = [
                { title: true, value: namesMap(t)[key] },
                { isYear: true, value: '2023' },
            ];

            let row2: any[] = [{ value: '' }, { string: true, value: `${t('Dev.')} 23/22` }];
            let row3: any[] = [{ value: '' }, { string: true, value: `${t('Dev.')} 23/21` }];
            group.forEach((item) => {
                row1.push(item[2023]);
                row2.push(item['Dev. 23/22']);
                row3.push(item['Dev. 23/21']);
            });

            // Создаем последний столбец со средними значениями
            [row1, row2, row3].forEach((row) => {
                const percent = row[row.length - 1].percent;
                const ocr = row[row.length - 1].ocr;
                const filtered = row
                    ?.filter((item, i) => !isNaN(item.value) && i > 1)
                    .map((item) => Number(item.value));
                const average = _.sum(filtered) / filtered.length;
                row.push({ percent, ocr, value: Math.round(average) });
            });

            rows.push(row1, row2, row3);
        });

    const table = rows.map((row, j) => {
        let isTitleRow = false;
        const cells = row.map((cell, i) => {
            const percent = cell.percent ? '%' : '';

            const value =
                cell.title || cell.string || cell.value === ''
                    ? cell.value
                    : !cell.isYear
                    ? valueFormatter({ value: cell.value }) + percent
                    : cell.value + percent;
            if (cell.title) {
                isTitleRow = true;
            }
            let color = '#222529';
            if (cell.percent) {
                if (cell.value < -3) {
                    color = 'rgb(252, 54, 47)';
                } else if (cell.value >= -3 && cell.value <= 3) {
                    color = 'rgb(233, 200, 80)';
                } else {
                    color = 'rgb(9, 189, 57)';
                }
            }
            if (cell.ocr) {
                if (row[1].value === '2023') {
                    color = '#222529';
                } else {
                    if (cell.value > 3) {
                        color = 'rgb(252, 54, 47)';
                    } else if (cell.value >= -3 && cell.value <= 3) {
                        color = 'rgb(233, 200, 80)';
                    } else {
                        color = 'rgb(9, 189, 57)';
                    }
                }
            }
            return (
                <Cell key={`${j}++${i}`} isTitle={cell.title} color={color}>
                    {value}
                </Cell>
            );
        });
        return (
            <Row key={`${j}++row`} isTitleRow={isTitleRow}>
                {cells}
            </Row>
        );
    });

    // Создаем хедер таблицы
    const headerRow = [{ value: '' }, { value: '' }];
    rawData[tenantId]['traffic'].forEach((item) => {
        if (
            DateTime.fromISO(item.date).toMillis() >= DateTime.fromISO(mainPeriod?.dateFrom || '').toMillis() &&
            DateTime.fromISO(item.date).toMillis() <= DateTime.fromISO(mainPeriod?.dateTo || '').toMillis()
        ) {
            const dt = DateTime.fromISO(item.date).setLocale(lang);
            headerRow.push({ value: dt.toFormat('LLL yyyy') });
        }
    });
    headerRow.push({ value: t('Average') });

    const header = (
        <tr>
            {headerRow.map((item, i) => (
                <th key={`${i}===th`}>{item.value}</th>
            ))}
        </tr>
    );

    return (
        <TableWrapper>
            <table>
                <thead>{header}</thead>
                <tbody>{table}</tbody>
            </table>
        </TableWrapper>
    );
};

export default MainTable;
