import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    margin-top: var(--space-xs);
    border: 1px solid var(--color-secondary);
    padding: var(--space-xs);
    display: flex;
    align-items: center;
    border-radius: 5px;
    column-gap: 16px;

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        padding: 0;
        border: none;
        border-radius: 0px;
        column-gap: 0px;
    }
`;

export const NumberToShowTitle = styled.div`
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        display: none;
    }
`;
