import { FC } from 'react';
import { IProps } from './interfaces';
import PasswaysTrafficSelects from './components/PasswaysTrafficSelects/PasswaysTrafficSelects';
import TopByMetricsSelects from './components/TopByMetricsSelects/TopByMetricsSelects';
import TopByMetricsLocationsSelects from './components/TopByMetricsLocationsSelects/TopByMetricsLocationsSelects';
import TopByMetricsNetworkObjectsSelects from './components/TopByMetricsNetworkObjectsSelects/TopByMetricsNetworkObjectsSelects';

/**
 * Селекты для виджета
 */
const Selects: FC<IProps> = (props) => {
    return (
        <>
            {props.widgetType === 'topByMetrics' && <TopByMetricsSelects {...props} />}
            {props.widgetType === 'passwaysTraffic' && <PasswaysTrafficSelects {...props} />}
            {props.widgetType === 'topByMetricsLocations' && <TopByMetricsLocationsSelects {...props} />}
            {props.widgetType === 'topByMetricsNetworkObjects' && <TopByMetricsNetworkObjectsSelects {...props} />}
        </>
    );
};

export default Selects;
