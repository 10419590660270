import styled from 'styled-components/macro';

export const WidgetWrapper = styled.div<{ isRtlLanguage: boolean }>`
    position: relative;
    background-color: var(--color-white);
    padding: 0 var(--space-xs);
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xs);
    border-radius: 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
    margin-bottom: var(--space-xs);
`;
