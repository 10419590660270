import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { toggleArray } from 'src/tools';

import { cabinetPreferencesValues, patchSidebar } from '../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { modulesConfig } from '../../../constants/modulesConfig';
import { FAVORITES_NAME } from '../constants/constants';

/**
 * Кастомный хук для работы с настройками кабинета для пользователя
 */
const useSidebarOperations = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [counter, setCounter] = useState(0);
    const {
        preferences,
        sidebar: { openSections, favoriteModules, activeSection },
    } = useSelector(cabinetPreferencesValues);

    /**
     * Сайдбар: Если при загрузке выбран модуль в закрытой секции, открываем секцию.
     */
    useEffect(() => {
        if (location.pathname === '/' || modulesConfig.length === 0 || !preferences?.id || counter > 0) return;

        const moduleId = modulesConfig.find((item) => item.service.path === location.pathname)?.module_id;
        const moduleName = modulesConfig.find((item) => item.module_id === moduleId)?.module_name;
        const sectionName = moduleName?.split(':')[0];

        if (sectionName && moduleId && activeSection === FAVORITES_NAME && favoriteModules.includes(moduleId)) {
            dispatch(patchSidebar({ openSections: Array.from(new Set([...openSections, FAVORITES_NAME])) }));
            setCounter(counter + 1);
        } else if (sectionName && moduleId && !openSections.includes(sectionName)) {
            dispatch(
                patchSidebar({ openSections: toggleArray(openSections, sectionName), activeSection: sectionName }),
            );
            setCounter(counter + 1);
        } else if (activeSection !== sectionName) {
            dispatch(patchSidebar({ activeSection: sectionName }));
            setCounter(counter + 1);
        }
    }, [location.pathname, preferences?.id, counter, modulesConfig]);
};

export default useSidebarOperations;
