import styled from 'styled-components/macro';

export const TreeWrapper = styled.div`
    padding: var(--space-xxs) 0;
    /* max-height: 700px; */
    overflow: auto;
    /* font-weight: 700; */
`;

export const ItemWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const Icons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const IconWrapper = styled.div`
    margin-top: 6px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-left: var(--space-xxxxs);
    cursor: pointer;
`;

export const BothIconsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
`;
