import { IReportingObject, TMetricResponse } from '../../../../../../../../General.interfaces';
import { IDataAdapterResult } from '../interfaces';

const dataAdapter = (response: TMetricResponse[], reportingObject: IReportingObject): IDataAdapterResult => {
    for (const metricResponse of response) {
        for (const metricResponseItem of metricResponse) {
            if (metricResponseItem.context.data_objects[0].id === reportingObject.id) {
                return {
                    value: metricResponseItem.items[0].value,
                };
            }
        }
    }

    return {
        value: null,
    };
};

export default dataAdapter;
