import { GradientColorObject, PatternObject, SeriesTiledwebmapOptions } from 'highcharts';

import { isMobileOutboundWidth } from 'src/theme';
import { theme } from 'src/theme';

import { ISeries, TSeries } from '../../interfaces';

const SERIES_COLORS = theme.seriesColors;
const SERIES_COUNT = SERIES_COLORS.length;
/**
 * Функция для генерации настроек оси Y
 * @param series все серии
 * @param maxColors максимальное количесвто цветов
 * @param windowDimensions разрешение окна
 * @returns массив или объект с настройками оси Y
 */
const generateYAxis = (series: TSeries[], maxColors: number, windowDimensions: { width: number; height: number }) => {
    const yAxisCount = series?.reduce((acc, value) => (value.yAxis !== undefined ? acc + 1 : acc), 0);

    if (yAxisCount > 1) {
        return series.map(
            (
                element: SeriesTiledwebmapOptions &
                    ISeries & { color?: string | GradientColorObject | PatternObject | undefined },
                index: number,
            ) => {
                const colorIndex = index % SERIES_COUNT;
                return {
                    labels: { style: { color: element.color || SERIES_COLORS[colorIndex] } },
                    opposite: index % 2 === 0 ? false : true,
                    title: { text: null },
                    softMin: 0,
                    visible: isMobileOutboundWidth(windowDimensions.width),
                };
            },
        );
    }
    return {
        labels: { style: { color: '#666666;' } },
        title: { text: null },
        softMin: 0,
    };
};

export default generateYAxis;
