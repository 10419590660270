import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Highcharts, { Chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import addTreemapModule from 'highcharts/modules/heatmap';
import HighchartsColorAxis from 'highcharts/modules/coloraxis';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import generateOptions from './tools/generateOptions';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
addTreemapModule(Highcharts);
HighchartsColorAxis(Highcharts);
NoDataToDisplay(Highcharts);

const HeatMap: React.FC<IProps> = forwardRef<HighchartsReact.RefObject, IProps>((props, ref) => {
    const [options, setOptions] = useState<Highcharts.Options>();

    useEffect(() => {
        setOptions(generateOptions({ options: props.options, customChartDimensions: props.customChartDimensions }));
    }, [props.options, props.customChartDimensions]);

    const afterChartCreated = (chart: Chart) => {
        props.storeChartInstance && props.storeChartInstance(chart);
    };

    const chart = useMemo(() => {
        if (options) {
            return <HighchartsReact callback={afterChartCreated} highcharts={Highcharts} ref={ref} options={options} />;
        }
        return null;
    }, [options]);

    return <Wrapper customHeight={props.customChartDimensions?.height}>{chart}</Wrapper>;
});

export default HeatMap;
