import { ResponseStatus } from '../../../../../../tools/API/constants';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import usePrepareData from './usePrepareData';
import { Reports_Basic_Widget_Reducer_Values, storeRawMetricsData } from '../reducer';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
import { DS } from '../../../../../../constants/constants';

/** кастомный хук для получения данных */
export const useGetMetrics = () => {
    const { downloadSignal, keyWord } = useSelector(Reports_Basic_Widget_Reducer_Values);
    const { currentModuleID } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { token } = useSelector(generalReducerValues);

    const fetchData = useRequestMetrics();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePrepareData();

    useEffect(() => {
        if (
            !localCurrentOptions?.[`mainPeriod${DS}${keyWord}`] ||
            !localCurrentOptions?.[`mainDateRanges${DS}${keyWord}`] ||
            !localCurrentOptions?.[`detail${DS}${keyWord}`] ||
            !localCurrentOptions?.[`selectedMetrics${DS}${keyWord}`] ||
            !localCurrentOptions?.[`selectedReportingObjectsIds${DS}${keyWord}`]
        )
            return;

        const [mainDateRange] = localCurrentOptions?.[`mainDateRanges${DS}${keyWord}`]?.filter(
            (item: { id: string }) => item.id === localCurrentOptions?.[`mainPeriod${DS}${keyWord}`]?.id,
        );
        const time_range = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];
        const time_freq: TTimeFreq = localCurrentOptions?.[`detail${DS}${keyWord}`].id as TTimeFreq;

        const controller = new AbortController();
        const signal = controller.signal;

        const requests: IRequestMetricsArgs[] = localCurrentOptions?.[`selectedMetrics${DS}${keyWord}`].map((metric: string) => {
            return {
                signal,
                token,
                alias: null,
                metric,
                obj_ids: localCurrentOptions?.[`selectedReportingObjectsIds${DS}${keyWord}`],
                object_aggregation: false,
                time_range,
                time_freq,
            };
        });

        dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

        fetchData(requests).then((responses: TServerResponse) => {
            /** Обработка ответа для метрик */
            handleServerResponse({
                responseAnalyzer: responseAnalyzer,
                success: storeRawMetricsData,
                error: storeRawMetricsData,
                res: responses,
                dispatch,
            });
        });

        return () => controller.abort();
    }, [downloadSignal]);
};
