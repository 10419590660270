import NumberToShow from '../../../../../../../components/NumberToShow/NumberToShow';
import { NumberToShowTitle, Wrapper } from './styles';
import { useDispatch } from 'react-redux';
import { storeTableItemsToShow } from '../../reducer';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';

/**
 * Компонент для отображения подвала страницы
 */
const Footer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSelect = (value: number | 'all') => {
        dispatch(storeTableItemsToShow(value));
    };

    return (
        <Wrapper>
            <NumberToShowTitle>{t('Show first')}</NumberToShowTitle>
            <NumberToShow handleSelect={handleSelect} defaultSelected={5} />
        </Wrapper>
    );
};

export default Footer;
