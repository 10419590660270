import { isNull, isUndefined } from 'lodash';

type TReturnFormat = 'string' | 'object';
type TDirection = 'up' | 'down' | 'usual';

// Перегрузка функции
export function getColorFromPercent(value: number | string | null | undefined): string;
export function getColorFromPercent(value: number | string | null | undefined, returnFormat: 'string'): string;

export function getColorFromPercent(
    value: number | string | null | undefined,
    returnFormat: 'object',
): {
    color: string;
    direction: TDirection;
};

/**
 * Получение цвета в зависимости от значения
 */
export function getColorFromPercent(
    value?: number | string | null | undefined,
    returnFormat: TReturnFormat = 'string',
) {
    let color = 'black';
    let direction: TDirection = 'usual';

    const returnHelper = (returnFormat: TReturnFormat) => {
        switch (returnFormat) {
            case 'string':
                return color;

            case 'object':
                return {
                    color,
                    direction,
                };

            default:
                return color;
        }
    };

    if (isNaN(Number(value)) || isNull(value) || isUndefined(value) || !isFinite(Number(value))) {
        return returnHelper(returnFormat);
    }

    if (Number(value) > 3) {
        color = 'var(--color-success)';
        direction = 'up';
    } else if (Number(value) < -3) {
        color = 'var(--color-accent)';
        direction = 'down';
    } else if (Number(value) >= -3 && Number(value) <= 3) {
        color = 'var(--color-warning)';
        direction = 'usual';
    }

    return returnHelper(returnFormat);
}
