import { increaseDownloadSignalCounter, Reports_Intersections_Widget_Reducer_Values } from './reducer';
import { withLoading } from '../../../../../tools/API/withLoading';
import { Table, TableWrapper } from './styles';
import useGridGenerator from './hooks/useGridGenerator';
import { useDispatch, useSelector } from 'react-redux';
import usePrepareData from './hooks/usePrepareData';
import ReactDataSheet from 'react-datasheet';
import React, { useEffect, useMemo, useState } from 'react';
import Title from '../../../../../components/Title/Title';
import ButtonsPanel from '../../../components/ButtonsPanel/ButtonsPanel';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../General.reducer';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useTranslation } from 'react-i18next';
import { DS } from '../../../../../constants/constants';
import { useGetReportFileName } from '../../../../../hooks/useGetReportFileName';
import { useCheckShopster } from './hooks/useCheckShopster';

/**
 * Компонент для отображения виджета среднедневынх пересечений
 */
const IntersectionsReportWidget: React.FC = () => {
    const {
        currentModuleID,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { grid, rawAverageIntersectionsData, keyWord } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const shopsterChecked = useCheckShopster();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const reportFileName = useGetReportFileName(keyWord, 'Daily intersections report');

    useGridGenerator();
    usePrepareData();

    const onDownloadClick = () => {
        dispatch(increaseDownloadSignalCounter());
    };

    const table = useMemo(() => {
        if (rawAverageIntersectionsData) {
            const WithLoadingTable = withLoading(TableWrapper, { height: 400, data: rawAverageIntersectionsData });

            return (
                <WithLoadingTable>
                    {grid && <ReactDataSheet data={grid} valueRenderer={(cell) => cell.value} />}
                </WithLoadingTable>
            );
        }
        return null;
    }, [rawAverageIntersectionsData, grid]);

    const reportFormat = useMemo(() => {
        return localCurrentOptions?.[keyWord ? `reportFormat${DS}${keyWord}` : 'reportFormat']?.[0]?.id;
    }, [keyWord, localCurrentOptions]);

    return (
        <WidgetWrapper styles={{ marginTop: 10 }}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title>{t('Daily intersections')}</Title>
                </WidgetTitle>
            </WidgetTitleWrapper>
            {reportFormat === 'plain' && <Table>{table}</Table>}
            <ButtonsPanel
                moduleName={`${currentModuleID}`}
                grid={grid || []}
                onPrepareClick={onDownloadClick}
                keyWord={keyWord}
                reportFileName={reportFileName}
                disableAllButtons={!shopsterChecked}
            />
        </WidgetWrapper>
    );
};

export default IntersectionsReportWidget;
