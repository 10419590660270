import { useEffect, useRef } from 'react';

/**
 * Кастомный хук для получения предыдущего состояния (значения)
 * @param value любое значение
 * @returns предыдущее состояние (значение)
 */
export const usePrevious = (value: any) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes

    return ref.current; //in the end, return the current ref value.
};
