import { FC } from 'react';
import { IProps as ISelectsProps } from '../../interfaces';
import SmartSwitch from '../../../../../../../SmartSwitch/SmartSwitch';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';

const PercentOfTotalSwitch: FC<ISelectsProps> = (props) => {
    const { t } = useTranslation();

    const onSmartSwitchChange = (args: { name: string; checked: boolean }) => {
        props.updateWidget('visual', { [args.name]: args.checked });
    };

    return (
        <SmartSwitch
            storePlace={props.widgetConfig?.visual}
            label={t('Show percent of total')}
            onChange={onSmartSwitchChange}
            name="showPercentOfTotal"
            defaultValue={Boolean(props.widgetConfig?.visual?.showPercentOfTotal)}
        />
    );
};

export default PercentOfTotalSwitch;
