import { FC, useMemo } from 'react';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Layer, Wrapper } from './styles';
import { useSelector } from 'react-redux';
import TextInput from '../../../../../../../../../components/TextInput/TextInput';

interface IProps {
    selectedReportingObjectsIds: number[];
}

/**
 * Компонент для отображения инпута с выбором отчетного объекта для события
 */
const ReportingObjectsSelect: FC<IProps> = ({ selectedReportingObjectsIds }) => {
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const outsideValue = useMemo(() => {
        return selectedReportingObjectsIds?.reduce((acc, id, index) => {
            if (index + 1 === selectedReportingObjectsIds.length) {
                acc += `${reportingObjectsById[id]?.name || id}`;
            } else {
                acc += `${reportingObjectsById[id]?.name || id}, `;
            }
            return acc;
        }, '');
    }, [selectedReportingObjectsIds, reportingObjectsById]);

    return (
        <Wrapper>
            <Layer />
            <TextInput
                hasError={outsideValue === ''}
                outsideValue={outsideValue}
                handleChange={() => {}}
                isMultiLine={true}
                fullWidth={true}
            />
        </Wrapper>
    );
};

export default ReportingObjectsSelect;
