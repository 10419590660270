import { DateTime } from 'luxon';
import { IMetricResponseItem, IResponseItem, TMetricResponse } from '../../../../../General.interfaces';
import aggregateMetricResponseByPeriodAndMetric from '../../tools/aggregateMetricResponseByPeriodAndMetric';
import { INumbersByWeekDaysVisual, TMetricsDAResponse } from '../interfaces';
import { isNumber } from 'lodash';
import getDifferenceBetweenNumbers from '../../../../../tools/getDifferenceBetweenNumbers';
import { NumbersWorkingModeIds } from '../../../WidgetsManager/enums';

/**
 * Функция для подготовки данных с сервера
 * @param response ответ от сервера
 * @param visual Визуальные настройки виджета
 * @returns подготовленные данные
 */
const numbersDefaultByDaysDataAdapter = (
    response: TMetricResponse[],
    visual?: INumbersByWeekDaysVisual,
): TMetricsDAResponse => {
    let mainPeriodValue: number | null = null;
    let percentDifference: string | null = null;
    const aggregatedRawData = aggregateMetricResponseByPeriodAndMetric(response);

    const filterMetricsByDaysOfTheWeek = (items: IResponseItem[]) => {
        return items.filter((item) => {
            const weekDay = DateTime.fromISO(item.time).setLocale('en-GB').weekdayLong?.toLowerCase() ?? '';

            return Boolean(visual?.selectedDaysOfTheWeek?.includes(weekDay));
        });
    };

    const aggregateModeHandler = (items: IResponseItem[]): number | null => {
        return items.reduce((acc: number | null, value) => {
            if (isNumber(value.value)) {
                if (isNumber(acc)) {
                    acc += value.value;
                } else {
                    acc = value.value;
                }
            }

            return acc;
        }, null);
    };

    const averageModeHandler = (items: IResponseItem[]): number | null => {
        const result = items.reduce((acc: number | null, value) => {
            if (isNumber(value.value)) {
                if (isNumber(acc)) {
                    acc += value.value;
                } else {
                    acc = value.value;
                }
            }

            return acc;
        }, null);
        if (isNumber(result)) {
            return result / items.length;
        }
        return null;
    };

    const periodHandler = (periodData?: IMetricResponseItem): number | null => {
        if (periodData && visual?.workingMode) {
            const filteredData = filterMetricsByDaysOfTheWeek(periodData.items);

            switch (visual.workingMode) {
                case NumbersWorkingModeIds.Sum: {
                    return aggregateModeHandler(filteredData);
                }
                case NumbersWorkingModeIds.DailyAverage: {
                    return averageModeHandler(filteredData);
                }

                default:
                    return null;
            }
        }

        return null;
    };

    const metric: string | undefined = Object.keys(Object.values(aggregatedRawData.mainPeriod)?.[0])?.[0];

    let mainPeriodResult: number | null = null;
    let comparePeriodResult: number | null = null;

    if (metric) {
        const mainPeriodMetricData: undefined | IMetricResponseItem = Object.values(aggregatedRawData.mainPeriod)[0]?.[
            metric
        ]?.[0];
        const comparePeriodMetricData: undefined | IMetricResponseItem = Object.values(
            aggregatedRawData.comparePeriod,
        )[0]?.[metric]?.[0];

        mainPeriodResult = periodHandler(mainPeriodMetricData);
        comparePeriodResult = periodHandler(comparePeriodMetricData);

        if (isNumber(mainPeriodResult) && isNumber(comparePeriodResult)) {
            const percentResult = getDifferenceBetweenNumbers(mainPeriodResult, comparePeriodResult).percentDifference;

            percentDifference = percentResult;
        }

        mainPeriodValue = mainPeriodResult;
    }

    return { mainPeriodValue, percentDifference };
};

export default numbersDefaultByDaysDataAdapter;
