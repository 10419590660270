import { DateTime } from 'luxon';
import { IPeriod, IReportingObject, ITenant2FloorRelation } from '../../../../../../General.interfaces';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';

interface IArgs {
    /**
     * Объект всех отчетных объектов, где ключ - это [тип отчетного объекта:маркер
     * отчетного объекта]
     */
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    /**
     * Связи арендаторов с этажами
     */
    tenant2FloorRelations: ITenant2FloorRelation[];
    /**
     * Периоды дат для главного периода
     */
    mainDateRanges: IPeriod[];
    /**
     * Арендатор, для которого нужно найти все этажи
     */
    tenant: IReportingObject;
    /**
     * Главный период
     */
    mainPeriod: IPeriod;
}

/**
 * Функция для получения отчетных объектов этажа, которые связаны
 * с конкретным арендатором
 */
const filterTenantValidFloors = (args: IArgs): IReportingObject[] | null => {
    const { reportingObjectsByTypeAndMarker, tenant2FloorRelations, mainDateRanges, tenant, mainPeriod } = args;

    const mainPeriodDateRanges = mainDateRanges?.find((element) => element.id === mainPeriod.id);

    if (mainPeriodDateRanges) {
        const validFloors = filterValidDateRanges(tenant2FloorRelations, mainPeriodDateRanges?.period).filter(
            (relation) => relation.tenant_id === tenant.id,
        );

        if (validFloors.length) {
            const result: IReportingObject[] = [];
            validFloors.forEach((floor) => {
                if (reportingObjectsByTypeAndMarker[`floor:${floor.floor}`]) {
                    result.push(reportingObjectsByTypeAndMarker[`floor:${floor.floor}`]);
                }
            });

            if (result.length) return result;
        }
    }
    return null;
};

export default filterTenantValidFloors;
