import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGenerateBackXLS } from './useGenerateBackXLS';
import Button from '../../../../components/Button/Button';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';
import { DS } from '../../../../constants/constants';

const DownloadXLSXFileButton: React.FC<{
    currentModuleID: string;
    keyWord?: string;
    reportFileName: string;
    disabled?: boolean;
}> = ({ currentModuleID, keyWord = '', reportFileName, disabled = false }) => {
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const [counter, setCounter] = useState(0);
    const status = useGenerateBackXLS(counter, `${currentModuleID}`, reportFileName, keyWord);
    const sendAnalytics = useSendSimpleAnalytics();

    const onClick = () => {
        const metricGroups = Array.from(
            new Set(
                localCurrentOptions?.[keyWord ? `selectedMetrics${DS}${keyWord}` : 'selectedMetrics']?.map(
                    (item: string) => {
                        return allMetrics?.find((metric) => metric.id === item)?.section;
                    },
                ),
            ),
        );

        if (metricGroups?.length) {
            metricGroups.forEach((group) => {
                sendAnalytics(`${keyWord || 'report'}_downloaded_${group}`);
            });
        } else {
            sendAnalytics(`${keyWord || 'report'}_downloaded`);
        }

        setCounter((counter) => counter + 1);
    };

    const buttonText = `${t(status)}`;
    return (
        <Button appearance="primary" text={buttonText} disabled={status !== 'Download' || disabled} onClick={onClick} />
    );
};

export default DownloadXLSXFileButton;
