import { Props } from './interfaces';
import React, { useId, useMemo } from 'react';
import { Shape } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { POLYGON_NEUTRAL_COLOR } from '../../../../constants';

/**
 * Компонент, отвечающий за отображения слоя периметра
 * @param selectedMarker Выбранный маркер
 * @param selectObject Функция для выбора маркера отчетного объекта
 * @param isBackground Если передан этот флаг, то цвет слоя всегда белый
 * @param stageScale Масштаб холста
 * @param heatColor Цвет, который зависит от значения метрики
 * @param object Сам объект
 * @param floor Этаж, где находится отчетный объект
 * @param plan Информация о плане
 */
const Perimeter: React.FC<Props> = ({
    heatColor,
    onMouseLeave: onMouseLeaveTrans,
    onMouseOver: onMouseOverTrans,
    selectedMarker,
    selectObject,
    availableMarkers,
    changeStageCursor,
    isBackground,
    stageIndex,
    object,
    floor,
}) => {
    const id = useId();

    const onClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        if (selectObject) {
            if (Array.isArray(availableMarkers)) {
                availableMarkers.includes(String(floor)) && selectObject(String(floor));
            } else {
                selectObject(String(floor));
            }
        }
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        if (Array.isArray(availableMarkers)) {
            !availableMarkers.includes(String(floor)) && changeStageCursor(stageIndex, 'not-allowed')();
        }
        e.cancelBubble = true;
        onMouseOverTrans && onMouseOverTrans(String(floor));
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        if (Array.isArray(availableMarkers)) {
            !availableMarkers.includes(String(floor)) && changeStageCursor(stageIndex, 'pointer')();
        }
        e.cancelBubble = true;
        onMouseLeaveTrans && onMouseLeaveTrans(null);
    };

    const fillColor = useMemo(() => {
        if (isBackground) return 'rgba(250, 250, 250, 0.8)';
        if (selectedMarker === String(floor)) return 'rgba(85, 125, 249, .7)';
        return heatColor || POLYGON_NEUTRAL_COLOR;
    }, [floor, heatColor, isBackground, selectedMarker]);

    return (
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                object.coords.coordinates.forEach((coord: number[], i: number) => {
                    if (i > 0) {
                        context.lineTo(coord[0], coord[1]);
                    }
                });
                context.lineTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                context.fillStrokeShape(shape);
            }}
            stroke={selectedMarker === String(floor) ? 'black' : POLYGON_NEUTRAL_COLOR}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
            key={`perometer-${id}`}
            id={`perometer-${id}`}
            onClick={onClick}
            fill={fillColor}
            onTap={onClick}
            strokeWidth={5}
            opacity={1}
        />
    );
};

export default Perimeter;
