import styled from 'styled-components/macro';
export const Tr = styled.tr``;

export const Table = styled.table`
    width: 100%;
    height: 100%;
`;

export const Td = styled.td<{ width?: string; minWidth?: string }>`
    position: relative;
    width: ${(p) => (p.width ? p.width : 'min-content')};
    min-width: ${(p) => (p.minWidth ? p.minWidth : 'min-content')};
    vertical-align: bottom;
    font-size: 13px;
    line-height: 13px;
`;
export const Name = styled.div`
    position: relative;
    margin-bottom: 5px;
`;

export const Value = styled.div<{ color?: string }>`
    color: ${(p) => (p.color ? p.color : 'initial')};
    width: 100%;
    padding: 0px var(--space-xxxs);
    white-space: nowrap;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const Percent = styled.div<{ color: string }>`
    color: ${(p) => p.color};
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding: 0px var(--space-xxxs);
    white-space: nowrap;
    height: 100%;
    font-weight: bold;
`;

export const Rating = styled.div<{ width: number; bgColor?: string }>`
    width: ${(p) => p.width + '%'};
    height: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: ${(p) => (p.bgColor ? p.bgColor : 'var(--color-primary)')};
`;

export const RatingWrapper = styled.div`
    background-color: var(--color-secondary);
    border-radius: 5px;
    margin-bottom: 2px;
`;
