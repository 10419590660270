import styled from 'styled-components/macro';

export const settingsIconStyles = {
    root: {
        fontSize: '20px',
        color: '#212529 !important',
    },
};

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        padding: 10px;
        /* max-width: 300px; */
    }
`;

export const TabletModeWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        padding: 10px;
        /* max-width: 300px; */
    }
`;
