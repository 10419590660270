import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IReducerState, TSelectedWidgetKey } from './interfaces';

const initialState: IReducerState = {
    selectedWidgetKey: 'eventTypes',
};

const Configuration_EventsConfig_Module_Redcuer = createSlice({
    name: 'Configuration_EventsConfig_Module_Redcuer',
    initialState,
    reducers: {
        /**
         * Сохранение ключа выбранного виджета
         */
        storeSelectedWidgetKey: (state, action: PayloadAction<TSelectedWidgetKey>) => {
            state.selectedWidgetKey = action.payload;
        },
    },
});

export const { storeSelectedWidgetKey } = Configuration_EventsConfig_Module_Redcuer.actions;

export const Configuration_EventsConfig_Module_Redcuer_Values = (state: RootState) =>
    state.Configuration_EventsConfig_Module_Redcuer;

export default Configuration_EventsConfig_Module_Redcuer.reducer;
