import styled from 'styled-components/macro';
import { GradientColorObject, PatternObject } from 'highcharts';

export const ChartDot = styled.div<{
    newColor: string | GradientColorObject | PatternObject | undefined;
    hideMargin?: boolean;
}>`
    height: 8px;
    flex: 0 0 8px;
    width: 8px;
    background-color: ${(props) => String(props.newColor)};
    border-radius: 50%;
    display: inline-block;

    margin: ${(p) => (p.hideMargin ? '0px' : '0 5px 0 0')};
`;
