import axios, { AxiosError, AxiosResponse } from 'axios';
import {
    addNewGlobalSpinnerItem,
    deleteGlobalSpinnerItemById,
    generalReducerValues,
    resetReportingObjects,
    storeObjects,
    storeStructures,
    updateGlobalSpinnerItemById,
} from '../../../General.reducer';
import { IStoreObjectsArgs } from '../../../General.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { CategoriesAPI } from '../categoriesAPI';
import requestStructure from '../requestStructure';
import { IRequestStructureArgs } from '../interfaces';
import generateId from '../../generateId';

const categoriesSrcMapping = {
    'project-categories': 'projectCategories',
    'data-obj-2-project-category': 'dataObj2ProjectCategory',
};

const textMap = {
    '0': 'Loading reporting objects...',
    '1': 'Loading project categories...',
    '2': 'Loading data object to project category...',
};
const errorTextMap = {
    '0': 'Loading reporting objects error',
    '1': 'Loading project categories error',
    '2': 'Loading data object to project category error',
};

/**
 * Кастомный хук для запроса структуры, связей и категорий
 */
export const useRequestMainStructures = () => {
    const dispatch = useDispatch();
    const {
        token,
        selectedLocationId,
        selectedProjectId,
        categoriesReload,
        user,
        initialDataReceived,
        urlsByServices,
    } = useSelector(generalReducerValues);

    const [structureUrl, categoriesUrl] = useMemo(() => {
        if (urlsByServices?.['core/structure-service'])
            return [
                urlsByServices['core/structure-service'].CACHED_STRUCTURE_URL,
                urlsByServices['core/admin-service'].CATEGORIES_URL,
            ];
        return [];
    }, [urlsByServices]);

    /** Обнуление отчетных объектов */
    useEffect(() => {
        dispatch(resetReportingObjects());
    }, [dispatch, selectedLocationId]);

    /** Получение отчетных объектов */
    useEffect(() => {
        if (
            selectedLocationId &&
            selectedProjectId &&
            initialDataReceived &&
            categoriesUrl &&
            structureUrl &&
            token &&
            user
        ) {
            const reportingObjectsPromise = requestStructure({
                token,
                queries: [
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'elements_admin_data_objects',
                    },
                ],
                jq: `map(.[]) | first`,
                url: structureUrl,
            });

            // const categoriesPromise = CategoriesAPI({
            //     user,
            //     token,
            //     chapter: 'categories',
            //     method: 'GET',
            //     url: categoriesUrl,
            // });

            const projectCategoriesPromise = CategoriesAPI({
                user,
                token,
                chapter: 'project-categories',
                method: 'GET',
                urlParams: { project_id: selectedProjectId },
                url: categoriesUrl,
            });

            const dataObj2ProjectCategoryPromise = CategoriesAPI({
                user,
                token,
                chapter: 'data-obj-2-project-category',
                method: 'GET',
                urlParams: { project_id: selectedProjectId, project_location_id: selectedLocationId },
                url: categoriesUrl,
            });

            const promises = [
                reportingObjectsPromise,
                // categoriesPromise,
                projectCategoriesPromise,
                dataObj2ProjectCategoryPromise,
            ];

            const reportingObjectsId = generateId();
            const categoriesId = generateId();
            const projectCategoriesId = generateId();
            const dataObj2ProjectCategoryId = generateId();

            const idsMap = {
                '0': reportingObjectsId,
                '1': projectCategoriesId,
                '2': dataObj2ProjectCategoryId,
            };

            promises.forEach((_, index) => {
                dispatch(addNewGlobalSpinnerItem({ id: idsMap[index], text: textMap[index] }));
            });

            axios.all(promises).then((res: (AxiosResponse | AxiosError)[]) => {
                res.forEach((response, index) => {
                    if (response.status !== 200 || (index === 0 && response?.['data']?.length === 0)) {
                        dispatch(updateGlobalSpinnerItemById({ id: idsMap[index], errorText: errorTextMap[index] }));
                    } else {
                        dispatch(deleteGlobalSpinnerItemById(idsMap[index]));
                    }
                });
                if (res.every((response) => response?.status === 200)) {
                    const data = {};
                    res.forEach((response: AxiosResponse, i) => {
                        const arr = response.request.responseURL.split('/');
                        let key = categoriesSrcMapping[arr[arr.length - 2]];
                        if (i === 0) {
                            key = 'reportingObjects';
                        }
                        data[key] = response.data;
                    });

                    dispatch(storeObjects(data as IStoreObjectsArgs));
                } else {
                    console.log('>>> useCategoriesAPI NET ERROR <<<');
                }
            });
        }
    }, [
        selectedLocationId,
        selectedProjectId,
        initialDataReceived,
        categoriesReload,
        categoriesUrl,
        structureUrl,
        token,
        user,
        dispatch,
    ]);

    /** Получение связей и элементов структуры */
    useEffect(() => {
        if (token && selectedLocationId && initialDataReceived && structureUrl) {
            const controller = new AbortController();
            const signal = controller.signal;

            const mainRequest: IRequestStructureArgs = {
                signal,
                token,
                url: structureUrl,
                queries: [
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_tenant2place',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_tenant2zone',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_tenant2floor',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_passway2dataobj',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_place2zone',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'elements_ms_data_objects',
                    },
                ],
            };

            dispatch(storeStructures(null));
            requestStructure(mainRequest).then((res) => {
                dispatch(storeStructures(res.data));
            });
        }
    }, [token, selectedLocationId, initialDataReceived, structureUrl, dispatch]);
};
