import { TooltipFormatterContextObject, TooltipOptions } from 'highcharts';
import { renderToString } from 'react-dom/server';
import Tooltip from '../../Tooltip/Tooltip';
import TooltipBody from '../../TooltipBody/TooltipBody';

interface IArgs extends TooltipOptions {
    tooltipOptions?: TooltipOptions;
}

const generateTooltip = (args: IArgs): TooltipOptions => {
    const { tooltipOptions } = args;

    return {
        formatter: function (this: TooltipFormatterContextObject) {
            return renderToString(
                <Tooltip
                    title={this.point?.['title'] || this.point?.name || String(this.x)}
                    subTitle={this.point?.['subTitle'] || ''}
                >
                    <TooltipBody points={this.points || [this]} />
                </Tooltip>,
            );
        },
        backgroundColor: 'transparent',
        animation: false,
        borderWidth: 0,
        useHTML: true,
        shadow: false,
        outside: true,
        hideDelay: 0,
        shared: true,
        padding: 0,
        ...tooltipOptions,
    };
};

export default generateTooltip;
