import { isNumber } from 'lodash';
import * as weatherIcons from '../assets/weather';
import { TTimeFreq } from '../../../../tools/API/interfaces';

interface IArgs {
    /** Температура */
    temperature?: number | null;
    /** Кол-во осадков */
    precipitation?: number | null;
    /** Облачность */
    cloudCover?: number | null;
    /** Атмосферное давление */
    pressure?: number | null;
    /** Дата для которой отобразить символ */
    dateTime: string;
    /** Текущая детализация графика */
    detail?: TTimeFreq;
}

const getWeatherMarkerSymbol = (args: IArgs): string => {
    const CLOUD_COVER_SUN_MIN_THRESHOLD = 25;
    const CLOUD_COVER_SUN_MAX_THRESHOLD = 50;
    let PRECIPITATION_THRESHOLD = 1;
    const TEMPERATURE_THRESHOLD = 0;

    const getSvgIcon = (iconKey: string) => {
        const key = `${iconKey}Animated`; // or Static
        return `url(${weatherIcons[key]})`;
    };

    /**
     * В зависимости от детализации графика выбираем порог для отображения
     * иконки осадков
     */
    switch (true) {
        case args.detail === 'H' || args.detail === '15MIN':
            PRECIPITATION_THRESHOLD = 0;
            break;

        case args.detail === 'D':
            PRECIPITATION_THRESHOLD = 1;
            break;
        case args.detail === 'W-MON' || args.detail === 'MS' || args.detail === 'YS':
            PRECIPITATION_THRESHOLD = 7;
            break;

        default:
            PRECIPITATION_THRESHOLD = 1;
            break;
    }

    if (isNumber(args.cloudCover) && isNumber(args.precipitation) && isNumber(args.temperature)) {
        /**
         * Если облачность больше максимального порогового значения и кол-во осадков больше порогового значения, то
         * отображаем иконку с осадками
         */
        if (args.cloudCover >= CLOUD_COVER_SUN_MAX_THRESHOLD && args.precipitation >= PRECIPITATION_THRESHOLD) {
            /**
             * В зависимости от температуры отображаем дождик или снег. Если температура
             * меньше порового значения, то отображаем иконку снега, иначе иконку дождя
             */
            if (args.temperature < TEMPERATURE_THRESHOLD) {
                return getSvgIcon('cloudSnow');
            } else {
                return getSvgIcon('cloudRain');
            }
        }
    }

    /**
     * Если значение облачности больше минимального порогового значения, но меньше максимального,
     * то отображаем иконку солнышка с тучками (Так же если есть осадки, то так же отображаются
     * иконки солнышка с тучами и осадками )
     */
    if (
        isNumber(args.cloudCover) &&
        args.cloudCover >= CLOUD_COVER_SUN_MIN_THRESHOLD &&
        args.cloudCover < CLOUD_COVER_SUN_MAX_THRESHOLD
    ) {
        /**
         * Если кол-во осадков больше порогового, то отображаем иконку солнышка с тучками и осадками
         * (Осадки выбираются в зависимости от температуры)
         */
        if (
            isNumber(args.precipitation) &&
            isNumber(args.temperature) &&
            args.precipitation >= PRECIPITATION_THRESHOLD
        ) {
            /**
             * В зависимости от температуры отображаем солнышко с тучками и дождиком или солнышко с тучками и снегом.
             */
            if (args.temperature < TEMPERATURE_THRESHOLD) {
                return getSvgIcon('cloudSnowSun');
            } else {
                return getSvgIcon('cloudRainSun');
            }
        } else {
            /**
             * Если осадков нет, то просто отображаем иконку солнца с тучками
             */
            return getSvgIcon('cloudSun');
        }
    }

    /**
     * Если значение облачности больше или равно пороговому, то отображаем тучку
     */
    if (isNumber(args.cloudCover) && args.cloudCover >= CLOUD_COVER_SUN_MAX_THRESHOLD) {
        return getSvgIcon('cloud');
    }

    /**
     * Если значение облачности меньше порогового, то отображаем солнышко
     */
    if (isNumber(args.cloudCover) && args.cloudCover < CLOUD_COVER_SUN_MIN_THRESHOLD) {
        return getSvgIcon('sun');
    }

    return 'circle';
};

export default getWeatherMarkerSymbol;
