import { ITabListOptionsItem } from '../../../../../../components/TabList/interfaces';

export const tabListItems: ITabListOptionsItem[] = [
    {
        id: 'all',
        text: 'All',
    },
    {
        id: 'location',
        text: 'Location',
    },
    {
        id: 'floor',
        text: 'Floors',
    },
];
