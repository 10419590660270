import { FC, useEffect, useState } from 'react';
import { Td } from '../../styles';
import { IProps } from './interfaces';
import useFetchData from './api/useFetchData';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import { Value } from './styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { valueFormatter } from '../../../../../../../../tools/Strings/valueFormatter';
import Stack from '../../../../../../../Stack/Stack';
import getPercentOfTheMain from './tools/getPercentOfTheMain';
import { getColorFromPercent } from '../../../../../../../../tools/getColorFromPercent';

/**
 * Ячейка с данными по метрики для отчетного объекта
 */
const DataCell: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { data, isFetching, isError } = useFetchData(props);
    const [percentOfTheMain, setPercentOfTheMain] = useState<number | null>(null);

    const { t } = useTranslation();
    const metricFromStore = allMetrics.find((item) => item.id === props.metric);

    useEffect(() => {
        if (data && props.selectedReportingObjectId) {
            setPercentOfTheMain(
                getPercentOfTheMain({
                    selectedReportingObjectId: props.selectedReportingObjectId,
                    reportingObjectId: props.reportingObjectId,
                    currentValue: data.value,
                    moduleId: props.moduleId,
                    widgetId: props.widgetId,
                    metric: props.metric,
                }),
            );
        } else {
            setPercentOfTheMain(null);
        }
    }, [data, props.selectedReportingObjectId, props.reportingObjectId, props.moduleId, props.widgetId, props.metric]);

    if (isFetching) {
        return (
            <Td>
                <span>{t('Loading...')}</span>
            </Td>
        );
    }

    if (isError) {
        return (
            <Td>
                <span>{t('An error accrued while getting data')}</span>
            </Td>
        );
    }

    if (!data) {
        return (
            <Td>
                <span>{t('No data')}</span>;
            </Td>
        );
    }

    return (
        <Td>
            <Stack gap={10} alignItems="center" justifyContent="space-between">
                <Value>
                    {valueFormatter({
                        precision: metricFromStore?.round_decimal_places,
                        units: metricFromStore?.units,
                        value: data.value,
                        showUnits: false,
                    })}
                </Value>
                {props.selectedReportingObjectId && props.reportingObjectId !== props.selectedReportingObjectId && (
                    <Value fontWeight={'500'} color={getColorFromPercent(percentOfTheMain)}>
                        {valueFormatter({ value: percentOfTheMain, precision: 1, units: '%' })}
                    </Value>
                )}
            </Stack>
        </Td>
    );
};

export default DataCell;
