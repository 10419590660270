import { FC } from 'react';
import { IProps } from './interfaces';
import {
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnId,
    TableHeader,
    TableHeaderCell,
    TableRow,
    useTableFeatures,
    useTableSort,
} from '@fluentui/react-components';
import { EVENT_TYPES_COLUMNS } from './constants/constants';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import { Events_EventsMap_Widget_Reducer_Values } from '../../../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import { useSelector } from 'react-redux';

/**
 * Таблица для типов событий
 */
const EventTypesTable: FC<IProps> = ({ openEventTypeModal }) => {
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);

    const { t } = useTranslation();

    const {
        getRows,
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns: EVENT_TYPES_COLUMNS,
            items: eventTypes || [],
        },
        [
            useTableSort({
                defaultSortState: { sortColumn: 'id', sortDirection: 'ascending' },
            }),
        ],
    );

    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });

    const rows = sort(getRows());

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    {EVENT_TYPES_COLUMNS.map((column) => {
                        return (
                            <TableHeaderCell {...headerSortProps(column.columnId)} key={column.columnId}>
                                {t(column.columnHeaderText)}
                            </TableHeaderCell>
                        );
                    })}
                </TableRow>
            </TableHeader>
            <TableBody>
                {rows.map(({ item }) => (
                    <TableRow key={item.id}>
                        {EVENT_TYPES_COLUMNS.map((column) => {
                            return (
                                <TableCell key={column.columnId} {...headerSortProps(column.columnId)}>
                                    <TableCellLayout>
                                        {column?.component ? (
                                            <column.component
                                                eventType={item}
                                                openEventTypeModal={openEventTypeModal}
                                            />
                                        ) : (
                                            item[column.columnId] || column.columnId
                                        )}
                                    </TableCellLayout>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default EventTypesTable;
