import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: none;

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        display: block;
        margin-top: 15px;
    }
`;
