import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, reloadCategories } from '../../../../../../../../General.reducer';
import { Footer, Wrapper } from './styles';
import { CategoriesAPI } from '../../../../../../../../tools/API/categoriesAPI';
import Button from '../../../../../../../../components/Button/Button';

const ModalDeleteSubCategory: React.FC<{
    handleOKClick: () => void;
    modalStatus: { show: boolean; id?: number; mode?: string };
}> = ({ modalStatus, handleOKClick }) => {
    const { token, urlsByServices, user } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const categoriesUrl = useMemo(() => {
        if (urlsByServices?.['core/admin-service']) return urlsByServices['core/admin-service'].CATEGORIES_URL;
        return null;
    }, [urlsByServices]);

    const onOKClick = useCallback(() => {
        if (modalStatus?.id && categoriesUrl) {
            CategoriesAPI({
                user,
                token,
                chapter: 'project-categories',
                id: modalStatus?.id,
                method: 'DELETE',
                url: categoriesUrl,
            }).then(() => {
                dispatch(reloadCategories());
            });
        }

        handleOKClick();
    }, [dispatch, handleOKClick, modalStatus?.id, token, categoriesUrl]);

    const warningText = `${t('This will delete category and all the relations with tenants')}!`;

    return (
        <Wrapper>
            <h4>{warningText}</h4>
            <Footer>
                <Button appearance="primary" text={t('Delete')} onClick={onOKClick} />
            </Footer>
        </Wrapper>
    );
};

export default ModalDeleteSubCategory;
