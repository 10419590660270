import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IReducerState, TLocalMetricsData, TSelectedWidgetKey } from './interfaces';

const initialState: IReducerState = {
    selectedWidgetKey: 'dataLocation',
    moduleName: 'Configuration:Metrics',
    savedMetricData: [],
};

const Configuration_Metrics_Module_Reducer = createSlice({
    name: 'Configuration_Metrics_Module_Reducer',
    initialState,
    reducers: {
        storeSelectedWidgetKey: (state, action: PayloadAction<TSelectedWidgetKey>) => {
            state.selectedWidgetKey = action.payload;
        },

        storeSavedMetricData: (state, action: PayloadAction<TLocalMetricsData>) => {
            state.savedMetricData = action.payload;
        },
    },
});

export const { storeSelectedWidgetKey, storeSavedMetricData } = Configuration_Metrics_Module_Reducer.actions;

export const Configuration_Metrics_Module_Reducer_Values = (state: RootState) =>
    state.Configuration_Metrics_Module_Reducer;

export default Configuration_Metrics_Module_Reducer.reducer;
