import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IReducerState, TRawMetricsData } from './interfaces';

const initialState: IReducerState = {
    moduleName: 'Reports:Reports from partners',
    tab: 'Bathel',
};

export const Reports_From_Partners_Module_Reducer = createSlice({
    name: 'Reports_From_Partners_Module_Reducer',
    initialState,

    reducers: {
        toggleTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },
    },
});

export const { toggleTab } = Reports_From_Partners_Module_Reducer.actions;

export const Reports_From_Partners_Module_Reducer_Values = (state: RootState) =>
    state.Reports_From_Partners_Module_Reducer;

export default Reports_From_Partners_Module_Reducer.reducer;
