import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import {
    storeResponseResult,
    storePreraredData,
    storeTenantId2zoneId,
    Leasing_TenantOverview_Reach_Widget_Reducer_Values,
} from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { usePrepareData } from './usePrepareData';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';
import { ITenant2ZoneRow } from '../../../../../../General.interfaces';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { ZONES_WORD } from '../../../../../../constants/constants';
import { TRequestMetricsArgs } from '../../../../../../tools/API/interfaces';
import { ResponseStatus } from '../../../../../../tools/API/constants';

export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();

    usePrepareData();
    const {
        token,
        structures,
        src: { dataObj2ProjectCategory, projectCategories, reportingObjects },
        cfg: { reportingObjectsByType, reportingObjectsById, reportingObjectsByTypeAndMarker },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const { selectedCategory, dataRefetchObject } = useSelector(Leasing_TenantOverview_Reach_Widget_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    useEffect(() => {
        if (!localCurrentOptions?.selectedReportingObjectsIds?.length || !token || !structures?.relations_tenant2zone) {
            dispatch(storePreraredData({ status: ResponseStatus.NoData, message: t('No Data') }));
            return;
        }

        dispatch(storePreraredData({ status: ResponseStatus.Loading, message: t('Loading...') }));
        dispatch(storeResponseResult(null));
        dispatch(storeTenantId2zoneId(null));

        const controller = new AbortController();
        const signal = controller.signal;
        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item?.id === mainPeriod)[0];
        const mainTimeRange = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];
        const selectedTenant = reportingObjectsById[localCurrentOptions?.selectedReportingObjectsIds?.[0]];
        const subCategoriesIds = dataObj2ProjectCategory
            ?.filter((item) => item.data_object_id === selectedTenant?.id)
            .map((item) => item.category_id);

        const subCategoryOfOther = projectCategories?.filter(
            (item) => String(item.parent_id) === selectedCategory && subCategoriesIds.includes(item.id),
        )[0];

        const sameCategoryTenantIds = dataObj2ProjectCategory
            ?.filter((tenant) => tenant?.category_id === subCategoryOfOther?.id)
            ?.map((item) => item.data_object_id);

        sameCategoryTenantIds?.push(selectedTenant?.id);

        const filteredTenant2zone: ITenant2ZoneRow[] = filterValidDateRanges(
            structures['relations_tenant2zone']?.filter((item: ITenant2ZoneRow) => {
                return sameCategoryTenantIds?.includes(item.tenant_id);
            }),
            mainDateRange?.period,
        );

        const tenantId2zoneId = filteredTenant2zone.map((item) => {
            const zoneId = Object.entries(reportingObjectsByTypeAndMarker).find((entry) => {
                const [key, value] = entry;
                return key.includes(ZONES_WORD) && value.marker === item.zone_marker;
            })?.[1]?.id;
            return { tenantId: item.tenant_id, zoneId };
        });

        dispatch(storeTenantId2zoneId(tenantId2zoneId));

        const zonesMarkers = filteredTenant2zone?.map((item) => item.zone_marker);
        const zonesIds = reportingObjects
            ?.filter((item) => zonesMarkers?.includes(item.marker))
            ?.map((item) => item.id);

        const requests: TRequestMetricsArgs = [
            {
                signal,
                token,
                alias: `tenants`,
                metric: 'fpc_sum_pass_count_in_wh',
                obj_ids: Array.from(new Set(filteredTenant2zone.map((item) => item.tenant_id))) || [],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            },
            {
                signal,
                token,
                alias: `zones`,
                metric: 'fpc_sum_pass_count_in_wh',
                obj_ids: zonesIds || [],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            },
        ];

        fetchData(requests).then((res: TServerResponse) => {
            handleServerResponse({
                responseAnalyzer,
                success: storeResponseResult,
                error: storePreraredData,
                dispatch,
                res,
            });
        });

        return () => {
            controller.abort();
        };
    }, [
        JSON.stringify(localCurrentOptions?.mainPeriod),
        JSON.stringify(localCurrentOptions?.selectedReportingObjectsIds),
        token,
        structures,
        reportingObjectsByType,
        selectedCategory,
        dataRefetchObject,
    ]);
};
