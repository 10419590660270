import { DivWrapperHeaderFullCellSC, Th } from '../../../styles';
import React, { FC } from 'react';

interface IProps {
    children: string[] | string
}

export const CellHeader: FC<IProps> = (props) => {
    const {
        children,
    } = props

    return (
        <Th>
            <DivWrapperHeaderFullCellSC>
                {children}
            </DivWrapperHeaderFullCellSC>

        </Th>
    );
};