import { IReportingObject } from '../../../../../../General.interfaces';
import { ITenantsView, ITreeMapData } from '../interfaces';

interface IArgs {
    /**
     * Объект отчетных объектов, где ключ - это тип отчетных объектов
     */
    reportingObjectsByType: { [type: string]: IReportingObject[] };
    /**
     * Режим отображения графика
     */
    tenantsView: ITenantsView;
    /**
     * Выбранный тип отчетных объектов
     */
    objectsType: string;
    /**
     * Название выбранной метрики
     */
    metricName: string;
    /**
     * Единицы измерения
     */
    units: string;
    /**
     * Функция перевода
     */
    getObjName: (x: IReportingObject) => string;
}

/**
 *  Функция для создания структуры Родительской группы элементов на графике
 */
const generateTreemapParentsById = (args: IArgs): { [id: string]: ITreeMapData } => {
    const { reportingObjectsByType, tenantsView, objectsType, metricName, getObjName, units } = args;

    const parentsById: { [id: string]: ITreeMapData } = {};

    if (tenantsView.id !== 'all' && objectsType === 'tenant') {
        reportingObjectsByType?.[tenantsView.id]?.forEach((element) => {
            parentsById[element.id] = {
                parentId: element.id.toString(),
                id: element.id.toString(),
                objectType: tenantsView.id,
                name: getObjName(element),
                value: 0, // area
                compareValue: 0, // color compare
                mainValue: 0, // color main
                colorValue: 0, // diff between main & compare
                metricName,
                units,
            };
        });
    }

    return parentsById;
};

export default generateTreemapParentsById;
