import { FC, useMemo } from 'react';
import { IProps } from './interfaces';
import useFetchData from './api/useFetchData';
import { withLoading } from '../../../tools/API/withLoading';
import getResponseStatus from '../../../tools/API/helpers/getResponseStatus';
import { Table, TableWrapper } from './styles';
import Header from './components/Header/Header';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import Body from './components/Body/Body';
import usePrepareData from './hooks/usePrepareData';

/**
 * Виджет "Rating"
 */
const Rating: FC<IProps> = (props) => {
    const { data, isFetching, error } = useFetchData(props);
    const preparedData = usePrepareData({ data, viewSettings: props.viewSettings });

    const sortedComparePeriods = orderBy(
        props.comparePeriods,
        (period) => DateTime.fromISO(period.dateFrom).toMillis(),
        'desc',
    );

    const WithLoading = useMemo(() => {

        const Component = withLoading(
            TableWrapper,
            {
                data: getResponseStatus({
                    isFetching,
                    error,
                    data: preparedData,
                    isDataExists: Boolean(preparedData?.length),
                }),
                height: 400,
            },
            Boolean(props.viewSettings?.showLoadingWrapperBorder),
        );

        return Component;
    }, [isFetching, error, JSON.stringify(preparedData), props.viewSettings?.showLoadingWrapperBorder]);

    return (
        <WithLoading>
            <Table>
                <Header
                    comparePeriods={sortedComparePeriods}
                    viewSettings={props.viewSettings}
                    mainPeriod={props.mainPeriod}
                />
                <Body
                    comparePeriods={sortedComparePeriods}
                    viewSettings={props.viewSettings}
                    metric={props.metric}
                    data={preparedData}
                />
            </Table>
        </WithLoading>
    );
};

export default Rating;
