import { FC } from 'react';
import UniversalSelect from '../../../../../../Selects/UniversalSelect/UniversalSelect';
import { IDefaultSelectProps } from '../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { IChangeOptionsArgs } from '../../../../../../UserSettings/interfaces';
import { ISelectedOption } from '../../../../../../Selects/Select/interfaces';
import { changeRequiredParams } from '../../../../../../../Dashboards.reducer';

const VisualSelects: FC<IDefaultSelectProps> = (props) => {
    const { currentModuleID, selectedLocationId } = useSelector(generalReducerValues);

    const dispatch = useDispatch();

    const selectsVisualOptions = [
        { id: 'panel', text: 'From panel' },
        { id: 'widget', text: 'From widget' },
        { id: 'none', text: 'None' },
    ];

    const onChangeVisual = (visualParamKey: string) => (args: IChangeOptionsArgs) => {
        if (selectedLocationId) {
            props.updateWidget('visual', { [visualParamKey]: args.newOptions?.[visualParamKey] });
            if (
                (visualParamKey === 'useMainFrom' || visualParamKey === 'useComparisonFrom') &&
                props.widgetConfig?.id
            ) {
                const choice = args?.newOptions?.[visualParamKey] as ISelectedOption[];
                const choiceMap = { useMainFrom: 'mainObject', useComparisonFrom: 'comparisonObjects' };
                const mode = choice[0].id === 'panel' ? 'add' : 'remove';
                dispatch(
                    changeRequiredParams({
                        id: props.widgetConfig?.id,
                        dashboardKey: props.dashboardKey,
                        param: choiceMap[visualParamKey],
                        moduleId: props.moduleId,
                        mode,
                    }),
                );
            }
        }
    };

    return (
        <>
            <UniversalSelect
                defaultSelected={[selectsVisualOptions[0]]}
                mode="single"
                labelText="Use main from"
                name="useMainFrom"
                options={selectsVisualOptions}
                localCurrentOptions={{ ...props.widgetConfig?.visual, currentModuleID } || {}}
                changeOptions={onChangeVisual('useMainFrom')}
            />
            <UniversalSelect
                defaultSelected={[selectsVisualOptions[2]]}
                mode="single"
                labelText="Use comparison from"
                name="useComparisonFrom"
                options={selectsVisualOptions}
                localCurrentOptions={{ ...props.widgetConfig?.visual, currentModuleID } || {}}
                changeOptions={onChangeVisual('useComparisonFrom')}
            />
        </>
    );
};

export default VisualSelects;
