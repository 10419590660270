import {
    PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS,
    PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS,
} from '../../../constants/constants';
import {
    Performance_PathToPurchase_Widget_Reducer_Values,
    storeChartOptionsGeneratorSettings,
    storeExtendedReportingObjects,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { generalReducerValues } from '../../../../../../General.reducer';
import generateData from '../tools/generateData';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';

/** Кастомный хук для подготовки данных */
const usePrepareData = () => {
    const { rawMetricsData, moduleName } = useSelector(Performance_PathToPurchase_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        cfg: { reportingObjectsById, reportingObjectsByTypeAndMarker },
        structures,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Сохранение настроек для построеня графика и расширенныйх отчетных объектов */
    useEffect(() => {
        if (
            localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS] &&
            Object.keys(reportingObjectsByTypeAndMarker).length &&
            localCurrentOptions?.selectedReportingObjectsIds &&
            Object.keys(reportingObjectsById).length &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            Array.isArray(rawMetricsData) &&
            rawMetricsData
        ) {
            const result = generateData({
                selectedGroupMarker: localCurrentOptions?.[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS]?.[0]?.id,
                selectedReportingObjectsIds: localCurrentOptions.selectedReportingObjectsIds,
                funnelSteps: localCurrentOptions[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS],
                tenant2FloorRelations: structures?.['relations_tenant2floor'] || [],
                tenant2ZoneRelations: structures?.['relations_tenant2zone'] || [],
                msDataObjects: structures?.['elements_ms_data_objects'] || [],
                mainDateRanges: localCurrentOptions.mainDateRanges,
                mainPeriod: localCurrentOptions.mainPeriod,
                rawMetricsData: rawMetricsData,
                reportingObjectsByTypeAndMarker,
                reportingObjectsById,
                t,
            });
            dispatch(storeChartOptionsGeneratorSettings(result.chartOptionsGeneratorSettings));
            dispatch(storeExtendedReportingObjects(result.extendedReportingObjects));
        }
    }, [
        JSON.stringify(localCurrentOptions?.[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS]),
        JSON.stringify(localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS]),
        reportingObjectsByTypeAndMarker,
        reportingObjectsById,
        rawMetricsData,
        structures,
    ]);
};

export default usePrepareData;
