import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import VisitorsInsideWidget from './widgets/VisitorsInside/VisitorsInside';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import { useTranslation } from 'react-i18next';
import Preset from '../../../components/Preset/Preset';
import { FC } from 'react';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import { objectTypesMap } from '../../../tools/getReportingObjects';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import { chartDetailingOptions, VISITORS_INSIDE_CHART_DETAILING } from './widgets/VisitorsInside/constants/constants';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';

/**
 * Компонент для отображения модуля VisitorsInside
 */
const VisitorsInside: FC = () => {
    const { t } = useTranslation();
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);

    const validObjectTypes = Object.keys(reportingObjectsByType)
        ?.filter((key) => key !== 'passway')
        .map((key) => {
            return t(objectTypesMap[key]) || key;
        });
    validObjectTypes.push('zones', 'categories');

    const validMainPeriods = ['today', 'yesterday', 'selectCustom'];

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            validOptions={validMainPeriods}
                            oneDayOnly
                            defaultPeriod="today"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ComparePeriodSelect
                            oneDayOnly
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ObjectsSelect
                            dataObjectFilters={[
                                'data_objects_floor',
                                'data_objects_passway_type',
                                'data_objects_tenant',
                            ]}
                            maxSelected={5}
                            validOptions={validObjectTypes}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            defaultSelected={[chartDetailingOptions[1]]}
                            name={VISITORS_INSIDE_CHART_DETAILING}
                            options={chartDetailingOptions}
                            labelText={t('Detailing')}
                            dropdownWidth={'sm'}
                            mode={'single'}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset currentOptionsKeys={['comparePeriods', 'mainPeriod', 'selectedReportingObjectsIds']} />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                <VisitorsInsideWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default VisitorsInside;
