import { FC } from 'react';
import { IProps as ISelectsProps } from '../interfaces';
import Stack from '../../../../../../Stack/Stack';
import UseMetricFromSelect from '../UseMetricFromSelect/UseMetricFromSelect';

const NetworkObjectsByLocationsSelects: FC<ISelectsProps> = (props) => {
    return (
        <Stack direction="column" styles={{ padding: '0px 12px' }} gap={5}>
            <UseMetricFromSelect {...props} />
        </Stack>
    );
};

export default NetworkObjectsByLocationsSelects;
