import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { downloadSignal, Reports_Basic_Widget_Reducer_Values } from './reducer';
import { Table, TableWrapper } from './styles';
import ReactDataSheet from 'react-datasheet';
import { withLoading } from '../../../../../tools/API/withLoading';
import ButtonsPanel from '../../../components/ButtonsPanel/ButtonsPanel';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../General.reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import Title from '../../../../../components/Title/Title';
import { DS } from '../../../../../constants/constants';
import { useGetReportFileName } from '../../../../../hooks/useGetReportFileName';

const BasicReportWidget: React.FC = () => {
    const { grid, rawMetricsData, keyWord } = useSelector(Reports_Basic_Widget_Reducer_Values);
    const { currentModuleID } = useSelector(generalReducerValues);
    const reportFileName = useGetReportFileName(keyWord, 'Basic report');

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const table = useMemo(() => {
        if (rawMetricsData) {
            const data = rawMetricsData['status'] ? rawMetricsData : [];
            const WithLoadingTable = withLoading(TableWrapper, { height: 400, data });
            return (
                <WithLoadingTable>
                    {grid && <ReactDataSheet data={grid} valueRenderer={(cell) => cell.value} />}
                </WithLoadingTable>
            );
        }
        return null;
    }, [rawMetricsData, grid, t]);

    const reportFormat = useMemo(() => {
        return localCurrentOptions?.[keyWord ? `reportFormat${DS}${keyWord}` : 'reportFormat']?.[0]?.id;
    }, [keyWord, localCurrentOptions]);

    const onDownloadClick = () => {
        dispatch(downloadSignal());
    };

    return (
        <WidgetWrapper styles={{ marginTop: 10 }}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title>{t('Basic report')}</Title>
                </WidgetTitle>
            </WidgetTitleWrapper>
            {reportFormat === 'plain' && <Table>{table}</Table>}
            <ButtonsPanel
                moduleName={`${currentModuleID}`}
                grid={grid}
                onPrepareClick={onDownloadClick}
                keyWord={keyWord}
                reportFileName={reportFileName}
            />
        </WidgetWrapper>
    );
};

export default BasicReportWidget;
