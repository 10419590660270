import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    increaseShoudUpdateMetricsCounter,
    toggleMetricsFetching,
    storeRowMetricsData,
    Cases_Benchmarks_Traffic_Widgwet_Reducer_Values,
} from '../reducer';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { TTimeFreq } from '../../../../../../tools/API/interfaces';
import { Cases_Benchmarks_Module_Reducer_Values } from '../../../reducer';
import { DS } from '../../../../../../constants/constants';

/**
 * Кастомный хук для получения метрик с сервера
 */
export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();
    const { moduleName } = useSelector(Cases_Benchmarks_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { dataRefetchObject } = useSelector(Cases_Benchmarks_Traffic_Widgwet_Reducer_Values);

    const { token } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Инициирует перезапрос метрик, если изменился период сравнения на вкладке Периодов
    useEffect(() => {
        dispatch(increaseShoudUpdateMetricsCounter());
    }, [localCurrentOptions?.comparePeriods]);

    useEffect(() => {
        if (!localCurrentOptions) return;
        const controller = new AbortController();
        const signal = controller.signal;

        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
        const mainTimeRange = [mainDateRange?.period?.dateFrom, mainDateRange?.period?.dateTo];
        const benchAlias = `benchmark${DS}${localCurrentOptions?.['myDetail']?.[0]?.id}${DS}${localCurrentOptions?.['geoOptions']?.[0]?.id}${DS}${localCurrentOptions?.['mallTypeOptions']?.[0]?.id}`;
        const mallAlias = `mall${DS}${localCurrentOptions?.['myDetail']?.[0]?.id}${DS}12032${DS}12039`;
        // const mallAlias = `mall:${localCurrentOptions?.['myDetail']?.[0]?.id}:${localCurrentOptions?.['geoOptions']?.[0]?.id}:${localCurrentOptions?.['mallTypeOptions']?.[0]?.id}`;

        if (localCurrentOptions.comparePeriods && localCurrentOptions.mainPeriod) {
            dispatch(toggleMetricsFetching(true));

            const benchmarkRequest = {
                signal,
                token,
                alias: benchAlias,
                metric: 'fpc_sum_pass_count_in_wh',
                obj_ids: [666000001],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: 'W-MON' as TTimeFreq,
            };

            const mallRequest = {
                signal,
                token,
                alias: mallAlias,
                metric: 'fpc_sum_pass_count_in_wh',
                obj_ids: [666000001],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: 'W-MON' as TTimeFreq,
            };

            const queryData = [mallRequest, benchmarkRequest];

            dispatch(storeRowMetricsData({ status: 'Loading', message: t('Loading...') }));
            fetchData(queryData)
                .then((res: TServerResponse) => {

                    handleServerResponse({
                        responseAnalyzer: responseAnalyzer,
                        success: storeRowMetricsData,
                        error: storeRowMetricsData,
                        dispatch,
                        res,
                    });
                })
                .catch((error: any) => {
                    console.log('DynamicsPMO useGetMetrics >>>>>', error);
                });
        }

        return () => {
            controller.abort();
        };
    }, [
        localCurrentOptions?.mainPeriod,
        localCurrentOptions?.['geoOptions']?.[0]?.id,
        localCurrentOptions?.['mallTypeOptions']?.[0]?.id,
        localCurrentOptions?.['myDetail']?.[0]?.id,
        localCurrentOptions?.currentModuleID,
        dataRefetchObject,
    ]);
};
