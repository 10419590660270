import { IGeneralPreferences, ISidebarSettings } from '../interfaces';

export const DEFAULT_GENERAL_CABINET_PREFERENCES: IGeneralPreferences = {
    useBusinessDays: 'no',
};
export const DEFAULT_SIDEBAR_PREFERENCES: ISidebarSettings = {
    openSections: ['Dashboard designer'],
    activeSection: 'Dashboard designer',
    favoriteModules: [],
};
