import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    /* border-top: 1px solid var(--color-secondary); */
    background-color: var(--color-secondary-light);
    padding: var(--space-xs);
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CompanyText = styled.div``;
