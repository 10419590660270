/**
 * Функция для преобразования текста в текст с оперделенным количесвтом символов в строке и определенным количеством строк
 * @param value значение для обработки
 * @param maxLineLength максимальная длина строки
 * @param maxLines максимальное количесвто строк
 * @returns Возвращает массив строк (линий)
 */
export const getFixedLines = (value: string, maxLineLength = 35, maxLines = 1) => {
    const words = value?.split(' ');

    //reduces the words into lines of maxLineLength
    const assembleLines = words?.reduce(
        (acc: { currLine: string; lines: string[] }, word) => {
            //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
            if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
                return {
                    lines: acc.lines.concat([acc.currLine + '\r\n']),
                    currLine: word,
                };
            }
            //otherwise add the word to the current line
            return {
                ...acc,
                currLine: acc.currLine + ' ' + word,
            };
        },
        { lines: [], currLine: '' },
    );

    //add the ending state of current line (the last line) to lines
    const allLines = assembleLines?.lines.concat([assembleLines.currLine]);

    //for now, only take first 2 lines due to tick spacing and possible overflow
    const lines = allLines?.slice(0, maxLines);
    let children: string[] = [];

    lines?.forEach((lineText, i) => {
        children.push(
            // if on the second line, and that line's length is within 3 of the max length, add ellipsis
            i === maxLines - 1 && allLines.length > maxLines ? lineText.slice(0, maxLineLength - 3) + '...' : lineText,
        );
        //increment dy to render next line text below
    });

    return children;
};
