import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    generalReducerValues,
    refetchDataObjectsSelectFilters,
    storeRawDataObjectsSelectFilters,
    toggleRawDataObjectsSelectFiltersFetching,
} from '../../General.reducer';
import { IDataObjectsSelectFilter } from './interfaces';

/**
 * Кастомный хук для работы с localStorage
 */
export const useLocalStorage = () => {
    const {
        dataObjectsSelectFiltersRefetchObject,
        extendedDataObjectsSelectFiltersByType,
        rawDataObjectsSelectFilters,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const LOCAL_STORAGE_KEY = 'objectsSelectFilters';

    /** Обновление данных в localStorage */
    useEffect(() => {
        if (Object.keys(extendedDataObjectsSelectFiltersByType).length) {
            const extendedFiltersArray = Object.values(extendedDataObjectsSelectFiltersByType);

            if (!isEqual(rawDataObjectsSelectFilters, extendedFiltersArray)) {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(extendedFiltersArray));
            }
        }
    }, [extendedDataObjectsSelectFiltersByType, rawDataObjectsSelectFilters, dispatch]);

    /** Получение сырых данных фильтров из localStorage*/
    useEffect(() => {
        const rawFilters = localStorage.getItem(LOCAL_STORAGE_KEY);

        if (rawFilters) {
            try {
                dispatch(toggleRawDataObjectsSelectFiltersFetching(true));
                const parsedFilters: IDataObjectsSelectFilter[] = JSON.parse(rawFilters);
                dispatch(storeRawDataObjectsSelectFilters(parsedFilters));
            } catch (error) {
                dispatch(toggleRawDataObjectsSelectFiltersFetching(false));
                console.log('Error with parsing reporting objects filter from localStorage>>>>', error);
            }
        } else {
            dispatch(toggleRawDataObjectsSelectFiltersFetching(false));
        }
    }, [dataObjectsSelectFiltersRefetchObject, dispatch]);
};
