import { useEffect, useState } from 'react';
import { IDataAdapterResponseItem, IViewSettings } from '../interfaces';
import { isNumber, max, sum } from 'lodash';

interface IArgs {
    data: IDataAdapterResponseItem[] | undefined;
    viewSettings?: IViewSettings;
}

const usePrepareData = (args: IArgs): IDataAdapterResponseItem[] | undefined => {
    const [newData, setNewData] = useState<IDataAdapterResponseItem[] | undefined>();

    useEffect(() => {
        if (args.data) {
            const filteredData = args.data.filter(
                args.viewSettings?.dataFilter ? args.viewSettings?.dataFilter : () => true,
            );

            const mainValues = filteredData.map((item) => item.mainValue.value);

            const mainTotal = sum(mainValues);
            const mainMax = max(mainValues);

            const newData = filteredData.map((item) => {
                return {
                    ...item,
                    mainValue: {
                        ...item.mainValue,
                        percentOfTotal: item.mainValue.value ? (item.mainValue.value * 100) / mainTotal : null,
                        percentOfMax:
                            item.mainValue.value && isNumber(mainMax) ? (item.mainValue.value * 100) / mainMax : null,
                    },
                };
            });

            setNewData(newData);
        }
    }, [JSON.stringify(args.data), args.viewSettings?.dataFilter]);

    return newData;
};

export default usePrepareData;
