import { FC, useEffect, useMemo, useState } from 'react';
import { AdditionalFilterIconWrapper, NumberOfActiveFilters, StyledFilterIcon } from './styles';
import ContextualMenu from '../../../../ContextualMenu/ContextualMenu';
import Filters from './components/Filters/Filters';
import { IProps } from './interfaces';

/**
 * Компонент для отображения фильтров для селекта отчетных объектов
 */
const AdditionalFilters: FC<IProps> = ({
    extendedDataObjectsSelectFiltersByType,
    dataObjectFilters,
    onFiltersChange,
    testId = '',
}) => {
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);

    /**
     * Закрытие контекстного меню с фильтрами при размонтировании компонента
     */
    useEffect(() => {
        return () => {
            toggleContextualMenu();
        };
    }, []);

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const activeFiltersCount = useMemo(() => {
        return Object.values(extendedDataObjectsSelectFiltersByType).reduce(
            (acc, value) => (value.active && dataObjectFilters.includes(value.filter_type) ? (acc += 1) : acc),
            0,
        );
    }, [extendedDataObjectsSelectFiltersByType, dataObjectFilters]);

    return (
        <ContextualMenu
            contextualMenuBody={
                <Filters
                    extendedDataObjectsSelectFiltersByType={extendedDataObjectsSelectFiltersByType}
                    onFiltersChange={onFiltersChange}
                    dataObjectFilters={dataObjectFilters}
                />
            }
            isContextualMenuOpen={isContextualMenuOpen}
            closeContextualMenu={closeContextualMenu}
        >
            <AdditionalFilterIconWrapper onClick={toggleContextualMenu} data-test-id={testId}>
                {activeFiltersCount ? <NumberOfActiveFilters>{activeFiltersCount}</NumberOfActiveFilters> : null}

                <StyledFilterIcon />
            </AdditionalFilterIconWrapper>
        </ContextualMenu>
    );
};

export default AdditionalFilters;
