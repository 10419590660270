import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    position: relative;
    margin-top: var(--space-xxs);
`;

export const Layer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    background-color: transparent;
`;
