import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { getReportingObjects } from '../../../../../../../../../tools/getReportingObjects';
import { FormItem } from '../../styles';
import {
    ISelectProps,
    ISelectedOption,
    TSelectedOptions,
} from '../../../../../../../../../components/Selects/Select/interfaces';
import Select from '../../../../../../../../../components/Selects/Select/Select';

const SelectObjectType: React.FC<
    ISelectProps & { selectedId: string; handleSelectValue: (selects: TSelectedOptions) => void }
> = ({ handleSelectValue, selectedId = '', ...props }) => {
    const {
        cfg: { reportingObjectsByType },
        currentModuleID,
    } = useSelector(generalReducerValues);
    const [selectedMetrics, setSelectedMetrics] = useState<TSelectedOptions>([]);
    const { t } = useTranslation();

    const options = useMemo(() => {
        const lockedFields = ['location'];
        const result = getReportingObjects(reportingObjectsByType, t)
            .filter((item) => !lockedFields.includes(item.key))
            .map((item) => ({
                id: item.key,
                text: t(item.section),
                order: item.order,
            }));
        return _.sortBy(result, (item) => item.order);
    }, [t]);

    const handleSelect = (args: TSelectedOptions) => {
        if (args.length) {
            setSelectedMetrics([args[0]]);
        }
        handleSelectValue(args);
    };

    const objSelect = useMemo(() => {
        let outsideSelected: TSelectedOptions = [];

        const outsideSelect = options?.find((x: ISelectedOption) => x.id === selectedId);
        if (outsideSelect) {
            outsideSelected = [outsideSelect];
        } else if (!_.isEmpty(options)) {
            outsideSelected = [options[0]];
        }

        return (
            <Select
                testId={`${currentModuleID}_Show inside rating`}
                options={options}
                showFilter={true}
                labelText={t('Show inside rating')}
                iconType={'chevron-down'}
                mode="single"
                fullWidth={props.fullWidth}
                shadow="md"
                handleSelect={handleSelect}
                outsideSelected={outsideSelected}
            />
        );
    }, [selectedMetrics, options, t, selectedId, props.fullWidth]);

    return <FormItem>{objSelect}</FormItem>;
};
export default SelectObjectType;
