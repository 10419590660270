import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IProps } from './interfaces';
import { queryClient } from '../../../../../../../../..';
import { TABLE_QUERY_KEY } from '../../DataCell/constants/constants';
import { IPeriodObj, TMetricResponse } from '../../../../../../../../../General.interfaces';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import isNumber from 'lodash/isNumber';
import { Th } from '../../../styles';
import Stack from '../../../../../../../../Stack/Stack';
import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import getDifferenceBetweenNumbers from '../../../../../../../../../tools/getDifferenceBetweenNumbers';
import { ObjByMeticsTableViewOptionIds } from '../../../../../enums';
import { useIsFetching } from '@tanstack/react-query';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';
import { Value } from './styles';

const TotalCell: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);

    const [percentOfTheMain, setPercentOfTheMain] = useState<number | null>(null);
    const [total, setTotal] = useState<number | null>(null);

    const { t } = useTranslation();

    const getQueryKey = useCallback(
        (periodType: 'main' | 'compare', period: IPeriodObj) => {
            return [
                TABLE_QUERY_KEY,
                props.moduleId,
                props.widgetId,
                periodType,
                props.metric,
                period,
                props.reportingObjects.map((item) => item.id).sort(),
            ];
        },
        [props.metric, props.moduleId, props.reportingObjects, props.widgetId],
    );

    const isMainFetching = useIsFetching({
        queryKey: getQueryKey('main', props.mainPeriod),
    });

    const isFetching = useIsFetching({
        queryKey: getQueryKey(props.periodType, props.period),
    });

    const metricFromStore = allMetrics.find((item) => item.id === props.metric);

    const getTotal = (periodType: 'main' | 'compare', period: IPeriodObj): null | number => {
        const data = queryClient.getQueryData(getQueryKey(periodType, period)) as undefined | TMetricResponse[];
        return sum(data?.[0].map((metricResponseItem) => sum(metricResponseItem.items.map((item) => item.value))));
    };

    useEffect(() => {
        const mainTotal = getTotal('main', props.mainPeriod);
        const compareTotal = getTotal('compare', props.period);

        if (props.periodType === 'main' && metricFromStore?.aggregation_objects === 'sum') {
            setTotal(mainTotal);
        } else if (props.periodType === 'compare' && metricFromStore?.aggregation_objects === 'sum') {
            if (props.mode === ObjByMeticsTableViewOptionIds.ShowAbsoluteValues) {
                setTotal(compareTotal);
            } else if (props.mode === ObjByMeticsTableViewOptionIds.ShowRelativeValues) {
                if (isNumber(mainTotal) && isNumber(compareTotal) && mainTotal !== 0) {
                    setTotal(Number(getDifferenceBetweenNumbers(mainTotal, compareTotal).percentDifference));
                } else {
                    setTotal(null);
                }
            }
        } else {
            setTotal(null);
        }
    }, [
        getQueryKey,
        props.mainPeriod,
        props.metric,
        props.mode,
        props.moduleId,
        props.period,
        props.periodType,
        props.reportingObjects,
        props.widgetId,
        isMainFetching,
        isFetching,
    ]);

    const showColoredValue = useMemo(() => {
        return props.periodType === 'compare' && props.mode === ObjByMeticsTableViewOptionIds.ShowRelativeValues;
    }, [props.periodType, props.mode]);

    return (
        <Th>
            <Stack alignItems={'center'} justifyContent={'flex-end'}>
                {props.periodType === 'main' && isMainFetching ? t('Loading...') : null}
                {props.periodType === 'compare' && isFetching ? t('Loading...') : null}

                {!isMainFetching && !isFetching ? (
                    <Value value={showColoredValue ? total : undefined}>
                        {valueFormatter({
                            units: showColoredValue ? '%' : metricFromStore?.units,
                            precision: metricFromStore?.round_decimal_places,
                            showUnits: showColoredValue,
                            value: total,
                        })}
                    </Value>
                ) : null}
            </Stack>
        </Th>
    );
};

export default TotalCell;
