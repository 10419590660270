import styled from 'styled-components/macro';

export const Title = styled.div<{ isRtlLanguage: boolean }>`
    color: var(--color-primary-darker);
    /* color: var(--color-primary-light); */
    position: relative;
    font-size: 1.25rem;
    /* display: inline-block; */
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 0px;
    /* height: 40px; */

    /* flex-direction: ${(p) => (p.isRtlLanguage ? 'row-reverse' : 'row')}; */
    /* padding-right: 15px;
    padding-left: 15px; */
    /* flex: 0 0 100%; */

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        max-width: 100%;
        padding-bottom: 5px;
    }
`;
