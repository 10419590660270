import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeNewAlert } from '../components/Alert/reducer';
import { IProjectAlert } from '../General.interfaces';
import { generalReducerValues } from '../General.reducer';
import { getHeaders } from '../tools/API/getHeaders';

export const useProjectAlerts = () => {
    const { user, urlsByServices, token } = useSelector(generalReducerValues);
    const url = urlsByServices?.['app/app-backend']?.PROJECT_ALERTS_URL;
    const dispatch = useDispatch();

    const { data } = useQuery({
        enabled: Boolean(url) && Boolean(user?.id),
        queryKey: ['PROJECT_ALERTS'],
        queryFn: () => {
            return axios
                .get<IProjectAlert[]>(url || '', {
                    headers: getHeaders(token),
                })
                .then((response) => response.data);
        },
    });

    useEffect(() => {
        if (data) {
            data.filter((alert) => {
                return user?.available_projects.includes(alert.project_id);
            }).forEach((alert) => {
                dispatch(
                    storeNewAlert({
                        type: alert.type,
                        text: alert.text,
                        duration: alert.duration || undefined,
                        alias: String(alert.id),
                    }),
                );
            });
        }
    }, [data, dispatch, user?.available_projects]);
};
