import { useSelector } from 'react-redux';
import Button from '../../../../../../../components/Button/Button';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import useMutateCorrectionBills from '../../../../api/useMutateCorrectionBills';
import { METRIC_KEYS } from '../../../../constants/constants';
import { ICorrectionBill } from '../../../../interfaces';
import { ButtonWrapper, ContentWrapper, Ul, Wrapper } from './styles';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { IPeriodObj } from '../../../../../../../General.interfaces';
import { useMemo } from 'react';
import { IObject } from '../../../../../../../components/Selects/Select/interfaces';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';

interface IProps {
    data: ICorrectionBill[];
    closeModal: () => void;
}

const ClearModal: React.FC<IProps> = ({ data, closeModal }) => {
    const {
        currentModuleID,
        cfg: { reportingObjectsById },
        lang,
    } = useSelector(generalReducerValues);
    const { postMutation } = useMutateCorrectionBills();
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const onClear = () => {
        if (data.length) {
            const emptyData = data.map((item) => {
                METRIC_KEYS.forEach((metric) => {
                    item[metric] = null;
                });
                return item;
            });
            postMutation.mutate(emptyData);
        }
        closeModal();
    };

    const period: IPeriodObj | undefined = useMemo(() => {
        if (localCurrentOptions?.['period']) {
            return localCurrentOptions?.['period'].reduce((acc: IPeriodObj, item: IObject) => {
                return { ...acc, [String(item.id)]: item.text };
            }, {});
        } else {
            return undefined;
        }
    }, [localCurrentOptions?.['period']]);

    return (
        <Wrapper>
            <ContentWrapper>
                <div>
                    {`${t('Do you really want to clear data for the period')} (${shortStringDate(period, lang)}) ${t(
                        'for the following tenants',
                    )}`}
                    :
                </div>
                <Ul>
                    {localCurrentOptions?.selectedReportingObjectsIds?.map((id) => {
                        return <li key={id}>-&nbsp;{reportingObjectsById?.[id]?.name || id}</li>;
                    })}
                </Ul>
            </ContentWrapper>
            <ButtonWrapper>
                <Button text={t('Cancel')} onClick={closeModal} />
                <Button text={t('Clear')} appearance="primary" onClick={onClear} />
            </ButtonWrapper>
        </Wrapper>
    );
};

export default ClearModal;
