import { useSelector } from 'react-redux';
import { cabinetPreferencesValues } from '../../../CabinetPreferences/reducer';
import { DynamicsPMOProps } from '../interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { useRequestMetrics } from '../../../../tools/API/hooks/useRequestMetrics';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { useMemo } from 'react';
import { ISelectedOption } from '../../../Selects/Select/interfaces';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../tools/API/interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../tools/API/dataAccessors/metricsDataAccessor';
import { DYNAMICS_PMO_WEATHER_QUERY_KEY, WEATHER_METRICS_BY_NAME } from '../constants/constants';
import { TMetricResponse } from '../../../../General.interfaces';
import { DS } from '../../../../constants/constants';
import { useQuery } from '@tanstack/react-query';
import { isUndefined } from 'lodash';


const useFetchWeather = (args: DynamicsPMOProps) => {
    const { token } = useSelector(generalReducerValues);
    const fetchData = useRequestMetrics({ isNetwork: args.isNetwork });

    const localCurrentOptions = useWidgetCurrentOptions(args.moduleId);

    const { queryKey, queryFn } = useMemo(() => {
        const weatherMetrics = Object.values(WEATHER_METRICS_BY_NAME);

        const getLocalCurrentOptionValue = (prefix: string, isArray = true): ISelectedOption | undefined => {
            const result = localCurrentOptions?.[`${prefix}${DS}${args.moduleId}${DS}${args.widgetId}`];
            return isArray ? result?.[0] : result;
        };

        const detail = getLocalCurrentOptionValue('detailSelect', false) ?? { id: null };
        const object = getLocalCurrentOptionValue('objectSelect') ?? { id: args.reportingObjects[0]?.id };

        if (token) {
            const requestArgs: IRequestMetricsArgs[] = [];

            const requestTemplate: IRequestMetricsArgs = {
                time_freq: !isUndefined(args.detail) ? args.detail : (detail.id as TTimeFreq),
                object_aggregation: false,
                time_range: [],
                alias: null,
                obj_ids: [],
                metric: '',
                token,
            };

            switch (args.chartType) {
                case 'metrics': {
                    if (object && typeof object.id === 'number') {
                        weatherMetrics.forEach((metric) => {
                            requestArgs.push({
                                ...requestTemplate,
                                time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                                obj_ids: [object.id as number],
                                alias: 'weather',
                                metric,
                            });
                        });
                    }
                    break;
                }

                case 'objects': {
                    weatherMetrics.forEach((metric) => {
                        requestArgs.push({
                            ...requestTemplate,
                            time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                            obj_ids: args.reportingObjects.map((item) => item.id),
                            alias: 'weather',
                            metric,
                        });
                    });

                    break;
                }

                case 'periods': {
                    if (object && typeof object.id === 'number') {
                        weatherMetrics.forEach((metric) => {
                            requestArgs.push({
                                ...requestTemplate,
                                time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                                obj_ids: [object.id as number],
                                alias: 'weather',
                                metric,
                            });
                        });
                    }
                    break;
                }

                case 'pos_metrics': {
                    if (object && typeof object.id === 'number') {
                        weatherMetrics.forEach((metric) => {
                            requestArgs.push({
                                ...requestTemplate,
                                time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                                obj_ids: [object.id as number],
                                alias: 'weather',
                                metric,
                            });
                        });
                    }
                    break;
                }

                default:
                    break;
            }

            const dataAccessorArgs: IMetricsDAArgs = {
                requestArgs,
                fetchData,
            };

            return {
                queryKey: [DYNAMICS_PMO_WEATHER_QUERY_KEY, args.moduleId, args.widgetId, args.chartType, requestArgs],
                queryFn: () => metricsDataAccessor(dataAccessorArgs),
            };
        }
        return {};
    }, [
        token,
        localCurrentOptions,
        args.detail,
        args.moduleId,
        args.widgetId,
        args.chartType,
        args.mainPeriod,
        args.reportingObjects,
        fetchData,
    ]);

    return useQuery<TMetricResponse[], unknown, TMetricResponse[], any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(args.viewSettings?.showWeather),
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchWeather;
