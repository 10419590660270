import styled from 'styled-components';
import { getColorFromPercent } from '../../../../../../../tools/getColorFromPercent';

export const MainValue = styled.div`
    font-weight: 600;
    min-width: 100px;
`;

export const PercentValueWrapper = styled.div<{ value?: number | string | null }>`
    white-space: nowrap;
    background-color: rgba(235, 235, 235, 0.3);
    padding: var(--space-xxxs) var(--space-xxs);
    color: ${(p) => getColorFromPercent(p.value)};
    font-weight: 600;
    text-align: left;
    height: 100%;
    min-width: var(--space-xxxl);
    display: flex;
    align-items: center;
`;

export const CompareValue = styled.div`
    min-width: 100px;
`;
