import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColorFromPercent } from '../../../../../../../tools/getColorFromPercent';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { CellWrapper, IconWrapper, MainCellWrapper, ReferenceCompareCellValue } from './styles';
import Icon from '../../../../../../../components/Icon/Icon';
import Stack from '../../../../../../../components/Stack/Stack';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { ICellProps } from './interfaces';

const Cell: React.FC<ICellProps> = ({
    deviationByReferenceCompare,
    showReferenceCompareValues,
    referenceCompareByMainAvg,
    showAverageValues,
    referenceCompare,
    isSelectedTenant,
    toggleCollapse,
    collapseable,
    deviationAvg,
    isExpanded,
    mainAvg,
    metric,
    alias,
    value,
    type,
}) => {
    const { allMetrics } = useSelector(generalReducerValues);

    const { t } = useTranslation();

    const cellValue = (value: number | string, type: string) => {
        switch (type) {
            case 'name': {
                return `${value} ${showAverageValues && collapseable ? t('AVG') : ''}`;
            }
            case 'deviation': {
                let units = '-';
                let result: string | number = value;

                if (showAverageValues && showReferenceCompareValues && deviationByReferenceCompare) {
                    units = '%';
                } else if (!isNaN(Number(showAverageValues && collapseable ? deviationAvg : value))) {
                    units = '%';
                }

                if (
                    showAverageValues &&
                    showReferenceCompareValues &&
                    deviationByReferenceCompare &&
                    !isSelectedTenant
                ) {
                    result = deviationByReferenceCompare;
                } else if (showAverageValues && collapseable && deviationAvg) {
                    result = deviationAvg;
                }

                return valueFormatter({ value: result, units });
            }
            case 'main': {
                const currentMetric = allMetrics?.find((item) => item.id === metric);

                const mainValue = valueFormatter({
                    value: showAverageValues && collapseable ? mainAvg : value,
                    units: currentMetric?.units || '',
                    showUnits: false,
                    precision: currentMetric?.round_decimal_places,
                });

                const referenceCompareResult = valueFormatter({
                    value: collapseable && showAverageValues ? referenceCompareByMainAvg : referenceCompare,
                    units: '%',
                });

                return (
                    <MainCellWrapper>
                        {(!showReferenceCompareValues || isSelectedTenant) && <span>{mainValue}</span>}
                        {(collapseable && showAverageValues && showReferenceCompareValues) ||
                        (!collapseable && !isSelectedTenant && showReferenceCompareValues) ? (
                            <ReferenceCompareCellValue
                                value={collapseable && showAverageValues ? referenceCompareByMainAvg : referenceCompare}
                            >
                                {referenceCompareResult}
                            </ReferenceCompareCellValue>
                        ) : null}
                    </MainCellWrapper>
                );
            }

            default:
                return value;
        }
    };

    const styles = (value: number | string, type: string) => {
        if (type === 'name') {
            const hover = collapseable ? true : false;
            const fontWeight = collapseable ? '600' : '400';
            return { textAlign: 'left', fontWeight, color: 'black', hover };
        } else if (type === 'main') {
            return { textAlign: 'right', fontWeight: '400', color: 'black' };
        } else {
            let result: string | number = value;

            if (showAverageValues && showReferenceCompareValues && deviationByReferenceCompare && !isSelectedTenant) {
                result = deviationByReferenceCompare;
            } else if (showAverageValues && collapseable && deviationAvg) {
                result = deviationAvg;
            }

            const color = getColorFromPercent(result);
            return { textAlign: 'right', fontWeight: '600', color };
        }
    };

    const onCellClick = () => {
        if (collapseable && alias && type === 'name') {
            toggleCollapse(alias);
        }
    };

    const iconType = isExpanded ? 'chevron-down' : 'chevron-right';

    return (
        <CellWrapper styles={styles(value, type)} onClick={onCellClick}>
            <Stack justifyContent="space-between" gap={10}>
                <span>{cellValue(value, type)}</span>
                {collapseable && type === 'name' && (
                    <IconWrapper>
                        <Icon type={iconType} size="xxs" />
                    </IconWrapper>
                )}
            </Stack>
        </CellWrapper>
    );
};

export default Cell;
