import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

const initialState: IReducerState = {};

export const Leasing_TenantOverview_Summary_Widget_Reducer = createSlice({
    name: 'Leasing_TenantOverview_Summary_Widget_Reducer',
    initialState,

    reducers: {},
});

export const Leasing_TenantOverview_Summary_Widget_Reducer_Values = (state: RootState) =>
    state.Leasing_TenantOverview_Summary_Widget_Reducer;

export default Leasing_TenantOverview_Summary_Widget_Reducer.reducer;
