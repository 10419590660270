import { omit } from 'lodash';
import { FC } from 'react';
import { IProps } from './interfaces';

const CellRenderer: FC<IProps> = (props) => {
    const modifiedProps = {
        ...omit(props, ['cell', 'editing', 'updated', 'selected', 'attributesRenderer']),
        colSpan: props.cell.colSpan,
        style: {
            ...props.style,
            ...props.cell.style,
        },
    };

    if (props.cell.forceComponent) {
        const Component = props.cell.customComponent;
        if (Component) {
            return (
                <td {...modifiedProps}>
                    <Component {...props} />
                </td>
            );
        }
    }
    return <td {...modifiedProps}>{props.children}</td>;
};

export default CellRenderer;
