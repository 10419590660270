import styled from 'styled-components/macro';

export const SelectsWrapper = styled.div`
    width: 100%;
    /* display: flex;
    align-items: flex-end;
    margin-bottom: var(--space-xxs); */
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        column-gap: 10px;
        margin-top: 15px;
    }
`;

export const MainPeriodText = styled.div`
    margin-right: var(--space-sm);
    font-size: var(--text-lg);
    font-weight: 500;
    padding-bottom: var(--space-xxxs);
    white-space: nowrap;
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        width: 100%;
        padding-bottom: 0;
    }
`;
