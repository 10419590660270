import React from 'react';
import Categories from './widgets/Categories/Categories';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';

const Dashboard: React.FC = () => {
    return (
        <ModuleWrapper>
            <WrapperContainer style={{ maxWidth: 'none' }}>
                <Categories />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Dashboard;
