import { FC, FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { IDataObjectsTenantFilterState, IGetComponentProps } from '../../interfaces';
import { CheckboxWrapper, CollapseWrapper } from './styles';
import Collapse from '../../../../../../components/Collapse/Collapse';
import Checkbox from '../../../../../../components/Checkbox/Checkbox';
import useTranslation from '../../../../../useTranslation/useTranslation';
import { Radio, RadioGroup, RadioGroupOnChangeData } from '@fluentui/react-components';

/**
 * Компонент для отображения фильтра арендаторов
 */
const Filter: FC<IGetComponentProps> = ({ filterData, onFiltersChange }) => {
    const [isFilterActive, setIsFilterActive] = useState(filterData.active);
    const [shouldUpdateFilterActive, setShouldUpdateFilterActive] = useState(false);

    const [selectedTenantFilterType, setSelectedTenantFilterType] = useState<string | undefined>();
    const [shouldUpdateTenantFilterType, setShouldUpdateTenantFilterType] = useState(false);

    const { t } = useTranslation();

    /**
     * Установка значения активного фильтра арендаторов
     */
    useLayoutEffect(() => {
        const selectedTenantFilterType = (
            filterData.filter_state as IDataObjectsTenantFilterState
        )?.tenant_filter_types?.find((element) => element.active)?.key;
        setSelectedTenantFilterType(selectedTenantFilterType);
    }, [filterData.filter_state]);

    /**
     * Вызов функции изменения фильтров при изменении состояние самого фильтра
     */
    useEffect(() => {
        if (shouldUpdateFilterActive) {
            setShouldUpdateFilterActive(false);
            onFiltersChange({ filterType: filterData.filter_type, key: 'active', value: isFilterActive });
        }
    }, [isFilterActive, shouldUpdateFilterActive]);

    /**
     * Изменение состояние внутреннего состояние фильтра
     */
    useEffect(() => {
        if (shouldUpdateTenantFilterType && filterData.filter_state) {
            setShouldUpdateTenantFilterType(false);
            const filterTypes = (filterData.filter_state as IDataObjectsTenantFilterState)?.tenant_filter_types.map(
                (element) => {
                    return {
                        ...element,
                        active: selectedTenantFilterType === element.key,
                    };
                },
            );

            const payload = {
                filterType: filterData.filter_type,
                key: 'filter_state',
                value: {
                    ...filterData.filter_state,
                    tenant_filter_types: filterTypes,
                },
            };
            onFiltersChange(payload);
        }
    }, [selectedTenantFilterType, shouldUpdateTenantFilterType]);

    const toggleFilterActive = (data: { checked: boolean }) => {
        setIsFilterActive(data.checked);
        setShouldUpdateFilterActive(true);
    };

    const onTenantFilterTypeChange = (_: FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
        setSelectedTenantFilterType(data.value);
        setShouldUpdateTenantFilterType(true);
    };

    return (
        <CollapseWrapper>
            <Collapse
                isCollapsed={!isFilterActive}
                additionalControl={
                    <CheckboxWrapper>
                        <Checkbox id={'Tenants filter'} selected={isFilterActive} handleToggle={toggleFilterActive} />
                    </CheckboxWrapper>
                }
                additionalControlPosition="before"
                label={t('Tenants filter')}
                iconJustify="space-between"
                hover="background-none"
                icon="chevron-right"
                iconPosition="right"
            >
                <RadioGroup
                    disabled={!isFilterActive}
                    value={selectedTenantFilterType}
                    onChange={onTenantFilterTypeChange}
                >
                    {(filterData.filter_state as IDataObjectsTenantFilterState)?.tenant_filter_types.map((element) => (
                        <Radio key={element.key} value={element.key} label={t(element.text)} />
                    ))}
                </RadioGroup>
            </Collapse>
        </CollapseWrapper>
    );
};

export default Filter;
