export enum RatingPasswaysTrafficIds {
    All = 'all',
    Location = 'location',
    Floor = 'floor',
}

export enum NumbersWorkingModeIds {
    Sum = 'sum',
    DailyAverage = 'dailyAverage',
}
