import { FC } from 'react';
import { IReportingObjectWithEvents } from '../../interfaces';
import { Title, Wrapper, EventsCount, ContentWrapper } from './styles';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';

const Card: FC<IReportingObjectWithEvents> = (props) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <ContentWrapper>
                <Title>{props.reportingObject.name}</Title>
                <EventsCount>
                    <span>{t('Events count')}:&nbsp;</span>
                    {props.events.length}
                </EventsCount>
            </ContentWrapper>
        </Wrapper>
    );
};

export default Card;
