import { isNull, isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { IChangedEvent, ITableCellData } from '../interfaces';

const checkRow = (row: ITableCellData[], timezone: string): { hasError: boolean } => {
    let dateFrom = '';
    let dateTo = '';
    let timeFrom = '';
    let timeTo = '';
    row.forEach((cell) => {
        if (!isUndefined(cell.value) && !isNull(cell.value)) {
            if (cell.key === 'date_from') {
                dateFrom = cell.value;
            }

            if (cell.key === 'date_to') {
                dateTo = cell.value;
            }

            if (cell.key === 'time_from') {
                timeFrom = cell.value;
            }

            if (cell.key === 'time_to') {
                timeTo = cell.value;
            }
        }
    });

    const df = `${dateFrom}T${timeFrom}`;
    const dt = `${dateTo}T${timeTo}`;

    const dtFrom = getDate(df, timezone);
    const dtTo = getDate(dt, timezone);

    const hasError = !dtFrom.isValid || !dtTo.isValid || dtFrom.toMillis() >= dtTo.toMillis() ? true : false;
    return { hasError };
};

export default checkRow;

const getDate = (str: string, tz: string) => {
    return DateTime.fromFormat(str, "yyyy-MM-dd'T'HH:mm", { zone: tz as string });
};
