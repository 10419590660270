import { IChartOptionsGeneratorSettings, IExtendedReportingObject } from '../interfaces';
import {
    IMsDataObject,
    IPeriod,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2ZoneRelation,
    TMetricResponse,
} from '../../../../../../General.interfaces';
import { IOptionsOfFunnelSteps, IdsOfFunnelStepsOptions, LegendNamesOfFunnelSteps } from '../../../constants/constants';

import { cloneDeep, sum } from 'lodash';
import filterTenantValidZone from './filterTenantValidZone';
import filterZoneValidFloors from './filterZoneValidFloors';
import { ZONES_WORD } from '../../../../../../constants/constants';
import filterTenantValidFloors from './filterTenantValidFloors';
import { TFunction } from 'i18next';

interface IArgs {
    reportingObjectsById: { [reportingObjectId: string]: IReportingObject };
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    tenant2FloorRelations: ITenant2FloorRelation[];
    tenant2ZoneRelations: ITenant2ZoneRelation[];
    selectedReportingObjectsIds: number[];
    funnelSteps: IOptionsOfFunnelSteps[];
    rawMetricsData: TMetricResponse[];
    msDataObjects: IMsDataObject[];
    selectedGroupMarker: string | undefined;
    mainDateRanges: IPeriod[];
    mainPeriod: IPeriod;
    t: TFunction;
}

interface IResponse {
    chartOptionsGeneratorSettings: IChartOptionsGeneratorSettings;
    extendedReportingObjects: IExtendedReportingObject[];
}

/**
 * Функция для генерации данных для графика и расширенных отчетных объектов
 * @param reportingObjectsByTypeAndMarker Объект отчетных объектов где ключ это [тип:маркер]
 * @param selectedReportingObjectsIds id выбранных отчетных объектов
 * @param reportingObjectsById Отчетный объекты, где ключ это id отчетного объекта
 * @param tenant2FloorRelations Взаимосвязь арендатора и этажей
 * @param tenant2ZoneRelations Взаимосвязь арендатора и зоны
 * @param selectedGroupMarker Выбранный маркер группы
 * @param rawMetricsData Массивы сырых данных метрики
 * @param mainDateRanges Периоды дат для главного периода
 * @param msDataObjects Данные для отчетных объектов
 * @param funnelSteps Шаги воронки
 * @param mainPeriod Главный период
 */
const generateData = (args: IArgs): IResponse => {
    const {
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectsIds,
        tenant2FloorRelations,
        reportingObjectsById,
        tenant2ZoneRelations,
        selectedGroupMarker,
        rawMetricsData,
        mainDateRanges,
        msDataObjects,
        funnelSteps,
        mainPeriod,
    } = args;

    const trafficData = rawMetricsData?.find((element) => element[0].context.metric === 'fpc_sum_pass_count_in_wh');
    const billsData = rawMetricsData?.find((element) => element[0].context.metric === 'fsf_bill_count');

    const location = trafficData?.find((element) => element.context.data_objects[0].type === 'location');
    const mainPeriodDateRanges = mainDateRanges?.find((element) => element.id === mainPeriod!.id);

    const extendedReportingObjects: IExtendedReportingObject[] = [];

    /** Получение начальной структуры серий по их id */
    const seriesById = cloneDeep(funnelSteps)
        .sort((a, b) => b.order - a.order)
        .reduce((acc, value) => {
            if (value.id !== IdsOfFunnelStepsOptions.Location) {
                acc[value.id] = {
                    id: value.legendName,
                    name: value.legendName,
                    color: value.seriesColor,
                    type: 'column',
                    data: [],
                    isMain: true,
                    category: '',
                };
            }
            return acc;
        }, {});
    const categories: string[] = [];

    if (mainPeriodDateRanges && Object.keys(reportingObjectsById).length && location) {
        /** Получение данных метрики по локации */
        const locationData = {
            name: location.context.data_objects[0].name,
            metricValue: location.items[0].value,
        };

        selectedReportingObjectsIds.forEach((reportingObjectId, reportingObjectIndex) => {
            const currentReportingObject = reportingObjectsById[reportingObjectId] as IReportingObject | undefined;
            if (currentReportingObject) {
                /** Добавляем имя отчетного объекта в категорию. Это имя будет отображаться на оси Y */
                categories.push(currentReportingObject.name);

                /** В зависимости от типа отчетного объекта добавляем данные */
                switch (true) {
                    case currentReportingObject.object_type === 'tenant':
                        const tenant: IExtendedReportingObject = {
                            reportingObjectData: currentReportingObject,
                            location: locationData,
                            floor: null,
                            zone: null,
                            tenant: null,
                            billsCount: null,
                            isLoading: false,
                        };
                        const validZone = filterTenantValidZone({
                            reportingObjectsByTypeAndMarker,
                            tenant: currentReportingObject,
                            tenant2ZoneRelations,
                            selectedGroupMarker,
                            mainDateRanges,
                            mainPeriod,
                        });

                        const tenantValidFloors = filterTenantValidFloors({
                            reportingObjectsByTypeAndMarker,
                            tenant: currentReportingObject,
                            tenant2FloorRelations,
                            mainDateRanges,
                            mainPeriod,
                        });

                        /** Если у Арендатора есть связь с Зоной, то добавляем данные по зоне */
                        if (validZone) {
                            const currentZoneMetricsData = trafficData?.find((metricsData) => {
                                return metricsData?.context.data_objects[0].id === validZone.id;
                            });

                            if (currentZoneMetricsData) {
                                tenant.zone = {
                                    name: currentZoneMetricsData.context.data_objects[0].name,
                                    metricValue: currentZoneMetricsData.items[0].value,
                                };
                                seriesById[IdsOfFunnelStepsOptions.Zone]?.data.push({
                                    y: currentZoneMetricsData.items[0].value,
                                    units: 'ppl',
                                    showUnits: true,
                                    x: reportingObjectIndex,
                                    name: LegendNamesOfFunnelSteps.zone,
                                });
                            }
                        }

                        /** Если у Арендатора есть связь с Этажом, то добавляем данные по этажу */
                        if (tenantValidFloors) {
                            const { yValue, name } = tenantValidFloors.reduce(
                                (acc, floor) => {
                                    const currentFloorMetricsData = trafficData?.find((metricsData) => {
                                        return metricsData.context.data_objects[0].id === floor.id;
                                    });

                                    if (currentFloorMetricsData) {
                                        acc.yValue = sum([currentFloorMetricsData.items[0].value, acc.yValue]);
                                        if (!acc.name) acc.name += floor.name;
                                        else acc.name += ` + ${floor.name}`;
                                    }

                                    return acc;
                                },
                                { yValue: 0, name: '' },
                            );

                            tenant.floor = {
                                metricValue: yValue,
                                name,
                            };

                            seriesById[IdsOfFunnelStepsOptions.Floor]?.data.push({
                                name: LegendNamesOfFunnelSteps.floor,
                                x: reportingObjectIndex,
                                y: yValue,
                                units: 'ppl',
                                showUnits: true,
                            });
                        }
                        /** Добавление информации по чекам */
                        const billsCountData = billsData?.find((item) => {
                            return item.context.data_objects[0].id === currentReportingObject.id;
                        });

                        if (billsCountData) {
                            tenant.billsCount = {
                                name: billsCountData.context.data_objects[0].name,
                                metricValue: billsCountData.items[0].value,
                            };
                            seriesById[IdsOfFunnelStepsOptions.BillsCount]?.data.push({
                                y: billsCountData.items[0].value,
                                name: LegendNamesOfFunnelSteps.billsCount,
                                x: reportingObjectIndex,
                                units: 'pc',
                                showUnits: true,
                            });
                        }
                        /** Добавление информации посещаемости для самого арендатора */
                        const currentTenantMetricsData = trafficData?.find((metricsData) => {
                            return metricsData.context.data_objects[0].id === currentReportingObject.id;
                        });

                        if (currentTenantMetricsData) {
                            tenant.tenant = {
                                name: currentTenantMetricsData.context.data_objects[0].name,
                                metricValue: currentTenantMetricsData.items[0].value,
                            };
                            seriesById[IdsOfFunnelStepsOptions.Tenant]?.data.push({
                                y: currentTenantMetricsData.items[0].value,
                                name: LegendNamesOfFunnelSteps.tenant,
                                x: reportingObjectIndex,
                                units: 'ppl',
                                showUnits: true,
                            });
                        }
                        extendedReportingObjects.push(tenant);
                        break;

                    /** Обработка кейса с зоной */
                    case currentReportingObject.object_type.includes(ZONES_WORD):
                        const zone: IExtendedReportingObject = {
                            reportingObjectData: currentReportingObject,
                            location: locationData,
                            floor: null,
                            zone: null,
                            tenant: null,
                            billsCount: null,
                            isLoading: false,
                        };

                        const zoneValidFloors = filterZoneValidFloors({
                            zoneMarker: currentReportingObject.marker,
                            reportingObjectsByTypeAndMarker,
                            mainDateRanges,
                            msDataObjects,
                            mainPeriod,
                        });

                        const currentZoneMetricsData = trafficData?.find((metricsData) => {
                            return metricsData.context.data_objects[0].id === currentReportingObject.id;
                        });

                        if (currentZoneMetricsData) {
                            zone.zone = {
                                name: currentZoneMetricsData.context.data_objects[0].name,
                                metricValue: currentZoneMetricsData.items[0].value,
                            };
                            seriesById[IdsOfFunnelStepsOptions.Zone]?.data.push({
                                y: currentZoneMetricsData.items[0].value,
                                name: LegendNamesOfFunnelSteps.zone,
                                x: reportingObjectIndex,
                                units: 'ppl',
                                showUnits: true,
                            });
                        }

                        if (zoneValidFloors) {
                            const { yValue, name } = zoneValidFloors.reduce(
                                (acc, floor) => {
                                    const currentFloorMetricsData = trafficData?.find((metricsData) => {
                                        return metricsData.context.data_objects[0].id === floor.id;
                                    });

                                    if (currentFloorMetricsData) {
                                        acc.yValue = sum([currentFloorMetricsData.items[0].value, acc.yValue]);
                                        if (!acc.name) acc.name += floor.name;
                                        else acc.name += ` + ${floor.name}`;
                                    }

                                    return acc;
                                },
                                { yValue: 0, name: '' },
                            );

                            zone.floor = {
                                metricValue: yValue,
                                name,
                            };

                            seriesById[IdsOfFunnelStepsOptions.Floor]?.data.push({
                                name: LegendNamesOfFunnelSteps.floor,
                                x: reportingObjectIndex,
                                y: yValue,
                                units: 'ppl',
                                showUnits: true,
                            });
                        }
                        extendedReportingObjects.push(zone);
                        break;

                    case currentReportingObject.object_type === 'floor':
                        const floor: IExtendedReportingObject = {
                            reportingObjectData: currentReportingObject,
                            location: locationData,
                            floor: null,
                            zone: null,
                            tenant: null,
                            billsCount: null,
                            isLoading: false,
                        };
                        const currentFloorMetricsData = trafficData?.find((metricsData) => {
                            return metricsData.context.data_objects[0].id === currentReportingObject.id;
                        });
                        if (currentFloorMetricsData) {
                            floor.floor = {
                                name: currentFloorMetricsData.context.data_objects[0].name,
                                metricValue: currentFloorMetricsData.items[0].value,
                            };
                            seriesById[IdsOfFunnelStepsOptions.Floor]?.data.push({
                                y: currentFloorMetricsData?.items[0].value,
                                name: LegendNamesOfFunnelSteps.floor,
                                x: reportingObjectIndex,
                                units: 'ppl',
                                showUnits: true,
                            });
                        }
                        extendedReportingObjects.push(floor);
                        break;

                    default:
                        break;
                }
            }
        });
    }

    return {
        chartOptionsGeneratorSettings: {
            series: Object.values(seriesById),
            columnsStacking: 'normal',
            xAxisType: 'category',
            sharedTooltips: false,
            inactiveSeriesOpacity: 0.2,
            seriesReverse: true,
            columnsGrouping: false,
            inverted: true,
            categories,
        },
        extendedReportingObjects,
    };
};

export default generateData;
