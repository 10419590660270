import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { Dashboards_Reducer_Values, postUserDashboards } from '../../../Dashboards.reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import Stack from '../../Stack/Stack';
import Button from '../../Button/Button';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import TextInput from '../../TextInput/TextInput';
import { useAppDispatch } from '../../../hooks/useSettings';
import { IDashboard } from '../interfaces';
import generateId from '../../../tools/generateId';

const AddNewBody: React.FC<IProps> = ({ defaultDashboards, closeModal }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const [selected, setSelected] = useState<{ colIndex: number; type: string; widgetId?: string }[]>([
        { colIndex: 0, type: 'number' },
    ]);
    const [name, setName] = useState('');

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onCancelClick = () => {
        closeModal();
    };

    const onAddClick = () => {
        const dashboards: IDashboard[] = [
            {
                dashboard_key: generateId(),
                dashboard_name: name,
                module_id: currentModuleID,
                widgets: [],
                version: 1,
                layouts: { lg: [], md: [], sm: [], xs: [], xxs: [] },
            },
        ];
        dispatch(postUserDashboards({ dashboards }));
        closeModal();
    };

    const handleChange = (args: { value: string }) => {
        setName(() => args.value);
    };

    return (
        <Wrapper>
            <TextInput
                fullWidth
                placeholder={t('Type custom name')}
                handleChange={handleChange}
                outsideValue={name}
                hasError={name.length < 2}
            />
            <Stack alignItems="center" justifyContent="flex-end" gap={10}>
                <Button onClick={onCancelClick}>{t('Cancel')}</Button>
                <Button appearance="primary" onClick={onAddClick} disabled={name.length < 2}>
                    {t('Add')}
                </Button>
            </Stack>
        </Wrapper>
    );
};

export default AddNewBody;
