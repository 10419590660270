const setHslLightness = (hsl: string, lightness = 50): string => {
    // Extract the hue, saturation, and lightness values from the HSL string
    const regex = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/;
    const match = hsl.match(regex);

    if (!match) {
        console.log('Invalid HSL string format');
        return hsl;
    }

    const hue = parseInt(match[1], 10);
    const saturation = parseFloat(match[2]);

    const modifiedHSL = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    return modifiedHSL;
};

export default setHslLightness;
