import { ISheetCell } from '../../../../../General.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import {
    IIntersectionsReportWidgetState,
    TRawAverageIntersectionsData,
    IExtendedAverageIntersection,
} from './interfaces';

const initialState: IIntersectionsReportWidgetState = {
    extendedAverageIntersectionsData: null,
    rawAverageIntersectionsData: null,
    downloadSignalCounter: 0,
    grid: null,
    keyWord: 'Intersections',
};

export const Reports_Intersections_Widget_Reducer = createSlice({
    name: 'Reports_Intersections_Widget_Reducer',
    initialState,

    reducers: {
        /**
         * Сигнал на скачивание
         */
        increaseDownloadSignalCounter: (state, _: PayloadAction) => {
            state.downloadSignalCounter += 1;
        },
        /**
         * Сохранение табличных данных
         */
        storeGrid: (state, action: PayloadAction<ISheetCell[][] | null>) => {
            state.grid = action.payload;
        },

        /**
         * Сохранение сырых данных среднедневынх пересечений
         */
        storeRawAverageIntersectionsData: (state, action: PayloadAction<TRawAverageIntersectionsData | null>) => {
            state.rawAverageIntersectionsData = action.payload;
        },

        /**
         * Сохранение расширенных данных по среднедневным пересечениям
         */
        storeExtendedAverageIntersectionsData: (
            state,
            action: PayloadAction<IExtendedAverageIntersection[][] | null>,
        ) => {
            state.extendedAverageIntersectionsData = action.payload;
        },

        /**
         * Обнуление редьюсера
         */
        resetIntersewctionsWidgetReducer: () => initialState,
    },
});

export const {
    storeExtendedAverageIntersectionsData,
    storeRawAverageIntersectionsData,
    resetIntersewctionsWidgetReducer,
    increaseDownloadSignalCounter,
    storeGrid,
} = Reports_Intersections_Widget_Reducer.actions;

export const Reports_Intersections_Widget_Reducer_Values = (state: RootState) =>
    state.Reports_Intersections_Widget_Reducer;

export default Reports_Intersections_Widget_Reducer.reducer;
