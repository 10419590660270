import { IPassway2DataObjRow, IPeriodObj, IReportingObject } from '../../../../../../General.interfaces';
import { ZONES_WORD } from '../../../../../../constants/constants';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';

interface IArgs {
    passway2DataObjRelations: IPassway2DataObjRow[];
    mainPeriod: IPeriodObj;
    ratingType: string;
    selectedFloor: number | null;
    selectedZoneId: string | null;
    reportingObjectsById: { [id: string]: IReportingObject };
}

const getAvailableIds = (args: IArgs): number[] => {
    const filteredByPeriod = filterValidDateRanges(args.passway2DataObjRelations, args.mainPeriod) || [];
    return filteredByPeriod
        .filter((item) => {
            switch (true) {
                case args.ratingType === 'all': {
                    return true;
                }

                case args.ratingType === 'floor': {
                    return item.dataobj_type === args.ratingType && Number(item.dataobj_marker) === args.selectedFloor;
                }

                case args.ratingType.includes(ZONES_WORD): {
                    return (
                        item.dataobj_type === 'zone' &&
                        item.dataobj_marker === args.reportingObjectsById?.[args.selectedZoneId || '']?.marker
                    );
                }

                default: {
                    return item.dataobj_type === args.ratingType;
                }
            }
        })
        .map((item) => item.passway_dataobj_id);
};

export default getAvailableIds;
