import { Filter24Regular } from '@fluentui/react-icons';
import styled from 'styled-components/macro';

export const StyledFilterIcon = styled(Filter24Regular)`
    color: var(--color-secondary-darker);
    ${(p) => (p.theme.isRtlLanguage ? 'margin-right: 5px;' : 'margin-left: 5px;')}
    cursor: pointer;
    :hover {
        color: var(--color-primary);
    }
`;

export const AdditionalFilterIconWrapper = styled.div`
    position: relative;
    display: flex;
`;

export const NumberOfActiveFilters = styled.div`
    position: absolute;
    top: -7px;
    right: ${(p) => (p.theme.isRtlLanguage ? 'unset' : '-7px')};
    left: ${(p) => (p.theme.isRtlLanguage ? '-7px' : 'unset')};
    padding: 4px;
    border-radius: 50%;
    background-color: var(--color-primary);
    color: white;
    font-size: 10px;
    line-height: 8px;
    min-width: 15px;
    display: flex;
    height: 15px;
    justify-content: center;
    align-items: center;
`;
