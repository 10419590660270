import styled from 'styled-components/macro';

export const Item = styled.div<{ selected: boolean }>`
    cursor: pointer;
    padding: var(--space-xxxs);
    background-color: ${(p) => (p.selected ? 'var(--color-primary)' : 'auto')};
    color: ${(p) => (p.selected ? 'var(--color-white)' : 'auto')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    :hover {
        color: ${(p) => (p.selected ? 'var(--color-white)' : 'var(--color-primary)')};
        /* font-weight: 700; */
    }
`;
export const ManagerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    grid-gap: 10px;
    width: 1000px;
    min-height: 600px;
`;
export const Column = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--color-secondary-darker);
    overflow: auto;
    height: 800px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    justify-content:flex-end;
`;
