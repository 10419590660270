import { useSelector } from 'react-redux';
import Button from '../../../../../../../components/Button/Button';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import useCreateBgReport from './hooks/useCreateBgReport';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useCallback, useMemo } from 'react';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from '../../reducer';
import { AddCircle24Regular } from '@fluentui/react-icons';
import { DS } from '../../../../../../../constants/constants';
import { IPeriod, IPeriodObj } from '../../../../../../../General.interfaces';
import { ISelectedOption } from '../../../../../../../components/Selects/Select/interfaces';
import { Wrapper } from './styles';

const CreateBgReportButton = ({
    onClick,
    billsCount,
}: {
    billsCount: undefined | null | number;
    onClick: () => void;
}) => {
    const { mutate, isPending } = useCreateBgReport();
    const { keyWord, bgReportType } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);
    const { selectedLocationId, currentModuleID, backgroundReportTypes, lang, appCoreData, currencyCode } =
        useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const rest = `${DS}${keyWord}`;

    const { t } = useTranslation();

    /** Основной период */
    const period: IPeriodObj | null = useMemo(() => {
        if (localCurrentOptions?.[`mainPeriod${rest}`] && localCurrentOptions?.[`mainDateRanges${rest}`]) {
            return (
                localCurrentOptions?.[`mainDateRanges${rest}`]?.find(
                    (dateRange: IPeriod) => dateRange.id === localCurrentOptions?.[`mainPeriod${rest}`]?.id,
                )?.period || null
            );
        }
        return null;
    }, [localCurrentOptions, rest]);

    /** ID типа отчета */
    const reportTypeId = useMemo(() => {
        if (backgroundReportTypes?.length) {
            return backgroundReportTypes.find((type) => type.name === bgReportType)?.id || null;
        }
        return null;
    }, [backgroundReportTypes, bgReportType]);

    const onCreate = useCallback(() => {
        if (selectedLocationId && period && reportTypeId && localCurrentOptions?.[`reportObject${rest}`]?.length) {
            const langCode = appCoreData?.languages?.find((item) => item.includes(lang)) || 'en-US';

            mutate({
                date_from: period.dateFrom,
                date_to: period.dateTo,
                pl_id: selectedLocationId,
                input_parameters: {
                    obj_ids: localCurrentOptions?.[`reportObject${rest}`],
                    additional_fields: localCurrentOptions?.[`additionalFields${rest}`]?.map(
                        (item: ISelectedOption) => item.id,
                    ),
                },
                report_type: reportTypeId,
                lang_code: langCode,
                currency_code: currencyCode,
            });
        }
        onClick();
        return null;
    }, [selectedLocationId, period, reportTypeId, localCurrentOptions, rest, mutate]);

    return (
        <Wrapper>
            <Button
                appearance="primary"
                onClick={onCreate}
                text={
                    isPending || billsCount === undefined
                        ? t('Loading...')
                        : !billsCount
                        ? t('No bills')
                        : t('Order a new report')
                }
                disabled={isPending || !billsCount}
                icon={<AddCircle24Regular />}
            />
        </Wrapper>
    );
};

export default CreateBgReportButton;
