import { FC, PropsWithChildren, useMemo } from 'react';
import { IProps } from './interfaces';

import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import { Tooltip as TooltipHost } from '@fluentui/react-components';
import { DivWrapperChildrenSC, DivWrapperContentSC, useStyles } from './styles';

/**
 * Кастомный тултип на основе Fluent Tooltip
 */
const Tooltip: FC<PropsWithChildren<IProps>> = ({
    children,
    showTooltip = true,
    content,
    positioning = 'top',
    gap,
    ...props
}) => {
    const { mainAreaSize } = useSelector(generalReducerValues);

    const _positioning = useMemo(() => {
        switch (positioning) {
            case 'top':
                return 'above';
            case 'bottom':
                return 'below';
            case 'left':
                return 'before';
            case 'right':
                return 'after';

            default:
                return 'above';
        }
    }, [positioning]);

    const styles = useStyles();

    const _content = {
        children: (
            <DivWrapperContentSC style={props.contentWrapperStyles} width={`${0.5 * mainAreaSize.width}px`}>
                {content}
            </DivWrapperContentSC>
        ),
        className: styles.tooltip,
    };

    return showTooltip ? (
        <TooltipHost
            showDelay={100}
            positioning={{ position: _positioning, offset: 5 }}
            relationship="inaccessible"
            content={_content}
            // visible={true}
            hideDelay={100}
            // styles={styles}
        >
            <DivWrapperChildrenSC style={props.contentWrapperStyles}>{children}</DivWrapperChildrenSC>
        </TooltipHost>
    ) : (
        <>{children}</>
    );
};

export default Tooltip;
