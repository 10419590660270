import styled from 'styled-components/macro';


export const Footer = styled.div`
    margin-top: var(--space-sm);
    display: flex;
    justify-content: flex-end;
`;
export const Body = styled.div`
    margin-top: var(--space-sm);
    font-size: medium;
    ul{
        margin-top: 0;
        padding-left: var(--space-xxs);
    }
    li {
    }
    /* display: flex;
    justify-content: flex-end; */
`;
