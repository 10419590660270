import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ isScreenHeight: boolean }>`
    position: absolute;
    width: calc(100% - var(--space-sidebar));
    left: 250px;
    flex-grow: 1;
    height: ${(p) => (p.isScreenHeight ? '100vh' : 'auto')};
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    justify-content: center;
    overflow: auto;
    z-index: 10000;

    /* @media (max-width: ${(p) => p.theme?.breakpoints?.tablet}) {
        width: 100%;
        position: relative;
        left: 0px;
    } */
`;

export const ContentWrapper = styled.div`
    width: 100%;
    max-height: 70vh;
    overflow: auto;
    position: absolute;
    top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextWrapper = styled.div`
    margin-top: var(--space-md);
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const DefaultText = styled.div`
    color: #bcc0c4;
`;

export const ErrorText = styled.div`
    color: rgb(36, 36, 36);
`;
