import styled from 'styled-components/macro';

export const ContentWrapper = styled.div<{ maxWidth?: string; padding?: string }>`
    max-width: ${(p) => (p.maxWidth ? p.maxWidth : '50%')};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${(p) => p.padding || '0px'};
    height: 100%;

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        width: 100%;
        max-width: 100%;
    }
`;

export const LoaderWrapper = styled.div`
    flex: 0 0 100%;
`;
