import React from 'react';
import cn from 'classnames';
import * as constants from '../../../../constants/constants';

import { ICollapseHeaderProps } from '../../../../configurations';
import '../../../../styles.scss';
import Icon from '../../../../../Icon/Icon';
import { IconWrapper } from './styles';

const CollapseHeader: React.FC<ICollapseHeaderProps> = ({ title, icon, isCollapsed, ...props }) => {
    const { DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_CLASS_SWITCHER, DEFAULT_CLASS_SWITCHER_FORM } = constants;
    const switcherFormClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SWITCHER_FORM]: true,
        });
    }, [props]);
    const switcherClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SWITCHER]: true,
        });
    }, [props]);
    return (
        <div className={switcherFormClassName}>
            <div className={switcherClassName}>
                <IconWrapper isCollapsed={isCollapsed}>
                    <Icon type={icon} size="xxs" color="secondary-darker" />
                </IconWrapper>
                <div className="crumbs_sidebar-compact-switcher__content">
                    <div className="collapse-header navigation-item color-contrast-high">
                        <span className="navigation-item__text">{title}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollapseHeader;
