import { FC } from 'react';
import { Item } from './styles';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import Stack from '../../../../Stack/Stack';
import { AddCircle24Regular } from '@fluentui/react-icons';
import { IWidget } from '../../../interfaces';

export interface IProps {
    name: string;
    icon?: React.ReactNode;
    onClick?: (x: string) => void;
    onAddClick?: (x: IWidget) => void;
    selected?: boolean;
    showPlus?: boolean;
    data?: IWidget;
    widgetsCount?: number;
}

const ColumnItem: FC<IProps> = ({ onClick, onAddClick, icon = null, name, selected, showPlus, data, widgetsCount }) => {
    const { t } = useTranslation();

    const _onClick = (name: string) => () => {
        onClick && onClick(name);
    };

    const _onAddClick = (x: IWidget) => () => {
        onAddClick && onAddClick(x);
    };

    return (
        <Item selected={Boolean(selected)} onClick={data ? _onAddClick(data) : _onClick(name)}>
            <Stack alignItems="center" gap={10}>
                {showPlus ? <AddCircle24Regular /> : icon}
                <div>{t(name ?? '')}</div>
                {Boolean(widgetsCount) && <span>{`(${widgetsCount})`}</span>}
            </Stack>
        </Item>
    );
};

export default ColumnItem;
