import { createTableColumn } from '@fluentui/react-components';
import { TEventTypeColumn } from '../interfaces';
import { IEventType } from '../../../../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import EventTypeColor from '../components/EventTypeColor/EventTypeColor';
import EditEventType from '../components/EditEventType/EditEventType';

export const EVENT_TYPES_COLUMNS: TEventTypeColumn[] = [
    {
        ...createTableColumn<IEventType>({
            columnId: 'id',
            compare: (a, b) => {
                return b.id - a.id;
            },
        }),
        columnHeaderText: 'ID',
    },
    {
        ...createTableColumn<IEventType>({
            columnId: 'name',
            compare: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        }),
        columnHeaderText: 'Name',
    },
    {
        ...createTableColumn<IEventType>({
            columnId: 'color',
            compare: (a, b) => {
                return a.color.localeCompare(b.color);
            },
        }),
        columnHeaderText: 'Color',
        component: EventTypeColor,
    },
    {
        ...createTableColumn<IEventType>({
            columnId: 'edit',
        }),
        columnHeaderText: 'Edit',
        component: EditEventType,
    },
];
