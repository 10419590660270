export const weekDayNames = [
    { en: '' },
    { en: 'Mo' },
    { en: 'Tu' },
    { en: 'We' },
    { en: 'Th' },
    { en: 'Fr' },
    { en: 'Sa' },
    { en: 'Su' },
];

export const monthNames = {
    1: { en: 'January' },
    2: { en: 'February' },
    3: { en: 'March' },
    4: { en: 'April' },
    5: { en: 'May' },
    6: { en: 'June' },
    7: { en: 'July' },
    8: { en: 'August' },
    9: { en: 'September' },
    10: { en: 'October' },
    11: { en: 'November' },
    12: { en: 'December' },
};

export const shortMonthNames = {
    1: { en: 'Jan' },
    2: { en: 'Feb' },
    3: { en: 'Mar' },
    4: { en: 'Apr' },
    5: { en: 'May' },
    6: { en: 'Jun' },
    7: { en: 'Jul' },
    8: { en: 'Aug' },
    9: { en: 'Sep' },
    10: { en: 'Oct' },
    11: { en: 'Nov' },
    12: { en: 'Dec' },
};
export const bgColor = 'rgba(69,114,230,.2)';
