import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import getDifferenceBetweenNumbers from '../../../../../../../../../tools/getDifferenceBetweenNumbers';
import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import { TableOfObjectsContext } from '../../../../context';
import { IExtendedReportingObject } from '../../../../interfaces';
import { DefaultText, Td, Tr } from '../../styles';
import { MetricValuesWrapper, NameWrapper } from './styles';
import { isNumber } from 'lodash';

interface IProps {
    /** Отсортированные отчетные объекты */
    sortedReportingObjects: IExtendedReportingObject[] | null | undefined;
}

/**
 * Тело таблицы
 */
const Body: FC<IProps> = ({ sortedReportingObjects }) => {
    const context = useContext(TableOfObjectsContext);
    const { t } = useTranslation();

    return (
        <tbody>
            {sortedReportingObjects?.map((element) => {
                return (
                    <Tr key={element.reportingObjectData.id}>
                        <Td>
                            <NameWrapper>
                                <DefaultText>{element.reportingObjectData.name}</DefaultText>
                            </NameWrapper>
                        </Td>
                        {context?.selectedMetrics?.map((selectedMetric) => {
                            const mainValue: number | undefined | null =
                                element.metrics?.[selectedMetric]?.mainPeriod?.items[0].value;
                            const currentMetric = context?.allMetrics?.find((element) => element.id === selectedMetric);

                            let compareValue = undefined;
                            if (mainValue !== undefined && mainValue !== null) {
                                if (isNumber(element.metrics?.[selectedMetric]?.comparePeriod?.items[0].value)) {
                                    const difference = getDifferenceBetweenNumbers(
                                        mainValue,
                                        element.metrics?.[selectedMetric]?.comparePeriod?.items[0].value as number,
                                    );
                                    compareValue = difference.percentDifference;
                                }
                            }

                            return (
                                <Td key={element.reportingObjectData.id + selectedMetric}>
                                    {element.metricsLoading ? (
                                        t('Loading...')
                                    ) : element.metricsError ? (
                                        t('Unable to retrieve data')
                                    ) : (
                                        <MetricValuesWrapper>
                                            <DefaultText>
                                                {valueFormatter({
                                                    units: currentMetric?.units,
                                                    value: mainValue,
                                                    showUnits: false,
                                                })}
                                            </DefaultText>
                                            <DefaultText value={compareValue}>
                                                {valueFormatter({ value: compareValue, units: '%' })}
                                            </DefaultText>
                                        </MetricValuesWrapper>
                                    )}
                                </Td>
                            );
                        })}
                    </Tr>
                );
            })}
        </tbody>
    );
};

export default Body;
