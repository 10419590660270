import { IHelpMetricsSource } from '../interfaces';

export const HELP_METRICS_CONFIG: IHelpMetricsSource[] = [
    {
        title: 'People counting system',
        description: 'FpcDescription',
        metrics: [
            {
                title: 'Footfall Entrance',
                description: 'Footfall Entrance Description',
            },
            {
                title: 'Footfall Exit',
                description: 'Footfall Exit Description',
            },
            {
                title: 'Footfall (Entrance) per m²',
                description: 'Footfall (Entrance) per m² Description',
            },
            {
                title: 'Footfall (Exit) per m²',
                description: 'Footfall (Exit) per m² Description',
            },
            {
                title: 'Avg. Daily Visits',
                description: 'Avg. Daily Visits Description',
            },
            {
                title: 'Avg. Visit Duration (PC)',
                description: 'Avg. Visit Duration (PC) Description',
            },
        ],
    },
    {
        title: 'Wi-Fi analytics',
        description: 'Tracking object visitors by MAC addresses of their mobile devices with Wi-Fi sensors.',
        metrics: [
            {
                title: 'Average visit duration',
                description:
                    'The average duration of visitors staying within the selected location during the visit. For a shopping center, floor, zone, tenant, this is the time spent from the moment the visitor’s entry signal appeared until the visitor exited or disconnected the visitor’s wifi device. The average duration depends on the behavior of visitors in the store - for example, in a cosmetics store, a high average duration can indicate that buyers choose a product with consultants, and that visitors can look for the desired product longer without the attention of the consultants.',
            },
            {
                title: 'Visitors (total)',
                description:
                    'The number of visits by visitors that were assigned to visitors, that is, are not staff or stationary signals. The number of visits is the number of visits.',
            },
            {
                title: 'Visitors (unique)',
                description:
                    'The number of unique visitors that were assigned to visitors, that is, are not staff or stationary signals. The number of unique visitors is the number of MAC addresses.',
            },
            {
                title: 'Capture rate',
                description:
                    'Percentage of unique visitors who were in the coverage area of the router and entered the shopping center, floor, zone, tenant. The approximate range of the WiFi sensor in the premise is 50 meters.',
            },
            {
                title: 'Engagement rate',
                description:
                    "Percentage of unique visitors who spent more than the engagement threshold in the selected location. The engagement threshold is set in the Shopster metrics settings - the gear in the 'Metrics'.",
            },
            {
                title: 'Bounced rate',
                description:
                    "Percentage of unique visitors who spent less than the engagement threshold in the selected location. The engagement threshold is set in the Shopster metrics settings - the gear in the 'Metrics'.",
            },
            {
                title: 'Returned rate',
                description:
                    'Percentage of unique visitors who visited the selected location repeatedly during the selected return period. The return period is set in the Shopster metrics settings.',
            },
            {
                title: 'New visitors rate',
                description:
                    "Percentage of unique visitors who were not in the selected location during the selected return period. The return period is set in the Shopster metrics settings - the gear in the 'Metrics'.",
            },
            {
                title: 'Intersections for the period',
                description:
                    'Percentage of unique visitors who have visited the main location and moved to another target location for the entire selected period. Locations can be tenants and zones.',
            },
            {
                title: 'Average daily intersections',
                description:
                    'Percentage of unique visitors who visited the main location and moved to another target location for the period averaged by day. Locations can be tenants and zones.',
            },
            {
                title: 'Average visit duration (engaged)',
                description:
                    "The average duration of engaged visitors is the time spent at the location from the moment when visitor's entry signal appears until the moment the visitor exits or turns off the visitor's wifi device, excluding visitors who are below the engagement threshold.",
            },
        ],
    },
    {
        title: 'Sales Data Capture System',
        description: `Collecting data about sales amounts and tenants’ checks in the following ways:

- Pull/push API integration.
- Cloud sales connector.
- Client APP connection to the POS database.
- FTP/file upload.`,
        metrics: [
            {
                title: 'Sales (Ex. VAT)',
                description: `Tenant sales excluding VAT. For the shopping center, these are the total sales of tenants connected to the Sales Data Capture System, excluding VAT.`,
            },
            {
                title: 'Sales (Inc. VAT)',
                description: `Tenant sales including VAT. For the shopping center, these are the total sales of tenants connected to the Sales Data Capture System, including VAT.`,
            },
            {
                title: 'Net Sales (Ex. VAT)',
                description: `Tenant sales, net of returns and VAT. For the shopping center, these are the total net sales of tenants connected to the Sales Data Capture System, excluding returns and VAT.`,
            },
            {
                title: 'Sales (Ex. VAT) per m²',
                description: `Tenant sales excluding VAT, divided by the tenant's area. For the shopping center, this is the total sales volume of tenants connected to the Sales Data Capture System, excluding VAT, divided by the total area of these tenants.`,
            },
            {
                title: 'Sales (Inc. VAT) per m²',
                description: `Tenant sales including VAT, divided by the tenant's area. For the shopping center, this is the total sales volume of tenants connected to the Sales Data Capture System, including VAT, divided by the total area of these tenants.`,
            },
            {
                title: 'Net Sales (Ex. VAT) per m²',
                description: `Tenant net sales divided by the tenant's area. For the shopping center, this is the total volume of net sales from tenants connected to the Sales Data Capture System, divided by the total area of these tenants.`,
            },
            {
                title: 'Returns (Ex. VAT)',
                description: `Tenant returns excluding VAT. For the shopping center, this is the total volume of returns from tenants connected to the Sales Data Capture System, excluding VAT.`,
            },
            {
                title: 'Returns (Inc. VAT)',
                description: `Tenant returns including VAT. For the shopping center, this is the total volume of returns from tenants connected to the Sales Data Capture System, including VAT.`,
            },
            {
                title: 'Avg. Receipt Amount (Ex. VAT)',
                description: `Tenant sales excluding VAT, divided by the number of receipts. It may be displayed incorrectly if the tenant unloads sales in a single receipt per day. For the shopping center, this is the total sales volume of tenants, excluding VAT, divided by the total number of receipts from these tenants.`,
            },
            {
                title: 'Avg. Receipt Amount (Inc. VAT)',
                description: `Tenant sales including VAT, divided by the number of receipts. It may be displayed incorrectly if the tenant unloads sales in a single receipt per day. For the shopping center, this is the total sales volume of tenants, including VAT, divided by the total number of receipts from these tenants.`,
            },
            {
                title: 'VAT',
                description: `Value-added tax (VAT) amount paid on tenant sales. For the shopping center, this is the sum of VAT paid by tenants connected to the Sales Data Capture System.`,
            },
            {
                title: 'Num of Receipts',
                description: `Number of tenant receipts. The value may be displayed incorrectly if the tenant uploads sales in a single check per day. For the shopping center, this is the total number of receipts for tenants connected to the Sales Data Capture System.`,
            },
            {
                title: 'Num of SKUs',
                description: `Number of items sold by the tenant (product names). May be displayed incorrectly when detailed by hours if the tenant uploads sales in a single check per day. For the shopping center, this is the total number of items sold by tenants connected to the Sales Data Capture System.`,
            },
            {
                title: 'Qty of Items Sold',
                description: `Number of items sold by the tenant across all product items. May be displayed incorrectly when detailed by hours if the tenant uploads sales in a single check per day. For the shopping center, this is the total number of items sold by tenants connected to the Sales Data Capture System.`,
            },
            {
                title: 'Avg. Num of SKUs per Receipt',
                description: `Average number of product items (product names) per receipt. May be displayed incorrectly when detailed by hours if the tenant uploads sales in a single check per day. For the shopping center, this is the average number of product items sold per receipt across all tenants connected to the Sales Data Capture System.`,
            },
            {
                title: 'Avg. Qty of Items per Receipt',
                description: `Average number of products sold by the tenant per receipt. May be displayed incorrectly when detailed by hours if the tenant uploads sales in a single check per day. For the shopping center, this is the average number of products sold per receipt across all tenants connected to the Sales Data Capture System.`,
            },
            {
                title: 'Share of Returns (Ex. VAT)',
                description: `Refunds from the tenant excluding VAT, divided by sales from the tenant excluding VAT.`,
            },
            {
                title: 'Share of Returns (Inc. VAT)',
                description: `Refunds from the tenant including VAT, divided by sales from the tenant including VAT.`,
            },
            {
                title: 'Conversion Rate',
                description: `The number of tenant receipts divided by the number of tenant visitors. This may be displayed incorrectly if the tenant uploads sales in one receipt per day. For the shopping center, it is the total number of receipts from tenants connected to the Sales Data Capture System divided by the total number of visitors for these tenants.`,
            },
        ],
    },
];
