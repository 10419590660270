import styled from 'styled-components/macro';

export const SidebarList = styled.ul`
    box-sizing: border-box;
    display: flex;
    gap: var(--space-xs);
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
`;
