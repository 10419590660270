import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { storeRawMetricsData, storeTableSortData, Leasing_Tenants_Tenants_Widget_Reducer_Values } from '../reducer';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePrepareData from './usePrepareData';
import { useEffect } from 'react';
import usePeriods from '../../../../../../hooks/common/usePeriods';
import { IDateRange } from '../../../../../../General.interfaces';
import { IRequestMetricsArgs } from '../../../../../../tools/API/interfaces';

/** Кастомный хук для получения сырых данных*/
export const useGetRawData = () => {
    const { idsOfTenants, dataRefetchObject, moduleName } = useSelector(Leasing_Tenants_Tenants_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { token } = useSelector(generalReducerValues);

    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mainPeriod, comparePeriods } = usePeriods(moduleName);
    usePrepareData();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (localCurrentOptions?.selectedMetrics && mainPeriod && comparePeriods.length && idsOfTenants && token) {
            dispatch(storeTableSortData(null));
            const generateMetricsQueryData = (period: IDateRange, alias: string): IRequestMetricsArgs[] => {
                const queryData: IRequestMetricsArgs[] = [];

                (localCurrentOptions.selectedMetrics ?? []).forEach((metric) => {
                    if (period.period) {
                        queryData.push({
                            time_range: [period.period.dateFrom, period.period.dateTo],
                            obj_ids: idsOfTenants,
                            object_aggregation: false,
                            time_freq: null,
                            alias,
                            metric,
                            token,
                        });
                    }
                });

                return queryData;
            };
            const mainPeriodQueryData = generateMetricsQueryData(mainPeriod, 'main').map((element) => ({
                ...element,
                signal,
            }));

            const comparePeriodQueryData = generateMetricsQueryData(comparePeriods[0], 'compare').map((element) => ({
                ...element,
                signal,
            }));

            const finalQueryData = mainPeriodQueryData.concat(comparePeriodQueryData);
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));
            fetchData(finalQueryData).then((response: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    res: response,
                    dispatch,
                });
            });
        }
        return () => controller.abort();
    }, [
        JSON.stringify(mainPeriod?.period),
        JSON.stringify(comparePeriods.map((period) => period.period)),
        dataRefetchObject,
        idsOfTenants,
        token,
        JSON.stringify(localCurrentOptions?.selectedMetrics),
    ]);
};
