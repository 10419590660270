import { PlotOptions } from 'highcharts';
import { DYNAMICS_DEFAULT_PLOT_OPTIONS_CONFIG } from '../constants/constants';

interface IArgs {
    plotOptions?: PlotOptions;
}

const generatePlotOptions = (args: IArgs): PlotOptions => {
    return {
        ...DYNAMICS_DEFAULT_PLOT_OPTIONS_CONFIG,
        ...args.plotOptions,
    };
};

export default generatePlotOptions;
