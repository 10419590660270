export enum AdditionalFieldsIds {
    AddId = 'addId',
    AddTimeZone = 'addTimeZone',
    AddWeekDay = 'addWeekDay',
    AddCategories = 'addCategories',
    AddPercent = 'addPercent',
    AddDelta = 'addDelta',
    AddPlace = 'addPlace',
    AddZone = 'addZone',
    AddFloor = 'addFloor',
    ShowEmpty = 'showEmpty',
}

export type TLoading = 'Loading...' | 'Download';
