import styled from 'styled-components/macro';

export const Title = styled.div`
    font-weight: 500;
`;

export const Text = styled.div`
    margin-top: var(--space-xxs);
    font-weight: 400;
`;

export const MetricInfoWrapper = styled.div`
    margin-top: 20px;

    :first-child {
        margin-top: 15px;
    }
`;

export const MetricSettingsWrapper = styled.div`
    max-width: 365px;
`;
