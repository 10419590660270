import { IBill, IBillItem, IPointOfSale } from '../interfaces';

export const BILL_ITEM_KEYS_TEXT_MAP: { [key in keyof IBillItem]: string } = {
    position: 'Item number in the receipt',
    sku: 'SKU number',
    name: 'Item name',
    price: 'Item price',
    qty: 'Qty of items sold',
    itemCostWithDiscount: 'Total item cost with discount (Ex. VAT)',
    itemDiscount: 'Item discount',
    total: 'Total item cost (Ex. VAT)',
    vat: 'VAT (Ex.)',
    position_vat: 'VAT',
};

export const POINT_OF_SALE_KEYS_TEXT_MAP: { [key in keyof Omit<IPointOfSale, 'id' | 'connector'>]: string } = {
    name: 'Cash register name',
    date_from: 'date_from',
    date_to: 'date_to',
    fn_factory_number: 'fn_factory_number',
    kkt_factory_number: 'Factory number of KKT',
    kkt_reg_number: 'Registration number of KKT',
    tenant_id: 'tenant_id',
};

export const BILL_KEYS_TEXT_MAP: { [key in keyof IBill]: string } = {
    date: 'Receipt date',
    time: 'Receipt time',
    weekday: 'Week day',
    doc_number: 'Receipt number',
    bill_type: 'Receipt type',
    payment_type: 'Payment type',
    payment_subtype: 'Payment subtype',
    operation_type: 'Transaction type',
    currency: 'currency',
    shift_number: 'shift_number',
    shift_open: 'shift_open',
    shift_close: 'shift_close',
    cashier: 'Name of cashier',
    items: 'items',
    point_of_sale: 'point_of_sale',
    tax_scheme: 'tax_scheme',
    fiscalize: 'Payment attribute',
    fisc_doc_number: 'fisc_doc_number',
    fisc_sign: 'fisc_sign',
    fisc_format_ver: 'fisc_format_ver',
    sum: 'Receipt amount (inc. VAT)',
    receiptAmountDiscount: 'Receipt amount with discount (inc. VAT)',
    totalDiscount: 'Total discount',
    provision_sum: 'provision_sum',
    prepaid_sum: 'prepaid_sum',
    credit_sum: 'credit_sum',
    cash_total_sum: 'cash_total_sum',
    ecash_total_sum: 'ecash_total_sum',
    vat_sum: 'Total receipt VAT',
    pref_sum: 'pref_sum',
    novat_sum: 'novat_sum',
    items_count: 'Num of SKUs in receipt',
};

export enum DetailedBillsAdditionalSettingsIds {
    ShowCategories = 'showCategories',
    ShowPlaceName = 'showPlaceName',
    ShowConnectionType = 'showConnectionType',
    ShowOFDName = 'showOFDName',
    ShowRegistrationNumberOfCCT = 'showRegistrationNumberOdCCT',
    ShowFactoryNumberOfCCT = 'showFactoryNumberOfCCT',
    ShowCashierName = 'showCashierName',
    ShowTransactionType = 'showTransactionType',
    ShowPaymentType = 'showPaymentType',
    ShowPaymentSubtype = 'showPaymentSubtype',
    ShowPaymentAttribute = 'showPaymentAttribute',
}

export const PREVIEW_TABLE_ADDITIONAL_SETTINGS_OPTIONS = [
    { id: DetailedBillsAdditionalSettingsIds.ShowCategories, text: 'Categories' },
    { id: DetailedBillsAdditionalSettingsIds.ShowPlaceName, text: 'Place identifier' },
    { id: DetailedBillsAdditionalSettingsIds.ShowConnectionType, text: 'Connection type' },
    { id: DetailedBillsAdditionalSettingsIds.ShowOFDName, text: 'Full name of the OFD' },
    { id: DetailedBillsAdditionalSettingsIds.ShowRegistrationNumberOfCCT, text: 'Registration number of point of pale (POS) equipment' },
    { id: DetailedBillsAdditionalSettingsIds.ShowFactoryNumberOfCCT, text: 'Factory serial number of the point of sale (POS) equipment' },
    { id: DetailedBillsAdditionalSettingsIds.ShowCashierName, text: 'Name of the cashier' },
    { id: DetailedBillsAdditionalSettingsIds.ShowTransactionType, text: 'Transaction type' },
    // { id: DetailedBillsAdditionalSettingsIds.ShowPaymentType, text: 'Show payment type' },
    // { id: DetailedBillsAdditionalSettingsIds.ShowPaymentSubtype, text: 'Show payment subtype' },
    { id: DetailedBillsAdditionalSettingsIds.ShowPaymentAttribute, text: 'Payment indicator' },
];

export const PREVIEW_TABLE_COLUMNS: {
    instanceKey: keyof (IBill & IBillItem & IPointOfSale);
    instance: 'bill' | 'point_of_sale' | 'bill_item';
    columnHeaderText: string;
    filterId?: DetailedBillsAdditionalSettingsIds;
}[] = [
    { instanceKey: 'date', instance: 'bill', columnHeaderText: BILL_KEYS_TEXT_MAP['date'] },
    { instanceKey: 'time', instance: 'bill', columnHeaderText: BILL_KEYS_TEXT_MAP['time'] },
    { instanceKey: 'weekday', instance: 'bill', columnHeaderText: BILL_KEYS_TEXT_MAP['weekday'] },
    { instanceKey: 'doc_number', instance: 'bill', columnHeaderText: BILL_KEYS_TEXT_MAP['doc_number'] },
    {
        instanceKey: 'kkt_reg_number',
        instance: 'point_of_sale',
        filterId: DetailedBillsAdditionalSettingsIds.ShowRegistrationNumberOfCCT,
        columnHeaderText: POINT_OF_SALE_KEYS_TEXT_MAP['kkt_reg_number'],
    },
    {
        instanceKey: 'kkt_factory_number',
        instance: 'point_of_sale',
        filterId: DetailedBillsAdditionalSettingsIds.ShowFactoryNumberOfCCT,
        columnHeaderText: POINT_OF_SALE_KEYS_TEXT_MAP['kkt_factory_number'],
    },
    {
        instanceKey: 'cashier',
        instance: 'bill',
        filterId: DetailedBillsAdditionalSettingsIds.ShowCashierName,
        columnHeaderText: BILL_KEYS_TEXT_MAP['cashier'],
    },
    {
        instanceKey: 'operation_type',
        instance: 'bill',
        filterId: DetailedBillsAdditionalSettingsIds.ShowTransactionType,
        columnHeaderText: BILL_KEYS_TEXT_MAP['operation_type'],
    },
    {
        instanceKey: 'payment_type',
        instance: 'bill',
        filterId: DetailedBillsAdditionalSettingsIds.ShowPaymentType,
        columnHeaderText: BILL_KEYS_TEXT_MAP['payment_type'],
    },
    {
        instanceKey: 'payment_subtype',
        instance: 'bill',
        filterId: DetailedBillsAdditionalSettingsIds.ShowPaymentSubtype,
        columnHeaderText: BILL_KEYS_TEXT_MAP['payment_subtype'],
    },
    {
        instanceKey: 'fiscalize',
        instance: 'bill',
        filterId: DetailedBillsAdditionalSettingsIds.ShowPaymentAttribute,
        columnHeaderText: BILL_KEYS_TEXT_MAP['fiscalize'],
    },
    {
        instanceKey: 'bill_type',
        instance: 'bill',
        filterId: DetailedBillsAdditionalSettingsIds.ShowPaymentAttribute,
        columnHeaderText: BILL_KEYS_TEXT_MAP['bill_type'],
    },

    { instanceKey: 'items_count', instance: 'bill', columnHeaderText: BILL_KEYS_TEXT_MAP['items_count'] },
    { instanceKey: 'position', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['position'] },
    { instanceKey: 'sku', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['sku'] },
    { instanceKey: 'name', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['name'] },
    { instanceKey: 'price', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['price'] },
    { instanceKey: 'vat', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['vat'] },
    { instanceKey: 'qty', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['qty'] },
    { instanceKey: 'total', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['total'] },
    {
        instanceKey: 'itemCostWithDiscount',
        instance: 'bill_item',
        columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['itemCostWithDiscount'],
    },
    { instanceKey: 'position_vat', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['position_vat'] },
    {
        instanceKey: 'sum',
        instance: 'bill',
        columnHeaderText: BILL_KEYS_TEXT_MAP['sum'],
    },
    {
        instanceKey: 'receiptAmountDiscount',
        instance: 'bill',
        columnHeaderText: BILL_KEYS_TEXT_MAP['receiptAmountDiscount'],
    },
    {
        instanceKey: 'vat_sum',
        instance: 'bill',
        columnHeaderText: BILL_KEYS_TEXT_MAP['vat_sum'],
    },
    { instanceKey: 'itemDiscount', instance: 'bill_item', columnHeaderText: BILL_ITEM_KEYS_TEXT_MAP['itemDiscount'] },
    {
        instanceKey: 'totalDiscount',
        instance: 'bill',
        columnHeaderText: BILL_KEYS_TEXT_MAP['totalDiscount'],
    },
];
