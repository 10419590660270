import { ISelectOption, IUsePrepareDataResponse } from './interfaces';
import { useEffect, useState } from 'react';

import { generalReducerValues } from '../../../../General.reducer';
import { useSelector } from 'react-redux';
import { ZONES_WORD } from '../../../../constants/constants';

/** Кастомный хук для подготовки данных */
const usePrepareData = (): IUsePrepareDataResponse => {
    const [groupsOfZonesSelectOptions, setGroupsOfZonesSelectOptions] = useState<ISelectOption[]>([]);
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState<null | ISelectOption>(null);
    const {
        cfg: { reportingObjectsByType },
        selectedLocationId,
        selectedProjectId,
    } = useSelector(generalReducerValues);

    useEffect(() => {
        setDefaultSelectedGroup(null);
        const groupsOfZones: string[] = Object.keys(reportingObjectsByType).filter((key) => key.includes(ZONES_WORD));

        if (groupsOfZones) {
            const result = groupsOfZones.map((element) => ({
                id: element.replace(`${ZONES_WORD}: `, ''),
                text: element.replace(`${ZONES_WORD}: `, ''),
            }));
            setGroupsOfZonesSelectOptions(result);
            setDefaultSelectedGroup(result[0]);
        }
    }, [reportingObjectsByType, selectedLocationId, selectedProjectId]);

    return { groupsOfZonesSelectOptions, defaultSelectedGroup };
};

export default usePrepareData;
