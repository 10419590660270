import { FC, useRef } from 'react';
import { IProps } from './interfaces';
import Stack from '../../Stack/Stack';
import Table from './components/Table/Table';
import WidgetTitleWrapper from '../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetAdditionalControls from '../../WidgetAdditionalControls/WidgetAdditionalControls';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import WidgetWrapper from '../../Wrappers/WidgetWrapper/WidgetWrapper';
import { queryClient } from '../../..';
import { SUMMARY_QUERY_KEY } from './components/Table/components/DataCell/constants/constants';

/**
 * Виджет Summary
 */
const Summary: FC<IProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const onReload = () => {
        queryClient.invalidateQueries({ queryKey: [SUMMARY_QUERY_KEY, props.moduleId, props.widgetId] });
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Summary')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        reloadHandlerNoDispatch={onReload}
                        widgetName={props.widgetId}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>

            <Stack direction="column" gap={15}>
                {props.reportingObjects.map((item) => {
                    return <Table key={item.id} reportingObject={item} {...props} />;
                })}
            </Stack>
        </WidgetWrapper>
    );
};

export default Summary;
