import { increaserDownloadSignalCounter, Reports_ByTenants_Widget_Reducer_Values } from './reducer';
import { generalReducerValues } from '../../../../../General.reducer';
import { ResponseStatus } from '../../../../../tools/API/constants';
import { withLoading } from '../../../../../tools/API/withLoading';
import { Table, TableWrapper } from './styles';
import useGridGenerator from './hooks/useGridGenerator';
import { usePrepareData } from './hooks/usePrepareData';
import { useDispatch, useSelector } from 'react-redux';
import ReactDataSheet from 'react-datasheet';
import React, { useMemo } from 'react';
import Title from '../../../../../components/Title/Title';
import ButtonsPanel from '../../../components/ButtonsPanel/ButtonsPanel';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { DS } from '../../../../../constants/constants';
import { useGetReportFileName } from '../../../../../hooks/useGetReportFileName';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';

/**
 * Компонент для отображения виджета загрузки отчета по арендаторам
 */
const TenantsReportWidget: React.FC = () => {
    const { rawMetricsData, grid, keyWord } = useSelector(Reports_ByTenants_Widget_Reducer_Values);
    const {
        structures,
        currentModuleID,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const reportFileName = useGetReportFileName(keyWord, 'Report by tenants');

    const { t } = useTranslation();
    const dispatch = useDispatch();
    useGridGenerator();
    usePrepareData();

    const onDownloadClick = () => {
        dispatch(increaserDownloadSignalCounter());
    };

    const isNoTenants = useMemo(() => {
        return !reportingObjectsByType?.['tenant'] || !reportingObjectsByType?.['tenant']?.length;
    }, [reportingObjectsByType]);

    const table = useMemo(() => {
        if (rawMetricsData || isNoTenants) {
            const data = isNoTenants
                ? { status: ResponseStatus.NoData, message: t('There are no tenants here!') }
                : rawMetricsData?.['status']
                ? rawMetricsData
                : !structures
                ? { status: ResponseStatus.Error, message: t('Get structure error') }
                : [];

            const WithLoadingTable = withLoading(TableWrapper, { height: 400, data });
            return (
                <WithLoadingTable>
                    {grid && <ReactDataSheet data={grid} valueRenderer={(cell) => cell.value} />}
                </WithLoadingTable>
            );
        }
        return null;
    }, [isNoTenants, rawMetricsData, t, structures, grid]);

    const reportFormat = useMemo(() => {
        return localCurrentOptions?.[keyWord ? `reportFormat${DS}${keyWord}` : 'reportFormat']?.[0]?.id;
    }, [keyWord, localCurrentOptions]);

    return (
        <WidgetWrapper styles={{ marginTop: 10 }}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title>{t('By tenants')}</Title>
                </WidgetTitle>
            </WidgetTitleWrapper>
            {(reportFormat === 'plain' || isNoTenants) && <Table>{table}</Table>}
            <ButtonsPanel
                moduleName={`${currentModuleID}`}
                onPrepareClick={onDownloadClick}
                reportFileName={reportFileName}
                disableAllButtons={isNoTenants}
                grid={grid || []}
                keyWord={keyWord}
            />
        </WidgetWrapper>
    );
};

export default TenantsReportWidget;
