import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponseStatus } from '../../../../../../../tools/API/constants';
import { getArrowAndColor } from '../../../../../../../tools/getArrowAndColor';
import LoadingBox from '../../../../../../../components/LoadingBox/LoadingBox';
import Header from '../Header/Header';
import { Li, Percent, Rating, Ul } from './styles';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';

interface IProps {
    /** Данные для рейтинга */
    data: any[] | null | string;
    /** Название рейтинга */
    title: string;
    /** Сколько элементов списка показать */
    itemsToShow: number | 'all';
}

/**
 * Компонент для отображения секции для рейтинга (растщие или падающие)
 */
const RatingItem: React.FC<IProps> = ({ data = [], title, itemsToShow }) => {
    const [ascStatus, setAscSort] = useState(true);
    const { t } = useTranslation();

    const shandleHeaderClick = (e: boolean) => {
        setAscSort(e);
    };

    const dataSort = () => {
        if (Array.isArray(data)) {
            let dataSort = _.cloneDeep(data);
            if (ascStatus) {
                return dataSort;
            } else {
                return dataSort.reverse();
            }
        }
        return [];
    };

    const list = useMemo(() => {
        const data = dataSort();
        const listdata = data.slice(0, itemsToShow === 'all' ? data.length : itemsToShow).map((item) => {
            const percent = valueFormatter({ value: item.colorValue, units: '%' });
            const color = getArrowAndColor(Number(item.colorValue)).color;
            const icon = getArrowAndColor(Number(item.colorValue)).icon;
            return (
                <Li key={item?.name}>
                    {t(item?.name || '', { ns: 'metrics' })}
                    <Percent color={color}>
                        {percent}
                        {icon}
                    </Percent>
                </Li>
            );
        });
        return <Ul>{listdata}</Ul>;
    }, [ascStatus, data]);

    return (
        <Rating>
            <Header text={title} handleHeaderClick={shandleHeaderClick} />

            <div className="byArea-ratins-list">
                {data && !Array.isArray(data) ? (
                    <LoadingBox
                        isError={data === ResponseStatus.Error ? true : undefined}
                        noData={data === ResponseStatus.NoData ? true : undefined}
                        hasBorder={false}
                        height={190}
                    />
                ) : (
                    list
                )}
            </div>
        </Rating>
    );
};

export default RatingItem;
