import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useTranslation } from 'react-i18next';
import { Props } from './interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { useSelector } from 'react-redux';
import Tooltip from '../../../Tooltip/Tooltip';
import IconButton from '../../../IconButton/IconButton';
import { DocumentPdf24Regular } from '@fluentui/react-icons';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';

const PADDING = 20;

const PDFDownload: React.FC<Props> = ({ targetRef, customContent, showTooltip = true, fileName = 'PDF report' }) => {
    const sendAnalytics = useSendSimpleAnalytics();

    const { t } = useTranslation();
    const { currentModuleID, sendYaAnalytics } = useSelector(generalReducerValues);

    const handleDownloadPdf = async () => {
        const element = targetRef.current;
        console.log('>>>>', targetRef);

        if (element) {
            const canvas = await html2canvas(element, { scale: 2 });
            const data = canvas.toDataURL('image/png');

            const pdf = new jsPDF();
            const imgProperties = pdf.getImageProperties(data);
            const pdfWidth = pdf.internal.pageSize.width * 2;
            const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

            pdf.internal.pageSize.height = (imgProperties.height * pdfWidth) / imgProperties.width + PADDING;
            pdf.internal.pageSize.width = pdfWidth + PADDING;

            pdf.addImage(data, 'PNG', 10, 10, pdfWidth, pdfHeight);

            pdf.save(t(fileName));

            sendAnalytics(`${fileName} PDF_download`);
        }
    };

    return (
        <Tooltip showTooltip={showTooltip} content={t('Download PDF')}>
            {customContent ? (
                <div data-test-id={`PDF-${fileName}`} onClick={handleDownloadPdf}>
                    {customContent}
                </div>
            ) : (
                <IconButton icon={<DocumentPdf24Regular />} onClick={handleDownloadPdf} testId={`PDF-${fileName}`} />
            )}
        </Tooltip>
    );
};

export default PDFDownload;
