/**
 * Словарь соответствий полей локации между бэком и фронтом.
 */
export const cabinetPreferencesMapping: Map<string, string> = new Map<string, string>([
    ['id', 'id'],
    ['userId', 'user_id'],
    ['useBusinessDays', 'business_days'],
    ['saveSettingsMode', 'settings_save_mode'],
    ['commonPeriods', 'use_common_periods'],
]);

export const bgReportsStatusMapping = {
    in_queue: 'In queue',
    in_progress: 'In progress',
    success: 'Success',
    failure: 'Failure',
};
