import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReducerState } from './interfaces';
import { RootState } from '../../../store';

const initialState: IReducerState = {
    moduleId: 'Dashboard designer:Object panels',
};

const Dashboard_General_Module_Reducer = createSlice({
    name: 'Dashboard_General_Module_Reducer',
    initialState,
    reducers: {},
});

export const {} = Dashboard_General_Module_Reducer.actions;

export const Dashboard_General_Module_Reducer_Values = (state: RootState) => state.Dashboard_General_Module_Reducer;

export default Dashboard_General_Module_Reducer.reducer;
