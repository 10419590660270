import { OptionsStackingValue, PlotOptions, PlotPieOptions } from 'highcharts';

interface IArgs {
    toggleShowSharedTooltips?: (args: { containerId: string | null; index: number | null; show: boolean }) => void;
    columnsStacking?: OptionsStackingValue;
    inactiveSeriesOpacity: number;
    showSeriesAnimation: boolean;
    columnsGrouping?: boolean;
    columnDataLabelsEnabled: boolean;
    plotPieOptions?: PlotPieOptions;
}

/**
 * Функция для генерации настроек каждого типа серий
 * @param toggleShowSharedTooltips Функция для отображения сразу нескольких тултипов на нескольких графиках (как в виджете Visitors Inside)
 * @param inactiveSeriesOpacity Прозрачность неактивной серии
 * @param showSeriesAnimation Флаг отвечающий за анимацию серий
 * @param columnsStacking Флаг, отвечающий за наложений колонок друг на друга (для типа серий columns)
 * @param columnsGrouping Флаг, отвечающий за наложений колонок друг на друга (для типа серий columns)
 * @param columnDataLabelsEnabled Флаг, отвечающий за отображение лейблов на колонках
 * @returns НАстрокйки для всех типов серий
 */
const generatePlotOptions = (args: IArgs): PlotOptions => {
    const {
        showSeriesAnimation,
        inactiveSeriesOpacity,
        toggleShowSharedTooltips,
        columnsGrouping,
        columnsStacking,
        columnDataLabelsEnabled,
        plotPieOptions,
    } = args;
    return {
        areaspline: {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1,
                },
                stops: [
                    [0, 'rgba(171, 198, 255, 0.7)'],
                    [0.5, 'rgba(204, 220, 255, 0.5)'],
                    [0.75, 'rgba(234, 241, 255, 0.3)'],
                    [1, 'rgba(253, 254, 255, 0.1)'],
                ],
            },
            marker: {
                symbol: 'circle',
            },
        },
        spline: {
            marker: {
                symbol: 'circle',
            },
        },
        column: {
            grouping: columnsGrouping,
            stacking: columnsStacking,
            dataLabels: {
                enabled: columnDataLabelsEnabled,
            },
        },
        line: {
            marker: {
                symbol: 'circle',
            },
        },
        pie: {
            // allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
            ...plotPieOptions,
        },
        series: {
            animation: showSeriesAnimation,
            softThreshold: true,
            marker: {
                lineColor: '#ffffff',
                lineWidth: 3,
                radius: 5,
            },
            states: {
                inactive: {
                    opacity: inactiveSeriesOpacity,
                },
            },

            point: {
                events: {
                    mouseOver: function () {
                        toggleShowSharedTooltips &&
                            toggleShowSharedTooltips({
                                show: true,
                                index: this.index,
                                containerId: this.series.chart.container.id,
                            });
                    },
                    mouseOut: function () {
                        toggleShowSharedTooltips &&
                            toggleShowSharedTooltips({ show: false, index: null, containerId: null });
                    },
                },
            },
        },
    };
};

export default generatePlotOptions;
