import { useSelector } from 'react-redux';
import { Performance_Dashboard_ByArea_Widget_Reducer_Values } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import { TMetricResponse } from '../../../../../../General.interfaces';
import { ISelectedObjectDAResponse } from '../interfaces';
import { useMemo } from 'react';
import { generalReducerValues } from '../../../../../../General.reducer';
import selectedObjectDataAdapter from './dataAdapters/selectedObjectDataAdapter';
import { ANALYSIS_BY_AREA_SELECTED_OBJECT_QUERY_KEY } from '../constants/constants';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { IRequestMetricsArgs } from '../../../../../../tools/API/interfaces';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import usePeriods from '../../../../../../hooks/common/usePeriods';
import { DS } from '../../../../../../constants/constants';

const useFetchSelectedObjectData = () => {
    const { selectedObjectId, comparePeriod } = useSelector(Performance_Dashboard_ByArea_Widget_Reducer_Values);
    const { token, allMetrics, currentModuleID } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { mainPeriod, comparePeriods } = usePeriods(currentModuleID);

    const fetchData = useRequestMetrics();

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        const selectedMetrics = localCurrentOptions?.selectedMetrics;
        const comparePeriodDateRange = comparePeriods?.find((item) => item.id === comparePeriod.id)?.period;
        if (token && selectedMetrics && selectedObjectId && mainPeriod && comparePeriodDateRange) {
            const requestArgs: IRequestMetricsArgs[] = [];

            selectedMetrics.forEach((metric) => {
                const requestTemplate = {
                    time_range: [mainPeriod.period.dateFrom, mainPeriod.period.dateTo],
                    obj_ids: [selectedObjectId],
                    object_aggregation: false,
                    time_freq: null,
                    metric: metric,
                    token,
                };

                const mainPeriodRequest: IRequestMetricsArgs = {
                    ...requestTemplate,
                    alias: `main${DS}${metric}`,
                };
                const comparePeriodRequest: IRequestMetricsArgs = {
                    ...requestTemplate,
                    alias: `compare${DS}${metric}`,
                    time_range: [comparePeriodDateRange.dateFrom, comparePeriodDateRange.dateTo],
                };

                requestArgs.push(mainPeriodRequest, comparePeriodRequest);
            });

            const dataAccessorArgs: IMetricsDAArgs = {
                requestArgs,
                fetchData,
            };

            return {
                queryKey: [ANALYSIS_BY_AREA_SELECTED_OBJECT_QUERY_KEY, selectedObjectId],
                queryFn: () => metricsDataAccessor(dataAccessorArgs),
                dataAdapter: (response: TMetricResponse[]) =>
                    selectedObjectDataAdapter({ response, allMetrics, metrics: localCurrentOptions.selectedMetrics! }),
            };
        }
        return {};
    }, [
        allMetrics,
        comparePeriod,
        JSON.stringify(localCurrentOptions?.selectedMetrics),
        comparePeriods,
        mainPeriod,
        selectedObjectId,
        token,
    ]);

    return useQuery<TMetricResponse[], unknown, ISelectedObjectDAResponse, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter) && Boolean(localCurrentOptions),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchSelectedObjectData;
