import { makeStyles } from '@fluentui/react-components';
import styled from 'styled-components/macro';

export const styles = { root: { display: 'flex' } };

export const DivWrapperChildrenSC = styled.div`
    display: grid;
    min-width: max-content;
    min-height: 1px;
    white-space: pre-line;
    /* height: 100%; */
`;

export const DivWrapperContentSC = styled.div<{
    width: string;
}>`
    display: grid;
    max-width: ${({ width }) => width};
    height: max-content;
    /* gap: 5px; */
    font-size: 13px;
    width: max-content;
    white-space: pre-line;
`;

export const useStyles = makeStyles({
    tooltip: {
        // display: 'grid',
        width: 'max-content',
        transform: 'none',
        maxWidth: 'none',
        paddingBottom: '4px',
        // maxWidth: `${0.5 * mainAreaSize.width}px`
    },
});
