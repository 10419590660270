import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

import { TLoadingData, TMetricResponse } from '../../../../../General.interfaces';
import { ResponseStatus } from '../../../../../tools/API/constants';

const initialState: IReducerState = {
    widgetName: 'Reach to the tenant. Comparison with category',
    preparedData: { message: 'No data', status: ResponseStatus.NoData },
    responseResult: null,
    selectedCategory: null,
    tenantId2zoneId: null,
    dataRefetchObject: {},
};

export const Leasing_TenantOverview_Reach_Widget_Reducer = createSlice({
    name: 'Leasing_TenantOverview_Reach_Widget_Reducer',
    initialState,

    reducers: {
        storePreraredData: (state, action: PayloadAction<TLoadingData>) => {
            state.preparedData = action.payload;
        },

        storeSelectedCategory: (state, action: PayloadAction<string>) => {
            state.selectedCategory = action.payload;
        },

        storeResponseResult: (state, action: PayloadAction<TMetricResponse[] | null>) => {
            state.responseResult = action.payload;
        },

        storeTenantId2zoneId: (
            state,
            action: PayloadAction<{ tenantId: number; zoneId: number | undefined }[] | null>,
        ) => {
            state.tenantId2zoneId = action.payload;
        },

        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const { storePreraredData, storeResponseResult, storeTenantId2zoneId, storeSelectedCategory, reloadWidget } =
    Leasing_TenantOverview_Reach_Widget_Reducer.actions;

export const Leasing_TenantOverview_Reach_Widget_Reducer_Values = (state: RootState) =>
    state.Leasing_TenantOverview_Reach_Widget_Reducer;

export default Leasing_TenantOverview_Reach_Widget_Reducer.reducer;
