import styled from 'styled-components/macro';
import ObjectsSelect from '../../../../../../../../../components/Selects/ObjectsSelect/ObjectsSelect';

export const ObjectsSelectWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;

    > div {
        height: 100%;
        width: 100%;
        > div {
            height: 100%;
            width: 100%;
            > div {
                max-width: 100%;
                min-width: 100px;
            }
        }
    }

    button {
        border: none;
        border-radius: 0px;
        height: 100%;
        padding: 0px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        background: inherit;
        white-space: normal;
        > div {
            padding: 0px;
            justify-content: flex-end;
            > span {
                white-space: normal;
                overflow: auto;
            }
        }
    }
`;
