import { isNull } from 'lodash';
import { ResponseStatus } from '../../../../tools/API/constants';

/**
 * Проверка типа для статуса
 */
export const isStatus = (status: unknown): status is { status: ResponseStatus; message: string } => {
    if (typeof status === 'object' && !isNull(status) && 'status' in status && 'message' in status) {
        return true;
    }

    return false;
};
