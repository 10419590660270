import { Ref, forwardRef } from 'react';
import { IProps } from './interfaces';
import { Wrapper } from './styles';

/**
 * Кнопка-иконка
 */
const IconButton = forwardRef<Ref<HTMLDivElement>, IProps>(
    (
        { icon, testId, disabled = false, onClick, hoverColor = 'var(--color-primary-darker)', size = 20, styles },
        ref,
    ) => {
        return (
            <Wrapper
                size={size}
                disabled={disabled}
                hoverColor={hoverColor}
                onClick={onClick}
                data-test-id={testId}
                ref={ref as Ref<HTMLDivElement>}
                style={{ ...styles }}
            >
                {icon}
            </Wrapper>
        );
    },
);

export default IconButton;
