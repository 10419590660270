import { isNumber, orderBy } from 'lodash';
import {
    IMetricResponseItem,
    IPeriodObj,
    IReportingObject,
    TMetricResponse,
} from '../../../../../../../../../General.interfaces';
import { TOrdering } from '../../../../../../../../OrderSwitcher/interfaces';
import { queryClient } from '../../../../../../../../..';
import { DS } from '../../../../../../../../../constants/constants';
import getDifferenceBetweenNumbers from '../../../../../../../../../tools/getDifferenceBetweenNumbers';
import { TABLE_REFERENCE_BY_LOCATION_QUERY_KEY } from '../../../constants/constants';

interface IArgs {
    orderingData: { ordering: TOrdering; columnId: string };
    reportingObjects: IReportingObject[];
    selectedReportingObjectId: number | null;
    moduleId: string;
    widgetId: string;
}

const sortHandler = (args: IArgs): number[] => {
    const orderingMap = {
        down: 'desc',
        up: 'asc',
    };

    const nullValuesMap = {
        down: -Infinity,
        up: Infinity,
    };

    const splittedColumnId = args.orderingData.columnId.split(DS);
    const metric = splittedColumnId[0] as string | undefined;
    const type = splittedColumnId[1] as string | undefined;

    const findPeriodDataInMetricResponse = (args: {
        reportingObjectId: number | null;
        response: TMetricResponse[][];
    }): null | IMetricResponseItem => {
        let result: null | IMetricResponseItem = null;
        for (const item of args.response) {
            if (result) break;

            for (const metricResponse of item) {
                if (result) break;

                for (const metricResponseItem of metricResponse) {
                    const { data_objects } = metricResponseItem.context;

                    if (data_objects[0].id === args.reportingObjectId) {
                        result = metricResponseItem;
                        break;
                    }
                }
            }
        }

        return result;
    };

    /** Сортировка столбца по имени отчетного объекта*/
    if (args.orderingData.columnId === 'name') {
        const result = orderBy(
            args.reportingObjects.map((item) => item.id).filter((item) => item !== args.selectedReportingObjectId),
            (id) => {
                return args.reportingObjects.find((item) => item.id === id)?.name;
            },
            orderingMap[args.orderingData.ordering],
        );

        if (args.selectedReportingObjectId) {
            result.unshift(args.selectedReportingObjectId);
        }

        return result;
    }

    const activeQueryData = queryClient
        .getQueriesData({
            queryKey: [TABLE_REFERENCE_BY_LOCATION_QUERY_KEY, args.moduleId, args.widgetId, metric],
            type: 'active',
        })
        .filter((item) => Boolean(item[1]))
        .map((item) => item[1]) as TMetricResponse[][];

    const result = orderBy(
        args.reportingObjects.map((item) => item.id).filter((item) => item !== args.selectedReportingObjectId),
        (id) => {
            const currentResponseItem = findPeriodDataInMetricResponse({
                reportingObjectId: id,
                response: activeQueryData,
            });

            switch (type) {
                case 'main':
                    const result = currentResponseItem?.items?.[0]?.value || null;
                    return isNumber(result) ? result : nullValuesMap[args.orderingData.ordering] || 'asc';

                case 'compare':
                    const selectedResponseItem = findPeriodDataInMetricResponse({
                        reportingObjectId: args.selectedReportingObjectId,
                        response: activeQueryData,
                    });
                    if (
                        isNumber(currentResponseItem?.items?.[0]?.value) &&
                        isNumber(selectedResponseItem?.items?.[0]?.value) &&
                        selectedResponseItem &&
                        selectedResponseItem?.items?.[0]?.value > 0
                    ) {
                        const result = Number(
                            getDifferenceBetweenNumbers(
                                selectedResponseItem?.items?.[0]?.value,
                                currentResponseItem?.items?.[0]?.value,
                            ).percentDifference,
                        );
                        return isNumber(result) ? result : nullValuesMap[args.orderingData.ordering] || 'asc';
                    }
                    return nullValuesMap[args.orderingData.ordering] || 'asc';

                default:
                    return null;
            }
        },
        [orderingMap[args.orderingData.ordering] || 'asc', 'desc'],
    );

    if (args.selectedReportingObjectId) {
        result.unshift(args.selectedReportingObjectId);
    }
    return result;
};

export default sortHandler;
