import {
    storeReportigObjectsRawMetricsDataById,
    updateExtendedReportingObjectsWithChartOptionsGenratorSettings,
    Performance_VisitorsInside_Widget_Reducer_Values,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import aggregateMetricsResponse from '../tools/aggregateMetricsResponse';
import { generalReducerValues } from '../../../../../../General.reducer';
import generateSeriesData from '../tools/generateSeriesData';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { VISITORS_INSIDE_CHART_DETAILING } from '../constants/constants';

/**
 * Кастомный хук для подготовки данных, необходимых для графиков
 */
const usePrepareData = () => {
    const {
        reportingObjectsRawMetricsDataById,
        extendedReportingObjectsById,
        shoudUpdateChartsByPeriod,
        rawMetricsData,
        moduleName,
    } = useSelector(Performance_VisitorsInside_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();
    const { lang, timeZone } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    /** Получение и запись в стейт настроек графиков  для каждого из отчетных объектов */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.[VISITORS_INSIDE_CHART_DETAILING]?.length) {
            const chartOptions = generateSeriesData({
                detailing: localCurrentOptions[VISITORS_INSIDE_CHART_DETAILING][0].id,
                mainDateRanges: localCurrentOptions.mainDateRanges,
                mainPeriod: localCurrentOptions.mainPeriod,
                reportingObjectsRawMetricsDataById,
                extendedReportingObjectsById,
                shoudUpdateChartsByPeriod,
                timezone: timeZone,
                lang,
                t,
            });
            dispatch(updateExtendedReportingObjectsWithChartOptionsGenratorSettings(chartOptions));
        }
    }, [
        localCurrentOptions?.[VISITORS_INSIDE_CHART_DETAILING],
        reportingObjectsRawMetricsDataById,
        localCurrentOptions?.mainPeriod,
        timeZone,
        lang,
        t,
    ]);

    /** Запись сырых данных для каждого из отчетных объектов */
    useEffect(() => {
        if (timeZone) {
            const aggregatedData = aggregateMetricsResponse(
                rawMetricsData,
                reportingObjectsRawMetricsDataById,
                shoudUpdateChartsByPeriod,
                timeZone,
            );
            dispatch(storeReportigObjectsRawMetricsDataById(aggregatedData));
        }
    }, [rawMetricsData, timeZone]);
};

export default usePrepareData;
