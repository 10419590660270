import { FC } from 'react';
import { IProps } from './interfaces';
import Stack from '../../../../../Stack/Stack';
import MetricsSelect from '../../../../../Selects/MetricsSelect/MetricsSelect';
import { IChangeOptionsArgs } from '../../../../../UserSettings/interfaces';
import { Divider } from '@fluentui/react-components';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import SmartSwitch from '../../../../../SmartSwitch/SmartSwitch';
import UniversalSelect from '../../../../../Selects/UniversalSelect/UniversalSelect';
import { TSelectedOptions } from '../../../../../Selects/Select/interfaces';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useSelector } from 'react-redux';

const Selects: FC<IProps> = ({ widgetConfig, updateWidget }) => {
    const SUMMARY_CHART_OPTIONS: TSelectedOptions = [
        { id: 'total', text: 'Total values' },
        { id: 'avg', text: 'Average values' },
    ];

    const { t } = useTranslation();
    const { currentModuleID } = useSelector(generalReducerValues);

    const onSmartSwitchChange = (args: { name: string; checked: boolean }) => {
        updateWidget('visual', { [args.name]: args.checked });
    };

    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        updateWidget('options', args.newOptions);
    };

    const onSelectChange = (key: string) => (args: IChangeOptionsArgs) => {
        updateWidget(key, args.newOptions);
    };

    return (
        <>
            <Stack styles={{ padding: '0px 12px' }} direction="column" gap={1}>
                <MetricsSelect
                    name={'metrics'}
                    maxSelected={1}
                    localCurrentOptions={widgetConfig?.options}
                    changeOptions={updateWidgetOptions}
                />
                <UniversalSelect
                    options={SUMMARY_CHART_OPTIONS}
                    localCurrentOptions={{ ...widgetConfig?.options, currentModuleID }}
                    name={`additionallyShow`}
                    defaultSelected={[SUMMARY_CHART_OPTIONS[0]]}
                    changeOptions={onSelectChange('options')}
                    labelText={t('Additionally show')}
                    dropdownWidth="auto"
                    mode="single"
                />
            </Stack>
            <Divider style={{ margin: '5px 0px' }} />
            <Stack styles={{ padding: '0px 12px' }} direction="column" gap={1}>
                <SmartSwitch
                    label={t('Show labels')}
                    storePlace={widgetConfig?.visual}
                    onChange={onSmartSwitchChange}
                    name="showLabels"
                    defaultValue={true}
                />
            </Stack>
        </>
    );
};

export default Selects;
