import { AxiosError, AxiosResponse } from 'axios';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useMemo } from 'react';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../../../../tools/API/appBackendAPI';
import {
    getIdRatingLoad,
    Performance_Ratings_RatingList_Widget_Reducer_Values,
    storeNewRating,
    storeRatings,
    storeRatingsDataRecived,
    storeRemoveRating,
} from '../reducer';
import _ from 'lodash';
import { IRating } from '../interfaces';
import { MAIN_RATING_INDICATORS } from '../constants/constants';

const front2BackPrepare = (arr: Array<{ id: string; text: string; checked: boolean }>) => {
    return arr?.filter((item) => item.checked).map((item) => item.id);
};

const back2FrontPrepare = (rating: any) => {
    const additional_columns = MAIN_RATING_INDICATORS.map((item) => {
        return { ...item, checked: rating.additional_columns.includes(item.id) };
    });
    return { ...rating, additional_columns };
};

export const useRequestRatings = () => {
    const dispatch = useDispatch();
    const { token, selectedLocationId, user, initialDataReceived, urlsByServices } = useSelector(generalReducerValues);
    const { newRating, removeRating, ratingsDataRecived, ratings } = useSelector(
        Performance_Ratings_RatingList_Widget_Reducer_Values,
    );

    const ratingsUrl = useMemo(() => {
        if (urlsByServices?.['app/app-backend']) return urlsByServices['app/app-backend'].RATINGS_URL;
        return null;
    }, [urlsByServices]);

    /** Добавление новых и редактирование */
    useEffect(() => {
        if (newRating !== null && ratingsUrl) {
            const rating: any = _.cloneDeep(newRating);
            const userId = user?.id;
            rating['user_id'] = userId;
            rating['pl_id'] = selectedLocationId;
            rating['additional_columns'] = front2BackPrepare(rating?.additional_columns);

            if (ratings?.find((x: IRating) => x.id === rating.id)) {
                const oldRatingsDataRecived = _.cloneDeep(ratingsDataRecived);
                const newCurrentStorage: IRating[] = ratings?.map((item: IRating) => {
                    if (item.id === rating.id) {
                        return rating;
                    }
                    return item;
                });
                dispatch(
                    storeRatingsDataRecived(
                        _.remove(oldRatingsDataRecived, function (n) {
                            return n !== rating.id;
                        }),
                    ),
                );

                dispatch(getIdRatingLoad({ id: rating.id, status: true }))
                patchRequest({ url: `${ratingsUrl}${rating.id}/`, token, data: rating }).then(() => {
                    const url = `${ratingsUrl}?pl_id=${selectedLocationId}`;
                    getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                        dispatch(getIdRatingLoad({ id: rating.id, status: false }))
                        dispatch(storeRatings(prepareRating(response['data'])));
                    });
                });
                // dispatch(storeRatings(prepareRating(newCurrentStorage)));
            } else {
                postRequest(ratingsUrl, token, rating).then(() => {
                    const url = `${ratingsUrl}?pl_id=${selectedLocationId}`;
                    getRequest(url, token).then((response: AxiosError | AxiosResponse) => {

                        dispatch(storeRatings(prepareRating(response['data'])));
                    });
                });
            }
            dispatch(storeNewRating(null));
        }
    }, [dispatch, newRating, ratings, ratingsDataRecived, selectedLocationId, token, user?.id, ratingsUrl]);

    /** Удаление */
    useEffect(() => {
        if (removeRating && ratingsUrl && selectedLocationId) {
            deleteRequest(ratingsUrl, token, removeRating).then(() => {
                const url = `${ratingsUrl}?pl_id=${selectedLocationId}`;
                getRequest(url, token).then((response: AxiosError | AxiosResponse) => {

                    dispatch(storeRatings(prepareRating(response['data'])));
                });
            });
            dispatch(storeRemoveRating(null));
        }
    }, [dispatch, removeRating, token, ratingsUrl, selectedLocationId]);

    /** При загрузке приложения, запрос рейтингов */
    useEffect(() => {
        if (token && selectedLocationId && initialDataReceived && ratingsUrl) {
            const url = `${ratingsUrl}?pl_id=${selectedLocationId}`;

            dispatch(storeRatings([]));

            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                if (response?.['data']?.length) {

                    dispatch(storeRatings(prepareRating(response['data'])));
                }
            });
        }
    }, [selectedLocationId, token, initialDataReceived, ratingsUrl, dispatch]);

    /**
     *
     * @param ratingList
     * @returns - массив рейтингов
     */
    const prepareRating = (ratingList: IRating[]) => {
        const storageResult = ratingList.map((item) => back2FrontPrepare(item));
        if (storageResult.length === 0) {
            return null;
        } else {
            return storageResult;
        }
    };
};
