import { useAppDispatch } from '../../../../../hooks/useSettings';
import ReactDataSheet from 'react-datasheet';
import { IMetricCell, ISummaryCell } from '../../interfaces';
import CellRenderer from './components/CellRenderer/CellRenderer';
import { updateTableData } from '../../reducer';
import { TableWrapper } from './styles';
import { useDeferredValue } from 'react';

interface IProps {
    data: IMetricCell[][] | ISummaryCell[][];
    tableKey?: string;
}

const Table: React.FC<IProps> = ({ data, tableKey }) => {
    const dispatch = useAppDispatch();

    const cellRenderer = (props: ReactDataSheet.CellRendererProps<IMetricCell | ISummaryCell>) => {
        return <CellRenderer {...props} />;
    };

    const onCellsChanged = (changes: ReactDataSheet.CellsChangedArgs<IMetricCell>) => {
        tableKey && dispatch(updateTableData({ data: changes, tableKey }));
    };

    const defData = useDeferredValue(data);


    return (
        <TableWrapper>
            <ReactDataSheet
                valueRenderer={(cell: IMetricCell | ISummaryCell) => cell.value}
                onCellsChanged={onCellsChanged}
                cellRenderer={cellRenderer}
                data={defData}
            />
        </TableWrapper>
    );
};

export default Table;
