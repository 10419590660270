import React, { useMemo } from 'react';
import { generalReducerValues } from '../../../../../General.reducer';
import { useSelector } from 'react-redux';
import Summary from '../../../../../components/CommonWidgets/Summary/Summary';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { IPeriodObj, IReportingObject } from '../../../../../General.interfaces';
import { DS } from '../../../../../constants/constants';

/**
 * Компонент для отображения виджета Summary
 */
const SummaryTable: React.FC = () => {
    const {
        currentModuleID,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const [reportingObjects, metrics, mainPeriod, comparePeriods] = useMemo(() => {
        const reportingObjects: IReportingObject[] = [];
        const comparePeriods: IPeriodObj[] = [];
        let mainPeriod: undefined | IPeriodObj;

        localCurrentOptions?.selectedReportingObjectsIds?.forEach((id) => {
            if (reportingObjectsById[id]) {
                reportingObjects.push(reportingObjectsById[id]);
            }
        });

        localCurrentOptions?.mainDateRanges?.forEach((mainDateRange) => {
            if (mainDateRange.id === localCurrentOptions?.mainPeriod?.id && mainDateRange.period) {
                mainPeriod = { ...mainDateRange.period };
            }
        });

        const comparePeriodIds = localCurrentOptions?.comparePeriods?.map((item) => item.id) || [];

        localCurrentOptions?.compareDateRanges?.forEach((compareDateRange) => {
            if (comparePeriodIds.includes(compareDateRange.id) && compareDateRange.period) {
                comparePeriods.push(compareDateRange.period);
            }
        });

        const metrics = localCurrentOptions?.selectedMetrics || [];

        return [reportingObjects, metrics, mainPeriod, comparePeriods];
    }, [localCurrentOptions, reportingObjectsById]);

    if (mainPeriod) {
        return (
            <Summary
                widgetId={`${currentModuleID}${DS}Summary`}
                reportingObjects={reportingObjects || []}
                comparePeriods={comparePeriods || []}
                moduleId={currentModuleID}
                metrics={metrics || []}
                mainPeriod={mainPeriod}
                isNetwork={false}
            />
        );
    }

    return null;
};

export default SummaryTable;
