import React, { useEffect, useMemo } from 'react';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { MainPeriodText, SelectsWrapper } from './styles';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import {
    storeSelectedZoneId,
    Performance_Dashboard_Traffic_Widget_Reducer_Values,
    storeSelectedFloor,
} from '../../reducer';
import { ZONES_WORD } from '../../../../../../../constants/constants';
import Select from '../../../../../../../components/Selects/Select/Select';
import { TSelectedOptions } from '../../../../../../../components/Selects/Select/interfaces';
import { Performance_Dashboard_Module_Reducer_Values } from '../../../../reducer';

/**
 * Компонент для отображения селектов (групп зон, зон и этажей)
 */
const Selects: React.FC = () => {
    const { ratingType, selectedZoneId, allFloors, selectedFloor } = useSelector(
        Performance_Dashboard_Traffic_Widget_Reducer_Values,
    );
    const {
        lang,
        cfg: { reportingObjectsByType },
        currentModuleID,
    } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Performance_Dashboard_Module_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (ratingType.includes(ZONES_WORD)) {
            const options = reportingObjectsByType
                ? reportingObjectsByType[ratingType]?.map((zone) => zone.id) || []
                : [];
            options.length && dispatch(storeSelectedZoneId(String(options[0])));
        } else {
            storeSelectedZoneId(null);
        }
    }, [ratingType, reportingObjectsByType, dispatch]);

    const handFloorSelect = (args: TSelectedOptions) => {
        const selectedFloor = Number(args[0].text);
        dispatch(storeSelectedFloor(selectedFloor as number));
    };
    const mainDates = localCurrentOptions?.mainDateRanges?.filter(
        (item) => item.id === localCurrentOptions?.mainPeriod?.id,
    )[0]?.period;

    const handZoneSelect = (args: TSelectedOptions) => {
        const selectedZoneId = args[0].id;
        dispatch(storeSelectedZoneId(String(selectedZoneId)));
    };

    const mainPeriodText = mainDates?.dateFrom ? shortStringDate([mainDates?.dateFrom, mainDates?.dateTo], lang) : '';

    const zoneSelect = useMemo(() => {
        if (!ratingType.includes(ZONES_WORD)) return null;

        const options = reportingObjectsByType
            ? reportingObjectsByType[ratingType]?.map((zone) => ({ id: zone.id, text: zone.name }))
            : [];

        const outsideSelected = options?.filter((item) => String(item.id) === String(selectedZoneId));

        return (
            <Select
                testId={`${currentModuleID}_Select zone`}
                outsideSelected={outsideSelected}
                handleSelect={handZoneSelect}
                labelText={t('Select zone')}
                iconType="chevron-down"
                showFilter={false}
                options={options}
                mode="single"
                text={'null'}
            />
        );
    }, [ratingType, reportingObjectsByType, t, dispatch, selectedZoneId]);

    const floorSelect = useMemo(() => {
        if (ratingType !== 'floor') return null;
        const options = allFloors ? allFloors.map((floor) => ({ id: floor.id, text: floor.marker })) : [];
        const outsideSelected = options?.filter((item) => String(item.text) === String(selectedFloor));

        return (
            <Select
                testId={`${currentModuleID}_Select floor`}
                disabled={ratingType !== 'floor'}
                outsideSelected={outsideSelected}
                handleSelect={handFloorSelect}
                labelText={t('Select floor')}
                iconType="chevron-down"
                dropdownWidth={'sm'}
                showFilter={false}
                options={options}
                mode="single"
                text={'null'}
            />
        );
    }, [allFloors, selectedFloor, ratingType, reportingObjectsByType]);

    return (
        <SelectsWrapper>
            {/* <MainPeriodText>{mainPeriodText}</MainPeriodText> */}
            {zoneSelect}
            {floorSelect}
        </SelectsWrapper>
    );
};

export default Selects;
