import { TFunction } from 'i18next';
import { cloneDeep, isUndefined } from 'lodash';
import { AxisTypeValue, ColorType } from 'highcharts';

import { theme } from 'src/theme';

import { IChartSettings } from '../../../../ChartSettings/interfaces';
import { TSeries } from '../../interfaces';

import alignSeriesByWeekday from './alignSeriesByWeekday';
import generateAverageSeries from './generateAverageSeries';
import generateTrendSeries from './generateTrendSeries';

interface IArgs {
    numberOfSeriesToDispaly: null | number;
    chartSettings?: IChartSettings;
    xAxisType: AxisTypeValue;
    maxColors: number;
    series: TSeries[];
    t: TFunction;
}

/**
 * Функция для генерации ноывх серий, у которых будут изменены значения x
 * (все значения по оси x будут приведены к значениям оси x главной серии (isMain))
 * @returns Серии с измененным значениями оси x
 */
const generateSeries = (args: IArgs): TSeries[] => {
    const { numberOfSeriesToDispaly, series, maxColors, chartSettings, xAxisType, t } = args;

    const ONE_DAY_IN_MILLIS = 24 * 3600 * 1000;
    let TICK_INTERVAL = ONE_DAY_IN_MILLIS;

    const mainSeries = series?.find((element) => element.isMain);
    if (mainSeries) {
        if (mainSeries.data.length > 1) {
            TICK_INTERVAL = mainSeries.data[1].x - mainSeries.data[0].x;
        }
        // Получение значений x из главной серии
        const mainSeriesXValues: number[] = [];
        mainSeries.data.forEach((element) => {
            mainSeriesXValues.push(element.x as number);
        });

        // Генерация новых серий
        let newSeries: TSeries[] = cloneDeep(series)
            .sort((a, _) => (a.isMain ? -1 : 1))
            .map((element: TSeries, seriesIndex) => {
                const colorIndex: number = seriesIndex > maxColors - 1 ? seriesIndex % 10 : seriesIndex;

                const data = element.data.map((seriesDataPoint, index) => {
                    /**
                     * Так как у неглавных серий может быть больше значений, чем в основной,
                     * то мы искуственно добавлеям новые значения в массив из значений
                     * оси X основной серии, путем добавления TICK_INTERVAL к послднему значению в массиве
                     */
                    if (index + 1 > mainSeriesXValues.length) {
                        const [lastXValue] = mainSeriesXValues.slice(-1);
                        mainSeriesXValues.push(lastXValue + TICK_INTERVAL);
                    }

                    return {
                        ...seriesDataPoint,
                        x: xAxisType === 'category' ? seriesDataPoint.x : mainSeriesXValues[index],
                        initialX: seriesDataPoint.x,
                    };
                });

                return {
                    ...element,
                    color: element.color || theme.seriesColors[colorIndex],
                    data,
                } as TSeries;
            });

        /**
         * Если выбран флаг подсветки выходных, то происходит выравнивание по дням недели
         */
        if (chartSettings?.highlightWeekends) {
            newSeries = alignSeriesByWeekday(newSeries, mainSeriesXValues);
        }

        if (chartSettings) {
            /**
             * Генерация серий тренда
             */
            chartSettings.selectedSeriesIdsForTrend.forEach((id) => {
                const seriesForTrend = newSeries?.find((series) => series.id === id) as TSeries;
                const seriesForTrendIndex = newSeries?.findIndex((series) => series.id === id);

                if (seriesForTrend) {
                    const trendSeries = generateTrendSeries({
                        seriesData: seriesForTrend.data,
                        seriesName: seriesForTrend.name,
                        seriesColor: seriesForTrend.color as ColorType,
                        yAxis: !isUndefined(seriesForTrend.yAxis) ? seriesForTrendIndex || 0 : undefined,
                        t,
                    });

                    if (trendSeries) newSeries.push(trendSeries);
                }
            });
            /**
             * Генерация серий среднего значения
             */
            chartSettings.selectedSeriesIdsForAverage.forEach((id) => {
                const seriesForAverage = newSeries?.find((series) => series.id === id) as TSeries;
                const seriesForAverageIndex = newSeries?.findIndex((series) => series.id === id);

                if (seriesForAverage) {
                    const averageSeries = generateAverageSeries({
                        seriesData: seriesForAverage.data,
                        seriesName: seriesForAverage.name,
                        seriesColor: seriesForAverage.color,
                        yAxis: !isUndefined(seriesForAverage.yAxis) ? seriesForAverageIndex || 0 : undefined,
                        t,
                    });

                    if (averageSeries) newSeries.push(averageSeries);
                }
            });
        }

        return newSeries.map(
            (element, index) =>
                ({
                    ...element,
                    name: t(element.name),
                    yAxis: isUndefined(element.yAxis)
                        ? undefined
                        : isUndefined(element['yAxisOverride'])
                        ? index
                        : element['yAxisOverride'],
                    visible: numberOfSeriesToDispaly ? !(index > numberOfSeriesToDispaly) : true,
                    legendIndex: index,
                } as TSeries),
        );
    } else {
        return series;
    }
};

export default generateSeries;
