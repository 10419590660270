import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useMemo } from 'react';
import { Group } from 'react-konva';
import Place from './components/Place/Place';
import { Props } from './interfaces';
import { recursiveTransform } from '../../core/transformCoords';
import PassWay from './components/PassWay/PassWay';
import { IPassWay } from './components/PassWay/interfaces';
import Zone from './components/Zone/Zone';
import Perimeter from './components/Perimeter/Perimeter';
import { ObjectLabel } from '../../../../../../../components/ObjectLabel/ObjectLabel';
import { findLabelCoords } from '../../../../../../../tools/findLabelCoords';
import { findPolygonCenter } from '../../../../../../../tools/findPolygonCenter';
import { ILayer, IPerimeter, IPlace, IZone, TLayerData } from '../../../../../../../General.interfaces';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { useTranslation } from 'react-i18next';
import { POLYGON_NEUTRAL_COLOR } from '../../../../../components/constants';
import generateId from '../../../../../../../tools/generateId';
import { hasOwnProperty } from 'handsontable/helpers';

const getTransformedLayer = (layers: ILayer[], plan: any, layerType: string) => {
    let [actualLayer] = layers?.filter((item) => item.layer_type === layerType && item.floor === plan.floor);
    if (!actualLayer?.data) return null;
    const transLayer = cloneDeep(actualLayer);
    recursiveTransform(transLayer, plan.mainPlan.plan2geo);
    recursiveTransform(transLayer, plan.widestPlan.mainPlan.geo2plan);
    return transLayer;
};

// const isZone = (abstractData: TLayerData): abstractData is IZone => {
//     return Object.hasOwn(abstractData, 'group_marker');
// };

// const hasCoords = (abstractData: TLayerData): abstractData is IPlace | IPerimeter => {
//     return Object.hasOwn(abstractData, 'coords');
// };

const hasLayerProperty = <T extends TLayerData>(abstractData: TLayerData, propertyName: string): abstractData is T => {
    return Object.hasOwn(abstractData, propertyName);
};

const Geometry: React.FC<Props> = ({
    layerType = 'places_layer',
    layers = [],
    plan,
    showLabels,
    stageScale,
    widgetSettings,
    selectedGroupId,
    allMetrics,
    ...props
}) => {
    const { t } = useTranslation();

    const backPerimeter = useMemo(() => {
        const transLayer = getTransformedLayer(layers, plan, 'perimeter_layer');
        return layerType !== 'perimeter_layer'
            ? transLayer?.data.map((data: IPerimeter) => {
                  return (
                      <Perimeter object={data} key={data.front_id} heatColor={'rgba(250, 250, 250, 0.8)'} {...props} />
                  );
              })
            : null;
    }, [layers, plan.floor, plan.mainPlan.plan2geo, plan.widestPlan.mainPlan.geo2plan, props]);

    const transLayer = getTransformedLayer(layers, plan, layerType);
    const units = t(allMetrics?.find((m) => m.id === props.metric)?.units || '');

    const allowedMarkers: string[] = Object.keys(props.colorsByMarker?.[props.metric] || {}).map((key) => key);

    const passwayRelationGeometry = () => {
        if (!props.passwayRelationShapeMarker && props.selectedRelationId !== 'location') {
            return null;
        } else if (layerType === 'pass_ways_layer') {
            if (props.selectedRelationId === 'zone') {
                const transLayer = getTransformedLayer(layers, plan, 'zones_layer');
                let zoneObject: IZone | null = null;
                transLayer?.data.forEach((item: any) => {
                    if (item.group_marker === selectedGroupId) {
                        item.zones.forEach((object: IZone) => {
                            if (object.zone_marker === props.passwayRelationShapeMarker) {
                                zoneObject = object;
                            }
                        });
                    }
                });
                return zoneObject ? (
                    <Zone object={zoneObject} stageScale={stageScale} heatColor={'rgba(0,0,50,0.4)'} {...props} />
                ) : null;
            } else if (props.selectedRelationId === 'place') {
                const transLayer = getTransformedLayer(layers, plan, 'places_layer');
                let placeObject: { [x: string]: any } | null = null;
                transLayer?.data.forEach((item: IPassWay) => {
                    if (item.marker === props.passwayRelationShapeMarker) {
                        placeObject = item;
                    }
                });

                return placeObject ? <Place object={placeObject} heatColor={'rgba(0,0,50,0.4)'} {...props} /> : null;
            } else if (props.selectedRelationId === 'location') {
                const transLayer = getTransformedLayer(layers, plan, 'perimeter_layer');
                return transLayer?.data.map((data: IPerimeter) => {
                    return <Perimeter key={data.front_id} object={data} heatColor={'rgba(0,0,50,0.4)'} {...props} />;
                });
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const geometry = transLayer?.data
        ?.filter((item: TLayerData | { marker?: string }) =>
            layerType === 'zones_layer' || layerType === 'perimeter_layer' || layerType === 'tenants_layer'
                ? true
                : allowedMarkers.includes(item?.marker ?? ''),
        )
        .map((layer) => {
            switch (layerType) {
                case 'places_layer':
                    const heatColor =
                        props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['heatColor'] ||
                        POLYGON_NEUTRAL_COLOR;
                    return (
                        <Place
                            layerType={'places_layer'}
                            object={layer}
                            key={layer.marker}
                            heatColor={heatColor}
                            {...props}
                        />
                    );
                case 'tenants_layer': {
                    const heatColor =
                        props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['heatColor'] ||
                        POLYGON_NEUTRAL_COLOR;

                    const key = generateId();

                    return (
                        <Place layerType={'tenants_layer'} object={layer} key={key} heatColor={heatColor} {...props} />
                    );
                }
                case 'perimeter_layer': {
                    const heatColor =
                        props.colorsByMarker?.[props.metric]?.[transLayer.floor]?.['heatColor'] ||
                        POLYGON_NEUTRAL_COLOR;
                    return <Perimeter object={layer} key={layer.marker} heatColor={heatColor} {...props} />;
                }

                case 'pass_ways_layer': {
                    const heatColor =
                        props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['heatColor'] ||
                        POLYGON_NEUTRAL_COLOR;
                    return (
                        <PassWay
                            object={layer as IPassWay}
                            key={layer.marker}
                            heatColor={heatColor}
                            planScale={plan.scale}
                            stageScale={stageScale}
                            {...props}
                        />
                    );
                }

                case 'zones_layer':
                    if (!layer) {
                        return null;
                    }

                    if (hasLayerProperty<IZone>(layer, 'group_marker') && layer?.group_marker === selectedGroupId) {
                        return layer?.zones?.map((object: IZone) => {
                            const heatColor =
                                props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['heatColor'] ||
                                POLYGON_NEUTRAL_COLOR;
                            return (
                                <Zone
                                    object={object}
                                    key={`${object.zone_marker}--${object.front_id}`}
                                    heatColor={heatColor}
                                    stageScale={stageScale}
                                    {...props}
                                />
                            );
                        });
                    }

                    return null;

                default:
                    return null;
            }
        });

    const generateValue = (str: string | number | undefined) => {
        if (str === undefined) return '';
        let result = valueFormatter({ value: str });
        if (widgetSettings.includes('comparison')) {
            result = result + '%';
        }
        return result;
    };

    const labels = transLayer?.data
        ?.filter((item) =>
            layerType === 'zones_layer' || layerType === 'perimeter_layer'
                ? true
                : allowedMarkers.includes(item.marker ?? ''),
        )
        .map((layer) => {
            switch (layerType) {
                case 'places_layer':
                case 'tenants_layer': {
                    if (!hasLayerProperty<IPlace>(layer, 'coords')) {
                        return null;
                    }

                    const labelCoords = findPolygonCenter(layer.coords.coordinates);
                    let value = generateValue(props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['value']);
                    const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
                    const id = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['id'];
                    return (
                        <ObjectLabel
                            key={id}
                            stageScale={stageScale}
                            x={labelCoords?.x}
                            y={labelCoords?.y}
                            name={name}
                            value={`${value} ${units}`}
                            selected={false}
                            planScale={plan.scale}
                        />
                    );
                }
                case 'pass_ways_layer':
                    if (!hasLayerProperty<IPassWay>(layer, 'passLine')) {
                        return null;
                    }
                    const labelCoords = findLabelCoords(layer.passLine.coordinates);
                    const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
                    const value = generateValue(props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['value']);
                    const id = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['id'];
                    return (
                        <ObjectLabel
                            key={id}
                            stageScale={stageScale}
                            x={labelCoords?.x}
                            y={labelCoords?.y}
                            name={name}
                            value={`${value} ${units}`}
                            selected={false}
                            planScale={plan.scale}
                        />
                    );

                case 'zones_layer':
                    if (!layer) {
                        return null;
                    } else {
                        if (hasLayerProperty<IZone>(layer, 'group_marker') && layer.group_marker === selectedGroupId) {
                            return layer.zones.map((object: IZone, i: number) => {
                                const labelCoords = findPolygonCenter(object.coords.coordinates);
                                const name = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['name'];
                                const value = generateValue(
                                    props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['value'],
                                );
                                const id = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['id'] + `${i}`;
                                return (
                                    <ObjectLabel
                                        key={id}
                                        stageScale={stageScale}
                                        x={labelCoords?.x}
                                        y={labelCoords?.y}
                                        name={name}
                                        value={`${value} ${units}`}
                                        selected={false}
                                        planScale={plan.scale}
                                    />
                                );
                            });
                        } else {
                            return null;
                        }
                    }

                default:
                    return null;
            }
        });

    const overLabel = transLayer?.data
        ?.filter((item) =>
            layerType === 'zones_layer' || layerType === 'perimeter_layer'
                ? true
                : allowedMarkers.includes(item?.marker ?? ''),
        )
        .map((layer) => {
            let value = generateValue(props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['value']);
            switch (layerType) {
                case 'places_layer':
                case 'tenants_layer': {
                    if (!hasLayerProperty<IPlace>(layer, 'coords')) {
                        return null;
                    }
                    const labelCoords = findPolygonCenter(layer.coords.coordinates);
                    const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
                    const id = generateId();
                    // const id = props.colorsByMarker?.[props.metric]?.[data.marker]?.['id'];
                    return props.overMarker === layer.marker ? (
                        <ObjectLabel
                            key={id}
                            stageScale={stageScale}
                            x={labelCoords?.x}
                            y={labelCoords?.y}
                            name={name}
                            value={`${value} ${units}`}
                            selected={false}
                            planScale={plan.scale}
                        />
                    ) : null;
                }
                case 'pass_ways_layer':
                    if (!hasLayerProperty<IPassWay>(layer, 'passLine')) {
                        return null;
                    }
                    const labelCoords = findLabelCoords(layer.passLine.coordinates);
                    const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
                    const id = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['id'];
                    return props.overMarker === layer.marker ? (
                        <ObjectLabel
                            key={id}
                            stageScale={stageScale}
                            x={labelCoords?.x}
                            y={labelCoords?.y}
                            name={name}
                            value={`${value} ${units}`}
                            selected={false}
                            planScale={plan.scale}
                        />
                    ) : null;

                case 'zones_layer':
                    if (!layer) {
                        return null;
                    }

                    if (!hasLayerProperty<IZone>(layer, 'zones')) {
                        return null;
                    }

                    if (layer.group_marker === selectedGroupId) {
                        return layer.zones.map((object: IZone) => {
                            const labelCoords = findPolygonCenter(object.coords.coordinates);
                            const name = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['name'];
                            const value = generateValue(
                                props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['value'],
                            );
                            const id = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['id'];

                            return props.overMarker === `${object.zone_marker}` ? (
                                <ObjectLabel
                                    key={id}
                                    stageScale={stageScale}
                                    x={labelCoords?.x}
                                    y={labelCoords?.y}
                                    name={name}
                                    value={`${value} ${units}`}
                                    selected={false}
                                    planScale={plan.scale}
                                />
                            ) : null;
                        });
                    } else {
                        return null;
                    }

                default:
                    return null;
            }
        });

    return (
        <Group>
            {widgetSettings.includes('showPerimeters') && backPerimeter}
            {passwayRelationGeometry()}
            {geometry}
            {showLabels && labels}
            {!showLabels && overLabel}
        </Group>
    );
};

export default Geometry;
