import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../../tools/getColorFromPercent';

export const CompareValue = styled.span<{ value: number | null }>`
    color: ${(p) => getColorFromPercent(p.value)};
    white-space: nowrap;
`;

export const MainValue = styled.div`
    font-weight: 500;
    white-space: nowrap;
`;

export const ContentWrapper = styled.div<{ periodsCount: number }>`
    display: grid;
    grid-template-columns: ${(p) => `repeat(${p.periodsCount}, 1fr)`};
    gap: 20px;
`;

export const Value = styled.span<{ color?: string; fontWeight?: string; bgColor?: string }>`
    white-space: nowrap;
    color: ${(p) => p.color || 'inherit'};
    font-weight: ${(p) => p.fontWeight || 'inherit'};
    background-color: ${(p) => p.bgColor || 'inherit'};
`;
