import { FC, useMemo, useState } from 'react';
import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import { IProps } from './interfaces';
import NetworkEventsByPl from './components/NetworkEventsByPl/NetworkEventsByPl';
import { useSelector } from 'react-redux';
import { Network_Section_Reducer_Values } from '../../../../Chapters/Network/reducer';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { shortStringDate } from '../../../../tools/Strings/shortStringDate';
import { generalReducerValues } from '../../../../General.reducer';
import { queryClient } from '../../../..';
import { NETWORK_GENERAL_QUERY_KEY } from '../../../../tools/API/hooks/useNetworkQuery';

/**
 * Виджета типа "Events"
 */
const Events: FC<IProps> = ({ widgetConfig, moduleId, dashboardKey, widgetDimensions }) => {
    const networkReducer = useSelector(Network_Section_Reducer_Values);
    const generalReducer = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(moduleId);

    const [widgetTitle, setWidgetTitle] = useState(widgetConfig?.title || '');

    const { t } = useTranslation();

    const widget = useMemo(() => {
        if (widgetConfig?.type.includes('networkEventsByPl')) {
            const period = localCurrentOptions?.mainDateRanges.find(
                (range) => range.id === localCurrentOptions.mainPeriod?.id,
            )?.period;
            if (period) {
                setWidgetTitle(`${t(widgetConfig.title || '')}: ${shortStringDate(period, generalReducer.lang)}`);
                return (
                    <NetworkEventsByPl
                        widgetDimensions={widgetDimensions}
                        events={networkReducer.src.events}
                        period={period}
                    />
                );
            }
        }
        return null;
    }, [widgetConfig, localCurrentOptions, t, generalReducer.lang, widgetDimensions, networkReducer.src.events]);

    const reloadWidget = () => {
        if (widgetConfig?.type.includes('networkEventsByPl')) {
            queryClient.invalidateQueries({ queryKey: [NETWORK_GENERAL_QUERY_KEY, 'events'] });
        }
    };

    return (
        <WidgetWrapper
            title={widgetTitle}
            wrapperPaddingSize={'normal'}
            dashboardKey={dashboardKey}
            reloadWidget={reloadWidget}
            widgetId={widgetConfig?.id}
            titleSize={'normal'}
            moduleId={moduleId}
        >
            {widget}
        </WidgetWrapper>
    );
};

export default Events;
