import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReducerState, ITenantsView } from './interfaces';
import { IPeriod } from '../../../../../General.interfaces';
import { RootState } from '../../../../../store';

const initialState: IReducerState = {
    widgetName: 'Analysis of shopping center objects by area',
    tenantsView: { id: 'all', text: 'Show all' },
    comparePeriod: { id: 'previous' },
    selectedObjectId: null,
    areaMetric: 'sum_area',
    selectedParentId: null,
    colorMetric: '',
    objectsType: '',
};

export const Performance_Dashboard_ByArea_Widget_Reducer = createSlice({
    name: 'Performance_Dashboard_ByArea_Widget_Reducer',
    initialState,

    reducers: {
        /** Запись периода сравнения */
        storeComparePeriod: (state, action: PayloadAction<IPeriod>) => {
            state.comparePeriod = { id: action.payload.id, period: action.payload.period };
        },

        /** Запись метрики цвета */
        storeColorMetric: (state, action: PayloadAction<string>) => {
            state.colorMetric = action.payload;
        },

        /** Запись метрики площади */
        storeAreaMetric: (state, action: PayloadAction<string>) => {
            state.areaMetric = action.payload;
        },

        /** Запись выбранного таба (типа отчетного объекта) */
        storeObjectsType: (state, action: PayloadAction<string>) => {
            state.objectsType = action.payload;
        },

        /**
         * Сохранение id выбранного объекта
         */
        storeSelectedObjectId: (state, action: PayloadAction<number | null>) => {
            const { selectedObjectId } = _.cloneDeep(state);
            if (selectedObjectId === action.payload) {
                state.selectedObjectId = null;
            } else {
                state.selectedObjectId = action.payload;
            }
        },

        /**
         * Запись id выбранной категории
         */
        storeSelectedParentId: (state, action: PayloadAction<string | null>) => {
            state.selectedParentId = action.payload;
        },

        /**
         * Сохранение режима отображения арендаторов
         */
        storeTenantsView: (state, action: PayloadAction<ITenantsView>) => {
            state.tenantsView = action.payload;
        },

        /**
         * Обнуление редьюсера
         */
        resetReducer: () => initialState,
    },
});

export const {
    storeSelectedParentId,
    storeSelectedObjectId,
    storeComparePeriod,
    storeColorMetric,
    storeObjectsType,
    storeTenantsView,
    storeAreaMetric,
    resetReducer,
} = Performance_Dashboard_ByArea_Widget_Reducer.actions;

export const Performance_Dashboard_ByArea_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_Dashboard_ByArea_Widget_Reducer;

export default Performance_Dashboard_ByArea_Widget_Reducer.reducer;
