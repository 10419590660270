import {
    ArrowTrendingLines24Regular,
    DataPie24Regular,
    DataTreemap24Regular,
    Grid24Regular,
    TextNumberListLtr24Regular,
} from '@fluentui/react-icons';
import {
    NumbersWorkingModeIds,
    RatingPasswaysTrafficIds,
} from '../../../components/DashboardsCommon/WidgetsManager/enums';
import { IWidgetType } from '../../../components/DashboardsCommon/interfaces';

export const NUMBERS_WORKING_MODE_OPTIONS = [
    { id: NumbersWorkingModeIds.DailyAverage, text: 'Daily average' },
    { id: NumbersWorkingModeIds.Sum, text: 'Sum' },
] as const;

export const NUMBERS_WORKING_MODE_MAP = {
    [NumbersWorkingModeIds.DailyAverage]: { text: 'Daily average', short: 'Avg.' },
    [NumbersWorkingModeIds.Sum]: { text: 'Sum', short: 'Sum' },
} as const;

export const NUMBERS_DAYS_OF_THE_WEEK_OPTIONS = [
    { id: 'monday', text: 'Mo' },
    { id: 'tuesday', text: 'Tu' },
    { id: 'wednesday', text: 'We' },
    { id: 'thursday', text: 'Th' },
    { id: 'friday', text: 'Fr' },
    { id: 'saturday', text: 'Sa' },
    { id: 'sunday', text: 'Su' },
] as const;

export const RATING_PASSWAYS_TRAFFIC_TYPE_OPTIONS = [
    { id: RatingPasswaysTrafficIds.All, text: 'All' },
    { id: RatingPasswaysTrafficIds.Location, text: 'Location' },
    { id: RatingPasswaysTrafficIds.Floor, text: 'Floors' },
] as const;

export const widgetTypes: IWidgetType[] = [
    {
        type: 'number',
        title: 'Number',
        children: [
            {
                id: '',
                type: 'simple',
                title: 'Simple',
                requiredParams: ['mainPeriod', 'comparePeriods', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {},
            },
            {
                id: '',
                type: 'weekDays',
                title: 'By weekdays',
                requiredParams: ['mainPeriod', 'comparePeriods', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {
                    workingMode: NUMBERS_WORKING_MODE_OPTIONS[0]?.id,
                    selectedDaysOfTheWeek: NUMBERS_DAYS_OF_THE_WEEK_OPTIONS.map((day) => day.id),
                },
            },
        ],
        icon: <Grid24Regular />,
        defaultLayout: {
            h: 4,
            w: 6,
            x: 100,
            y: 100,
            isResizable: false,
        },
    },
    {
        type: 'dynamics',
        title: 'Dynamics',
        children: [
            {
                id: '',
                type: 'periods',
                title: 'Periods',
                requiredParams: ['mainPeriod', 'comparePeriods', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {},
            },
            {
                id: '',
                type: 'metrics',
                title: 'Metrics',
                requiredParams: ['mainPeriod', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {},
            },
            {
                id: '',
                type: 'objects',
                title: 'Objects',
                requiredParams: ['mainPeriod', 'comparisonObjects', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {},
            },
        ],
        icon: <ArrowTrendingLines24Regular />,
        defaultLayout: {
            h: 8,
            w: 18,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
    {
        type: 'piechart',
        title: 'Piechart',
        children: [
            {
                id: '',
                type: 'categories',
                title: 'Categories',
                requiredParams: ['mainPeriod', 'comparePeriods', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {},
            },
            {
                id: '',
                type: 'distribByCats',
                title: 'Distribution by categories',
                requiredParams: ['mainPeriod', 'comparePeriods', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {},
            },
        ],
        icon: <DataPie24Regular />,
        defaultLayout: {
            h: 8,
            w: 18,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
    {
        type: 'rating',
        title: 'Rating',
        children: [
            {
                id: '',
                type: 'topByMetrics',
                title: 'Top by metrics',
                requiredParams: ['mainPeriod', 'comparePeriods'],
                sources: ['default'],
                options: {},
                visual: {},
            },
            {
                id: '',
                type: 'passwaysTraffic',
                title: 'Traffic by entrances',
                requiredParams: ['mainPeriod', 'comparePeriods'],
                sources: ['default'],
                options: {},
                visual: {
                    passwaysTrafficType: [RATING_PASSWAYS_TRAFFIC_TYPE_OPTIONS[1]],
                },
            },
        ],
        icon: <TextNumberListLtr24Regular />,
        defaultLayout: {
            h: 8,
            w: 18,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
    {
        type: 'heatmap',
        title: 'Heatmap',
        children: [
            {
                id: '',
                type: 'weekdaysByHours',
                title: 'Weekdays by hours',
                requiredParams: ['mainPeriod', 'mainObject'],
                sources: ['default'],
                options: {},
                visual: {},
            },
        ],
        icon: <DataTreemap24Regular />,
        defaultLayout: {
            h: 8,
            w: 18,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
];

export const widgetTypesByType: { [widgetType: string]: IWidgetType } = widgetTypes.reduce((acc, value) => {
    acc[value.type] = value;
    return acc;
}, {});
