import { FC } from 'react';
import { IProps } from './interfaces';
import Stack from '../../../../../../../components/Stack/Stack';
import { getObjectName } from '../../../../../../../hooks/useObjectTranslation';
import { Tab } from './styles';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';

const ReportingObjectsTabs: FC<IProps> = (props) => {
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    return (
        <Stack alignItems="center" gap={10} flexWrap="wrap">
            {props.reportingObjectsIds.map((id) => {
                const reportingObject = reportingObjectsById?.[id];
                return (
                    <Tab
                        isSelected={id === props.selectedReportingObjectId}
                        onClick={() => props.onSelect(id)}
                        key={id}
                    >
                        {reportingObject
                            ? getObjectName({
                                  reportingObject,
                                  addFloor: true,
                                  showType: true,
                                  t,
                              })
                            : id}
                    </Tab>
                );
            })}
        </Stack>
    );
};

export default ReportingObjectsTabs;
