import { memo, useContext, useMemo } from 'react';
import ReactDataSheet from 'react-datasheet';
import { EventsManagementContext } from '../../context';
import { ITableCellData } from '../../interfaces';
import Cell from './components/Cell/Cell';
import FilterContextualMenu from './components/FilterContextualMenu/FilterContextualMenu';
import { TableWrapper } from './styles';
import filterTableData from './tools/filterTableData';

/**
 * Компонент для отображения таблицы со всеми событиями
 */
const Table = memo(() => {
    const context = useContext(EventsManagementContext);

    const onCellsChanged = (changes: { cell: ITableCellData; value: any }[]) => {
        changes.forEach(({ cell, value }) => {
            context?.updateCellValueTrans({ frontId: cell.frontId, key: cell.key, value });
        });
    };

    const onContextMenu = (e: MouseEvent, cell: ITableCellData) => {
        e.preventDefault();
        const cursorCoords = { x: e.clientX, y: e.clientY };
        context?.storeContextMenuTragetTrans({ cursorCoords, cell });
    };

    const filteredTableData = useMemo(() => {
        const filteredData = filterTableData({
            reportingObjectsById: context?.reportingObjectsById,
            eventTypesById: context?.eventTypesById,
            filters: context?.filtersByCellKey,
            tableData: context?.tableData || [],
        });
        return filteredData;
    }, [context?.tableData, context?.filtersByCellKey, context?.reportingObjectsById, context?.eventTypesById]);

    const cellRenderer = (props: any) => <Cell filteredTableData={filteredTableData} {...props} />;

    console.log(filteredTableData);

    return (
        <TableWrapper>
            <ReactDataSheet
                onContextMenu={onContextMenu}
                valueRenderer={(cell: any) => cell.value}
                onCellsChanged={onCellsChanged}
                cellRenderer={cellRenderer}
                data={filteredTableData}
            />
            <FilterContextualMenu />
        </TableWrapper>
    );
});

export default Table;
