import { FC, useEffect, useMemo, useRef } from 'react';
import { IProps } from './interfaces';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { Wrapper } from './styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { TLayouts } from '../interfaces';
import { MIN_ROW_HEIGHT, ROWS_PER_SCREEN, STEPS_IN_ROW } from '../../../Chapters/Dashboards/constants';
import WidgetPlate from '../../../Chapters/Dashboards/components/WidgetPlate/WidgetPlate';
import WidgetHub from '../WidgetHub/WidgetHub';
import { Dashboards_Reducer_Values } from '../../../Dashboards.reducer';
import { useTranslation } from 'react-i18next';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

/**
 * Layout для отображения сетки с виджетами
 */
const DashboardLayout: FC<IProps> = ({
    userDashboards,
    dashboardKey,
    reportingObjects,
    moduleId,
    layouts,
    updateWidgetTrans,
    changeLayouts,
}) => {
    const { mainAreaSize } = useSelector(generalReducerValues);
    const { editPanelMode } = useSelector(Dashboards_Reducer_Values);

    const { i18n } = useTranslation();
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const gridLayoutContainer = document.querySelector('.react-grid-layout');
            const children = document.querySelectorAll('.react-grid-item');
            gridLayoutContainer?.setAttribute('dir', 'ltr');
            children.forEach((child) => child.setAttribute('dir', i18n.dir()));
        }
    }, [i18n, i18n.language, ref?.current]);

    /** Изменение layouts */
    const onChange = (currentLayout: ReactGridLayout.Layout[], allLayouts: ReactGridLayout.Layouts) => {
        if (currentLayout?.length) {
            changeLayouts(allLayouts as TLayouts);
        }
    };

    /** Получение виджетов для сетки */
    const widgets = useMemo(() => {
        const currentDashboard = userDashboards.find(
            (dashboard) => dashboard.module_id === moduleId && dashboard.dashboard_key === dashboardKey,
        );

        if (currentDashboard?.widgets?.length) {
            return currentDashboard.widgets.map((widgetConfig) => {
                return (
                    <WidgetPlate key={widgetConfig.id}>
                        <WidgetHub
                            widgetConfig={widgetConfig}
                            dashboardKey={dashboardKey}
                            moduleId={moduleId}
                            updateWidgetTrans={updateWidgetTrans}
                            reportingObjects={reportingObjects}
                        />
                    </WidgetPlate>
                );
            });
        }

        return null;
    }, [userDashboards, moduleId, dashboardKey, updateWidgetTrans]);

    /** Высота строки в сетке */
    // const rowHeight = useMemo(() => {
    //     const value =
    //         (mainAreaSize.height - 350) /
    //         (Math.floor((ROWS_PER_SCREEN * mainAreaSize.height) / mainAreaSize.width) * HEIGHT_MULTIPLIER);
    //     if (value < MIN_ROW_HEIGHT) return MIN_ROW_HEIGHT;
    //     return value;
    // }, [mainAreaSize.height, mainAreaSize.width]);

    // const rowHeight = useMemo(() => {
    //     const ratio =
    //         mainAreaSize.width / mainAreaSize.height > 1.5
    //             ? 1
    //             : ROWS_PER_SCREEN * (mainAreaSize.width / mainAreaSize.height);

    //     const value = (mainAreaSize.height - 350) / (ROWS_PER_SCREEN * ratio * STEPS_IN_ROW);
    //     if (value < MIN_ROW_HEIGHT) return MIN_ROW_HEIGHT;

    //     return value;
    // }, [mainAreaSize.height, mainAreaSize.width]);

    const BREAK_COLS = {
        lg: 36,
        md: 24,
        sm: 18,
        xs: 12,
        xxs: 6,
    };

    const BREAK_WIDTHES = {
        lg: 1000,
        md: 800,
        sm: 600,
        xs: 400,
        xxs: 0,
    };

    const getColsNumber = (width: number) => {
        let result = 0;
        if (width > BREAK_WIDTHES.xxs && width <= BREAK_WIDTHES.xs) {
            result = BREAK_COLS.xxs;
        } else if (width > BREAK_WIDTHES.xs && width <= BREAK_WIDTHES.sm) {
            result = BREAK_COLS.xs;
        } else if (width > BREAK_WIDTHES.sm && width <= BREAK_WIDTHES.md) {
            result = BREAK_COLS.sm;
        } else if (width > BREAK_WIDTHES.md && width <= BREAK_WIDTHES.lg) {
            result = BREAK_COLS.md;
        } else {
            result = BREAK_COLS.lg;
        }
        return result;
    };

    const rowHeight = useMemo(() => {
        const w = mainAreaSize.width - 280;
        const h = mainAreaSize.height - 350;
        const ratio = w / h;
        const colsNumber = getColsNumber(w);
        const rowsNumber = Math.round(colsNumber / ratio);

        const value = h / rowsNumber;
        // if (value < MIN_ROW_HEIGHT) return MIN_ROW_HEIGHT;

        return value;
    }, [mainAreaSize.height, mainAreaSize.width]);

    return (
        <Wrapper>
            {Boolean(widgets) && (
                <ResponsiveReactGridLayout
                    ref={ref}
                    breakpoints={BREAK_WIDTHES}
                    cols={BREAK_COLS}
                    layouts={layouts || undefined}
                    containerPadding={[0, 0]}
                    rowHeight={rowHeight}
                    onLayoutChange={onChange}
                    isDraggable={editPanelMode}
                >
                    {widgets}
                </ResponsiveReactGridLayout>
            )}
        </Wrapper>
    );
};

export default DashboardLayout;
