import CreateModalBody from './components/CreateModalBody/CreateModalBody';
import React, { useRef, useState } from 'react';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetsNavigation from '../../components/WidgetsNavigation/WidgetsNavigation';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { ListWrapper } from './styles';
import { IEventType } from '../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Button/Button';
import Modals from '../../../../../components/Modals/Modals';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { IEventTypeModalStatus } from './interfaces';
import EventTypesTable from './components/EventTypesTable/EventTypesTable';

const EventTypes: React.FC = () => {
    const [eventTypeModalStatus, setEventTypeModalStatus] = useState<IEventTypeModalStatus>({ show: false });

    const { t } = useTranslation();
    const ref = useRef(null);

    const openEventTypeModal = (eventType?: IEventType) => {
        setEventTypeModalStatus({ show: true, eventType });
    };

    const closeEventTypeModal = () => {
        setEventTypeModalStatus({ show: false });
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Event types management')}
                    <WidgetAdditionalControls
                        widgetName={'Event types management'}
                        pdfDownloadData={{ targetRef: ref }}
                    />
                </WidgetTitle>
                <WidgetsNavigation />
            </WidgetTitleWrapper>
            <Modals
                title={eventTypeModalStatus.eventType ? t('Event type edit') : t('Create new event type')}
                closeModal={closeEventTypeModal}
                modalStatus={eventTypeModalStatus}
                topColor="fff"
            >
                <CreateModalBody eventTypeToEdit={eventTypeModalStatus.eventType} closeModal={closeEventTypeModal} />
            </Modals>
            <Button appearance="primary" text={t('Create new event type')} onClick={() => openEventTypeModal()} />
            <ListWrapper>
                <EventTypesTable openEventTypeModal={openEventTypeModal} />
            </ListWrapper>
        </WidgetWrapper>
    );
};

export default EventTypes;
