import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { utils, writeFile } from 'xlsx-with-styles';

import { Button, ButtonProps } from 'src/components/Button';
import { PickPureFieldType } from 'src/types';

import { ISheetCell } from '../../../../General.interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';
import { DS } from '../../../../constants/constants';

type Appearance = PickPureFieldType<ButtonProps, 'appearance'>;

const SaveXLSXFileButton: React.FC<{
    grid: ISheetCell[][];
    disabled?: boolean;
    text?: string;
    appearance?: Appearance;
    bookName?: string;
    currentModuleID: string;
    keyWord?: string;
    additionalGrid?: ISheetCell[][];
}> = ({
    grid,
    disabled = false,
    bookName = 'Basic report',
    keyWord = '',
    text = 'Download',
    currentModuleID,
    additionalGrid = [],
    appearance,
}) => {
    const sendAnalytics = useSendSimpleAnalytics();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const NUMBER_OF_EMPTY_ROWS = 1;

    const saveFile = (ext: string) => () => {
        const wb = utils.book_new();

        const worksheet = utils.aoa_to_sheet(
            [
                ...additionalGrid,
                ...Array(NUMBER_OF_EMPTY_ROWS)
                    .fill('')
                    .map((item) => [{ value: item }]),
                ...grid,
            ].map((row) => {
                return row.map((cell) => {
                    // return cell.value;

                    if (cell.value && isFinite(Number(String(cell.value).replaceAll(' ', '')))) {
                        return Number(String(cell.value).replaceAll(' ', ''));
                    } else {
                        return cell.value;
                    }
                });
            }),
        );

        const colsWidths = grid[0].map((cell, i) => {
            const width =
                Math.max(
                    ...grid.map((row) => {
                        return String(row[i]?.value)?.length || 1;
                    }),
                ) *
                    2 +
                1;
            return { width, height: 3 };
        });

        Object.keys(worksheet).forEach((key) => {
            /* all keys that do not begin with "!" correspond to cell addresses */
            if (key[0] !== '!') {
                const cell = worksheet[key];
                let bold = false;
                let patternType = 'none';
                let bgColor = { rgb: 'FFFFFFFF' };
                let bottom = { style: 'hair', color: { auto: 1 } };

                const mainGridHeaderNumber = additionalGrid.length ? additionalGrid.length + 1 : 1;

                const numberPattern = /\d+(\.\d+)?/;
                const matches = key.match(numberPattern);

                let currentKeyNumber = 1;

                if (matches) {
                    currentKeyNumber = parseFloat(matches[0]);
                }

                if (currentKeyNumber === mainGridHeaderNumber + NUMBER_OF_EMPTY_ROWS) {
                    bold = true;
                    bottom = { style: 'thin', color: { auto: 1 } };
                }
                cell.s = {
                    fill: {
                        patternType,
                        fgColor: { rgb: 'FF000000' },
                        bgColor,
                    },
                    font: {
                        // name: 'Times New Roman',
                        sz: 14,
                        color: { rgb: 'FF000000' },
                        bold,
                        italic: false,
                        underline: false,
                    },
                    border: {
                        top: { style: 'hair', color: { auto: 1 } },
                        right: { style: 'hair', color: { auto: 1 } },
                        bottom,
                        left: { style: 'hair', color: { auto: 1 } },
                    },
                };
            }
        });

        worksheet['!cols'] = colsWidths;

        utils.book_append_sheet(wb, worksheet, 'worksheet');

        const metricGroups = Array.from(
            new Set(
                localCurrentOptions?.[keyWord ? `selectedMetrics${DS}${keyWord}` : 'selectedMetrics']?.map(
                    (item: string) => {
                        return allMetrics?.find((metric) => metric.id === item)?.section;
                    },
                ),
            ),
        );

        if (metricGroups?.length) {
            metricGroups.forEach((group) => {
                sendAnalytics(`${keyWord || 'report'}_downloaded_${group}`);
            });
        } else {
            sendAnalytics(`${keyWord || 'report'}_downloaded`);
        }

        writeFile(wb, `${bookName}.` + ext);
    };

    return <Button text={t(text)} appearance={appearance} onClick={saveFile('xlsx')} disabled={disabled} />;
};

export default SaveXLSXFileButton;
