import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// import { ServiceWorkers } from 'src/service-workers/ServiceWorkers';
import { REACT_APP_REF } from 'src/constants';

import { store } from './store';
import App from './App';
import GlobalSpinner from './components/GlobalSpinner/GlobalSpinner';
import i18Init from './i18n';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    },
});

i18Init();

const root = createRoot(REACT_APP_REF.current!);

root.render(
    <QueryClientProvider client={queryClient}>
        <Router>
            {/* <ServiceWorkers> */}
            <Provider store={store}>
                <ReactQueryDevtools initialIsOpen={false} />
                <Suspense fallback={<GlobalSpinner isScreenHeight={true} />}>
                    <App />
                </Suspense>
            </Provider>
            {/* </ServiceWorkers> */}
        </Router>
    </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
