import styled from 'styled-components/macro';

export const DataItem = styled.div`
    margin-top: 10px;
    span {
        display: inline-block;
        margin-right: 10px;
        font-weight: 700;
    }
    :first-child {
        margin-top: 0px;
    }
`;
