import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

// import { useAppDispatch } from 'src/hooks/useSettings';
import {
    FocusTable,
    ColumnOptions,
    // TableRange,
} from 'src/components/FocusTable';
import LoadingBox from 'src/components/LoadingBox/LoadingBox';
import { generalReducerValues } from 'src/General.reducer';
import {
    // storeActivePosOfTenantsIds,
    // storeSelectedPosOfTenantsRanges,
    Sales_PosAnalysis_Module_Reducer_Values,
} from 'src/Chapters/Sales/PosAnalysis/reducer';
import useTranslation from 'src/hooks/useTranslation/useTranslation';
import WidgetTitleWrapper from 'src/components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from 'src/components/Wrappers/WidgetTitle/WidgetTitle';
import {
    emptyObject,
    // chunks,
} from 'src/tools';

import { posEntitiesByTenantAdapter } from '../../api/dataAdapters/posEntitiesByTenantAdapter';
import useFetchData from '../../api/useFetchData';
import { CELL_STYLE } from '../../../../constants';

// import { PosExportsContext } from '../../context';

const columnsOptions: ColumnOptions[] = [
    { id: 'id', label: 'POS ID', visible: false },
    { id: 'tenantName', label: 'Tenant' },
    { id: 'inn', label: 'EIN', align: 'right' },
    { id: 'posType', label: 'Connection type' },
    // { id: 'posName', minWidth: 200, title: 'Pos Details' },
    { id: 'factoryId', label: 'Factory POS ID', align: 'right' },
    { id: 'registrationId', label: 'Registration POS ID', align: 'right' },
    // { id: 'accumulatorId', style: { minWidth: 200 }, label: 'Fiscal device POS ID', align: 'right', visible: false },
    // { id: 'dateFrom', style: { minWidth: 120 }, sortOrder: 'asc' },
    // { id: 'dateTo', style: { minWidth: 120 }, sortOrder: 'asc' },
    { id: 'firstDate', label: 'Date of first receipt', hasSort: false, hasFilter: false },
    // { id: 'firstTime', label: 'Time of first receipt', hasSort: false, hasFilter: false, visible: false },
    { id: 'lastDate', label: 'Date of last receipt', hasSort: false, hasFilter: false },
    // { id: 'lastTime', label: 'Time of last receipt', hasSort: false, hasFilter: false, visible: false },
    { id: 'sales', label: 'Sales (Inc. VAT)', hasSort: false, hasFilter: false },
    { id: 'receiptsCount', label: 'Num of Receipts', hasSort: false, hasFilter: false },
];

// const rangeToArray = (range: CellRange) => [range.from.row, range.from.col, range.to.row, range.to.col];

interface TenantPosListProps {}

export const TenantPosList: React.FC<TenantPosListProps> = () => {
    // const hotRef = useRef<unknown>(null);
    // const dispatch = useAppDispatch();

    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();

    const { activeTenantIds } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);

    const { data, isFetching } = useFetchData(posEntitiesByTenantAdapter);

    const possByTenant = data || emptyObject;

    const tenantPosEntities = useMemo(
        () => (activeTenantIds || []).reduce((acc, tenantId) => [...acc, ...(possByTenant?.[tenantId] || [])], []),
        [activeTenantIds, possByTenant],
    );

    const showTable: boolean = tenantPosEntities?.length > 0;

    const tableTitle =
        (activeTenantIds || [])?.length === 1
            ? `${t('Tenant details')}: ${(activeTenantIds || [])
                  .map((id) => reportingObjectsById[id ?? '']?.object_name ?? '')
                  .join('')}`
            : t('Tenants details');

    // const { selectedPosOfTenantsRanges } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);

    // const selectedCells = useMemo(() => {
    //     if (!selectedPosOfTenantsRanges) {
    //         return null;
    //     }

    //     const [firstChunk] = [...chunks<number>(selectedPosOfTenantsRanges || [], 4)];
    //     const [startRow, startCol, endRow, endCol] = firstChunk;

    //     return {
    //         start: { row: startRow, col: startCol },
    //         end: { row: endRow, col: endCol },
    //     } as TableRange;
    // }, [selectedPosOfTenantsRanges]);

    // const onSelectionChange = useCallback(
    //     (range: TableRange | null, selectedRows: typeof tenantPosEntities) => {
    //         if (range === null || !(selectedRows?.length > 0)) {
    //             dispatch(storeActivePosOfTenantsIds(null));
    //             dispatch(storeSelectedPosOfTenantsRanges(null));
    //             return;
    //         }

    //         const { start, end } = range;

    //         const selectedTenantIds = selectedRows.map(({ id }) => Number(id));

    //         dispatch(storeActivePosOfTenantsIds(selectedTenantIds?.length > 0 ? selectedTenantIds : null));
    //         dispatch(storeSelectedPosOfTenantsRanges([start.row, start.col, end.row, end.col]));
    //     },
    //     [dispatch],
    // );

    if (isFetching) {
        return <LoadingBox height={400} />;
    }

    return (
        <>
            {showTable && (
                <>
                    <WidgetTitleWrapper>
                        <WidgetTitle>{tableTitle}</WidgetTitle>
                    </WidgetTitleWrapper>
                    <FocusTable
                        tableId="tenantsPosList"
                        nameForExports={tableTitle}
                        value={tenantPosEntities}
                        columnsOptions={columnsOptions}
                        // onSelectionChange={onSelectionChange}
                        // selectedCells={selectedCells}
                        maxHeight={300}
                        cellStyle={CELL_STYLE}
                        selectedCells={null}
                        // style={{ height: 'auto' }}
                        // stretchH={'all'}
                        // onCellMouseUp={handleSelectAfterOnCellMouseUp}
                        // filters
                    />
                </>
            )}
        </>
    );
};
