import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { IBgReport } from '../../../../../../../../General.interfaces';
import { CREATE_BG_REPORT_RQ_KEY, GET_ALL_BG_REPORTS_RQ_KEY } from '../../../../../../../../constants/constants';
import { ICreateBgReportDto } from '../../../interfaces';
import { postRequest } from '../../../../../../../../tools/API/appBackendAPI';

/**
 * Кастомный хук для создания нового bg report
 */
const useCreateBgReport = () => {
    const { token, urlsByServices, selectedLocationId } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const createBgReportUrl = useMemo(() => {
        if (urlsByServices) {
            return urlsByServices['app/app-backend'].CREATE_BACKGROUND_REPORT_URL;
        }
        return null;
    }, [urlsByServices]);

    return useMutation<IBgReport, AxiosError, ICreateBgReportDto>({
        mutationKey: [CREATE_BG_REPORT_RQ_KEY, selectedLocationId, token],
        mutationFn: (data: ICreateBgReportDto) => {
            if (createBgReportUrl) {
                return postRequest(createBgReportUrl, token, data, dispatch);
            }
            return new Promise((_, reject) => reject(new AxiosError('No valid data for request')));
        },

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GET_ALL_BG_REPORTS_RQ_KEY] });
        },
    });
};

export default useCreateBgReport;
