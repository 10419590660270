import { ChangeEvent, FC, MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContextualMenu from '../../../../../../../components/ContextualMenu/ContextualMenu';
import CsvFormatContextualMenuBody from './components/CsvFormatContextualMenuBody/CsvFormatContextualMenuBody';
import { CsvFormatButtonWrapper, InputWrapper, Label, Wrapper } from './styles';
import Button from '../../../../../../../components/Button/Button';
import TextInput from '../../../../../../../components/TextInput/TextInput';
import Stack from '../../../../../../../components/Stack/Stack';

interface IProps {}

/**
 * Компонент тела модального окна для пакетной загрузки событий
 */
const MultipleEventsModalBody: FC<IProps> = ({}) => {
    const [eventsCsvString, setEventsCsvString] = useState(
        'name;date_from;date_to;event_type;data_objects_ids;comment',
    );
    const [selectedFile, setSelectedFile] = useState<null | File>(null);
    const [showCsvFormat, setShowCsvFormat] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const onFileClick = (e: MouseEvent<HTMLInputElement>) => {
        e.target['value'] = null;
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.files && setSelectedFile(e.target.files[0]);
    };

    const onSelectClick = () => {
        fileInputRef?.current?.click();
    };

    const onCsvStringChange = (args: { value: string }) => {
        setEventsCsvString(args.value);
    };

    const toggleCsvFormatContextualMenu = () => {
        setShowCsvFormat((prevState) => !prevState);
    };

    const closeCsvFormatContextualMenu = () => {
        setShowCsvFormat(false);
    };

    const onAddClick = () => {
        console.log('start parsing');
    };

    return (
        <Wrapper>
            <input
                style={{ display: 'none' }}
                onChange={onFileChange}
                onClick={onFileClick}
                ref={fileInputRef}
                accept=".csv"
                type="file"
            />

            <ContextualMenu
                contextualMenuBody={<CsvFormatContextualMenuBody />}
                closeContextualMenu={closeCsvFormatContextualMenu}
                isContextualMenuOpen={showCsvFormat}
                gapSpace={10}
            >
                <CsvFormatButtonWrapper>
                    <Button appearance="primary" onClick={toggleCsvFormatContextualMenu}>
                        {t('Show csv format')}
                    </Button>
                </CsvFormatButtonWrapper>
            </ContextualMenu>

            <InputWrapper>
                <Label>{t('Event csv string')}</Label>
                <TextInput
                    handleChange={onCsvStringChange}
                    outsideValue={eventsCsvString}
                    isMultiLine={true}
                    fullWidth={true}
                />
            </InputWrapper>

            <Stack direction={'row'} alignItems="center" gap={10}>
                <Button appearance="primary" onClick={onSelectClick}>
                    {t('Select csv file')}
                </Button>
                <div>{selectedFile?.name}</div>
            </Stack>
            <Stack direction={'row'} alignItems="center" justifyContent="flex-end">
                <Button appearance="primary" onClick={onAddClick}>
                    {t('Add')}
                </Button>
            </Stack>
        </Wrapper>
    );
};

export default MultipleEventsModalBody;
