import styled from 'styled-components/macro';

export const ModuleWrapper = styled.div`
    height: 100%;
`;

export const TableWrapper = styled.div`
    padding-top: 20px;
`;

export const Wrapper = styled.div`
    margin-top: var(--space-xs);
`;
