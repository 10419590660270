import { FC } from 'react';
import { IProps } from './interfaces';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { Wrapper } from './styles';
import { RatingPasswaysTrafficIds } from '../../../../WidgetsManager/enums';
import { ZONES_WORD } from '../../../../../../constants/constants';

/**
 * Дополнительная информация для типа passwaysTraffic
 */
const PasswaysTrafficInfo: FC<IProps> = ({ widgetConfig }) => {
    const { t } = useTranslation();

    const trafficType = widgetConfig?.visual?.passwaysTrafficType?.[0];
    const trafficFloor = widgetConfig?.visual?.passwaysTrafficFloor?.[0];
    const trafficZone = widgetConfig?.visual?.passwaysTrafficZone?.[0];

    return (
        <>
            <Wrapper>
                <span>{t('Traffic by')}:&nbsp;</span>
                <div>{t(String(trafficType?.text || trafficType?.id || ''))}</div>
            </Wrapper>
            {trafficType?.id === RatingPasswaysTrafficIds.Floor && (
                <Wrapper>
                    <span>{t('Floor')}:&nbsp;</span>
                    <div>{trafficFloor?.text || trafficFloor?.id}</div>
                </Wrapper>
            )}
            {String(trafficType?.id)?.includes(ZONES_WORD) && (
                <Wrapper>
                    <span>{t('Zone')}:&nbsp;</span>
                    <div>{trafficZone?.text || trafficZone?.id}</div>
                </Wrapper>
            )}
        </>
    );
};

export default PasswaysTrafficInfo;
