import { isNumber } from 'lodash';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';

interface IArgs {
    series: Array<TDynamicsSeries>;
}

const getSeriesTickInterval = (args: IArgs): number => {
    const { series } = args;
    let TICK_INTERVAL = 24 * 3600 * 1000; // one day by default

    const mainSeries = series.find((item) => Boolean(item['isMain'])) || series[0];
    if (mainSeries) {
        if ('data' in mainSeries && Array.isArray(mainSeries.data) && mainSeries.data.length > 1) {
            const first = mainSeries.data[0] as IExtendedChartPoint;
            const second = mainSeries.data[1] as IExtendedChartPoint;

            if (isNumber(first.x) && isNumber(second.x)) {
                TICK_INTERVAL = second.x - first.x;
            }
        }
    }
    return TICK_INTERVAL;
};

export default getSeriesTickInterval;
