import { IHeatMapCustomChartDimensions } from '../interfaces';
import generateChart from './chart/generateChart';
import generateTooltip from './tooltip/generateTooltip';

interface IArgs {
    customChartDimensions?: IHeatMapCustomChartDimensions;
    options?: Highcharts.Options;
}

const generateOptions = (args: IArgs): Highcharts.Options => {
    const { options, customChartDimensions } = args;

    return {
        ...options,
        credits: {
            enabled: false,
        },
        noData: {
            position: {
                verticalAlign: 'middle',
                align: 'center',
                x: 0,
                y: 0,
            },
            style: {
                color: 'rgba(0,0,0,.4)',
                fontWeight: 'normal',
                fontSize: '16px',
            },
        },
        chart: generateChart({ customChartDimensions, chartOptions: options?.chart }),
        tooltip: generateTooltip({ tooltipOptions: options?.tooltip }),
    };
};

export default generateOptions;
