import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { POST_CORRECTION_BILLS_QUERY_KEY } from '../constants/constants';
import { generalReducerValues } from '../../../../General.reducer';
import { postRequest } from '../../../../tools/API/appBackendAPI';
import { IPostBillData } from '../interfaces';
import { useGetCorrectionBills } from './useGetCorrectionBills';

interface IArgs {
    postMutationKey: unknown;
}

const useMutateCorrectionBills = (args?: IArgs) => {
    const { urlsByServices, token } = useSelector(generalReducerValues);
    const { data, isLoading, refetch } = useGetCorrectionBills();

    const url = useMemo(() => {
        return 'https://fsf-api.sales-flow.ru/api/correction-bill/';
    }, [urlsByServices]);

    const postMutation = useMutation<IPostBillData[], unknown, Omit<IPostBillData, 'id'>[]>({
        mutationKey: [POST_CORRECTION_BILLS_QUERY_KEY, args?.postMutationKey],
        mutationFn: (data): Promise<IPostBillData[]> => {
            return postRequest(String(url), token, data, undefined, true).then((response) => response.data);
        },
        onSuccess: () => {
            refetch();
        },
    });

    // const updateMutation = useMutation<IDecision, unknown, { data: Partial<Omit<IDecision, 'id'>>; id: number }>({
    //     mutationKey: [UPDATE_DECISION_QUERY_KEY],
    //     mutationFn: (args): Promise<IDecision> => {
    //         return patchRequest({
    //             url: `${String(DecisionsUrl)}${args.id}/`,
    //             token,
    //             data: args.data,
    //             throwError: true,
    //         }).then((response) => response.data);
    //     },
    //     // onSuccess: () => {
    //     //     queryClient.invalidateQueries([DECISION_TYPES_QUERY_KEY]);
    //     // },
    // });

    // const deleteMutation = useMutation<void, unknown, number>({
    //     mutationKey: [DELETE_DECISION_QUERY_KEY],
    //     mutationFn: (id): Promise<void> => {
    //         return deleteRequest(String(DecisionsUrl), token, id, undefined, true);
    //     },
    //     // onSuccess: () => {
    //     //     queryClient.invalidateQueries([DECISION_TYPES_QUERY_KEY]);
    //     // },
    // });

    return {
        postMutation,
        // updateMutation, deleteMutation
    };
};

export default useMutateCorrectionBills;
