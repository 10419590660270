import styled from 'styled-components/macro';

export const AccordionWrapper = styled.div``;
export const AccordionItemStyle = styled.div`
    border: 1px solid var(--color-border-gray);
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 8px;
    background-color: var(--color-white);
`;
export const AccordionItemHeading = styled.div``;
export const AccordionItemButton = styled.div`
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.187rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    position: relative;
    padding: 20px;
`;
export const AccordionItemBody = styled.div`
    min-height: 1px;
    padding: 1.25rem;
    padding-top: 0;
`;
export const AccordionIcon = styled.div<{ opened: boolean }>`
    transform: ${(p) => (p.opened ? 'rotate(90deg)' : 'none')};
    display: flex;
    align-items: center;
    button {
        min-width: 20px;
    }
`;
