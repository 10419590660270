import styled from 'styled-components/macro';

export const Period = styled.div`
    font-size: 1rem;
    font-weight: 500;
`;

export const CompareText = styled.div`
    color: rgba(0, 0, 0, 0.3);
`;

export const Wrapper = styled.div`
    margin-bottom: var(--space-xxs);
`;

export const FilterMonitorWrapper = styled.div`
    margin-top: var(--space-xxs);
`;
