import { IMetric } from '../../../../../../General.interfaces';

export const addAreaMetricsFoo = (allMetrics: IMetric[]) => {
    const areaMetric: IMetric = {
        id: 'sum_area',
        text: 'Sum area',
        section: 'Common',
        isLocal: false,
        units: 'm\u00b2',
        round_decimal_places: 0,
        optional_metric_params: {},
        available_for_permissions: [],
        aggregation_objects: 'sum',
        aggregation_time: 'sum',
    };
    return [areaMetric, ...allMetrics];
};
