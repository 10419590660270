import {
    Leasing_Tenants_Tenants_Widget_Reducer_Values,
    reloadWidget,
    storeTableItemsToShow,
    storeTableSortData,
} from './reducer';
import { memo, useMemo, useRef } from 'react';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { useSelector } from 'react-redux';

import FiltersMonitor from '../../../../../components/Filters/FiltersMonitor/FiltersMonitor';
import { IReportingObject } from '../../../../../General.interfaces';
import { ITableSortData } from './interfaces';
import Table from './components/Table/Table';
import { TableWrapper } from './styles';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import Footer from './components/Footer/Footer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

/**
 * Компонент для отображения виджета Leasing:Tenants
 */
const TenantsWidget = memo(() => {
    const sendAnalytics = useSendSimpleAnalytics();
    const { extendedTenants, tableSortData, extendedTenantsById, tableItemsToShow, moduleName } = useSelector(
        Leasing_Tenants_Tenants_Widget_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        allMetrics,
        cfg: { tenant2ZoneByTenantId, tenant2FloorByTenantId, reportingObjectsById },
        src: { dataObj2ProjectCategory },
        sendYaAnalytics,
    } = useSelector(generalReducerValues);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ref = useRef(null);

    /** Открытие других виджетов для выбранного арендатора */
    const openAdditionalTenantInformation = (data: {
        module: 'Leasing:Tenant overview' | 'Performance:Year over year';
        tenantData: IReportingObject;
        path: string;
        selectedMetrics: string[];
    }) => {
        sendAnalytics(`navigate_tenant_to_${data.module}`);
        if (localCurrentOptions?.selectedLocationId) {
            dispatch(
                changeOptions({
                    newOptions: {
                        tenants: [{ id: data.tenantData.id, text: data.tenantData.name }],
                        selectedReportingObjectsIds: [data.tenantData.id],
                        selectedMetrics: data.selectedMetrics,
                    },
                    currentModuleID: data.module,
                }),
            );
        }
        navigate(data.path);
    };

    const storeTableSortDataTrans = (data: null | ITableSortData) => {
        dispatch(storeTableSortData(data));
    };

    const storeTableItemsToShowTrans = (value: number | 'all') => {
        dispatch(storeTableItemsToShow(value));
    };

    const table = useMemo(() => {
        if (
            extendedTenants &&
            extendedTenantsById &&
            localCurrentOptions?.selectedMetrics &&
            Object.keys(reportingObjectsById).length
        ) {
            return (
                <Table
                    openAdditionalTenantInformation={openAdditionalTenantInformation}
                    selectedMetricsIds={localCurrentOptions.selectedMetrics}
                    mainDateRanges={localCurrentOptions.mainDateRanges}
                    storeTableSortDataTrans={storeTableSortDataTrans}
                    dataObj2ProjectCategory={dataObj2ProjectCategory}
                    tenant2FloorByTenantId={tenant2FloorByTenantId}
                    filters={localCurrentOptions['filters'] || []}
                    tenant2ZoneByTenantId={tenant2ZoneByTenantId}
                    reportingObjectsById={reportingObjectsById}
                    mainPeriod={localCurrentOptions.mainPeriod}
                    extendedTenantsById={extendedTenantsById}
                    tableItemsToShow={tableItemsToShow}
                    extendedTenants={extendedTenants}
                    tableSortData={tableSortData}
                    allMetrics={allMetrics}
                    moduleName={moduleName}
                />
            );
        }
        return null;
    }, [
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.['filters'],
        dataObj2ProjectCategory,
        reportingObjectsById,
        extendedTenantsById,
        tableItemsToShow,
        extendedTenants,
        tableSortData,
        moduleName,
    ]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Tenants')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        reloadHandler={reloadWidget}
                        widgetName={'Tenants'}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <FiltersMonitor />
            <TableWrapper>{table}</TableWrapper>
            {Boolean(extendedTenants && extendedTenantsById) && (
                <Footer testId={'Tenants'} storeTableItemsToShowTrans={storeTableItemsToShowTrans} itemsToShow={tableItemsToShow} />
            )}
        </WidgetWrapper>
    );
});

export default TenantsWidget;
