import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingWrapper } from './styles';
import { generalReducerValues } from '../../General.reducer';
import { useSelector } from 'react-redux';
import Spinner from '../Spinner/Spinner';

const LoadingBox: React.FC<{
    height?: number | string;
    hasBorder?: boolean;
    text?: string;
    isError?: boolean;
    noData?: boolean;
}> = ({ height, text, hasBorder, isError = false, noData = false }) => {
    const { domain } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    return (
        <LoadingWrapper height={height} hasBorder={hasBorder}>
            {!isError && !noData && (
                <Spinner size={'large'} labelPosition="below" label={t(text || '')} type={domain} />
            )}
            {isError && <span>{t('Network Error')}</span>}
            {noData && <span>{t('No data')}</span>}
            {text && (isError || noData) && <span>{t(text)}</span>}
        </LoadingWrapper>
    );
};

export default LoadingBox;
