import React, { useEffect, useState } from 'react';

import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import RatingListWidget from './widgets/RatingList/RatingListWidget';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import { changeOptions } from '../../../components/UserSettings/reducer';
import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import { ButtonWrapper } from './widgets/RatingList/style';
import Button from '../../../components/Button/Button';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import Wrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { Performance_Ratings_RatingList_Widget_Reducer_Values, storeEditRatingId } from './widgets/RatingList/reducer';
import { useTranslation } from 'react-i18next';
import { getReportingObjects } from '../../../tools/getReportingObjects';
import ModalNewRating from './widgets/RatingList/components/ModalNewRating/ModalNewRating';
import Modals from '../../../components/Modals/Modals';

const RatingsQuery: React.FC = () => {
    const {
        cfg: { reportingObjectsByType },
        currentModuleID,
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const [modalStatus, setModalStatus] = useState({ show: false });
    const { t } = useTranslation();
    const objectBytype = getReportingObjects(reportingObjectsByType, t);
    const { editRatingId } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);
    useEffect(() => {
        if (editRatingId) {
            setModalStatus({ show: true });
        }
    }, [editRatingId]);

    const buttonClick = () => {
        dispatch(storeEditRatingId(null));
        setModalStatus({ show: true });
    };
    const handleModalClose = () => {
        setModalStatus({ show: false });
        dispatch(storeEditRatingId(null));
    };

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <Wrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ComparePeriodSelect
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </Wrapper>
                    <ButtonWrapper>
                        <Button appearance="primary" onClick={buttonClick} disabled={objectBytype.length === 0}>
                            {t('New rating')}
                        </Button>
                    </ButtonWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <RatingListWidget />
            <Modals
                title={t(editRatingId ? 'Rating editing' : 'New rating adding')}
                closeModal={handleModalClose}
                modalStatus={modalStatus}
                topColor="fff"
            >
                <ModalNewRating closeModal={handleModalClose}></ModalNewRating>
            </Modals>
        </ModuleWrapper>
    );
};

export default RatingsQuery;
