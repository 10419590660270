import { FC } from 'react';
import { IProps } from './interfaces';
import { Th, Tr } from '../../styles';
import useObjectTranslation from '../../../../../../../hooks/useObjectTranslation';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import Icon from '../../../../../../Icon/Icon';
import Stack from '../../../../../../Stack/Stack';

/**
 * Шапка таблицы
 */
const Header: FC<IProps> = (props) => {
    const { lang } = useSelector(generalReducerValues);
    const { getObjName } = useObjectTranslation();

    return (
        <thead>
            <Tr>
                <Th fontSize={'16px'} width={'210px'}>
                    {getObjName(props.reportingObject, true)}
                </Th>
                <Th fontWeight={'600'} whiteSpace={'nowrap'}>
                    {shortStringDate(props.mainPeriod, lang)}
                </Th>
                {props.comparePeriods.map((comparePeriod, i) => {
                    return (
                        <Th
                            key={JSON.stringify(comparePeriod) + i}
                            whiteSpace={'nowrap'}
                            fontWeight={'600'}
                            colSpan={2}
                        >
                            <Stack alignItems={'center'} gap={10}>
                                <Icon type="compare" />
                                {shortStringDate(comparePeriod, lang)}
                            </Stack>
                        </Th>
                    );
                })}
            </Tr>
        </thead>
    );
};

export default Header;
