import { RefObject, useEffect, useState } from 'react';

export enum ScrollDirection {
    up = 'UP',
    down = 'DOWN',
}

interface IArgs {
    ref?: RefObject<HTMLDivElement>;
    threshold?: number;
}

export const useScrollDirection = ({ ref, threshold = 100 }: IArgs) => {
    const [scrollDir, setScrollDir] = useState(ScrollDirection.up);

    useEffect(() => {
        const refCurrent = ref?.current;
        let previousScrollYPosition = refCurrent?.scrollTop ?? 0;
        const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
            Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

        const isScrollingUp = (currentScrollYPosition: number) =>
            currentScrollYPosition > previousScrollYPosition &&
            !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
            !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

        const updateScrollDirection = () => {
            const currentScrollYPosition = refCurrent?.scrollTop ?? 0;

            if (scrolledMoreThanThreshold(currentScrollYPosition)) {
                const newScrollDirection = isScrollingUp(currentScrollYPosition)
                    ? ScrollDirection.down
                    : ScrollDirection.up;
                setScrollDir(newScrollDirection);
                previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
            }
        };

        const onScroll = () => {
            window.requestAnimationFrame(updateScrollDirection);
        };

        refCurrent?.addEventListener('scroll', onScroll);

        return () => refCurrent?.removeEventListener('scroll', onScroll);
    }, [ref, threshold]);

    return scrollDir;
};
