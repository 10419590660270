import styled from 'styled-components/macro';

export const SelectsWrapper = styled.div`
    width: 80%;
    display: flex;
    position: relative;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: var(--space-xxxs);
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        width: 100%;
        div {
            max-width: 130px;
        }
    }
`;
