import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReducerState } from './interfaces';
import { RootState } from '../../../store';

const initialState: IReducerState = {
    moduleName: 'Reports:Analytical',
    tab: 'basic',
};

const Reports_Analytical_Module_Reducer = createSlice({
    name: 'Reports_Analytical_Module_Reducer',
    initialState,
    reducers: {
        toggleTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },
    },
});

export const { toggleTab } = Reports_Analytical_Module_Reducer.actions;

export const Reports_Analytical_Module_Reducer_Values = (state: RootState) => state.Reports_Analytical_Module_Reducer;

export default Reports_Analytical_Module_Reducer.reducer;
