import { IExtendedOption, IUsePrepareDataArgs, IUsePrepareDataResponse } from './interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Кастомный зук для подготовки данных
 */
const usePrepareData = (args: IUsePrepareDataArgs): IUsePrepareDataResponse => {
    const [seriesSelectOptions, setSeriesSelectOptions] = useState<IExtendedOption[]>([]);
    const { t } = useTranslation();
    const { series } = args;

    /** Полуцчений опция селектов */
    useEffect(() => {
        if (series.length) {
            const options = series
                ?.filter((element) => !element['additionalSeriesId'])
                .map((element) => ({
                    id: `${element.id}`,
                    text: element.name || (t('This should be the name of the series') as string),
                    seriesData: element,
                }));

            setSeriesSelectOptions(options);
        }
    }, [series]);

    return { seriesSelectOptions };
};

export default usePrepareData;
