import { useTranslation } from 'react-i18next';
import { ItemWrapper, Label, Wrapper, Text } from './styles';
import TextInput from '../../../../../../../../../components/TextInput/TextInput';

/**
 * Тело контекстного меню для отображения формата csv для пакетной загрузски событий
 */
const CsvFormatContextualMenuBody = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <ItemWrapper>
                <Text>
                    <span>{t('Csv header')}:</span>name;date_from;date_to;event_type;data_objects_ids;comment
                </Text>
                <Text>
                    <span>{t('Csv row example')}:</span>Event name;2022-01-01T00:00:00;2022-01-02T00:00:00;Event
                    type;100,234,356;Optional comment
                </Text>
                <ItemWrapper>
                    <Label>{t('Final string example')}</Label>
                    <TextInput
                        isMultiLine={true}
                        fullWidth={true}
                        outsideValue={`name;date_from;date_to;event_type;data_objects_ids;comment\nEvent name;2022-01-01T00:00:00;2022-01-02T00:00:00;Eventtype;100,234,356;Optional comment\nEvent name without comment;2022-01-02T00:00:00;2022-01-03T00:00:00;Event type 2;101,235,56;`}
                    />
                </ItemWrapper>
            </ItemWrapper>
        </Wrapper>
    );
};

export default CsvFormatContextualMenuBody;
