import {
    storeExtendedReportingObjects,
    storeIdsOfReportingObjects,
    Events_Events_TableOfObjects_Widget_Reducer_Values,
    storeSelectedComparePeriod,
    storeAvailableComparePeriods,
} from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../../General.reducer';
import generateExtendedTenants from '../tools/generateExtendedReportingObjects';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IComparePeriod } from '../interfaces';
import { stringDate } from '../../../../../../tools/Strings/stringDate';
import { Events_Events_Module_Reducer_Values } from '../../../reducer';

/** Кастомный хук для подготовки данных*/
const usePrepareData = () => {
    const { moduleName } = useSelector(Events_Events_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        lang,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { rawMetricsData, selectedObjectsType, selectedComparePeriod } = useSelector(
        Events_Events_TableOfObjects_Widget_Reducer_Values,
    );
    const dispatch = useDispatch();

    /** Получение актуального периода сравнения */
    useEffect(() => {
        if (localCurrentOptions?.compareDateRanges && localCurrentOptions?.comparePeriods?.length) {
            const availablePeriods = localCurrentOptions.comparePeriods.reduce((acc: IComparePeriod[], value) => {
                const availablePeriod = localCurrentOptions?.compareDateRanges?.find(
                    (element) => element.id === value.id,
                );
                if (availablePeriod) {
                    acc.push({
                        id: availablePeriod.id,
                        text: stringDate(availablePeriod?.period, lang, '', 'dd MMM yyyy'),
                        period: availablePeriod?.period,
                    });
                }
                return acc;
            }, []);

            dispatch(storeAvailableComparePeriods(availablePeriods));

            const currentPeriod = availablePeriods?.find(
                (element) =>
                    element?.period.dateFrom === selectedComparePeriod?.period.dateFrom &&
                    element?.period.dateTo === selectedComparePeriod?.period.dateTo,
            );

            if (availablePeriods.length) {
                if (!currentPeriod || !selectedComparePeriod) {
                    dispatch(storeSelectedComparePeriod(availablePeriods[0]));
                }
            }
        }
    }, [localCurrentOptions?.compareDateRanges, localCurrentOptions?.comparePeriods, selectedComparePeriod, lang]);

    /** Запись всех id  отчетных объектов выбранного типа */
    useEffect(() => {
        dispatch(storeExtendedReportingObjects(null));
        dispatch(storeIdsOfReportingObjects(null));
        if (Object.keys(reportingObjectsByType).length && selectedObjectsType) {
            const ids = reportingObjectsByType?.[selectedObjectsType]?.map((element) => element.id);
            dispatch(storeIdsOfReportingObjects(ids));
        }
    }, [reportingObjectsByType, selectedObjectsType]);

    /** Получение расширенной информации о арендаторе */
    useEffect(() => {
        if (
            Object.keys(reportingObjectsByType).length &&
            localCurrentOptions?.selectedMetrics &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            selectedObjectsType &&
            reportingObjectsByType?.[selectedObjectsType] &&
            selectedComparePeriod &&
            rawMetricsData
        ) {
            const extendedReportingObjects = generateExtendedTenants({
                selectedComparePeriod,
                selectedMetrics: localCurrentOptions.selectedMetrics,
                mainDateRanges: localCurrentOptions.mainDateRanges,
                mainPeriod: localCurrentOptions.mainPeriod,
                reportingObjects: reportingObjectsByType[selectedObjectsType],
                rawMetricsData,
            });
            dispatch(storeExtendedReportingObjects(extendedReportingObjects));
        }
    }, [
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.mainPeriod,
        selectedObjectsType,
        selectedComparePeriod,
        rawMetricsData,
    ]);
};

export default usePrepareData;
