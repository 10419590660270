import { useSelector } from 'react-redux';
import { cabinetPreferencesValues } from '../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { IDataAdapterResponse, IProps } from '../interfaces';
import { useRequestMetrics } from '../../../../tools/API/hooks/useRequestMetrics';
import { useQuery } from '@tanstack/react-query';
import { TMetricResponse } from '../../../../General.interfaces';
import { useMemo } from 'react';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../tools/API/dataAccessors/metricsDataAccessor';
import { IRequestMetricsArgs } from '../../../../tools/API/interfaces';
import { STACKED_BAR_CHART_BY_OBJECTS_QUERY_KEY } from '../constants/constants';
import defaultDataAdapter from './dataAdapter';
import { omit } from 'lodash';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';

interface IArgs extends IProps {}

const useFetchData = (args: IArgs) => {
    const { token, allMetrics } = useSelector(generalReducerValues);

    const fetchData = useRequestMetrics({ isNetwork: Boolean(args.isNetwork) });

    const { t } = useTranslation(['metrics', 'translation']);

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        if (token) {
            const requestArgs: IRequestMetricsArgs[] = [];

            const objIds: number[] = args.reportingObjects.map((item) => item.id);

            requestArgs.push({
                time_range: [args.mainPeriod.dateFrom, args.mainPeriod.dateTo],
                object_aggregation: false,
                metric: args.metric,
                obj_ids: objIds,
                time_freq: null,
                alias: null,
                token,
            });

            const dataAccessorArgs: IMetricsDAArgs = {
                requestArgs,
                fetchData,
            };

            return {
                queryKey: [STACKED_BAR_CHART_BY_OBJECTS_QUERY_KEY, args.widgetId, args.metric, args.mainPeriod, objIds],
                queryFn: () => metricsDataAccessor(dataAccessorArgs),
                dataAdapter: (response: TMetricResponse[]) =>
                    defaultDataAdapter({
                        response,
                        allMetrics,
                        t,
                        ...omit(args, ['metric', 'isNetwork', 'mainPeriod']),
                    }),
            };
        }
        return {};
    }, [args, fetchData, token, allMetrics, t]);

    return useQuery<TMetricResponse[], unknown, IDataAdapterResponse, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;
