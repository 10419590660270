import { IMetric, TMetricResponse } from '../../../../../../../General.interfaces';
import { DS } from '../../../../../../../constants/constants';
import getDifferenceBetweenNumbers from '../../../../../../../tools/getDifferenceBetweenNumbers';
import { IObjectRatingData, ISelectedObjectDAResponse } from '../../interfaces';

interface IArgs {
    /** Ответ от сервера */
    response: TMetricResponse[];
    /** Все метрики, по которым были получены данные */
    metrics: string[];
    /** Все метрики из хранилища */
    allMetrics: IMetric[];
}

const selectedObjectDataAdapter = (args: IArgs): ISelectedObjectDAResponse => {
    const objectData: IObjectRatingData[] = args.metrics.map((metric) => {
        const metricFromStore = args.allMetrics.find((item) => item.id === metric);

        const mainPeriodValue = args.response.find((metricResponse) => {
            const alias = metricResponse[0]?.context.alias;
            return alias === `main${DS}${metric}`;
        })?.[0]?.items?.[0]?.value;

        const comparePeriodValue = args.response.find((metricResponse) => {
            const alias = metricResponse[0]?.context.alias;
            return alias === `compare${DS}${metric}`;
        })?.[0]?.items?.[0]?.value;

        const colorValue = Number(
            getDifferenceBetweenNumbers(Number(mainPeriodValue), Number(comparePeriodValue)).percentDifference,
        );

        return {
            name: metricFromStore?.text || metric,
            colorValue,
        };
    });

    return {
        objectData,
    };
};

export default selectedObjectDataAdapter;
