import { TFunction } from 'i18next';
import { AdditionalFieldsIds } from '../../../../interfaces';

export interface IIntersectionsReportAddFieldOption {
    id: AdditionalFieldsIds;
    text: string;
    columnName?: string;
}

export const intersectionsReportAdditionalFieldsOptions: IIntersectionsReportAddFieldOption[] = [
    {
        id: AdditionalFieldsIds.AddId,
        text: 'Add id',
    },
    { id: AdditionalFieldsIds.ShowEmpty, text: 'Show empty rows' },
];

export const generateIntersectionsReportAddFields = (t: TFunction): IIntersectionsReportAddFieldOption[] => {
    return intersectionsReportAdditionalFieldsOptions.map((element) => ({
        ...element,
        text: element.text,
        columnName: t(element.columnName || ''),
    }));
};
