import { TMetricResponse } from '../../../../General.interfaces';
import { DS } from '../../../../constants/constants';

export interface IAggregatedMetricDataByPeriodAndMetric {
    mainPeriod: {
        [date: string]: {
            [metric: string]: TMetricResponse;
        };
    };
    comparePeriod: {
        [date: string]: {
            [metric: string]: TMetricResponse;
        };
    };
}

const aggregateMetricResponseByPeriodAndMetric = (
    response: TMetricResponse[],
): IAggregatedMetricDataByPeriodAndMetric => {
    if (Array.isArray(response)) {
        return response.reduce(
            (acc, value) => {
                if (value.length) {
                    value.forEach((element) => {
                        const { alias, time_range, metric } = element.context;

                        if (alias) {
                            const slittedAlias = alias.split(DS);
                            const periodType = slittedAlias[0] as 'mainPeriod' | 'comparePeriod';
                            const dateRangeKey = `${time_range[0]}${DS}${time_range[1]}`;

                            if (periodType === 'mainPeriod') {
                                if (!acc.mainPeriod[dateRangeKey]) {
                                    acc.mainPeriod[dateRangeKey] = {};
                                }

                                if (!acc.mainPeriod[dateRangeKey][metric]) {
                                    acc.mainPeriod[dateRangeKey][metric] = [];
                                }
                                acc.mainPeriod[dateRangeKey][metric].push(element);
                            }

                            if (periodType === 'comparePeriod') {
                                if (!acc.comparePeriod[dateRangeKey]) {
                                    acc.comparePeriod[dateRangeKey] = {};
                                }
                                if (!acc.comparePeriod[dateRangeKey][metric]) {
                                    acc.comparePeriod[dateRangeKey][metric] = [];
                                }
                                acc.comparePeriod[dateRangeKey][metric].push(element);
                            }
                        }
                    });
                }

                return acc;
            },
            { mainPeriod: {}, comparePeriod: {} },
        );
    }
    return { mainPeriod: {}, comparePeriod: {} };
};

export default aggregateMetricResponseByPeriodAndMetric;
