import { memo, FC, useState } from 'react';
import { IProps } from './interfaces';
import { StyledTable, Wrapper } from './styles';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import { TOrdering } from '../../../../../OrderSwitcher/interfaces';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';

/**
 * Виджет таблицы с данными по метрикам для отчетных объектов
 */
const TableObjectsByMetrics: FC<IProps> = (props) => {
    const [orderingData, setOrderingData] = useState<null | { ordering: TOrdering; columnId: string }>(null);

    const sortedComparePeriods = sortBy(props.comparePeriods, (period) =>
        DateTime.fromISO(period.dateTo).toMillis(),
    ).reverse();

    const handleOrderToggle = (data: { ordering: TOrdering; id: string }) => {
        setOrderingData({ ordering: data.ordering, columnId: data.id });
    };

    return (
        <Wrapper>
            <StyledTable>
                <Header
                    {...props}
                    comparePeriods={sortedComparePeriods}
                    handleOrderToggle={handleOrderToggle}
                    orderingData={orderingData}
                />
                <Body {...props} comparePeriods={sortedComparePeriods} orderingData={orderingData} />
            </StyledTable>
        </Wrapper>
    );
};

export default memo(TableObjectsByMetrics);
