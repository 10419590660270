import { FC } from 'react';
import NumberToShow from '../../../../../../../components/NumberToShow/NumberToShow';
import { IProps } from './interfaces';
import { NumberToShowTitle, Wrapper } from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для отображения подвала виджета
 */
const Footer: FC<IProps> = ({ setItemsToShow, itemsToShow, testId }) => {
    const { t } = useTranslation();

    const handleSelect = (value: number | 'all') => {
        setItemsToShow(value);
    };

    return (
        <Wrapper>
            <NumberToShowTitle>{t('Show first')}</NumberToShowTitle>
            <NumberToShow testId={testId} handleSelect={handleSelect} defaultSelected={itemsToShow} />
        </Wrapper>
    );
};

export default Footer;
