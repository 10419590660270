import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import { useSelector } from 'react-redux';
import { OptionsWrapper, WidgetsWrapper } from './styles/styles';
import { Dashboard_General_Module_Reducer_Values } from './reducer';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import './styles/styles.scss';
import DashboardLayout from '../../../components/DashboardsCommon/DashboardLayout/DashboardLayout';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import TabList from '../../../components/TabList/TabList';
import { IUpdateWidgetPayload, TLayouts } from '../../../components/DashboardsCommon/interfaces';
import { Dashboards_Reducer_Values, storeSelectedKey, updateLayout, updateWidget } from '../../../Dashboards.reducer';
import Modals from '../../../components/Modals/Modals';
import WidgetsManager from '../../../components/DashboardsCommon/WidgetsManager/WidgetsManager';
import _, { isNull } from 'lodash';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import Stack from '../../../components/Stack/Stack';
import AddNewBody from '../../../components/DashboardsCommon/AddNewBody/AddNewBody';
import EditBody from '../../../components/DashboardsCommon/EditBody/EditBody';
import useObjectTranslation from '../../../hooks/useObjectTranslation';
import WidgetTitle from '../../../components/Wrappers/WidgetTitle/WidgetTitle';
import PanelsManager from '../../../components/DashboardsCommon/PanelsManager/PanelsManager';
import useRequiredSelects from '../../../components/DashboardsCommon/hooks/useRequiredSelects';
import useGetAvailableWidgets from '../../../components/DashboardsCommon/WidgetsManager/hooks/useGetAvailableWidgets';
import { widgetTypes } from './widgetsConfig';
import { IMenuPermissions } from '../../../components/DashboardsCommon/PanelsManager/interfaces';
import { PeopleCommunity24Filled, Person24Filled, LockClosed24Filled } from '@fluentui/react-icons';
import { Item } from '../components/DeleteBody/styles';
import { DateTime } from 'luxon';
import ModuleWrapper from 'src/components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from 'src/components/Wrappers/WrapperContainer/WrapperContainer';

/**
 * Компонент для отображения модуля "Dashboard designer:Object panels"
 */
const ObjectPanels: React.FC = () => {
    const { moduleId } = useSelector(Dashboard_General_Module_Reducer_Values);
    const { userDashboards, defaultDashboards, selectedKey: selectedKeys } = useSelector(Dashboards_Reducer_Values);
    const [manageModalStatus, setManageModalStatus] = useState({ show: false });
    const [addNewModalStatus, setAddNewStatus] = useState({ show: false });
    const [editStatus, setEditStatus] = useState({ show: false });
    const {
        currentModuleID,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const selectedKey = selectedKeys[currentModuleID] ?? null;

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const requiredSelects = useRequiredSelects(userDashboards, selectedKey);
    const availableWidgets = useGetAvailableWidgets(widgetTypes);

    const ref = useRef<HTMLDivElement | null>(null);

    const { getObjName } = useObjectTranslation();

    const { t } = useTranslation();

    const layouts = useMemo(() => {
        if (userDashboards.length && selectedKey) {
            return (
                userDashboards.find(
                    (dashboard) => dashboard.module_id === currentModuleID && dashboard.dashboard_key === selectedKey,
                )?.layouts || null
            );
        }
        return null;
    }, [userDashboards, selectedKey, currentModuleID]);

    const tabListItems = useMemo(() => {
        const result = userDashboards
            .filter((item) => item.module_id === currentModuleID)
            .sort(
                (a, b) =>
                    DateTime.fromISO(b.created_at || '').toMillis() - DateTime.fromISO(a.created_at || '').toMillis(),
            )
            .map((item) => {
                const text =
                    item.dashboard_key.includes('default') || item.dashboard_key.includes('project')
                        ? t(item.dashboard_name)
                        : item.dashboard_name;
                const tooltip =
                    item.dashboard_key.includes('default') || item.dashboard_key.includes('project')
                        ? t('Default panel')
                        : t('Custom panel');
                const icon =
                    item.dashboard_key.includes('default') || item.dashboard_key.includes('project') ? (
                        <LockClosed24Filled />
                    ) : (
                        <Person24Filled />
                    );
                return { id: item.dashboard_key, text, icon, tooltip };
            });
        return result;
    }, [currentModuleID, t, userDashboards]);

    /**
     * Сохранение выбранного таба
     */

    useEffect(() => {
        if (tabListItems.length && !selectedKey) {
            dispatch(storeSelectedKey({ [currentModuleID]: tabListItems[tabListItems.length - 1].id }));
        }
    }, [currentModuleID, dispatch, selectedKey, tabListItems]);

    const selectedObjectText: null | string = useMemo(() => {
        const selectedReportingObjectId = localCurrentOptions?.['mainObject']?.[0];

        if (selectedReportingObjectId && reportingObjectsById?.[selectedReportingObjectId]) {
            return getObjName(reportingObjectsById[selectedReportingObjectId], true);
        }
        return null;
    }, [getObjName, localCurrentOptions?.['mainObject'], reportingObjectsById]);

    const tabListSelectHandler = (tabId: string) => {
        dispatch(storeSelectedKey({ [currentModuleID]: tabId }));
    };

    const changeUserLayoutsTrans = (layouts: TLayouts) => {
        if (selectedKey) {
            dispatch(
                updateLayout({
                    dashboardKey: selectedKey,
                    moduleId: currentModuleID,
                    layouts,
                }),
            );
        }
    };
    const handleManageModalClose = () => {
        setManageModalStatus({ show: false });
    };

    const updateWidgetTrans = (data: IUpdateWidgetPayload) => {
        dispatch(updateWidget(data));
    };

    const handleAddNewModalClose = () => {
        setAddNewStatus({ show: false });
    };
    const handleEditClose = () => {
        setEditStatus({ show: false });
    };

    var saveAsShared = selectedKey !== null && !selectedKey.includes('project') && !selectedKey.includes('default');

    const menuPermissions: IMenuPermissions = {
        addWidgets: true,
        addNewPanel: true,
        editPanels: true,
        moveMode: true,
        saveAsShared,
        resetToDefault: true,
    };

    const style: React.CSSProperties = { maxWidth: 'none' };

    return (
        <div ref={ref}>
            <Modals title={t('Add widget')} closeModal={handleManageModalClose} modalStatus={manageModalStatus}>
                <WidgetsManager
                    userDashboards={userDashboards}
                    closeModal={handleManageModalClose}
                    availableWidgets={availableWidgets}
                    selectedKey={selectedKey}
                />
            </Modals>
            <Modals title={t('Add new panel')} closeModal={handleAddNewModalClose} modalStatus={addNewModalStatus}>
                <AddNewBody
                    defaultDashboards={defaultDashboards}
                    closeModal={handleAddNewModalClose}
                    selectedKey={selectedKey}
                />
            </Modals>
            <Modals title={t('Edit panels')} closeModal={handleEditClose} modalStatus={editStatus}>
                <EditBody userDashboards={userDashboards} closeModal={handleEditClose} />
            </Modals>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        {requiredSelects.includes('mainPeriod') && (
                            <MainPeriodSelect
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                            />
                        )}
                        {requiredSelects.includes('comparePeriods') && (
                            <ComparePeriodSelect
                                mode="single"
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                            />
                        )}
                        {requiredSelects.includes('mainObject') && (
                            <ObjectsSelect
                                name={'mainObject'}
                                label={t('Main object')}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                                localCurrentOptions={localCurrentOptions}
                                dataObjectFilters={[
                                    'data_objects_tenant',
                                    'data_objects_floor',
                                    'data_objects_passway_type',
                                ]}
                                maxSelected={1}
                                minSelected={1}
                            />
                        )}
                        {requiredSelects.includes('comparisonObjects') && (
                            <ObjectsSelect
                                name={'comparisonObjects'}
                                label={t('Comparison objects')}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                                localCurrentOptions={localCurrentOptions}
                                dataObjectFilters={[
                                    'data_objects_tenant',
                                    'data_objects_floor',
                                    'data_objects_passway_type',
                                ]}
                                maxSelected={10}
                                minSelected={1}
                            />
                        )}
                    </SelectsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer style={style}>
                <WidgetsWrapper>
                    <Stack alignItems="center" justifyContent="space-between">
                        {selectedObjectText && <WidgetTitle>{selectedObjectText}</WidgetTitle>}

                        <Stack
                            className="general_tab-list__wrapper"
                            alignItems="center"
                            justifyContent="flex-end"
                            gap={10}
                        >
                            {selectedKey && (
                                <TabList
                                    selectHandler={tabListSelectHandler}
                                    selectedValue={selectedKey}
                                    options={tabListItems}
                                    addComponent={
                                        <PanelsManager
                                            menuPermissions={menuPermissions}
                                            selectedKey={selectedKey}
                                            userDashboards={userDashboards}
                                            setAddNewStatus={setAddNewStatus}
                                            setEditStatus={setEditStatus}
                                            setManageModalStatus={setManageModalStatus}
                                            panelRef={ref}
                                        />
                                    }
                                />
                            )}
                        </Stack>
                    </Stack>
                    {!isNull(selectedKey) && (
                        <DashboardLayout
                            userDashboards={userDashboards}
                            dashboardKey={selectedKey}
                            moduleId={currentModuleID}
                            layouts={layouts}
                            changeLayouts={changeUserLayoutsTrans}
                            updateWidgetTrans={updateWidgetTrans}
                        />
                    )}
                </WidgetsWrapper>
            </WrapperContainer>
        </div>
    );
};

export default ObjectPanels;
