import { TFunction } from 'i18next';
import { IMetric, IMetricResponseItem, IReportingObject, TMetricResponse } from '../../../../../../General.interfaces';
import { ICell, IDataAdapterResponse, IViewSettings } from '../interfaces';
import { isNumber, sortBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { IDynamicsProps } from '../../../../../../components/Charts/Dynamics/interfaces';
import { DS } from '../../../../../../constants/constants';
import { AxisLabelsFormatterContextObject } from 'highcharts';
import { stringDate } from '../../../../../../tools/Strings/stringDate';
import { getObjectName } from '../../../../../../hooks/useObjectTranslation';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import TableCompareValue from '../components/TableCompareValue/TableCompareValue';

interface IArgs {
    /** Ответ от сервера */
    response: TMetricResponse[];
    /** Все метрики */
    allMetrics: IMetric[];
    /** Объект отчетных объектов, где ключ - это ID отчетного объекта */
    reportingObjectsById: { [id: string]: IReportingObject };
    /** Настройки отображения */
    viewSettings?: IViewSettings;
    /** Функция перевода */
    t: TFunction;
}

const dataAdapter = (args: IArgs): IDataAdapterResponse => {
    const chartOptionsByReportingObjectId: { [id: string]: IDynamicsProps } = {};
    const metricsByReportingObjectId: { [id: string]: IMetricResponseItem[] } = {};
    const tablesByReportingObjectId: { [id: string]: ICell[][] } = {};

    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return args.t(stringDate(DateTime.fromMillis(this.value as number).toISO(), 'en', '', 'MMMM'));
    };

    const metricFromStore = args.allMetrics.find((item) => item.id === args.response[0]?.[0]?.context.metric);

    args.response.forEach((metricResponse) => {
        metricResponse.forEach((metricResponseItem) => {
            const dataObjectId = metricResponseItem.context.data_objects[0].id;

            if (!chartOptionsByReportingObjectId[dataObjectId]) {
                chartOptionsByReportingObjectId[dataObjectId] = {
                    series: [],
                    tooltipTitle: args.t('Periods'),
                    tooltipSubTitle: metricFromStore
                        ? `${args.t(metricFromStore.text)}, ${args.t(metricFromStore.units)}`
                        : metricResponseItem.context.metric,
                    xAxisLabelsFormating,
                    numberOfSeriesToDispaly: null,
                };
            }

            if (!metricsByReportingObjectId[dataObjectId]) {
                metricsByReportingObjectId[dataObjectId] = [];
            }

            metricsByReportingObjectId[dataObjectId].push(metricResponseItem);

            const data = metricResponseItem.items.map((item) => {
                return {
                    y: isNumber(item.value) ? Number(item.value.toFixed(2)) : item.value,
                    name: `${args.t(stringDate(item.time, 'en', '', 'MMMM'))} ${args.t(
                        stringDate(item.time, 'en', '', 'yyyy'),
                    )}`,
                    x: DateTime.fromISO(item.time).toMillis(),
                    units: metricFromStore?.units,
                };
            });

            chartOptionsByReportingObjectId[dataObjectId].series.push({
                name: DateTime.fromISO(metricResponseItem.context.time_range[0]).year.toString(),
                id: `${dataObjectId}${DS}${metricResponseItem.context.time_range[0]}`,
                type: 'column',
                isMain: false,
                data,
            });
        });
    });

    Object.entries(metricsByReportingObjectId).forEach(([key, value]) => {
        const table: ICell[][] = [];

        const sortedMetrics = sortBy(value, (metricResponseItem) => {
            return DateTime.fromISO(metricResponseItem.context.time_range[0]).year;
        });

        const reportingObjectNameRow: ICell[] = [
            {
                colSpan: metricsByReportingObjectId[key].length * 2,
                isHeader: true,
                value: `${getObjectName({
                    reportingObject:
                        args.reportingObjectsById[value[0].context.data_objects[0].id] ||
                        value[0].context.data_objects[0],
                    showType: true,
                    addFloor: true,
                    t: args.t,
                })}\n${args.t(metricFromStore?.text || '')}, ${args.t(metricFromStore?.units || '')}`,
                readOnly: true,
                className: 'dynamics-by-months_table_title',
            },
        ];
        table.push(reportingObjectNameRow);

        const yearsRow: ICell[] = [
            { value: '' },
            ...sortedMetrics.map((metricResponseItem) => {
                const year = DateTime.fromISO(metricResponseItem.context.time_range[0]).year;
                return {
                    value: year.toString(),
                    readOnly: true,
                    className: 'dynamics-by-months_table_years',
                };
            }),
            ...sortedMetrics.slice(1).map((metricResponseItem, index) => {
                const currentYear = DateTime.fromISO(metricResponseItem.context.time_range[0])?.year;
                const compareYear = DateTime.fromISO(sortedMetrics[index].context.time_range[0])?.year || '';

                return {
                    value: `${currentYear} / ${compareYear}`,
                    readOnly: true,
                    className: 'dynamics-by-months_table_years',
                };
            }),
        ];
        table.push(yearsRow);

        const totalRow: ICell[] = [
            {
                value: args.t('Total'),
                className: 'dynamics-by-months_table_total',
                style: { fontWeight: '500' },
            },
            ...sortedMetrics.map((metricResponseItem) => {
                return {
                    value: valueFormatter({
                        value: metricResponseItem.items.every((item) => item.value === null)
                            ? null
                            : sumBy(metricResponseItem.items, 'value'),
                        precision: metricFromStore?.round_decimal_places,
                        units: metricFromStore?.units,
                        showUnits: false,
                    }),
                    readOnly: true,
                    className: 'dynamics-by-months_table_total',
                };
            }),
            ...sortedMetrics.slice(1).map((metricResponseItem, index) => {
                const currentYearSum = sumBy(metricResponseItem.items, 'value');
                const compareYearSum = sumBy(sortedMetrics[index].items, 'value');

                const difference =
                    isNumber(currentYearSum) && isNumber(compareYearSum) && compareYearSum > 0 && currentYearSum > 0
                        ? getDifferenceBetweenNumbers(currentYearSum, compareYearSum)
                        : null;

                return {
                    value:
                        args.viewSettings?.showDifference && difference
                            ? `${valueFormatter({
                                  value: difference?.percentDifference,
                                  units: '%',
                              })} (${valueFormatter({
                                  value: difference?.difference,
                                  units: metricFromStore?.units,
                                  precision: metricFromStore?.round_decimal_places,
                                  showUnits: false,
                              })})`
                            : valueFormatter({
                                  value: difference?.percentDifference,
                                  units: '%',
                              }),
                    readOnly: true,
                    className: 'dynamics-by-months_table_total',
                    forceComponent: true,
                    customComponent: TableCompareValue,
                    additionalOptions: {
                        mainPeriod: {
                            dateFrom:
                                DateTime.fromISO(metricResponseItem.items[0].time).startOf('year').toISODate() || '',
                            dateTo: DateTime.fromISO(metricResponseItem.items[0].time).endOf('year').toISODate() || '',
                        },
                        comparePeriod: {
                            dateFrom:
                                DateTime.fromISO(sortedMetrics[index].items[0].time).startOf('year').toISODate() || '',
                            dateTo:
                                DateTime.fromISO(sortedMetrics[index].items[0].time).endOf('year').toISODate() || '',
                        },
                        compareValue: {
                            percentDifference: difference?.difference,
                        },
                        reportingObjectId: Number(key),
                        metric: metricResponseItem.context.metric,
                    },
                };
            }),
        ];
        table.push(totalRow);

        const valuesByMonth: { [month: string]: ICell[] } = {};
        sortedMetrics.forEach((metricResponseItem) => {
            metricResponseItem.items.forEach((responseItem) => {
                const month = DateTime.fromISO(responseItem.time).toFormat('MMMM');
                if (!valuesByMonth[month]) {
                    valuesByMonth[month] = [
                        {
                            value: args.t(month),
                            readOnly: true,
                            className: 'dynamics-by-months_table_row',
                            style: { fontWeight: '500' },
                        },
                    ];
                }
                valuesByMonth[month].push({
                    value: valueFormatter({
                        value: responseItem.value,
                        units: metricFromStore?.units,
                        precision: metricFromStore?.round_decimal_places,
                        showUnits: false,
                    }),
                    readOnly: true,
                    className: 'dynamics-by-months_table_row',
                });
            });
        });
        sortedMetrics.slice(1).forEach((metricResponseItem, metricResponseItemIndex) => {
            metricResponseItem.items.forEach((responseItem, responseItemIndex) => {
                const dateTime = DateTime.fromISO(responseItem.time);
                const compareDateTime = DateTime.fromISO(
                    sortedMetrics[metricResponseItemIndex]?.items[responseItemIndex]?.time,
                );

                if (!valuesByMonth[dateTime.toFormat('MMMM')]) {
                    valuesByMonth[dateTime.toFormat('MMMM')] = [
                        { value: args.t(dateTime.toFormat('MMMM')), style: { fontWeight: 'bold' } },
                    ];
                }

                const currentValue = responseItem.value;
                const compareValue = sortedMetrics[metricResponseItemIndex]?.items[responseItemIndex]?.value;

                const difference =
                    isNumber(currentValue) && isNumber(compareValue) && compareValue > 0 && currentValue > 0
                        ? getDifferenceBetweenNumbers(currentValue, compareValue)
                        : null;

                valuesByMonth[dateTime.toFormat('MMMM')].push({
                    readOnly: true,
                    className: 'dynamics-by-months_table_row',
                    forceComponent: true,
                    customComponent: TableCompareValue,
                    additionalOptions: {
                        mainPeriod: {
                            dateFrom: dateTime.startOf('month').toISODate() || '',
                            dateTo: dateTime.endOf('month').toISODate() || '',
                        },
                        comparePeriod: {
                            dateFrom: compareDateTime.startOf('month').toISODate() || '',
                            dateTo: compareDateTime.endOf('month').toISODate() || '',
                        },
                        compareValue: {
                            percentDifference: difference ? difference.percentDifference : null,
                        },
                        reportingObjectId: Number(key),
                        metric: metricResponseItem.context.metric,
                    },
                    value:
                        args.viewSettings?.showDifference && difference
                            ? `${valueFormatter({
                                  value: difference?.percentDifference,
                                  units: '%',
                              })} (${valueFormatter({
                                  value: difference?.difference,
                                  units: metricFromStore?.units,
                                  precision: metricFromStore?.round_decimal_places,
                                  showUnits: false,
                              })})`
                            : valueFormatter({
                                  value: difference?.percentDifference,
                                  units: '%',
                              }),
                });
            });
        });

        table.push(...Object.values(valuesByMonth));

        tablesByReportingObjectId[key] = table;
    });

    return {
        chartOptionsByReportingObjectId: Object.entries(chartOptionsByReportingObjectId).reduce((acc, [key, value]) => {
            acc[key] = {
                ...value,
                series: sortBy(value.series, (item) => {
                    const splittedId = item.id.split(DS);
                    return DateTime.fromISO(splittedId[1] || '').year;
                }).map((item, index) => ({ ...item, isMain: index === 0 })),
            };
            return acc;
        }, {}),
        tablesByReportingObjectId,
    };
};

export default dataAdapter;
