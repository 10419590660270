import {
    Events_Events_TableOfObjects_Widget_Reducer_Values,
    storeSelectedComparePeriod,
    storeSelectedObjectsType,
    storeTableSortData,
    reloadWidget,
} from './reducer';
import { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IComparePeriod, ITableSortData } from './interfaces';
import Table from './components/Table/Table';
import { TableWrapper } from './styles';
import { generalReducerValues } from '../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { TableOfObjectsContext } from './context';
import Header from './components/Header/Header';
import SubHeader from './components/SubHeader/SubHeader';
import { Events_Events_Module_Reducer_Values } from '../../reducer';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

/**
 * Компонент для отображения виджета Leasing:Tenants
 */
const TableOfObjects = memo(() => {
    const { moduleName } = useSelector(Events_Events_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        cfg: { tenant2ZoneByTenantId, tenant2FloorByTenantId, reportingObjectsById, reportingObjectsByType },
        src: { dataObj2ProjectCategory },
        allMetrics,
    } = useSelector(generalReducerValues);
    const {
        extendedReportingObjects,
        availableComparePeriods,
        selectedComparePeriod,
        selectedObjectsType,
        tableSortData,
    } = useSelector(Events_Events_TableOfObjects_Widget_Reducer_Values);
    const rootNodeRef = useRef(null);
    const dispatch = useDispatch();

    const storeTableSortDataTrans = (data: null | ITableSortData) => {
        dispatch(storeTableSortData(data));
    };

    const storeSelectedObjectsTypeTrans = (selectedObjectsType: string) => {
        dispatch(storeSelectedObjectsType(selectedObjectsType));
    };

    const storeSelectedComparePeriodTrans = (comparePeriod: IComparePeriod) => {
        dispatch(storeSelectedComparePeriod(comparePeriod));
    };

    const context = {
        selectedMetrics: localCurrentOptions?.selectedMetrics,
        mainDateRanges: localCurrentOptions?.mainDateRanges,
        mainPeriod: localCurrentOptions?.mainPeriod,
        filters: localCurrentOptions?.['filters'],
        extendedReportingObjects,
        dataObj2ProjectCategory,
        availableComparePeriods,
        reportingObjectsByType,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        selectedComparePeriod,
        reportingObjectsById,
        selectedObjectsType,
        tableSortData,
        rootNodeRef,
        allMetrics,
        storeSelectedComparePeriodTrans,
        storeSelectedObjectsTypeTrans,
        storeTableSortDataTrans,
        reloadWidget,
    };

    return (
        <TableOfObjectsContext.Provider value={context}>
            <WidgetWrapper ref={rootNodeRef}>
                <Header />
                <SubHeader />
                <TableWrapper>
                    <Table />
                </TableWrapper>
            </WidgetWrapper>
        </TableOfObjectsContext.Provider>
    );
});

export default TableOfObjects;
