import { IArgs as IDataAdapterArgs } from '../../api/dataAdapter';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import alignSeriesByWeekday from './alignSeriesByWeekday';
import alignSeriesXAxisByMain from './alignSeriesXAxisByMain';
import generateAverageSeries from './generateAverageSeries';
import generateDefaultSeries from './generateDefaultSeries';
import generateTrendSeries from './generateTrendSeries';
import generateWeatherSeries from './generateWeatherSeries';

interface IArgs extends Omit<IDataAdapterArgs, 'events' | 'eventType'> {}

const generateFinalSeries = (args: IArgs): Array<TDynamicsSeries> => {
    let series: Array<TDynamicsSeries> = [];

    series.push(...generateDefaultSeries(args));

    if (args.viewSettings?.showWeather) {
        series.push(
            ...generateWeatherSeries({
                ...args,
                numberOfSeriesBefore: args.chartType === 'metrics' ? series.length : 1,
            }),
        );
    }

    series = alignSeriesXAxisByMain({ series });

    if (args.viewSettings?.showWeekends && args.detail.id === 'D') {
        series = alignSeriesByWeekday({ series });
    }

    if (args.viewSettings?.seriesIdsForTrend?.length) {
        series.push(
            ...generateTrendSeries({
                series,
                seriesIdsForTrend: args.viewSettings.seriesIdsForTrend,
                t: args.t,
            }),
        );
    }
    if (args.viewSettings?.seriesIdsForAverage?.length) {
        series.push(
            ...generateAverageSeries({
                series,
                seriesIdsForAverage: args.viewSettings.seriesIdsForAverage,
                t: args.t,
            }),
        );
    }
    return series;
};

export default generateFinalSeries;
