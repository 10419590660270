import { IPeriod } from '../../../../../../General.interfaces';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
import { IObject } from '../../../../../../components/Selects/Select/interfaces';

interface IGenerateMetricsQueryDataArgs {
    selectedReportingObjectsIds: number[];
    mainDateRanges: IPeriod[];
    selectedMetrics: string[];
    mainPeriod: IPeriod;
    detail: IObject;
    token: string;
}

/**
 * Функция для получения данных, необходимых для запроса метрик
 * @param selectedReportingObjectsIds Массив ID выбранных отчетных объектов
 * @param selectedMetrics Массивы выбранных метрик
 * @param mainDateRanges Массив временных интервалов для выбранного основоного периода
 * @param mainPeriod Выбранный период
 * @param detail Детализация данных
 * @param token Токен авторизации
 * @returns Массив данных, необходимых для запроса выбранных метрик
 */
const generateMetricsQueryData = (args: IGenerateMetricsQueryDataArgs): IRequestMetricsArgs[] => {
    const { selectedReportingObjectsIds, mainDateRanges, mainPeriod, selectedMetrics, token, detail } = args;
    const queryData: IRequestMetricsArgs[] = [];

    const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod.id);

    if (mainPeriodDateRnage?.period) {
        selectedMetrics.forEach((metric) => {
            queryData.push({
                time_range: [mainPeriodDateRnage?.period!.dateFrom, mainPeriodDateRnage?.period!.dateTo],
                obj_ids: selectedReportingObjectsIds,
                time_freq: detail.id as TTimeFreq,
                object_aggregation: false,
                alias: null,
                metric,
                token,
            });
        });
    }

    return queryData;
};

export default generateMetricsQueryData;
