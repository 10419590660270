import { createSlice } from '@reduxjs/toolkit';
import { IReducerState } from './interfaces';
import { RootState } from '../../../../../store';

const initialState: IReducerState = {};

export const Events_Events_Summary_Widget_Reducer = createSlice({
    name: 'Events_Events_Summary_Widget_Reducer',
    initialState,

    reducers: {},
});

export const Events_Events_Summary_Widget_Reducer_Values = (state: RootState) =>
    state.Events_Events_Summary_Widget_Reducer;

export default Events_Events_Summary_Widget_Reducer.reducer;
