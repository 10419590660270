import React, { FC } from 'react';
import getFilterInstance from '../../../../../../../hooks/dataObjectsSelectFilters/Filters/tools/getFilterInstance';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import Stack from '../../../../../../Stack/Stack';

/**
 * Компонент для отображения дополнительных настроек фильтра отчетных объектов
 */
const Filters: FC<IProps> = ({ extendedDataObjectsSelectFiltersByType, dataObjectFilters, onFiltersChange }) => {
    return (
        <Wrapper>
            <Stack gap={10} direction={'column'}>
                {Object.values(extendedDataObjectsSelectFiltersByType).map((element) => {
                    const filterInstance = getFilterInstance(element.filter_type);
                    return (
                        <React.Fragment key={element.filter_type}>
                            {filterInstance && dataObjectFilters.includes(filterInstance.name)
                                ? filterInstance.getComponent({ filterData: element, onFiltersChange })
                                : null}
                        </React.Fragment>
                    );
                })}
            </Stack>
        </Wrapper>
    );
};

export default Filters;
