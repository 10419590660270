import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import {
    BodyIntersectionWrapper,
    RowReportingObjectName,
    BodyDefaultText,
    PaiwiseValue,
    PercentValue,
    BodyTd,
    BodyTr,
} from './styles';
import { FC, useContext } from 'react';
import { IntersectionsWithTenantsContext } from '../../../../context';

/**
 * Компонент для отображения тела таблицы
 */
const Body: FC<IProps> = ({ sortedReportingObjects, intersectionsDataById, days }) => {
    const context = useContext(IntersectionsWithTenantsContext);
    const { t } = useTranslation();

    return (
        <tbody>
            {sortedReportingObjects
                .slice(
                    0,
                    context?.tableItemsToShow === 'all'
                        ? sortedReportingObjects.length
                        : context?.tableItemsToShow || 1,
                )
                .map((reportingObject) => {
                    return (
                        <BodyTr key={reportingObject.id}>
                            <BodyTd>
                                <RowReportingObjectName>
                                    <div>{reportingObject.name}</div>
                                </RowReportingObjectName>
                            </BodyTd>
                            <BodyTd>
                                {context?.isLoading ? (
                                    <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                                ) : context?.isError ? (
                                    <BodyDefaultText>{t('Unable to get data')}</BodyDefaultText>
                                ) : (
                                    <BodyIntersectionWrapper>
                                        <PercentValue>
                                            {valueFormatter({
                                                units: '%',
                                                value:
                                                    intersectionsDataById?.[reportingObject?.id]?.percentIntersection! /
                                                    days,
                                            })}
                                        </PercentValue>
                                        <PaiwiseValue>
                                            {valueFormatter({
                                                precision: 0,
                                                value: String(
                                                    intersectionsDataById?.[reportingObject?.id]?.intersection! / days,
                                                ),
                                            })}
                                        </PaiwiseValue>
                                    </BodyIntersectionWrapper>
                                )}
                            </BodyTd>
                            <BodyTd>
                                {context?.isLoading ? (
                                    <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                                ) : context?.isError ? (
                                    <BodyDefaultText>{t('Unable to get data')}</BodyDefaultText>
                                ) : (
                                    <BodyIntersectionWrapper>
                                        <PercentValue>
                                            {valueFormatter({
                                                value: intersectionsDataById?.[reportingObject?.id]
                                                    ?.percentIntersection,
                                                units: '%',
                                            })}
                                        </PercentValue>
                                        <PaiwiseValue>
                                            {valueFormatter({
                                                value: String(
                                                    intersectionsDataById?.[reportingObject?.id]?.intersection!.toFixed(
                                                        0,
                                                    ),
                                                ),
                                            })}
                                        </PaiwiseValue>
                                    </BodyIntersectionWrapper>
                                )}
                            </BodyTd>
                        </BodyTr>
                    );
                })}
        </tbody>
    );
};

export default Body;
