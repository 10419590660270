import { FC } from 'react';
import { IProps } from './interfaces';
import TopByMetricsLocationsOnBoardSelects from './components/TopByMetricsLocationsOnBoardSelects/TopByMetricsLocationsOnBoardSelects';
import TopByMetricsNetworkObjectsOnBoardSelects from './components/TopByMetricsNetworkObjectsOnBoardSelects/TopByMetricsNetworkObjectsOnBoardSelects';

const OnBoardSelects: FC<IProps> = (props) => {
    return (
        <>
            {props.widgetType === 'topByMetricsLocations' && <TopByMetricsLocationsOnBoardSelects {...props} />}
            {props.widgetType === 'topByMetricsNetworkObjects' && (
                <TopByMetricsNetworkObjectsOnBoardSelects {...props} />
            )}
        </>
    );
};

export default OnBoardSelects;
