import styled from 'styled-components/macro';

export const PresetWrapper = styled.div`
    margin-left: auto;
    align-self: flex-end;
`;
export const Wrapper = styled.div`
    position: relative;
`;

export const ShareWrapper = styled.div<{ disabled: boolean }>`
    position: absolute;
    right: 5px;
    bottom: 2px;
    cursor: pointer;
    color: ${(p) => (p.disabled ? 'var(--color-secondary-dark)' : 'var(--color-secondary-darker)')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'all')};
    :hover {
        color: var(--color-primary-light);
    }
`;
