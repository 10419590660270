import { useSelector } from 'react-redux';
import {
    Configuration_SalesMetricsUpload_Module_Reducer_Values,
    storeDataFromFile,
    storeDateRangeFromFile,
    storeObjIdsFromFile,
    storeSelectedTab,
} from '../../reducer';
import Table from '../Table/Table';
import Stack from '../../../../../components/Stack/Stack';
import Button from '../../../../../components/Button/Button';
import { Button as FluentButton } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { IValidateFooResult } from '../../interfaces';
import Modals from '../../../../../components/Modals/Modals';
import UploadFromFileButton from './components/UploadFromFileButton/UploadFromFileButton';

import ValidationModal from '../ValidationModal/ValidationModal';
import useSaveTable from '../../tools/useSaveTable';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';
import { generalReducerValues } from 'src/General.reducer';

interface IProps {}

const FileUpload: React.FC<IProps> = () => {
    const sendAnalytics = useSendSimpleAnalytics();

    const { dataFromFile, objIdsFromFile, dateRangeFromFile } = useSelector(
        Configuration_SalesMetricsUpload_Module_Reducer_Values,
    );

    const [validationModalStatus, setValidationModalStatus] = useState<{
        show: boolean;
        data: Omit<IValidateFooResult, 'isValid'>[];
    }>({
        show: false,
        data: [],
    });
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { save } = useSaveTable('dataFromFile');

    const saveActive = useMemo(() => {
        return dataFromFile.some((row) => {
            return row.some((cell) => {
                return cell.changeType !== undefined;
            });
        });
    }, [dataFromFile]);

    const onSaveClick = () => {
        sendAnalytics(`Upload_from_file_Save_click`);

        save({
            dataToSave: dataFromFile,
            onSaveSuccess: () => {
                if (dateRangeFromFile && objIdsFromFile.length) {
                    dispatch(
                        changeOptions({
                            newOptions: {
                                period: Object.entries(dateRangeFromFile).map(([key, value]) => ({
                                    id: key,
                                    text: value,
                                })),
                                selectedReportingObjectsIds: objIdsFromFile,
                            },
                        }),
                    );
                }

                dispatch(storeSelectedTab('summaryTable'));
                dispatch(storeDataFromFile([]));
                dispatch(storeDateRangeFromFile(null));
                dispatch(storeObjIdsFromFile([]));
            },
        });
    };

    const closeValidationModal = () => {
        setValidationModalStatus({ show: false, data: [] });
    };

    return (
        <>
            <Modals
                topColor={'var(--color-accent-dark)'}
                title={t('Validation errors')}
                modalStatus={validationModalStatus}
                closeModal={closeValidationModal}
            >
                <ValidationModal data={validationModalStatus.data || []} closeModal={closeValidationModal} />
            </Modals>
            <Stack styles={{ marginBottom: 10 }} gap={10}>
                <UploadFromFileButton />
                <Button text={t('Save')} appearance="primary" disabled={!saveActive} onClick={onSaveClick} />
            </Stack>
            <Table data={dataFromFile} tableKey={'dataFromFile'} />
        </>
    );
};

export default FileUpload;
