import { Delete16Regular } from '@fluentui/react-icons';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 16px;
    width: 280px;
`;

export const StyledIcon = styled(Delete16Regular)`
    color: var(--color-secondary-darker);
    margin-left: 5px;
    :hover {
        color: var(--color-primary);
    }
`;

export const CheckBoxWrapper = styled.div`
    padding: var(--space-xxxxs) 0;
`;

export const ListWrapper = styled.div`
    max-height: 450px;
    overflow: auto;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const SearchWrapper = styled.div`
    margin-bottom: var(--space-xxs);
`;
