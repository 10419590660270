import { FC } from 'react';
import Row from '../Row/Row';
import { Table as StyledTable } from './styles';
import { IProps } from './interfaces';

const Table: FC<IProps> = ({ ratingItems, bgColor }) => (
    <StyledTable>
        <tbody>
            {ratingItems?.map((row) => (
                <Row key={row.name} data={row} bgColor={bgColor} />
            ))}
        </tbody>
    </StyledTable>
);

export default Table;
