import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _, { cloneDeep } from 'lodash';
import { DS } from '../../../../../constants/constants';
import { TLoadingData } from '../../../../../General.interfaces';
import { RootState } from '../../../../../store';
import { IItemElement, IRating, IRatingData, IRatingReducerState } from './interfaces';

const initialState: IRatingReducerState = {
    newRating: null,
    ratings: null,
    responseResult: null,
    ratingsData: [],
    removeRating: null,
    ratingsDataRecived: [],
    editRatingId: null,
    idLoadRating: {},
};

export const Performance_Ratings_RatingList_Widget_Reducer = createSlice({
    name: 'Rating_List_Reducer',
    initialState,

    reducers: {
        storeNewRating: (state, action: PayloadAction<IRating | null>) => {
            state.newRating = action.payload;
        },
        storeRatings: (state, action: PayloadAction<IRating[] | null>) => {

            const ratings = action.payload?.sort((a, b) => b.id - a.id) || null;
            state.ratings = ratings;
        },
        storeEditingRating:(state, action: PayloadAction<IRating>) => {

            state.ratings = state.ratings?.map((item) => {
                if (item.id === action?.payload?.id) {
                    return {
                        ...action.payload,
                    }
                }
                return {
                    ...item,
                }
            }) || null;
        },
        storeResponseResult: (state, action: PayloadAction<TLoadingData | null>) => {
            const responsesForRatings: IItemElement[] = [];
            const { responseResult } = cloneDeep(state);
            if (action.payload === null) {
                state.responseResult = action.payload;
            } else if (Array.isArray(action.payload)) {
                action.payload.forEach((element) => {
                    const alias = element[0].context.alias;
                    const metric = element[0].context.metric;
                    const id: number = Number(alias.split(DS)[0]);
                    const rating = responsesForRatings?.find((x) => x.id === id);
                    if (Array.isArray(responseResult)) {
                        _.remove(responseResult, function (n) {
                            return n.id === id;
                        });
                    }
                    if (rating) {
                        rating.request.push(element);
                    } else {
                        responsesForRatings.push({
                            id,
                            metric,
                            request: [element],
                        });
                    }
                });

                if (Array.isArray(responseResult)) {
                    state.responseResult = responseResult.concat(responsesForRatings);
                } else {
                    state.responseResult = responsesForRatings;
                }
            } else {
                const alias = action.payload.alias;
                const id: number = alias ? Number(alias.split(DS)[0]) : 0;
                if (Array.isArray(responseResult)) {
                    _.remove(responseResult, function (n) {
                        return n.id === id;
                    });
                }
                responsesForRatings.push({
                    id,
                    metric: '',
                    request: [],
                    error: {
                        status: action.payload.status,
                        message: action.payload.message,
                    },
                });

                if (Array.isArray(responseResult)) {
                    state.responseResult = responseResult.concat(responsesForRatings);
                } else {
                    state.responseResult = responsesForRatings;
                }
            }
        },
        getIdRatingLoad: (state, action: PayloadAction<{id: number, status: boolean}>) => {
            let obj:{[x:string]: number} | {} = {}
            if (action.payload.status) {
                obj[action.payload.id] = action.payload.id
            }
            else if (!action.payload.status) {
                delete obj[action.payload.id]
            }
            state.idLoadRating = obj;
        },
        storeRatingsData: (state, action: PayloadAction<IRatingData[]>) => {
            state.ratingsData = action.payload;
        },
        storeRemoveRating: (state, action: PayloadAction<number | null>) => {
            state.removeRating = action.payload;
        },
        storeEditRatingId: (state, action: PayloadAction<number | null>) => {
            state.editRatingId = action.payload;
        },
        storeRatingsDataRecived: (state, action: PayloadAction<number[]>) => {
            state.ratingsDataRecived = action.payload;
        },
        /**
         * Обнуление редюсера
         */
        resetRatingsReducer: () => initialState,
    },
});

export const {
    storeNewRating,
    storeRatings,
    storeResponseResult,
    storeRatingsData,
    storeRemoveRating,
    storeRatingsDataRecived,
    storeEditRatingId,
    resetRatingsReducer,
    storeEditingRating,
    getIdRatingLoad,
} = Performance_Ratings_RatingList_Widget_Reducer.actions;

export const Performance_Ratings_RatingList_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_Ratings_RatingList_Widget_Reducer;

export default Performance_Ratings_RatingList_Widget_Reducer.reducer;
