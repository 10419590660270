import { FC, useMemo } from 'react';
import { IProps } from './interfaces';
import HeatMapChart from '../../../Charts/HeatMap/HeatMap';
import { Wrapper } from './styles/styles';
import './styles/styles.scss';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import useFetchData from './hooks/useFetchData';
import { isMetricErrorGuard } from '../../../../tools/API/dataAccessors/metricsDataAccessor';
import { ResponseStatus } from '../../../../tools/API/constants';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import Selects from './components/Selects/Selects';

/**
 * Виджет типа "HeatMap"
 */
const HeatMap: FC<IProps> = ({ dashboardKey, moduleId, widgetDimensions, widgetConfig, updateWidgetTrans }) => {
    const { data, isFetching, error, refetch } = useFetchData({
        widgetConfig,
        dashboardKey,
        moduleId,
    });
    const { allMetrics } = useSelector(generalReducerValues);

    const { t } = useTranslation(['metrics', 'translation']);

    const mainChart = useMemo(() => {
        if (data?.main) {
            return (
                <HeatMapChart
                    {...data.main}
                    customChartDimensions={
                        widgetDimensions
                            ? { width: (widgetDimensions.width - 30) * 0.9, height: widgetDimensions.height - 60 }
                            : undefined
                    }
                />
            );
        }
        return null;
    }, [JSON.stringify(data?.main), JSON.stringify(widgetDimensions)]);

    const summaryChart = useMemo(() => {
        if (data?.summary && widgetConfig?.options?.additionallyShow?.[0].id === 'total') {
            return (
                <HeatMapChart
                    {...data.summary}
                    customChartDimensions={
                        widgetDimensions
                            ? { width: (widgetDimensions.width - 30) * 0.1, height: widgetDimensions.height - 60 }
                            : undefined
                    }
                />
            );
        } else if (data?.avg && widgetConfig?.options?.additionallyShow?.[0].id === 'avg') {
            return (
                <HeatMapChart
                    {...data.avg}
                    customChartDimensions={
                        widgetDimensions
                            ? { width: (widgetDimensions.width - 30) * 0.1, height: widgetDimensions.height - 60 }
                            : undefined
                    }
                />
            );
        }
        return null;
    }, [
        JSON.stringify(data?.avg),
        JSON.stringify(data?.summary),
        widgetConfig?.options?.additionallyShow,
        widgetDimensions,
    ]);

    /** Имя текущей метрики */
    const [currentMetricName, currentMetricUnits] = useMemo(() => {
        if (allMetrics.length && widgetConfig?.options?.metrics?.length) {
            const currentMetricId = widgetConfig?.options?.metrics?.[0] || '';
            const currentMetric = allMetrics.find((metric) => metric.id === currentMetricId);

            if (currentMetric) {
                return [currentMetric.text, currentMetric.units];
            }
        }
        return [];
    }, [allMetrics, widgetConfig?.options?.metrics]);

    const reloadWidget = () => {
        refetch();
    };

    const updateWidget = (key: string, value: { [x: string]: unknown }) => {
        if (widgetConfig && widgetConfig?.id) {
            updateWidgetTrans({
                moduleId,
                dashboardKey,
                data: {
                    widgetId: widgetConfig?.id,
                    key: key,
                    value: { ...(widgetConfig[key] || {}), ...value },
                },
            });
        }
    };

    return (
        <WidgetWrapper
            title={widgetConfig?.title || ''}
            titleAdditionalText={`${t(currentMetricName || '')}, ${t(currentMetricUnits || '')}`}
            isNoData={isMetricErrorGuard(error) && error.status === ResponseStatus.NoData}
            isError={isMetricErrorGuard(error) && error.status === ResponseStatus.Error}
            widgetSelects={<Selects widgetConfig={widgetConfig} updateWidget={updateWidget} />}
            updateWidgetTrans={updateWidgetTrans}
            widgetId={widgetConfig?.id}
            dashboardKey={dashboardKey}
            reloadWidget={reloadWidget}
            isLoading={isFetching}
            moduleId={moduleId}
        >
            <Wrapper>
                {mainChart}
                {summaryChart}
            </Wrapper>
        </WidgetWrapper>
    );
};

export default HeatMap;
