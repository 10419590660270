import RatingItem from '../Rating/Rating';
import React from 'react';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { RatingsWrapper } from './styles';
import { ResponseStatus } from '../../../../../../../tools/API/constants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useObjectTranslation from '../../../../../../../hooks/useObjectTranslation';
import useFetchSelectedObjectData from '../../api/useFetchSelectedObjectData';
import { Performance_Dashboard_ByArea_Widget_Reducer_Values } from '../../reducer';

interface IProps {
    itemsToShow: number | 'all';
}

/**
 * Компонент отображения рейтингов для выбранного отчетного объекта
 */
const ObjectsRatings: React.FC<IProps> = ({ itemsToShow }) => {
    const { selectedObjectId } = useSelector(Performance_Dashboard_ByArea_Widget_Reducer_Values);
    const { cfg } = useSelector(generalReducerValues);
    const { getObjName } = useObjectTranslation();
    const { data, isFetching, error } = useFetchSelectedObjectData();
    const { t } = useTranslation();

    const RATING_TYPES = ['Growing', 'Falling'];

    const list = (trend: string) => {
        if (isFetching) {
            return ResponseStatus.Loading;
        }
        if (error) {
            return ResponseStatus.Error;
        }

        if (Array.isArray(data?.objectData)) {
            const list = data?.objectData
                ?.filter((item) => item.colorValue && Number(item.colorValue) !== Infinity)
                ?.filter((item) => {
                    if (trend === 'Growing') {
                        return Number(item.colorValue) >= 0;
                    } else {
                        return Number(item.colorValue) < 0;
                    }
                })
                .sort((a, b) => Math.abs(Number(b.colorValue)) - Math.abs(Number(a.colorValue)));
            if (list?.length) {
                return list;
            } else {
                return null;
            }
        }

        return ResponseStatus.NoData;
    };

    return (
        <RatingsWrapper>
            {selectedObjectId &&
                Object.keys(cfg.reportingObjectsById).length &&
                RATING_TYPES.map((type) => {
                    const selectedObjectName = getObjName(cfg.reportingObjectsById?.[selectedObjectId]);
                    const data = list(type);
                    return (
                        <RatingItem
                            itemsToShow={itemsToShow}
                            key={selectedObjectName + data + type}
                            data={data}
                            title={`${t(type)} ${t('metrics')} "${selectedObjectName}"`}
                        />
                    );
                })}
        </RatingsWrapper>
    );
};

export default ObjectsRatings;
