import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../tools/getColorFromPercent';

export const TableWrapper = styled.div`
    max-height: 700px;
    overflow: auto;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    position: relative;
`;

export const StyledHead = styled.thead`
    position: sticky;
    top: 0;
    font-weight: 600;
    z-index: 10;
    background-color: white;
`;

const HEADER_HEIGHT = '94px';

export const Tr = styled.tr<{ isSelectedTenant?: boolean; isGroup?: boolean }>`
    border: 1px solid var(--default-border-color);
    background-color: ${(p) => (p.isSelectedTenant ? 'rgb(255, 253, 235)' : 'white')};
    position: ${(p) => (p.isGroup ? 'sticky' : 'relative')};
    top: ${(p) => (p.isGroup ? HEADER_HEIGHT : '0')};
    z-index: ${(p) => (p.isGroup ? '10' : 'unset')};
    td,
    th {
        padding: var(--space-xxxxs) var(--space-xxs);
        height: 40px;
        :first-child {
            background-color: ${(p) => (p.isSelectedTenant ? 'rgb(255, 253, 235)' : 'white')};
            position: sticky;
            left: 0;
            z-index: 5;
        }
    }
`;

export const CellWrapper = styled.td<{
    styles: {
        textAlign: string;
        fontWeight: string;
        color: string;
        hover?: boolean;
    };
}>`
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    cursor: ${(p) => (p?.styles?.['hover'] ? 'pointer' : 'auto')};
    text-align: ${(p) => p.styles.textAlign};
    color: ${(p) => p.styles.color};
    font-weight: ${(p) => p.styles.fontWeight};
    font-size: 14px;
    position: relative;
    white-space: nowrap;
`;

export const MainCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const ReferenceCompareCellValue = styled.span<{ value?: number | null }>`
    color: ${(p) => getColorFromPercent(p.value)};
`;
export const IconWrapper = styled.div``;
