import { FC } from 'react';
import { IProps } from './interfaces';
import { Status } from './styles';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { bgReportsStatusMapping } from '../../../../constants/keysMapping';

/**
 * Отображение ячейки "status" для "background reports"
 */
const BgReportStatus: FC<IProps> = ({ status }) => {
    const { t } = useTranslation();

    const colorsByStatus = {
        failure: 'var(--color-accent)',
        success: 'var(--color-success)',
        in_progress: 'var(--color-warning)',
        in_queue: 'initial',
    };

    return <Status color={colorsByStatus[status]}>{t(bgReportsStatusMapping[status] || '')}</Status>;
};

export default BgReportStatus;
