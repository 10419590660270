import React from 'react';
import { useSelector } from 'react-redux';
import { Heart16Regular, Heart16Filled } from '@fluentui/react-icons';
import cn from 'classnames';

import { toggleArray } from 'src/tools';
import useTranslation from 'src/hooks/useTranslation/useTranslation';
import { theme } from 'src/theme';

import * as constants from '../../../../constants/constants';
import { IItemProps } from '../../../../configurations';
import { useAppDispatch } from '../../../../../../hooks/useSettings';
import { cabinetPreferencesValues, patchSidebar } from '../../../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { modulesConfig } from '../../../../../../constants/modulesConfig';
import Tooltip from '../../../../../Tooltip/Tooltip';
import '../../../../styles.scss';

import { ElementWrapper, FavoriteButton, NameWrapper } from './styles';

const ModuleName: React.FC<IItemProps> = ({ subsection, onClick, active, disabled, ...props }) => {
    const { t } = useTranslation('translationSidebar');
    const dispatch = useAppDispatch();
    const {
        sidebar: { favoriteModules, openSections },
    } = useSelector(cabinetPreferencesValues);

    const { currentModuleID } = useSelector(generalReducerValues);

    const { FAVORITES_NAME } = constants;

    const toogleFavoritesClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        let activeSection = subsection.chapterName;
        if (activeSection === FAVORITES_NAME && subsection.module_id === currentModuleID) {
            activeSection =
                modulesConfig.find((item) => item.module_id === currentModuleID)?.module_name.split(':')[0] || '';
            dispatch(
                patchSidebar({
                    favoriteModules: toggleArray(favoriteModules, subsection.module_id),
                    activeSection,
                }),
            );
        } else {
            dispatch(
                patchSidebar({
                    favoriteModules: toggleArray(favoriteModules, subsection.module_id),
                    openSections: Array.from(new Set([...openSections, FAVORITES_NAME])),
                }),
            );
        }
    };

    const isAlreadyFavoriteSection = subsection.module_id ? favoriteModules.includes(subsection.module_id) : false;

    return subsection ? (
        <ElementWrapper disabled={Boolean(subsection.disabled)} selected={active} onClick={onClick}>
            <Tooltip content={isAlreadyFavoriteSection ? t('Remove from favorites') : t('Add to favorites')}>
                <FavoriteButton
                    onClick={toogleFavoritesClickHandler}
                    isFavoriteSection={subsection.chapterName === FAVORITES_NAME}
                >
                    {isAlreadyFavoriteSection ? (
                        <Heart16Filled style={{ color: theme.colors.accent }} />
                    ) : (
                        <Heart16Regular style={{ color: theme.colors.accent }} />
                    )}
                </FavoriteButton>
            </Tooltip>

            {!subsection.version ? (
                <NameWrapper className="NameWrapper">{t(subsection.name)}</NameWrapper>
            ) : (
                <NameWrapper className="NameWrapper">
                    {t(subsection.name)}
                    <sup>{subsection.version}</sup>
                </NameWrapper>
            )}
        </ElementWrapper>
    ) : null;
};

export default ModuleName;
