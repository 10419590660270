import { cloneDeep } from 'lodash';

/**
 * Функция для поиска центра любого полигона
 * @param coords Координаты полигона
 * @returns координаты центра полигона
 */
export const findPolygonCenter = (coords: Array<number[]>): { x: number; y: number } | null => {
    if (coords.length === 0) return null;
    const coordsCopy = cloneDeep(coords);
    const first = coordsCopy[0];
    const last = coordsCopy[coordsCopy.length - 1];
    if (first[0] !== last[0] || first[1] !== last[1]) coordsCopy.push(first);
    let twicearea = 0;
    let x = 0;
    let y = 0;
    const nPts = coordsCopy.length;
    let p1;
    let p2;
    let f;
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = coordsCopy[i];
        p2 = coordsCopy[j];
        f = p1[0] * p2[1] - p2[0] * p1[1];
        twicearea += f;
        x += (p1[0] + p2[0]) * f;
        y += (p1[1] + p2[1]) * f;
    }
    f = twicearea * 3;
    return { x: x / f, y: y / f };
};
