import { FC, memo, useEffect, useMemo, useState } from 'react';
import { IProps, TWidgetType } from './interfaces';
import { Wrapper } from './styles/styles';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import WidgetWrapper from '../../../../Chapters/Dashboards/components/WidgetWrapper/WidgetWrapper';
import { IProps as IWidgetWrapperProps } from '../../../../Chapters/Dashboards/components/WidgetWrapper/interfaces';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import Selects from './components/Selects/Selects';
import { DS } from '../../../../constants/constants';
import OnBoardSelects from './components/OnBoardSelects/OnBoardSelects';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';

import { TDynamicsSeries } from '../../../CommonWidgets/DynamicsPMO/components/Dynamics/interfaces';
import DynamicsPMO from '../../../CommonWidgets/DynamicsPMO/DynamicsPMO';
import { queryClient } from '../../../..';
import { DYNAMICS_PMO_QUERY_KEY } from '../../../CommonWidgets/DynamicsPMO/constants/constants';
import useDynamicsProps from './hooks/useDynamicsProps';
import { omit } from 'lodash';

/**
 * Виджет типа "динамика"
 */
const Dynamics: FC<IProps> = memo((props) => {
    const { allMetrics } = useSelector(generalReducerValues);

    const [series, setSeries] = useState<TDynamicsSeries[]>([]);

    const localCurrentOptions = useWidgetCurrentOptions(props.moduleId);
    const dynamicsProps = useDynamicsProps({ ...props, series });
    const [headerHeight, setHeaderHeight] = useState(0);

    const { t } = useTranslation(['metrics', 'translation']);

    const widgetType = props.widgetConfig?.type?.split(DS)?.[1] as TWidgetType;

    useEffect(() => {
        if (props.widgetConfig?.options?.details?.id !== 'D') {
            updateWidget('visual', {
                additionalVisualOptions: props.widgetConfig?.visual?.['additionalVisualOptions']?.filter(
                    (item: any) => item.id !== 'showWeekends',
                ),
            });
        }
    }, [props.widgetConfig?.options?.details?.id]);

    const wrapperSettings = useMemo(() => {
        const result: Partial<IWidgetWrapperProps> = {
            titleAdditionalText: '',
            wrapperPaddingSize: undefined,
            titleSize: undefined,
        };

        let currentMetricId = props.widgetConfig?.options?.metrics?.[0] || '';
        if (props.widgetConfig?.visual.useMetricFrom?.[0]?.id === 'fromPanel') {
            currentMetricId = localCurrentOptions?.['selectedSingleMetric']?.[0];
        }
        const currentMetric = allMetrics.find((metric) => metric.id === currentMetricId);

        const widgetTypesWithSingleMetric = [
            'objects',
            'periods',
            'objectsLocations',
            'periodsLocations',
            'objectsReportingObjects',
        ];
        if (widgetTypesWithSingleMetric.includes(widgetType)) {
            result.titleAdditionalText = `${t(currentMetric?.text || '')}, ${t(currentMetric?.units || '')}`;
        }

        const widgetTypesWithNormalPaddings = ['objectsLocations', 'periodsLocations', 'objectsReportingObjects'];
        if (widgetTypesWithNormalPaddings.includes(widgetType)) {
            result.wrapperPaddingSize = 'normal';
            result.titleSize = 'normal';
        }

        return result;
    }, [localCurrentOptions, props.widgetConfig, allMetrics, widgetType, t]);

    const updateWidget = (key: string, value: { [x: string]: unknown }) => {
        if (props.widgetConfig && props.widgetConfig?.id) {
            props.updateWidgetTrans({
                moduleId: props.moduleId,
                dashboardKey: props.dashboardKey,
                data: {
                    widgetId: props.widgetConfig?.id,
                    key: key,
                    value: { ...(props.widgetConfig[key] || {}), ...value },
                },
            });
        }
    };
    const giveSeriesOutside = (series: TDynamicsSeries[]) => {
        setSeries(series);
    };

    const reloadWidget = () => {
        queryClient.invalidateQueries({ queryKey: [DYNAMICS_PMO_QUERY_KEY, props.moduleId, props.widgetConfig?.id] });
    };

    return (
        <WidgetWrapper
            {...wrapperSettings}
            title={props.widgetConfig?.title || ''}
            updateWidgetTrans={props.updateWidgetTrans}
            widgetSelects={
                <Selects
                    {...omit(props, ['reportingObjects', 'widgetDimensions', 'updateWidgetTrans'])}
                    updateWidget={updateWidget}
                />
            }
            widgetOnBoardSelects={
                <OnBoardSelects
                    {...omit(props, ['reportingObjects', 'widgetDimensions', 'updateWidgetTrans'])}
                    updateWidget={updateWidget}
                />
            }
            widgetId={props.widgetConfig?.id}
            dashboardKey={props.dashboardKey}
            reloadWidget={reloadWidget}
            moduleId={props.moduleId}
            contentWrapperStyles={{
                overflow: Boolean(
                    props.widgetConfig?.visual?.additionalVisualOptions?.find((item) => item.id === 'showEvents'),
                )
                    ? 'visible'
                    : 'hidden',
            }}
            getHeaderHeight={(height) => setHeaderHeight(height)}
        >
            <Wrapper
                maxHeight={
                    Boolean(
                        props.widgetConfig?.visual?.additionalVisualOptions?.find((item) => item.id === 'showEvents'),
                    )
                        ? (props.widgetDimensions?.height ?? 0) - headerHeight - 30
                        : undefined
                }
            >
                {dynamicsProps ? <DynamicsPMO {...dynamicsProps} giveSeriesOutside={giveSeriesOutside} /> : null}
            </Wrapper>
        </WidgetWrapper>
    );
});

export default Dynamics;
