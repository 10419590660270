import styled from 'styled-components/macro';

export const SelectsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    column-gap: 15px;

    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        margin-top: 10px;
    }
`;

export const MainPeriodText = styled.div`
    margin-right: var(--space-sm);
    font-size: var(--text-lg);
    font-weight: 500;
    padding-bottom: var(--space-xxxs);
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        width: 100%;
    }
`;
