import { createContext } from 'react';
import { ILayer, IReportingObject } from '../../../../General.interfaces';
import { IColorsByMarker, TSupportedLayerTypes, TSupportedTypesOfReportingObjects } from './interfaces';
import { IStageScale } from './tools/interfaces';

interface IFloorsContext {
    changeStageCursor: (stageIndex: number, cursor: string) => () => void;
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    selectedReportingObjectType: TSupportedTypesOfReportingObjects;
    selectedLayerType: TSupportedLayerTypes;
    selectObject?: (marker: string) => void;
    colorsByMarker: IColorsByMarker | null;
    onMouseOver?: (marker: string) => void;
    onMouseLeave?: (value: null) => void;
    selectedGroupOfZones: string | null;
    availableMarkers?: string[] | null;
    selectedMarker?: string | null;
    hoveredMarker: string | null;
    labelAdditionalText?: string;
    stageScale: IStageScale;
    showPerimeters: boolean;
    showAllLabels: boolean;
    layers: ILayer[];
    showPlans: boolean;
}

const FloorsContext = createContext<IFloorsContext>({
    stageScale: { stageScale: 1, stageX: 0, stageY: 0 },
    reportingObjectsByTypeAndMarker: {},
    selectedReportingObjectType: 'zone',
    selectedLayerType: 'zones_layer',
    changeStageCursor: () => () => {},
    selectedGroupOfZones: null,
    showPerimeters: false,
    showAllLabels: false,
    colorsByMarker: null,
    hoveredMarker: null,
    showPlans: false,
    layers: [],
});
export default FloorsContext;
