import styled from 'styled-components/macro';
import { JustifyType } from '../configurations/justify.type';

export const DivCollapseBodySC = styled.div<{ isRtl?: boolean | undefined; collapsed: boolean }>`
    max-height: ${(p) => (p.collapsed ? '0px' : '200px')};
    /* margin-top: 6px; */
    height: ${(p) => (p.collapsed ? '0px' : 'auto')};
    /* display: ${(p) => (p.collapsed ? 'none' : 'flex')}; */
    opacity: ${(p) => (p.collapsed ? 0 : 1)};
    transition: max-height 200ms ease, opacity 200ms ease;
    overflow: auto;
    /* ${(p) =>
        p.isRtl
            ? 'padding-right: var(--space-sm); padding-left: 0 !important;'
            : 'padding-left: 0; padding-right: 0 !important;'} */
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
    gap: 10px;
`;

export const Wrapper = styled.div<{ justifyContent: JustifyType }>`
    justify-content: ${(p) => p.justifyContent};
    margin-bottom: 6px;
`;
