import { DateTime, Interval } from 'luxon';
import { monthNames, shortMonthNames } from '../const';
import i18next from 'i18next';
import { TFunction } from 'i18next';

/**
 * Функция распределяет массив дней месяца месяца по сетке из 6-ти недель календаря
 * @param days Массив дней месяца
 * @returns
 */
export const arrangeDaysToMonthGrid = (days: Array<{ day: DateTime }>) => {
    const monthGrid = [];
    let daysIndex = 0;

    for (let monthWeek = 1; monthWeek <= 6; monthWeek++) {
        const week = [];
        let weekNumber = null;
        for (let weekDay = 1; weekDay <= 7; weekDay++) {
            if (days[daysIndex] && weekDay === days[daysIndex].day.weekday) {
                weekNumber = days[daysIndex].day.weekNumber;
                week.push({ data: days![daysIndex] });
                daysIndex++;
            } else {
                week.push({ data: null });
            }
        }
        monthGrid.push({ weekNumber, week });
    }

    return monthGrid;
};

export function* getRanges(interval: Interval, mode: 'day' | 'month' | 'year') {
    let cursor = interval?.start?.startOf(mode);
    while (cursor && interval?.end?.endOf(mode) && cursor < interval?.end?.endOf(mode)) {
        yield cursor;
        cursor = cursor.plus({ [`${mode}s`]: 1 });
    }
}

export const getResult = (
    firstSelectedDate: DateTime,
    secondSelectedDate: DateTime,
    mode: string,
    showFutureDates: boolean,
    oneDayOnly: boolean,
) => {
    let dateFrom = firstSelectedDate;
    let dateTo = secondSelectedDate;

    if (firstSelectedDate < secondSelectedDate) {
        dateFrom =
            mode === 'years' && !oneDayOnly ? firstSelectedDate.startOf('month').startOf('day') : firstSelectedDate;
        dateTo =
            mode === 'years' && !oneDayOnly ? secondSelectedDate.endOf('month').startOf('day') : secondSelectedDate;
    } else if (firstSelectedDate > secondSelectedDate) {
        dateFrom =
            mode === 'years' && !oneDayOnly ? secondSelectedDate.startOf('month').startOf('day') : secondSelectedDate;
        dateTo = mode === 'years' && !oneDayOnly ? firstSelectedDate.endOf('month').startOf('day') : firstSelectedDate;
    } else {
        dateFrom =
            mode === 'years' && !oneDayOnly ? firstSelectedDate.startOf('month').startOf('day') : firstSelectedDate;
        dateTo =
            mode === 'years' && !oneDayOnly ? secondSelectedDate.endOf('month').startOf('day') : secondSelectedDate;
    }

    if (!showFutureDates && dateTo.toMillis() > DateTime.now().toMillis()) {
        dateTo = DateTime.now().startOf('day');
    }
    return { dateFrom, dateTo };
};

export const shiftHelper = (date: DateTime, shiftType: 'year' | 'month') => {
    let shift = 0;
    const now = DateTime.now();
    while (now.plus({ [shiftType]: shift })[shiftType] !== date[shiftType]) {
        if (now.plus({ [shiftType]: shift })[shiftType] < date[shiftType]) {
            shift++;
        } else {
            shift--;
        }
    }
    return shift;
};

interface IGetHeaderTextArgs {
    dateFrom: DateTime;
    dateTo: DateTime;
    mode: string;
    t: TFunction;
}

export const getHeaderText = (args: IGetHeaderTextArgs) => {
    const { dateFrom, dateTo, mode, t } = args;
    let _mode = mode;
    if (
        dateFrom.toMillis() !== dateFrom.startOf('month').startOf('day').toMillis() ||
        dateTo.toMillis() !== dateTo.endOf('month').startOf('day').toMillis()
    ) {
        _mode = 'months';
    }
    switch (_mode) {
        case 'months': {
            return `${dateFrom.day} ${t(shortMonthNames[dateFrom.month]['en'])} ${dateFrom.year}  –  ${dateTo.day} ${t(
                shortMonthNames[dateTo.month]['en'],
            )} ${dateTo.year}`;
        }
        case 'years': {
            return `${t(monthNames[dateFrom.month]['en'])} ${dateFrom.year}  –  ${t(monthNames[dateTo.month]['en'])} ${
                dateTo.year
            }`;
        }

        default:
            return '';
    }
};
