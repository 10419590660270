import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { Leasing_TenantOverview_Info_Widget_Reducer_Values } from './reducer';
import { TableWrapper } from './styles';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import Cell from './components/Cell';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../reducer';
import { usePrepareData } from './hooks/usePrepareData';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

const Info: React.FC = ({}) => {
    const ref = useRef(null);
    usePrepareData();

    const { preparedData } = useSelector(Leasing_TenantOverview_Info_Widget_Reducer_Values);

    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);

    const {
        src: { reportingObjects },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const tenantId = localCurrentOptions?.selectedReportingObjectsIds?.[0];
    const tenant = reportingObjects?.find((item) => String(item.id) === String(tenantId));
    const tenantName = tenant?.name || '';
    const widgetName = `${tenantName}: ${t('Info')}`;

    const table = useMemo(() => {
        const result = Array.isArray(preparedData)
            ? preparedData?.map((item) => {
                  return <Cell data={item.data} name={item.name} key={item.key} />;
              })
            : null;

        return result;
    }, [JSON.stringify(preparedData)]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {widgetName}
                    <WidgetAdditionalControls widgetName={widgetName} pdfDownloadData={{ targetRef: ref }} />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <TableWrapper>{table}</TableWrapper>
        </WidgetWrapper>
    );
};

export default Info;
