import { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch } from './useSettings';
import { generalReducerValues } from '../General.reducer';
import { useSelector } from 'react-redux';
import { removeAlertByAlias, storeNewAlert } from '../components/Alert/reducer';
import useTranslation from './useTranslation/useTranslation';

export const useCookiesAlert = () => {
    const dispatch = useAppDispatch();
    const { user, lang } = useSelector(generalReducerValues);
    const [allowCookies, setAllowCookies] = useState<{ value: string | null }>({ value: null });
    const { t } = useTranslation('policies');

    useEffect(() => {
        let allowCookies: string | null = localStorage.getItem('allowCookies');
        setAllowCookies({ value: allowCookies });
        const intervalId = setInterval(() => {}, 1000 * 5 * 60); // 5 minutes in milliseconds
        return () => clearInterval(intervalId);
    }, []);

    const onOKClick = () => {
        setAllowCookies({ value: 'yes' });
        localStorage.setItem('allowCookies', 'yes');
    };

    useEffect(() => {
        if (!allowCookies.value && user?.id) {
            dispatch(
                storeNewAlert({
                    type: 'info',
                    text: 'COOKIES_ALERT_TEXT',
                    alias: 'COOKIES_ALERT',
                    actions: [{ text: 'OK', action: onOKClick }],
                    // link: { text: 'How it works', href: 'cookies' },
                    hasChildren: true,
                }),
            );
        } else if (allowCookies.value && user?.id) {
            dispatch(removeAlertByAlias('COOKIES_ALERT'));
        }
    }, [allowCookies, user?.id]);
};
