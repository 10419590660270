import { DateTime } from 'luxon';
import { TTF } from '../interfaces';

interface IArgs {
    from: string;
    to: string;
    timeFreq: TTF;
}

export const generateDates = ({ from, to, timeFreq }: IArgs): string[] => {
    const dateFrom = DateTime.fromISO(from);
    const dateTo = DateTime.fromISO(to);

    const dates: string[] = [];
    let counter = dateFrom;
    if (timeFreq === 'MS') {
        while (counter <= dateTo) {
            if (counter.toISODate() !== null) {
                dates.push(String(counter.toISODate()));
            }
            counter = counter.plus({ months: 1 });
        }
    } else {
        while (counter <= dateTo) {
            if (counter.toISODate() !== null) {
                dates.push(String(counter.toISODate()));
            }
            counter = counter.plus({ days: 1 });
        }
    }
    return dates;
};
