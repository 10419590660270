export const engagementThresholdInfo = [
    {
        title: 'Engagement rate',
        text: 'Configures the proportion of visitors who were in a certain location for more than the selected number of minutes',
    },
    {
        title: 'Bounced rate',
        text: 'Configures the proportion of visitors who were in a certain location less than the selected number of minutes',
    },
];

export const comebackWindowInfo = [
    {
        title: 'New visitors rate',
        text: 'Configures the proportion of new visitors who did not return within the selected time period',
    },
    {
        title: 'Returned visitors rate',
        text: 'Configures the proportion of repeat visitors who returned during the selected period',
    },
];
