import React, { useEffect, useState, useTransition } from 'react';
import { DateTime } from 'luxon';
import { MonthCell, Year, YearHeader, YearName, YearWrapper } from './styles';
import { bgColor, shortMonthNames } from '../../const';
import { IProps } from './interfaces';
import { useTranslation } from 'react-i18next';

const YearsGrid: React.FC<IProps> = ({
    allMonthsOfCalendar,
    firstDateDidChanged,
    secondDateDidChanged,
    oneDayOnly,
    showFutureDates,
    firstSelectedDate,
    secondSelectedDate,
    onYearHeaderClick,
}) => {
    const [dateUnderCursor, setDateUnderCursor] = useState<DateTime | null>(null);
    const now = DateTime.now();
    const { t } = useTranslation();

    useEffect(() => {
        if (secondSelectedDate) {
            setDateUnderCursor(secondSelectedDate.startOf('month'));
        }
    }, [secondSelectedDate]);

    const onCellClick = (month: DateTime) => () => {
        if (!oneDayOnly) {
            if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                firstDateDidChanged(month);
                secondDateDidChanged(null);
                setDateUnderCursor(null);
            } else if (firstSelectedDate && !secondSelectedDate) {
                secondDateDidChanged(month);
            }
        } else {
            if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                firstDateDidChanged(month);
                secondDateDidChanged(month);
                setDateUnderCursor(null);
            } else if (firstSelectedDate && !secondSelectedDate) {
                secondDateDidChanged(month);
            }
        }
    };

    const onCellMouseEnter = (month: DateTime) => () => {
        if (firstSelectedDate && secondSelectedDate) return;
        setDateUnderCursor(month);
    };

    const _onYearHeaderClick = (year: number) => () => {
        onYearHeaderClick(year);
    };

    const grid = allMonthsOfCalendar.map((year, i) => {
        const months = year.map((month, j) => {
            const _firstSelectedDate = firstSelectedDate?.startOf('month');
            const _secondSelectedDate = secondSelectedDate?.startOf('month');
            const disabled = showFutureDates ? false : month > now;
            const isLastUnderCusor =
                month.valueOf() === dateUnderCursor?.valueOf() ||
                (_secondSelectedDate && month.valueOf() === _secondSelectedDate?.startOf('day').valueOf()) ||
                false;
            const selected =
                _firstSelectedDate?.valueOf() === month.valueOf() || _secondSelectedDate?.valueOf() === month.valueOf();
            let bg = 'transparent';
            if (_firstSelectedDate && dateUnderCursor) {
                if (month.valueOf() === _firstSelectedDate?.valueOf() && _firstSelectedDate! < dateUnderCursor!) {
                    bg = `linear-gradient(90deg,transparent 50%,${bgColor} 0)`;
                } else if (
                    month.valueOf() === _firstSelectedDate?.valueOf() &&
                    _firstSelectedDate! > dateUnderCursor!
                ) {
                    bg = `linear-gradient(-90deg,transparent 50%,${bgColor} 0)`;
                } else if (
                    (month.valueOf() > _firstSelectedDate?.valueOf() && month.valueOf() < dateUnderCursor?.valueOf()) ||
                    (month.valueOf() < _firstSelectedDate?.valueOf() && month.valueOf() > dateUnderCursor?.valueOf())
                ) {
                    bg = `${bgColor}`;
                } else if (month.valueOf() > _firstSelectedDate?.valueOf() && isLastUnderCusor) {
                    bg = `linear-gradient(-90deg,transparent 50%,${bgColor} 0)`;
                } else if (month.valueOf() < _firstSelectedDate?.valueOf() && isLastUnderCusor) {
                    bg = `linear-gradient(90deg,transparent 50%,${bgColor} 0)`;
                }
            }
            return (
                <MonthCell
                    key={`month${i}++${j}--`}
                    disabled={disabled}
                    bg={bg}
                    selected={selected}
                    isLastUnderCusor={isLastUnderCusor}
                    onClick={onCellClick(month)}
                    onMouseEnter={onCellMouseEnter(month)}
                >
                    <span>{t(shortMonthNames[month.month]['en'])}</span>
                </MonthCell>
            );
        });
        return (
            <YearWrapper index={i} key={`${year[0].year}year${i}`}>
                <YearHeader onClick={_onYearHeaderClick(year[0].year)}>
                    <YearName>{year[0].year}</YearName>
                </YearHeader>
                <Year key={`month${i}`}>{months}</Year>
            </YearWrapper>
        );
    });

    return <>{grid}</>;
};

export default YearsGrid;
