import { FC } from 'react';
import { IProps } from './interfaces';
import { Td, Tr } from '../../styles';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import DataCell from '../DataCell/DataCell';

/**
 * Тело таблицы
 */
const Body: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation(['metrics', 'translation']);

    return (
        <tbody>
            {props.metrics.map((metric, i) => {
                const metricFromStore = allMetrics.find((item) => item.id === metric);
                return (
                    <Tr key={metric + i}>
                        <Td>{t(metricFromStore?.text || metric)}</Td>
                        <DataCell {...props} periodType={'main'} metric={metric} period={props.mainPeriod} />
                        {props.comparePeriods.map((comparePeriod, i) => {
                            return (
                                <DataCell
                                    key={`${props.reportingObject.id}_${metric}_${JSON.stringify(comparePeriod)}_${
                                        props.reportingObject.id
                                    }_${i}`}
                                    periodType={'compare'}
                                    period={comparePeriod}
                                    metric={metric}
                                    {...props}
                                />
                            );
                        })}
                    </Tr>
                );
            })}
        </tbody>
    );
};

export default Body;
