import styled from 'styled-components/macro';

interface IModuleOptionsWrapper {
    scrollDirection: string;
    isTablet: boolean;
}

export const SearchWrapper = styled.div`
    /* height: 70px; */
    /* max-height: 70px; */
    max-width: ${(p) => p.theme.spaces.sideBarWidth};
    width: 100%;
    display: flex;
    /* justify-content: stretch; */
    align-items: flex-end;

    padding-right: ${(p) => (p.theme.isRtlLanguage ? 0 : p.theme.spaces.md)};
    padding-left: ${(p) => (!p.theme.isRtlLanguage ? 0 : p.theme.spaces.md)};
    /* padding-bottom: 13px; */
    /* margin-bottom: var(--space-xxs); */
    flex-shrink: 0;
    flex-grow: 1;
`;

// const animation = (p: IModuleOptionsWrapper) => {
//     const panelTop = p.isTablet ? '70px' : '40px';
//     const appearence = (panelTop: string) => keyframes`
//     0% { top: -300px; }
//     100% { top: ${panelTop}; }
// `;
//     const disappearence = (panelTop: string) => keyframes`
//    0% { top: ${panelTop}; }
//     100% { top: -300px; }
// `;

//     return p.scrollDirection === 'UP'
//         ? css`
//               ${appearence(panelTop)} ${PANEL_ANIMATION_TIME}s ease-in-out;
//           `
//         : css`
//               ${disappearence(panelTop)} ${PANEL_ANIMATION_TIME}s ease-in-out;
//           `;
// };

export const ModuleOptionsWrapper = styled.div<IModuleOptionsWrapper>`
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);

    background-color: var(--color-white);
    /* height: 70px; */
    overflow: hidden;

    display: flex;

    padding-bottom: var(--space-xxs);
    padding-left: var(--space-md);
    padding-right: var(--space-md);
    /* border-bottom-left-radius: 20px; */
    /* padding-bottom: var(--space-xxxs); */
    position: absolute;
    margin-top: -5px;

    z-index: 15;

    width: 100vw;
    left: 0;
    top: ${(p) => p.theme.spaces.headerHeight};
    /* left: var(--space-sidebar); */

    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        width: calc(100vw);
        left: 0;
        height: auto;
        /* max-width: calc(100vw + 250px); */
        /* left: ${(p) => (p.theme.isRtlLanguage ? '0px' : '249px')}; */
        /* padding: var(--space-md); */
        /* padding-bottom: var(--space-xs); */
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
`;
