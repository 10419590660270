import React from 'react';
import cn from 'classnames';

import { Props } from './configurations';
import * as constants from '../../constants/constants';
import useStyles from '../stylesHooks/useStyles';
import './styles/styles.scss';

const Title: React.FC<Props> = ({ titleLevel = 'h2', ...props }) => {
    const mergedStyles = useStyles({ props, constants });
    const className = React.useMemo(() => {
        const { DEFAULT_SEPARATOR_ELEMENT_PREFIX } = constants;
        return cn(['title_react', `title_react${DEFAULT_SEPARATOR_ELEMENT_PREFIX}${titleLevel}`, ...mergedStyles]);
    }, [props, titleLevel]);

    return <div className={className}>{props.children}</div>;
};

export default Title;
