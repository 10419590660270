import styled from 'styled-components/macro';

export const Units = styled.span`
    color: var(--color-secondary-darker);
    line-height: 14px;
`;

export const THead = styled.thead`
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
`;

export const PeriodsWrapper = styled.div<{ periodsCount: number }>`
    display: grid;
    grid-template-columns: ${(p) => `repeat(${p.periodsCount}, 1fr)`};
    gap: 20px;
`;

export const PeriodText = styled.span`
    font-size: 12px;
    white-space: nowrap;
`;
