import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    min-width: 500px;
    max-width: 65vw;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 16px;
`;

export const Link = styled.a`
    color: var(--color-primary-light);
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
        color: var(--color-primary);
    }
`;

export const Extension = styled.div`
    background: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
    color: white;
    border-radius: 5px;
`;

export const FileName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
