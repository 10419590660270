import { IMetric, TMetricResponse } from '../../../../../General.interfaces';
import getDifferenceBetweenNumbers from '../../../../../tools/getDifferenceBetweenNumbers';
import { valueFormatter } from '../../../../../tools/Strings/valueFormatter';
import { getColorFromPercent } from '../../../../../tools/getColorFromPercent';
import { isNull, isNumber } from 'lodash';
import { IRatingsDataAdapterResponseItem } from '../interfaces';

interface IDynamicsDefaultDAArgs {
    numberToShow?: number;
    /** Ответ от сервера. Сюда приходит ответ от запроса метрик */
    response: TMetricResponse[];
    /** Все метрики */
    allMetrics: IMetric[];
}

/**
 * Подготовка данных для виджета. Функция возвращает конфигурацию для компонента динамики
 */
export const ratingDefaultTopAdapter = (args: IDynamicsDefaultDAArgs): IRatingsDataAdapterResponseItem[] => {
    const { response, allMetrics, numberToShow = 5 } = args;

    const metric = allMetrics.find((metric) => metric.id === response?.[0]?.[0]?.context?.metric);

    const mainArray = response.find((item) => {
        return item?.[0]?.['context']?.alias ? item?.[0]?.['context']?.alias.includes('mainPeriod') : false;
    });

    const compareArray = response.find((item) => {
        return item?.[0]?.['context']?.alias ? item?.[0]?.['context']?.alias.includes('comparePeriod') : false;
    });

    const filteredMainArray =
        mainArray
            ?.sort((a, b) => {
                return Number(b.items[0].value) - Number(a.items[0].value);
            })
            .filter((item, i) => {
                const itemValue = item.items?.[0]?.value as number | null;
                const condition = i < numberToShow && !isNull(itemValue);
                return condition;
            }) || [];

    const max = filteredMainArray[0]?.items?.[0]?.value as number | null | undefined;

    const dataStructure = filteredMainArray.map((item) => {
        const { id, name } = item.context.data_objects[0];
        const mainValue = item.items?.[0]?.value as number | null | undefined;
        const percentOfMax = (isNumber(mainValue) && isNumber(max) && Math.round((100 * mainValue) / max)) || 0;
        const compareData = compareArray?.find((item) => item.context.data_objects[0].id === id);
        const compareValue = compareData?.items[0].value as number | null | undefined;
        const diff =
            (isNumber(mainValue) && isNumber(compareValue) && getDifferenceBetweenNumbers(mainValue, compareValue)) ||
            null;

        return {
            name,
            mainValue: {
                value: valueFormatter({
                    value: mainValue,
                    precision: metric?.round_decimal_places,
                    units: metric?.units,
                    showUnits: false,
                }),
                percentOfMax,
            },
            diff: {
                value: valueFormatter({
                    value: diff?.percentDifference,
                    precision: 1,
                    showSpaceBetweenNumberAndUnit: false,
                    units: '%',
                }),
                color: getColorFromPercent(diff?.percentDifference),
            },
        };
    });

    return dataStructure;
};
