import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import { detailOptions as options } from '../../../constants/detailOptions';
import { TTimeFreq } from '../../../tools/API/interfaces';
import Select from '../Select/Select';
import { IObject } from '../Select/interfaces';
import { useAppDispatch } from '../../../hooks/useSettings';

const DetailSelect: React.FC<IProps> = ({
    restrictedOptions = [],
    name = 'detail',
    localCurrentOptions,
    changeOptions,
    ...props
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const translatedOptions = options
        ?.filter((item: { id: TTimeFreq; text: string }) => !restrictedOptions.includes(item.id))
        .map((item) => {
            return { ...item, text: item.text };
        });

    const { locations, currentModuleID, selectedLocationId } = useSelector(generalReducerValues);

    useEffect(() => {
        const translatedOptionsIds = translatedOptions.map((element) => element.id);

        if (
            translatedOptions.length &&
            selectedLocationId &&
            localCurrentOptions &&
            !translatedOptionsIds.includes(localCurrentOptions?.[name]?.id as TTimeFreq)
        ) {
            changeOptions({
                newOptions: {
                    [name]: translatedOptions[0],
                },
            });
        }
    }, [JSON.stringify(translatedOptions), localCurrentOptions]);

    const handleSelect = (
        args: {
            id: string | number | null;
            text?: string;
        }[],
    ) => {
        const initial = options?.find((obj) => obj?.id === args?.[0]?.id);
        selectedLocationId &&
            initial?.text &&
            changeOptions({
                newOptions: {
                    [name]: initial as IObject,
                },
            });
    };

    return (
        <Wrapper>
            <Select
                testId={`${currentModuleID}_detail`}
                disabled={locations.length === 0}
                options={translatedOptions.map((element) => ({ ...element, text: t(element.text) }))}
                dropdownWidth="sm"
                dropdownAlign="left"
                showFilter={false}
                mode="single"
                labelText={t('Detail')}
                iconType="chevron-down"
                handleSelect={handleSelect}
                outsideText={t(localCurrentOptions?.[name]?.text || '')}
                outsideSelected={[localCurrentOptions?.[name] || { id: 'd', text: 'Days' }]}
            />
        </Wrapper>
    );
};

export default DetailSelect;
