import Highcharts, { Chart, Options } from 'highcharts';
import { IDynamicsProps, TSeries } from './interfaces';
import React, { useEffect, useMemo, useState } from 'react';

import DynamicsOptions from './components/DynamicsOptions/DynamicsOptions';
import HighchartsReact from 'highcharts-react-official';
import { Wrapper } from './styles';
import { chartSettingsReducerValues } from '../../ChartSettings/reducer';
import generateOptions from './tools/generateOptions';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import useTranslation from '../../../hooks/useTranslation/useTranslation';

/**
 * Компонент для отображения графиков типа "Динамика"
 */
const Dynamics: React.FC<IDynamicsProps> = ({ chartId, externalChartSettings, testId, ...props }) => {
    const { chartSettingsById } = useSelector(chartSettingsReducerValues);
    const { mainAreaSize } = useSelector(generalReducerValues);
    const [options, setOptions] = useState<Options>();
    const windowDimensions = mainAreaSize;
    const { t } = useTranslation(['metrics', 'translation']);

    useEffect(() => {
        const extendedOptions = generateOptions({
            chartSettings: externalChartSettings
                ? externalChartSettings
                : chartId && !externalChartSettings
                ? chartSettingsById[chartId]
                : undefined,
            windowDimensions,
            t,
            ...props,
        });

        setOptions(extendedOptions as Options);
    }, [
        JSON.stringify(props.customChartDimensions),
        JSON.stringify(props.series),
        chartSettingsById,
        windowDimensions,
        chartId,
        externalChartSettings,
        t,
    ]);

    const afterChartCreated = (chart: Chart) => {
        props.storeChartInstance && props.storeChartInstance(chart);
    };

    const chart = useMemo(() => {
        if (options) {
            return <HighchartsReact callback={afterChartCreated} highcharts={Highcharts} options={options} />;
        }

        return null;
    }, [options]);

    return (
        <Wrapper customHeight={props.customChartDimensions?.height}>
            {chart}
            {!externalChartSettings && chartId && options && (
                <DynamicsOptions
                    testId={testId + ':settings'}
                    chartId={chartId}
                    series={(options?.series as TSeries[]) || []}
                />
            )}
        </Wrapper>
    );
};

export default Dynamics;
