import { SpinnerLabel, SpinnerWrapper, Wrapper } from './styles';
import { SPINNER_TYPES_MAP } from './constants/constants';
import { IProps } from './interfaces';
import Lottie from 'lottie-react';
import { FC } from 'react';

const Spinner: FC<IProps> = (props) => {
    const { size = 'medium', labelPosition = 'after', type = 'default' } = props;

    return (
        <Wrapper labelPosition={labelPosition} type={type}>
            <SpinnerWrapper size={size}>
                <Lottie animationData={SPINNER_TYPES_MAP[type] || SPINNER_TYPES_MAP.default} />
            </SpinnerWrapper>
            {props.label && <SpinnerLabel>{props.label}</SpinnerLabel>}
        </Wrapper>
    );
};

export default Spinner;
