import { IObject, TNestedOptions } from '../interfaces';
import { isSimple } from './getOptionsType';

export const getPlainFilteredOptions = (options: IObject[] | TNestedOptions, filter: string) => {
    if (isSimple(options)) {
        return options?.filter(
            (option) => filter === '' || option.text.toLowerCase().includes(filter.toLowerCase()),
        ) as IObject[];
    } else {
        return options.reduce((acc, item) => {
            const objects: IObject[] = item.objects?.filter(
                (option) => filter === '' || option.text.toLowerCase().includes(filter.toLowerCase()),
            );
            return [...acc, ...objects];
        }, []);
    }
};
