import styled from 'styled-components/macro';

export const IconWrapper = styled.div`
    align-self: center !important ;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
        color: var(--color-primary-darker);
    }
`;
