import AnalysisByArea from './widgets/AnalysisByArea/AnalysisByArea';
import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import DynamicsPMO from './widgets/DynamicsPMO/DynamicsPMO';
import EventsCarousel from '../../Events/Events/widgets/EventsCarousel/EventsCarousel';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../components/Selects/MetricsSelect/MetricsSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import React from 'react';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import SummaryTable from './widgets/SummaryTable/SummaryTable';
// import Traffic from './widgets/TrafficOld/Traffic';
import Traffic from './widgets/Traffic/Traffic';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';

/**
 * Компонент для отображения модуля "Dashboard"
 */
const Dashboard: React.FC = () => {
    const { user, currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ComparePeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ObjectsSelect
                            maxSelected={10}
                            dataObjectFilters={[
                                'data_objects_tenant',
                                'data_objects_floor',
                                'data_objects_passway_type',
                            ]}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <MetricsSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'selectedReportingObjectsIds',
                                'selectedMetrics',
                                'comparePeriods',
                                'mainPeriod',
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>

            <WrapperContainer>
                <SummaryTable />
                <EventsCarousel moduleName={'Performance:Dashboard'} />
                <AnalysisByArea />
                <DynamicsPMO />
                {/* {user?.permissions.includes('core/data-source | fpc | api/read') && <Traffic />} */}
                {user?.permissions.includes('core/data-source | fpc | api/read') && <Traffic />}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Dashboard;
