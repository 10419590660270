import { FC, useEffect, useState } from 'react';
import { IEventType } from '../../../../interfaces';
import { Color, Name, NameWrapper, Wrapper } from './styles';
import Tooltip from '../../../../../../../../../components/Tooltip/Tooltip';
import useTranslation from 'src/hooks/useTranslation/useTranslation';

interface IProps {
    /** Тип события */
    eventType: IEventType;
    /** Флаг состояния бокового меню */
    open: boolean;
}

/**
 * КОмпонент для отображения каждого типа события в боковом меню
 */
const EventItem: FC<IProps> = ({ eventType, open }) => {
    const { t } = useTranslation(['translationEvents']);
    const [transitionOpen, setTransitionOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => setTransitionOpen(open), 200);
    }, [open]);

    return (
        <Wrapper>
            <Tooltip positioning={'right'} showTooltip={!open} content={t(eventType.name)}>
                <NameWrapper>
                    <Color color={eventType.color} />
                    {(open ? transitionOpen : false) && <Name>{t(eventType.name)}</Name>}
                </NameWrapper>
            </Tooltip>
        </Wrapper>
    );
};

export default EventItem;
