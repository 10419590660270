import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { ContextualMenuItemType, IContextualMenuItem, ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { FocusZoneDirection } from '@fluentui/react/lib/FocusZone';
import { IContextMenuProps } from './interfaces';
import {
    toggleContextMenu,
    Configuration_Categories_Widget_Reducer_Values,
    changeFilters,
    resetFilters,
} from '../../../../reducer';
import { generalReducerValues, reloadCategories } from '../../../../../../../../../General.reducer';
import { IProjectCategory } from '../../../../../../../../../General.interfaces';
import FilterInput from '../../../../../../../../../components/FilterInput/FilterInput';
import { CategoriesAPI } from '../../../../../../../../../tools/API/categoriesAPI';

/**
 * Компонент Контекстного меню
 */
const ContextMenu: React.FC<IContextMenuProps> = () => {
    const { contextMenu, filters } = useSelector(Configuration_Categories_Widget_Reducer_Values);
    const {
        token,
        user,
        src: { projectCategories },
        urlsByServices,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const categoriesUrl = useMemo(() => {
        if (urlsByServices) return urlsByServices['core/admin-service'].CATEGORIES_URL;
        return null;
    }, [urlsByServices]);

    const onHideContextualMenu = () => {
        dispatch(toggleContextMenu({ show: false }));
    };

    const changeValue = (value: string, mode?: string) => {
        contextMenu.col && dispatch(changeFilters({ value, colIndex: contextMenu.col, mode }));
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    function renderFilterItem(): JSX.Element {
        if (!contextMenu.col) return <></>;
        return (
            <FilterInput value={filters[contextMenu.col]?.filter || ''} changeValue={changeValue} isOutsideControlled />
        );
    }

    const availableCategories = useMemo(() => {
        const addEditCategory =
            (args: { objectId: number | undefined; categoryId: number; categoryName: string; relationId?: number }) =>
            () => {
                if (!args.objectId || !categoriesUrl) return;
                !relationId &&
                    CategoriesAPI({
                        user,
                        token,
                        chapter: 'data-obj-2-project-category',
                        data: { data_object_id: args.objectId, category_id: args.categoryId },
                        method: 'POST',
                        url: categoriesUrl,
                    }).then(() => {
                        dispatch(reloadCategories());
                    });
                relationId &&
                    CategoriesAPI({
                        user,
                        token,
                        chapter: 'data-obj-2-project-category',
                        data: { category_id: args.categoryId },
                        method: 'PATCH',
                        id: relationId,
                        url: categoriesUrl,
                    }).then(() => {
                        dispatch(reloadCategories());
                    });

                onHideContextualMenu();
            };

        const deleteCategory = (relationId: number) => () => {
            if (!relationId || !categoriesUrl) return;
            CategoriesAPI({
                user,
                token,
                chapter: 'data-obj-2-project-category',
                id: relationId,
                method: 'DELETE',
                url: categoriesUrl,
            }).then(() => {
                dispatch(reloadCategories());
            });
            onHideContextualMenu();
        };

        const objectId = contextMenu?.object?.id;
        const relationId = contextMenu?.cell?.relation?.id;

        const result: IContextualMenuItem[] = projectCategories
            ?.filter((item) => item.parent_id === contextMenu?.cell?.id)
            .map((item: IProjectCategory) => {
                return {
                    key: String(item.id),
                    text: item.object_name,
                    canCheck: true,
                    // isChecked: selection[keys[6]],
                    onClick: addEditCategory({
                        objectId,
                        categoryId: item.id,
                        categoryName: item.object_name,
                        relationId,
                    }),
                };
            });

        result.push({
            key: 'none',
            text: 'None',
            canCheck: true,
            // topDivider: true,
            // isChecked: selection[keys[6]],
            onClick: deleteCategory(relationId),
        });

        return result;
    }, [
        contextMenu?.cell?.id,
        contextMenu?.cell?.relation?.id,
        contextMenu?.object?.id,
        dispatch,
        onHideContextualMenu,
        projectCategories,
        categoriesUrl,
        token,
    ]);

    let menuItems: IContextualMenuItem[] =
        contextMenu.row !== 0
            ? [
                  {
                      key: 'filters',
                      itemType: ContextualMenuItemType.Section,
                      sectionProps: {
                          //   topDivider: true,
                          //   bottomDivider: true,
                          title: `${t('Filters')}`,

                          items: [
                              {
                                  key: 'filter',
                                  text: `${t('Filter')}`,
                                  // onClick: () => {},
                                  subMenuProps: {
                                      focusZoneProps: { direction: FocusZoneDirection.bidirectional },
                                      items: [
                                          {
                                              key: 'filter',
                                              text: `${t('Filter')}`,
                                              onRender: renderFilterItem,
                                          },
                                      ],
                                  },
                              },
                              {
                                  key: 'filterByValue',
                                  text: `${t('Filter by value')}`,
                                  onClick: () => changeValue(contextMenu.cell?.value || ''),
                              },
                              {
                                  key: 'filterByAnyValue',
                                  text: `${t('Filter by any value')}`,
                                  onClick: () => changeValue('__', 'any'),
                              },
                              {
                                  key: 'filterByNoValue',
                                  text: `${t('Filter by no value')}`,
                                  onClick: () => changeValue('__', 'no'),
                              },
                              {
                                  key: 'reset',
                                  text: t('Reset filters'),
                                  onClick: onResetFilters,
                              },
                          ],
                      },
                  },
              ]
            : [
                  {
                      key: 'reset',
                      text: t('Reset filters'),
                      onClick: onResetFilters,
                  },
              ];

    if (contextMenu.row !== 0 && contextMenu.col && contextMenu.col >= 2) {
        const add = {
            key: 'editCategory',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                topDivider: true,
                bottomDivider: true,
                title: `${t('Add/Edit category')}`,
                items: availableCategories,
            },
        };

        menuItems = [...menuItems, add];
    }

    return useMemo(() => {
        return (
            <ContextualMenu
                items={menuItems}
                hidden={!contextMenu.show}
                target={contextMenu.cursorCoords}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        );
    }, [menuItems, contextMenu.show, contextMenu.cursorCoords, onHideContextualMenu]);
};

export default ContextMenu;
