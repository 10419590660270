import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getHeaders } from './getHeaders';

/**
 * Функция для запроса локаций
 */
const requestLocations = async (token: string, url: string) => {
    axiosRetry(axios, { retries: 3, retryDelay: () => 3000, retryCondition: (_error) => true });

    try {
        const response = await axios({
            method: 'POST',
            url,
            data: JSON.stringify({
                auth: {
                    xtoken: token,
                },
            }),
            headers: getHeaders(token),
        });

        return response;
    } catch (error) {
        return error;
    }
};

export default requestLocations;
