import {
    IChartOptionsGeneratorSettings,
    IExtendedReportingObject,
    IPathToPurchaseWidgetState,
    TRawMetricsData,
} from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store';

const initialState: IPathToPurchaseWidgetState = {
    moduleName: 'Performance:Path to purchase',
    chartOptionsGeneratorSettings: null,
    extendedReportingObjects: null,
    rawMetricsData: null,
    dataRefetchObject: {},
};

const Performance_PathToPurchase_Widget_Reducer = createSlice({
    name: 'PathToPurchaseWidget',
    initialState,
    reducers: {
        /**
         * Сохранение сырых данных по метрикам
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Сохранение настроек для генерации опций графика
         */
        storeChartOptionsGeneratorSettings: (state, action: PayloadAction<null | IChartOptionsGeneratorSettings>) => {
            state.chartOptionsGeneratorSettings = action.payload;
        },

        /**
         * Сохранение расширенных данных по отчетному объекту
         */
        storeExtendedReportingObjects: (state, action: PayloadAction<null | IExtendedReportingObject[]>) => {
            state.extendedReportingObjects = action.payload;
        },

        /**
         * Перезапросить данные
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },

        /**
         * Обнуление редьюсера
         */
        resetReducer: (state) => {
            state.chartOptionsGeneratorSettings = null;
            state.extendedReportingObjects = null;
            state.rawMetricsData = null;
        },
    },
});

export const {
    storeChartOptionsGeneratorSettings,
    storeExtendedReportingObjects,
    storeRawMetricsData,
    resetReducer,
    reloadWidget,
} = Performance_PathToPurchase_Widget_Reducer.actions;

export const Performance_PathToPurchase_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_PathToPurchase_Widget_Reducer;

export default Performance_PathToPurchase_Widget_Reducer.reducer;
