import { IPreparedGridData, IReducerState, TRowMetricsData } from './interfaces';
import { ISheetCell } from '../../../../../General.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';

const initialState: IReducerState = {
    downloadSignalCounter: 0,
    rawMetricsData: null,
    preparedGridData: null,
    grid: null,
    keyWord: 'ByTenants',

};

export const Reports_ByTenants_Widget_Reducer = createSlice({
    name: 'Reports_ByTenants_Widget_Reducer',
    initialState,

    reducers: {
        /**
         * Сигнал на скачивание
         */
        increaserDownloadSignalCounter: (state, _: PayloadAction) => {
            state.downloadSignalCounter += 1;
        },

        /**
         * Сохранение сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TRowMetricsData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Сохранение подготовленных данных, необходимых для построения таблицы
         */
        storePreparedGridData: (state, action: PayloadAction<IPreparedGridData | null>) => {
            state.preparedGridData = action.payload;
        },

        /**
         * Сохранение таблицы
         */
        storeGrid: (state, action: PayloadAction<ISheetCell[][] | null>) => {
            state.grid = action.payload;
        },

        /**
         * Обнуление рельюсера
         */
        resetTenantsWidgetReducer: () => initialState,
    },
});

export const {
    increaserDownloadSignalCounter,
    resetTenantsWidgetReducer,
    storePreparedGridData,
    storeRawMetricsData,
    storeGrid,
} = Reports_ByTenants_Widget_Reducer.actions;

export const Reports_ByTenants_Widget_Reducer_Values = (state: RootState) => state.Reports_ByTenants_Widget_Reducer;

export default Reports_ByTenants_Widget_Reducer.reducer;
