import styled from 'styled-components/macro';

export const PresetsWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        display: none;
    }
`;
