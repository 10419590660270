import { CSSProperties } from 'react';
import { TSpinnerLabelPosition, TSpinnerSize } from '../interfaces';
import { spinnerFocustech, spinnerMallsense, spinnerDefault } from '../spinners';

export const SPINNER_DIMENSIONS_BY_SIZE: Record<TSpinnerSize, { width: number; height: number }> = {
    'extra-tiny': {
        width: 16,
        height: 16,
    },
    tiny: {
        width: 20,
        height: 20,
    },
    'extra-small': {
        width: 24,
        height: 24,
    },
    small: {
        width: 28,
        height: 28,
    },
    medium: {
        width: 32,
        height: 32,
    },
    large: {
        width: 36,
        height: 36,
    },
    'extra-large': {
        width: 40,
        height: 40,
    },
    huge: {
        width: 44,
        height: 44,
    },
};

export const SPINNER_WRAPPER_STYLES_BY_LABEL_POSITION: Record<
    TSpinnerLabelPosition,
    { flexDirection: CSSProperties['flexDirection'] }
> = {
    above: {
        flexDirection: 'column-reverse',
    },
    below: {
        flexDirection: 'column',
    },
    before: {
        flexDirection: 'row-reverse',
    },
    after: {
        flexDirection: 'row',
    },
};
export const SPINNER_WRAPPER_STYLES_BY_TYPE: Record<string, { gap: string }> = {
    default: { gap: '10px' },
    'mallsense.ae': { gap: '25px' },
    'focustech.xyz': { gap: '10px' },
};

export const SPINNER_TYPES_MAP = {
    default: spinnerDefault,
    'mallsense.ae': spinnerMallsense,
    'focustech.xyz': spinnerFocustech,
    'focus.bi': spinnerFocustech,
};
