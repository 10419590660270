import React from 'react';
import { NavLink } from 'react-router-dom';

import useTranslation from 'src/hooks/useTranslation/useTranslation';

export const CookiesAlertBody: React.FC = () => {
    const { t } = useTranslation(['policies', 'translation']);
    return (
        <p style={{ margin: 0 }}>
            {`${t(
                'We use cookies. They help us make this site more user-friendly. By continuing to use the site, you agree to the',
            )} `}
            <NavLink to={'/cookies'}>{t('Privacy Policy')}</NavLink>
            {` ${t('in full.')}`}
        </p>
    );
};
