import { useSelector } from 'react-redux';
import { Configuration_RentPayments_Module_Reducer_Values } from '../reducer';
import { COLUMNS } from '../constants/constants';
import { cloneDeep } from 'lodash';
import { useLocalStorage } from '../../../../hooks/dataObjectsSelectFilters/useLocalStorage';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../General.reducer';
import { stringDate } from '../../../../tools/Strings/stringDate';
import { IPeriod } from '../../../../General.interfaces';
import { useMemo } from 'react';
import { IObject } from '../../../../components/Selects/Select/interfaces';
import { Data, Headers } from 'react-csv/lib/core';

export const useCreateCSVTemplate = (): {
    csvHeaders: Headers;
    csvData: Data;
    fileName: string;
} => {
    const { dataToEdit } = useSelector(Configuration_RentPayments_Module_Reducer_Values);
    const { currentModuleID, lang } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const period: { dateFrom: string | null; dateTo: string | null } = useMemo(() => {
        const result: { dateFrom: string | null; dateTo: string | null } = { dateFrom: null, dateTo: null };
        localCurrentOptions?.['period'].forEach((item: any) => {
            result[item.id] = item.text;
        });
        return result;
    }, [localCurrentOptions?.['period']]);

    const fileName = stringDate(period, lang);

    const csvHeaders = COLUMNS.map((col) => {
        return { key: col.colId, label: col.colName };
    });

    const cloned = cloneDeep(dataToEdit);
    cloned.shift();

    const csvData: Data =
        cloned.map((row) => {
            const obj = {};
            row.forEach((cell) => {
                obj[cell.colId] = cell.value || '';
            });
            return obj;
        }) || [];

    return { csvHeaders, csvData, fileName };
};
