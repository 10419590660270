import styled from 'styled-components/macro';

export const Item = styled.div<{ selected: boolean }>`
    cursor: pointer;
    padding: var(--space-xxxs);
    background-color: ${(p) => (p.selected ? 'var(--color-primary)' : 'auto')};
    color: ${(p) => (p.selected ? 'var(--color-white)' : 'auto')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    :hover {
        color: ${(p) => (p.selected ? 'var(--color-white)' : 'var(--color-primary)')};
    }
`;

export const CheckboxWrapper = styled.div`
    padding: var(--space-xxxxs) var(--space-xxxs);
`;
