import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';

const initialState = {};

export const Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer = createSlice({
    name: 'Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer',
    initialState,
    reducers: {},
});

export const {} = Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer.actions;

export const Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values = (state: RootState) =>
    state.Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer;

export default Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer.reducer;
