import { FC } from 'react';
import Number from '../microWidgets/Number/Number';
import { IProps } from './interfaces';
import PieChart from '../microWidgets/PieChart/PieChart';
import Rating from '../microWidgets/Rating/Rating';
import HeatMap from '../microWidgets/HeatMap/HeatMap';
import Table from '../microWidgets/Table/Table';
import Events from '../microWidgets/Events/Events';
import StackedBarChart from '../microWidgets/StackedBarChart/StackedBarChart';
import Dynamics from '../microWidgets/Dynamics/Dynamics';

const widgetMap = {
    number: Number,
    dynamics: Dynamics,
    rating: Rating,
    piechart: PieChart,
    heatmap: HeatMap,
    table: Table,
    events: Events,
    stackedBarChart: StackedBarChart,
};

type WidgetType = keyof typeof widgetMap;

const WidgetHub: FC<IProps> = ({
    widgetDimensions,
    dashboardKey,
    moduleId,
    widgetConfig,
    updateWidgetTrans,
    reportingObjects,
}) => {
    const [widgetType] = (widgetConfig?.id?.split(':') || []) as WidgetType[];

    if (widgetType === undefined) {
        return null;
    }

    const WidgetComponent = widgetMap[widgetType];

    return (
        <WidgetComponent
            widgetDimensions={widgetDimensions}
            dashboardKey={dashboardKey}
            widgetConfig={widgetConfig}
            moduleId={moduleId}
            updateWidgetTrans={updateWidgetTrans}
            reportingObjects={reportingObjects}
        />
    );
};

export default WidgetHub;
