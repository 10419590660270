import { emptyArray } from 'src/tools';
import { TFunction, TTranslationNS } from 'src/hooks/useTranslation';

import { PointsOfSalesResponse, PointOfSale, ReportingObjectsById } from '../interfaces';

import { posDataByTenantId } from './helpers';

// const columnTitles = [
//     'PosId',
//     'PosRegId',
//     'AccumulatorId',
//     'FirstDate',
//     'FirstTime',
//     'LastDate',
//     'LastTime',
//     'SalesCount',
//     'Returns',
// ]; // TODO: Required translations

export function posEntitiesByTenantAdapter({
    response,
    reportingObjectsById,
    t,
}: {
    response: PointsOfSalesResponse;
    reportingObjectsById: ReportingObjectsById;
    t: TFunction<TTranslationNS>;
}) {
    const { results = emptyArray as PointOfSale[] } = response?.data;

    const possByTenant = posDataByTenantId(results, reportingObjectsById, t);

    return possByTenant;
}
