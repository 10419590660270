import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../../../../../General.reducer';
import { stringDate } from '../../../../../../../../../../../../../tools/Strings/stringDate';
import { IExtendedEvent } from '../../../../../../../../interfaces';
import { DataItem } from './styles';

interface IProps {
    /** Событие */
    event: IExtendedEvent;
}

/**
 * Контент внутри тултипа, при наведении на событие
 */
const EventTooltipContent: FC<IProps> = ({ event }) => {
    const {
        lang,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const selectedReportingObjects = useMemo(() => {
        return event.data_objects_ids.reduce((acc, id, index) => {
            if (index + 1 === event.data_objects_ids.length) {
                acc += `${reportingObjectsById[id]?.name || id}`;
            } else {
                acc += `${reportingObjectsById[id]?.name || id}, `;
            }
            return acc;
        }, '');
    }, [reportingObjectsById, event]);

    return (
        <>
            <DataItem>
                <span>{t('Event name')}:</span>
                {event.name}
            </DataItem>
            <DataItem>
                <span>{t('Event period')}:</span>
                {stringDate({ dateFrom: event.date_from, dateTo: event.date_to }, lang, '', 'd MMMM yyyy HH:mm')}
            </DataItem>
            <DataItem>
                <span>{t('Event type')}:</span>
                {event.event_type_name}
            </DataItem>
            <DataItem>
                <span>{t('Reporting objects')}:</span>
                {selectedReportingObjects}
            </DataItem>
            <DataItem>
                <span>{t('Comment')}:</span>
                {event.comment}
            </DataItem>
        </>
    );
};

export default EventTooltipContent;
