import { useTranslation } from 'react-i18next';
import { CompanyText, Container, Wrapper } from './styles';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import { domainNamesMapping } from '../../constants/domainNamesMapping';

/**
 * Компонент для отображения "Подвала" кабинета
 */
const Footer = () => {
    const { domain } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container>
                <CompanyText>
                    ©&nbsp;<span>{t(domainNamesMapping[domain] || '')}</span>,&nbsp;
                    <span>{DateTime.now().year}</span>
                </CompanyText>
            </Container>
        </Wrapper>
    );
};

export default Footer;
