import { FC, useState } from 'react';
import { IProps } from './interfaces';
import useFetchData from '../../api/useFetchData';
import ReactDataSheet from 'react-datasheet';
import { ICell, ICellAdditionalOptions, IDetailedPeriodModalStatus } from '../../interfaces';
import Stack from '../../../../../../../components/Stack/Stack';
import { TableWrapper } from './styles';
import CellRenderer from '../CellRenderer/CellRenderer';
import DetailedPeriodModal from '../DetailedPeriodModal/DetailedPeriodModal';
import Modals from '../../../../../../../components/Modals/Modals';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';

const Tables: FC<IProps> = (props) => {
    const [detailedPeriodModalStatus, setDetailedPeriodsModalStatus] = useState<IDetailedPeriodModalStatus>({
        show: false,
    });

    const { data } = useFetchData(props);
    const { t } = useTranslation();

    const openDetailedPeriodModal = (data: ICellAdditionalOptions) => {
        setDetailedPeriodsModalStatus({ show: true, additionalOptions: data });
    };

    const cellRenderer = (props: ReactDataSheet.CellRendererProps<ICell>) => {
        return <CellRenderer {...props} openDetailedPeriodModal={openDetailedPeriodModal} />;
    };

    const closeDetailedPeriodModal = () => {
        setDetailedPeriodsModalStatus({ show: false });
    };

    return (
        <>
            <Modals
                title={`${t('Period')}`}
                modalStatus={detailedPeriodModalStatus}
                closeModal={closeDetailedPeriodModal}
                topColor="#fff"
            >
                <DetailedPeriodModal {...detailedPeriodModalStatus} />
            </Modals>
            <Stack styles={{ overflow: 'auto' }}>
                {Object.entries(data?.tablesByReportingObjectId || {}).map(([key, value]) => (
                    <TableWrapper key={key}>
                        <ReactDataSheet
                            valueRenderer={(cell: ICell) => cell.value}
                            cellRenderer={cellRenderer}
                            data={value}
                        />
                    </TableWrapper>
                ))}
            </Stack>
        </>
    );
};

export default Tables;
