import styled from 'styled-components/macro';

export const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
`;

export const ButtonWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
        width: 100%;
    }
`;
