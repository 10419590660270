import { useTranslation } from 'react-i18next';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isTabletOutboundWidth, isMobileInboundWidth } from 'src/theme';

import { CrossVisitsMapsSettingsIds, ICrossVisitsSelectOption } from '../../constants/crossVisitsMapsSettings';
import { ILayer, IPlan } from '../../../../../General.interfaces';
import { CROSS_VISITS_MAPS_SETTINGS } from '../../constants/constants';
import Floors from '../../../components/Floors/Floors';
import { ResponseStatus } from '../../../../../tools/API/constants';
import { Maps_CrossVisits_Module_Reducer_Values } from '../../reducer';
import { generalReducerValues } from '../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { withLoading } from '../../../../../tools/API/withLoading';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { ZONES_WORD } from '../../../../../constants/constants';
import { TSupportedLayerTypes } from '../../../components/Floors/interfaces';
import { getObjSectionName } from '../../../../../tools/Strings/getObjSectionName';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

import getDataForLoadingWrapper from './tools/getDataForLoadingWrapper';
import { MapsWrapper, StageWrapper } from './styles';
import {
    Maps_CrossVisits_Widget_Reducer_Values,
    storeSelectedGroupOfZones,
    storeSelectedReportingObjectMarker,
    storeSelectedReportingObjectType,
} from './reducer';
import Ratings from './components/Ratings/Ratings';
import { AvailableReportingObjectsTypes } from './interfaces';

/**
 * Виджет для отображения виджета среднедневных пересечений
 */
const CrossVisitsWidget = () => {
    const { rawVersionsData, moduleName } = useSelector(Maps_CrossVisits_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        rawComparisonAverageIntersectionsData,
        selectedReportingObjectMarker,
        selectedReportingObjectType,
        rawAverageIntersectionsData,
        selectedGroupOfZones,
        availableMarkers,
        colorsByMarker,
        extendedLayers,
        rawLayersData,
        rawPlansData,
    } = useSelector(Maps_CrossVisits_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsByType, reportingObjectsByTypeAndMarker },
        structures,
        mainAreaSize,
    } = useSelector(generalReducerValues);
    const stageAreaRef = useRef(null);
    const widgetWrapperRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isMobile = isMobileInboundWidth(mainAreaSize.width);

    const zonesItems = Object.keys(reportingObjectsByType)
        .filter((key) => key.includes(ZONES_WORD))
        .map((key) => ({
            id: key,
            layerType: 'zones_layer',
            text: getObjSectionName({ t, reportingObject: reportingObjectsByType[key][0] }),
        }));

    const tabListItems = [
        ...zonesItems,
        {
            id: 'tenant',
            layerType: 'tenants_layer',
            text: t('Tenants'),
        },
        {
            id: 'floor',
            layerType: 'perimeter_layer',
            text: t('Floors'),
        },
    ];

    const layerType: TSupportedLayerTypes =
        (tabListItems?.find((item) => item.id === selectedReportingObjectType)?.layerType as TSupportedLayerTypes) ||
        'places_layer';

    const tabListSelectHandler = (id: string) => {
        dispatch(storeSelectedReportingObjectType(id as AvailableReportingObjectsTypes));

        id.includes(ZONES_WORD)
            ? dispatch(storeSelectedGroupOfZones(id.replace(`${ZONES_WORD}: `, '')))
            : dispatch(storeSelectedGroupOfZones(null));
    };
    const stageAreaSize = useMemo(() => {
        const width = widgetWrapperRef.current?.getBoundingClientRect().width;
        const sideBarVisible = isTabletOutboundWidth(mainAreaSize.width);
        return sideBarVisible
            ? { width: width || mainAreaSize.width - 250, height: mainAreaSize.height }
            : mainAreaSize;
    }, [mainAreaSize, widgetWrapperRef]);

    const storeSelectedReportingObjectMarkerTrans = (value: string | null) => {
        dispatch(storeSelectedReportingObjectMarker(value));
    };

    const maps = useMemo(() => {
        if (rawVersionsData) {
            const loadingData = getDataForLoadingWrapper([
                structures || { status: ResponseStatus.Error, message: t('Get structure error') },
                rawVersionsData,
                rawLayersData,
                rawPlansData,
            ]);

            const stageWidthCalculationFactor = localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                (element: ICrossVisitsSelectOption) => element.id === CrossVisitsMapsSettingsIds.ShowRatings,
            );

            const WithLoadingTable = withLoading(MapsWrapper, { height: 400, data: loadingData });
            return (
                <WithLoadingTable>
                    {rawPlansData && extendedLayers && (
                        <Floors
                            showAllLabels={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.ShowLabels,
                                )
                            }
                            showPlans={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.ShowPlans,
                                )
                            }
                            showPerimeters={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.ShowPerimeters,
                                )
                            }
                            joinFloors={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.JoinFloors,
                                )
                            }
                            status={
                                rawAverageIntersectionsData?.['status']
                                    ? rawAverageIntersectionsData
                                    : rawComparisonAverageIntersectionsData?.['status']
                                    ? rawComparisonAverageIntersectionsData
                                    : null
                            }
                            selectedLayerType={layerType}
                            selectedGroupOfZones={selectedGroupOfZones}
                            stageWidthCalculationFactor={stageWidthCalculationFactor ? 0.7 : 1}
                            reportingObjectsByTypeAndMarker={reportingObjectsByTypeAndMarker}
                            selectedReportingObjectType={selectedReportingObjectType}
                            selectObject={storeSelectedReportingObjectMarkerTrans}
                            RatingsComponent={(props) => <Ratings {...props} />}
                            selectedMarker={selectedReportingObjectMarker}
                            colorsByMarker={colorsByMarker || {}}
                            availableMarkers={availableMarkers}
                            layers={extendedLayers as ILayer[]}
                            plans={rawPlansData as IPlan[]}
                            stageAreaSize={stageAreaSize}
                            labelAdditionalText={'%'}
                        />
                    )}
                </WithLoadingTable>
            );
        }
        return null;
    }, [
        localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS],
        rawComparisonAverageIntersectionsData,
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectMarker,
        JSON.stringify(stageAreaSize),
        selectedReportingObjectType,
        rawAverageIntersectionsData,
        selectedGroupOfZones,
        availableMarkers,
        rawVersionsData,
        colorsByMarker,
        extendedLayers,
        rawLayersData,
        rawPlansData,
        structures,
    ]);

    const tabList = useMemo(() => {
        return (
            <TabList
                selectedValue={selectedReportingObjectType}
                selectHandler={tabListSelectHandler}
                widgetName="CrossVisits"
                options={tabListItems}
            />
        );
    }, [reportingObjectsByType, selectedReportingObjectType, t]);

    return (
        <WidgetWrapper ref={widgetWrapperRef}>
            <WidgetTitleWrapper>
                <WidgetTitle>{t('Cross-visits on the map')}</WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            <StageWrapper ref={stageAreaRef}>{maps}</StageWrapper>
        </WidgetWrapper>
    );
};

export default CrossVisitsWidget;
