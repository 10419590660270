import { useContext, useMemo } from 'react';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { EventsManagementContext } from '../../../../context';
import { TColumnsKeys } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { DataCell, HeaderCell } from '../../styles';
import EventControls from '../EventControls/EventControls';
import EventType from '../EventType/EventType';
import ReportingObjects from '../ReportingObjects/ReportingObjects';

/**
 * Компонент для отображения ячейки таблицы
 */
const Cell = (props: any) => {
    const context = useContext(EventsManagementContext);
    const { filteredTableData, children, ...rest } = props;
    const changedType = context?.changedData[props.cell.frontId]?.type;
    const { user } = useSelector(generalReducerValues);

    const dataCellCeontent = useMemo(() => {


        switch (props.cell.key as TColumnsKeys) {
            case 'controls':
                return user?.permissions.includes('app/client | modules/configuration/events | feature/edit') ? (
                    <EventControls data={props.cell} />
                ) : null;

            case 'event_type':
                return <EventType data={props.cell} />;

            case 'data_objects_ids':
                return <ReportingObjects data={props.cell} />;

            default:
                return children;
        }
    }, [props]);

    switch (props.cell.type) {
        case 'header':
            return <HeaderCell {...rest}>{children}</HeaderCell>;
        default:
            return (
                <DataCell changedType={changedType} {...rest}>
                    {dataCellCeontent}
                </DataCell>
            );
    }
};

export default Cell;
