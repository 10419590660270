import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../tools/getColorFromPercent';

export const StyledTable = styled.table<{ isRtlLanguage: Boolean }>`
    width: 100%;
    border-collapse: separate;
    background: #fff;
    table-layout: fixed;
    position: relative;
  ${({ isRtlLanguage }) => (isRtlLanguage ? `direction: rtl;` : `direction: ltr;`)}
`;

export const Td = styled.td`
    border-bottom: 1px solid #ebebeb;
    width: 180px;
    padding: 15px;
    border-right: 1px solid #ebebeb;
    :first-child {
        border-right: none;
        background: #f6f9ff;
    }
    :last-child {
        border-right: none;
    }
`;

export const Tr = styled.tr`
    :hover {
        td {
            background: #f6f9ff;
        }
    }
    :last-child {
        td {
            border-bottom: none;
        }
    }
`;

export const DefaultText = styled.div<{ value?: string }>`
    font-weight: 500;
    font-size: 14px;
    color: ${({ value }) => getColorFromPercent(value)};
`;

export const IconsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const iconStyles = {
    root: {
        fontSize: '8px',
        height: '8px',
        lineHeight: '8px',
        cursor: 'pointer',
    },
};
