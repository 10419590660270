import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

// import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';

// import { isMobileInboundWidth } from 'src/theme';
// import { emptyObject } from 'src/tools';
// import { HandsOnTable, ColumnOptions, CellRange } from 'src/components/HandsOnTable';
// import { DataSheetGridRef } from 'src/components/DataSheetGrid';
import { FocusTable, TableRange, ColumnOptions } from 'src/components/FocusTable';
// import useTranslation from 'src/hooks/useTranslation/useTranslation';
import { useAppDispatch } from 'src/hooks/useSettings';
import {
    storeActiveTenantId,
    storeSelectedTenantsRanges,
    storeActivePosOfTenantsIds,
    storeSelectedPosOfTenantsRanges,
    Sales_PosAnalysis_Module_Reducer_Values,
} from 'src/Chapters/Sales/PosAnalysis/reducer';
import LoadingBox from 'src/components/LoadingBox/LoadingBox';
import { emptyArray, chunks } from 'src/tools';

import useFetchData from '../../api/useFetchData';
import { tenantsAndPosAdapter } from '../../api/dataAdapters/tenantsAndPosAdapter';
import { CELL_STYLE, WIDGET_NAME } from '../../../../constants';

interface TenantsListProps {}

const columnsOptions: ColumnOptions[] = [
    { id: 'id', visible: false },
    { id: 'tenantName', label: 'Tenant', sortOrder: 'asc' },
    { id: 'orgName', label: 'Legal entity name' },
    { id: 'inns', label: 'EIN', isCancellableSort: true, sortOrder: 'desc', align: 'right' },
    { id: 'posType', style: { whiteSpace: 'pre-line' }, label: 'Connection types' },
    {
        id: 'posCount',
        label: 'POS count (Registration POS ID)',
        align: 'right',
    },
];

// const rangeToArray = (range: CellRange) => [range.from.row, range.from.col, range.to.row, range.to.col];

export const TenantsList: React.FC<TenantsListProps> = () => {
    const dispatch = useAppDispatch();

    const { selectedTenantsRanges } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);

    const selectedCells = useMemo(() => {
        if (!selectedTenantsRanges || selectedTenantsRanges.length === 0) {
            return null;
        }

        const [firstChunk] = [...chunks<number>(selectedTenantsRanges || [], 4)];
        const [startRow, startCol, endRow, endCol] = firstChunk;

        return {
            start: { row: startRow, col: startCol },
            end: { row: endRow, col: endCol },
        } as TableRange;
    }, [selectedTenantsRanges]);

    const { data, isFetching } = useFetchData(tenantsAndPosAdapter);

    const tableData = data || emptyArray;

    // useEffect(() => {
    //     const handler = () => {
    //         // @ts-ignore
    //         const exportPlugin = hotRef?.current?.hotInstance?.getPlugin('exportFile');
    //         exportPlugin?.downloadFile?.('csv', {
    //             bom: false,
    //             columnDelimiter: ',',
    //             columnHeaders: true,
    //             exportHiddenColumns: true,
    //             exportHiddenRows: true,
    //             fileExtension: 'csv',
    //             mimeType: 'text/csv',
    //             rowDelimiter: '\r\n',
    //             rowHeaders: false,
    //             filename: 'tenantsPOS',
    //         });
    //         // exportPlugin?.exportAsString?.('csv', {
    //         //     exportHiddenRows: true, // default false
    //         //     exportHiddenColumns: true, // default false
    //         //     columnHeaders: true, // default false
    //         //     rowHeaders: true, // default false
    //         //     columnDelimiter: ';', // default ','
    //         //     range: [rowFrom, rowTo, colFrom, colTo],
    //         //     // range: [1, 1, 6, 6], // [startRow, endRow, startColumn, endColumn]
    //         // });
    //     };

    //     context?.setCsvExportHandler?.(handler, t('Tenants'));
    // }, []);

    // useEffect(() => {
    // const [minRow, minCol, maxRow, MaxCol] = selectedCells?.[0] || [];
    // focusTableRef?.current?.setSelection?.({
    //     min: { col: minCol, row: minRow },
    //     max: { col: MaxCol, row: maxRow },
    // });
    // }, []);

    // const handleSelectAfterOnCellMouseUp = (selectedRows: unknown[], _ranges: CellRange[]) => {
    //     const selectedTenantIds = selectedRows.map(([tenantId]) => Number(tenantId));
    //     dispatch(storeActiveTenantId(selectedTenantIds?.length > 0 ? selectedTenantIds : null));
    //     // const selectedRanges = (ranges || []).reduce((acc, range) => [...acc, ...rangeToArray(range)], []);
    //     // dispatch(storeSelectedRanges(selectedRanges));
    //     // if (ranges.length !== 1) {
    //     //     context?.setCsvExportHandler?.(undefined);
    //     //     return;
    //     // }
    // };

    // : Partial<Column<Record<string, any>, any, any>>[] | undefined
    // console.log(tableData)

    const onSelectionChange = useCallback(
        (range: TableRange | null, selectedRows: typeof tableData) => {
            dispatch(storeActivePosOfTenantsIds([]));
            dispatch(storeSelectedPosOfTenantsRanges(null));

            if (range === null || !(selectedRows?.length > 0)) {
                dispatch(storeActiveTenantId(null));
                dispatch(storeSelectedTenantsRanges(null));
                return;
            }

            const { start, end } = range;

            const selectedTenantIds = selectedRows.map(({ id }) => Number(id));

            dispatch(storeActiveTenantId(selectedTenantIds?.length > 0 ? selectedTenantIds : null));
            dispatch(storeSelectedTenantsRanges([start.row, start.col, end.row, end.col]));
        },
        [dispatch],
    );

    if (isFetching) {
        return <LoadingBox height={400} />;
    }

    return (
        <FocusTable
            tableId="tenantList"
            nameForExports={WIDGET_NAME}
            value={tableData}
            maxHeight={300}
            columnsOptions={columnsOptions}
            selectedCells={selectedCells}
            onSelectionChange={onSelectionChange}
            cellStyle={CELL_STYLE}
            // onChangeConfig={(arg: any) => {}}
            // initialConfig={{ sorters: [{ sortOrder: 'desc', columnId: 'inns' }] }}
        />
    );
};
