import { TFunction } from 'i18next';
import { IMetric, TMetricResponse } from '../../../../../General.interfaces';
import { IDynamicsProps } from '../../../../Charts/Dynamics/interfaces';
import aggregateMetricResponseByPeriodAndMetric from '../../tools/aggregateMetricResponseByPeriodAndMetric';
import generateSeries from '../tools/generateSeries';
import '../styles/styles.scss';

interface IPiechartDefaultDAArgs {
    /** Ответ от сервера. Сюда приходит ответ от запроса метрик */
    response: TMetricResponse[];
    /** Все метрики */
    allMetrics: IMetric[];
    /** Тип виджета */
    widgetType: string | undefined;
    /** Размеры виджета */
    widgetDimensions: null | { width: number; height: number } | undefined;
    /** Функция перевода */
    t: TFunction;
}

const piechartDefaultDataAdapter = (args: IPiechartDefaultDAArgs): IDynamicsProps => {
    const { widgetDimensions, response, allMetrics, widgetType, t } = args;
    const aggregatedRawData = aggregateMetricResponseByPeriodAndMetric(response);

    switch (widgetType) {
        case 'categories': {
            const series = generateSeries({
                metrics: aggregatedRawData,
                allMetrics,
                widgetType,
                t,
            });

            return { series, numberOfSeriesToDispaly: null, inactiveSeriesOpacity: 0.2 };
        }

        case 'distribByCats': {
            const series = generateSeries({
                metrics: aggregatedRawData,
                allMetrics,
                widgetType,
                t,
            });

            return {
                series,
                numberOfSeriesToDispaly: null,
                plotPieOptions: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    innerSize: '70%',
                    borderWidth: 2,
                },

                inactiveSeriesOpacity: 0.2,
                additionalLegendSettings: {
                    verticalAlign: 'middle',
                    maxHeight: undefined,
                    itemMarginBottom: 5,
                    layout: 'vertical',
                    itemMarginTop: 5,
                    align: 'right',
                    width: '50%',
                    margin: 1,
                },
                legendWrapperClassName: 'legend__wrapper',
                legendWrapperInlineStyle: {
                    width: widgetDimensions ? `${(widgetDimensions.width - 45) * 0.5}px` : 'auto',
                },
            };
        }

        default:
            return { series: [] };
    }
};

export default piechartDefaultDataAdapter;
