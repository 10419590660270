import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
    IGeneralReducerState,
    IReportingObject,
    IWindowSize,
    IUser,
    TStructures,
    ICategory,
    IProjectCategory,
    IStoreObjectsArgs,
    ITenant2ZoneRelation,
    IMetric,
    IAppCoreData,
    IServices,
    IUrlsByServices,
    TLang,
    IStandardCategory,
    IGlobalSpinnerItem,
    IModuleConfig,
    ITenant2PlaceRelation,
    IBgReportType,
} from './General.interfaces';

import { cloneDeep, isUndefined, unset } from 'lodash';
import { ILocation, TMenu } from './components/SideBar/configurations';
import {
    IDataObjectsSelectFilter,
    IUpdateDataObjectsSelectFiltersPayload,
} from './hooks/dataObjectsSelectFilters/interfaces';
import { objectsOrder } from './constants/objectsOrder';
import { CURRENCY, ZONES_WORD } from './constants/constants';
import { modulesConfig } from './constants/modulesConfig';
import { modifyCategories } from './tools/modifyCategories';
import { currenciesMap } from './constants/currencies';

/**
 * Функция для получения объекта с urls, где ключ - это название сервиса
 */
const getUrlsByServices = (services: IServices, appBackendHost: string): IUrlsByServices => {
    const result = Object.entries(services).reduce((acc, [key, value]: [keyof IServices, string]) => {
        acc[key] = {};
        if (key === 'core/admin-service') {
            acc[key] = {
                AUTH_URL: `https://${value}/sso/ajax-token`,
                AUTH_SAML_URL: `https://${value}/saml/`,
                LOGOUT_URL: `https://${value}/admin/logout/`,
                PL_BY_ML_URL: `https://${value}/api/pl-by-ml/`,
                CATEGORIES_URL: `https://${value}/api/categories`,
                LEVENSTEIN_URL: `https://${value}/api/categories/levenshtein-comparison-arr/`,
                GROUP_DATA_OBJECTS_URL: `https://${value}/api/categories/group-data-objects/`,
                PLACES_URL: `https://${value}/api/places/`,
                TENANTS_URL: `https://${value}/api/tenants/`,
                TENANT_2_PLACE_URL: `https://${value}/api/tenant-2-place/`,
            };
        }

        if (key === 'core/map-service-back') {
            acc[key] = {
                LOCATION_CLONE_URL: `https://${value}/api/location-clone/`,
                PLANS_URL: `https://${value}/api/plans/`,
                LAYERS_URL: `https://${value}/api/layers/`,
                VERSIONS_URL: `https://${value}/api/versions/`,
            };
        }

        if (key === 'core/client-metrics') {
            acc[key] = {
                CLIENT_METRIC_VALUES_URL: `https://${value}/api/metric_values/`,
            };
        }

        if (key === 'core/structure-service') {
            acc[key] = {
                CURRENT_URL: `https://${value}/core-current/`,
                LOCATIONS_URL: `https://${value}/structure-service/v1/project_locations/`,
                CACHED_STRUCTURE_URL: `https://${value}/structure-service/v1/cached_structure/`,
                METRIC_DYNAMICS_URL: `https://${value}/core-data-provider/v1/metric_dynamics/`,
                // METRIC_DYNAMICS_URL: `https://${value}/core-data-provider/v1/metric_dynamics`,
                AVERAGE_INTERSECTIONS_REQUEST_URL: `https://${value}/core-data-provider/v1/shopster_awgweighted_intersections_percent_obj_with_arr/`,
                PAIWISE_INTERSECTIONS_URL: `https://${value}/core-data-provider/v1/shopster_paiwise_intersections_obj_with_arr/`,
                METRICS_LIST_URL: `https://${value}/structure-service/v1/metrics-list/`,
                STANDARD_CATEGORIES_LIST_URL: `https://${value}/structure-service/v1/standard-categories-list/`,
            };
        }

        if (key === 'app/app-backend') {
            let appBackendValue = appBackendHost ? `http://${appBackendHost}` : `https://${value}`;
            acc[key] = {
                CABINET_PREFERENCES_URL: `${appBackendValue}/api/user-settings/cabinet-preferences/`,
                PROJECT_ALERTS_URL: `${appBackendValue}/api/user-interaction/project-alerts/`,
                ACCOUNT_PARAMETERS_URL: `${appBackendValue}/api/user-settings/account-parameters/`,
                USER_PRESETS_URL: `${appBackendValue}/api/presets/user-presets/`,
                SHARED_PRESETS_URL: `${appBackendValue}/api/presets/shared-presets/`,
                LOCATION_EVENTS_URL: `${appBackendValue}/api/location-events/location-events/`,
                LOCATION_EVENT_TYPES_URL: `${appBackendValue}/api/location-events/location-event-types/`,
                MANAGEMENT_DECISIONS_URL: `${appBackendValue}/api/management-decisions/management-decisions/`,
                MANAGEMENT_DECISION_TYPES_URL: `${appBackendValue}/api/management-decisions/management-decision-types/`,
                RATINGS_URL: `${appBackendValue}/api/ratings/ratings/`,
                DEFAULT_DASHBOARDS_URL: `${appBackendValue}/api/dashboards/default-dashboards/`,
                PROJECT_DASHBOARDS_URL: `${appBackendValue}/api/dashboards/project-dashboards/`,
                USER_DASHBOARDS_URL: `${appBackendValue}/api/dashboards/user-dashboards/`,
                SHARED_DASHBOARDS_URL: `${appBackendValue}/api/dashboards/shared-dashboards/`,
                BACKGROUND_REPORTS_URL: `${appBackendValue}/api/background-reports/`,
                CREATE_BACKGROUND_REPORT_URL: `${appBackendValue}/api/create-background-report/`,
                BACKGROUND_REPORT_TYPES_URL: `${appBackendValue}/api/background-report-types/`,
            };
        }
        if (key === 'app/client') {
            acc[key] = {
                CABINET_URL: `https://${value}`,
            };
        }

        return acc;
    }, {}) as IUrlsByServices;

    return result;
};

const initialState: IGeneralReducerState = {
    currentModuleID: 'Dashboard designer:Object panels',
    initialDataReceived: false,
    generalSearch: '',
    optionPanelHeight: 10,
    sendYaAnalytics: false,
    sendGooAnalytics: false,
    serviceMode: false,
    domain: '',
    categoriesReload: 0,
    appBackendHost: '',
    user: null,
    token: '',
    currencyCode: 0,
    allMetrics: [],
    rawMetrics: [],
    localMetricsList: [],
    locations: [],
    structures: null,
    sideBarMenu: [],
    globalSpinner: {},
    modulesConfig: modulesConfig,
    mainAreaSize: { width: 0, height: 0 },
    selectedLocationId: null,
    selectedProjectId: null,
    timeZone: null,
    backgroundReportTypes: [],
    backgroundReportTypesById: {},
    lang: 'en',
    src: {
        reportingObjects: [],
        categories: [],
        projectCategories: [],
        dataObj2ProjectCategory: [],
        standardCategories: [],
    },
    cfg: {
        reportingObjectsByType: {},
        reportingObjectsByTypeAndMarker: {},
        locationsById: {},
        reportingObjectsById: {},
        tenant2ZoneByTenantId: {},
        tenant2FloorByTenantId: {},
        tenant2PlaceByTenantId: {},
        dataObj2ProjectCategoryByObjId: {},
        dataObj2ProjectCategoryByCatId: {},
        projectCategoriesById: {},
    },
    rawDataObjectsSelectFilters: [],
    dataObjectsSelectFiltersRefetchObject: {},
    extendedDataObjectsSelectFiltersByType: {},
    rawDataObjectsSelectFiltersFetching: true,
    appCoreData: null,
    urlsByServices: null,
    isRtlLanguage: false,
    isSidebarOpen: false,
    isModuleLoading: {},
};

export const GeneralReducer = createSlice({
    name: 'GeneralReducer',
    initialState,

    reducers: {
        storeAppCoreData: (state, action: PayloadAction<{ data: IAppCoreData | null; domain: string }>) => {
            const { appBackendHost } = cloneDeep(state);
            let urlsByServices = null;
            if (action.payload?.data?.services) {
                urlsByServices = getUrlsByServices(action.payload.data.services, appBackendHost);
            }
            state.urlsByServices = urlsByServices;
            state.appCoreData = action.payload?.data;
            state.domain = action.payload?.domain;
        },

        storeToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },

        setOptionsPanelHeight: (state, action: PayloadAction<number>) => {
            state.optionPanelHeight = action.payload;
        },

        storeGeneralSearch: (state, action: PayloadAction<string>) => {
            state.generalSearch = action.payload;
        },

        changeServiceMode: (state, action: PayloadAction<boolean>) => {
            state.serviceMode = action.payload;
        },

        storeInitialDataReceived: (state) => {
            state.initialDataReceived = true;
        },

        storeAllMetrics: (state, action: PayloadAction<Array<IMetric>>) => {
            state.allMetrics = action.payload;
        },
        storeCurrencyCode: (state, action: PayloadAction<number>) => {
            state.currencyCode = action.payload;
        },

        storeRawMetrics: (state, action: PayloadAction<Array<IMetric>>) => {
            state.rawMetrics = action.payload;
        },

        storeLocalMetricsList: (state, action: PayloadAction<Array<string>>) => {
            state.localMetricsList = action.payload;
        },

        storeStandardCategories: (state, action: PayloadAction<IStandardCategory[]>) => {
            if (action.payload?.length) {
                state.src.standardCategories = action.payload;
            }
        },

        storeLocations: (state, action: PayloadAction<ILocation[]>) => {
            const { selectedLocationId } = cloneDeep(state);

            // const newLocations = action.payload.map((item) => {
            //     if (item.id === 201) {
            //         return { ...item, name: 'Demo2' };
            //     } else {
            //         return item;
            //     }
            // });

            state.locations = action.payload;
            const locationsById = Object.fromEntries(action.payload.map((item) => [item.id, item]));
            state.cfg.locationsById = locationsById;
            if (!selectedLocationId || !locationsById[selectedLocationId]) {
                state.selectedLocationId = action.payload[0].id;
                state.selectedProjectId = action.payload[0].project_id;
                state.timeZone = action.payload[0].timezone;
            } else {
                state.timeZone = locationsById[selectedLocationId].timezone;
                state.selectedProjectId = locationsById[selectedLocationId].project_id;
            }
        },

        storeObjects: (state, action: PayloadAction<IStoreObjectsArgs>) => {
            const { src, cfg } = cloneDeep(state);

            let { reportingObjects, categories, projectCategories, dataObj2ProjectCategory } = action.payload;

            reportingObjects = reportingObjects
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
                .map((item, i) => {
                    if (item.object_type === 'zone') {
                        return {
                            ...item,
                            order: objectsOrder['zone'],
                            object_type: `${ZONES_WORD}: ${item.object_params.group_marker}`,
                            block_type: 'reporting_object',
                            // name: fakeNames[i],
                        };
                    } else {
                        return {
                            ...item,
                            order: objectsOrder[item.object_type] || 1000,
                            block_type: 'reporting_object',
                            // name: fakeNames[i],
                        };
                    }
                });

            const dataObj2ProjectCategoryByObjId = {};
            const dataObj2ProjectCategoryByCatId = {};

            dataObj2ProjectCategory.forEach((element) => {
                if (dataObj2ProjectCategoryByObjId[element.data_object_id]) {
                    dataObj2ProjectCategoryByObjId[element.data_object_id].push(element);
                } else {
                    dataObj2ProjectCategoryByObjId[element.data_object_id] = [element];
                }

                if (dataObj2ProjectCategoryByCatId[element.category_id]) {
                    dataObj2ProjectCategoryByCatId[element.category_id].push(element);
                } else {
                    dataObj2ProjectCategoryByCatId[element.category_id] = [element];
                }
            });

            // Создаем массив ID категорий, имеющих объекты

            const filledCategoriesIds = Object.keys(dataObj2ProjectCategoryByCatId).map((key) => Number(key));

            // const modCategories = modifyCategories(categories, 'category');
            const modProjectCategories = modifyCategories(projectCategories, filledCategoriesIds);

            // reportingObjects.push(...modCategories);
            reportingObjects.push(...modProjectCategories);

            state.src.reportingObjects = reportingObjects;
            state.src.categories = categories;
            state.src.projectCategories = projectCategories;
            state.src.dataObj2ProjectCategory = dataObj2ProjectCategory;

            const reportingObjectsByTypeAndMarker = {};
            const reportingObjectsByType = {};
            const reportingObjectsById = {};
            const projectCategoriesById = {};

            reportingObjects
                ?.filter(
                    (item) => item.block_type !== 'category',
                    // && item.block_type !== 'project_category'
                )
                .forEach((obj) => {
                    if (reportingObjectsByType[obj.object_type]) {
                        reportingObjectsByType[obj.object_type] = [...reportingObjectsByType[obj.object_type], obj];
                    } else {
                        reportingObjectsByType[obj.object_type] = [obj];
                    }
                    reportingObjectsByTypeAndMarker[`${obj.object_type}:${obj.marker}`] = obj;
                });

            reportingObjects.forEach((obj) => {
                reportingObjectsById[obj.id] = obj;
            });

            projectCategories.forEach((element) => {
                projectCategoriesById[element.id] = element;
            });

            state.cfg.reportingObjectsByType = reportingObjectsByType;
            state.cfg.reportingObjectsById = reportingObjectsById;
            state.cfg.reportingObjectsByTypeAndMarker = reportingObjectsByTypeAndMarker;
            state.cfg.dataObj2ProjectCategoryByObjId = dataObj2ProjectCategoryByObjId;
            state.cfg.dataObj2ProjectCategoryByCatId = dataObj2ProjectCategoryByCatId;
            state.cfg.projectCategoriesById = projectCategoriesById;
        },

        changeLang: (state, action: PayloadAction<TLang>) => {
            state.lang = action.payload;
        },

        changeIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
            state.isSidebarOpen = action.payload;
        },

        reloadCategories: (state, action: PayloadAction) => {
            state.categoriesReload = cloneDeep(state.categoriesReload) + 1;
        },

        storeSelectedLocationId: (state, action: PayloadAction<number>) => {
            if (cloneDeep(state.selectedLocationId) !== action.payload) {
                state.src.reportingObjects = [];
                state.src.categories = [];
                state.src.projectCategories = [];
                state.src.dataObj2ProjectCategory = [];

                state.cfg.reportingObjectsByType = {};
                state.cfg.reportingObjectsByTypeAndMarker = {};
                state.cfg.reportingObjectsById = {};
                state.cfg.tenant2ZoneByTenantId = {};
                state.cfg.tenant2FloorByTenantId = {};
                state.cfg.tenant2PlaceByTenantId = {};

                state.selectedLocationId = action.payload;
                const timeZone = cloneDeep(state.cfg.locationsById)[action.payload]?.timezone;
                const selectedProjectId = cloneDeep(state.cfg.locationsById)[action.payload]?.project_id;

                if (timeZone !== undefined) {
                    state.timeZone = timeZone;
                }
                if (selectedProjectId !== undefined) {
                    state.selectedProjectId = selectedProjectId;
                }
            }
        },

        changeCurrentModuleID: (state, action: PayloadAction<string>) => {
            state.currentModuleID = action.payload;
        },

        storeMainAreaSize: (state, action: PayloadAction<IWindowSize>) => {
            state.mainAreaSize = action.payload;
        },

        resetReportingObjects: (state, action: PayloadAction) => {
            state.src.reportingObjects = [];
            state.src.categories = [];
            state.src.projectCategories = [];
            state.src.dataObj2ProjectCategory = [];

            state.cfg.reportingObjectsByType = {};
            state.cfg.reportingObjectsByTypeAndMarker = {};
            state.cfg.reportingObjectsById = {};
        },

        storeStructures: (state, action: PayloadAction<TStructures>) => {
            const structures = action.payload;
            if (structures) {
                // Переименовываем структуры pl_structure/pl201/core/relations_tenant2place.json ---> relations_tenant2place
                const newStructures: TStructures = {};
                Object.keys(structures).forEach((key) => {
                    const newKey = key.split('/')[key.split('/').length - 1].split('.')[0];
                    newStructures[newKey] = structures[key];
                });

                if (newStructures.relations_tenant2zone?.length) {
                    const tenant2ZoneByTenantId = cloneDeep(newStructures.relations_tenant2zone)?.reduce(
                        (acc, value: ITenant2ZoneRelation) => {
                            if (!acc[value.tenant_id]) acc[value.tenant_id] = [value];
                            else acc[value.tenant_id].push(value);
                            return acc;
                        },
                        {},
                    );
                    if (tenant2ZoneByTenantId) state.cfg.tenant2ZoneByTenantId = tenant2ZoneByTenantId;
                }

                if (newStructures.relations_tenant2floor?.length) {
                    const tenant2FloorByTenantId = cloneDeep(newStructures.relations_tenant2floor)?.reduce(
                        (acc, value: ITenant2ZoneRelation) => {
                            if (!acc[value.tenant_id]) acc[value.tenant_id] = [value];
                            else acc[value.tenant_id].push(value);
                            return acc;
                        },
                        {},
                    );

                    if (tenant2FloorByTenantId) state.cfg.tenant2FloorByTenantId = tenant2FloorByTenantId;
                }

                if (newStructures.relations_tenant2place?.length) {
                    const tenant2PlaceByTenantId = cloneDeep(newStructures.relations_tenant2place)?.reduce(
                        (acc, value: ITenant2PlaceRelation) => {
                            if (!acc[value.tenant_id]) acc[value.tenant_id] = [value];
                            else acc[value.tenant_id].push(value);
                            return acc;
                        },
                        {},
                    );

                    if (tenant2PlaceByTenantId) state.cfg.tenant2PlaceByTenantId = tenant2PlaceByTenantId;
                }

                state.structures = newStructures;
            } else {
                state.structures = null;
            }
        },

        storeUser: (state, action: PayloadAction<IUser>) => {
            const { domain, serviceMode } = cloneDeep(state);

            // state.sendYaAnalytics = true;

            if (
                !action.payload?.permissions.includes('app/client | not-collect-analytics | feature/view') &&
                action.payload?.project_id !== null &&
                !serviceMode
            ) {
                if (domain.includes('foc')) {
                    state.sendYaAnalytics = true;
                    state.sendGooAnalytics = false;
                } else if (domain.includes('mal')) {
                    state.sendYaAnalytics = false;
                    state.sendGooAnalytics = true;
                }
            }
            state.user = action.payload;
        },

        changeAppBackendHost: (state, action: PayloadAction<string>) => {
            state.appBackendHost = action.payload;
        },

        setIsRTLLang: (state, action: PayloadAction<boolean>) => {
            state.isRtlLanguage = action.payload;
        },

        /**
         * Сохранение сырых данных для фильтров селекта объектов
         */
        storeRawDataObjectsSelectFilters: (state, action: PayloadAction<IDataObjectsSelectFilter[]>) => {
            state.rawDataObjectsSelectFilters = action.payload;
            state.rawDataObjectsSelectFiltersFetching = false;
        },

        /**
         * Перезапросить фильтры для селекта объекта
         */
        refetchDataObjectsSelectFilters: (state) => {
            state.dataObjectsSelectFiltersRefetchObject = {};
        },

        /**
         * Сохранение Расширенной (изменяемой) версии фильтров для селекта объектов
         */
        storeExtendedDataObjectsSelectFiltersByType: (
            state,
            action: PayloadAction<{ [filterType: string]: IDataObjectsSelectFilter }>,
        ) => {
            state.extendedDataObjectsSelectFiltersByType = action.payload;
        },

        /**
         * Изменение данных расширенной версии фильтров для селекта объектов
         */
        updateExtendedDataObjectsSelectFilters: (
            state,
            action: PayloadAction<IUpdateDataObjectsSelectFiltersPayload>,
        ) => {
            const { filterType, key, value } = action.payload;
            if (!isUndefined(state.extendedDataObjectsSelectFiltersByType[filterType]?.[key])) {
                state.extendedDataObjectsSelectFiltersByType[filterType][key] = value;
            }
        },

        /**
         * Изменение флага загрузки фильтров
         */
        toggleRawDataObjectsSelectFiltersFetching: (state, action: PayloadAction<boolean>) => {
            state.rawDataObjectsSelectFiltersFetching = action.payload;
        },

        /**
         * Изменение флага загрузки фильтров
         */
        storeModulesConfig: (state, action: PayloadAction<IModuleConfig[]>) => {
            state.modulesConfig = action.payload;
        },

        /**
         * Добавление элемента к объекту globalSpinner
         */
        addNewGlobalSpinnerItem: (state, action: PayloadAction<IGlobalSpinnerItem>) => {
            state.globalSpinner[action.payload.id] = action.payload;
        },

        /**
         * Изменение элемента объекта globalSpinner
         */
        updateGlobalSpinnerItemById: (state, action: PayloadAction<IGlobalSpinnerItem>) => {
            state.globalSpinner[action.payload.id] = action.payload;
        },

        /**
         * Удаление элемента из объекта globalSpinner по его id
         */
        deleteGlobalSpinnerItemById: (state, action: PayloadAction<string>) => {
            const globalSpinner = cloneDeep(state.globalSpinner);
            unset(globalSpinner, action.payload);
            state.globalSpinner = globalSpinner;
        },

        /**
         * Изменение флага загрузки модуля
         */
        toggleModuleLoading: (state, action: PayloadAction<object | null>) => {
            state.isModuleLoading = action.payload;
        },

        /**
         * Обнуление globalSpinner
         */
        resetGlobalSpinner: (state) => {
            state.globalSpinner = {};
        },

        /**
         * Сохранение background report types
         */
        storeBackgroundReportTypes: (state, action: PayloadAction<IBgReportType[]>) => {
            const reportTypesById = action.payload.reduce((acc, value) => {
                acc[value.id] = value;
                return acc;
            }, {});
            state.backgroundReportTypes = action.payload;
            state.backgroundReportTypesById = reportTypesById;
        },
    },
});

export const {
    storeAppCoreData,
    changeAppBackendHost,
    storeUser,
    storeRawDataObjectsSelectFilters,
    storeExtendedDataObjectsSelectFiltersByType,
    updateExtendedDataObjectsSelectFilters,
    toggleRawDataObjectsSelectFiltersFetching,
    resetReportingObjects,
    storeToken,
    storeStructures,
    storeMainAreaSize,
    storeAllMetrics,
    storeCurrencyCode,
    storeRawMetrics,
    storeLocalMetricsList,
    reloadCategories,
    storeInitialDataReceived,
    storeGeneralSearch,
    storeObjects,
    storeStandardCategories,
    changeCurrentModuleID,
    storeLocations,
    storeSelectedLocationId,
    changeLang,
    storeModulesConfig,
    setOptionsPanelHeight,
    changeServiceMode,
    refetchDataObjectsSelectFilters,
    setIsRTLLang,
    addNewGlobalSpinnerItem,
    updateGlobalSpinnerItemById,
    deleteGlobalSpinnerItemById,
    resetGlobalSpinner,
    toggleModuleLoading,
    storeBackgroundReportTypes,
    changeIsSidebarOpen,
} = GeneralReducer.actions;

export const generalReducerValues = (state: RootState) => state.GeneralReducer;

export default GeneralReducer.reducer;
