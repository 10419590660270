import styled from 'styled-components/macro';

export const Li = styled.li`
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    border: 1px solid white;
    :first-child {
        width: 100% !important;
        margin-top: 0px;
    }
`;

export const Color = styled.div.attrs<{ width: string; bgColor: string }>((props) => ({
    style: {
        background: props.bgColor,
        width: props.width,
    },
}))<{ width: string; bgColor: string }>`
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0;
    height: 100%;
`;

export const Text = styled.div`
    /* color: var(--color-white);
    mix-blend-mode: difference; */
    font-size: 10px;
    margin-top: 5px;
    z-index: 2;
    line-height: 10px;
    :first-child {
        margin-top: 0px;
    }
`;

export const TextWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Ul = styled.ul`
    list-style: none;
    margin: 0;
    padding: 5px 5px 5px 5px;
`;

export const Wrapper = styled.div`
    height: 100%;
    /* border: 1px solid var(--color-secondary-dark); */
    position: relative;
    margin-left: 10px;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: auto;
`;

export const Header = styled.div`
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 5px;
    border-bottom: 1px solid var(--color-secondary-dark);
    text-align: center;
`;
