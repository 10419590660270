import React from 'react';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import BackgroundHeader from '../components/Header/Header';
import { HELP_METRICS_CONFIG } from './constants/constants';
import { MetricSourceWrapper } from './styles';
import Title from '../../../components/Title/Title';
import { Description } from '../styles';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import Metric from './components/Metric/Metric';
import { theme } from 'src/theme';

/**
 * Компонент модуля сабмодуля Метрики
 */
const Metrics: React.FC = () => {
    const { t } = useTranslation(['translationHelp', 'metrics']);
    return (
        <ModuleWrapper>
            <div style={{ paddingLeft: theme.spaces.md, paddingRight: theme.spaces.md }}>
                <BackgroundHeader title={t('Metrics')} />
            </div>
            <WrapperContainer>
                {HELP_METRICS_CONFIG.map((metricsSource) => {
                    return (
                        <MetricSourceWrapper key={metricsSource.title}>
                            <Title titleLevel="h2" margin="b_xxs">
                                {t(metricsSource.title)}
                            </Title>
                            <Description>{t(metricsSource.description)}</Description>
                            {metricsSource.metrics.map((metric) => {
                                return <Metric key={metric.title} metric={metric} />;
                            })}
                        </MetricSourceWrapper>
                    );
                })}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Metrics;
