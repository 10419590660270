import { forwardRef } from 'react';
import { IProps } from './interfaces';
import { Wrapper } from './styles';

/**
 * Компонент для реализации функционала flex контейнера
 */
const Stack = forwardRef<HTMLDivElement, IProps>(
    (
        {
            direction = 'row',
            justifyContent,
            verticalFill,
            alignItems,
            className,
            flexWrap,
            children,
            padding,
            styles,
            gap,
        },
        ref,
    ) => {
        return (
            <Wrapper
                justifyContent={justifyContent}
                verticalFill={verticalFill}
                alignItems={alignItems}
                className={className}
                direction={direction}
                flexWrap={flexWrap}
                padding={padding}
                style={styles}
                ref={ref}
                gap={gap}
            >
                {children}
            </Wrapper>
        );
    },
);

export default Stack;
