import { useSelector } from 'react-redux';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import { Container } from '../../../components/Wrappers/WrapperContainer/styles';
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import { Events_Events_Module_Reducer_Values } from './reducer';
import usePrepareData from './hooks/usePrepareData';
import Header from './components/Header/Header';
import SummaryTable from './widgets/SummaryTable/SummaryTable';
import DynamicsPMO from './widgets/DynamicsPMO/DynamicsPMO';
import TableOfObjects from './widgets/TableOfObjects/TableOfObjects';
import Modals from '../../../components/Modals/Modals';
import EventModalBody from '../EventsMap/widgets/EventsMapWidget/components/EventModalBody/EventModalBody';
import { useState } from 'react';
import ModuleOptionsPanel from 'src/components/ModuleOptionsPanel/ModuleOptionsPanel';
import { theme } from 'src/theme';
import WrapperContainer from 'src/components/Wrappers/WrapperContainer/WrapperContainer';

/**
 * Компонент для отображения модуля События
 */
const Events = () => {
    const { selectedEventId, currentEvent } = useSelector(Events_Events_Module_Reducer_Values);
    const [editEventModalStatus, setEditEventModalStatus] = useState({ show: false });
    usePrepareData();

    const toggleEditEventModalStatus = () => {
        setEditEventModalStatus((prevState) => ({ ...prevState, show: !prevState.show }));
    };

    return (
        <ModuleWrapper styles={{ paddingLeft: theme.spaces.md, paddingRight: theme.spaces.md }}>
            {selectedEventId && currentEvent ? (
                <>
                    <Modals
                        closeModal={toggleEditEventModalStatus}
                        modalStatus={editEventModalStatus}
                        title={currentEvent.name}
                        topColor="fff"
                    >
                        <EventModalBody event={currentEvent} closeModal={toggleEditEventModalStatus} />
                    </Modals>
                    <Header event={currentEvent} toggleEditEventModalStatus={toggleEditEventModalStatus} />
                    <div style={{ paddingTop: theme.spaces.md }}>
                        <SummaryTable />
                        <DynamicsPMO />
                        <TableOfObjects />
                    </div>
                </>
            ) : (
                <div style={{ paddingTop: theme.spaces.md }}>
                    <ErrorMessage />
                </div>
            )}
        </ModuleWrapper>
    );
};

export default Events;
