import styled from 'styled-components/macro';

export const FeadbackWrapper = styled.div<{ isRtlLanguage: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Smiles = styled.div`
    position: relative;
    display: flex;
`;

export const AnimationWrapper = styled.div`
    cursor: pointer;
    width: 40px;
    height: 40px;
    :hover {
        scale: 120%;
    }
`;

export const Title = styled.h4`
    margin: 0;
    padding: 0;
    margin-bottom: var(--space-xxs);
    color: var(--color-secondary-darker);
`;
