import { Switch, SwitchOnChangeData } from '@fluentui/react-components';
import { isBoolean } from 'lodash';
import { ChangeEvent, memo, useEffect } from 'react';

interface IProps {
    name: string;
    checked?: boolean;
    label: string;
    defaultValue: boolean;
    storePlace: any;
    onChange: (args: { name: string; checked: boolean }) => void;
}

const SmartSwitch = memo<IProps>(({ name, storePlace, label, defaultValue, checked, onChange }) => {
    useEffect(() => {
        if (storePlace && storePlace?.[name] === undefined) {
            onChange({ name, checked: defaultValue });
        }
    }, [defaultValue, name, onChange, storePlace]);

    const onSwitchChange = (name: string) => (ev: ChangeEvent<HTMLInputElement>, data: SwitchOnChangeData) => {
        onChange({ name, checked: data.checked });
    };

    const innerChecked = isBoolean(checked) ? checked : Boolean(storePlace?.[name]);

    return <Switch checked={innerChecked} onChange={onSwitchChange(name)} label={label} />;
});

export default SmartSwitch;
