import { useSelector } from 'react-redux';
import { IProps } from '../interfaces';
import { cabinetPreferencesValues } from '../../../../../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useRequestMetrics } from '../../../../../../../../tools/API/hooks/useRequestMetrics';
import metricsDataAccessor, {
    IMetricsDAArgs,
} from '../../../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { IPeriodObj, TMetricResponse } from '../../../../../../../../General.interfaces';
import { SUMMARY_QUERY_KEY } from '../constants/constants';
import { useMemo } from 'react';
import { IRequestMetricsArgs } from '../../../../../../../../tools/API/interfaces';
import defaultDataAdapter from './dataAdapter';
import { useQuery } from '@tanstack/react-query';
import { useWidgetCurrentOptions } from 'src/hooks';

interface IArgs extends IProps {}

const useFetchData = (args: IArgs) => {
    const { token, currentModuleID } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const fetchData = useRequestMetrics({ isNetwork: args.isNetwork });

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        const obj_ids = args.reportingObjects.map((item) => item.id).sort();

        const generateRequestArgs = (options: { alias: string; period: IPeriodObj }): IRequestMetricsArgs => {
            return {
                time_range: [options.period.dateFrom, options.period.dateTo],
                obj_ids,
                object_aggregation: false,
                alias: options.alias,
                metric: args.metric,
                time_freq: null,
                token,
            };
        };

        const requestArgs: IRequestMetricsArgs[] = [];

        requestArgs.push(generateRequestArgs({ alias: args.periodType, period: args.period }));

        const dataAccessorArgs: IMetricsDAArgs = {
            requestArgs,
            fetchData,
        };

        return [
            [
                SUMMARY_QUERY_KEY,
                args.moduleId, // args.moduleId должно обязательно быть вторым
                args.widgetId, // args.widgetId должно обязательно быть третьим
                args.periodType, // args.periodType должно обязательно быть четвертым
                args.metric, // args.metric должно обязательно быть пятым
                args.period, // args.period должно обязательно быть шестым
                obj_ids,
            ],
            () => metricsDataAccessor(dataAccessorArgs),
            (response: TMetricResponse[]) => defaultDataAdapter(response, args.reportingObject),
        ];
    }, [args, token, fetchData]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter) && Boolean(localCurrentOptions),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;
