import { FC } from 'react';
import { IProps } from './interfaces';
import { GridDotsFilled } from '@fluentui/react-icons';
import { Menu, MenuList, MenuPopover, MenuTrigger, useOverflowMenu } from '@fluentui/react-components';
import { MoreHorizontalFilled, MoreHorizontalRegular, bundleIcon } from '@fluentui/react-icons';
import OverflowMenuItem from '../OverflowMenuItem/OverflowMenuItem';
import { useCustomFocusIndicatorStyles } from '../../../../App.styles';
import Button from '../../../Button/Button';
import IconButton from '../../../IconButton/IconButton';
import { IconWrapper } from './styles';

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

/**
 * Компонент для сокрытия непомещающихся элементов внутрь меню-бургера
 */
const OverflowMenu: FC<IProps> = ({ selectHandler, options, testId }) => {
    const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLDivElement>();
    const focusIndicatorStyles = useCustomFocusIndicatorStyles();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu hasIcons>
            <MenuTrigger disableButtonEnhancement>

                <IconWrapper data-test-id={testId ? `${testId}:OverflowMenuItem:icon` : null} ref={ref}>
                    <GridDotsFilled />
                </IconWrapper>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {options.map((item) => (
                        <OverflowMenuItem
                            testId={testId ? `${testId}:OverflowMenuItem` : undefined}
                            key={item.id}
                            item={item}
                            selectHandler={selectHandler}
                        />
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export default OverflowMenu;
