import { TMetricResponse } from '../../../General.interfaces';
import { ResponseStatus } from '../constants';
import { TServerResponse, handleServerResponse } from '../handleServerResponse';
import { IRequestMetricsArgs } from '../interfaces';
import responseAnalyzer from '../responseAnalyzer';

export interface IMetricsDAArgs {
    /** Функция запроса */
    fetchData: (args: IRequestMetricsArgs[]) => Promise<TServerResponse>;
    /** Аргументы запроса */
    requestArgs: IRequestMetricsArgs[];
}

type TErrorResponse = { status: ResponseStatus; message: string; alias?: string };
export type TMetricsDAResponse = TMetricResponse[] | TErrorResponse;

/**
 * Получение метрик. Если возникла ошибка, то происходит throw этой ошибки
 */
const metricsDataAccessor = (args: IMetricsDAArgs): Promise<TMetricResponse[]> => {
    const { fetchData, requestArgs } = args;

    return fetchData(requestArgs).then((response: TServerResponse) => {
        let result: TMetricsDAResponse = { status: ResponseStatus.NoData, message: 'No data' };
        const responseHandlerFoo = (response: TMetricsDAResponse) => {
            result = response;
        };
        handleServerResponse({
            responseAnalyzer: responseAnalyzer,
            responseHandlerFoo,
            res: response,
        });

        if (!Array.isArray(result)) throw result;

        return result;
    });
};
export const isMetricErrorGuard = (error: unknown): error is TErrorResponse => {
    return error instanceof Object && 'status' in error;
};

export default metricsDataAccessor;
