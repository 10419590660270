import { isNull } from 'lodash';
import { IMetric, IProjectCategory, ISheetCell } from '../../../../../../General.interfaces';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { AdditionalFieldsIds } from '../../../../interfaces';
import { ITenantsReportAdditionalFieldOption } from '../interfaces';

interface ICell {
    value: string | number;
    readOnly: boolean;
    fieldId?: AdditionalFieldsIds;
}

interface IGenerateGridRowArgs {
    additionalFields: ITenantsReportAdditionalFieldOption[];
    metricsData: {
        [metricName: string]: string;
    };
    isFieldReadOnly: boolean;
    selectedMetrics?: string[];
    categories?: (IProjectCategory | null)[];
    allMetrics: IMetric[];
    showWeekDay: boolean;
    mainPeriod: string;
    tenantName: string;
    placeName: string;
    tenantId: string;
    isHeader?: boolean;
    zonesNames: string[];
    timeZone: string;
    weekday: string;
    floor: string;
    area: string;
}

/**
 * Функция для генерации строки таблицы
 * @param additionalFields Дополнительные поля таблицы
 * @param isFieldReadOnly Колонка readOnly или нет
 * @param metricsData Данные метрик
 * @param showWeekDay Флаг, отвечающий за отображение дней денеди
 * @param tenantName Название арендатора
 * @param mainPeriod Основной период
 * @param placeName Название помещения
 * @param tenantId ID арендатора
 * @param zonesNames массив имен зон
 * @param timeZone Тайм зона
 * @param weekday ID арендатора
 * @param floor Номер этажа
 * @param area Площадь помещения
 * @returns Сгенерированную строку для таблицы
 */
const generateGridRow = (args: IGenerateGridRowArgs): ISheetCell[] | null => {
    const {
        tenantId,
        tenantName,
        mainPeriod,
        metricsData,
        area,
        additionalFields,
        isFieldReadOnly,
        placeName,
        floor,
        zonesNames,
        timeZone,
        weekday,
        showWeekDay,
        allMetrics,
        categories = [],
        isHeader = false,
        selectedMetrics,
    } = args;

    const additionalFieldsIds = additionalFields?.map((element) => element.id) || [];

    let shouldAddRow = true;

    if (!additionalFieldsIds?.includes(AdditionalFieldsIds.ShowEmpty) && !isHeader) {
        shouldAddRow = (selectedMetrics || []).some((metric) => {
            return metricsData[metric] !== '–' && !isNull(metricsData[metric]);
        });
    }

    const result: ICell[] = [
        { value: tenantId, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.AddId },
        { value: tenantName, readOnly: isFieldReadOnly },
        ...categories.map((item) => {
            return {
                value: item?.object_name || '-',
                readOnly: isFieldReadOnly,
                fieldId: AdditionalFieldsIds.AddCategories,
            };
        }),
        { value: placeName, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.AddPlace },
        { value: area, readOnly: isFieldReadOnly },
        { value: floor, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.AddFloor },
        { value: zonesNames.join(', '), readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.AddZone },
        { value: mainPeriod, readOnly: isFieldReadOnly },
        { value: weekday, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.AddWeekDay },
        { value: timeZone, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.AddTimeZone },
        ...(selectedMetrics || []).map((metric) => {
            const units = allMetrics?.find((m) => m.id === metric)?.units || '';

            return {
                value: isHeader
                    ? metricsData[metric]
                    : valueFormatter({ value: metricsData[metric], units, showUnits: false, numericOutput: true }),
                readOnly: isFieldReadOnly,
            };
        }),
    ];

    Object.keys(AdditionalFieldsIds).forEach((key) => {
        const val = AdditionalFieldsIds[key];
        if (!additionalFieldsIds.includes(val)) {
            while (result.findIndex((element) => element.fieldId === val) > -1) {
                const index = result.findIndex((element) => element.fieldId === val);
                if (index > -1) result.splice(index, 1);
            }
        }
    });

    if (!showWeekDay) {
        const index = result.findIndex((element) => element.fieldId === AdditionalFieldsIds.AddWeekDay);

        if (index > -1) result.splice(index, 1);
    }

    if (shouldAddRow) return result;

    return null;
};

export default generateGridRow;
