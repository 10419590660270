import { PresetReducerValues, storeSelectedUserPresetIds } from '../components/Preset/reducer';
import { changeOptions } from '../components/UserSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { IObject } from '../components/Selects/Select/interfaces';
import { generalReducerValues } from '../General.reducer';
import { useWidgetCurrentOptions } from './useWidgetCurrentOptions';
import { useAppDispatch } from './useSettings';

/**
 * Кастомный хук для работы с данными пресета
 * @returns Настройки для селекта пресета
 */
export const useUserPresets = () => {
    const { currentModuleID } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { selectedUserPresetIds, userPresets, sharedPresetHash } = useSelector(PresetReducerValues);
    const [options, setOptions] = useState<IObject[]>([]);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    /**
     * Перезапись настроек пользователя данными из пресета
     */
    useEffect(() => {
        if (selectedUserPresetIds[currentModuleID]) {
            const settings = userPresets.find(
                (preset) => preset.id === selectedUserPresetIds[currentModuleID],
            )?.settings;

            settings && dispatch(changeOptions({ newOptions: settings }));
        }
    }, [currentModuleID, dispatch, selectedUserPresetIds, userPresets]);

    /**
     * Проверка на идентичность данный настроек пользователя с данными пресета
     */
    useEffect(() => {
        const currentPreset =
            userPresets.find((item) => item.id === selectedUserPresetIds[currentModuleID])?.settings || {};
        let isPresetEqual = true;

        Object.keys(currentPreset).forEach((key) => {
            if (
                isPresetEqual &&
                selectedUserPresetIds[currentModuleID] &&
                currentModuleID === localCurrentOptions?.currentModuleID
            ) {
                isPresetEqual = isEqual(localCurrentOptions?.[key], currentPreset[key]);
            }
        });

        !isPresetEqual && dispatch(storeSelectedUserPresetIds({ moduleId: currentModuleID, presetId: null }));
    }, [JSON.stringify(userPresets), JSON.stringify(localCurrentOptions)]);

    return options;
};
