import { FC, useEffect, useState } from 'react';
import { IProps } from './interfaces';
import useFetchData from './api/useFetchData';
import { Td } from '../../styles';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { CompareValue, MainValue, PercentValueWrapper } from './styles';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { useIsFetching } from '@tanstack/react-query';
import { SUMMARY_QUERY_KEY } from './constants/constants';
import getPercentOfTheMain from './tools/getPercentOfTheMain';
import { isUndefined } from 'lodash';

const DataCell: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const { data, isFetching, isError } = useFetchData(props);
    const [percentOfTheMain, setPercentOfTheMain] = useState<number | null>(null);
    const isFetchingMainPeriod = useIsFetching({
        queryKey: [
            SUMMARY_QUERY_KEY,
            props.moduleId, // props.moduleId должно обязательно быть вторым
            props.widgetId, // props.widgetId должно обязательно быть третьим
            'main', // должно обязательно быть четвертым
            props.metric, // props.metric должно обязательно быть пятым
            props.mainPeriod,
        ],
    });
    const { t } = useTranslation();

    const metricFromStore = allMetrics.find((item) => item.id === props.metric);

    useEffect(() => {
        if (!isFetchingMainPeriod && data && props.periodType === 'compare') {
            setPercentOfTheMain(
                getPercentOfTheMain({
                    compareValue: data.value,
                    mainPeriodQueryKey: [
                        SUMMARY_QUERY_KEY,
                        props.moduleId, // props.moduleId должно обязательно быть вторым
                        props.widgetId, // props.widgetId должно обязательно быть третьим
                        'main', // должно обязательно быть четвертым
                        props.metric, // props.metric должно обязательно быть пятым
                        props.mainPeriod,
                    ],
                    reportingObjectId: props.reportingObject.id,
                }),
            );
        }
    }, [isFetchingMainPeriod, data, props]);

    const colSpan = props.periodType === 'compare' ? 2 : 1;

    if (isFetching) {
        return (
            <Td colSpan={colSpan}>
                <span>{t('Loading...')}</span>
            </Td>
        );
    }

    if (isError) {
        return (
            <Td colSpan={colSpan}>
                <span>{t('An error accrued while getting data')}</span>
            </Td>
        );
    }

    if (!data) {
        return (
            <Td colSpan={colSpan}>
                <span>{t('No data')}</span>
            </Td>
        );
    }

    if (data) {
        if (props.periodType === 'main') {
            return (
                <Td colSpan={colSpan}>
                    <MainValue>
                        {valueFormatter({
                            precision: metricFromStore?.round_decimal_places,
                            units: metricFromStore?.units,
                            value: data.value,
                            t,
                        })}
                    </MainValue>
                </Td>
            );
        }

        return (
            <>
                <Td width={'10px'} padding={!isFetching && !isError && !isUndefined(data) ? '0' : undefined}>
                    <PercentValueWrapper value={isFetchingMainPeriod ? null : percentOfTheMain}>
                        {isFetchingMainPeriod
                            ? t('Loading...')
                            : valueFormatter({ value: percentOfTheMain, units: '%' })}
                    </PercentValueWrapper>
                </Td>
                <Td>
                    <CompareValue>
                        {valueFormatter({
                            precision: metricFromStore?.round_decimal_places,
                            units: metricFromStore?.units,
                            value: data.value,
                            showUnits: false,
                        })}
                    </CompareValue>
                </Td>
            </>
        );
    }

    return <Td colSpan={colSpan} />;
};

export default DataCell;
