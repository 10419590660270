import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPreset } from '../components/Preset/interfaces';
import { PresetReducerValues, storeSharedPresetHash } from '../components/Preset/reducer';
import { changeOptions, userSettingsReducerValues } from '../components/UserSettings/reducer';
import { generalReducerValues } from '../General.reducer';
import { getRequest, patchRequest } from '../tools/API/appBackendAPI';
import { useAppDispatch } from './useSettings';

export const useSharedPreset = () => {
    const dispatch = useAppDispatch();
    const [sharedPresetModalStatus, setSharedPresetModalStatus] = useState<{ show: boolean; sharedPreset?: IPreset }>({
        show: false,
    });

    const { globalSpinner, locations, urlsByServices, token, initialDataReceived } = useSelector(generalReducerValues);

    const { sharedPresetHash } = useSelector(PresetReducerValues);

    useEffect(() => {
        if (initialDataReceived && sharedPresetHash && token) {
            const url = `${urlsByServices?.['app/app-backend'].SHARED_PRESETS_URL}?hash=${sharedPresetHash}`;
            getRequest(url, token).then((res) => {
                const sharedPreset: IPreset = { ...res.data[0], name: `${res.data[0].name} (shared)` };
                setSharedPresetModalStatus({ show: true, sharedPreset });
                dispatch(storeSharedPresetHash(null));
            });
        }
    }, [sharedPresetHash, initialDataReceived, token, locations]);

    return { sharedPresetModalStatus, setSharedPresetModalStatus };
};
