import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import Title from '../../../../../components/Title/Title';
import Stack from '../../../../../components/Stack/Stack';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import HistoryTable from './components/HistoryTable/HistoryTable';

const DetailedBills: React.FC = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);

    const { t } = useTranslation(['translation', 'translationCategories']);
    const dispatch = useDispatch();

    const title = `${t('Reports history')}`;

    return reportingObjectsByType?.['tenant']?.length ? (
        <WidgetWrapper
            styles={{ marginTop: 10, marginBottom: 0, display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        >
            <Stack justifyContent="space-between" alignItems="center" gap={10}>
                <WidgetTitle>
                    <Title>{title}</Title>
                </WidgetTitle>
            </Stack>
            <HistoryTable />
        </WidgetWrapper>
    ) : (
        <WidgetWrapper styles={{ marginTop: 10, marginBottom: 0, display: 'flex', flexDirection: 'column' }}>
            {t('There are no tenants in the location!')}
        </WidgetWrapper>
    );
};

export default DetailedBills;
