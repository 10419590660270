import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IProps } from './interfaces';
import { removeAlert } from './reducer';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { MessageBar, MessageBarActions, MessageBarBody } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { ActionsBar, AlertText, useAlertStyles } from './styles';
import { NavLink } from 'react-router-dom';
import { CookiesAlertBody } from './components/CookiesAlertBody/CookiesAlertBody';
import Button from '../Button/Button';

/**
 * Компонент для отображения нотификации
 */
const Alert: FC<IProps> = ({ alert, children }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['policies', 'translation']);
    const styles = useAlertStyles();

    /** Автозакрытие */
    useEffect(() => {
        let timer: undefined | NodeJS.Timeout = undefined;
        if (alert.duration) {
            timer = setTimeout(() => dispatch(removeAlert(alert.id)), alert.duration * 1000);
        }

        return () => clearTimeout(timer);
    }, [alert.duration]);

    const onDismiss = () => {
        dispatch(removeAlert(alert.id));
    };

    const actions = useMemo(() => {
        if (!alert.actions || !alert.actions.length) {
            return null;
        } else {
            return (
                <ActionsBar>
                    {alert.actions?.map((action, i) => {
                        return (
                            <Button
                                key={`action--${i}`}
                                appearance="primary"
                                size="small"
                                text={action.text}
                                onClick={action.action}
                            />
                        );
                    })}
                </ActionsBar>
            );
        }
    }, [alert.actions]);

    const childrensMap: { [x: string]: ReactNode } = {
        COOKIES_ALERT: <CookiesAlertBody />,
    };

    return alert.hasChildren ? (
        <MessageBar className={styles.messageBar} intent={alert.type}>
            <MessageBarBody>{childrensMap?.[alert?.alias ?? ''] || null}</MessageBarBody>
            <MessageBarActions
                containerAction={
                    <Button
                        onClick={onDismiss}
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<DismissRegular />}
                    />
                }
            >
                {actions}
            </MessageBarActions>
        </MessageBar>
    ) : (
        <MessageBar className={styles.messageBar} intent={alert.type}>
            <MessageBarBody>
                <AlertText>
                    {t(alert.text)}
                    <br />
                    {alert.link && <NavLink to={alert.link.href}>{t(alert.link.text)}</NavLink>}
                </AlertText>
            </MessageBarBody>
            <MessageBarActions
                containerAction={
                    <Button
                        onClick={onDismiss}
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<DismissRegular />}
                    />
                }
            >
                {actions}
            </MessageBarActions>
        </MessageBar>
    );
};

export default Alert;
