import React, { useMemo, useState } from 'react';
import Panel from '../../../Panel/Panel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cabinetPreferencesValues, patchUserPreferences } from '../../reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { ZONES_WORD } from '../../../../constants/constants';
import { useAppDispatch } from '../../../../hooks/useSettings';
// import { Radio, RadioGroup, RadioGroupOnChangeData } from '@fluentui/react-components';
import Select from '../../../Selects/Select/Select';
import { TSelectedOptions } from '../../../Selects/Select/interfaces';
import { TUseBusinessDays } from '../../interfaces';
import { Footer } from '../../styles';
import Stack from '../../../Stack/Stack';
import Button from '../../../Button/Button';
import { isEqual, omit } from 'lodash';

/**
 * Компонент для отображения настроек уровня запроса метрик
 */
const General: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const {
        cfg: { reportingObjectsByType },
        currentModuleID,
    } = useSelector(generalReducerValues);

    const { preferences } = useSelector(cabinetPreferencesValues);

    const [localPreferences, setLocalPreferences] = useState(preferences);

    const BUSINESS_DAYS_OPTIONS = useMemo(() => {
        return [
            { id: 'yes', text: t('Yes') },
            { id: 'no', text: t('No') },
        ];
    }, [t]);

    const localCurrentOptions = useMemo(() => {
        return BUSINESS_DAYS_OPTIONS.filter((item) => item.id === localPreferences.useBusinessDays);
    }, [BUSINESS_DAYS_OPTIONS, localPreferences.useBusinessDays]);

    const zoneGroupsOptions = useMemo(() => {
        const result = [{ id: 'All', text: t('All') }];
        const add = Object.keys(reportingObjectsByType)
            .filter((key) => {
                return key.includes(ZONES_WORD);
            })
            .map((key) => {
                const id = reportingObjectsByType[key][0].object_params?.group_marker || '';
                const text = reportingObjectsByType[key][0].object_params?.group_marker || '';
                return { id, text };
            });

        add.length > 1 && result.push(...add);
        return result;
    }, [reportingObjectsByType, t]);

    const changeBusinessDays = (args: TSelectedOptions) => {
        args[0].id && setLocalPreferences({ ...localPreferences, useBusinessDays: args[0].id as TUseBusinessDays });
    };

    const onOkClick = () => {
        if (!isEqual(localPreferences, preferences)) {
            // dispatch(storeCabinetPreferences({ preferences: localPreferences }));
            dispatch(patchUserPreferences(omit(localPreferences, ['id'])));
        }
        // closeModal();
    };

    return (
        <>
            {/* <Panel title={t('Main zones group')}>
                    <UniversalSelect
                        options={zoneGroupsOptions}
                        mode="single"
                        name={'mainZoneGroup'}
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                    />
                </Panel> */}
            <Panel title={t('Use of business days')}>
                <Select
                    showFilter={false}
                    iconType="chevron-down"
                    fullWidth
                    testId="business_days_changed"
                    options={BUSINESS_DAYS_OPTIONS}
                    mode="single"
                    dropdownWidth="auto"
                    outsideSelected={localCurrentOptions}
                    handleSelect={(args) => changeBusinessDays(args)}
                />
            </Panel>
            <Footer>
                <Stack gap={10}>
                    <Button onClick={closeModal} text={t('Cancel')} />
                    <Button
                        onClick={onOkClick}
                        text={t('Apply and restart')}
                        appearance="primary"
                        disabled={isEqual(localPreferences, preferences)}
                    />
                </Stack>
            </Footer>
        </>
    );
};

export default General;
