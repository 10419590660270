import { MouseEvent, useMemo, useState } from 'react';
import useFetchData from './hooks/useFetchData';
import { ResponseStatus } from '../../../../../../../../../tools/API/constants';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';
import { withLoading } from '../../../../../../../../../tools/API/withLoading';
import { StyledTableCellLayout, TableWrapper } from './styles';

import {
    Table,
    TableBody,
    TableCell,
    TableColumnDefinition,
    TableColumnId,
    TableHeader,
    TableHeaderCell,
    TableRow,
    createTableColumn,
    useTableFeatures,
    useTableSort,
    useTableColumnSizing_unstable,
    TableColumnSizingOptions,
} from '@fluentui/react-components';

import { ITableItem } from './dataAdapters/bgReportsTableDataAdapter';
import { useSelector } from 'react-redux';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from '../../../../reducer';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../../hooks/useWidgetCurrentOptions';
import { DS } from '../../../../../../../../../constants/constants';
import BgReportStatus from '../../../../../../../components/BgReportStatus/BgReportStatus';
import BgReportOutput from '../../../../../../../components/BgReportOutput/BgReportOutput';
import { DateTime } from 'luxon';
import { shortStringDate } from '../../../../../../../../../tools/Strings/shortStringDate';
import Modals from '../../../../../../../../../components/Modals/Modals';
import FilesModal from '../../../../../../../components/FilesModal/FilesModal';

const DetailedBillsTableColumns: TableColumnDefinition<ITableItem>[] = [
    createTableColumn<ITableItem>({
        columnId: 'id',
        compare: (a: ITableItem, b: ITableItem) => {
            return a.id - b.id;
        },
    }),
    createTableColumn<ITableItem>({
        columnId: 'tenantName',
        compare: (a: ITableItem, b: ITableItem) => {
            return String(a.tenantName).localeCompare(String(b.tenantName));
        },
    }),
    createTableColumn<ITableItem>({
        columnId: 'createdAt',
        compare: (a: ITableItem, b: ITableItem) => {
            return DateTime.fromISO(a.createdAt).toMillis() - DateTime.fromISO(b.createdAt).toMillis();
        },
    }),
    // createTableColumn<ITableItem>({
    //     columnId: 'reportType',
    //     compare: (a: ITableItem, b: ITableItem) => {
    //         return String(a.reportType).localeCompare(String(b.reportType));
    //     },
    // }),

    createTableColumn<ITableItem>({
        columnId: 'period',
        compare: (a: ITableItem, b: ITableItem) => {
            return DateTime.fromISO(a.period.dateFrom).toMillis() - DateTime.fromISO(b.period.dateFrom).toMillis();
        },
    }),

    createTableColumn<ITableItem>({
        columnId: 'output',
    }),

    createTableColumn<ITableItem>({
        columnId: 'status',
        compare: (a: ITableItem, b: ITableItem) => {
            return String(a.status).localeCompare(String(b.status));
        },
    }),
];

/**
 * Компонент для отображения таблицы со всеми заказанными отчетами
 */
const ArchiveTable = () => {
    const { data, error, isLoading } = useFetchData();
    const { keyWord } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);
    const { currentModuleID, lang } = useSelector(generalReducerValues);

    const [outputModalStatus, setOutputModalStatus] = useState<{ show: boolean; jsonOutput: unknown }>({
        show: false,
        jsonOutput: {},
    });
    const [filesModalStatus, setFilesModalStatus] = useState<{ show: boolean; files: string[] }>({
        show: false,
        files: [],
    });

    const rest = `${DS}${keyWord}`;

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const [columnSizingOptions, setColumnSizingOptions] = useState<TableColumnSizingOptions>({
        id: {
            idealWidth: 100,
            defaultWidth: 100,
        },
    });

    const {
        getRows,
        sort: { getSortDirection, toggleColumnSort, sort },
        columnSizing_unstable: columnSizing,
    } = useTableFeatures(
        {
            columns: DetailedBillsTableColumns,
            items: data || [],
        },
        [
            useTableColumnSizing_unstable({ columnSizingOptions }),
            useTableSort({
                defaultSortState: { sortColumn: 'id', sortDirection: 'descending' },
            }),
        ],
    );

    const { t } = useTranslation();

    const status = useMemo(() => {
        if (isLoading) {
            return { status: ResponseStatus.Loading, message: t('Loading...') };
        }

        if (error) {
            return { status: ResponseStatus.Error, message: error.message };
        }

        if (!data?.length) {
            return { status: ResponseStatus.NoData, message: t('No data') };
        }

        if (data?.length) {
            return { status: ResponseStatus.Success, message: t('Success') };
        }

        return { status: ResponseStatus.NoData, message: t('No data') };
    }, [data, error, isLoading, t]);

    const rows = useMemo(() => {
        return sort(
            getRows().filter((item) => {
                return localCurrentOptions?.[`objectsToShow${rest}`].includes(item.item.tenantId);
            }),
        );
    }, [getRows, localCurrentOptions, rest, sort]);

    const openFilesModal = (files: string[]) => {
        setFilesModalStatus({ show: true, files });
    };

    const openOutputModal = (jsonOutput: unknown) => {
        setOutputModalStatus({ show: true, jsonOutput });
    };

    const closeOutputModal = () => {
        setOutputModalStatus({ show: false, jsonOutput: {} });
    };

    const closeFilesModal = () => {
        setFilesModalStatus({ show: false, files: [] });
    };

    const content = useMemo(() => {
        const headerSortProps = (columnId: TableColumnId) => ({
            onClick: (e: React.MouseEvent) => {
                toggleColumnSort(e, columnId);
            },
            sortDirection: getSortDirection(columnId),
        });
        const WithLoading = withLoading(TableWrapper, { height: 400, data: status });
        return (
            <WithLoading>
                {data && (
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell
                                    {...columnSizing.getTableHeaderCellProps('id')}
                                    {...headerSortProps('id')}
                                >
                                    {t('Report id')}
                                </TableHeaderCell>
                                <TableHeaderCell {...headerSortProps('tenantName')}>
                                    <StyledTableCellLayout align="right">{t('Tenant name')}</StyledTableCellLayout>
                                </TableHeaderCell>
                                <TableHeaderCell {...headerSortProps('createdAt')}>
                                    <StyledTableCellLayout align="right">{t('Created at')}</StyledTableCellLayout>
                                </TableHeaderCell>
                                <TableHeaderCell {...headerSortProps('period')}>
                                    <StyledTableCellLayout align="right">{t('Period')}</StyledTableCellLayout>
                                </TableHeaderCell>
                                <TableHeaderCell {...headerSortProps('status')}>
                                    <StyledTableCellLayout align="right"> {t('Status')}</StyledTableCellLayout>
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <StyledTableCellLayout align="right">{t('Output')}</StyledTableCellLayout>
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {rows.map(({ item }) => (
                                <TableRow key={item.id}>
                                    <TableCell {...columnSizing.getTableHeaderCellProps('id')}>
                                        <StyledTableCellLayout>{item.id}</StyledTableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <StyledTableCellLayout align="right">{item.tenantName}</StyledTableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <StyledTableCellLayout align="right">
                                            {shortStringDate(item.createdAt, lang)}
                                        </StyledTableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <StyledTableCellLayout align="right">
                                            {shortStringDate(item.period, lang)}
                                        </StyledTableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <StyledTableCellLayout align="right">
                                            <BgReportStatus status={item.status} />
                                        </StyledTableCellLayout>
                                    </TableCell>
                                    <TableCell align="right">
                                        <StyledTableCellLayout align="right">
                                            <BgReportOutput
                                                status={item.status}
                                                output={item.jsonOutput}
                                                files={item.filesOutput}
                                                openFilesModal={openFilesModal}
                                                openOutputModal={openOutputModal}
                                            />
                                        </StyledTableCellLayout>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </WithLoading>
        );
    }, [columnSizing, data, getSortDirection, lang, rows, status, t, toggleColumnSort]);

    return (
        <>
            <Modals closeModal={closeFilesModal} modalStatus={filesModalStatus} title={t('Report files')}>
                <FilesModal files={filesModalStatus.files} />
            </Modals>
            <Modals closeModal={closeOutputModal} modalStatus={outputModalStatus} title={t('Output content')}>
                <div style={{ width: '50vw' }}>
                    <span>Some serialized content from report output</span>
                    <div>{JSON.stringify(outputModalStatus.jsonOutput || '')}</div>
                </div>
            </Modals>
            {content}
        </>
    );
};

export default ArchiveTable;
