import { IdsOfREportingObjectTypes } from './constants/optionsOfReportingObjectType';

import FiltersSelect from '../../../components/Filters/FiltersSelect/FiltersSelect';
import { ISettings } from '../../../components/UserSettings/interfaces';
import { LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE } from './constants/constants';
import LeasingCrossVisitsWidget from './widgets/LeasingCrossVisitsWidget/LeasingCrossVisitsWidget';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../General.reducer';
import { getReportingObjects } from '../../../tools/getReportingObjects';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { ZONES_WORD } from '../../../constants/constants';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import useAnalytics from './hooks/useAnalytics';
import { useAppDispatch } from '../../../hooks/useSettings';
import { changeOptions } from '../../../components/UserSettings/reducer';

/**
 * Компонент дя отображения модуля Leasing:Cross-visits
 */
const CrossVisits = () => {
    const { t } = useTranslation();
    useAnalytics();

    const {
        currentModuleID,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const REPORTING_OBJECTS_VALID_OPTIONS = ['Zones', t('Tenants')];

    const validOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [reportingObjectsByType, t]);

    const optionsOfReportingObjectType = useMemo(() => {
        if (Object.keys(reportingObjectsByType)) {
            const validFields = ['tenant'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    id: item.key,
                    text: item.section,
                }))
                ?.filter((item) => validFields.includes(item.id) || item.id.includes(ZONES_WORD));
        }
        return [];
    }, [reportingObjectsByType, t]);

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ObjectsSelect
                            dataObjectFilters={['data_objects_floor', 'data_objects_tenant']}
                            maxSelected={5}
                            validOptions={REPORTING_OBJECTS_VALID_OPTIONS}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            name={LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE}
                            labelText={t('Objects types')}
                            options={optionsOfReportingObjectType}
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        {localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE]?.[0]?.id ===
                            IdsOfREportingObjectTypes.Tenants && <FiltersSelect validOptions={validOptions} />}
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE,
                                    'selectedReportingObjectsIds',
                                    'mainPeriod',
                                    'filters',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                <LeasingCrossVisitsWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default CrossVisits;
