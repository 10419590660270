import React, { useEffect, useState } from 'react';
import { Props } from './configurations';
import * as constants from '../../constants/constants';

import cn from 'classnames';
import { ButtonWrapper, SearchWrapper } from './styles/styles';
import { IIcons } from '../Icon/configurations/iconsList';

import { theme } from 'src/theme';
import './styles/styles.scss';
import { isUndefined, size } from 'lodash';
import { SearchRegular } from '@fluentui/react-icons';
import IconButton from '../IconButton/IconButton';

const Search: React.FC<Props> = ({
    disabled = false,
    id = undefined,
    name = undefined,
    maxLength = constants.DEFAULT_INPUT_MAX_LENGTH,
    minLength = 0,
    placeholder = '',
    pattern = undefined,
    size = 'md',
    fullWidth = false,
    handleChange,
    outsideValue,
}) => {
    const [iconType, setIconType] = useState<IIcons>('search');

    const [inputValue, setInputValue] = useState<string | undefined>('');
    const { DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } = constants;

    useEffect(() => {
        if (!isUndefined(outsideValue)) {
            setInputValue(outsideValue);
        }
    }, [outsideValue]);

    useEffect(() => {
        if (inputValue && inputValue.length) {
            setIconType(() => 'close');
        } else {
            setIconType(() => 'search');
        }
    }, [inputValue, id, name]);

    /**
     * получить класс размера кнопки
     * @param size - размер кнопки
     */
    const getSizeCss = (size: string): string => {
        return `${DEFAULT_CLASS_INPUT_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}size${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${size}`;
    };

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        handleChange && handleChange({ id, name, value: newValue });
        setInputValue(() => newValue);
    };

    const onIconClick = () => {
        if (iconType === 'close') {
            setInputValue(() => '');
            handleChange && handleChange({ id, name, value: '' });
        }
    };

    const className = React.useMemo(() => {
        const cssClassSize = getSizeCss(size);
        return cn(
            DEFAULT_CLASS_INPUT_PREFIX,
            cssClassSize,
            {
                [`${DEFAULT_CLASS_INPUT_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}full-width`]: fullWidth,
            },
            'search__input',
        );
    }, [DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, getSizeCss, size, disabled]);

    return (
        <SearchWrapper fullWidth={fullWidth}>
            <input
                type={'text'}
                placeholder={placeholder}
                pattern={pattern}
                maxLength={maxLength}
                minLength={minLength}
                disabled={disabled}
                className={className}
                value={inputValue}
                onChange={onChange}
            />
            <ButtonWrapper isRtl={document.dir === 'rtl'}>
                <IconButton
                    styles={{ marginRight: theme.spaces.xxs }}
                    size={20}
                    onClick={onIconClick}
                    icon={<SearchRegular />}
                />
            </ButtonWrapper>
        </SearchWrapper>
    );
};

export default Search;
