import {
    ArrowTrendingLines24Regular,
    CalendarDate24Regular,
    Grid24Regular,
    TextNumberListLtr24Regular,
} from '@fluentui/react-icons';
import { IWidgetType } from '../../../components/DashboardsCommon/interfaces';

export const widgetTypes: IWidgetType[] = [
    {
        type: 'table',
        title: 'Table',
        children: [
            {
                id: '',
                type: 'locationsByMetrics',
                title: 'Summary',
                requiredParams: ['mainPeriod', 'comparePeriods'],
                sources: ['default'],
                options: {},
                visual: {},
            },
            {
                id: '',
                type: 'referenceByLocation',
                title: 'Comparison relative to benchmark',
                requiredParams: ['mainPeriod', 'comparePeriods'],
                sources: ['default'],
                options: {},
                visual: {},
            },
        ],
        icon: <Grid24Regular />,
        defaultLayout: {
            h: 10,
            w: 36,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
    {
        type: 'events',
        title: 'Events',
        children: [
            {
                id: '',
                type: 'networkEventsByPl',
                title: 'Network events',
                requiredParams: ['mainPeriod'],
                sources: ['default'],
                options: {},
                visual: {},
            },
        ],
        icon: <CalendarDate24Regular />,
        defaultLayout: {
            h: 4,
            w: 36,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
    {
        type: 'dynamics',
        title: 'Dynamics',
        children: [
            {
                id: '',
                type: 'objectsLocations',
                title: 'Locations',
                requiredParams: ['mainPeriod'],
                sources: ['default'],
                options: {},
                visual: {},
            },
            {
                id: '',
                type: 'periodsLocations',
                title: 'Periods',
                requiredParams: ['mainPeriod', 'comparePeriods'],
                sources: ['default'],
                options: {},
                visual: {},
            },
        ],
        icon: <ArrowTrendingLines24Regular />,
        defaultLayout: {
            h: 12,
            w: 36,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
    {
        type: 'rating',
        title: 'Rating',
        children: [
            {
                id: '',
                type: 'topByMetricsLocations',
                title: 'Top by metrics',
                requiredParams: ['mainPeriod', 'comparePeriods'],
                sources: ['default'],
                options: {},
                visual: {},
            },
        ],
        icon: <TextNumberListLtr24Regular />,
        defaultLayout: {
            h: 8,
            w: 18,
            x: 100,
            y: 100,
            isResizable: true,
        },
    },
];

export const widgetTypesByType: { [widgetType: string]: IWidgetType } = widgetTypes.reduce((acc, value) => {
    acc[value.type] = value;
    return acc;
}, {});
