import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    overflow: auto;
    table {
        width: 100%;
        position: relative;
        table-layout: auto !important;
    }

    td,
    th {
        input {
            width: 100% !important;
            height: 100% !important;
            padding: var(--space-xxxs) !important;
        }
    }
`;
