import { FC, useState } from 'react';
import Stack from '../../../../../../../components/Stack/Stack';
import { IProps } from './interfaces';
import ReportingObjectsTabs from '../ReportingObjectsTabs/ReportingObjectsTabs';
import useFetchData from '../../api/useFetchData';
import Dynamics from '../../../../../../../components/Charts/Dynamics/Dynamics';

const Chart: FC<IProps> = (props) => {
    const { data } = useFetchData(props);

    const [selectedReportingObjectId, setSelectedReportingObjectId] = useState(props.reportingObjectsIds[0]);

    const onReportingObjectSelect = (id: number) => {
        setSelectedReportingObjectId(id);
    };

    return (
        <Stack direction="column" gap={15}>
            <ReportingObjectsTabs
                selectedReportingObjectId={selectedReportingObjectId}
                reportingObjectsIds={props.reportingObjectsIds}
                onSelect={onReportingObjectSelect}
            />
            {data?.chartOptionsByReportingObjectId?.[selectedReportingObjectId] && (
                <div>
                    <Dynamics {...data.chartOptionsByReportingObjectId[selectedReportingObjectId]} />
                </div>
            )}
        </Stack>
    );
};

export default Chart;
