import { ReportingObjectInfo, StepContentWrapper, PercentageFill, Percentage, Wrapper, Title, Step } from './styles';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { FC } from 'react';
import { optionsOfFunnelSteps } from '../../../../constants/constants';
import { DS } from '../../../../../../../constants/constants';
import { isNumber } from 'lodash';
import getPercentage from './tools/getPercentage';

/**
 * Компонент для отображения воронки для отчетного объекта
 */
const Funnel: FC<IProps> = ({ data, funnelSteps, funnelSettings }) => {
    const { t } = useTranslation();
    const idsOfFunnelSteps = funnelSteps.map((element) => element.id);

    return (
        <Wrapper>
            <Title>{data.reportingObjectData.name}</Title>
            {Object.values(optionsOfFunnelSteps)
                .sort((a, b) => b.order - a.order)
                .filter((funnelSteps) => idsOfFunnelSteps.includes(funnelSteps.id))
                .map((funnelStep, index, arr) => {
                    const units = funnelStep.id === 'billsCount' ? 'pc' : 'ppl';
                    const currentStepMetricValue = data?.[funnelStep.id]?.metricValue;
                    let percentage: number | '—' = 100;

                    if (index !== 0) {
                        percentage = getPercentage({
                            prevValue: data?.[arr[index - 1]?.id]?.metricValue,
                            locationValue: data?.location?.metricValue,
                            mainValue: currentStepMetricValue,
                            funnelSettings,
                        });
                    }

                    return (
                        <Step key={`${data.reportingObjectData.id}${DS}${funnelStep.id}`}>
                            <StepContentWrapper>
                                <ReportingObjectInfo>
                                    <div>
                                        <span>{t(funnelStep.text)}</span>{' '}
                                        {data?.[funnelStep.id]?.name ? data?.[funnelStep.id]?.name : '—'}
                                    </div>
                                    <div>{valueFormatter({ value: currentStepMetricValue, t, units })}</div>
                                </ReportingObjectInfo>
                                <Percentage>
                                    {isNumber(percentage)
                                        ? valueFormatter({ value: percentage, units: '%' })
                                        : percentage}
                                </Percentage>
                            </StepContentWrapper>
                            <PercentageFill width={isNumber(percentage) ? percentage : 0} />
                        </Step>
                    );
                })}
        </Wrapper>
    );
};

export default Funnel;
