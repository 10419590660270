import { FC } from 'react';
import { IProps } from './interfaces';
import { shortStringDate } from '../../../../../tools/Strings/shortStringDate';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { Th } from '../../styles';

/**
 * Шапка таблицы
 */
const Header: FC<IProps> = ({ mainPeriod, comparePeriods, viewSettings }) => {
    const { lang } = useSelector(generalReducerValues);
    return (
        <thead>
            <tr>
                <Th colSpan={2} />
                {[mainPeriod, ...comparePeriods].map((period, index) => {
                    if (viewSettings?.showRelativeValues && index === 0) {
                        return <Th key={JSON.stringify(period) + index} paddingLeft="0px" paddingRight="0px" />;
                    }
                    return <Th key={JSON.stringify(period) + index}>{shortStringDate(period, lang)}</Th>;
                })}
            </tr>
        </thead>
    );
};

export default Header;
