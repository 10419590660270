import { IUseInitialDataArgs, IUseInitialDataResponse } from '../tools/interfaces';
import { ILayer } from '../../../../../../General.interfaces';
import { TSupportedLayerTypes } from '../../../Floors/interfaces';
import getTransformedLayer from '../tools/getTransformedLayer';
import { useEffect, useState } from 'react';

const useInitialData = (args: IUseInitialDataArgs): IUseInitialDataResponse => {
    const { layers, selectedLayerType, plan, selectedGroupOfZones } = args;
    const [transformedLayer, setTransformedLayer] = useState<null | ILayer>(null);
    const [transformedPerimeterLayer, setTransformedPerimeterLayer] = useState<null | ILayer>(null);

    /** Получение преобразованного слоя с измененными координатами */
    useEffect(() => {
        const PERIMETER_LAYER_TYPE: TSupportedLayerTypes = 'perimeter_layer';
        const transformedLayer = getTransformedLayer({ layers, plan, selectedLayerType, selectedGroupOfZones });
        const transformedPerimeterLayer = getTransformedLayer({
            layers,
            plan,
            selectedLayerType: PERIMETER_LAYER_TYPE,
            selectedGroupOfZones,
        });
        setTransformedPerimeterLayer(transformedPerimeterLayer);
        setTransformedLayer(transformedLayer);
    }, [layers, selectedLayerType, plan]);

    return { transformedLayer, transformedPerimeterLayer };
};

export default useInitialData;
