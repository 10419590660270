import React from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { LogoBox } from './styles';

const Logo: React.FC = () => {
    const { appCoreData } = useSelector(generalReducerValues);
    return <LogoBox href="/">{appCoreData?.logo_url && <img src={appCoreData?.logo_url} alt={'Logo'} />}</LogoBox>;
};

export default Logo;
