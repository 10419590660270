import { useMemo } from 'react';
import { IDataAdapterResponse, IProps } from '../interfaces';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import defaultDataAdapter from './dataAdapter';
import { TMetricResponse } from '../../../../../../General.interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { DateTime } from 'luxon';
import { IRequestMetricsArgs } from '../../../../../../tools/API/interfaces';
import { useQuery } from '@tanstack/react-query';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { DYNAMICS_BY_MONTHS_QUERY_KEY } from '../constants/constants';
import { useWidgetCurrentOptions } from 'src/hooks';

interface IArgs extends IProps {}

const useFetchData = (args: IArgs) => {
    const {
        token,
        allMetrics,
        cfg: { reportingObjectsById },
        currentModuleID,
    } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const fetchData = useRequestMetrics();

    const { t } = useTranslation(['metrics', 'translation']);

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        if (token) {
            const requestArgs: IRequestMetricsArgs[] = [];
            args.years.forEach((year) => {
                const dateFrom = DateTime.fromObject({
                    year: Number(year.id),
                    month: 1,
                    day: 1,
                }).toISODate();
                const dateTo = DateTime.fromObject({
                    year: Number(year.id),
                    month: 12,
                    day: 31,
                }).toISODate();

                if (dateFrom && dateTo) {
                    requestArgs.push({
                        time_range: [dateFrom, dateTo],
                        obj_ids: args.reportingObjectsIds,
                        metric: args.metric,
                        object_aggregation: false,
                        time_freq: 'MS',
                        alias: null,
                        token,
                    });
                }
            });
            const dataAccessorArgs: IMetricsDAArgs = {
                requestArgs,
                fetchData,
            };

            return {
                queryKey: [
                    DYNAMICS_BY_MONTHS_QUERY_KEY,
                    args.moduleId,
                    args.widgetId,
                    args.metric,
                    args.reportingObjectsIds,
                    args.years,
                ],
                queryFn: () => metricsDataAccessor(dataAccessorArgs),
                dataAdapter: (response: TMetricResponse[]) =>
                    defaultDataAdapter({
                        viewSettings: args.viewSettings,
                        reportingObjectsById,
                        allMetrics,
                        response,
                        t,
                    }),
            };
        }
        return {};
    }, [
        token,
        args.years,
        args.moduleId,
        args.widgetId,
        args.metric,
        args.reportingObjectsIds,
        args.viewSettings,
        reportingObjectsById,
        fetchData,
        allMetrics,
        t,
    ]);

    return useQuery<TMetricResponse[], unknown, IDataAdapterResponse, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter) && Boolean(localCurrentOptions),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;
