import { DateTime } from 'luxon';
import { FC, memo, useContext, useEffect, useState } from 'react';
import Header from './components/Header/Header';
import MonthsGrid from './components/MonthsGrid/MonthsGrid';
import YearsGrid from './components/YearsGrid/YearsGrid';
import { TMode } from './interfaces';
import { CalendarWrapper, IconWrapper, ShifterLine } from './styles';
import Icon from '../../../../../../../components/Icon/Icon';
import { EventsMapWidgetContext } from '../../context';

interface IProps {}

/**
 * Компонент для отображения карты событий (календаря)
 */
const Calendar: FC<IProps> = memo(() => {
    const context = useContext(EventsMapWidgetContext);

    const [mode, setMode] = useState<TMode>('years');
    const [date, setDate] = useState(DateTime.now());
    const [shift, setShift] = useState(0);

    useEffect(() => {
        setDate(DateTime.now().plus({ [mode]: shift }));
    }, [shift, mode]);

    const changeModeHandler = (mode: TMode) => {
        setMode(mode);
        setShift(0);
    };

    const onShiftClick = (shift: number) => () => {
        setShift((prevState) => prevState + shift);
    };

    const setModeTrans = (mode: TMode) => {
        setMode(mode);
    };

    const setShiftTrans = (shift: number) => {
        setShift(shift);
    };

    return (
        <>
            <Header mode={mode} changeModeHandler={changeModeHandler} />
            <CalendarWrapper>
                <ShifterLine>
                    <IconWrapper onClick={onShiftClick(-1)}>
                        <Icon type={context?.isRtlLanguage ? 'chevron-right' : 'chevron-left'} size="xxs" />
                    </IconWrapper>

                    <IconWrapper onClick={onShiftClick(1)}>
                        <Icon type={context?.isRtlLanguage ? 'chevron-left' : 'chevron-right'} size="xxs" />
                    </IconWrapper>
                </ShifterLine>
                {mode === 'months' && <MonthsGrid date={date} />}
                {mode === 'years' && (
                    <YearsGrid setShiftTrans={setShiftTrans} setModeTrans={setModeTrans} date={date} />
                )}
            </CalendarWrapper>
        </>
    );
});

export default Calendar;
