import { ObjByMeticsTableViewOptionIds, ObjByMeticsUseMetricsFromIds } from '../enums';

export const OBJ_BY_METRICS_TABLE_VIEW_OPTIONS = [
    // { id: ObjByMeticsTableViewOptionIds.ShowAll, text: 'Show all' },
    { id: ObjByMeticsTableViewOptionIds.ShowRelativeValues, text: 'Show relative values' },
    { id: ObjByMeticsTableViewOptionIds.ShowAbsoluteValues, text: 'Show absolute values' },
];

export const OBJ_BY_METRICS_USE_METRICS_FROM_OPTIONS = [
    { id: ObjByMeticsUseMetricsFromIds.FromWidget, text: 'From widget' },
    { id: ObjByMeticsUseMetricsFromIds.FromPanel, text: 'From panel' },
];

export const TABLE_VIEW_OPTIONS = [
    { id: 'showRelativeValues', text: 'Show relative values' },
    { id: 'showAbsoluteValues', text: 'Show absolute values' },
];
