import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { generalReducerValues } from '../../../../../../General.reducer';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { TMetricResponse } from '../../../../../../General.interfaces';
import { IMetricParams, IRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import dataAdapter from './dataAdapter';
import { DS } from '../../../../../../constants/constants';
import {
    CORRECTION_MAP,
    operationTypes,
    paymentAnalysisTenantSelect,
    paymentTypes,
    salesMetricsNames,
    vatSelect,
} from '../../../constants/constants';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';

const useFetchData = () => {
    const { token, allMetrics, currentModuleID, currencyCode } = useSelector(generalReducerValues);


    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const fetchData = useRequestMetrics();
    const { t } = useTranslation(['metrics', 'translation']);

    const [queryKey, queryFn, adapter] = useMemo(() => {
        const vatMode: string = localCurrentOptions?.[vatSelect]?.[0]?.id ?? 'novat';

        const mainDatePeriod = localCurrentOptions?.mainDateRanges?.find(
            (item) => item.id === localCurrentOptions?.mainPeriod?.id,
        )?.period;

        const tenantId = localCurrentOptions?.[paymentAnalysisTenantSelect]?.[0];
        const correctionReceipts = localCurrentOptions?.['correctionReceipts']?.[0].id as string | undefined;

        if (mainDatePeriod && token && tenantId && correctionReceipts) {
            const myAdapter = (response: TMetricResponse[]) =>
                dataAdapter({
                    currencyCode,
                    allMetrics,
                    response,
                    vatMode,
                    t,
                });

            const requestArgs = salesMetricsNames.reduce((acc, mn) => {
                const metric = `${mn}${vatMode}`;
                const reqs = paymentTypes.map((pType) => {
                    return operationTypes.map((operationType) => {
                        return prepareArgs({
                            timeRange: [mainDatePeriod.dateFrom, mainDatePeriod.dateTo],
                            alias: `${operationType}${DS}${pType}`,
                            objIds: [tenantId],
                            metric,
                            timeFreq: null,
                            token,
                            metricParams: {
                                operation_type: operationType,
                                bill_type: CORRECTION_MAP[correctionReceipts],
                                payment_sum: pType,
                            },
                        });
                    });
                });
                return [...acc, ...reqs.flat()];
            }, []);

            const args: IMetricsDAArgs = { requestArgs, fetchData };
            return [
                [
                    'paymentStructure',
                    vatMode,
                    mainDatePeriod.dateFrom,
                    mainDatePeriod.dateTo,
                    tenantId,
                    correctionReceipts,
                ],
                () => metricsDataAccessor(args),
                myAdapter,
            ];
        } else {
            return [[]];
        }
    }, [allMetrics, fetchData, localCurrentOptions, t, token]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: adapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;

interface IPrepareArgs {
    timeRange: [string, string];
    alias: string;
    metric: string;
    objIds: number[];
    token: string;
    timeFreq?: TTimeFreq;
    metricParams?: IMetricParams;
}

const prepareArgs = (args: IPrepareArgs): IRequestMetricsArgs => {
    const { timeRange, alias, metric, objIds, token, metricParams, timeFreq = null } = args;
    return {
        object_aggregation: false,
        time_range: timeRange,
        time_freq: timeFreq,
        obj_ids: objIds,
        metric,
        alias,
        token,
        metric_params: metricParams,
    };
};
