import { IGenerateOptionsArgs } from './interfaces';
import { defaultOptions } from '../constants/defaultOptions';
import generateChart from './chart/generateChart';
import generateLegend from './legend/generateLegend';
import generatePlotOptions from './plotOptions/generatePlotOptions';
import generateSeries from './series/generateSeries';
import generateTooltip from './tooltip/generateTooltip';
import generateXAxis from './xAxis/generateXAxis';
import generateYAxis from './yAxis/generateYAxis';

/**
 * Функция гля генерации настроек для графика
 */
const generateOptions = (args: IGenerateOptionsArgs) => {
    const {
        series,
        showSeriesNameInTooltipBody = false,
        showCurrentDateIndicator = false,
        columnDataLabelsEnabled = false,
        numberOfSeriesToDispaly = 4,
        showSeriesAnimation = true,
        inactiveSeriesOpacity = 1,
        additionalLegendSettings,
        legendWrapperInlineStyle,
        xAxisType = 'datetime',
        legendWrapperClassName,
        columnsGrouping = true,
        seriesReverse = false,
        sharedTooltips = true,
        customChartDimensions,
        tooltipSubTitle = '',
        xAxisLabelFontSize,
        tooltipTitle = '',
        inverted = false,
        windowDimensions,
        useUTC = false,
        timezone = '',
        columnsStacking,
        plotPieOptions,
        chartSettings,
        categories,
        xAxisOptions,
        chartOptions,
        tooltipOptions,
        toggleShowSharedTooltips,
        tooltipTitleFormatter,
        xAxisLabelsFormating,
        t,
    } = args;
    const MAX_COLORS = 10;

    const extendedSeries = generateSeries({
        numberOfSeriesToDispaly,
        maxColors: MAX_COLORS,
        chartSettings,
        xAxisType,
        series,
        t,
    });

    const xAxis = generateXAxis({
        highlightWeekends: chartSettings?.highlightWeekends,
        series: extendedSeries,
        showCurrentDateIndicator,
        xAxisLabelsFormating,
        xAxisLabelFontSize,
        xAxisOptions,
        categories,
        xAxisType,
        timezone,
    });
    const yAxis = generateYAxis(extendedSeries, MAX_COLORS, windowDimensions);

    const tooltip = generateTooltip({
        showSeriesNameInTooltipBody,
        tooltipTitleFormatter,
        tooltipSubTitle,
        sharedTooltips,
        tooltipTitle,
        tooltipOptions,
    });

    const legend = generateLegend({
        seriesLength: extendedSeries?.length,
        additionalLegendSettings,
        legendWrapperInlineStyle,
        legendWrapperClassName,
        maxColors: MAX_COLORS,
        seriesReverse,
    });

    const plotOptions = generatePlotOptions({
        toggleShowSharedTooltips,
        columnDataLabelsEnabled,
        inactiveSeriesOpacity,
        showSeriesAnimation,
        columnsGrouping,
        columnsStacking,
        plotPieOptions,
    });

    const chart = generateChart(inverted, windowDimensions, customChartDimensions, chartOptions);

    return {
        ...defaultOptions,
        series: extendedSeries,
        time: { useUTC },
        plotOptions,
        tooltip,
        legend,
        chart,
        xAxis,
        yAxis,
    };
};

export default generateOptions;
