import styled from 'styled-components/macro';

export const Tab = styled.div<{ isSelected: boolean }>`
    border: none;
    background: ${({ isSelected }) => (isSelected ? '#6c757d' : '#f2f2f2')};
    border-radius: 8px;
    color: ${({ isSelected }) => (isSelected ? '#fff' : '#000')};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    padding: 8px 10px;
    cursor: pointer;
    margin-right: 12px;
    margin-top: 5px;
`;
