import { FC } from 'react';
import { IProps as ISelectsProps } from '../../interfaces';
import UniversalSelect from '../../../../../../../Selects/UniversalSelect/UniversalSelect';
import { IChangeOptionsArgs } from '../../../../../../../UserSettings/interfaces';

const TableViewSelect: FC<ISelectsProps> = (props) => {
    const updateWidgetVisual = (args: IChangeOptionsArgs) => {
        props.updateWidget('visual', args.newOptions);
    };

    const tableViewOptions = [
        { id: 'showAll', text: 'Show all' },
        { id: 'showAbsoluteValues', text: 'Show absolute values' },
        { id: 'showRelativeValues', text: 'Show relative values' },
    ];

    return (
        <UniversalSelect
            options={tableViewOptions}
            localCurrentOptions={{ ...props.widgetConfig?.visual, currentModuleID: props.moduleId }}
            changeOptions={updateWidgetVisual}
            labelText={'Table settings'}
            name={'tableView'}
            mode={'single'}
        />
    );
};

export default TableViewSelect;
