import { FC } from 'react';
import { IProps } from './interfaces';
import Stack from '../../../../../Stack/Stack';
import MetricsSelect from '../../../../../Selects/MetricsSelect/MetricsSelect';
import { IChangeOptionsArgs } from '../../../../../UserSettings/interfaces';
import UniversalSelect from '../../../../../Selects/UniversalSelect/UniversalSelect';
import { OBJ_BY_METRICS_TABLE_VIEW_OPTIONS } from '../../constants/constants';
import { ObjByMeticsUseMetricsFromIds } from '../../enums';

const OnBoardSelects: FC<IProps> = ({ widgetConfig, updateWidget, currentModuleId }) => {
    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        updateWidget('options', args.newOptions);
    };

    const updateWidgetVisual = (args: IChangeOptionsArgs) => {
        updateWidget('visual', args.newOptions);
    };

    return (
        <>
            <Stack gap={10} flexWrap="wrap">
                <MetricsSelect
                    disabled={widgetConfig?.visual.useMetricsFrom?.[0]?.id === ObjByMeticsUseMetricsFromIds.FromPanel}
                    localCurrentOptions={widgetConfig?.options}
                    changeOptions={updateWidgetOptions}
                    name={'metrics'}
                    maxSelected={10}
                />
                {(widgetConfig?.type?.includes('locationsByMetrics') ||
                    widgetConfig?.type?.includes('objectsByMetrics')) && (
                    <UniversalSelect
                        options={OBJ_BY_METRICS_TABLE_VIEW_OPTIONS}
                        localCurrentOptions={{ ...widgetConfig?.visual, currentModuleID: currentModuleId }}
                        changeOptions={updateWidgetVisual}
                        name={'tableView'}
                        mode={'single'}
                        labelText={'Table settings'}
                    />
                )}
            </Stack>
        </>
    );
};

export default OnBoardSelects;
