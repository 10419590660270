import { TFunction } from 'i18next';
import { IMetric } from '../../../../../General.interfaces';
import { ISeriesPoint, TSeries } from '../../../../Charts/Dynamics/interfaces';
import { DS } from '../../../../../constants/constants';
import { IAggregatedMetricDataByPeriodAndMetric } from '../../tools/aggregateMetricResponseByPeriodAndMetric';
import LegendPercentageInfo from '../components/LegendPercentageInfo/LegendPercentageInfo';
import { FC } from 'react';
import { isNumber } from 'lodash';

interface IArgs {
    /**
     * Все метрики из GeneralReducer
     */
    allMetrics: IMetric[];
    /**
     * Сырые данные метрик
     */
    metrics: IAggregatedMetricDataByPeriodAndMetric;
    /**
     * Функция перевода
     */
    t: TFunction;
    /**
     * Тип PieChart
     */
    widgetType: string | undefined;
}

/**
 * Функция для получения серий графика
 * @param args аргументы
 * @returns массив серий для графика
 */
const generateSeries = (args: IArgs): TSeries[] => {
    const { metrics, allMetrics, t, widgetType } = args;
    const series: TSeries[] = [];

    let title = '';
    let AdditionalComponent: null | FC<object> = null;
    // Это значение указывает на то сколько элементов показывать в пироге (остальные суммируются в кусок Others)
    let itemsToShow: number | null = null;

    let otherItem: null | ISeriesPoint = null;

    switch (widgetType) {
        case 'categories':
            title = t('Tenant');
            break;
        case 'distribByCats':
            title = t('Category');
            AdditionalComponent = LegendPercentageInfo;
            itemsToShow = 10;
            break;

        default:
            break;
    }

    Object.entries(metrics.mainPeriod).forEach(([dateKey, metricsData]) => {
        Object.entries(metricsData).forEach(([metric, metricData], metricIndex) => {
            const metricFromStore = allMetrics.find((item) => item.id === metric);
            const points = metricData
                .map((data) => {
                    const firstCompareDateKey = Object.keys(metrics.comparePeriod)[0] as string | undefined;
                    const compareValue = metrics.comparePeriod[firstCompareDateKey || '']?.[metric]?.find(
                        (compareMetricData) =>
                            compareMetricData.context.data_objects[0]?.id === data.context.data_objects[0]?.id,
                    )?.items?.[0]?.value;

                    return {
                        name: t(data.context.data_objects[0]?.name),
                        y: data.items?.[0]?.value,
                        compareValue,
                        AdditionalComponent,
                        title,
                        units: metricFromStore?.units,
                        precision: metricFromStore?.round_decimal_places,
                    };
                })
                .sort((a, b) => (b.y ?? 0) - (a.y ?? 0));

            /**
             * Если задан параметр itemsToShow, то берутся все элементы начиная с itemsToShow
             * и их значения y и compareValue складываются. Далее из этих значений создается
             * новый элемент серии под название "Others"
             */
            if (isNumber(itemsToShow)) {
                const { y, compareValue } = points.slice(itemsToShow).reduce(
                    (acc: { y: null | number; compareValue: null | number }, point, index) => {
                        if (isNumber(acc.y) && isNumber(point.y)) {
                            acc.y = acc.y + point.y;
                        } else if (isNumber(point.y)) {
                            acc.y = point.y;
                        }

                        if (isNumber(acc.compareValue) && isNumber(point.compareValue)) {
                            acc.compareValue = acc.compareValue + point.compareValue;
                        } else if (isNumber(point.compareValue)) {
                            acc.compareValue = point.compareValue;
                        }
                        return acc;
                    },
                    { y: null, compareValue: null },
                );
                if (isNumber(y) && isNumber(compareValue)) {
                    otherItem = {
                        name: t('Others'),
                        y,
                        compareValue,
                        AdditionalComponent,
                        title,
                        units: metricFromStore?.units,
                        precision: metricFromStore?.round_decimal_places,
                    } as unknown as ISeriesPoint;
                }
            }

            const data =
                isNumber(itemsToShow) && otherItem ? [...points.slice(0, itemsToShow as number), otherItem] : points;

            const result: TSeries = {
                id: `${dateKey}${DS}${metric}`,
                data: (data as unknown as ISeriesPoint[]).sort((a, b) =>
                    isNumber(b.y) && isNumber(a.y) ? b.y - a.y : 0,
                ),
                isMain: metricIndex === 0,
                name: 'series name',
                type: 'pie',
            };

            series.push(result);
        });
    });

    return series;
};

export default generateSeries;
