import { DurationLikeObject } from 'luxon';
import { TTimeFreq } from '../tools/API/interfaces';

export const detailOptions: { id: TTimeFreq; text: string; duration: DurationLikeObject | null }[] = [
    { id: null, text: 'Without detail', duration: null },
    { id: '15MIN', text: '15 min', duration: { minutes: 15 } },
    { id: 'H', text: 'Hours', duration: { hours: 1 } },
    { id: 'D', text: 'Days', duration: { days: 1 } },
    { id: 'W-MON', text: 'Weeks', duration: { weeks: 1 } },
    { id: 'MS', text: 'Months', duration: { months: 1 } },
    { id: 'YS', text: 'Years', duration: { years: 1 } },
];

const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const mounth = week * 4;
const year = mounth * 12;

export const chartTickIntervalsByDetail = {
    '15MIN': minute * 15,
    H: hour,
    D: day,
    'W-MON': week,
    MS: mounth,
    YS: year,
};

export const chartTickOptionsByDetail = {
    '15MIN': { minutes: 15 },
    H: { hours: 1 },
    D: { days: 1 },
    'W-MON': { weeks: 1 },
    MS: { months: 1 },
    YS: { years: 1 },
};
