import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

import { IReportingObject } from '../../../../../General.interfaces';

const initialState: IReducerState = {
    ratingType: 'location',
    allFloors: [],
    selectedFloor: null,
    selectedZoneId: null,
};

export const Performance_Dashboard_Traffic_Widget_Reducer = createSlice({
    name: 'Performance_Dashboard_Traffic_Widget_Reducer',
    initialState,

    reducers: {
        storeRatingType: (state, action: PayloadAction<string>) => {
            state.ratingType = action.payload;
        },

        storeSelectedZoneId: (state, action: PayloadAction<string | null>) => {
            state.selectedZoneId = action.payload;
        },

        storeAllFloors: (state, action: PayloadAction<IReportingObject[]>) => {
            state.allFloors = action.payload;
        },
        storeSelectedFloor: (state, action: PayloadAction<number | null>) => {
            state.selectedFloor = action.payload;
        },
    },
});

export const { storeRatingType, storeAllFloors, storeSelectedFloor, storeSelectedZoneId } =
    Performance_Dashboard_Traffic_Widget_Reducer.actions;

export const Performance_Dashboard_Traffic_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_Dashboard_Traffic_Widget_Reducer;

export default Performance_Dashboard_Traffic_Widget_Reducer.reducer;
