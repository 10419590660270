import { IMetricCell, IValidateArgs, IValidateFooResult } from '../interfaces';
import { useAppDispatch } from '../../../../hooks/useSettings';
import { invalidateTableData } from '../reducer';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';

export const useValidate = ({ validateFoos, tableKey, validateReadOnlyCells = false }: IValidateArgs) => {
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();

    const validate = (rows: IMetricCell[][]): { isValid: boolean; data: Omit<IValidateFooResult, 'isValid'>[] } => {
        const result: IValidateFooResult[] = [];

        rows.forEach((row) => {
            const editableCells = row.filter((cell) => {
                return validateReadOnlyCells ? true : !cell.readOnly;
            });
            validateFoos.forEach((foo) => {
                result.push(foo({ row: editableCells, reportingObjectsById }));
            });
        });

        if (result.some((item) => item.isValid === false)) {
            const onlyInvalidRows = result.filter((item) => item.isValid === false);
            dispatch(invalidateTableData({ data: onlyInvalidRows, tableKey }));
            return { isValid: false, data: onlyInvalidRows };
        } else {
            return { isValid: true, data: [] };
        }
    };

    return { validate };
};
