import { FC, useMemo } from 'react';
import { IProps as ISelectsProps } from '../../interfaces';
import UniversalNestedSelect from '../../../../../../../Selects/UniversalNestedSelect/UniversalNestedSelect';
import useTranslation from '../../../../../../../../hooks/useTranslation/useTranslation';
import { IChangeOptionsArgs } from '../../../../../../../UserSettings/interfaces';
import { useFetchGroupDataObjects } from '../../../../../../../../tools/API/hooks/groupDataObjects/useFetchGroupDataObjects';
import useGDONestedSelectOptions from '../../../../../../../../Chapters/Network/tools/useGDONestedSelectOptions';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';

const OnBoardNetworkGroupObjectsSelect: FC<ISelectsProps> = (props) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const groupDataObjects = useFetchGroupDataObjects();
    const groupObjectsOptions = useGDONestedSelectOptions(groupDataObjects);

    const { t } = useTranslation();

    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        props.updateWidget('options', args.newOptions);
    };

    return (
        <UniversalNestedSelect
            disabled={props.widgetConfig?.visual.useObjectsFrom?.[0].id === 'fromPanel'}
            options={groupObjectsOptions}
            name={`networkGroupObjects`}
            maxSelected={1}
            minSelected={1}
            labelText={t('Objects to compare')}
            localCurrentOptions={{ ...props.widgetConfig?.options, currentModuleID }}
            changeOptions={updateWidgetOptions}
        />
    );
};

export default OnBoardNetworkGroupObjectsSelect;
