import styled from 'styled-components/macro';

export const LogoBox = styled.a`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    img {
        width: 50%;
        height: 100%;
    }
    :hover {
        img {
            transition: width 300ms ease-in-out;
            width: 55%;
        }
    }
`;
