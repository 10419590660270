import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { LocationRippleFilled } from '@fluentui/react-icons';

import { isTabletInboundWidth, theme } from 'src/theme';

import { userSettingsReducerValues } from '../../UserSettings/reducer';
import { generalReducerValues } from '../../../General.reducer';
import Select from '../Select/Select';
import { IObject, TSelectedOptions } from '../Select/interfaces';

import { IProps } from './interfaces';
import { LocationSelectWrapper } from './styles';
/**
 * Компонент универсального селетка
 */
const LocationsSelect: React.FC<IProps> = ({
    iconType = 'chevron-down',
    mode = 'multi',
    dropdownAlign = 'left',
    labelText = ' ',
    dropdownWidth,
    outsideText,
    showFilter = false,
    options,
    showClearAll,
    text,
    minSelected,
    maxSelected,
    name,
    changeOptions,
}) => {
    const { t } = useTranslation();

    const {
        locations,
        initialDataReceived,
        currentModuleID,
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);
    const { settings } = useSelector(userSettingsReducerValues);

    const isTablet = isTabletInboundWidth(width);

    const handleSelect = (args: TSelectedOptions) => {
        currentModuleID &&
            args[0]?.id &&
            changeOptions({
                newOptions: {
                    [name]: args,
                },
            });
    };

    const outsideSelected = useMemo(() => {
        return options?.filter((item) => {
            return settings?.['global']?.[name]?.map((item: any) => String(item.id)).includes(String(item.id));
        });
    }, [name, JSON.stringify(options), JSON.stringify(settings?.['global']?.[name])]);

    useEffect(() => {
        const settingsIds = settings?.['global']?.[name]?.map((item) => Number(item.id)) ?? [];
        const optionsIds = options.map((item) => Number(item.id));
        const isCurrentSettingsValid = settingsIds.length && settingsIds.every((id) => optionsIds.includes(id));
        if ((optionsIds.length && settings && !settingsIds.length) || !isCurrentSettingsValid) {
            changeOptions({
                newOptions: {
                    [name]: [options[0]],
                },
            });
        }
    }, [name, JSON.stringify(options), settings]);

    return (
        <LocationSelectWrapper>
            <LocationRippleFilled fontSize={20} color={theme.colors.accent} />
            <Select
                testId={`${currentModuleID}_locations`}
                outsideSelected={outsideSelected as TSelectedOptions}
                disabled={locations.length === 0}
                options={
                    options?.map((element) => ({
                        ...element,
                        text: t(element.text || ''),
                    })) as IObject[]
                }
                dropdownWidth={dropdownWidth}
                handleSelect={handleSelect}
                showSorting={true}
                showClearAll={showClearAll}
                maxSelected={maxSelected}
                minSelected={minSelected}
                labelText={t(labelText)}
                dropdownAlign={dropdownAlign}
                outsideText={initialDataReceived ? outsideText : '-'}
                iconType={iconType}
                showFilter={showFilter}
                mode={mode}
                maxWidth={300}
                fullWidth={isTablet}
                text={text ? t(text) : t('Selected')}
                styles={{ border: 'none', fontWeight: 700, color: theme.colors.infoDark }}
            />
        </LocationSelectWrapper>
    );
};

export default LocationsSelect;
