import {
    AvailableReportingObjectsTypes,
    ICrossVisitsWidgetReducerState,
    IZonesByGroup,
    TRawAverageIntersectionsData,
    TRawComparisonAverageIntersectionsData,
    TRawLayersData,
    TRawPlansData,
} from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IColorsByMarker } from '../../../components/Floors/interfaces';
import { ILayer } from '../../../../../General.interfaces';
import { RootState } from '../../../../../store';

const initialState: ICrossVisitsWidgetReducerState = {
    selectedReportingObjectType: AvailableReportingObjectsTypes.Floor,
    rawComparisonAverageIntersectionsData: null,
    selectedReportingObjectMarker: null,
    rawAverageIntersectionsData: null,
    contraReportingObjectsIds: null,
    groupOfZonesSelectOptions: [],
    mainReportingObjectId: null,
    selectedGroupOfZones: null,
    availableMarkers: null,
    colorsByMarker: null,
    extendedLayers: null,
    rawLayersData: null,
    rawPlansData: null,
    zonesByGroup: null,
};

const Maps_CrossVisits_Widget_Reducer = createSlice({
    name: 'Maps_CrossVisits_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение сырых данных слоев из мапсервиса
         */
        storeRawLayersData: (state, action: PayloadAction<TRawLayersData>) => {
            state.rawLayersData = action.payload;
        },

        /**
         * Сохранение сырых данных планов локаций
         */
        storeRawPlansData: (state, action: PayloadAction<TRawPlansData>) => {
            state.rawPlansData = action.payload;
        },

        /**
         * Сохранение выбранного типа отчетного объекта
         */
        storeSelectedReportingObjectType: (state, action: PayloadAction<AvailableReportingObjectsTypes>) => {
            state.selectedReportingObjectType = action.payload;
            state.selectedReportingObjectMarker = null;
            state.mainReportingObjectId = null;
            state.contraReportingObjectsIds = null;
            state.colorsByMarker = null;
        },

        /**
         * Сохранение id выбранного отченого объекта для сравнения (main_obj_id)
         */
        storeMainReportingObjectId: (state, action: PayloadAction<number | null>) => {
            state.mainReportingObjectId = action.payload;
        },

        /**
         * Сохранение расширенных слоев (с арендаторами)
         */
        storeExtendedLayers: (state, action: PayloadAction<ILayer[] | null>) => {
            state.extendedLayers = action.payload;
        },

        /**
         * Сохранение достпуных для получения метрики маркеров
         */
        storeAvailableMarkers: (state, action: PayloadAction<string[] | null>) => {
            state.availableMarkers = action.payload;
        },

        /**
         * Сохранение id всех отчтеных объектов, кроме выбранного (contra_obj_ids)
         */
        storeContraReportingObjectsIds: (state, action: PayloadAction<number[] | null>) => {
            state.contraReportingObjectsIds = action.payload;
        },

        /**
         * Сохранение объекта зон, где ключом является гурппа зон
         */
        storeZonesByGroup: (state, action: PayloadAction<IZonesByGroup | null>) => {
            state.zonesByGroup = action.payload;
        },

        /**
         * Сохранение выбранной группы зон
         */
        storeSelectedGroupOfZones: (state, action: PayloadAction<string | null>) => {
            state.selectedGroupOfZones = action.payload;
            state.mainReportingObjectId = null;
            state.rawAverageIntersectionsData = null;
            state.rawComparisonAverageIntersectionsData = null;
            state.contraReportingObjectsIds = null;
            state.selectedReportingObjectMarker = null;
            state.colorsByMarker = null;
        },

        /**
         * Сохранение выбранного маркера отчетного объекта
         */
        storeSelectedReportingObjectMarker: (state, action: PayloadAction<string | null>) => {
            state.selectedReportingObjectMarker = action.payload;
        },

        /**
         * Сохранение сырых данных среднедневынх пересечений
         */
        storeRawAverageIntersectionsData: (state, action: PayloadAction<TRawAverageIntersectionsData>) => {
            state.rawAverageIntersectionsData = action.payload;
        },
        /**
         * Сохранение сырых данных среднедневынх пересечений
         */
        storeRawComparisonAverageIntersectionsData: (
            state,
            action: PayloadAction<TRawComparisonAverageIntersectionsData>,
        ) => {
            state.rawComparisonAverageIntersectionsData = action.payload;
        },

        /**
         * Обнуление некоторых параметров при изменении версии
         */
        resetDataAfterVersionChange: (state) => {
            state.mainReportingObjectId = null;
            state.contraReportingObjectsIds = null;
            state.selectedReportingObjectMarker = null;
            state.rawAverageIntersectionsData = null;
            state.rawComparisonAverageIntersectionsData = null;
            state.colorsByMarker = null;
        },

        /**
         * Обнуление некоторых параметров при изменении версии
         */
        resetMetricsData: (state) => {
            state.rawAverageIntersectionsData = null;
            state.rawComparisonAverageIntersectionsData = null;
            state.colorsByMarker = null;
        },

        /**
         * Сохранение цветов по маркеру объекта
         */
        storeColorsByMarker: (state, action: PayloadAction<IColorsByMarker | null>) => {
            state.colorsByMarker = action.payload;
        },
    },
});

export const {
    storeRawComparisonAverageIntersectionsData,
    storeSelectedReportingObjectMarker,
    storeRawAverageIntersectionsData,
    storeSelectedReportingObjectType,
    storeContraReportingObjectsIds,
    resetDataAfterVersionChange,
    storeMainReportingObjectId,
    storeSelectedGroupOfZones,
    storeAvailableMarkers,
    storeColorsByMarker,
    storeExtendedLayers,
    storeRawLayersData,
    storeRawPlansData,
    storeZonesByGroup,
    resetMetricsData,
} = Maps_CrossVisits_Widget_Reducer.actions;

export const Maps_CrossVisits_Widget_Reducer_Values = (state: RootState) => state.Maps_CrossVisits_Widget_Reducer;

export default Maps_CrossVisits_Widget_Reducer.reducer;
