import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ position: 'left' | 'right' }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${(p) => (p.position === 'left' ? '0 10px 0 0' : '0 0 0 10px')};
    /* width: 30px; */
    height: 20px;
`;
export const IconWrapper = styled.div`
    height: 14px;
    cursor: pointer;
    display: flex;
`;
