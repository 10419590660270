import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    border: 1px solid var(--color-primary-lighter);
    border-radius: var(--radius-xl);
    padding: 4px 10px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    :hover {
        background-color: #edf1fc;
        border: 1px solid white;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    gap: 10px;
`;
