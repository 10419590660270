import { FC, RefObject, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getReportingObjects } from '../../../../../../../../../tools/getReportingObjects';
import FiltersMonitor from '../../../../../../../../../components/Filters/FiltersMonitor/FiltersMonitor';
import FiltersSelect from '../../../../../../../../../components/Filters/FiltersSelect/FiltersSelect';
import { IntersectionsWithTenantsContext } from '../../../../context';
import { IExtendedReportingObject } from '../../../../interfaces';
import { FiltersMonitorWrapper, HeaderTitle, HeaderWrapper, SelectsWrapper, WrappedItem, Wrapper } from './styles';
import { ISelectedOption } from '../../../../../../../../../components/Selects/Select/interfaces';
import { ZONES_WORD } from '../../../../../../../../../constants/constants';
import { useSendSimpleAnalytics } from '../../../../../../../../../hooks/useAnalytics';
import UniversalSelect from '../../../../../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { INTERSECTIONS_W_TENANTS_OBJECTS_TYPE } from '../../../../constants/constants';
import { IChangeOptionsArgs } from '../../../../../../../../../components/UserSettings/interfaces';

interface IProps {
    /** расширенные данные выбранного арендатора */
    extendedReportingObject: IExtendedReportingObject;
    /** Ссылка на компонент шапки */
    headerRef: RefObject<HTMLDivElement>;
}

const Header: FC<IProps> = ({ extendedReportingObject, headerRef }) => {
    const sendAnalytics = useSendSimpleAnalytics();
    const context = useContext(IntersectionsWithTenantsContext);
    const { t } = useTranslation();

    const validOptions = useMemo(() => {
        if (context && Object.keys(context.reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(context.reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [context?.reportingObjectsByType, t]);

    const optionsOfReportingObjectType = useMemo(() => {
        if (context && Object.keys(context.reportingObjectsByType)) {
            const validFields = ['tenant'];
            return getReportingObjects(context.reportingObjectsByType, t)
                .map((item) => ({
                    id: item.key,
                    text: item.section,
                }))
                ?.filter((item) => validFields.includes(item.id) || item.id.includes(ZONES_WORD));
        }
        return [];
    }, [context?.reportingObjectsByType, t]);

    const onReportingObjectTypeSelect = (args: IChangeOptionsArgs) => {
        const { newOptions } = args;

        const newOptionsValue = newOptions[INTERSECTIONS_W_TENANTS_OBJECTS_TYPE];
        if (context && Array.isArray(newOptionsValue) && newOptionsValue.length) {
            const result: ISelectedOption = newOptionsValue[0];
            context.storeSelectedReportingObjectsTypeTrans(result);
            sendAnalytics(
                `object_type_changed_to_${result.text || result.id}`,
                'reachGoal',
                `${context.moduleName}:${context.widgetName}`,
            );
        }
    };

    return (
        <Wrapper ref={headerRef}>
            <HeaderWrapper>
                <WrappedItem>
                    <HeaderTitle>{extendedReportingObject.reportingObject.name}</HeaderTitle>
                </WrappedItem>
                <WrappedItem>
                    <SelectsWrapper>
                        <UniversalSelect
                            localCurrentOptions={{
                                [INTERSECTIONS_W_TENANTS_OBJECTS_TYPE]: [context?.selectedReportingObjectsType],
                            }}
                            name={INTERSECTIONS_W_TENANTS_OBJECTS_TYPE}
                            changeOptions={onReportingObjectTypeSelect}
                            options={optionsOfReportingObjectType}
                            mode="single"
                        />
                        {context?.selectedReportingObjectsType.id === 'tenant' && (
                            <FiltersSelect label="" dropdownAlign="right" validOptions={validOptions} />
                        )}
                    </SelectsWrapper>
                </WrappedItem>
            </HeaderWrapper>
            {context?.selectedReportingObjectsType.id === 'tenant' && (
                <FiltersMonitorWrapper>
                    <FiltersMonitor />
                </FiltersMonitorWrapper>
            )}
        </Wrapper>
    );
};

export default Header;
