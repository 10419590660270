import { IDataObjectsPasswayTypeFilterState } from '../../../interfaces';

export const passwayTypeFilterState: IDataObjectsPasswayTypeFilterState = {
    sort_by_tenant_name: true,
    passway_filter_types: [
        {
            key: 'allPassways',
            text: 'All passways',
            active: true,
        },
        {
            key: 'multipasswayTenantsOnly',
            text: 'Multipassway tenants only',
            active: false,
        },
        {
            key: 'perimeterPassways',
            text: 'Perimeter passways',
            active: false,
        },
        {
            key: 'interzonalPassways',
            text: 'Interzonal passways',
            active: false,
        },
    ],
};
