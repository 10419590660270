import React, { useCallback } from 'react';
import { IProps, TOrdering } from './interfaces';
import { IconWrapper, Wrapper } from './styles';
import Icon from '../Icon/Icon';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import { useSendSimpleAnalytics } from '../../hooks/useAnalytics';

const OrderSwitcher: React.FC<IProps> = ({
    widgetName,
    mode = 'double',
    id = 'percent',
    position,
    handleOrderToggle,
    ordering = 'none',
    primaryId = null,
}) => {
    const sendAnalytics = useSendSimpleAnalytics();
    const { currentModuleID } = useSelector(generalReducerValues);

    const onClick = (dir: TOrdering) => (e: React.MouseEvent) => {
        e.stopPropagation();

        if (mode === 'double') {
            handleOrderToggle({ ordering: dir, id });
        } else {
            if (ordering === dir && id === primaryId) {
                handleOrderToggle({ ordering: 'none', id });
            } else {
                handleOrderToggle({ ordering: dir, id });
            }
        }

        widgetName && sendAnalytics(`sorting_elements_${dir}`, 'reachGoal', `${currentModuleID}:${widgetName}`);
    };

    const getColor = useCallback(
        (mode: TOrdering) => {
            if (mode === 'none' || mode !== ordering || (primaryId && primaryId !== id)) {
                return 'secondary-dark';
            } else {
                return 'primary';
            }
        },
        [id, ordering, primaryId],
    );

    return (
        <Wrapper position={position}>
            <IconWrapper onClick={onClick('up')}>
                <Icon type="caret-up" size="xxs" color={getColor('up')} />
            </IconWrapper>
            <IconWrapper onClick={onClick('down')}>
                <Icon type="caret-down" size="xxs" color={getColor('down')} />
            </IconWrapper>
        </Wrapper>
    );
};

export default OrderSwitcher;
