import { FC, memo, useState } from 'react';
import { StyledIcon, Wrapper, GrandWrapper, IconWrapper } from './styles';
import EventItem from './components/EventItem/EventItem';
import { useSelector } from 'react-redux';
import { Events_EventsMap_Widget_Reducer_Values } from '../../reducer';
import Stack from '../../../../../../../components/Stack/Stack';

interface IProps {}

/**
 * Боковое меню
 */
const Sidebar: FC<IProps> = memo(() => {
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prevState) => !prevState);
    };

    return (
        <GrandWrapper open={open}>
            <Wrapper>
                <IconWrapper>
                    <StyledIcon open={open} onClick={toggleOpen} />
                </IconWrapper>
                <Stack direction={'column'} gap={10}>
                    {eventTypes.map((type) => {
                        return <EventItem key={type.id} open={open} eventType={type} />;
                    })}
                </Stack>
            </Wrapper>
        </GrandWrapper>
    );
});

export default Sidebar;
