import axios from 'axios';
import { getHeaders } from './getHeaders';
import { IRequestLayersArgs } from './interfaces';

const requestLayers = async (args: IRequestLayersArgs) => {
    const { mlId, token, versionId, url } = args;
    const headers = getHeaders(token)

    try {
        const response = await axios({
            method: 'GET',
            headers,
            url,
        });

        return response;
    } catch (error) {
        return error;
    }
};

export default requestLayers;
