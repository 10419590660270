import { isNumber, keyBy } from 'lodash';
import { IMetric } from '../../../../../General.interfaces';
import { TDynamicsSeries } from '../../components/Dynamics/interfaces';
import { stringDate } from '../../../../../tools/Strings/stringDate';
import { IExtendedChartPoint } from '../../../../Charts/interfaces';
import datetimeFormatHelper from '../../../../../tools/Strings/datetimeFormatHelper';
import { DateTime } from 'luxon';
import { IArgs as IDataAdapterArgs } from '../../api/dataAdapter';
import { YAxisOptions } from 'highcharts';

interface IArgs extends Omit<IDataAdapterArgs, 'events' | 'eventType'> {}

const generateDefaultSeries = (args: IArgs): Array<TDynamicsSeries> => {
    const series: Array<TDynamicsSeries> = [];

    const getMetricFromStore = (metric: string): IMetric | undefined => {
        return args.allMetrics.find((item) => item.id === metric);
    };

    const getTooltipSubTitle = (metric: string): string => {
        const metricFromStore = args.allMetrics.find((item) => item.id === metric);
        return metricFromStore
            ? `${args.t(metricFromStore.text || '')}, ${args.t(metricFromStore.units || '')}`
            : metric;
    };

    const reportingObjectsById = keyBy(args.reportingObjects, 'id');

    const getObjectName = (id: number): string => {
        if (args.isNetwork) {
            return reportingObjectsById[id]?.name || id.toString();
        }
        return args.getObjNameById({
            showType: true,
            id,
        });
    };

    args.response.forEach((metricResponse, metricResponseIndex) => {
        metricResponse.forEach((metricResponseItem, metricResponseItemIndex) => {
            const metricFromStore = getMetricFromStore(metricResponseItem.context.metric);

            const seriesId = `${metricResponseItem.context.time_range[0]}__${metricResponseItem.context.time_range[1]}__${metricResponseItem.context.metric}__${metricResponseItem.context.data_objects[0].name}__${metricResponseItem.context.data_objects[0].id}__${args.chartType}__${metricResponseIndex}__${metricResponseItemIndex}`;

            const seriesSettings = {
                name: '',
                isMain: false,
            };

            switch (args.chartType) {
                case 'metrics': {
                    seriesSettings.name = args.t(metricFromStore?.text || metricResponseItem.context.metric);
                    seriesSettings.isMain = metricResponseItemIndex === 0 && metricResponseIndex === 0;
                    break;
                }
                case 'objects': {
                    seriesSettings.name = getObjectName(metricResponseItem.context.data_objects[0].id);
                    seriesSettings.isMain = metricResponseItemIndex === 0 && metricResponseIndex === 0;
                    break;
                }

                case 'periods': {
                    seriesSettings.name = stringDate(
                        {
                            dateFrom: metricResponseItem.context.time_range[0],
                            dateTo: metricResponseItem.context.time_range[1],
                        },
                        args.lang,
                    );

                    seriesSettings.isMain = metricResponseItem.context.alias === 'main';

                    break;
                }

                case 'pos_metrics': {
                    seriesSettings.name = args.t(metricResponseItem.context.alias || '');
                    break;
                }
                default:
                    break;
            }
            const data: IExtendedChartPoint[] = metricResponseItem.items.map((element) => {
                const pointSettings = {
                    name: '',
                    title: '',
                    subTitle: '',
                };

                const pointFormattedDate = datetimeFormatHelper({
                    selectedChartDetailing: { ...args.detail, id: String(args.detail.id) },
                    dateInMillis: DateTime.fromISO(element.time).toMillis(),
                    lang: args.lang,
                    t: args.t,
                });

                switch (args.chartType) {
                    case 'metrics':
                        pointSettings.name = args.t(metricFromStore?.text || '');
                        pointSettings.subTitle = '';
                        pointSettings.title = pointFormattedDate;
                        break;

                    case 'periods':
                        pointSettings.name = pointFormattedDate;
                        pointSettings.title = args.t('Periods');
                        pointSettings.subTitle = getTooltipSubTitle(metricResponseItem.context.metric);
                        break;

                    case 'objects':
                        pointSettings.name = getObjectName(metricResponseItem.context.data_objects[0].id);
                        pointSettings.title = pointFormattedDate;
                        pointSettings.subTitle = getTooltipSubTitle(metricResponseItem.context.metric);
                        break;

                    case 'pos_metrics':
                        pointSettings.name = metricResponseItem.context.alias || '';
                        pointSettings.title = pointFormattedDate;
                        pointSettings.subTitle = getTooltipSubTitle(metricResponseItem.context.metric);
                        break;

                    default:
                        break;
                }

                return {
                    ...pointSettings,
                    y: isNumber(element.value) ? Number(element.value.toFixed(2)) : element.value,
                    x: DateTime.fromISO(element.time).toMillis(),
                    showUnits: args.chartType === 'metrics',
                    precision: metricFromStore?.round_decimal_places,
                    units: metricFromStore?.units,
                };
            });

            let yAxis: number | undefined;
            let yAxisData: YAxisOptions | undefined;

            switch (true) {
                case args.chartType === 'metrics':
                    yAxis = metricResponseIndex;
                    break;
                case args.viewSettings?.showWeather:
                    yAxis = 0;
                    yAxisData = { labels: { style: { color: 'rgb(51, 51, 51)' } } };
                    break;

                default:
                    break;
            }

            const newSeries: TDynamicsSeries = {
                ...seriesSettings,
                id: seriesId,
                yAxis,
                yAxisData,
                data,
                visible: !args.hiddenSeries.includes(seriesId),
                type:
                    data.length === 1
                        ? 'column'
                        : args.chartType === 'periods' && metricResponseItem.context.alias === 'main'
                            ? 'areaspline'
                            : 'spline',
            };

            series.push(newSeries);
        });
    });
    return series;
};

export default generateDefaultSeries;
