import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IProps } from './interfaces';
import { queryClient } from '../../../../../../../../..';
import { TABLE_QUERY_KEY } from '../../DataCell/constants/constants';
import { IPeriodObj, TMetricResponse } from '../../../../../../../../../General.interfaces';
import { sumBy, sum, isNumber } from 'lodash';
import { Th } from '../../../styles';
import Stack from '../../../../../../../../Stack/Stack';
import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import getDifferenceBetweenNumbers from '../../../../../../../../../tools/getDifferenceBetweenNumbers';
import { ObjByMeticsTableViewOptionIds } from '../../../../../enums';
import { useIsFetching } from '@tanstack/react-query';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';
import { Value } from './styles';

const AverageCell: FC<IProps> = (props) => {
    const { allMetrics } = useSelector(generalReducerValues);

    const [percentOfTheMain, setPercentOfTheMain] = useState<number | null>(null);
    const [average, setAverage] = useState<number | null>(null);

    const { t } = useTranslation();

    const getQueryKey = useCallback(
        (periodType: 'main' | 'compare', period: IPeriodObj) => {
            return [
                TABLE_QUERY_KEY,
                props.moduleId,
                props.widgetId,
                periodType,
                props.metric,
                period,
                props.reportingObjects.map((item) => item.id).sort(),
            ];
        },
        [props.metric, props.moduleId, props.reportingObjects, props.widgetId],
    );

    const isMainFetching = useIsFetching({
        queryKey: getQueryKey('main', props.mainPeriod),
    });

    const isFetching = useIsFetching({
        queryKey: getQueryKey(props.periodType, props.period),
    });

    const metricFromStore = allMetrics.find((item) => item.id === props.metric);

    useEffect(() => {
        const getAverage = (periodType: 'main' | 'compare', period: IPeriodObj): null | number => {
            const data = queryClient.getQueryData(getQueryKey(periodType, period)) as undefined | TMetricResponse[];

            const total = sum(
                data?.[0].map((metricResponseItem) => sum(metricResponseItem.items.map((item) => item.value))),
            );

            const notNullItemsCount = sumBy(data?.[0], (metricResponseItem) => {
                return sumBy(metricResponseItem.items, (item) => (isNumber(item.value) ? 1 : 0));
            });

            if (notNullItemsCount) {
                return total / notNullItemsCount;
            }

            return null;
        };

        const mainAverage = getAverage('main', props.mainPeriod);

        if (props.periodType === 'main') {
            setAverage(mainAverage);
        } else {
            const average = getAverage(props.periodType, props.period);
            setAverage(average);

            if (isNumber(mainAverage) && isNumber(average) && average > 0) {
                setPercentOfTheMain(Number(getDifferenceBetweenNumbers(mainAverage, average).percentDifference));
            } else {
                setPercentOfTheMain(null);
            }
        }
    }, [
        getQueryKey,
        props.mainPeriod,
        props.metric,
        props.moduleId,
        props.period,
        props.periodType,
        props.reportingObjects,
        props.widgetId,
        isMainFetching,
        isFetching,
    ]);

    const valueToShow = useMemo(() => {
        if (props.periodType === 'main') return average;

        if (props.mode === ObjByMeticsTableViewOptionIds.ShowAbsoluteValues) {
            return average;
        }

        if (
            props.mode === ObjByMeticsTableViewOptionIds.ShowRelativeValues ||
            props.mode === ObjByMeticsTableViewOptionIds.ShowAll
        ) {
            return percentOfTheMain;
        }
        return null;
    }, [average, percentOfTheMain, props.mode, props.periodType]);

    return (
        <Th>
            <Stack alignItems={'center'} justifyContent={'flex-end'}>
                {props.periodType === 'main' && isMainFetching ? t('Loading...') : null}
                {props.periodType === 'compare' && isFetching ? t('Loading...') : null}

                {!isMainFetching && !isFetching ? (
                    <Value
                        value={
                            props.periodType === 'compare' &&
                            props.mode !== ObjByMeticsTableViewOptionIds.ShowAbsoluteValues
                                ? valueToShow
                                : undefined
                        }
                    >
                        {valueFormatter({
                            units: props.periodType === 'compare' ? '%' : metricFromStore?.units,
                            precision: metricFromStore?.round_decimal_places,
                            showUnits:
                                props.periodType === 'compare' &&
                                props.mode !== ObjByMeticsTableViewOptionIds.ShowAbsoluteValues,
                            value: valueToShow,
                        })}
                    </Value>
                ) : null}
            </Stack>
        </Th>
    );
};

export default AverageCell;
