import { Wrapper } from './styles';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { FC } from 'react';
import { IProps } from './interfaces';
import { ResponseStatus } from '../../../../tools/API/constants';
import Stack from '../../../../components/Stack/Stack';
import { isStatus } from '../tools/guards';
import Spinner from '../../../../components/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';

/**
 * Отображение статусов поверх канваса
 */
const Status: FC<IProps> = ({ status }) => {
    const { domain } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    if (isStatus(status)) {
        if (status.status === ResponseStatus.Loading) {
            return (
                <Wrapper>
                    <Spinner type={domain} label={t(status.message || 'Loading...')} labelPosition={'below'} />
                </Wrapper>
            );
        }

        if (status?.status === ResponseStatus.Error || status?.status === ResponseStatus.NoData) {
            return (
                <Wrapper>
                    <Stack alignItems="center" justifyContent="center" gap={5} direction="column">
                        <span>{t(status.status)}</span>
                        <span>{t(status.message || status.status)}</span>
                    </Stack>
                </Wrapper>
            );
        }
    }

    return null;
};

export default Status;
