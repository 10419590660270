import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EventsManagementContext } from '../../../../context';
import { ITableCellData } from '../../../../interfaces';
import { Wrapper } from './styles';
import Stack from '../../../../../../../../../components/Stack/Stack';
import Tooltip from '../../../../../../../../../components/Tooltip/Tooltip';
import { Button } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Copy24Regular, Delete24Regular } from '@fluentui/react-icons';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import IconButton from '../../../../../../../../../components/IconButton/IconButton';

interface IProps {
    /** Данные ячейки таблицы */
    data: ITableCellData;
}

/**
 * Компонент для отображения элементов управления для каждого события
 */
const EventControls: FC<IProps> = ({ data }) => {
    const context = useContext(EventsManagementContext);
    const { user } = useSelector(generalReducerValues);

    const { t } = useTranslation();

    const onDelete = () => {
        context?.addDeletedRowToChangedDataTrans({ frontId: data.frontId });
    };

    const onClone = () => {
        context?.onCloneClickTrans(data.frontId);
    };

    // const isDisabled = !user?.permissions?.includes('app/client | modules/decisions-configuration | feature/edit');
    const isDisabled = false;

    return (
        <Wrapper>
            <Stack direction="row" alignItems="center" gap={10}>
                <Tooltip content={t('Clone')}>
                    <IconButton disabled={isDisabled} size={24} onClick={onClone} icon={<Copy24Regular />} />
                </Tooltip>
                <Tooltip content={t('Delete')}>
                    <IconButton disabled={isDisabled} size={24} onClick={onDelete} icon={<Delete24Regular />} />
                </Tooltip>
            </Stack>
        </Wrapper>
    );
};

export default EventControls;
