import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DS } from '../constants/constants';
import { IDateRange } from '../General.interfaces';
import { generalReducerValues } from '../General.reducer';
import { useWidgetCurrentOptions } from './useWidgetCurrentOptions';

export const useGetReportFileName = (keyWord: string, reportName: string) => {
    const { currentModuleID, locations, selectedLocationId } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const locationName = locations.find((item) => item.id === selectedLocationId)?.name || '';
    const mainDateRange: IDateRange = localCurrentOptions?.[
        keyWord ? `mainDateRanges${DS}${keyWord}` : 'mainDateRanges'
    ]?.filter(
        (item: { id: string }) =>
            item.id === localCurrentOptions?.[keyWord ? `mainPeriod${DS}${keyWord}` : 'mainPeriod']?.id,
    )[0];
    let dates = `${mainDateRange?.period?.dateFrom} – ${mainDateRange?.period?.dateTo}`;
    if (mainDateRange?.period?.dateFrom === mainDateRange?.period?.dateTo) {
        dates = mainDateRange?.period?.dateFrom;
    }

    const result = `${locationName}_${t(reportName)}_${dates}`;

    return result;
};
