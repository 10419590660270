import { FC } from 'react';
import { IProps as ISelectsProps } from '../../interfaces';
import Stack from '../../../../../../../Stack/Stack';
import UseMetricFromSelect from '../UseMetricFromSelect/UseMetricFromSelect';
import TableViewSelect from '../TableViewSelect/TableViewSelect';
import PercentOfTotalSwitch from '../PercentOfTotalSwitch/PercentOfTotalSwitch';
import UseObjectsFromSelect from '../UseObjectsFromSelect/UseObjectsFromSelect';

const TopByMetricsNetworkObjectsSelects: FC<ISelectsProps> = (props) => {
    return (
        <Stack direction="column" styles={{ padding: '0px 12px' }} gap={5}>
            <UseMetricFromSelect {...props} />
            <UseObjectsFromSelect {...props} />
            <TableViewSelect {...props} />
            <PercentOfTotalSwitch {...props} />
        </Stack>
    );
};

export default TopByMetricsNetworkObjectsSelects;
