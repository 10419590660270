import getDifferenceBetweenNumbers from '../../../../../../../../tools/getDifferenceBetweenNumbers';
import sortValues from '../../../../../../../../tools/sortValues';
import { IExtendedReportingObject } from '../../../interfaces';
import { IDataForSort } from '../interfaces';
import { cloneDeep, isNull, isNumber } from 'lodash';

interface IArgs {
    reportingObjects: IExtendedReportingObject[];
    dataForSort: IDataForSort;
}

/**
 * Функция для сортировки арендаторов
 * @param dataForSort данные для сортировки
 * @param reportingObjects все отчетные объекты
 */
const sortReportingObjects = (args: IArgs): IExtendedReportingObject[] => {
    const { reportingObjects, dataForSort } = args;

    return cloneDeep(reportingObjects)?.sort((a, b) => {
        if (a.metrics && b.metrics) {
            const aValue: number | null = a.metrics[dataForSort.metricId]?.mainPeriod?.items[0]?.value;
            const bValue: number | null = b.metrics[dataForSort.metricId]?.mainPeriod?.items[0]?.value;

            let aCompareValue = null;
            let bCompareValue = null;

            if (!isNull(aValue) && isNumber(a.metrics?.[dataForSort.metricId]?.comparePeriod?.items[0]?.value)) {
                const difference = getDifferenceBetweenNumbers(
                    aValue,
                    a.metrics?.[dataForSort.metricId]?.comparePeriod?.items[0]?.value as number,
                );
                aCompareValue = +difference.percentDifference;
            }

            if (!isNull(bValue) && isNumber(b.metrics[dataForSort.metricId]?.comparePeriod?.items[0]?.value)) {
                const difference = getDifferenceBetweenNumbers(
                    bValue,
                    b.metrics[dataForSort.metricId]?.comparePeriod?.items[0]?.value as number,
                );
                bCompareValue = +difference.percentDifference;
            }

            if (dataForSort.periodType === 'mainPeriod') {
                return sortValues({ a: aValue, b: bValue, sortOrder: dataForSort.ordering === 'up' ? -1 : 1 });
            }

            if (dataForSort.periodType === 'comparePeriod') {
                return sortValues({
                    a: aCompareValue,
                    b: bCompareValue,
                    sortOrder: dataForSort.ordering === 'up' ? -1 : 1,
                });
            }
        }
        return 1;
    });
};

export default sortReportingObjects;
