import { IDateRange, IPeriod, TLang } from '../General.interfaces';
import { TFunction } from 'i18next';
import { DateTime, Interval } from 'luxon';
import { stringDate } from './Strings/stringDate';
import { shortStringDate } from './Strings/shortStringDate';

/**
 * Функция получения данных по периодам сравнения
 * @returns Массив с настройками для селекта
 */
export const generateCompareDateRanges = (
    mainDateRanges: IDateRange[],
    comparePeriods: IPeriod[],
    mainPeriod: IPeriod,
    t: TFunction,
    lang: TLang,
) => {
    const mPeriod = mainDateRanges?.find((item) => item.id === mainPeriod?.id) as IDateRange;

    if (!mPeriod?.id) return [];

    /** Получение предыдущего периода, используя разницу дат основоного периода */
    const getPrevious = (mainPeriod: IDateRange) => {
        let daysNumber = 1;
        if (mainPeriod?.period.dateTo !== mainPeriod?.period.dateFrom) {
            daysNumber =
                DateTime.fromISO(mainPeriod?.period.dateTo).diff(DateTime.fromISO(mainPeriod?.period.dateFrom), [
                    'days',
                ]).days + 1;
        }
        return {
            dateFrom: DateTime.fromISO(mainPeriod?.period.dateFrom).minus({ days: daysNumber }).toISODate(),
            dateTo: DateTime.fromISO(mainPeriod?.period.dateTo).minus({ days: daysNumber }).toISODate(),
        };
    };

    /** Получение предыдущего периода, используя разницу между двумя датами основого периода в неделях */
    const getSameWeekDays = (mainPeriod: IDateRange) => {
        switch (mainPeriod.id) {
            case 'today':
            case 'yesterday':
            case 'currentWeek':
            case 'previousWeek':
                return {
                    dateFrom: DateTime.fromISO(mainPeriod?.period.dateFrom).minus({ weeks: 1 }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod?.period.dateTo).minus({ weeks: 1 }).toISODate(),
                };
            case 'currentMonth':
            case 'currentYear':
            case 'selectCustom':
                const start = DateTime.fromISO(mainPeriod?.period.dateFrom);
                const end = DateTime.fromISO(mainPeriod?.period.dateTo);
                /**
                 * Вычисляем сколько недель между двумя датами
                 * Округляем в большую сторону
                 */
                let weeksToShift = Math.ceil(end.diff(start, 'weeks').toObject().weeks!);
                if (weeksToShift === 0) {
                    weeksToShift = 1;
                }

                return {
                    dateFrom: DateTime.fromISO(mainPeriod?.period.dateFrom).minus({ weeks: weeksToShift }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod?.period.dateTo).minus({ weeks: weeksToShift }).toISODate(),
                };
            case 'previousMonth':
                return {
                    dateFrom: DateTime.fromISO(mainPeriod?.period.dateFrom).minus({ months: 1 }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod?.period.dateTo).minus({ months: 1 }).endOf('month').toISODate(),
                };

            default:
                return {
                    dateFrom: DateTime.fromISO(mainPeriod?.period.dateFrom).minus({ weeks: 1 }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod?.period.dateTo).minus({ weeks: 1 }).toISODate(),
                };
        }
    };

    /** Получение предыдущего периода, используя разницу между датами основого периода в месяцах */
    const getSameMonthDays = (mainPeriod: IDateRange) => {
        const from = DateTime.fromISO(mainPeriod?.period.dateFrom);
        const to = DateTime.fromISO(mainPeriod?.period.dateTo);
        const mainDiffDays = Interval.fromDateTimes(from, to).length('days');

        let monthsNumber = 1;
        if (mainPeriod?.period.dateTo !== mainPeriod?.period.dateFrom) {
            monthsNumber =
                DateTime.fromISO(mainPeriod?.period.dateTo)
                    .startOf('month')
                    .diff(DateTime.fromISO(mainPeriod?.period.dateFrom).startOf('month'), ['months']).months + 1;
        }
        const firstDay = to.startOf('month').minus({ months: monthsNumber }).startOf('month').day;
        const lastDay = to.startOf('month').minus({ months: monthsNumber }).endOf('month').day;

        const days = lastDay - firstDay;

        let dateTo = to.startOf('month').minus({ months: monthsNumber }).set({ day: to.day });
        if (to.day > days || (to.day === to.endOf('month').day && from.day === from.startOf('month').day)) {
            dateTo = to.startOf('month').minus({ months: monthsNumber }).endOf('month');
        }

        let dateFrom = dateTo.minus({ days: mainDiffDays });
        if (to.day === to.endOf('month').day && from.day === from.startOf('month').day) {
            dateFrom = from.minus({ months: monthsNumber }).startOf('month');
        }

        return {
            dateFrom: dateFrom.toISODate(),
            // dateFrom: from.startOf('month').minus({ months: monthsNumber }).set({ day: from.day }).toISODate(),
            dateTo: dateTo.toISODate(),
        };
    };

    /** Получение предыдущего периода, ровно 52 недели назад от основоного периода  */
    const getAligned = (mainPeriod: IDateRange) => {
        return {
            dateFrom: DateTime.fromISO(mainPeriod?.period.dateFrom).minus({ weeks: 52 }).toISODate(),
            dateTo: DateTime.fromISO(mainPeriod?.period.dateTo).minus({ weeks: 52 }).toISODate(),
        };
    };

    /** Получение предыдущего периода, ровно год назаад от основного периода */
    const getYearOverYear = (mainPeriod: IDateRange) => {
        return {
            dateFrom: DateTime.fromISO(mainPeriod?.period.dateFrom).minus({ years: 1 }).toISODate(),
            dateTo: DateTime.fromISO(mainPeriod?.period.dateTo).minus({ years: 1 }).toISODate(),
        };
    };

    /** Получение предыдщего произвольного периода */
    const getCustom = (comparePeriods?: IPeriod[]) => {
        const customPeriod = comparePeriods?.find((item) => item.id === 'selectCustom');
        if (customPeriod?.period) {
            return customPeriod?.period;
        } else {
            return null;
        }
    };

    /** Получение читаемой строки из периода */
    const getStringPeriod = (period: { dateFrom: string | null; dateTo: string | null } | null) => {
        if (!period || !period.dateFrom || !period.dateTo) return '';
        return shortStringDate({ dateFrom: period.dateFrom, dateTo: period.dateTo }, lang);
    };

    let options = [
        {
            id: 'previous',
            text: `${t('The same days number before')}\n(${getStringPeriod(getPrevious(mPeriod))})`,
            period: getPrevious(mPeriod),
        },
        {
            id: 'sameWeekdays',
            text: `${t('The same weekdays before')}\n(${getStringPeriod(getSameWeekDays(mPeriod))})`,
            period: getSameWeekDays(mPeriod),
        },
        {
            id: 'sameMonthdays',
            text: `${t('The same monthdays before')}\n(${getStringPeriod(getSameMonthDays(mPeriod))})`,
            period: getSameMonthDays(mPeriod),
        },
        {
            id: 'alignedYoY',
            text: `${t('Aligned Year-Over-Year')}\n(${getStringPeriod(getAligned(mPeriod))})`,
            period: getAligned(mPeriod),
        },
        {
            id: 'yoy',
            text: `${t('Year-Over-Year')}\n(${getStringPeriod(getYearOverYear(mPeriod))})`,
            period: getYearOverYear(mPeriod),
        },
        {
            id: 'selectCustom',
            text: `${t('Select custom')}${
                getCustom(comparePeriods) ? `\n(${getStringPeriod(getCustom(comparePeriods))})` : ''
            }`,
            period: getCustom(comparePeriods),
        },
    ] as IDateRange[];

    return options;
};
