import { FC } from 'react';
import { IProps } from './interfaces';
import { MenuItem, useIsOverflowItemVisible } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useCustomFocusIndicatorStyles } from '../../../../App.styles';

/**
 * Компонент для отображения элемента внутри меню-бургера
 */
const OverflowMenuItem: FC<IProps> = ({ item, selectHandler, testId }) => {
    const isVisible = useIsOverflowItemVisible(item.id);
    const { t } = useTranslation();

    const focusIndicatorStyles = useCustomFocusIndicatorStyles();

    const onSelect = () => {
        selectHandler(item.id);
    };

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem
            data-test-id={testId ? `${testId}:${item.text}` : null}
            className={focusIndicatorStyles.focusIndicator} key={item.id} icon={item.icon} onClick={onSelect}>
            <div>{t(item.text)}</div>
        </MenuItem>
    );
};

export default OverflowMenuItem;
