import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

const initialState: IReducerState = {};

export const Performance_Dashboard_Summary_Widget_Reducer = createSlice({
    name: 'Performance_Dashboard_Summary_Widget_Reducer',
    initialState,

    reducers: {},
});

export const Performance_Dashboard_Summary_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_Dashboard_Summary_Widget_Reducer;

export default Performance_Dashboard_Summary_Widget_Reducer.reducer;
