import { IReportingObject } from '../../../../../../General.interfaces';
import { IColorsByMarker } from '../../../../components/Floors/interfaces';
import { HSL2RGB } from '../../../../../../tools/hls2rgb';
import { AvailableReportingObjectsTypes, TRawComparisonAverageIntersectionsData } from '../interfaces';
import { IAverageIntersection } from '../../../../../../tools/API/interfaces';

interface IArgs {
    reportingObjectsByType: { [reportingObjectType: string]: IReportingObject[] };
    rawComparisonAverageIntersectionsData: TRawComparisonAverageIntersectionsData;
    selectedReportingObjectType: AvailableReportingObjectsTypes;
    rawAverageIntersectionsData: IAverageIntersection[];
}

/**
 * Функция для получения цветов для метрик
 * @param rawAverageIntersectionsData данные по среднедневным пересечениям
 * @param selectedReportingObjectType тип выбранного отчетного объекта
 * @param reportingObjectsByType объект всех отчетных объектов, где ключ это тип отчетного объекта
 * @returns объект с значениями цветов, где ключ это маркер отчетного объекта
 */
const generateColorsByMarker = (args: IArgs): IColorsByMarker => {
    const {
        rawAverageIntersectionsData,
        selectedReportingObjectType,
        reportingObjectsByType,
        rawComparisonAverageIntersectionsData,
    } = args;
    const result: IColorsByMarker = rawAverageIntersectionsData.reduce((acc, intersectionData) => {
        const currentReportingObjects = reportingObjectsByType[selectedReportingObjectType];
        let compariosnIntersectionData: IAverageIntersection | null = null;
        if (Array.isArray(rawComparisonAverageIntersectionsData)) {
            compariosnIntersectionData =
                rawComparisonAverageIntersectionsData?.find(
                    (element) => element.contra_obj_id === intersectionData.contra_obj_id,
                ) || null;
        }
        const contraReportingObject = currentReportingObjects?.find(
            (element) => element.id === intersectionData.contra_obj_id,
        );

        // TODO: Проверка формул!!!
        if (contraReportingObject) {
            acc[contraReportingObject.marker] = {
                value: compariosnIntersectionData
                    ? (compariosnIntersectionData.main_percent - intersectionData.main_percent) /
                      (intersectionData.main_percent || 1)
                    : intersectionData.main_percent,
                marker: contraReportingObject.marker,
                name: contraReportingObject.name,
                id: contraReportingObject.id,
                contraValue: compariosnIntersectionData
                    ? (compariosnIntersectionData.contra_percent - intersectionData.contra_percent) /
                      (intersectionData.contra_percent || 1)
                    : intersectionData.contra_percent,
            };
        }
        return acc;
    }, {});
    const values = Object.values(result)
        ?.filter((item) => item.value !== undefined)
        .map((item) => Number(item.value));
    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);

    Object.keys(result).forEach((key) => {
        const obj = result[key];
        const value = obj.value;
        const normValue = (value - minValue) / (maxValue - minValue) || 0;

        const heatColor = HSL2RGB(normValue * 130, 200, 30, 0.6);
        result[key] = { ...obj, heatColor, normValue };
    });
    return result;
};

export default generateColorsByMarker;
