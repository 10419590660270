import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import { useMemo } from 'react';
import { ContentWrapper, DefaultText, ErrorText, TextWrapper, Wrapper } from './styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner/Spinner';

/**
 * Глобальный спиннер приложения
 */
const GlobalSpinner = ({ isScreenHeight = false }) => {
    const { globalSpinner, domain } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const text = useMemo(() => {
        if (Object.keys(globalSpinner).length) {
            return Object.values(globalSpinner).map((element) => {
                return (
                    <React.Fragment key={element.id}>
                        {element.errorText ? (
                            <ErrorText>{t(element.errorText)}</ErrorText>
                        ) : (
                            <DefaultText>{t(element.text || '')}</DefaultText>
                        )}
                    </React.Fragment>
                );
            });
        }
        return null;
    }, [globalSpinner, t]);

    return (
        // <Wrapper isScreenHeight={isScreenHeight}>
        <ContentWrapper>
            <Spinner type={domain} label={t('Loading...')} labelPosition={'below'} size={'large'} />
            <TextWrapper>{text}</TextWrapper>
        </ContentWrapper>
        // </Wrapper>
    );
};

export default GlobalSpinner;
