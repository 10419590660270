import { TFunction, TTranslationNS } from 'src/hooks/useTranslation';

import { TenantPosInfo, PointOfSale, ReportingObjectsById } from '../interfaces';

export const posDataByTenantId = (
    results: PointOfSale[],
    reportingObjectsById: ReportingObjectsById,
    t: TFunction<TTranslationNS>,
) => {
    return results.reduce((acc, rawPosData) => {
        const connector = rawPosData.connector;
        const tenantId = rawPosData.connector.tenant_id;

        const tenantName = reportingObjectsById[tenantId].object_name;

        const info = {
            factoryId: rawPosData.kkt_factory_number, // name todo retrieve from data
            registrationId: rawPosData.kkt_reg_number,
            accumulatorId: rawPosData.fn_factory_number,  // Fiscal device POS ID

            dateFrom: connector.date_from || rawPosData.date_from || '',
            dateTo: connector.date_to || rawPosData.date_to || '',

            firstDate: rawPosData?.first_bill_date || '-',
            firstTime: '-',
            lastDate: rawPosData?.last_bill_date || '-',
            lastTime: '-',
            sales: '-',
            receiptsCount: '-',

            tenantId,
            id: rawPosData.id.toString(),
            // id: `${tenantId.toString()}-${rawPosData.connector.id}`,
            tenantName,
            posName: rawPosData.name || '',

            orgName: rawPosData.connector.legal_entity || '',
            inn: rawPosData.connector.tenant_inn,
            posType: t(rawPosData.connector.connector_type || ''), // type todo retrieve from data
            isPosOnline: rawPosData.connector.is_active,
            rawPosData,
        } as TenantPosInfo;

        const prevData = acc[tenantId] || ([] as TenantPosInfo[]);

        return {
            ...acc,
            [tenantId.toString()]: [...prevData, info],
        };
    }, {} as Record<string, TenantPosInfo[]>);
};

export const aggregateField = <T = {}>(entities: T[], fieldName: keyof T) => {
    return entities.reduce((acc, entity) => [...acc, (entity?.[fieldName] || '').toString().trim()], [] as string[]);
};
