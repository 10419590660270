import Button from '../../../../../components/Button/Button';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import { IValidateFooResult } from '../../interfaces';
import { Table } from './Table';
import { ButtonWrapper, ContentWrapper, Wrapper } from './styles';

interface IProps {
    data: Omit<IValidateFooResult, 'isValid'>[];
    closeModal: () => void;
}

const ValidationModal: React.FC<IProps> = ({ data, closeModal }) => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <ContentWrapper>
                {/* <div>{`${t('There are some validation errors here!')}`}</div> */}
                <Table data={data} />
            </ContentWrapper>
            <ButtonWrapper>
                <Button appearance="primary" text={t('OK')} onClick={closeModal} />
            </ButtonWrapper>
        </Wrapper>
    );
};

export default ValidationModal;
