import { useQuery } from '@tanstack/react-query';
import { useRequestMetrics } from '../../../../../tools/API/hooks/useRequestMetrics';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../General.reducer';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../../tools/API/interfaces';
import { DS } from '../../../../../constants/constants';
import { cabinetPreferencesValues } from '../../../../CabinetPreferences/reducer';
import { IPeriodObj, TMetricResponse } from '../../../../../General.interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../tools/API/dataAccessors/metricsDataAccessor';
import numbersDefaultDataAdapter from '../dataAdapters/numbersDefaultDataAdapter';
import { TMetricsDAResponse, TWidgetConfig } from '../interfaces';
import numbersDefaultByDaysDataAdapter from '../dataAdapters/numbersDefaultByDaysDataAdapter';

interface IArgs {
    /** Конфигурация виджета */
    widgetConfig: TWidgetConfig;
    /** ID текущего модуля */
    moduleId: string;
    /** Ключ текущего dashboard */
    dashboardKey: string | null;
}

const useFetchData = (args: IArgs) => {
    const { widgetConfig, moduleId, dashboardKey } = args;
    const {
        token,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(moduleId);
    const fetchData = useRequestMetrics();

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        const mainDatePeriod = localCurrentOptions?.mainDateRanges?.find(
            (item) => item.id === localCurrentOptions?.mainPeriod?.id,
        )?.period;

        const compareDatePeriods: IPeriodObj[] = [];

        localCurrentOptions?.comparePeriods?.forEach((comparePeriod) => {
            const period = localCurrentOptions?.compareDateRanges?.find(
                (element) => element.id === comparePeriod.id,
            )?.period;

            if (period) compareDatePeriods.push(period);
        });

        if (
            localCurrentOptions?.['mainObject']?.length &&
            Object.keys(reportingObjectsById).length &&
            widgetConfig?.sources?.length &&
            widgetConfig?.options?.metrics?.length &&
            compareDatePeriods.length &&
            widgetConfig?.type &&
            widgetConfig?.id &&
            mainDatePeriod &&
            dashboardKey &&
            moduleId &&
            token
        ) {
            const widgetType = widgetConfig?.type.split(DS)?.[1];

            switch (widgetConfig.sources[0]) {
                case 'default': {
                    let dataAdapter: undefined | ((args: unknown) => TMetricsDAResponse);
                    let timeFreq: TTimeFreq = null;

                    switch (widgetType) {
                        case 'simple': {
                            dataAdapter = (response: TMetricResponse[]) => numbersDefaultDataAdapter(response);

                            break;
                        }
                        case 'weekDays': {
                            timeFreq = 'D';
                            dataAdapter = (response: TMetricResponse[]) =>
                                numbersDefaultByDaysDataAdapter(response, widgetConfig?.visual);
                            break;
                        }

                        default:
                            break;
                    }

                    const mainPeriodArgs = prepareArgs({
                        timeRange: [mainDatePeriod.dateFrom, mainDatePeriod.dateTo],
                        alias: `mainPeriod${DS}${widgetConfig.id}`,
                        objIds: localCurrentOptions?.['mainObject'],
                        metric: widgetConfig?.options?.metrics[0],
                        timeFreq,
                        token,
                    });

                    const comparePeriodArgs = prepareArgs({
                        timeRange: [compareDatePeriods[0].dateFrom, compareDatePeriods[0].dateTo],
                        alias: `comparePeriod${DS}${widgetConfig.id}`,
                        objIds: localCurrentOptions?.['mainObject'],
                        metric: widgetConfig?.options?.metrics[0],
                        timeFreq,
                        token,
                    });
                    const args: IMetricsDAArgs = {
                        requestArgs: [mainPeriodArgs, comparePeriodArgs],
                        fetchData,
                    };
                    return [
                        [
                            'default',
                            'number',
                            localCurrentOptions?.['mainObject'],
                            compareDatePeriods,
                            mainDatePeriod,
                            moduleId,
                            dashboardKey,
                            widgetConfig?.options,
                            widgetConfig?.id,
                        ],
                        () => metricsDataAccessor(args),
                        dataAdapter,
                    ];
                }

                default:
                    return [[]];
            }
        }
        return [[]];
    }, [localCurrentOptions, widgetConfig, reportingObjectsById, dashboardKey, moduleId, token, fetchData]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter) && Boolean(localCurrentOptions),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
        retry: 2,
    });
};

export default useFetchData;

interface IPrepareArgs {
    timeRange: [string, string];
    alias: string;
    metric: string;
    objIds: number[];
    token: string;
    timeFreq?: TTimeFreq;
}

const prepareArgs = (args: IPrepareArgs): IRequestMetricsArgs => {
    const { timeRange, alias, metric, objIds, token, timeFreq = null } = args;
    return {
        object_aggregation: false,
        time_range: timeRange,
        time_freq: timeFreq,
        obj_ids: objIds,
        metric,
        alias,
        token,
    };
};
