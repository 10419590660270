import { FC, RefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetAdditionalControls from '../../../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { SalesOfTenantsContext } from '../../context';
import WidgetTabList from '../WidgetTabList/WidgetTabList';

interface IProps {
    /** Ссылка на контейнер виджета */
    wrapperRef: RefObject<null>;
}

/**
 * Компонент для отображения шапки виджета
 */
const Header: FC<IProps> = ({ wrapperRef }) => {
    const context = useContext(SalesOfTenantsContext);
    const { t } = useTranslation();

    return (
        <WidgetTitleWrapper>
            <WidgetTitle>
                {t('Sales of tenants')}
                <WidgetAdditionalControls
                    pdfDownloadData={{ targetRef: wrapperRef }}
                    reloadHandlerNoDispatch={context?.reloadWidget}
                    widgetName="Sales of tenants"
                />
            </WidgetTitle>
            {!context?.isMobile && <WidgetTabList />}
        </WidgetTitleWrapper>
    );
};

export default Header;
