import { ILayer } from '../../../../../../General.interfaces';
import { IGetTransformedLayerArgs } from './interfaces';
import { recursiveTransform } from './transformCoords';
import { cloneDeep } from 'lodash';

const getTransformedLayer = (args: IGetTransformedLayerArgs): null | ILayer => {
    const { selectedLayerType, plan, layers, selectedGroupOfZones } = args;

    let [actualLayer] = layers?.filter((item) => item.layer_type === selectedLayerType && item.floor === plan.floor);
    if (!actualLayer?.data) return null;
    let zonesLayer: ILayer | null = null;

    if (selectedGroupOfZones && selectedLayerType === 'zones_layer') {
        const currentZones = actualLayer.data?.find((element) => element['group_marker'] === selectedGroupOfZones);

        if (currentZones) {
            zonesLayer = {
                ...actualLayer,
                data: currentZones['zones'],
            };
        } else {
            zonesLayer = null;
        }
    }

    if (!zonesLayer && selectedLayerType === 'zones_layer') return null;
    const transLayer = cloneDeep(selectedLayerType === 'zones_layer' ? zonesLayer : actualLayer);

    recursiveTransform(transLayer, plan.mainPlan.plan2geo);
    recursiveTransform(transLayer, plan.widestPlan.mainPlan.geo2plan);
    return transLayer;
};

export default getTransformedLayer;
