import { AdditionalFieldsIds } from '../../interfaces';
import { ITenantsReportAdditionalFieldOption } from '../widgets/TenantsReportWidget/interfaces';

export const additionalFieldsOptions = [
    { id: AdditionalFieldsIds.AddId, text: 'Show id' },
    { id: AdditionalFieldsIds.AddWeekDay, text: 'Show week day' },
    { id: AdditionalFieldsIds.AddTimeZone, text: 'Show time zone' },
    { id: AdditionalFieldsIds.ShowEmpty, text: 'Show empty rows' },
];

export const additionalCompareFieldsOptions = [
    { id: AdditionalFieldsIds.AddId, text: 'Show id' },
    { id: AdditionalFieldsIds.AddWeekDay, text: 'Show week day' },
    { id: AdditionalFieldsIds.AddTimeZone, text: 'Show time zone' },
    { id: AdditionalFieldsIds.AddPercent, text: 'Show percent' },
    { id: AdditionalFieldsIds.AddDelta, text: 'Show delta' },
    { id: AdditionalFieldsIds.ShowEmpty, text: 'Show empty rows' },
];

export const tenantsReportAdditionalFieldsOptions: ITenantsReportAdditionalFieldOption[] = [
    {
        id: AdditionalFieldsIds.AddId,
        text: 'Show id',
        structure_type: '',
        columnName: 'Id',
    },
    {
        id: AdditionalFieldsIds.AddTimeZone,
        text: 'Show time zone',
        structure_type: '',
        columnName: 'Time zone',
    },
    {
        id: AdditionalFieldsIds.AddWeekDay,
        text: 'Show week day',
        structure_type: '',
        columnName: 'Week day',
    },
    {
        id: AdditionalFieldsIds.AddPlace,
        text: 'Show place',
        structure_type: 'relations_tenant2place',
        columnName: 'Place',
    },
    {
        id: AdditionalFieldsIds.AddCategories,
        text: 'Show categories',
        structure_type: '',
        columnName: 'Category',
    },
    {
        id: AdditionalFieldsIds.AddZone,
        text: 'Show zone',
        structure_type: 'relations_tenant2zone',
        columnName: 'Zone(s)',
    },
    {
        id: AdditionalFieldsIds.AddFloor,
        text: 'Show floor',
        structure_type: 'relations_tenant2floor',
        columnName: 'Floor',
    },
    { id: AdditionalFieldsIds.ShowEmpty, text: 'Show empty rows', structure_type: '', columnName: 'Show empty' },
    // {
    //     id: 'Category',
    //     text: 'Category',
    // },
    // {
    //     id: 'Subcategory',
    //     text: 'Subcategory',
    // },
    // {
    //     id: 'Buildings',
    //     text: 'Buildings',
    // },
    // {
    //     id: 'Price segment',
    //     text: 'Price segment',
    // },
];

export const tenantsReportAdditionalFieldsForGridData = [
    {
        id: AdditionalFieldsIds.AddPlace,
        text: 'Place',
        structure_type: 'relations_tenant2place',
    },
];

export const tenantsReportAdditionalFieldsOptionsById: { [fieldId: string]: ITenantsReportAdditionalFieldOption } =
    tenantsReportAdditionalFieldsOptions.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
    }, {});
