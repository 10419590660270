import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import { LoadingElement } from './styles';
import { useEffect, useRef } from 'react';

const ModuleLoadingElement = () => {
    const { isModuleLoading } = useSelector(generalReducerValues);

    if (Boolean(isModuleLoading)) {
        return <LoadingElement data-test-id="ModuleLoadingElement" />;
    }
    return null;
};

export default ModuleLoadingElement;
