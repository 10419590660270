import Icon from '../components/Icon/Icon';

/**
 * Получение цвета и иконки стрелочки в зависимости от значениа
 */
export const getArrowAndColor = (value: number) => {
    if (value >= -3 && value <= 3) {
        return {
            color: 'var(--color-warning)',
            icon: null,
        };
    } else if (value < 3) {
        return {
            color: 'var(--color-accent)',
            icon: <Icon type="short-arrow-down" size="xxs" margin="l_xxxxs" />,
        };
    } else {
        return { color: 'var(--color-success)', icon: <Icon type="short-arrow-up" size="xxs" margin="l_xxxxs" /> };
    }
};
