import EventTooltipContent from './components/EventTooltipContent/EventTooltipContent';
import { EventBody, EventName, EventWrapper } from './styles';
import { DateTime } from 'luxon';
import { IExtendedEvent } from '../../../../../../interfaces';
import { FC, MouseEvent, useContext, useState } from 'react';
import ContextualMenu from '../../../../../../../../../../../components/ContextualMenu/ContextualMenu';
import EventContextualMenuBody from './components/EventContextualMenuBody/EventContextualMenuBody';
import { EventsMapWidgetContext } from '../../../../../../context';
import Tooltip from '../../../../../../../../../../../components/Tooltip/Tooltip';

interface IProps {
    /** Текущий день в календаре */
    day: { data: DateTime | null };
    /** Событие за этот день */
    event: IExtendedEvent;
    /** Режим работы календаря */
    yearView: boolean;
}

/**
 * Компонент для отображения события в календаре
 */
const Event: FC<IProps> = ({ day, event, yearView }) => {
    const [showEventContextualMenu, setShowEventContextualMenu] = useState(false);

    const context = useContext(EventsMapWidgetContext);

    const isStart =
        day.data?.day === DateTime.fromISO(event.date_from).day &&
        day.data?.month === DateTime.fromISO(event.date_from).month;
    const isEnd =
        day.data?.day === DateTime.fromISO(event.date_to).day &&
        day.data?.month === DateTime.fromISO(event.date_to).month;

    const toggleShowEventContextualMenu = (e: MouseEvent) => {
        e.preventDefault();
        setShowEventContextualMenu((prevState) => !prevState);
    };

    const onEventClick = () => {
        setShowEventContextualMenu(false);
        if (context) {
            context.storeSelectedEventIdTrans(event.id);
        }
    };

    const onEditClick = (id: number) => {
        setShowEventContextualMenu(false);
        if (context) {
            context.storeSelectedEventIdToEditTrans(id);
        }
    };

    const closeEventContextualMenu = () => {
        setShowEventContextualMenu(false);
    };

    return (
        <ContextualMenu
            contextualMenuBody={<EventContextualMenuBody onEditClick={onEditClick} event={event} />}
            isContextualMenuOpen={showEventContextualMenu}
            closeContextualMenu={closeEventContextualMenu}
            gapSpace={yearView ? 2 : 5}
        >
            <EventWrapper
                isStart={context?.isRtlLanguage ? isEnd : isStart}
                isEnd={context?.isRtlLanguage ? isStart : isEnd}
                onContextMenu={toggleShowEventContextualMenu}
                color={event.event_type_color}
                onClick={onEventClick}
                index={event.index}
                yearView={yearView}
                name={event.name}
                key={event.id}
            >
                <Tooltip showTooltip={!showEventContextualMenu} content={<EventTooltipContent event={event} />}>
                    <EventBody>{isStart && !yearView && <EventName>{event.name}</EventName>}</EventBody>
                </Tooltip>
            </EventWrapper>
        </ContextualMenu>
    );
};

export default Event;
