import styled from 'styled-components/macro';
import { createGlobalStyle, css, keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ isModalOpen: boolean }>`
  body {
    overflow: ${(props) => (props.isModalOpen ? 'hidden' : 'auto')};
  }`;

export const Title = styled.div`
    color: var(--color-primary-darker);
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
`;

export const CloseIconWrapper = styled.div`
    cursor: pointer;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: auto;
`;

export const CloseWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 5px;
`;

export const Modal = styled.div<{ topColor?: string }>`
    box-shadow: rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    min-width: 300px;
    min-height: 200px;
    max-width: 90vw;
    max-height: 90vh;
    padding: 20px;
    /* overflow: hidden; */
    transition: all 0.2s;
    border-top: ${({ topColor }) => `4px solid ${topColor || 'var(--color-primary-dark)'}`};
    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    @media (max-width: 575px) {
        max-height: 70vh;
    }
`;

export const Body = styled.div`
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
`;

const animation = (isModalOpen: boolean) => {
    const appearence = () => keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;
    const disappearence = () => keyframes`
     0% { opacity: 1; }
    100% { opacity: 0; }
`;

    return isModalOpen
        ? css`
              ${appearence()} 0.2s ease-in-out;
          `
        : css`
              ${disappearence()} 0.2s ease-in-out;
          `;
};

export const Overlay = styled.div<{ isModalOpen: boolean }>`
    animation: ${(p) => {
        return animation(p.isModalOpen);
    }};
    position: fixed;
    z-index: 100;
    border: none;
    left: 0 !important;
    top: 0 !important;
    width: 100vw;
    height: 100vh;
    margin: 0;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
`;
