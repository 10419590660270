import { IPeriod } from '../../../../../../General.interfaces';
import { IRequestAverageIntersectionsArgs } from '../../../../../../tools/API/interfaces';

interface IGenerateAverageIntersectionsQueryDataArgs {
    selectedCompareReportingObjectsIds: number[];
    selectedReportingObjectsIds: number[];
    mainDateRanges: IPeriod[];
    mainPeriod: IPeriod;
    token: string;
}

/**
 * Функция для получения тела для среднедневынх пересечений
 * @param selectedCompareReportingObjectsIds ID отчетных объектов для сравнения
 * @param selectedReportingObjectsIds ID отчетных объектов
 * @param mainDateRanges Временные периоды
 * @param mainPeriod Выбранный период
 * @param token Токен авторизации
 * @returns тело запроса для среднедневых пересечений
 */
const generateAverageIntersectionsQueryData = (
    args: IGenerateAverageIntersectionsQueryDataArgs,
): IRequestAverageIntersectionsArgs[] => {
    const { selectedCompareReportingObjectsIds, selectedReportingObjectsIds, mainDateRanges, mainPeriod, token } = args;
    const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod.id);
    const queryData: IRequestAverageIntersectionsArgs[] = [];

    if (mainPeriodDateRnage?.period) {
        selectedReportingObjectsIds.forEach((selectedReportingObjectId) => {
            queryData.push({
                time_range: [mainPeriodDateRnage?.period!.dateFrom, mainPeriodDateRnage?.period!.dateTo],
                contra_obj_ids: selectedCompareReportingObjectsIds,
                main_obj_id: selectedReportingObjectId,
                token,
            });
        });
    }

    return queryData;
};

export default generateAverageIntersectionsQueryData;
