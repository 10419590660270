import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxItem } from '../../styles';
import { MAIN_RATING_INDICATORS } from '../../../../constants/constants';
import { ICheckboxItem } from '../../../../interfaces';
import Checkbox from '../../../../../../../../../components/Checkbox/Checkbox';

const CheckboxGroupObjectType: React.FC<{
    defaultRatingIndicators: ICheckboxItem[];
    mainIndicator: string | undefined;
    handleCheckboxsValues: (selects: ICheckboxItem[]) => void;
}> = ({ mainIndicator = '', handleCheckboxsValues, defaultRatingIndicators = MAIN_RATING_INDICATORS }) => {
    const [ratingIndicators, setValueRatingIndicators] = useState<ICheckboxItem[]>(defaultRatingIndicators);
    const { t } = useTranslation();


    useEffect(() => {
        setValueRatingIndicators(defaultRatingIndicators);
    }, [JSON.stringify(defaultRatingIndicators)]);



    const checked = (s: { id?: string | undefined; checked: boolean }) => {
        handleCheckboxsValues(ratingIndicators.map((item) => {
            if (item.id === s.id) {
                return { ...item, checked: s.checked };
            } else return item;
        }));
        setValueRatingIndicators(
            ratingIndicators.map((item) => {
                if (item.id === s.id) {
                    return { ...item, checked: s.checked };
                } else return item;
            }),
        );
    };
    const groupObjectType = useMemo(() => {
        return ratingIndicators.map((item) => {

            return (
                <CheckboxItem key={item.id}>
                    <Checkbox
                        handleToggle={checked}
                        id={item.id}
                        label={t(item.text)}
                        disabled={mainIndicator === item.id}
                        selected={item.checked}
                    />
                </CheckboxItem>
            );
        });
    }, [ratingIndicators, mainIndicator, t]);

    return <div>{groupObjectType}</div>;
};
export default CheckboxGroupObjectType;
