import { IPeriod } from '../../../General.interfaces';
import { IRequestMetricsArgs, TTimeFreq } from '../interfaces';

interface IArgs {
    selectedReportingObjectsIds: number[];
    timeFreq: TTimeFreq | null;
    dateRanges: IPeriod[];
    selectedMetrics: string[];
    period: IPeriod;
    token: string;
    alias?: string;
}

/**
 * Функция для получения данных, необходимых для запроса метрик
 * @param selectedReportingObjectsIds Массив ID выбранных отчетных объектов
 * @param selectedMetrics Массивы выбранных метрик
 * @param dateRanges Массив временных интервалов для выбранного основоного периода
 * @param period Выбранный период
 * @param timeFreq Детализация данных
 * @param token Токен авторизации
 * @returns Массив данных, необходимых для запроса выбранных метрик
 */
const generateMetricsQueryData = (args: IArgs): IRequestMetricsArgs[] => {
    const {
        selectedReportingObjectsIds,
        dateRanges,
        period,
        selectedMetrics,
        token,
        timeFreq,
        alias = null,
    } = args;
    const queryData: IRequestMetricsArgs[] = [];

    const mainPeriodDateRnage = dateRanges?.find((element) => element.id === period.id);

    if (mainPeriodDateRnage?.period) {
        selectedMetrics.forEach((metric) => {
            queryData.push({
                time_range: [mainPeriodDateRnage?.period!.dateFrom, mainPeriodDateRnage?.period!.dateTo],
                obj_ids: selectedReportingObjectsIds,
                object_aggregation: false,
                time_freq: timeFreq,
                alias,
                metric,
                token,
            });
        });
    }

    return queryData;
};

export default generateMetricsQueryData;
