import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Preset from '../../../../../components/Preset/Preset';
import DetailSelect from '../../../../../components/Selects/DetailSelect/DetailSelect';
import MainPeriodSelect from '../../../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../../../components/Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../../../components/Selects/ObjectsSelect/ObjectsSelect';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { ISettings } from '../../../../../components/UserSettings/interfaces';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { PresetsWrapper } from '../../../../../components/Wrappers/PresetsWrapper/styles';
import { SelectsWrapper } from '../../../../../components/Wrappers/SelectsWrapper/styles';
import { DS } from '../../../../../constants/constants';

import { generalReducerValues } from '../../../../../General.reducer';
import { Reports_Advanced_SimplePaymentType_Widget_Reducer_Values } from './reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import LayoutSelect from '../../../components/LayoutSelect/LayoutSelect';
import { AdditionalFieldsIds } from '../../../interfaces';
import { additionalFieldsOptions } from '../../constants/constants';
import ComparePeriodSelect from '../../../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import { useMemo } from 'react';
import { DateTime } from 'luxon';

const DEFAULT_METRICS = ['fpc_sum_pass_count_in_wh', 'fpc_avg_daily_pass_count_in_wh', 'fpc_avg_dwell_time'];

const SimplePaymentTypeOptions = () => {
    const { currentModuleID, user, allMetrics } = useSelector(generalReducerValues);
    const { keyWord } = useSelector(Reports_Advanced_SimplePaymentType_Widget_Reducer_Values);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const additionalFieldsOptions = [
        { id: 'showPlace', text: 'Show place name' },
        { id: 'showConnectionType', text: 'Show connection type' },
        { id: 'productCategory', text: 'Show product category' },
    ];

    const validObjOptions = [t('Tenants')];

    const layoutOptions = [
        { id: 'plain', text: 'Plain report' },
        { id: 'landscape', text: 'Horizontal format' },
    ];

    return (
        <>
            <SelectsWrapper>
                <MainPeriodSelect
                    name={`mainPeriod${DS}${keyWord}`}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <ObjectsSelect
                    validOptions={validObjOptions}
                    canSelectEntireSection={true}
                    name={`selectedReportingObjectsIds${DS}${keyWord}`}
                    dataObjectFilters={['data_objects_tenant']}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <DetailSelect
                    name={`detail${DS}${keyWord}`}
                    restrictedOptions={['15MIN', 'H']}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <UniversalSelect
                    options={additionalFieldsOptions}
                    name={`additionalFields${DS}${keyWord}`}
                    labelText={t('Additional settings')}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <LayoutSelect name={`reportFormat${DS}${keyWord}`} options={layoutOptions} />
            </SelectsWrapper>
            <PresetsWrapper>
                <Preset
                    currentOptionsKeys={[
                        `mainPeriod${DS}${keyWord}`,
                        `selectedReportingObjectsIds${DS}${keyWord}`,
                        `detail${DS}${keyWord}`,
                        `additionalFields${DS}${keyWord}`,
                        `reportFormat${DS}${keyWord}`,
                    ]}
                />
            </PresetsWrapper>
        </>
    );
};

export default SimplePaymentTypeOptions;
