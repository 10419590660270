import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../../../tools/getColorFromPercent';

export const Th = styled.th<{ bgColor: string; fontSize: string; fontWeight: string; textAlign?: string }>`
    padding: 10px;
    border: 1px solid #ececec;
    min-width: 200px;
    font-size: ${(p) => p.fontSize};
    background-color: ${(p) => p.bgColor};
    font-weight: ${(p) => p.fontWeight};
    text-align: ${(p) => p.textAlign || 'center'};
    :first-child {
        width: 200px;
    }
`;

export const Thead = styled.thead`
    position: sticky;
    top: 0;
`;

export const PercentageValue = styled.div<{ value: string | null | undefined | number }>`
    color: ${(p) => getColorFromPercent(p.value)};
`;

export const SubtypeHeaderText = styled.div`
    text-align: center;
`;
