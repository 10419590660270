import { FC, useMemo } from 'react';
import { IProps } from './interfaces';
import { StyledTable, Wrapper } from './styles';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';

/**
 * Таблица для отчетного объекта по всем метрикам и периодам
 */
const Table: FC<IProps> = (props) => {
    const sortedComparePeriods = useMemo(() => {
        return orderBy(
            props.comparePeriods,
            (period) => {
                return DateTime.fromISO(period.dateFrom).toMillis();
            },
            'desc',
        );
    }, [props.comparePeriods]);

    return (
        <Wrapper>
            <StyledTable>
                <Header
                    reportingObject={props.reportingObject}
                    comparePeriods={sortedComparePeriods}
                    mainPeriod={props.mainPeriod}
                />
                <Body {...props} comparePeriods={sortedComparePeriods} />
            </StyledTable>
        </Wrapper>
    );
};

export default Table;
