import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../../../tools/getColorFromPercent';

export const Td = styled.td<{ bgColor?: string }>`
    border: 1px solid #ececec;
    padding: 10px;
    background-color: ${(p) => p.bgColor || 'inherit'};
`;

export const MetricValue = styled.div`
    font-weight: 500;
`;

export const PercentageValue = styled(MetricValue)<{ value: number | string | null | undefined }>`
    color: ${(p) => getColorFromPercent(p.value)};
`;
