export const colors = {
    primary: 'hsl(223, 50%, 60%)',
    primaryLight: 'hsl(223, 50%, 70%)',
    primaryLighter: 'hsl(223, 50%, 80%)',
    primaryDark: 'hsl(223, 50%, 50%)',
    primaryDarker: 'hsl(223, 50%, 40%)',

    secondary: 'hsl(210, 20%, 90%)',
    secondaryLight: 'hsl(210, 20%, 95%)',
    secondaryLighter: 'hsl(210, 20%, 98%)',
    secondaryDark: 'hsl(210, 20%, 80%)',
    secondaryDarker: 'hsl(210, 20%, 70%)',

    info: 'hsl(190, 60%, 70%)',
    infoLight: 'hsl(190, 60%, 80%)',
    infoLighter: 'hsl(190, 60%, 90%)',
    infoDark: 'hsl(190, 60%, 60%)',
    infoDarker: 'hsl(190, 60%, 50%)',

    accent: 'hsl(0,80%,65%)',
    accentLight: 'hsl(0,80%,75%)',
    accentLighter: 'hsl(0,80%,85%)',
    accentDark: 'hsl(0, 60%, 45%)',
    accentDarker: 'hsl(0, 60%, 35%)',

    success: 'hsl(130, 40%, 50%)',
    successLight: 'hsl(130, 40%, 60%)',
    successLighter: 'hsl(130, 40%, 70%)',
    successDark: 'hsl(130, 40%, 40%)',
    successDarker: 'hsl(130, 40%, 30%)',

    warning: 'hsl(40, 95%, 60%)',
    warningLight: 'hsl(40, 95%, 70%)',
    warningLighter: 'hsl(40, 95%, 80%)',
    warningDark: 'hsl(40, 95%, 50%)',
    warningDarker: 'hsl(40, 95%, 40%)',

    blue: 'hsl(223, 100%, 65%)',
    blueLight: 'hsl(223, 100%, 75%)',
    blueLighter: 'hsl(223, 100%, 85%)',
    blueDark: 'hsl(223, 100%, 55%)',
    blueDarker: 'hsl(223, 100%, 45%)',

    text: 'hsl(288, 77%, 11%)',
    textLight: 'hsl(196, 60%, 20%)',
    textLighter: 'hsl(196, 45%, 29%)',

    white: '#FFFFFF',
} as const;

export const extendedColors = {
    cerulean: '#36CEFB',
    amethyst: '#9859FF',
    cadetBlue: '#4C9EA6',
    goldenYellow: '#EDCD23',
    purple: '#B30EAC',
    lightPeriwinkle: '#AE9CF1',
    steelBlue: '#5587C1',
    tangerine: '#DC630C',
} as const;

const allColors = {
    ...colors,
    ...extendedColors,
} as const;

const SPACE_UNIT = 1;

export const commonTheme = {
    colors: allColors,

    seriesColors: [
        // 0
        colors.primaryDark,
        colors.warning,
        extendedColors.cerulean,
        extendedColors.amethyst,
        extendedColors.cadetBlue,
        // 5
        extendedColors.goldenYellow,
        extendedColors.purple,
        extendedColors.lightPeriwinkle,
        extendedColors.steelBlue,
        extendedColors.tangerine,
        // 10
    ] as const,

    fontSizes: {
        xs: `${1.0 * SPACE_UNIT}rem`,
        sm: `${1.25 * SPACE_UNIT}rem`,
        md: `${1.75 * SPACE_UNIT}rem`,
    } as const,
    zIndexes: {} as const,
    opacity: {
        disabled: 0.45,
    } as const,

    spaces: {
        xxxxs: `${0.25 * SPACE_UNIT}rem`,
        xxxxm: `${0.35 * SPACE_UNIT}rem`,
        xxxs: `${0.5 * SPACE_UNIT}rem`,
        xxs: `${0.75 * SPACE_UNIT}rem`,
        xs: `${1.0 * SPACE_UNIT}rem`,
        sm: `${1.25 * SPACE_UNIT}rem`,
        md: `${1.75 * SPACE_UNIT}rem`,
        lg: `${2.25 * SPACE_UNIT}rem`,
        xl: `${2.75 * SPACE_UNIT}rem`,
        xxl: `${3.75 * SPACE_UNIT}rem`,
        xxxl: `${5.0 * SPACE_UNIT}rem`,
        xxxxl: `${7.0 * SPACE_UNIT}rem`,

        sideBarWidth: '250px',
        headerHeight: '50px',
    } as const,
} as const;
