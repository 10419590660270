import { intersection, keyBy } from 'lodash';
import { IReportingObject } from '../../../../../../../General.interfaces';
import { IDataAdapterResponse, IProps, IReportingObjectWithEvents } from '../interfaces';
import { DateTime, Interval } from 'luxon';

interface IArgs extends IProps {
    reportingObjects: IReportingObject[];
}

const dataAdapter = (args: IArgs): IDataAdapterResponse => {
    var reportingObjectsById = keyBy(args.reportingObjects, 'id');

    var data: { [reportingObjectId: string]: IReportingObjectWithEvents } = {};

    var isIntersect = (dateFrom: string, dateTo: string) => {
        var periodInterval = Interval.fromDateTimes(
            DateTime.fromISO(args.period.dateFrom),
            DateTime.fromISO(args.period.dateTo),
        );
        var eventInterval = Interval.fromDateTimes(DateTime.fromISO(dateFrom), DateTime.fromISO(dateTo));
        return Boolean(eventInterval.intersection(periodInterval));
    };

    args.events
        .filter((event) => isIntersect(event.date_from, event.date_to))
        .forEach((event) => {
            var intersections = intersection(
                Object.keys(reportingObjectsById),
                event.data_objects_ids.map((id) => String(id)),
            );

            intersections.forEach((id) => {
                if (!data.hasOwnProperty(id)) {
                    data[id] = {
                        reportingObject: reportingObjectsById[id],
                        events: [event],
                    };
                } else {
                    data[id].events.push(event);
                }
            });
        });

    return {
        data: Object.values(data),
    };
};

export default dataAdapter;
