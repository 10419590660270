import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

import { IPlan, IVersion } from '../../../../../General.interfaces';

const initialState: IReducerState = {
    plans: [],
    versions: [],
};

export const Maps_MapsOverview_Widget_Reducer = createSlice({
    name: 'Maps_MapsOverview_Widget_Reducer',
    initialState,

    reducers: {
        storePlans: (state, action: PayloadAction<IPlan[]>) => {
            state.plans = action.payload;
        },
        storeVersions: (state, action: PayloadAction<IVersion[]>) => {
            state.versions = action.payload;
        },
    },
});

export const { storePlans, storeVersions } = Maps_MapsOverview_Widget_Reducer.actions;

export const Maps_MapsOverview_Widget_Reducer_Values = (state: RootState) => state.Maps_MapsOverview_Widget_Reducer;

export default Maps_MapsOverview_Widget_Reducer.reducer;
