import { useId, Input, Label, InputOnChangeData } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { IProps } from './interfaces';
import { useState, FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, Wrapper, useStyles } from './styles';
import Button from 'src/components/Button/Button';
import { generalReducerValues } from 'src/General.reducer';
import { useAppDispatch } from 'src/hooks/useSettings';
import generateId from 'src/tools/generateId';
import { Dashboards_Reducer_Values, postUserDashboards } from 'src/Dashboards.reducer';

const DuplicateModalBody: FC<IProps> = ({ closeModal }) => {
    const underlineId = useId('input-underline');
    const styles = useStyles();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { currentModuleID } = useSelector(generalReducerValues);
    const { userDashboards, selectedKey } = useSelector(Dashboards_Reducer_Values);
    const currentDashboard = userDashboards.find((item) => item.dashboard_key === selectedKey[currentModuleID]);
    const [dashboardName, setDashboardName] = useState(`${t(currentDashboard?.dashboard_name ?? '')} ${t('copy')}`);

    const onButtonClick = () => {
        if (!currentDashboard) return;
        const { created_at, updated_at, id, ...rest } = currentDashboard;
        dispatch(
            postUserDashboards({
                dashboards: [
                    {
                        ...rest,
                        dashboard_name: dashboardName,
                        dashboard_key: generateId(16),
                        version: 0,
                    },
                ],
            }),
        );
        closeModal();
    };

    const handleNameInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
        setDashboardName(() => data.value);

    return (
        <Wrapper>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId}>
                    {t('Name')}
                </Label>
                <Input
                    onChange={handleNameInput}
                    value={dashboardName}
                    placeholder={t('Name')}
                    appearance="underline"
                    id={underlineId}
                />
            </div>

            <ButtonWrapper>
                <Button text={t('Duplicate')} appearance={'primary'} onClick={onButtonClick} />
            </ButtonWrapper>
        </Wrapper>
    );
};

export default DuplicateModalBody;
