import { AxiosError, AxiosResponse } from 'axios';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useMemo } from 'react';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../../../../tools/API/appBackendAPI';
import {
    storeEventTypes,
    storeRawEventsData,
    Events_EventsMap_Widget_Reducer_Values,
    storeNewEvents,
    storeEventsToUpdate,
    storeExtendedEventsDataById,
    storeEventIdToDelete,
} from '../reducer';
import usePrepareData from './usePrepareData';
import { useSendSimpleAnalytics } from '../../../../../../hooks/useAnalytics';

export const useRequestEvents = () => {
    const sendAnalytics = useSendSimpleAnalytics();
    usePrepareData();
    const dispatch = useDispatch();
    const { token, selectedLocationId, urlsByServices, initialDataReceived } = useSelector(generalReducerValues);
    const { newEvents, eventsToUpdate, eventIdToDelete, dataRefetchObject } = useSelector(
        Events_EventsMap_Widget_Reducer_Values,
    );

    const [locationEventsUrl, locationEventTypesUrl] = useMemo(() => {
        if (urlsByServices?.['app/app-backend']) {
            return [
                urlsByServices['app/app-backend'].LOCATION_EVENTS_URL,
                urlsByServices['app/app-backend'].LOCATION_EVENT_TYPES_URL,
            ];
        }
        return [];
    }, [urlsByServices]);

    /** Добавление новых событий */
    useEffect(() => {
        if (newEvents.length && locationEventsUrl && token && selectedLocationId) {
            const { data_objects_ids, date_from, date_to, timezone, name, event_type, comment } = newEvents[0];
            const data = { data_objects_ids, date_from, date_to, timezone, name, event_type, comment };
            postRequest(locationEventsUrl, token, data).then(() => {
                sendAnalytics(`add_new_event`);
                getRequest(`${locationEventsUrl}?event_type__pl_id=${selectedLocationId}`, token).then(
                    (response: AxiosError | AxiosResponse) => {
                        dispatch(storeRawEventsData(response['data']));
                    },
                );
            });
            dispatch(storeNewEvents([]));
        }
    }, [newEvents, locationEventsUrl, token, dispatch, selectedLocationId]);

    /** Изменение событий */
    useEffect(() => {
        if (eventsToUpdate.length && locationEventsUrl && token && selectedLocationId) {
            const {
                data_objects_ids,
                date_from,
                date_to,
                timezone,
                name,
                event_type,
                comment = '',
                id,
            } = eventsToUpdate[0];
            const data = { data_objects_ids, date_from, date_to, timezone, name, event_type, comment, id };
            patchRequest({ url: `${locationEventsUrl}${data.id}/`, token, data }).then(() => {
                getRequest(`${locationEventsUrl}?event_type__pl_id=${selectedLocationId}`, token).then(
                    (response: AxiosError | AxiosResponse) => {
                        dispatch(storeRawEventsData(response['data']));
                    },
                );
            });
            dispatch(storeEventsToUpdate([]));
        }
    }, [dispatch, eventsToUpdate, locationEventsUrl, token, selectedLocationId]);

    /** Удаление событий */
    useEffect(() => {
        if (eventIdToDelete && locationEventsUrl && token && selectedLocationId) {
            deleteRequest(locationEventsUrl, token, eventIdToDelete).then(() => {
                getRequest(`${locationEventsUrl}?event_type__pl_id=${selectedLocationId}`, token).then(
                    (response: AxiosError | AxiosResponse) => {
                        dispatch(storeRawEventsData(response['data']));
                    },
                );
            });
            dispatch(storeEventIdToDelete(null));
            dispatch(storeEventsToUpdate([]));
        }
    }, [dispatch, eventIdToDelete, locationEventsUrl, token, selectedLocationId]);

    /** При загрузке приложения, запрос на типы событий для данной локации и потом, запрос на сами сохраненные события */
    useEffect(() => {
        dispatch(storeExtendedEventsDataById({}));
        dispatch(storeRawEventsData([]));

        if (token && selectedLocationId && initialDataReceived && locationEventTypesUrl && locationEventsUrl) {
            const url = `${locationEventTypesUrl}?pl_id=${selectedLocationId}`;
            dispatch(storeEventTypes([]));
            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                if (response?.['data']?.length) {
                    dispatch(storeEventTypes(response['data']));
                    const url = `${locationEventsUrl}?event_type__pl_id=${selectedLocationId}`;
                    getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                        dispatch(storeRawEventsData(response['data']));
                    });
                } else {
                    dispatch(storeRawEventsData([]));
                }
            });
        }
    }, [
        locationEventTypesUrl,
        selectedLocationId,
        initialDataReceived,
        dataRefetchObject,
        locationEventsUrl,
        token,
        dispatch,
    ]);
};
