import React, { useId, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Divider } from '@fluentui/react-components';
import _ from 'lodash';

import { isMobileInboundWidth } from 'src/theme';

import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../reducer';
import Dynamics from '../../../../../components/Charts/Dynamics/Dynamics';
import { withLoading } from '../../../../../tools/API/withLoading';
import { TAB_LIST_ITEMS } from '../../constants/constants';
import DetailSelect from '../../../../../components/Selects/DetailSelect/DetailSelect';
import getChartDataDetailing from '../../../../../constants/chartDetailing';
import { detailOptions } from '../../../../../constants/detailOptions';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import { generalReducerValues } from '../../../../../General.reducer';
import { TTimeFreq } from '../../../../../tools/API/interfaces';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { TabListItemIds } from '../../enums';
import getResponseStatus from '../../../../../tools/API/helpers/getResponseStatus';

import { Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values, storeSelectedTab } from './reducer';
import useFetchData from './api/useFetchData';
import { ChartWrapper, DynamicsChartTitle, LoaderWrapper, SelectsWrapper } from './styles';

const StructureDynamics: React.FC = () => {
    const ref = useRef(null);
    const {
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const { selectedTab } = useSelector(Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values);

    const { data, error, isFetching, refetch } = useFetchData();

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const dynamicChartId = useId();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const tabListSelectHandler = (id: TabListItemIds) => {
        dispatch(storeSelectedTab(id));
    };

    const isMobile = isMobileInboundWidth(width);

    const WithLoading = useMemo(() => {
        const Component = withLoading(LoaderWrapper, {
            data: getResponseStatus({
                isDataExists: data ? data?.isDataExists : false,
                isFetching: isFetching,
                error: error,
                data: data,
            }),
            height: 400,
        });

        return Component;
    }, [JSON.stringify(data), error, isFetching]);

    const detailSelect = useMemo(() => {
        let restrictedOptions: TTimeFreq[] = [];
        if (localCurrentOptions?.mainDateRanges) {
            const validDetailing = getChartDataDetailing(
                t,
                localCurrentOptions?.mainDateRanges,
                localCurrentOptions?.mainPeriod,
            ).map((item) => {
                return item.id;
            });
            const detailOptionsId = detailOptions.map((item) => {
                return item.id;
            });
            restrictedOptions = _.difference(detailOptionsId, validDetailing);
        }

        return (
            <DetailSelect
                restrictedOptions={[...restrictedOptions, '15MIN']}
                localCurrentOptions={localCurrentOptions}
                changeOptions={(args) => dispatch(changeOptions(args))}
            />
        );
    }, [localCurrentOptions?.mainPeriod]);

    const tabList = useMemo(() => {
        return (
            <TabList
                selectedValue={selectedTab}
                selectHandler={tabListSelectHandler}
                widgetName={'StructureAndDynamics'}
                options={TAB_LIST_ITEMS}
            />
        );
    }, [selectedTab, t]);

    const onReload = () => {
        refetch();
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Structure and dynamics')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName={'Structure and dynamics'}
                        reloadHandlerNoDispatch={onReload}
                    />
                </WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            <SelectsWrapper>{detailSelect}</SelectsWrapper>
            <WithLoading>
                <ChartWrapper>
                    {data?.structureChartOptions ? <Dynamics {...data.structureChartOptions} /> : null}
                </ChartWrapper>
                <Divider style={{ marginBottom: 'var(--space-xs)' }} />
                <DynamicsChartTitle>{t('Deviation in % to the comparison period')}</DynamicsChartTitle>
                <ChartWrapper>
                    {data?.dynamicsChartOptions ? (
                        <Dynamics chartId={dynamicChartId} {...data.dynamicsChartOptions} />
                    ) : null}
                </ChartWrapper>
            </WithLoading>
        </WidgetWrapper>
    );
};

export default StructureDynamics;
