import { IUseStageHandlersArgs, IUseStageHandlersResponse } from '../tools/interfaces';
import { handleWheelScale } from '../tools/handleWheelScale';
import { KonvaEventObject } from 'konva/lib/Node';
import fitStage from '../tools/fitStage';

/**
 * Кастомный хук для получения обработчиков различных действий для Stage
 * @param changeHoveredMarker Функция для маркера, на который навелись
 * @param changeStageScale Функция для изменения масштаба
 * @param refsOfStages Ссылки на холсты
 * @param widestPlan Самый широкий план
 * @param stageWidth Ширина Stage
 * @returns Обработчики для Stage
 */
const useStageHandlers = (args: IUseStageHandlersArgs): IUseStageHandlersResponse => {
    const { changeStageScale, changeHoveredMarker, widestPlan, stageWidth, refsOfStages } = args;

    /** Обработчик двойного клика по Stage */
    const stageDoubleClickHandler = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        if (widestPlan) {
            const stageScale = fitStage(widestPlan, stageWidth);
            changeStageScale({ stageScale: isNaN(stageScale) ? 1 : stageScale, stageX: 0, stageY: 0 });
        }
    };

    /** Обработчик клика по Stage */
    const stageClickHandler = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();

    };

    /** Обработчик колесика мыши */
    const stageWheelHandler = (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        changeStageScale(handleWheelScale(e));
    };

    /** Обработчик события  onMouseLeave*/
    const onMouseLeave = (value: null) => {
        changeHoveredMarker(value);
    };

    /** Обработчик события  onMouseOver*/
    const onMouseOver = (value: string) => {
        changeHoveredMarker(value);
    };

    /** Функция для изменения курсора на холсте */
    const changeStageCursor = (stageIndex: number, cursor: string) => () => {
        const container = refsOfStages.current![stageIndex]?.container();
        if (container) {
            container.style.cursor = cursor;
        }
    };

    return {
        stageDoubleClickHandler,
        stageClickHandler,
        changeStageCursor,
        stageWheelHandler,
        onMouseLeave,
        onMouseOver,
    };
};

export default useStageHandlers;
