import { cloneDeep } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDataObjectsFloorFilterState } from '../../../../interfaces';
import { IGetComponentProps } from '../../interfaces';
import { CheckboxWrapper, CollapseWrapper } from './styles';
import Checkbox from '../../../../../../components/Checkbox/Checkbox';
import Collapse from '../../../../../../components/Collapse/Collapse';
import Stack from '../../../../../../components/Stack/Stack';

/**
 * Компонент для отображения фильтра этажей
 */
const Filter: FC<IGetComponentProps> = ({ filterData, onFiltersChange }) => {
    const [filterState, setFilterState] = useState(
        cloneDeep((filterData.filter_state as IDataObjectsFloorFilterState).floors || []),
    );
    const [shouldUpdateFiltersState, setShouldUpdateFiltersState] = useState(false);

    const [isFilterActive, setIsFilterActive] = useState(filterData.active);
    const [shouldUpdateFilterActive, setShouldUpdateFilterActive] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (shouldUpdateFiltersState) {
            setShouldUpdateFiltersState(false);
            onFiltersChange({
                filterType: filterData.filter_type,
                key: 'filter_state',
                value: { ...filterData.filter_state, floors: filterState },
            });
        }
    }, [filterState]);

    useEffect(() => {
        if (shouldUpdateFilterActive) {
            setShouldUpdateFilterActive(false);
            onFiltersChange({ filterType: filterData.filter_type, key: 'active', value: isFilterActive });
        }
    }, [isFilterActive]);

    const toggleFilterActive = (data: { checked: boolean }) => {
        setIsFilterActive(data.checked);
        setShouldUpdateFilterActive(true);
    };

    const handleFilterStateChange = (data: { id?: string; checked: boolean }) => {
        setShouldUpdateFiltersState(true);

        setFilterState((prevState) => {
            const copy = cloneDeep(prevState);
            const index = copy.findIndex((element) => element.name === data.id);
            if (index > -1) copy[index].active = data.checked;
            return copy;
        });
    };

    return (
        <CollapseWrapper>
            <Collapse
                isCollapsed={!isFilterActive}
                additionalControl={
                    <CheckboxWrapper>
                        <Checkbox id={'Filter by floors'} selected={isFilterActive} handleToggle={toggleFilterActive} />
                    </CheckboxWrapper>
                }
                additionalControlPosition="before"
                label={t('Filter by floors')}
                iconJustify="space-between"
                hover="background-none"
                icon="chevron-right"
                iconPosition="right"
            >
                <Stack padding="5px 0 0 10px" direction={'column'} gap={10}>
                    {filterState.map((element) => (
                        <Checkbox
                            label={`${t('Floor')}: ${element.name}`}
                            handleToggle={handleFilterStateChange}
                            selected={element.active}
                            disabled={!isFilterActive}
                            key={element.name}
                            id={element.name}
                        />
                    ))}
                </Stack>
            </Collapse>
        </CollapseWrapper>
    );
};

export default Filter;
