import styled from 'styled-components';

export const GrandWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const Wrapper = styled.div`
    background: #fff;
    border: 1px solid rgba(108, 117, 125, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    display: inline-block;
`;

export const Item = styled.div<{ selected: Boolean; hideRightBorder?: boolean }>`
    border-right: 1px solid rgba(108, 117, 125, 0.2);
    color: ${(p) => (p.selected ? '#000' : 'rgba(0,0,0,.5)')};
    cursor: pointer;
    display: inline-block;
    padding: 4px 16px;

    background-color: ${(p) => (p.selected ? 'rgba(76,126,255,.2)' : '#fff')};

    :hover {
        background-color: rgba(76, 126, 255, 0.1);
    }

    :first-child {
        border-right: ${(p) => (!p.hideRightBorder ? (p.theme.isRtlLanguage ? '0px' : 'inherit') : '0px')};
    }
    :last-child {
        border-right: ${(p) => (!p.hideRightBorder ? (p.theme.isRtlLanguage ? 'inherit' : '0px') : '0px')};
    }
`;
