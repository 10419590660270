import styled from 'styled-components/macro';
import { Delete16Regular } from '@fluentui/react-icons';

export const Wrapper = styled.div`
    /* margin-right: var(--space-sm); */
`;

export const StIcon = styled(Delete16Regular)`
    color: var(--color-secondary-darker);
    ${(p) => (p.theme.direction === 'rtl' ? 'margin-right: 5px;' : 'margin-left: 5px;')}
    :hover {
        color: var(--color-primary);
    }
`;
