import { useEffect, useState } from 'react';

import { AxisLabelsFormatterContextObject } from 'highcharts';
import alignSeriesByWeekday from '../tools/alignSeriesByWeekday';
import { Cases_Benchmarks_Traffic_Widgwet_Reducer_Values } from '../reducer';
import { cloneDeep } from 'lodash';
import datetimeFormatHelper from '../tools/datetimeFormatHelper';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Кастомный хук для получения настроек диаграммы
 * @returns объект настроек для диаграммы
 */
const useChartOptions = () => {
    const { chartOptionsGeneratorSettings, selectedChartDetailing, highlightWeekend } = useSelector(
        Cases_Benchmarks_Traffic_Widgwet_Reducer_Values,
    );
    const [options, setOptions] = useState<Highcharts.Options | null>(null);
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return datetimeFormatHelper({
            selectedChartDetailing: selectedChartDetailing,
            dateInMillis: this.value as number,
            isCategory: true,
            lang,
            t,
        });
    };

    const tooltipTitleFormatter = (value: string | number) => {
        return datetimeFormatHelper({
            selectedChartDetailing: selectedChartDetailing,
            dateInMillis: value as number,
            isCategory: false,
            lang,
            t,
        });
    };

    /**
     * В зависимости от языка, подсветки выходных и самих настроек, составляет объект настроек для диграммы
     */
    useEffect(() => {
        if (chartOptionsGeneratorSettings) {
            let series = cloneDeep(chartOptionsGeneratorSettings.series);

            if (highlightWeekend) series = alignSeriesByWeekday(series);

            // series = changeTooltipBodyPointsName({
            //     selectedChartDetailing: { id: 'W-MON', text: '' },
            //     series,
            //     lang,
            //     t,
            // });

            // setOptions(
            //     generateOptions({
            //         ...chartOptionsGeneratorSettings,
            //         tickInterval: chartTickIntervalsByDetail['W-MON'],
            //         tooltipTitleFormatter,
            //         xAxisLabelsFormating,
            //         highlightWeekend: false,
            //         useUTC: false,
            //         series,
            //     }),
            // );
        }
    }, [chartOptionsGeneratorSettings, lang, highlightWeekend, t]);

    return options;
};

export default useChartOptions;
