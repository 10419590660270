import { makeStyles, shorthands } from '@fluentui/react-components';
import styled from 'styled-components/macro';

export const useAlertStyles = makeStyles({
    messageBar: {
        minWidth: '300px',
        maxWidth: '500px',
        ...shorthands.borderRadius('10px'),
    },
});

export const AlertText = styled.div`
    word-wrap: break-word;
    white-space: pre-line;
    padding: 10px 0;
`;

export const ActionsBar = styled.div`
    display: flex;
    justify-content: flex-end;
    /* padding-bottom: var(--space-xxs); */
    width: 100%;
`;
