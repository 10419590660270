import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../General.reducer';

/**
 * Кастомный хук обновления приложения при краше (срабатывает при изменении выбранной локации или смене модуля)
 */
export const useErrorReset = () => {
    const [reset, setReset] = useState(0);
    const { selectedLocationId, currentModuleID } = useSelector(generalReducerValues);

    useEffect(() => {
        setReset(() => reset + 1);
    }, [selectedLocationId, currentModuleID]);

    return reset;
};
