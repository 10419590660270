import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Body, Footer } from './styles';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import Title from '../../../../../components/Title/Title';
import { generalReducerValues } from '../../../../../General.reducer';
import { Reports_Bartel_Widget_Reducer_Values } from './reducer';
import DownloadXLSXFileButton from '../../../components/DownloadXLSXFileButton/DownloadXLSXFileButton';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { useGetReportFileName } from '../../../../../hooks/useGetReportFileName';

const DEFAULT_METRICS = ['fpc_sum_pass_count_in_wh', 'fpc_avg_daily_pass_count_in_wh', 'fpc_avg_dwell_time'];

const BartelWidget: React.FC = () => {
    const { t } = useTranslation();
    const { currentModuleID } = useSelector(generalReducerValues);
    const { keyWord } = useSelector(Reports_Bartel_Widget_Reducer_Values);
    const reportFileName = useGetReportFileName(keyWord, "Barthel's report");

    return (
        <WidgetWrapper styles={{ marginTop: 10 }}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title>{t("Barthel's report")}</Title>
                </WidgetTitle>
            </WidgetTitleWrapper>
            <Body>
                <p>
                    {t(`In this section, unique reports from our partners, colleagues, and clients will be presented.`)}
                </p>
                <p>
                    {t(
                        'The report by Alexandra Barthel is intended to evaluate the effectiveness of the Malls performance in attracting visitors and to identify trends in attendance changes based on the day of the week, as well as across different floors and entrance groups of the Malls. This report can assist managers and marketing specialists in making decisions regarding marketing strategies and visitor flow management.',
                    )}
                </p>
                <p>{t(`The report includes the following key metrics:`)}</p>
                <ul>
                    <li>{`● ${t('Footfall Entrance')}`}</li>
                    <li>{`● ${t('Avg. Daily Visits')}`}</li>
                    <li>{`● ${t('Avg. Visit Duration (PC)')}`}</li>
                    <li>{`● ${t('Temperature (optional)')}`}</li>
                </ul>
            </Body>
            <Footer>
                <DownloadXLSXFileButton
                    currentModuleID={currentModuleID}
                    keyWord={keyWord}
                    reportFileName={reportFileName}
                />
            </Footer>
        </WidgetWrapper>
    );
};

export default BartelWidget;
