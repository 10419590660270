import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    color: #a94442;
    background-color: #f2dede;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    span {
        text-decoration: underline;
        cursor: pointer;
        margin: 0 5px;
    }
`;
