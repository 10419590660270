import { useId, Input, Label, InputOnChangeData, Textarea } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { IProps } from './interfaces';
import { useState, useEffect, FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, Wrapper, useStyles } from './styles';
import Button from 'src/components/Button/Button';
import { addNewGlobalSpinnerItem, deleteGlobalSpinnerItemById, generalReducerValues } from 'src/General.reducer';
import { useAppDispatch } from 'src/hooks/useSettings';
import { postRequest } from 'src/tools/API/appBackendAPI';
import { storeNewAlert } from 'src/components/Alert/reducer';
import generateId from 'src/tools/generateId';
import { IDashboard } from 'src/components/DashboardsCommon/interfaces';
import { Dashboards_Reducer_Values } from 'src/Dashboards.reducer';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';

const MakeSharedModalBody: FC<IProps> = ({ closeModal }) => {
    const [value, copy] = useCopyToClipboard();
    const underlineId = useId('input-underline');
    const descriptionId = useId('input-description');
    const styles = useStyles();

    const [description, setDescription] = useState('');
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { currentModuleID, token, user, urlsByServices, initialDataReceived } = useSelector(generalReducerValues);
    const { userDashboards, selectedKey } = useSelector(Dashboards_Reducer_Values);
    const currentDashboard = userDashboards.find((item) => item.dashboard_key === selectedKey[currentModuleID]);
    const [dashboardName, setDashboardName] = useState(currentDashboard?.dashboard_name ?? '');

    const onButtonClick = () => {
        postSharedDashboard();
        closeModal();
    };

    const handleNameInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
        setDashboardName(() => data.value);

    const handleDescriptionInput = (ev: ChangeEvent<HTMLTextAreaElement>, data: InputOnChangeData) =>
        setDescription(() => data.value);

    const postSharedDashboard = async () => {
        const url = urlsByServices?.['app/app-backend']?.SHARED_DASHBOARDS_URL;

        if (!token || !url || !currentDashboard) return;

        const dashboard_name = dashboardName ? dashboardName : currentDashboard.dashboard_name;

        const data: IDashboard = { ...currentDashboard, dashboard_key: generateId(16), dashboard_name };
        const { id, ...rest } = data;
        const spinnerId = generateId();
        dispatch(addNewGlobalSpinnerItem({ id: spinnerId, text: 'Saving shared panel...' }));
        try {
            const res = await postRequest(url, token, rest);
            dispatch(deleteGlobalSpinnerItemById(spinnerId));
            const hash = res.data.hash;
            copy(`${document.location.href}?shared_dashboard=${hash}`);
            dispatch(
                storeNewAlert({
                    type: 'success',
                    text: `${t('Link copied to clipboard')}`,
                    duration: 30,
                }),
            );
        } catch (err) {
            dispatch(deleteGlobalSpinnerItemById(spinnerId));
            dispatch(
                storeNewAlert({
                    type: 'error',
                    text: `${t("Can't save shared panel")}`,
                    duration: 30,
                }),
            );
        }
    };

    return (
        <Wrapper>
            <div className={styles.field}>
                <Label weight="semibold" htmlFor={underlineId}>
                    {t('Name')}
                </Label>
                <Input
                    onChange={handleNameInput}
                    value={dashboardName}
                    placeholder={t('Name')}
                    appearance="underline"
                    id={underlineId}
                />
            </div>

            {/* <div className={styles.field}>
                <Label weight="semibold" htmlFor={descriptionId}>
                    {t('Description')}
                </Label>
                <Textarea
                    onChange={handleDescriptionInput}
                    value={description}
                    // appearance="underline"
                    id={descriptionId}
                />
            </div> */}

            <ButtonWrapper>
                <Button text={t('Share')} appearance={'primary'} onClick={onButtonClick} />
            </ButtonWrapper>
        </Wrapper>
    );
};

export default MakeSharedModalBody;
