import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';
import { ILayer, ITenantLayer } from '../../../../../../General.interfaces';
import { generalReducerValues } from '../../../../../../General.reducer';
import { AvailableReportingObjectsTypes, IZonesByGroup } from '../interfaces';
import generateColorsByMarker from '../tools/generateColorsByMarker';
import { Maps_CrossVisits_Module_Reducer_Values } from '../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import {
    Maps_CrossVisits_Widget_Reducer_Values,
    storeContraReportingObjectsIds,
    storeMainReportingObjectId,
    storeAvailableMarkers,
    storeColorsByMarker,
    storeExtendedLayers,
    storeZonesByGroup,
    resetMetricsData,
} from '../reducer';
import { ZONES_WORD } from '../../../../../../constants/constants';

/**
 * Кастомный хук, отвечающий за подготовку данных, необходимых компонентам
 */
const usePrepareData = () => {
    const { moduleName } = useSelector(Maps_CrossVisits_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        rawComparisonAverageIntersectionsData,
        selectedReportingObjectMarker,
        selectedReportingObjectType,
        rawAverageIntersectionsData,
        contraReportingObjectsIds,
        mainReportingObjectId,
        selectedGroupOfZones,
        availableMarkers,
        rawLayersData,
        zonesByGroup,
    } = useSelector(Maps_CrossVisits_Widget_Reducer_Values);

    const AVAILABLE_DATA_SOURCE = 'shopster';
    const {
        cfg: { reportingObjectsByType },
        structures,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    /** Обнуление части стейта, после смены версии */
    // useEffect(() => {
    //     selectedVersionId && dispatch(resetDataAfterVersionChange());
    // }, [selectedVersionId]);

    /** Получение объекта зон, где ключ это маркер группы зон */
    useEffect(() => {
        const zonesByGroup: IZonesByGroup = reportingObjectsByType[AvailableReportingObjectsTypes.Zone]?.reduce(
            (acc, value) => {
                const groupMarker = value.object_params.group_marker;
                if (groupMarker) {
                    if (!acc[groupMarker]) acc[groupMarker] = [value];
                    else acc[groupMarker].push(value);
                }
                return acc;
            },
            {},
        );
        dispatch(storeZonesByGroup(zonesByGroup));
    }, [reportingObjectsByType]);

    /** Получение id главного отчетного объекта и всех остальных */
    useEffect(() => {
        if (selectedReportingObjectMarker && availableMarkers) {
            const currentReportingObjects = reportingObjectsByType[selectedReportingObjectType];

            const mainReportingObject = currentReportingObjects?.find((element) => {
                let flag = false;
                const isCurrentZone = selectedReportingObjectType.includes(ZONES_WORD)
                    ? element.object_params.group_marker === selectedGroupOfZones
                    : true;
                if (element.marker === selectedReportingObjectMarker && isCurrentZone) {
                    flag = true;
                }
                return flag;
            });

            if (mainReportingObject) {
                const idsOfContraReportingObjects = currentReportingObjects
                    ?.filter((element) => {
                        let flag = false;
                        const isCurrentZone = selectedReportingObjectType.includes(ZONES_WORD)
                            ? element.object_params.group_marker === selectedGroupOfZones
                            : true;

                        if (
                            element.id !== mainReportingObject.id &&
                            availableMarkers.includes(element.marker) &&
                            isCurrentZone
                        ) {
                            flag = true;
                        }

                        return flag;
                    })
                    .map((element) => element.id);

                if (idsOfContraReportingObjects.length) {
                    dispatch(storeContraReportingObjectsIds(idsOfContraReportingObjects));
                    dispatch(storeMainReportingObjectId(mainReportingObject.id));
                    dispatch(resetMetricsData());
                }
            }
        }
    }, [selectedReportingObjectMarker, availableMarkers, selectedGroupOfZones, zonesByGroup]);

    /** Получение цветов по маркеру для пересечений */
    useEffect(() => {
        if (rawAverageIntersectionsData && Array.isArray(rawAverageIntersectionsData)) {
            const colorsByMarker = generateColorsByMarker({
                rawComparisonAverageIntersectionsData,
                rawAverageIntersectionsData,
                selectedReportingObjectType,
                reportingObjectsByType,
            });
            dispatch(storeColorsByMarker(colorsByMarker));
        }
    }, [
        rawComparisonAverageIntersectionsData,
        rawAverageIntersectionsData,
        contraReportingObjectsIds,
        mainReportingObjectId,
    ]);

    /** Добавления слоя арендаторов ко всем слоям */
    useEffect(() => {
        if (
            localCurrentOptions?.selectedLocationId &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            Array.isArray(rawLayersData) &&
            structures
        ) {
            const mainPeriodDateRnage = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions.mainPeriod?.id,
            );

            if (mainPeriodDateRnage) {
                const filteredTenants = filterValidDateRanges(
                    structures[`relations_tenant2place`] as { date_to: string; date_from: string }[],
                    mainPeriodDateRnage?.period,
                );

                const newLayers = cloneDeep(rawLayersData);

                rawLayersData.forEach((layer: ILayer) => {
                    if (layer.layer_type === 'places_layer') {
                        const data: ITenantLayer[] = [];
                        layer.data?.forEach((item: ITenantLayer) => {
                            const marker = filteredTenants?.find((t2p) => t2p['place_marker'] === item.marker)?.[
                                'tenant_marker'
                            ];

                            marker && data.push({ ...item, marker });
                        });
                        const newLay = { ...layer, layer_type: 'tenants_layer', data };
                        newLayers.push(newLay);
                    }
                });

                dispatch(storeExtendedLayers(newLayers));
            }
        }
    }, [
        localCurrentOptions?.selectedLocationId,
        localCurrentOptions?.mainDateRanges,
        localCurrentOptions?.mainPeriod,
        rawLayersData,
        structures,
    ]);

    /** Сохранение всех доступных маркеров */
    useEffect(() => {
        const currentReportingObjects = reportingObjectsByType[selectedReportingObjectType];

        if (currentReportingObjects) {
            const availableMarkers = currentReportingObjects
                ?.filter((element) => {
                    let flag = true;
                    const dataSources = element.external_ids.map((element) => element.data_source);
                    if (!dataSources.includes(AVAILABLE_DATA_SOURCE)) {
                        flag = false;
                    }

                    return flag;
                })
                .map((element) => element.marker);

            dispatch(storeAvailableMarkers(availableMarkers));
        }
    }, [selectedReportingObjectType, reportingObjectsByType]);
};

export default usePrepareData;
