import styled from 'styled-components/macro';

export const MainPeriodText = styled.div`
    margin-right: var(--space-sm);
    font-size: var(--text-lg);
    font-weight: 500;
    padding-bottom: var(--space-xxxs);
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        width: 100%;
    }
`;

export const CheckboxWrapper = styled.div`
    padding-bottom: 8px;
    line-height: 0px;
    align-self: end;
    .checkbox {
        height: 18px;
        width: 34px;
    }

    .checkbox-switch {
        height: 16px;
        width: 16px;
    }

    .checkbox__content {
        line-height: 14px;
        font-weight: 500;
    }
`;

export const Wrapper = styled.div`
    margin-bottom: 15px;
`;
