import { FC } from 'react';
import { ITableCustomComponentProps } from '../../../../interfaces';
import Stack from '../../../../../../../../../components/Stack/Stack';
import { Copy24Regular, Delete24Regular } from '@fluentui/react-icons';
import Tooltip from '../../../../../../../../../components/Tooltip/Tooltip';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { copyDecisionTableRow, deleteDecisionTableRowRow } from '../../../../reducer';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Button } from 'src/components/Button';

const DecisionControls: FC<ITableCustomComponentProps> = (props) => {
    const { user } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onCopy = () => {
        dispatch(copyDecisionTableRow({ frontId: props.cell.frontId }));
    };

    const onDelete = () => {
        dispatch(deleteDecisionTableRowRow({ frontId: props.cell.frontId }));
    };

    const isDisabled = !user?.permissions?.includes('app/client | modules/decisions-configuration | feature/edit');

    return (
        <span>
            <Stack direction="row" alignItems="center" gap={5}>
                <Tooltip content={t('Clone')}>
                    <Button disabled={isDisabled} onClick={onCopy} appearance="transparent" icon={<Copy24Regular />} />
                </Tooltip>
                <Tooltip content={t('Delete')}>
                    <Button
                        disabled={isDisabled}
                        onClick={onDelete}
                        appearance="transparent"
                        icon={<Delete24Regular />}
                    />
                </Tooltip>
            </Stack>
        </span>
    );
};

export default DecisionControls;
