import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { isTabletOutboundWidth, isMobileInboundWidth } from 'src/theme';

import { generalReducerValues } from '../../../../../General.reducer';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Maps_MapsOverview_Module_Reducer_Values, storeObjectsType, storeSelectedGroup } from '../../reducer';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import LoadingBox from '../../../../../components/LoadingBox/LoadingBox';
import { ZONES_WORD } from '../../../../../constants/constants';
import { getObjSectionName } from '../../../../../tools/Strings/getObjSectionName';
import TabList from '../../../../../components/TabList/TabList';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

import { geoMatrixConverner } from './core/geoMatrixConverter';
import Ratings from './components/Ratings/Ratings';
import Floors from './components/Floors/Floors';
import { IScale } from './components/Floors/interfaces';
import { IOpenTenantInformationArgs } from './interfaces';
import { CanvasWrapper, Cont, MetricContainer } from './styles';

const MapsWidget: React.FC = () => {
    const { moduleName } = useSelector(Maps_MapsOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const canvasAreaRef = useRef(null);
    const sendAnalytics = useSendSimpleAnalytics();
    const [widgetSettings, setWidgetSettings] = useState<string[]>([]);
    const {
        allMetrics,
        mainAreaSize,
        sendYaAnalytics,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const { plans, selectedObjectType, colorsByMarker, metricsData } = useSelector(
        Maps_MapsOverview_Module_Reducer_Values,
    );
    const [ratingHeight, setRatingHeight] = useState(1);

    useEffect(() => {
        if (localCurrentOptions?.['mapsSettings']) {
            setWidgetSettings(localCurrentOptions?.['mapsSettings'].map((item: { id: string }) => item.id));
        }
    }, [localCurrentOptions?.['mapsSettings']]);

    const existingTypes = ['tenant', 'zone', 'floor'];

    const zonesItems = Object.keys(reportingObjectsByType)
        .filter((key) => key.includes(ZONES_WORD))
        .map((key) => ({
            id: key,
            layerType: 'zones_layer',
            text: getObjSectionName({ t, reportingObject: reportingObjectsByType[key][0] }),
        }));

    const tabListItems = [
        ...zonesItems,
        {
            id: 'tenant',
            layerType: 'tenants_layer',
            text: t('Tenants'),
        },
        // {
        //     itemKey: 'place',
        //     layerType: 'places_layer',
        //     headerText: 'Places',
        // },
        // {
        //     itemKey: 'passway',
        //     layerType: 'pass_ways_layer',
        //     headerText: 'Passways',
        // },
        {
            id: 'floor',
            layerType: 'perimeter_layer',
            text: t('Floors'),
        },
    ];

    const isMobile = isMobileInboundWidth(mainAreaSize.width);

    const tabListSelectHandler = (id: string) => {
        dispatch(storeObjectsType(id));
        id.includes(ZONES_WORD) && dispatch(storeSelectedGroup(id.replace(`${ZONES_WORD}: `, '')));
    };

    /** Открытие других виджетов для выбранного арендатора */
    const openTenantInformation = (data: IOpenTenantInformationArgs) => {
        sendAnalytics(`navigate_maps_overview_tenant_to_${data.module}`);
        dispatch(
            changeOptions({
                newOptions: {
                    tenants: [data.tenantData],
                    selectedReportingObjectsIds: [data.tenantData.id],
                    selectedMetrics: data.selectedMetrics,
                },
                currentModuleID: data.module,
            }),
        );

        navigate(data.path);
    };

    const latestPlans = {};

    Array.isArray(plans) &&
        plans?.forEach((plan) => {
            const mainPlan = plans?.filter((item) => plan.floor === item?.floor)?.filter((item) => item.is_main)[0];

            if (latestPlans[plan.floor]) {
                if (
                    DateTime.fromISO(plan.active_from).toMillis() >=
                    DateTime.fromISO(latestPlans[plan.floor].active_from).toMillis()
                ) {
                    latestPlans[plan.floor] = { ...plan, mainPlan };
                }
            } else {
                latestPlans[plan.floor] = { ...plan, mainPlan };
            }
        });

    const widestPlan = Object.keys(latestPlans)
        .map((key) => latestPlans[key])
        .sort((a, b) => b.width / b.scale - a.width / a.scale)[0];

    const finalPlans = Object.keys(latestPlans)
        .map((key) => {
            const plan = latestPlans[key];
            let imageOffset = [0, 0];
            const vector = geoMatrixConverner(0, 0, plan.plan2geo);
            if (vector && vector[0]) {
                imageOffset = geoMatrixConverner(vector[0], vector[1], widestPlan.mainPlan.geo2plan).map((item) => {
                    if (Math.abs(item) < 0.1) {
                        return 0;
                    } else {
                        return item;
                    }
                });
            }
            return { ...plan, imageOffset, widestPlan };
        })
        .sort((a, b) => b.floor - a.floor);

    const layerType = tabListItems?.find((item) => item.id === selectedObjectType)?.layerType || 'places_layer';

    const metricsNumber = localCurrentOptions?.['selectedMetrics']?.length
        ? localCurrentOptions?.['selectedMetrics']?.length
        : 1;

    const workAreaSize = useMemo(() => {
        const sideBarVisible = isTabletOutboundWidth(mainAreaSize.width);
        return sideBarVisible ? { width: mainAreaSize.width - 250, height: mainAreaSize.height } : mainAreaSize;
    }, [mainAreaSize]);

    // const ratingHeight = useMemo(() => {
    //     if (widgetSettings.includes('joinFloors')) {
    //         return workAreaSize.height - 300;
    //     }
    //     return (
    //         finalPlans.reduce((acc, plan) => {
    //             const planRatio = plan.width / plan.height;
    //             const stageWidth = widgetSettings.includes('showRatings')
    //                 ? (0.7 * workAreaSize.width) / metricsNumber
    //                 : workAreaSize.width / metricsNumber;

    //             const stageHeight = Math.round(stageWidth / planRatio);
    //             return acc + stageHeight;
    //         }, 0) -
    //         10 * (finalPlans.length - 4)
    //     );
    // }, [workAreaSize, finalPlans, metricsNumber, widgetSettings]);

    const changeScale = (args: IScale) => {
        set_scale(args);
    };

    const colsNumber = localCurrentOptions?.['selectedMetrics']?.length || 1;

    const diagram = useMemo(() => {
        return finalPlans[0] ? (
            (localCurrentOptions?.['selectedMetrics'] || [1]).map((metric, j) => {
                const metricName = t(allMetrics?.find((item) => item.id === metric)?.text || '', { ns: 'metrics' });
                return (
                    <Cont key={`canvas--${metric}`}>
                        <WidgetTitle>{metricName}</WidgetTitle>
                        <MetricContainer>
                            <Floors
                                scale={scale}
                                changeScale={changeScale}
                                metric={String(metric)}
                                layerType={layerType}
                                canvasAreaSize={workAreaSize}
                                setRatingHeight={setRatingHeight}
                                openTenantInformation={openTenantInformation}
                            />
                            {widgetSettings.includes('showRatings') && (
                                <Ratings
                                    metric={String(metric)}
                                    colorsByMarker={colorsByMarker}
                                    height={ratingHeight}
                                    openTenantInformation={openTenantInformation}
                                />
                            )}
                        </MetricContainer>
                    </Cont>
                );
            })
        ) : (
            <LoadingBox height={400} text={t('Loading...')} />
        );
        // }
    }, [
        allMetrics,
        workAreaSize,
        colorsByMarker,
        finalPlans,
        layerType,
        localCurrentOptions,
        metricsData,
        scale,
        ratingHeight,
        t,
        widgetSettings,
        colsNumber,
    ]);

    const tabList = useMemo(() => {
        return (
            <TabList
                selectHandler={tabListSelectHandler}
                selectedValue={selectedObjectType}
                widgetName="MapsOverview"
                options={tabListItems}
            />
        );
    }, [existingTypes, selectedObjectType, t]);

    return (
        <WidgetWrapper>
            <WidgetTitleWrapper>
                <WidgetTitle>{t('Metrics on the map')}</WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            {/* <Selects /> */}
            <CanvasWrapper ref={canvasAreaRef} colsNumber={colsNumber}>
                {diagram}
            </CanvasWrapper>
        </WidgetWrapper>
    );
};

export default MapsWidget;
