import { FC } from 'react';
import { IProps } from './interfaces';
import Tooltip from '../../../../Charts/Tooltip/Tooltip';
import TooltipBody from '../../../../Charts/TooltipBody/TooltipBody';
import { IWeatherData } from '../../interfaces';
import { orderBy } from 'lodash';
import { WeatherTooltipTitle } from './styles';
import Stack from '../../../../Stack/Stack';
import { BodyWrapper } from '../../../../Charts/Tooltip/styles';
import { ChartDot } from '../../../../Charts/styles';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import { valueFormatter } from '../../../../../tools/Strings/valueFormatter';

const DynamicsTooltip: FC<IProps> = (props) => {
    const { t } = useTranslation(['metrics', 'translation']);

    const weatherData: IWeatherData | undefined = props.data.points?.find((item) => {
        return Boolean(item.point?.['weatherData']);
    })?.point?.['weatherData'];

    return (
        <Tooltip
            title={props.data.point?.['title'] || props.data.point?.name || String(props.data.x)}
            subTitle={props.data.point?.['subTitle'] || ''}
        >
            <>
                <TooltipBody points={props.data.points || [props.data]} />
                {weatherData && (
                    <>
                        <Stack styles={{ padding: '5px' }} alignItems="center" gap={10} justifyContent="space-between">
                            <WeatherTooltipTitle>{`${t('Weather for')} ${
                                weatherData.reportingObject?.name
                            }`}</WeatherTooltipTitle>
                        </Stack>
                        <BodyWrapper>
                            {orderBy(Object.values(weatherData.weather), (item) => item.color, 'asc').map((item) => {
                                return (
                                    <Stack
                                        key={item.metricId}
                                        gap={10}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Stack alignItems="center" gap={2}>
                                            {item.color && <ChartDot newColor={item.color} />}
                                            <div>{t(item.metric?.text || item.metricId)}</div>
                                        </Stack>

                                        <div>
                                            {valueFormatter({
                                                value: item.value,
                                                units: item.metric?.units,
                                                precision: item.metric?.round_decimal_places,
                                                t,
                                            })}
                                        </div>
                                    </Stack>
                                );
                            })}
                        </BodyWrapper>
                    </>
                )}
            </>
        </Tooltip>
    );
};

export default DynamicsTooltip;
