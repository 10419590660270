import styled from 'styled-components/macro';

export const OptionsWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* padding-right: var(--space-md);
    padding-left: var(--space-md); */
    /* max-width: 1176px; */
`;
