import axios from 'axios';
import { getHeaders } from './getHeaders';
import { ICategoriesAPIArgs } from './interfaces';

export const CategoriesAPI = async (args: ICategoriesAPIArgs) => {
    const { token, chapter, id, data, method, urlParams, url, user } = args;
    if (!user) return;
    if (
        !user.permissions.includes('app/client | modules/configuration/categories | feature/edit') &&
        ['POST', 'PATCH', 'DELETE'].includes(method)
    ) {
        return;
    }
    const headers = getHeaders(token)

    const getId = () => {
        if (id) {
            return `${id}/`;
        } else {
            return '';
        }
    };

    const getParams = () => {
        if (urlParams) {
            const arr = Object.keys(urlParams).map((param) => `${param}=${urlParams[param]}`);
            return `?${arr.join('&')}`;
        } else {
            return '';
        }
    };

    try {
        const response = await axios({
            method,
            headers,
            data,
            url: `${url}/${chapter}/${getId()}${getParams()}`,
        });

        return response;
    } catch (error) {
        return error;
    }
};
