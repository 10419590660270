import axios from 'axios';
import { getHeaders } from './getHeaders';

/**
 * Функция для получения информации обк аккаунте пользователя (пермишены и время жизни токена)
 */
export const getCurrent = async (token: string, currentUrl: string) => {
    const headers = getHeaders(token);

    try {
        const response = await axios({
            method: 'GET',
            headers,
            url: currentUrl,
        });
        return response;
    } catch (error) {
        return error;
    }
};
