import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { GrandWrapper, Item, Wrapper } from './styles';

/**
 * Компонент для отображения переданных чисел и обработки их выбора
 */
const NumberToShow: FC<IProps> = ({ handleSelect, numbersToShow = [3, 5, 10, 15], defaultSelected = 10, testId='' }) => {
    const [selectedValue, setSelectedValue] = useState(defaultSelected);
    const { t } = useTranslation();

    useEffect(() => {
        handleSelect && handleSelect(selectedValue);
    }, [handleSelect, selectedValue]);

    const handleValueSelect = (value: number | 'all') => () => {
        setSelectedValue(value);
    };


    return (
        <GrandWrapper>
            <Wrapper>
                {numbersToShow.map((element) => (
                    <Item data-test-id={element + testId} key={element} selected={element === selectedValue} onClick={handleValueSelect(element)}>
                        {element}
                    </Item>
                ))}
            </Wrapper>

            <Wrapper>
                <Item data-test-id={numbersToShow.length + testId + ':all'} selected={'all' === selectedValue} onClick={handleValueSelect('all')} hideRightBorder={true}>
                    {t('All')}
                </Item>
            </Wrapper>
        </GrandWrapper>
    );
};

export default NumberToShow;
