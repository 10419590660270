import { ChartOptions } from 'highcharts';
import { ICustomChartDimensions } from '../interfaces';
import { CHART_DESKTOP_HEIGHT, CHART_MOBILE_HEIGHT } from '../../../../constants/constants';

interface IArgs {
    customChartDimensions?: ICustomChartDimensions;
    chartOptions?: ChartOptions;
    isMobile?: boolean;
}

const generateChart = (args: IArgs): ChartOptions => {
    const { customChartDimensions, chartOptions, isMobile } = args;
    return {
        height: customChartDimensions
            ? customChartDimensions.height
            : isMobile
            ? CHART_MOBILE_HEIGHT
            : CHART_DESKTOP_HEIGHT,
        width: customChartDimensions ? customChartDimensions.width : undefined,
        style: { fontFamily: 'Roboto' },
        animation: true,
        className: 'column__wrapper',
        ...chartOptions,
    };
};

export default generateChart;
