import { FC } from 'react';
import { IProps } from './interfaces';
import { Name, Rating, RatingWrapper, Td, Tr, Value } from '../Table/styles';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';

/**
 * Компонент для отображения одной строки в рейтинге
 */
const Row: FC<IProps> = ({ data, bgColor }) => {
    return (
        <Tr>
            <Td width={'100%'} minWidth={'50px'}>
                <Name title={data.name}>{data.name}</Name>
                <RatingWrapper>
                    <Rating width={data.mainValue.percentOfMax} bgColor={bgColor} />
                </RatingWrapper>
            </Td>

            <Td>
                <Value>{data.mainValue.value}</Value>
            </Td>
            <Td>
                <Value color={'var(--color-secondary-darker)'}>
                    {valueFormatter({ value: data.mainValue.percentOfOperationTotal, precision: 1, units: '%' })}
                </Value>
            </Td>
        </Tr>
    );
};

export default Row;
