import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import { GreyText, IntersectionThDataWrapper, Th, THead } from './styles';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { FC, useContext } from 'react';
import { IntersectionsWithTenantsContext } from '../../../../context';

/**
 * Компонент для отображения шапки таблицы
 */
const TableHeader: FC<IProps> = ({ sumValue, days, headerHeight }) => {
    const context = useContext(IntersectionsWithTenantsContext);
    const { t } = useTranslation();
    return (
        <THead headerHeight={headerHeight}>
            <tr>
                <Th>
                    <GreyText>{t(context?.selectedReportingObjectsType.text || '')}</GreyText>
                </Th>
                <Th>
                    <IntersectionThDataWrapper>
                        <GreyText>{t('Daily average')}</GreyText>
                        {context?.isLoading ? (
                            <GreyText>{t('Loading...')}</GreyText>
                        ) : context?.isError ? (
                            <GreyText>{t('Unable to get data')}</GreyText>
                        ) : (
                            <GreyText>
                                {sumValue !== null ? valueFormatter({ value: sumValue / days, precision: 0 }) : '—'}
                            </GreyText>
                        )}
                    </IntersectionThDataWrapper>
                </Th>
                <Th>
                    <IntersectionThDataWrapper>
                        <GreyText>{t('Period')}</GreyText>
                        {context?.isLoading ? (
                            <GreyText>{t('Loading...')}</GreyText>
                        ) : context?.isError ? (
                            <GreyText>{t('Unable to get data')}</GreyText>
                        ) : (
                            <GreyText>{valueFormatter({ value: sumValue, precision: 0 })}</GreyText>
                        )}
                    </IntersectionThDataWrapper>
                </Th>
            </tr>
        </THead>
    );
};

export default TableHeader;
