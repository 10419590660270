import { IDynamicsPMOReducerState } from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store';
import { ITabListOptionsItem } from '../../../../../components/TabList/interfaces';

const availableChartTypes: ITabListOptionsItem[] = [
    { text: 'Periods', id: 'periods' },
    { text: 'Metrics', id: 'metrics' },
];

const initialState: IDynamicsPMOReducerState = {
    selectedChartType: availableChartTypes[0],
    availableChartTypes,
};

const Leasing_TenantOverview_Dynamics_Widget_Reducer = createSlice({
    name: 'Leasing_TenantOverview_Dynamics_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Запись выбранного типа графика
         */
        storeSelectedChartType: (state, action: PayloadAction<string>) => {
            const currentChartType = availableChartTypes?.find((element) => element.id === action.payload);
            state.selectedChartType = currentChartType || availableChartTypes[0];
        },
    },
});

export const { storeSelectedChartType } = Leasing_TenantOverview_Dynamics_Widget_Reducer.actions;

export const Leasing_TenantOverview_Dynamics_Widget_Reducer_Values = (state: RootState) =>
    state.Leasing_TenantOverview_Dynamics_Widget_Reducer;

export default Leasing_TenantOverview_Dynamics_Widget_Reducer.reducer;
