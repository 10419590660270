import { Helmet } from 'react-helmet';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MessageBarGroup } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { Theme, GlobalStyle } from 'src/theme';
import Header from 'src/components/Header/Header';
import useSettings from 'src/hooks/useSettings';
import useSidebarOperations from 'src/components/SideBar/hooks/useSidebarOperations';
import { useProjectAlerts } from 'src/hooks/useProjectAlerts';
import useCurrentOptions from 'src/hooks/useCurrentOptions';
import DataComponent from 'src/tools/DataComponent';
import useCabinetPreferencesData from 'src/components/CabinetPreferences/hooks/useCabinetPreferencesData';
import SideBar from 'src/components/SideBar/SideBar';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useAppBackendHost } from 'src/hooks/useAppBackendHost';
import { useRequestEvents } from 'src/Chapters/Events/EventsMap/widgets/EventsMapWidget/hooks/useFetchData';
import { AlertsReducerValues } from 'src/components/Alert/reducer';
import Alert from 'src/components/Alert/Alert';
import { useRequestRatings } from 'src/Chapters/Performance/Ratings/widgets/RatingList/hooks/useRequestRatings';
import { useChangeAccParams } from 'src/hooks/useChangeAccParams';
import { useRequestMainStructures } from 'src/tools/API/hooks/useRequestMainStructures';
import TokenExpiration from 'src/components/TokenExpiration/TokenExpiration';
import ErrorBoundaryFallback from 'src/components/ErrorBounadryFallback/ErrorBounadaryFallback';
import { useErrorReset } from 'src/hooks/useErrorReset';
import { useModulePermissions } from 'src/hooks/useModulePermissions';
import useDataObjectsSelectFilters from 'src/hooks/dataObjectsSelectFilters/useDataObjectsSelectFilters';
import useInitialRequests from 'src/tools/API/hooks/useInitialRequests';
import useRTLLang from 'src/hooks/useRTLLang';
import useUrl from 'src/hooks/useUrl';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import { useEmptySettings } from 'src/hooks/useEmptySettings';
import YaMetricsCounter from 'src/components/AnalyticsCounter/YaMetricsCounter';
import GooMetricsCounter from 'src/components/AnalyticsCounter/GooMetricsCounter';
import Footer from 'src/components/Footer/Footer';
import GlobalSpinner from 'src/components/GlobalSpinner/GlobalSpinner';
import { resetSelectedUserPresetIds } from 'src/components/Preset/reducer';
import { useSharedPreset } from 'src/hooks/useSharedPreset';
import Modals from 'src/components/Modals/Modals';
import SharedPresetModalBody from 'src/components/Preset/components/SharedPresetModalBody/SharedPresetModalBody';
import useRelocatePresets from 'src/hooks/useRelocatePresets';
import { useUserPresets } from 'src/hooks/useUserPresets';
import ModuleLoadingElement from 'src/components/ModuleLoadingElement/ModuleLoadingElement';
import { useObserverError } from 'src/hooks/useObserverError';
import { useNetworkQuery } from 'src/tools/API/hooks/useNetworkQuery';
import useCurrencies from 'src/tools/API/hooks/useCurrencies';
import { svgSprite } from 'src/assets/sprites/svg-sprite';

import { generalReducerValues, resetGlobalSpinner, changeIsSidebarOpen } from './General.reducer';
import { GeneralContext, IGeneralContext } from './General.context';
import {
    AlertsLayer,
    GlobalSpinnerWrapper,
    Main,
    MainArea,
    ModuleContentWrapper,
    SidebarLayout,
    WorkArea,
    useAppStyles,
} from './App.styles';
import { AppRouter } from './AppRouter';
import { useSharedDashboard } from './hooks/useSharedDashboard';
import SharedDashboardModalBody from './components/DashboardsCommon/PanelsManager/components/SharedDashboardModalBody/SharedDashboardModalBody';

// Если понадобится динамически переопределить CSS переменные - то делаем так
// document.documentElement.style.setProperty('--color-primary', 'red')

function App() {
    const { sharedPresetModalStatus, setSharedPresetModalStatus } = useSharedPreset();
    const { sharedDashboardModalStatus, setSharedDashboardModalStatus } = useSharedDashboard();

    const mainBoxRef = useRef<HTMLDivElement>(null);

    const { alerts } = useSelector(AlertsReducerValues);

    const {
        cfg: { reportingObjectsById },
        selectedLocationId,
        appCoreData,
        currentModuleID,
        token,
        lang,
        user,
        isRtlLanguage,
        initialDataReceived,
        sendYaAnalytics,
        sendGooAnalytics,
        allMetrics,
        globalSpinner,
        isSidebarOpen,
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);

    const appStyles = useAppStyles();

    const showModule = useModulePermissions();
    const location = useLocation();

    useEffect(() => {
        if (selectedLocationId) {
            dispatch(resetGlobalSpinner());
            dispatch(resetSelectedUserPresetIds());
        }
    }, [selectedLocationId]);

    useEffect(() => {
        if (mainBoxRef?.current !== null) {
            mainBoxRef.current.scrollTop = 0;
        }
    }, [currentModuleID, selectedLocationId]);

    useInitialRequests();
    useCurrencies();
    useObserverError();

    useUrl();

    useRelocatePresets();
    useUserPresets();
    useProjectAlerts();

    useWindowDimensions();
    useDataObjectsSelectFilters();
    useSidebarOperations();
    useCabinetPreferencesData();
    useRequestMainStructures();
    useNetworkQuery();

    useChangeAccParams();
    useAppBackendHost();
    useCurrentOptions();
    useRequestRatings();
    useRequestEvents();
    useEmptySettings();
    useAnalytics();
    useSettings();
    useRTLLang();

    const t = useTranslation().t;
    const dispatch = useDispatch();

    useEffect(() => {
        document.dir = isRtlLanguage ? 'rtl' : 'ltr';
    }, [isRtlLanguage]);

    const toggleSidebarOpen = () => {
        dispatch(changeIsSidebarOpen(!isSidebarOpen));
    };

    const dataHooks = useMemo(() => {
        if (token && Object.keys(reportingObjectsById).length && initialDataReceived) {
            return <DataComponent />;
        } else {
            return null;
        }
    }, [token, reportingObjectsById, initialDataReceived]);

    const reset = useErrorReset();

    const closeSharedPresetModal = () => {
        setSharedPresetModalStatus({ show: false });
    };

    const context: IGeneralContext = {
        mainBoxRef,
    };

    return (
        <GeneralContext.Provider value={context}>
            <Theme isRtlLanguage={isRtlLanguage}>
                <GlobalStyle />
                <Helmet htmlAttributes={{ lang }}>
                    <link rel="icon" href={appCoreData?.favicon_url} />
                </Helmet>

                {sendYaAnalytics && <YaMetricsCounter />}
                {sendGooAnalytics && <GooMetricsCounter />}

                <MainArea>
                    {Boolean(Object.keys(globalSpinner).length) && (
                        // <GlobalSpinnerWrapper isSidebarShown={Boolean(user)}>
                        <GlobalSpinner />
                        // </GlobalSpinnerWrapper>
                    )}
                    <ModuleLoadingElement />
                    <Modals
                        title={t('Shared preset')}
                        modalStatus={sharedPresetModalStatus}
                        closeModal={closeSharedPresetModal}
                    >
                        <SharedPresetModalBody
                            closeModal={closeSharedPresetModal}
                            sharedPreset={sharedPresetModalStatus.sharedPreset}
                        />
                    </Modals>
                    <Modals
                        title={t('Shared dashboard panel')}
                        modalStatus={sharedDashboardModalStatus}
                        closeModal={() => setSharedDashboardModalStatus({ show: false })}
                    >
                        <SharedDashboardModalBody
                            closeModal={() => setSharedDashboardModalStatus({ show: false })}
                            sharedDashboard={sharedDashboardModalStatus.sharedDashboard}
                        />
                    </Modals>
                    {dataHooks}
                    <TokenExpiration />
                    <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: svgSprite }} />
                    <Header
                        marginBottom={location.pathname === '/events/events' ? '0px' : undefined}
                        toggleSidebarOpen={toggleSidebarOpen}
                        sidebarOpen={isSidebarOpen}
                    />

                    {appCoreData &&
                    showModule &&
                    selectedLocationId &&
                    allMetrics.length &&
                    Object.keys(reportingObjectsById).length ? (
                        <WorkArea sidebarOpen={isSidebarOpen}>
                            {/* Основная рабочая область сервиса */}
                            {user && (
                                <>
                                    <SidebarLayout sidebarOpen={isSidebarOpen} onClick={toggleSidebarOpen} />
                                    <SideBar currentServiceName={'client-office'} />
                                </>
                            )}

                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} resetKeys={[reset]}>
                                <Main ref={mainBoxRef} paddingTop={0}>
                                    <ModuleContentWrapper>
                                        <AppRouter />
                                    </ModuleContentWrapper>
                                    <Footer />
                                </Main>
                            </ErrorBoundary>
                        </WorkArea>
                    ) : (
                        <GlobalSpinner isScreenHeight={false} />
                    )}
                    <AlertsLayer>
                        <MessageBarGroup className={appStyles.messageBarGroup} animate="both">
                            {alerts.map((alert) => (
                                <Alert alert={alert} key={alert.id} />
                            ))}
                        </MessageBarGroup>
                    </AlertsLayer>
                </MainArea>
            </Theme>
        </GeneralContext.Provider>
    );
}

export default App;
