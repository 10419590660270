import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import { isMobileInboundWidth } from 'src/theme';

import { generalReducerValues } from '../../../../../../../General.reducer';
import { IPeriod } from '../../../../../../../General.interfaces';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';
import {
    Performance_Dashboard_ByArea_Widget_Reducer_Values,
    storeComparePeriod,
    storeColorMetric,
    storeAreaMetric,
    storeTenantsView,
} from '../../reducer';
import FiltersSelect from '../../../../../../../components/Filters/FiltersSelect/FiltersSelect';
import FiltersMonitor from '../../../../../../../components/Filters/FiltersMonitor/FiltersMonitor';
import { getReportingObjects } from '../../../../../../../tools/getReportingObjects';
import { ITenantsView } from '../../interfaces';
import Select from '../../../../../../../components/Selects/Select/Select';
import { IObject, TSelectedOptions } from '../../../../../../../components/Selects/Select/interfaces';
import { Performance_Dashboard_Module_Reducer_Values } from '../../../../reducer';
import Stack from '../../../../../../../components/Stack/Stack';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { useSendSimpleAnalytics } from '../../../../../../../hooks/useAnalytics';
import { stringDate } from '../../../../../../../tools/Strings/stringDate';
import { addAreaMetricsFoo } from '../../tools/addAreaMetrics';

import { MainPeriodText, SelectsWrapper } from './styles';

/** Компонент для отображения селектов виджета (период сравнения, метрика цвета и метрика площади) */
const Selects: React.FC = () => {
    const sendAnalytics = useSendSimpleAnalytics();
    const { moduleName } = useSelector(Performance_Dashboard_Module_Reducer_Values);
    const { comparePeriod, colorMetric, areaMetric, objectsType, tenantsView, widgetName } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const [compareOptions, setCompareOptions] = useState<IObject[]>([]);
    const {
        allMetrics,
        lang,
        currentModuleID,
        cfg: { reportingObjectsByType },
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        const compareOptions: IObject[] = localCurrentOptions?.compareDateRanges
            ? (localCurrentOptions?.comparePeriods?.map((p) => {
                  const [range] = localCurrentOptions?.compareDateRanges?.filter((r) => r.id === p.id);
                  const text = stringDate(range?.period, lang, '', 'dd MMM yyyy');
                  return { id: p.id, text, period: range?.period };
              }) as IObject[])
            : [];

        setCompareOptions(compareOptions);
    }, [localCurrentOptions?.compareDateRanges, localCurrentOptions?.comparePeriods, lang]);

    const isMobile = isMobileInboundWidth(width);

    const filterValidOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType).length) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [reportingObjectsByType, t]);

    const tenantsViewOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType).length) {
            const options = getReportingObjects(reportingObjectsByType, t)
                .filter(
                    (item) =>
                        item.objects[0]?.object_type.includes('Zones') ||
                        item.objects[0]?.block_type === 'project_category',
                )
                .map((item) => {
                    return {
                        text: item.section,
                        id: item.key,
                    };
                });

            return [{ id: 'all', text: t('Show all') }, ...options];
        }
        return [];
    }, [reportingObjectsByType, t]);

    useEffect(() => {
        const ids = tenantsViewOptions.map((item) => item.id);

        if (!ids.includes(tenantsView.id)) {
            dispatch(storeTenantsView(tenantsViewOptions[0]));
        }
    }, [tenantsViewOptions, tenantsView]);

    const areaMetricOptions: IObject[] = localCurrentOptions?.selectedMetrics
        ? addAreaMetricsFoo(cloneDeep(allMetrics))
              ?.filter((item) => localCurrentOptions?.selectedMetrics?.includes(item.id) || item.id === 'sum_area')
              .map((item) => ({ ...item, text: t(item.text, { ns: 'metrics' }) }))
        : [];

    const colorMetricOptions: IObject[] = localCurrentOptions?.selectedMetrics
        ? cloneDeep(allMetrics)
              ?.filter((item) => localCurrentOptions?.selectedMetrics?.includes(item.id) && item.id !== 'sum_area')
              .map((item) => ({ ...item, text: t(item.text, { ns: 'metrics' }) }))
        : [];

    useEffect(() => {
        if (colorMetric === '' && colorMetricOptions[0]) {
            handleColorMetricleSelect([colorMetricOptions[0]]);
        }
    }, [colorMetric, colorMetricOptions]);

    useEffect(() => {
        // При изменении метрик в селекте модуля, проверяем, валидны ли выбранные метрики в виджете, и если нет, принудительно их перевыбираем
        if (!colorMetricOptions.map((item) => item.id).includes(colorMetric)) {
            colorMetricOptions[0] && handleColorMetricleSelect([colorMetricOptions[0]]);
        }
        if (!areaMetricOptions.map((item) => item.id).includes(areaMetric) && areaMetric !== 'sum_area') {
            dispatch(storeAreaMetric('sum_area'));
        }
    }, [
        areaMetric,
        areaMetricOptions,
        colorMetric,
        colorMetricOptions,
        dispatch,
        localCurrentOptions?.selectedMetrics,
    ]);

    const handPeriodSelect = (args: TSelectedOptions) => {
        args?.[0] && dispatch(storeComparePeriod(args[0] as IPeriod));
    };

    const handleAreaMetricleSelect = (args: TSelectedOptions) => {
        sendAnalytics('area_metric_selection', 'reachGoal', `${moduleName}:${widgetName}`);
        args?.[0]?.id && dispatch(storeAreaMetric(String(args[0].id)));
    };

    const handleColorMetricleSelect = (args: TSelectedOptions) => {
        colorMetric && sendAnalytics('color_metric_selection', 'reachGoal', `${moduleName}:${widgetName}`);
        args?.[0]?.id && dispatch(storeColorMetric(String(args[0].id)));
    };

    const handleTenantsViewSelect = (args: TSelectedOptions) => {
        args?.[0] && dispatch(storeTenantsView(args[0] as ITenantsView));
    };

    const outsideSelectedColorMetric = cloneDeep(allMetrics)
        ?.filter((item) => colorMetric === item.id)
        .map((item) => ({ ...item, text: t(item.text, { ns: 'metrics' }) }));
    const outsideSelectedAreaMetric = addAreaMetricsFoo(cloneDeep(allMetrics))
        ?.filter((item) => areaMetric === item.id)
        .map((item) => ({ ...item, text: t(item.text, { ns: 'metrics' }) }));
    const mainDates = localCurrentOptions?.mainDateRanges?.filter(
        (item) => item.id === localCurrentOptions?.mainPeriod?.id,
    )[0]?.period;
    const mainPeriodText = mainDates?.dateFrom ? shortStringDate([mainDates?.dateFrom, mainDates?.dateTo], lang) : '';

    const compareSelect = useMemo(() => {
        const outsideSelectedCompPeriod = localCurrentOptions?.compareDateRanges
            ?.filter((item) => comparePeriod.id === item.id)
            ?.map((item) => ({ ...item, text: stringDate(item?.period, lang, '', 'dd MMM yyyy') }));
        return (
            <Select
                testId={`${currentModuleID}_TreeMap_Comparison period`}
                outsideSelected={outsideSelectedCompPeriod}
                maxWidth={isMobile ? 130 : undefined}
                labelText={t('Comparison period')}
                handleSelect={handPeriodSelect}
                options={compareOptions}
                iconType="chevron-down"
                showFilter={false}
                mode="single"
            />
        );
    }, [localCurrentOptions?.compareDateRanges, compareOptions, isMobile, comparePeriod.id, t, lang, currentModuleID]);

    const groupOutsideSelected = useMemo(() => {
        const text = tenantsViewOptions.find((item) => item.id === tenantsView.id)?.text ?? '';
        return { ...tenantsView, text };
    }, [tenantsView, tenantsViewOptions]);

    return (
        <SelectsWrapper>
            <Stack flexWrap={'wrap'} alignItems={'flex-end'} gap={10}>
                <MainPeriodText>{mainPeriodText}</MainPeriodText>
                {compareSelect}
                <Select
                    testId={`${currentModuleID}_TreeMap_Select area metrics`}
                    outsideSelected={outsideSelectedAreaMetric}
                    handleSelect={handleAreaMetricleSelect}
                    maxWidth={isMobile ? 130 : undefined}
                    labelText={t('Select area metrics')}
                    options={areaMetricOptions}
                    iconType="chevron-down"
                    showFilter={false}
                    mode="single"
                />
                <Select
                    testId={`${currentModuleID}_TreeMap_Select color metrics`}
                    outsideSelected={outsideSelectedColorMetric}
                    handleSelect={handleColorMetricleSelect}
                    maxWidth={isMobile ? 130 : undefined}
                    labelText={t('Select color metrics')}
                    options={colorMetricOptions}
                    iconType="chevron-down"
                    showFilter={false}
                    mode="single"
                />
            </Stack>

            {objectsType === 'tenant' && (
                <Stack gap={20} padding={'15px 0 0 0'} alignItems="flex-end" flexWrap={'wrap'}>
                    {objectsType === 'tenant' && (
                        <Select
                            testId={`${currentModuleID}_TreeMap_Grouping`}
                            handleSelect={handleTenantsViewSelect}
                            maxWidth={isMobile ? 130 : undefined}
                            outsideSelected={[groupOutsideSelected]}
                            options={tenantsViewOptions}
                            labelText={t('Grouping')}
                            iconType="chevron-down"
                            showFilter={false}
                            mode="single"
                        />
                    )}
                    <FiltersSelect validOptions={filterValidOptions} />
                    <FiltersMonitor />
                </Stack>
            )}
        </SelectsWrapper>
    );
};

export default Selects;
