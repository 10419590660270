import { createGlobalStyle, css } from 'styled-components';

const isMac: boolean = navigator.platform.indexOf('Mac') === 0;

export const GlobalStyle = createGlobalStyle`
${() =>
    !isMac
        ? css`
              .hot-display-license-info {
                  display: none;
              }

              ::-webkit-scrollbar {
                  width: 7px;
                  height: 7px;
              }

              ::-webkit-scrollbar-track {
                  background-color: whitesmoke;
              }

              ::-webkit-scrollbar-thumb {
                  background-color: var(--color-secondary-dark);
                  border-radius: 4px;
              }
          `
        : css`
              .hot-display-license-info {
                  display: none;
              }
          `}
`;
