import styled from 'styled-components/macro';

export const SaveAndCancelButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
`;
