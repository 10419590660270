import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../../../tools/getColorFromPercent';
import { TSort } from '../../../../../../../../../components/CommonWidgets/RatingWithAreaByObjects/interfaces';

export const RatingCard = styled.div`
    background: var(--color-white);
    border: 1px solid #ebebeb;
    border-top-color: rgb(235, 235, 235);
    border-top-style: solid;
    border-top-width: 1px;
    border-radius: 0 0 5px 5px;
    border-bottom-left-radius: 5px;
    border-top: none;
    box-sizing: border-box;
    position: relative;
`;
export const RatingHeader = styled.div`
    margin: 0 var(--space-xxxxs) 0 var(--space-sm);
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    //width: 100%;
`;
export const RatingHat = styled.div<{ sortMethod: TSort }>`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 15px;
    position: relative;
    background-color: var(--color-gray);
    width: 100%;
    background-color: ${(p) => (p.sortMethod === 'desc' ? 'var(--color-success)' : 'var(--color-accent-dark)')};
`;
export const RatingBody = styled.div`
    overflow: auto;
`;

export const RatingHatFiller = styled.div`
    border-left: 1px solid var(--color-gray-dark);
    border-right: 1px solid var(--color-gray-dark);
    background: var(--color-white);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 0;
    height: 10px;
    left: 0;
    position: absolute;
    width: 100%;
`;
export const RatingCardItem = styled.div`
    flex: 0 0 50%;
    max-width: calc(50% - 15px);
    margin-bottom: var(--space-sm);
    //border: 1px solid #ebebeb;
    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
        flex: 0 0 100%;
        max-width: 100%;
    }
`;

export const OptionWrapper = styled.div`
    padding: var(--space-xxxxs) var(--space-xs);
    cursor: pointer;
    border: 1px solid transparent;

    :hover {
        background-color: var(--color-primary-lighter);
        color: var(--color-white);
    }
`;
export const ChartWrapper = styled.div``;
export const RatingTable = styled.table`
    width: 100%;
    color: var(--color-black);
    border-collapse: collapse;
    border-spacing: 0;
`;
export const RatingTableTH = styled.th`
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    font-size: 0.75rem;
    padding: var(--space-xxs);
    line-height: 1rem;
    min-width: 20%;
    padding-bottom: 6px;
    padding-top: 6px;
    vertical-align: baseline;
    border-bottom: 1px solid var(--color-gray);
    text-align: right;
    z-index: 1;
    &:first-child {
        text-align: left;
    }
`;
export const RatingTableTD = styled.td<{ value?: number }>`
    color: ${(p) => (p.value ? getColorFromPercent(p.value) + '!important' : 'rgba(0, 0, 0, 0.4)')};
    font-size: 12px;
    text-align: right;
    padding: var(--space-xxs);
    font-weight: 400;
    vertical-align: middle;
    border-bottom: 1px solid var(--color-gray);
    white-space: nowrap;

    &:first-child {
        padding-right: 0;
        text-align: left;
        color: var(--color-black);
        font-size: 14px;
        font-weight: 500;
        white-space: normal;
    }

    &:last-child {
        background-color: hsla(0, 0%, 92.2%, 0.3);
        color: var(--color-black);
        font-size: 14px;
        font-weight: 500;
    }
`;

export const Controls = styled.div`
    display: flex;
    justify-content: end;
    font-size: 1rem;
    gap: 5px;
`;

export const FooterWrapper = styled.div`
    padding: 10px 20px;
    display: flex;
    column-gap: 16px;
    align-items: center;
    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        padding: 10px;
    }
`;

export const FooterWrapperText = styled.div`
    @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
        display: none;
    }
    @media (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        display: inline-block;
    }
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        display: none;
    }
`;
