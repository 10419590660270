import { FC, useRef, useState } from 'react';
import {
    IPropsRatingItem,
    IViewSettings,
} from '../../../../../../../../../components/CommonWidgets/RatingWithAreaByObjects/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';
import {
    DivBoxSC,
    DivWrapperBodyAndFooterSC,
} from '../../../../../../../../../components/CommonWidgets/RatingWithAreaByObjects/styles';
import { storeEditRatingId } from '../../../../reducer';
import { Controls, OptionWrapper, RatingCardItem, RatingHat, RatingHatFiller } from './style';
import Title from '../../../../../../../../../components/Title/Title';
import WidgetAdditionalControls from '../../../../../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import ContextualMenu from '../../../../../../../../../components/ContextualMenu/ContextualMenu';
import IconButton from '../../../../../../../../../components/IconButton/IconButton';
import { MoreHorizontal24Regular } from '@fluentui/react-icons';
import Footer from '../../../../../../../Dashboard/widgets/Traffic/components/Footer/Footer';
import Rating from '../../../../../../../../../components/CommonWidgets/RatingWithAreaByObjects/Rating';
import Modals from '../../../../../../../../../components/Modals/Modals';
import ModalDeleteRating from '../../../ModalDeleteRating/ModalDeleteRating';

const RatingItem: FC<IPropsRatingItem> = (props) => {
    const [itemsToShow, setItemsToShow] = useState<number | 'all'>(10);
    const [contextMenuStatus, setContextMenuStatus] = useState(false);
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { lang } = useSelector(generalReducerValues);

    const { t } = useTranslation(['metrics', 'translation', 'translationCategories']);
    const [modalRemoveStatus, setModalRemoveStatus] = useState({ show: false });
    const dispatch = useDispatch();
    const ref = useRef(null);

    const viewSettings: IViewSettings = {
        mainValuePercentOfTotalStyles: { fontWeight: 700 },
        compareValueStyles: { fontWeight: 700 },
        showLoadingWrapperBorder: false,
        showCompareValueArrow: true,
        showPercentOfTotal: true,
        showMainValueUnits: true,
        showAllValues: true,
        itemsToShow:
            itemsToShow === 'all'
                ? reportingObjectsByType[
                      props.rating ? (props.rating.dataobj_type ? props.rating.dataobj_type : '') : ''
                  ].length
                : itemsToShow,
        metric: props.rating?.metric,
        mainColumn: props.rating?.main_column,
        sortType: props.rating?.sort_method,
        dataObjType: props.rating?.dataobj_type,
        additionalColumns: props.rating?.additional_columns,
    };

    const toggleDropdown = () => {
        setContextMenuStatus((prevState) => !prevState);
    };

    const editRating = () => {
        if (props.rating?.id) {
            dispatch(storeEditRatingId(props.rating.id));
        }
        closeContextualMenu();
    };

    const removeRating = () => {
        setModalRemoveStatus({ show: true });
        closeContextualMenu();
    };
    const handleRemoveModalClose = () => {
        setModalRemoveStatus({ show: false });
    };
    const closeContextualMenu = () => {
        setContextMenuStatus(false);
    };

    return (
        <RatingCardItem ref={ref}>
            <RatingHat sortMethod={props.rating?.sort_method ?? 'asc'}>
                <RatingHatFiller />
            </RatingHat>
            <DivWrapperBodyAndFooterSC>
                <DivBoxSC $columns={2}>
                    <Title titleLevel="h3">{props.rating?.name}</Title>
                    <Controls>
                        <WidgetAdditionalControls widgetName="Ratings" pdfDownloadData={{ targetRef: ref }} />
                        <ContextualMenu
                            contextualMenuBody={
                                <div>
                                    <OptionWrapper onClick={editRating}>{t('Edit')}</OptionWrapper>
                                    <OptionWrapper onClick={removeRating}>{t('Remove')}</OptionWrapper>
                                </div>
                            }
                            isContextualMenuOpen={contextMenuStatus}
                            closeContextualMenu={closeContextualMenu}
                            gapSpace={5}
                        >
                            <div>
                                <IconButton icon={<MoreHorizontal24Regular />} onClick={toggleDropdown} size={24} />
                            </div>
                        </ContextualMenu>
                    </Controls>
                </DivBoxSC>
                <Rating
                    viewSettings={viewSettings}
                    comparePeriods={props.comparePeriods}
                    mainPeriod={props.mainPeriod}
                    reportingObjectIds={props.reportingObjectIds}
                    metric={props.metric ? props.metric : ''}
                    moduleId={props.moduleId}
                    widgetId={props.widgetId}
                    allMetrics={props.allMetrics}
                    lang={lang}
                    isLoadAftertEdit={props.isLoad}
                />

                <Footer isBorder={false} testId={'Traffic by entrances'} setItemsToShow={setItemsToShow} />
            </DivWrapperBodyAndFooterSC>

            <Modals
                title={t('Delete rating')}
                closeModal={handleRemoveModalClose}
                modalStatus={modalRemoveStatus}
                topColor="fff"
            >
                <ModalDeleteRating ratingId={props.rating?.id} closeModal={handleRemoveModalClose}></ModalDeleteRating>
            </Modals>
        </RatingCardItem>
    );
};

export default RatingItem;
