import { ILegendProps } from './interfaces';
import { ChartDot } from '../../../styles';
import { MainTextWrapper, Text, Wrapper } from './styles';
import { FC } from 'react';
import React from 'react';

/**
 * Компонент для отображения легенды графиков DynamicsPMO
 * @param visible Флаг отвечающий за отображение легенды (выбрана или нет) от этого меняется цвет
 * @param color Цвет точки
 * @param name Название легенды
 */
const Legend: FC<ILegendProps> = ({ color, name, visible, data, className, legendWrapperInlineStyle }) => {
    const AdditionalComponent = data['AdditionalComponent'] as undefined | FC<object>;
    const additionalComponentProps = { data };
    return (
        <Wrapper style={legendWrapperInlineStyle} visible={visible} className={className}>
            <MainTextWrapper>
                <ChartDot newColor={color} />
                <Text>{name}</Text>
            </MainTextWrapper>
            {AdditionalComponent && <AdditionalComponent {...additionalComponentProps} />}
        </Wrapper>
    );
};

export default Legend;
