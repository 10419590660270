import { makeStyles, tokens } from '@fluentui/react-components';
import styled from 'styled-components/macro';

export const LangWrapper = styled.div`
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: right;
    /* margin-left: var(--space-xxxs); */
    line-height: 0;
    min-height: 36px;
`;
export const LangValue = styled.span`
    ${(p) => (p.theme.isRtlLanguage ? 'margin-right: var(--space-xxxs);' : 'margin-left: var(--space-xxxs);')}
`;

export const Wrapper = styled.div``;

export const useOverrides = makeStyles({
    list: { color: tokens.colorNeutralForeground3, minWidth: 'unset' },
    item: { paddingLeft: '2px' },
});
