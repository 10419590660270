import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from './styles';
import { ISheetCell } from '../../../../General.interfaces';
import SaveXLSXFileButton from '../SaveXLSXFileButton/SaveXLSXFileButton';
import DownloadXLSXFileButton from '../DownloadXLSXFileButton/DownloadXLSXFileButton';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';
import Button from '../../../../components/Button/Button';
import { DS } from '../../../../constants/constants';

const ButtonsPanel: React.FC<{
    grid: ISheetCell[][];
    keyWord?: string;
    moduleName: string;
    reportFileName: string;
    onPrepareClick: () => void;
    isFetching?: boolean;
    disableAllButtons?: boolean;
}> = ({
    grid,
    moduleName,
    onPrepareClick,
    keyWord = '',
    isFetching = false,
    reportFileName,
    disableAllButtons = false,
}) => {
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { t } = useTranslation();
    const reportFormat = useMemo(() => {
        return localCurrentOptions?.[keyWord ? `reportFormat${DS}${keyWord}` : 'reportFormat']?.[0]?.id;
    }, [localCurrentOptions]);

    return (
        <Footer>
            {reportFormat === 'plain' ? (
                <>
                    <Button
                        appearance="primary"
                        text={isFetching ? t('Loading...') : t('Prepare')}
                        onClick={onPrepareClick}
                        disabled={isFetching || disableAllButtons}
                    />
                    <SaveXLSXFileButton
                        grid={grid}
                        appearance="primary"
                        disabled={grid.length === 0 || isFetching || disableAllButtons}
                        bookName={reportFileName}
                        currentModuleID={moduleName}
                        keyWord={keyWord}
                    />
                </>
            ) : (
                <DownloadXLSXFileButton
                    currentModuleID={moduleName}
                    keyWord={keyWord}
                    reportFileName={reportFileName}
                    disabled={disableAllButtons}
                />
            )}
        </Footer>
    );
};

export default ButtonsPanel;
