import { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import { IProps } from './interfaces';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Wrapper } from './styles';
import generateOptions from './tools/generateOptions';

const PieChart: FC<IProps> = forwardRef<HighchartsReact.RefObject, IProps>((props, ref) => {
    const [options, setOptions] = useState<Highcharts.Options>();

    useEffect(() => {
        setOptions(generateOptions({ options: props.options, customChartDimensions: props.customChartDimensions }));
    }, [props.options, props.customChartDimensions]);

    const chart = useMemo(() => {
        if (options) {
            return <HighchartsReact highcharts={Highcharts} ref={ref} options={options} />;
        }
        return null;
    }, [options]);

    return (
        <Wrapper customHeight={props.customChartDimensions?.height} style={props.chartWrapperStyles}>
            {chart}
        </Wrapper>
    );
});

export default PieChart;
