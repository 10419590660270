import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
    padding-top: 5px;
    margin-left: ${(p) => (p.theme.isRtlLanguage ? 0 : '10px')};
    margin-right: ${(p) => (p.theme.isRtlLanguage ? '10px' : 0)};
`;

export const CollapseWrapper = styled.div`
    .molecules-collapse__label {
        font-weight: 500;
    }

    .molecules-collapse {
        border-bottom: 1px solid #ebebeb;
    }

    .molecules-collapse__body {
        padding-bottom: 10px;
        padding-left: 10px;
    }
`;
