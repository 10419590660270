import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    flex: 1 0 0;
    table {
        width: 100%;
        position: relative;
        table-layout: auto !important;

        td,
        th {
            color: black !important;
            > span {
                min-height: 40px;
                padding: 10px !important;
                display: flex !important;
                align-items: center !important;
                height: 100% !important;
            }
        }

        td {
            > span {
                white-space: nowrap;
            }
            :first-child {
                > span {
                    white-space: pre-line;
                }
            }
            :not(:first-child) {
                span {
                    justify-content: flex-end;
                }
            }
        }
    }
`;
