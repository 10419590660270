export enum AdditionalSeriesIds {
    Avergage = 'SeriesAverageLine',
    Trend = 'SeriesTrendLine',
    Remove = 'RemoveAdditionalSeries',
}

export enum AdditionalSeriesTypes {
    Trend = 'Trend',
    Avergage = 'Average',
}
