import { FC } from 'react';
import { ITableCustomComponentProps } from '../../../../interfaces';
import ObjectsSelect from '../../../../../../../../../components/Selects/ObjectsSelect/ObjectsSelect';
import { IChangeOptionsArgs } from '../../../../../../../../../components/UserSettings/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { batchDecisionsTableUpdate } from '../../../../reducer';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { isUndefined } from 'lodash';
import { ObjectsSelectWrapper } from './styles';
import useTranslation from '../../../../../../../../../hooks/useTranslation/useTranslation';

const ReportingObjectsSelect: FC<ITableCustomComponentProps> = (props) => {
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const objIds = props.cell.value.split(',').map((id) => Number(id));

    const cellValue: (string | undefined)[] = objIds.map((id) => reportingObjectsById[id]?.name);

    const onChange = (args: IChangeOptionsArgs) => {
        const { selectedReportingObjectsIds } = args.newOptions;
        if (Array.isArray(selectedReportingObjectsIds)) {
            const newData = {
                value: selectedReportingObjectsIds.join(','),
                cell: props.cell,
                row: props.row,
                col: props.col,
            };
            dispatch(batchDecisionsTableUpdate([newData]));
        }
    };

    const isInvalidValue = props.cell.value ? cellValue.some((name) => isUndefined(name)) : false;

    return (
        <ObjectsSelectWrapper>
            <ObjectsSelect
                text={isInvalidValue ? t('Invalid value') : cellValue.join(', ')}
                localCurrentOptions={{
                    selectedReportingObjectsIds: objIds,
                }}
                showSelectedOptionsCount={false}
                changeOptions={onChange}
                iconType={null}
                label=""
            />
        </ObjectsSelectWrapper>
    );
};

export default ReportingObjectsSelect;
