import { LegendOptions, Point, Series } from 'highcharts';
import { renderToString } from 'react-dom/server';
import { CSSProperties } from 'react';

import { theme } from 'src/theme';

import Legend from '../../components/Legend/Legend';
import { TSeries } from '../../interfaces';

interface IArgs {
    seriesReverse: boolean;
    seriesLength: number;
    maxColors: number;
    additionalLegendSettings?: LegendOptions;
    legendWrapperClassName?: string;
    legendWrapperInlineStyle?: CSSProperties;
}

/**
 * Функция для генерации настроек легенды
 * @param seriesReverse Флаг отвечающий за порядок серий (прямой или обратный)
 * @param seriesLength количесвто серий
 * @param maxColors колчиесвто дотсупных цветов
 * @param additionalLegendSettings Дополнительные настройки легенды
 * @returns Объект с настройками для легенды
 */
const generateLegend = (args: IArgs) => {
    const {
        seriesReverse,
        seriesLength,
        maxColors,
        legendWrapperClassName,
        legendWrapperInlineStyle,
        additionalLegendSettings = {},
    } = args;
    return {
        labelFormatter: function (this: Point | Series) {
            const colorIndex = this.index > maxColors - 1 ? this.index % 10 : this.index;

            const seriesIndex = seriesReverse ? seriesLength - colorIndex - 1 : colorIndex;
            const seriesColor = theme.seriesColors[seriesIndex];

            const optionsColor = (this.options as TSeries).color as string;
            return renderToString(
                <Legend
                    color={optionsColor || seriesColor}
                    className={legendWrapperClassName}
                    legendWrapperInlineStyle={legendWrapperInlineStyle}
                    visible={this.visible}
                    name={this.name}
                    data={this}
                />,
            );
        },
        reversed: seriesReverse,
        itemMarginBottom: 10,
        squareSymbol: false,
        alignColumns: false,
        itemDistance: 10,
        symbolPadding: 0,
        symbolHeight: 0,
        symbolRadius: 0,
        align: 'left',
        symbolWidth: 0,
        maxHeight: 100,
        useHTML: true,
        margin: 27,
        ...additionalLegendSettings,
    };
};

export default generateLegend;
