import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    height: 100%;
    overflow: hidden;
    padding-top: 5px;
`;

export const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    z-index: 20;
    position: relative;
`;

export const ContentWrapper = styled.div`
    flex-grow: 1;
    overflow: auto;
`;
