import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import HighchartsReact from 'highcharts-react-official';

import { isMobileInboundWidth } from 'src/theme';

import { IProps } from '../interfaces';
import { theme } from 'src/theme';
import generateChart from '../tools/generateChart';
import { generalReducerValues } from '../../../../../../General.reducer';
import generateTooltip from '../tools/generateTooltip';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import generateLegendOptions from '../tools/generateLegend';
import generatePlotOptions from '../tools/generatePlotOptions';

interface IArgs extends IProps {}

const useChartProps = (args: IArgs): HighchartsReact.Props => {
    const {
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();

    const isMobile = isMobileInboundWidth(width);

    return {
        options: {
            colors: [...theme.seriesColors],
            title: {
                text: '',
            },
            lang: {
                noData: t('No data'),
            },
            credits: {
                enabled: false,
            },
            noData: {
                position: {
                    verticalAlign: 'middle',
                    align: 'center',
                    x: 0,
                    y: 0,
                },
                style: {
                    color: 'rgba(0,0,0,.4)',
                    fontWeight: 'normal',
                    fontSize: '16px',
                },
            },
            chart: generateChart({
                customChartDimensions: args.customChartDimensions,
                chartOptions: args.options?.chart,
                isMobile,
            }),
            tooltip: generateTooltip({ tooltipOptions: args.options?.tooltip }),
            legend: generateLegendOptions({ legendOptions: args.options?.legend }),
            plotOptions: generatePlotOptions({ plotOptions: args.options?.plotOptions }),
            ...omit(args.options, ['chart', 'tooltip', 'legend', 'plotOptions']),
        },
    };
};

export default useChartProps;
