import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UniversalSelect from '../../../../components/Selects/UniversalSelect/UniversalSelect';
import { changeOptions } from '../../../../components/UserSettings/reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useAppDispatch } from '../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../hooks/useWidgetCurrentOptions';

export const DEFAULT_OPTIONS = [
    { id: 'plain', text: 'Plain report' },
    { id: 'portrait', text: 'Vertical format' },
    { id: 'landscape', text: 'Horizontal format' },
];

const LayoutSelect: React.FC<{ name?: string; options?: Array<{ id: string; text: string }> }> = ({
    options = DEFAULT_OPTIONS,
    name = 'reportFormat',
}) => {
    const { t } = useTranslation();
    const { currentModuleID } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    return (
        <UniversalSelect
            name={name}
            labelText={t('Report format')}
            dropdownWidth="sm"
            options={options}
            mode="single"
            defaultSelected={[{ id: 'plain', text: t('Plain') }]}
            localCurrentOptions={localCurrentOptions}
            changeOptions={(args) => dispatch(changeOptions(args))}
        />
    );
};

export default LayoutSelect;
