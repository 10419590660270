import { createContext } from 'react';
import { IEvent, IEventType, IEventTypeToUpdate, IExtendedEvent } from './interfaces';

export interface IEventsMapWidgetContext {
    extendedEventsDataById: { [x: string]: IExtendedEvent };
    isRtlLanguage: boolean;
    storeEventTypeToUpdateTrans: (event: IEventTypeToUpdate | null) => void;
    storeEventsToUpdateTrans: (events: IExtendedEvent[]) => void;
    storeSelectedEventIdToEditTrans: (id: number) => void;
    storeEventIdToDeleteTrans: (id: number) => void;
    storeNewEventsTrans: (events: IEvent[]) => void;
    storeSelectedEventIdTrans: (id: number) => void;
    closeEventModal: () => void;
}

export const EventsMapWidgetContext = createContext<null | IEventsMapWidgetContext>(null);
