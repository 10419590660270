import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState, TRawMetricsData } from './interfaces';
import { ISheetCell } from '../../../../../General.interfaces';

const initialState: IReducerState = {
    grid: [],
    downloadSignal: {},
    rawMetricsData: null,
    keyWord: 'Basic',
};

export const Reports_Basic_Widget_Reducer = createSlice({
    name: 'Reports_Basic_Widget_Reducer',
    initialState,

    reducers: {
        /**
         * Триггер загрузки
         */

        downloadSignal: (state, action: PayloadAction) => {
            state.downloadSignal = {};
        },

        /**
         * Сохранение таблицы
         */
        storeGrid: (state, action: PayloadAction<ISheetCell[][]>) => {
            state.grid = action.payload;
        },

        /**
         * Сохранение сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Обнуление редьюсера
         */
        resetReducer: (state) => {
            state.rawMetricsData = null;
            state.grid = [];
        },
    },
});

export const { downloadSignal, storeGrid, storeRawMetricsData, resetReducer } = Reports_Basic_Widget_Reducer.actions;

export const Reports_Basic_Widget_Reducer_Values = (state: RootState) => state.Reports_Basic_Widget_Reducer;

export default Reports_Basic_Widget_Reducer.reducer;
