import React, { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { WidgetWrapper } from './styles';

interface Props {
    children?: ReactNode;
    styles?: React.CSSProperties;
}

export type Ref = HTMLDivElement;

const Element = forwardRef<Ref, Props>((props, ref) => {
    const { isRtlLanguage } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    return (
        <WidgetWrapper ref={ref} isRtlLanguage={isRtlLanguage} style={{ ...props.styles }}>
            {props.children}
        </WidgetWrapper>
    );
});

export default Element;
