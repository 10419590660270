import { keyBy, sumBy } from 'lodash';
import { SeriesColumnOptions } from 'highcharts';

import { TFunction } from 'src/hooks/useTranslation';

import { IMetric, IReportingObject, TMetricResponse } from '../../../../General.interfaces';
import {
    ICategory,
    IDataAdapterResponse,
    IGroup,
    IReportingObject2Category,
    IReportingObject2Group,
} from '../interfaces';
import { IExtendedChartPoint } from '../../../Charts/interfaces';

interface IArgs {
    response: TMetricResponse[];
    reportingObjects: IReportingObject[];
    categories: ICategory[];
    reportingObjects2Categories: IReportingObject2Category[];
    reportingObjects2Groups: IReportingObject2Group[];
    groups: IGroup[];
    allMetrics: IMetric[];
    t: TFunction;
}

const dataAdapter = (args: IArgs): IDataAdapterResponse => {
    const seriesByGroupId = keyBy(
        args.groups.map((group) => {
            return {
                id: group.id,
                name: group.text,
                data: [] as IExtendedChartPoint[],
                type: 'column',
            };
        }),
        'id',
    );

    const groupsByReportingObjectId = keyBy(args.reportingObjects2Groups, 'reportingObjectId');
    const categoriesByReportingObjectId = keyBy(args.reportingObjects2Categories, 'reportingObjectId');

    const metricFromStore = args.allMetrics.find((item) => args.response[0]?.[0]?.context.metric === item.id);

    args.response.forEach((metricResponse) => {
        metricResponse.forEach((metricResponseItem) => {
            const { items, context } = metricResponseItem;
            const objectId = context.data_objects[0].id;
            const group = groupsByReportingObjectId[objectId];
            const category = categoriesByReportingObjectId[objectId];
            const reportingObject = args.reportingObjects.find((item) => item.id === objectId);
            const categoryIndex = args.categories.findIndex((item) => item.id === category?.categoryId);

            if (group && categoryIndex > -1 && reportingObject) {
                const result = sumBy(items, (item) => item.value || 0);
                seriesByGroupId[group.groupId]?.data.push({
                    name: reportingObject.name,
                    y: result,
                    x: categoryIndex,
                    title: args.t(metricFromStore?.text || ''),
                    units: metricFromStore?.units,
                    showUnits: false,
                    subTitle: args.t(metricFromStore?.units || ''),
                });
            }
        });
    });

    return {
        chartProps: {
            options: {
                series: Object.values(seriesByGroupId) as SeriesColumnOptions[],
                xAxis: {
                    categories: args.categories.map((item) => item.text),
                },
                chart: {
                    type: 'bar',
                    height: undefined,
                },
                plotOptions: {
                    series: {
                        stacking: 'percent',
                        dataLabels: {
                            enabled: true,
                            format: '{point.percentage:.0f}%',
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                },
                legend: {
                    reversed: false,
                },
                tooltip: {
                    shared: false,
                },
            },
        },
    };
};

export default dataAdapter;
