import { FC } from 'react';
import { IProps } from './interfaces';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { isNumber } from 'lodash';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';
import { getColorFromPercent } from '../../../../../../tools/getColorFromPercent';
import Stack from '../../../../../Stack/Stack';
import { PercentDifferenceValue } from './styles/styles';
import './styles/styles.scss';

/**
 * Компонент для отображения дополнительно информации в легенде графика
 */
const LegendPercentageInfo: FC<IProps> = ({ data }) => {
    if ('percentage' in data) {
        const compareValue = data['compareValue'] as number | undefined | null;
        const mainValue = data.y as number | undefined | null;

        let percentDifference: string | null = null;

        if (isNumber(mainValue) && isNumber(compareValue)) {
            const percentResult = getDifferenceBetweenNumbers(mainValue, compareValue).percentDifference;
            percentDifference = percentResult;
        }

        const percentDifferenceColor = getColorFromPercent(percentDifference, 'object');

        return (
            <>
                <Stack
                    className="piechart-by-cat-legend_percentage-info__wrapper"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    {valueFormatter({
                        value: data.percentage,
                        showSpaceBetweenNumberAndUnit: false,
                        units: '%',
                        precision: (data.percentage || 0) <= 1 ? 1 : 0,
                    })}
                </Stack>

                <PercentDifferenceValue color={percentDifferenceColor.color}>
                    {valueFormatter({
                        value: percentDifference,
                        showSpaceBetweenNumberAndUnit: false,
                        units: '%',
                        precision: 1,
                    })}
                </PercentDifferenceValue>
            </>
        );
    }

    return null;
};

export default LegendPercentageInfo;
