import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Reports_Basic_Widget_Reducer_Values, resetReducer, storeGrid } from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../../General.reducer';
import { generateGrid } from '../tools/generateGrid';
import { ISelectedOption } from '../../../../../../components/Selects/Select/interfaces';
import { DS } from '../../../../../../constants/constants';

/** Кастомный хук для подготовки данных */
const usePrepareData = () => {
    const { rawMetricsData, keyWord } = useSelector(Reports_Basic_Widget_Reducer_Values);
    const {
        src: { reportingObjects },
        allMetrics,
        currentModuleID,
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Обнуление редьюсера при смене локации */
    useEffect(() => {
        dispatch(resetReducer());
    }, [localCurrentOptions?.selectedLocationId]);

    /** Создание таблицы после получения метрик */
    useEffect(() => {
        if (Array.isArray(rawMetricsData)) {
            const rest = `${DS}${keyWord}`;
            const additionalFieldsIds =
                localCurrentOptions?.[`additionalFields${rest}`]?.map((element: ISelectedOption) => element.id) || [];
            const selectedMetrics = localCurrentOptions?.[`selectedMetrics${rest}`] || [];

            const grid = generateGrid(
                rawMetricsData,
                reportingObjects,
                allMetrics,
                additionalFieldsIds,
                selectedMetrics,
                t,
            );
            grid && dispatch(storeGrid(grid));
        }
    }, [JSON.stringify(localCurrentOptions?.[`additionalFields${DS}${keyWord}`]), rawMetricsData, t]);
};

export default usePrepareData;
