import { useDispatch, useSelector } from 'react-redux';
import { TSelectedWidgetKey } from '../../interfaces';
import { Configuration_EventsConfig_Module_Redcuer_Values, storeSelectedWidgetKey } from '../../reducer';
import { tabListItems } from './constants/constants';
import TabList from '../../../../../components/TabList/TabList';

/**
 * Компонент для отображения навигации по виджетам
 */
const WidgetsNavigation = () => {
    const { selectedWidgetKey } = useSelector(Configuration_EventsConfig_Module_Redcuer_Values);
    const dispatch = useDispatch();

    const tabListSelectHandler = (id: string) => {
        dispatch(storeSelectedWidgetKey(id as TSelectedWidgetKey));
    };

    return <TabList options={tabListItems} selectedValue={selectedWidgetKey} selectHandler={tabListSelectHandler} />;
};

export default WidgetsNavigation;
