import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { Reports_Comparison_Widget_Reducer_Values, storeRawMetricsData } from '../reducer';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import usePrepareData from './usePrepareData';
import { TRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
import { DS } from '../../../../../../constants/constants';

/** Кастомный хук для получения данных */
export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();
    const { token, currentModuleID } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const { downloadSignal, keyWord } = useSelector(Reports_Comparison_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    usePrepareData();
    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const rest = `${DS}${keyWord}`;

        if (!localCurrentOptions) return;

        if (
            !localCurrentOptions?.[`mainPeriod${rest}`] ||
            !localCurrentOptions?.[`comparePeriods${rest}`] ||
            !localCurrentOptions?.[`mainDateRanges${rest}`] ||
            !localCurrentOptions?.[`compareDateRanges${rest}`] ||
            !localCurrentOptions?.[`detail${rest}`] ||
            !localCurrentOptions?.[`selectedMetrics${rest}`] ||
            !localCurrentOptions?.[`selectedReportingObjectsIds${rest}`]
        )
            return;

        const mainDateRange = localCurrentOptions?.[`mainDateRanges${rest}`].find(
            (item: { id: string }) => item.id === localCurrentOptions?.[`mainPeriod${rest}`]?.id,
        );
        const compareDateRange = localCurrentOptions?.[`compareDateRanges${rest}`].find(
            (item: { id: string }) => item.id === localCurrentOptions?.[`comparePeriods${rest}`][0]?.id,
        );
        if (mainDateRange && compareDateRange) {
            const timeRanges = [
                [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo, 'main'],
                [compareDateRange?.period.dateFrom, compareDateRange?.period.dateTo, 'compare'],
            ];

            const time_freq: TTimeFreq = localCurrentOptions?.[`detail${rest}`].id as TTimeFreq;

            const requests: TRequestMetricsArgs = [];

            localCurrentOptions?.[`selectedMetrics${rest}`].forEach((metric: string) => {
                timeRanges.forEach((time_range) => {
                    requests.push({
                        signal,
                        token,
                        alias: time_range[2],
                        metric,
                        obj_ids: localCurrentOptions?.[`selectedReportingObjectsIds${rest}`],
                        object_aggregation: false,
                        time_range: time_range.slice(0, 2),
                        time_freq,
                    });
                });
            });

            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

            fetchData(requests).then((responses: TServerResponse) => {
                /** Обработка ответа для метрик */
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    res: responses,
                    dispatch,
                });
            });
        }

        return () => controller.abort();
    }, [downloadSignal]);
};
