import { FC } from 'react';
import { IProps as ISelectsProps } from '../../interfaces';
import MetricsSelect from '../../../../../../../Selects/MetricsSelect/MetricsSelect';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { IChangeOptionsArgs } from '../../../../../../../UserSettings/interfaces';

const OnBoardMetricsSelect: FC<ISelectsProps> = (props) => {
    const updateWidgetOptions = (args: IChangeOptionsArgs) => {
        props.updateWidget('options', args.newOptions);
    };

    const onMetricsChange = (args: IChangeOptionsArgs) => {
        updateWidgetOptions(args);
    };

    return (
        <MetricsSelect
            disabled={props.widgetConfig?.visual.useMetricFrom?.[0].id === 'fromPanel'}
            localCurrentOptions={props.widgetConfig?.options}
            changeOptions={onMetricsChange}
            name={'metrics'}
            maxSelected={1}
        />
    );
};

export default OnBoardMetricsSelect;
