import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../General.reducer';
import { getRequest, patchRequest } from '../tools/API/appBackendAPI';
import { useAppDispatch } from './useSettings';
import { Dashboards_Reducer_Values, storeSharedDashboardHash } from 'src/Dashboards.reducer';
import { IDashboard } from 'src/components/DashboardsCommon/interfaces';

export const useSharedDashboard = () => {
    const dispatch = useAppDispatch();
    const [sharedDashboardModalStatus, setSharedDashboardModalStatus] = useState<{
        show: boolean;
        sharedDashboard?: IDashboard;
    }>({
        show: false,
    });

    const { globalSpinner, locations, urlsByServices, token, initialDataReceived } = useSelector(generalReducerValues);

    const { sharedDashboardHash } = useSelector(Dashboards_Reducer_Values);

    useEffect(() => {
        if (initialDataReceived && sharedDashboardHash && token) {
            const url = `${urlsByServices?.['app/app-backend'].SHARED_DASHBOARDS_URL}?hash=${sharedDashboardHash}`;
            getRequest(url, token).then((res) => {
                const { hash, id, ...rest } = res.data[0];
                const sharedDashboard: IDashboard = {
                    ...rest,
                    dashboard_name: `${res.data[0].dashboard_name} (shared)`,
                };
                setSharedDashboardModalStatus({ show: true, sharedDashboard });
                dispatch(storeSharedDashboardHash(null));
            });
        }
    }, [sharedDashboardHash, initialDataReceived, token, locations, urlsByServices]);

    return { sharedDashboardModalStatus, setSharedDashboardModalStatus };
};
